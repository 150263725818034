import ReactPixel from 'react-facebook-pixel';
import appConfig from '../../core/appConfig';

/**
 * React JS wrapper for Facebook's Pixel
 * https://github.com/zsajjad/react-facebook-pixel
 */

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: appConfig.app.debug, // enable logs
};

ReactPixel.init( appConfig.fb.pixelId, undefined, options );

export default ReactPixel;
