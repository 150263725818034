import { ReactElement } from 'react';

//  Components
import InsuranceHelper from '../../components/InsuranceHelper';
import Translation from '../../components/Translation';
import GuardiansListItem from './GuardiansListItem';

// Types
import PersonInterface from '../../types/PersonInterface';
import RoleInterface from '../../types/RoleInterface';
import Person from '../../core-data-service/models/Person';

// Hooks
import useRenderInsuranceUpsell from '../../hooks/useRenderInsuranceUpsell';

interface Props {
  minorChildren: PersonInterface[];
  roles: RoleInterface[];
}

export default function GuardiansSummaryList({ minorChildren, roles }: Props ): ReactElement{

  const firstMinorChildFirstName = minorChildren[0] ? new Person( minorChildren[0]).firstName : '';
  const shouldRenderInsuranceHelper = useRenderInsuranceUpsell() && !!firstMinorChildFirstName;

  return (
    <div>
      {/* for each underage child get child and find guardian and backup guardian*/}
      {/* filter for underage children */}
      {
        minorChildren.map(( ward, i ) => {

          /** Find all guardian type roles for this child */
          const wardRoles = roles
            .filter( role => role.ward_id === ward.id );

          if( wardRoles.length > 0 ){
            return wardRoles
              .map(( role, idx ) =>
                <div key={ role.id }
                  className={ '' }
                >
                  <GuardiansListItem
                    className={ idx !== 0 || i > 0  ? 'pt-8' : '' }
                    ward={ ward }
                    role={ role }
                    isFallback={ idx > 0 }
                    showAddButton={ idx === wardRoles.length -1 }
                  />
                </div>,
              );
          } else {
            return(
              <GuardiansListItem
                className={ i > 0 ? 'pt-8' : '' }
                key={ ward.id }
                ward={ ward }
                showAddButton = { true }
              />
            );
          }
        })}
      { shouldRenderInsuranceHelper && <InsuranceHelper
        className="mt-10"
        body={ <Translation translationKey="guardians.upsell.messagebubble.text" dynamicStringValues={ [ firstMinorChildFirstName ] }/> }
        primaryActionLabel={ <Translation translationKey="upsell.messagebubble.guardians.button"/> }
        eventProperties={{ button: 'GuardiansSummary' }}
      />}
    </div>
  );
}
