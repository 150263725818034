
import { ReactElement, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Components
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Translation from '../../components/Translation';

// Routes
import {
  will_intro as willIntroRoute,
  dashboard as dashboardRoute,
} from '../../../core/routes';

// Hooks
import useTranslations from '../../hooks/useTranslations';

// Redux
import entitlementsSelector from '../../store/selectors/entitlementsSelector';
import { patchInvitation } from '../../store/reducers/invitationsReducer';
import { useDispatch, useSelector } from '../../store';
import userSelector from '../../store/selectors/userSelector';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import { fetchPeople } from '../../store/reducers/peopleReducer';

// Types
import Invitation from '../../core-data-service/models/Invitation';

// Utils
import { toTitleCase } from '../../../core/utils/stringHelpers';

export default function DescribeRole(): ReactElement{
  const { push } = useHistory();
  const { id } = useParams<{ id: string}>();
  const dispatch = useDispatch();
  const user = useSelector( userSelector );
  const { getTranslation } = useTranslations();

  const { will } = useSelector( entitlementsSelector );

  const invitation = useSelector( store => store.invitations.data.find( invite => invite.id === id ));
  const isPendingInviteUpdate = useSelector( state => state.invitations.patchInvitation.meta.requestStatus === 'pending' );

  const invitorFirstName = invitation?.invitor?.name || '';
  // if the invite has a ROLE, like Guardian, Executor, etc.
  const shouldRenderRoleSection = invitation?.type === Invitation.TYPE_ROLE;
  const roleName = toTitleCase( invitation?.context?.name || '' ) ;

  const handleModalClosed = () => {
    push( dashboardRoute.get());
  };

  // at this point, update the invitation to ACCEPTED
  useEffect(() => {
    if( invitation?.user_id === user.id ) {
      dispatch( makeSnack({
        message: 'You cannot accept your own invite',
        theme:'error',
      }));
    } else {
      dispatch( patchInvitation({
        id,
        response: Invitation.RESPONSE.ACCEPTED,
      }));
      dispatch( fetchPeople());
    }
  }, [ dispatch, id, invitation?.user_id, user.id ]);

  return (
    isPendingInviteUpdate
      ? <></>
      : <Modal
        toggleModal={ handleModalClosed }
      >
        <div className="text-center">
          <div className="w-[100px] h-[100px] mx-auto mb-8">
            <img src={ getTranslation( 'modal.invitation.inviteAccepted.headerImage' ) } alt="Thumbs Up" />
          </div>
          <h1 className="text-black font-headline mb-4 text-3xl">
            <Translation translationKey="invite.onboarding.connected.title" dynamicStringValues={ [ invitorFirstName ] } />
          </h1>
          <p className="mb-8">
            <Translation translationKey="invite.onboarding.prepare.will.description" />
          </p>
          <Button onClick={ () => push( will.isEnabled ? willIntroRoute.get() : dashboardRoute.get()) } className="shadow-button mx-auto">
            <Translation translationKey="invite.onboarding.create.will.button" />
          </Button>
          { shouldRenderRoleSection && <>
            <hr className="my-8 border-t border-grey"/>
            <p className="mb-4"><Translation translationKey="invite.onboarding.information.label" dynamicStringValues={ [ roleName ] } /></p>
            <a className="text-forest font-bold" target="_blank" rel="noreferrer" href={ `https://tomorrow.me/learn/${invitation?.context?.name?.toLowerCase()}` } >Videos and resources</a>
          </>}
        </div>
      </Modal>
  );
}
