import User from '../core-data-service/models/User';
import { useSelector } from '../store';

interface UseAuth {
  hasAuthenticated: boolean;
  hasUserRegistered: boolean;
  isGuestUser: boolean;
  hasCompletedOnboarding: boolean;
}

export default function useAuth(): UseAuth{
  const hasCompletedOnboarding = useSelector( state => !!state.auth?.hasCompletedOnboarding );
  const hasAuthenticated = useSelector( state => !!state.auth?.hasAuthenticated );
  const isGuestUser = useSelector( state => state.user?.data?.status === User.STATUS_GUEST );
  const hasUserRegistered = hasCompletedOnboarding && hasAuthenticated && !isGuestUser;

  return {
    hasCompletedOnboarding,
    hasUserRegistered,
    hasAuthenticated,
    isGuestUser,
  };
}
