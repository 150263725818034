import { ReactElement } from 'react';
import useTranslations from '../hooks/useTranslations';
import { TranslationKeys } from '../store/initial/translations';
import defaultJSON from '../store/initial/translations/default.json';
import { parseTranslation } from '../../core/utils/parseTranslation';
import appConfig from '../../core/appConfig';
import { ENV_PRODUCTION } from '../../core/constants';

export interface ITranslationProps {
  translationKey: TranslationKeys;
  dynamicStringValues?: string[];
}

export default function Translation({
  translationKey,
  dynamicStringValues = [],
}: ITranslationProps ): ReactElement{
  let { translation } = useTranslations( translationKey, dynamicStringValues );
  if(( !translation || translation.length===0 ) && translationKey in defaultJSON ){
    translation = parseTranslation( defaultJSON[translationKey], dynamicStringValues );
  }
  return (
    appConfig.app.deploymentEnv !== ENV_PRODUCTION ?
      <span title={ translationKey }>
        {  translation }
      </span>
      : <>{ translation }</>
  );
}
