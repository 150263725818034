import { ReactElement, useState } from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import { useHistory, useLocation } from 'react-router-dom';
import { person as personRoute } from '../../../core/routes';
import ActionContainer from '../../components/ActionContainer';
import AddButton from '../../components/AddButton';
import FieldErrorComponent from '../../components/FieldError';
import PeopleSelector from '../../components/PeopleSelector';
import { useSelector } from '../../store';
import { TranslationKeys } from '../../store/initial/translations';
import PersonInterface from '../../types/PersonInterface';
import PersonSelectedContainer from '../../components/PersonSelectedContainer';

interface Props {
  className?: string;
  label: string;
  selectorTitleTranslationKey?: TranslationKeys;
  onChange: ( person: PersonInterface['id'] | null )=> void;
  value?: PersonInterface['id'] | null;
  people: PersonInterface[];
  error?: FieldError;
}

export default function PropertyOwnershipSelector({ className = '', label, error, selectorTitleTranslationKey, onChange, value, people }: Props ): ReactElement{
  const location = useLocation();
  const { push } = useHistory();
  const [ isPickerOpen, setIsPickerOpen ] = useState( false );

  // only allows for one value currently
  const selectedPerson = useSelector( state => state.people.data.find( person => person.id === value ));
  const onRemovePerson = () => onChange( null );
  const handlePrimaryAction = () => {
    if( !!selectedPerson ) {
      onRemovePerson();
    } else {
      if( people.length ) {
        setIsPickerOpen( true );
      } else {
        push({
          pathname: personRoute.get({ id: 'create' }),
          state: { background: location },
        });
      }
    }
  };

  return (
    <ActionContainer
      primaryActionLabel={ !!selectedPerson ? 'Remove' : '' }
      primaryActionTheme={ !!selectedPerson ? 'clean-danger' : 'clean' }
      primaryAction={ !!selectedPerson ? handlePrimaryAction : undefined }
      header={ <span className="font-bold text-black">{label}</span> }
      className={ className }
    >
      {!selectedPerson &&
        <AddButton
          onClick={ handlePrimaryAction }
          iconName={ 'addGuardian' }
          className={ 'mt-4' }
        />
      }
      {selectedPerson &&
        <PersonSelectedContainer
          person={ selectedPerson }
          role={ label }
          translationKey={ 'personSelector.property.label' }
        />
      }

      {/* @todo - make fieldError component */}
      {error && error.message &&
        <FieldErrorComponent
          className="mt-2"
          errorMessage={ error.message }
        />
      }

      <PeopleSelector
        onSelect={ ( id: PersonInterface['id']) => {
          setIsPickerOpen( false );
          !!onChange && onChange( id );
        } }
        optionTypeLabel={ label }
        people={ people }
        isOpen={ isPickerOpen }
        onClose={ ( id?: PersonInterface['id']) => {
          setIsPickerOpen( !isPickerOpen );
        } }
        titleTranslationKey={ selectorTitleTranslationKey }
      />
    </ActionContainer>
  );
}

