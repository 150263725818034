import InheritanceInterface from '../types/InheritanceInterface';

export const isNumberEqualishTo100 = ( value: number, tolerance = 0.03 ): boolean => {
  return value + tolerance >= 100 && value - tolerance <= 100;
};

export const sumOfShares = ( arr: ( InheritanceInterface | undefined )[]): number => arr
  .reduce(( prev: number, current ) => {
    const share = current?.share || 0;
    return share + prev;
  }, 0 );
