import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Routes
import { healthcare_directive as healthcareDirectiveRoute } from '../../../../../core/routes';

// Redux
import { useDispatch, useSelector } from '../../../../store';
import { setUserAttributes } from '../../../../store/reducers/userReducer';
import { directivesSelector } from '../../../../store/selectors/directivesSelector';

// Hooks
import useFlow from '../../../../hooks/useFlow';
import useUserAttributes from '../../../../hooks/useUserAttributes';

// Components
import Flow from '../../../../components/Flow';
import SegmentView from '../../../../components/SegmentView';
import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import Translation from '../../../../components/Translation';

// Types
import WishesStep from './WishesStep';
import { patchDirective } from '../../../../store/reducers/directivesReducer';
import { FormAnswersInterface } from '../../../../types/FormInterface';

// Utils
import { filterFormAnswers, getConditionalQuestions } from '../../../../utils/formUtils';

export default function Wishes(): ReactElement{
  const { hasStartedWishesChapter } = useUserAttributes().documents.healthcareDirective;

  const existingForm = useSelector( directivesSelector );
  const {
    id: directiveId,
    form: questions,
    directives: existingDirective,
  } = existingForm;
  const dispatch = useDispatch();

  // Set the user attribute on entry
  useEffect(() => {
    if ( !hasStartedWishesChapter ) {
      dispatch( setUserAttributes({ 'app.healthcareDirectiveWishesChapterStarted': true }));
    }
  }, [ dispatch, hasStartedWishesChapter ]);


  const { push } = useHistory();

  const handleProceed = () => currentIndex === renderedQuestions.length - 1
    ? push( healthcareDirectiveRoute.get())
    : proceed();


  const renderedQuestions = getConditionalQuestions( questions, existingDirective );

  // first question as fallback
  // this could be improved, but for now let's just navigate to the first "unanswered" question
  // there could be an argument to go to first "required" question but that is only the last one
  let initialIndex = 0;
  for( const [ idx, question ] of renderedQuestions.entries()) {
    if( !existingDirective[question.id]) {
      initialIndex = idx;
      break;
    }
  }


  const { currentIndex, proceed, retreat } = useFlow( initialIndex );
  /**
     * Instead of submitting data from each question, this method is passed down to the submit handler
     * It accepts the data as an argument and then determines which "conditional" questions are still
     * valid, creates a new directive to patch and then makes a request.
     */
  const handleDirectiveUpdate = ( data: FormAnswersInterface ) => {
    const updatedDirective = filterFormAnswers( questions, { ...existingDirective, ...data });
    const patchData = {
      id: directiveId,
      directives: updatedDirective,
      onSuccess: handleProceed,
    };

    dispatch( patchDirective( patchData ));
  };
  /**
   * @TODO if not finished set initial index
   */

  return (
    <div className="flex fixed inset-0 sm-only:bg-white bg-defaultBg">
      <SegmentView
        onRetreat={ () => push( healthcareDirectiveRoute.get()) }
        hidePrimaryAction
        retreatIcon="close"
      >
        <div
          style={{
            transform: `translateX(${-( 100 - (( currentIndex )/( renderedQuestions.length )) * 100 )}%)`,
          }}
          className="progress absolute top-0 left-0 h-2 bg-forest w-full transition-transform duration-400"
        />
        <Flow currentIndex={ currentIndex }>
          {renderedQuestions.map(( question, idx ) => {
            return (
              <WishesStep
                question={ question }
                key={ idx }
                proceedLabel={
                  currentIndex === renderedQuestions.length - 1
                    ? <Translation translationKey="card.questions.finish.button.title"/>
                    : <Translation translationKey="card.questions.next.button.title"/>
                }
                retreatButton={ currentIndex !== 0
                  ? <Button className="px-5" type="button" theme="ghost" onClick={ retreat }>
                    <Icon name="arrow-left" />
                    <span className="sr-only">Previous Question</span>
                  </Button>
                  : <></>
                }
                handleDirectiveUpdate={ handleDirectiveUpdate }
              />
            );
          })}
        </Flow>
      </SegmentView>
    </div>
  );
}
