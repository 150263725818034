import { Keyable } from '../../client/types/Keyable';

export function keysOf<T>( obj: T ): Array<keyof T>{
  return Array.from( Object.keys( obj )) as any;
}

// https://stackoverflow.com/a/62055863/6650101
type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];
export function ObjectEntries<T extends Record<string,unknown>>( t: T ): Entries<T>[]{
  return Object.entries( t ) as any;
}

// Allows to keep types when using Object.keys
export const ObjectKeys = Object.keys as <T extends Record<string,unknown>>( obj: T )=> Array<keyof T>;


export function stringToUserAttribute( attributeString: string ): string{
  const userAttributesMatches: Keyable = {
    'hasChildren': 'app.hasChildren',
    'hasDomesticPartnership': 'app.hasDomesticPartnership',
    'hasSpouse': 'app.hasSpouse',
    'hasUnderageChildren': 'app.hasUnderageChildren',
    'incomeBelowThreshold': 'financial.income.below_threshold',
    'hasSeenExecutorsInviteModal': 'app.hasSeenExecutorsInviteModal',
    'hasSeenGuardiansInviteModal': 'app.hasSeenGuardiansInviteModal',
    'hasSeenChildrenHeirsLifeModal': 'app.hasSeenChildrenHeirsLifeModal',
    'shouldBeReviewed': 'app.willNeedsReview',
    'willHeirsChapterStarted': 'app.willHeirsChapterStarted',
    'willHeirsChapterNeedsReview': 'app.willHeirsChapterNeedsReview',
    'guardiansChapterStarted': 'app.guardiansChapterStarted',
    'guardiansChapterNeedsReview': 'app.guardiansChapterNeedsReview',
    'willValuablesFinished': 'app.willValuablesFinished',
    'petsChapterStarted': 'app.petsChapterStarted',
    'willRealEstateFinished': 'app.willRealEstateFinished',
    'executorsChapterStarted': 'app.executorsChapterStarted',
    'willPreviewViewed': 'app.willPreviewViewed',
  };

  return userAttributesMatches[attributeString] || attributeString;
}
