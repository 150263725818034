export const secondsToTimeString = ( seconds: number ): string => {
  const date = new Date( seconds * 1000 );
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = zeroPad( date.getUTCSeconds());
  if ( hh ) {
    return `${hh}:${zeroPad( mm )}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export function zeroPad( string: string | number ): string{
  return ( '0' + string ).slice( -2 );
}

