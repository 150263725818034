import { AxiosResponse } from 'axios';
import WebClientRequest from '../core-data-service/WebClientRequest';

const stripeHelper = {
  processPayment: async( stripe_token: string|undefined, sku: string ): Promise<AxiosResponse> =>{
    if ( !stripe_token ) {
      return Promise.reject( 'Missing card' );
    }

    if ( !sku ) {
      return Promise.reject( 'Missing sku' );
    }

    return WebClientRequest.post( '/v1/transactions', { sku, stripe_token });
  },
};

export default stripeHelper;
