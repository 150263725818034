import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { dashboard as dashboardRoute } from '../../core/routes';
import Button, { ButtonProps, ButtonTheme } from './Button';
import Icon, { Icons } from './Icon';

interface SegementViewProps {
  backgroundColorClass?: string;
  contentBackgroundColorClass?: string;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  contentContainerPadding?: string;
  full?: boolean;
  hidePrimaryAction?: boolean;
  hideSecondaryAction?: boolean;
  hideLogo?: boolean;
  onPrimaryAction?: ()=> void;
  onSecondaryAction?: ()=> void;
  secondaryActionChildren?: React.ReactNode;
  secondaryButtonTheme?: ButtonTheme;
  onRetreat: ()=> void;
  primaryActionComponent?: React.ReactNode;
  primaryActionLabel?: string | React.ReactNode;
  primaryActionTheme?: ButtonTheme;
  primaryActionButtonType?: ButtonProps['type'];
  primaryActionButtonIsPending?: ButtonProps['isPending'];
  retreatIcon?: Icons;
  title?: React.ReactNode | string;
  subhead?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  shouldHideBackButton?: boolean;
}

export default function SegmentView({
  backgroundColorClass = 'sm-only:bg-white bg-defaultBg',
  contentBackgroundColorClass = 'md:bg-white',
  children,
  className = '',
  contentClassName = '',
  contentContainerPadding = '',
  full = false,
  hidePrimaryAction,
  hideSecondaryAction,
  hideLogo = false,
  onPrimaryAction,
  primaryActionButtonIsPending = false,
  primaryActionButtonType = 'button',
  primaryActionComponent,
  primaryActionLabel = 'Continue',
  onSecondaryAction,
  secondaryActionChildren = 'Skip',
  secondaryButtonTheme,
  onRetreat,
  retreatIcon = 'arrow-left',
  title,
  subhead,
  subtitle,
  shouldHideBackButton = false,
}: SegementViewProps ): ReactElement{

  return (
    <div
      className={ `
        fixed
        md-max:flex
        md-max:flex-col
        top-0
        h-full
        left-0
        right-0
        ${backgroundColorClass}
        overflow-y-scroll
      ` }
    >
      <div className="container--lg relative md-max:flex md-max:flex-col md-max:flex-1 md-max:w-full">

        {/* Left controls */}

        <div className="md:absolute z-10 md:top-12 md:left-10 md:bottom-12 flex">
          <div
            className="
              flex-1
              flex
              flex-row
              md:flex-col
              justify-between
              items-start
              fixed
              top-6 sm-only:top-8 sm-only:left-8 sm-only:right-8
              md:top-12 md:bottom-12
              pointer-events-none
            "
          >
            {
              !shouldHideBackButton &&
            <button
              type="button"
              id="close-retreat-button"
              className={
                `${
                  full
                    ? 'bg-white rounded-full shadow-md'
                    : 'sm-only:bg-white rounded-full sm-only:shadow-md'
                }
                retreatButton
                button-focusStyles--dark
                pointer-events-auto
                `
              }
              onClick={ () => {
                onRetreat && onRetreat();
              } }
            >
              <Icon name={ retreatIcon } className="text-black"/>
            </button>
            }

            {!hideLogo &&
              <Link to={ dashboardRoute.get() } className="pointer-events-auto">
                <Icon name={ 'logoMark' } className="hidden md:block text-forest"/>
              </Link>
            }

            {/* secondary action */}
            {!hideSecondaryAction &&
              <div className="md:hidden pointer-events-auto">
                {onSecondaryAction && secondaryActionChildren &&
                  <SegmentSecondaryButton
                    buttonId="general-secondary-button"
                    theme={ secondaryButtonTheme }
                    onClick={ onSecondaryAction }
                  >
                    {secondaryActionChildren}
                  </SegmentSecondaryButton>
                }
              </div>
            }
          </div>
        </div>

        {/* content */}

        <div
          className={ `
            ${className}
            flex-1
            flex
            flex-wrap
            justify-center
            md-max:pt-24
            md:min-h-screen
          ` }
        >
          <div className={ `${full ? 'fixed inset-0 flex-1 flex flex-col overflow-y-scroll' : 'w-full md:w-lg md:p-12'} ${contentClassName}` }>
            {!full &&
              <>
                {subhead && <div className="text-black-50 mb-4">{subhead}</div>}
                {( title || subtitle ) &&
                  <div className="mb-8 md:mb-12">
                    {title &&
                      <h1 className="text-black text-3xl md:text-5xl font-headline">
                        {title}
                      </h1>
                    }

                    { subtitle && <div className="mt-4 md:mt-8">{subtitle}</div> }
                  </div>
                }
              </>
            }
            {full ?
              children
              :
              <div className={ `rounded md:overflow-hidden sm-only:pb-32 md:p-12 ${ contentContainerPadding } ${contentBackgroundColorClass}` }>
                {children}
              </div>
            }
          </div>
        </div>

        {/* right controls */}
        <div
          className={ `
              ${full ? 'absolute top-12 right-10 bottom-12' : 'md-max:mb-4 md:absolute md:top-12 md:right-10 md:bottom-12' }
              `
          }
        >
          <div
            className={ `
                flex flex-col
                items-end
                fixed
                z-10
                ${full ? 'justify-start md-max:top-4' : 'justify-end md-max:bottom-6 '}
                md-max:left-6 md-max:right-6
                md:m-0 md:top-12 md:bottom-12
                md:transform md:-translate-x-full
                pointer-events-none
              ` }
          >
            <div className="flex flex-1 flex-col justify-between items-end">

              {/* secondary action */}
              {!hideSecondaryAction &&
                <div className="hidden md:block pointer-events-auto">
                  {onSecondaryAction &&
                      <SegmentSecondaryButton
                        buttonId="general-secondary-button"
                        theme={ secondaryButtonTheme }
                        onClick={ onSecondaryAction }
                      >
                        {secondaryActionChildren}
                      </SegmentSecondaryButton>
                  }
                </div>
              }
              {!hidePrimaryAction &&
                ( primaryActionComponent ?
                  <div className="pointer-events-auto">{primaryActionComponent}</div>
                  :
                  <div className="pointer-events-auto">
                    <Button
                      id="primary-action-button"
                      className="sm-only:shadow-button"
                      type={ primaryActionButtonType }
                      isPending={ primaryActionButtonIsPending }
                      onClick={ () => {
                        onPrimaryAction && onPrimaryAction();
                      } }
                    >
                      {primaryActionLabel}
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface SegementSecondaryButton {
  children: React.ReactNode;
  theme?: ButtonTheme;
  onClick: ()=> void;
  buttonId?: string;
}

const SegmentSecondaryButton = ({ children, onClick, theme = 'clean',buttonId }: SegementSecondaryButton ) => (
  <Button
    id={ buttonId }
    theme={ theme }
    small
    className={ `${theme !== 'clean' ? '-mt-3 md:-mt-4' : ''}` }
    onClick={ onClick }
  >
    {children}
  </Button>
);
