import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';
import { SendCodeFormData } from '../../views/Authenticate/SendCodeForm';
import { SendEmailCodeFormData } from '../../views/Lenox/DisabilityLogin';


interface AuthState {
  access_token?: string;
  hasAuthenticated: boolean;
  hasCompletedOnboarding: boolean;
  sendCode: {
    updatedAt?: string;
    data?: SendCodeFormData | null;
    meta?: AsyncThunkMetaInterface;
    errorMessage?: string;
  };
  verifyCode: {
    updatedAt?: string;
    meta?: AsyncThunkMetaInterface;
    errorMsg?: string;
    rejectedCount: number;
  };
  sendEmailCode: {
    updatedAt?: string;
    meta?: AsyncThunkMetaInterface;
    data?: SendEmailCodeFormData | null;
    errorMessage?: string;
  };
  verifyEmailCode: {
    updatedAt?: string;
    meta?: string;
    errorMsg?: string;
  };
}

const initialState = {
  access_token: window.access_token,
  hasAuthenticated: false,
  hasCompletedOnboarding: false,
  sendCode: {
    meta: {} as AsyncThunkMetaInterface,
    data: {} as SendCodeFormData,
  },
  sendEmailCode:{
    meta: {} as AsyncThunkMetaInterface,
    data: {} as SendEmailCodeFormData,
  },
  verifyCode: {
    rejectedCount: 0,
  },
  verifyEmailCode:{},
} as AuthState;


export default initialState;
