import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import initialState from '../initial/snackbar';
import { SNACKBAR_TRANSITION_DURATION_OUT, SNACKBAR_TRANSITION_DURATION_IN, SNACKBAR_DEFAULT_DURATION } from '../../settings';
import { SnackBarProps } from '../../components/Snackbar';


export const makeSnack = createAsyncThunk(
  'snackbar/makeSnack',
  async( snack: SnackBarProps, thunkAPI ) => {

    if( !snack.uniqueId ){
      snack.uniqueId = uuid();
    }

    thunkAPI.dispatch( push( snack ));
    thunkAPI.dispatch( chewSnack( snack ));
    return snack;
  },
);

export const chewSnack = createAsyncThunk(
  'snackbar/chewSnack',
  async( snack: SnackBarProps, thunkAPI ) => {
    await new Promise( resolve => setTimeout( resolve, ( snack.duration || SNACKBAR_DEFAULT_DURATION ) + SNACKBAR_TRANSITION_DURATION_IN ));
    thunkAPI.dispatch( swallowSnack( snack ));
    return snack;
  },
);

export const swallowSnack = createAsyncThunk(
  'snackbar/swallowSnack',
  async( snack: SnackBarProps ) => {
    await new Promise( resolve => setTimeout( resolve, SNACKBAR_TRANSITION_DURATION_OUT ));
    return snack;
  },
);


const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    push( snackbar, action: PayloadAction<SnackBarProps> ){
      snackbar.items.unshift( action.payload );
    },
  },

  extraReducers: builder => {
    builder.addCase( swallowSnack.pending, ( snackbar, action ) => {
      const { arg, ...meta } = action.meta;
      const uniqueId = arg.uniqueId;
      const idx = snackbar.items.findIndex( snack => snack?.uniqueId === uniqueId );
      snackbar.items[idx].isClosing = true;
      snackbar.swallowSnack.meta = meta;
    });
    builder.addCase( swallowSnack.fulfilled, ( snackbar, action ) => {
      const { arg, ...meta } = action.meta;
      snackbar.swallowSnack.meta = meta;
      const { uniqueId } = action.payload;
      const idx = snackbar.items.findIndex( snack => snack?.uniqueId === uniqueId );
      snackbar.items.splice( idx, 1 );
    });
    builder.addCase( swallowSnack.rejected, ( snackbar, action ) => {
      const { arg, ...meta } = action.meta;
      snackbar.swallowSnack.meta = meta;
    });
  },
});

const { push } = snackbarSlice.actions;

export default snackbarSlice.reducer;
