import { ReactElement, useEffect } from 'react';

// Components
import ValuablesListView from './ValuablesListView';

// Redux
import { setUserAttributes } from '../../../store/reducers/userReducer';
import { useDispatch } from '../../../store';

// Types

export default function Valuables(): ReactElement{
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( setUserAttributes({
      'app.willValuablesFinished': true,
    }));
  }, [ dispatch ]);

  return (
    <div className="flex fixed inset-0 bg-white">
      <ValuablesListView />
    </div>
  );
}
