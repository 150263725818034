import { ReactElement, useRef } from 'react';
import ReactPlayer from 'react-player';

// Components
import VideoModal from '../../components/VideoModal';
import { VideoSourceMap } from '../../components/VideoPlayer';

// Redux
import { useDispatch, useSelector } from '../../store';
import { hideModal } from '../../store/reducers/modalReducer';
import { UserAttributes } from '../../types/UserInterface';

// Utils
import analytics from '../../utils/analytics';

export interface GlobalVideoModalProps {
  src: VideoSourceMap;
  hasViewedKey?: keyof UserAttributes;
  onSkip?: ()=> void;
  onEnded?: ()=> void;
  shouldCloseOnComplete?: boolean;
}

export default function GlobalVideoModal({ src, hasViewedKey, onSkip, onEnded, shouldCloseOnComplete = true }: GlobalVideoModalProps ): ReactElement{
  const dispatch = useDispatch();

  const player = useRef<ReactPlayer|null>( null );

  const handleExitTracking = ( trackingEventName: string ) => {
    analytics.track( trackingEventName,{
      video_name: player.current?.props.url,
      progress: player.current?.getCurrentTime(),
    });
  };

  const handleOnRetreat = () => {
    dispatch( hideModal( 'videoModal' ));
    handleExitTracking( 'Touch - Video Back' );
  };

  const handleOnSkip = onSkip
    ? () => {
      dispatch( hideModal( 'videoModal' ));
      onSkip();
      handleExitTracking( 'Touch - Video Skip' );
    }
    : undefined;

  const handleOnEnded = () => {
    onEnded && onEnded();
    shouldCloseOnComplete && dispatch( hideModal( 'videoModal' ));
  };

  return (
    <VideoModal
      isOpen={ useSelector( state => state.modal.data.videoModal.isOpen ) }
      source={ src }
      hasViewedKey={ hasViewedKey }
      onClose={ handleOnRetreat }
      onSkip={ handleOnSkip }
      onEnded={ handleOnEnded }
    />
  );
}
