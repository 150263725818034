/**
 * This hook is basically the same as `useExecutors`
 * Consider combining and taking a roleType as an arg if duplicated yet again
 */

// Redux
import { useSelector } from '../store';
import invitationsSelector from '../store/selectors/invitationsSelector';

// Components
import { SectionStates } from '../components/DocumentSectionOverview';

// Types
import Person from '../core-data-service/models/Person';
import Role from '../core-data-service/models/Role';
import Invitation from '../core-data-service/models/Invitation';
import PersonInterface from '../types/PersonInterface';
import RoleInterface from '../types/RoleInterface';

// Utils
import isDefined from '../utils/isDefined';

// Hooks
import useUserAttributes from './useUserAttributes';
import { DocumentSectionState } from './useWillSectionStatus';

const useTrustees = (): {
  trustees: PersonInterface[];
  hasBackupTrustee: boolean;
  potentialTrustees: PersonInterface[];
  potentialNewTrustees: PersonInterface[];
  roles: RoleInterface[];
  uninvitedTrusteesFirstNames: string[];
  getTrusteesSectionState: ()=> DocumentSectionState;
} => {
  const { documents: { trust: {
    hasStartedTrusteesChapter,
  } } } = useUserAttributes();

  const roles = useSelector( state =>
    state.roles.data
      .filter( role => role.name === Role.TYPE.TRUSTEE )
      .sort(( first, second ) => first.priority > second.priority ? 1 : -1 ),
  );

  const invitations = useSelector( invitationsSelector );

  const hasBackupTrustee = roles.length > 1;

  const uninvitedTrusteesFirstNames = Object.values( roles.reduce(( acc, role ) => {
    const { grantee_id, invitation_id } = role;
    const invite = invitations.find( invitation => invitation.id === invitation_id );
    const name = invite?.invitee?.name;
    const hasNotBeenInvited = invite?.status === Invitation.STATUS.CREATED;
    if( name && hasNotBeenInvited && !acc[grantee_id]) {
      acc[grantee_id] = name;
    }
    return acc;
  }, {} as {[key: string]: string}));

  const people = useSelector( state => state.people.data );

  const trustees: PersonInterface[] = roles
    .map( role => people.find( person => person.id === role.grantee_id ))
    .filter( isDefined );

  const potentialTrustees = people
    .filter( _person => {
      const person = new Person( _person );
      return Boolean( _person.relationship !== 'ME' && person.isAdult !== false );
    });

  const alreadyHaveRole = Role.allPrioritized( Role.TYPE.TRUSTEE );
  const potentialNewTrustees = potentialTrustees.filter( person => !alreadyHaveRole.find( role => role.grantee_id === person.id ));

  const getTrusteesSectionState = (): DocumentSectionState => {
    if( trustees.length > 0 && hasStartedTrusteesChapter ) {
      return { state: SectionStates.complete };
    } else if( !!hasStartedTrusteesChapter ) {
      return {
        state: SectionStates.warning,
        message: { translationKey: 'product.trust.trustee.chapter.missing.error' },
      };
    } else {
      return { state: SectionStates.default };
    }
  };

  return {
    trustees,
    hasBackupTrustee,
    potentialNewTrustees,
    potentialTrustees,
    roles,
    uninvitedTrusteesFirstNames,
    getTrusteesSectionState,
  };
};


export default useTrustees;
