import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

// Data
import initialState from '../initial/config';


export const fetchConfig = createAsyncThunk(
  'config/fetchConfig',
  async( _, thunkAPI ) => {
    const url = '/v1/config';
    return WebClientRequest
      .get( url )
      .then( response => {
        const config = response.data.data;
        thunkAPI.dispatch( updateConfig( config ));
      });
  },
);

const configSlice = createSlice({
  name: 'config',
  initialState,

  reducers: {
    updateConfig( config, action ){
      config.data = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase( fetchConfig.pending, ( config, action ) => {
      const { arg, ...meta } = action.meta;
      config.fetchConfig.meta = meta;
    });
    builder.addCase( fetchConfig.fulfilled, ( config, action ) => {
      const { arg, ...meta } = action.meta;
      config.fetchConfig.meta = meta;
    });
    builder.addCase( fetchConfig.rejected, ( config, action ) => {
      const { arg, ...meta } = action.meta;
      config.fetchConfig.meta = meta;
    });
  },

});

export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;
