import { AxiosResponse } from 'axios';

/**
 * Given a response from axios, return token from 'Authorization' header.
 */
const getAccessTokenFromResponse = ( response: AxiosResponse ): string => {
  return ( response.config?.headers?.Authorization || '' )
    .replace( 'Bearer ', '' );
};


export default getAccessTokenFromResponse;
