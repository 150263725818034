import { ReactElement } from 'react';
import Icon from '../components/Icon';

export default function Loading(): ReactElement{
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <Icon name="logoMark" className="text-forest animate-pulse--full h-12 w-12" />
      {/* below "hidden p tag causes font to be requested by browser before render and helps with CLS" */}
      <p className="sr-only">Loading <span className="font-bold">Tomorrow</span></p>
    </div>
  );
}
