import { ReactElement,  useEffect, useMemo, useState } from 'react';
import {  useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { useForm } from 'react-hook-form';

// Components
import Modal from '../../components/Modal';
import { default as PersonDetailAvatarPicker, AVATARS_BASE_URL } from './PersonDetailAvatarPicker';
import PersonDetailForm from './PersonDetailForm';
import PersonDetailPhoto from './PersonDetailPhoto';
import PersonDetailPhotoCropper from './PersonDetailPhotoCropper';
import Translation from '../../components/Translation';
import InvitationWrapper from '../../components/InvitationWrapper';

// Hooks
import useBackstack from '../../hooks/useBackstack';

// Utils
import { formatDate } from '../../../core/utils/datetimeUtils';
import { people as peopleRoute, person as personRoute } from '../../../core/routes';

// Redux
import { deletePersonPhoto, patchPerson, uploadPersonPhoto } from '../../store/reducers/peopleReducer';
import { useDispatch, useSelector } from '../../store';
import { pop } from '../../store/reducers/backstackReducer';
import { isPendingPersonPhotoUpload } from '../../store/selectors/peopleSelectors';

// Types
import PersonInterface, { AxiosPersonResponse } from '../../types/PersonInterface';
import { splitFirstAndLastName } from '../../../core/utils/stringHelpers';
import LocationState from '../../types/LocationState';
import  { RelationshipTypes } from '../../core-data-service/models/Relationship';
import { getPersonByID } from '../../utils/people/getPersonByID';
import { RoleTypes } from '../../core-data-service/models/Role';

export const PersonDetailView = (
  {
    isOpen = true,
    onSuccess,
    onClose,
    retreatRoute,
    renderedAsRoute = true, // set to false to use view outside of routing
    prohibitedRelationships,
    relationshipType,
    id,
    roleType,
  }: {
    isOpen?: boolean;
    onSuccess?: ( id: PersonInterface['id'])=> void;
    onClose?: ()=> void;
    renderedAsRoute?: boolean;
    retreatRoute?: Location['pathname'];
    prohibitedRelationships?: string;
    relationshipType?: RelationshipTypes;
    id?: PersonInterface['id'];
    roleType?: RoleTypes;
  }): ReactElement => {

  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation<LocationState>();
  const { goBack } = useBackstack( retreatRoute || location.pathname || peopleRoute.get());

  const handleOnClose = () => {
    onClose && onClose();
    if( renderedAsRoute ) {
      setIsModalOpen( false );
      setTimeout(() => {
        goBack();
      }, 300 );
    }
  };

  // Component state
  const [ stagedPhotoUpload, setStagedPhotoUpload ] = useState<File|null>( null );
  const [ stagedPhotoObjURL, setStagedPhotoObjURL ] = useState<string|undefined>( undefined );
  const [ isProcessingPhoto, setIsProcessingPhoto ] = useState<boolean>( false );
  const [ isCropping, setIsCropping ] = useState<boolean>( false );
  const [ isCropAccepted, setIsCropAccepted ] = useState<boolean>( false );
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>( isOpen );
  const [ isChoosingAvatar, setIsChoosingAvatar ] = useState<boolean>( false );
  const [ avatarURL, setAvatarURL ] = useState<string|undefined>( undefined );

  // Application state
  const isPendingPhoto = useSelector( isPendingPersonPhotoUpload ) || isProcessingPhoto;

  // Get person
  const person = useSelector( getPersonByID( id || '' ));

  // Form stuff
  const defaultValues = useMemo(
    () => {
      return {
        name: person?.name || '',
        firstName: splitFirstAndLastName( person?.name )[0]||'',
        lastName: splitFirstAndLastName( person?.name )[1]||'',
        relationship: person?.relationship || null,
        dob: formatDate( person?.dob || '' ),
        email: person?.email || '',
        phone: person?.phone || '',
        street: person?.address?.street,
        street2: person?.address?.street2,
        city: person?.address?.city,
        state: person?.address?.state,
        zip: person?.address?.zip,
      };
    },
    [ person ],
  );

  const { register, setValue, handleSubmit, getValues, reset, clearErrors, watch, formState: { errors } } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    shouldUnregister: false,
  });

  /**
   * Upload a photo to the API
   * @param photo photo to upload
   * @param person person to upload the photo to
   */
  const dispatchPhotoUpload = (
    photo: File,
    person: PersonInterface,
    onSuccess?: ()=> void,
  ) => {

    // Lets remove the photo file from the component state
    // to prevent submitting the file multiple times
    // we will leave the `photoObjURL` for display
    setStagedPhotoUpload( null );

    dispatch( uploadPersonPhoto({
      id: person.id,
      photo,
      compressionOptions: {
        onProgress: ( progress: number ) => {
          setIsProcessingPhoto( progress < 100 );
        },
      },
      onSuccess: () => {
        if( !!stagedPhotoObjURL ){
          URL.revokeObjectURL( stagedPhotoObjURL );
          onSuccess instanceof Function && onSuccess();
        }
      },
    }));
  };


  const dispatchDeletePhoto = (
    person?: PersonInterface|null,
    onSuccess?: ()=> void,
  ) => {
    if( !!person ){
      dispatch( deletePersonPhoto({ id: person.id }));
    } else {
      setAvatarURL( undefined );
      setStagedPhotoObjURL( undefined );
    }
  };

  /**
   * Do this after successfully creating a new person
   * @param person
   */
  const onPostSuccess = ( response: AxiosPersonResponse ) => {
    const person = response.data.data;

    if( !!stagedPhotoUpload ) {
      dispatchPhotoUpload( stagedPhotoUpload, person );
    }

    if( renderedAsRoute ) {
      dispatch( pop()); // remove "family/create" history from backstack
      push({
        pathname: personRoute.get({ id: person.id }),
        state: { background: location.state?.background || { pathname: peopleRoute.get() } },
      });
    }
    setIsModalOpen( false );
    if( renderedAsRoute ) {
      goBack();
    } else {
      reset(); //clean up form data
      setStagedPhotoUpload( null );
      setStagedPhotoObjURL( undefined );
      setAvatarURL( undefined );
    }
    onSuccess && onSuccess( person.id );
  };

  /**
   * Do this after successfully updating an existing person
   * @param person
   */
  const onPatchSuccess = () => {
    handleOnClose();
  };

  const onDeleteSuccess = handleOnClose;

  /**
   * Update form values after person is fetched from API
   */

  useEffect(() => {
    reset( defaultValues );
  }, [ defaultValues, reset ]);

  /**
   * Automatically PATCH `photo_url` if `avatarURL` is set on existing person
   * Then clear `avatarURL` state
   */
  useEffect(() => {
    if( !!person?.id && avatarURL ){
      dispatch( patchPerson({ id: person.id, photo_url: avatarURL }));
      setAvatarURL( undefined );
    }
  }, [ person, avatarURL, setAvatarURL, dispatch ]);

  return(
    <>
      <Modal
        onClose={ handleOnClose }
        isOpen={ isOpen || isModalOpen }
        toggleModal={ handleOnClose }
      >
        <InvitationWrapper
          inviteeId={ person?.id || '' }
        />
        {!person?.photo_url && !avatarURL &&
          <p className="mb-8"><Translation translationKey={ 'addperson.button.complete' }/></p>
        }
        <PersonDetailPhoto
          isPendingPhoto={ isPendingPhoto }
          photo_url={ avatarURL || person?.photo_url || stagedPhotoObjURL || null }
          setIsChoosingAvatar={ setIsChoosingAvatar }
          setIsCropAccepted={ setIsCropAccepted }
          setIsCropping={ setIsCropping }
          setIsProcessingPhoto={ setIsProcessingPhoto }
          setStagedPhotoObjURL={ setStagedPhotoObjURL }
          setStagedPhotoUpload={ setStagedPhotoUpload }
          dispatchDeletePhoto={ dispatchDeletePhoto }
          person={ person }
          isAvatar={ person?.photo_url?.includes( AVATARS_BASE_URL ) ?? !!avatarURL }
        />
        <PersonDetailForm
          avatarURL={ avatarURL }
          clearErrors={ clearErrors }
          errors={ errors }
          getValues={ getValues }
          handleSubmit={ handleSubmit }
          isPendingPhoto={ isPendingPhoto }
          onDeleteSuccess={ onDeleteSuccess }
          onPatchSuccess={ onPatchSuccess }
          onPostSuccess={ onPostSuccess }
          person={ person }
          register={ register }
          setValue={ setValue }
          prohibitedRelationships={ prohibitedRelationships }
          relationshipType={ relationshipType }
          roleType={ roleType }
          watch={ watch }
        />
      </Modal>
      {/**
       * These must come after main modal otherwise will not display
       * Consider refactoring this whole view into a Flow component
       */}
      <PersonDetailPhotoCropper
        dispatchPhotoUpload={ dispatchPhotoUpload }
        isCropAccepted={ isCropAccepted }
        isCropping={ isCropping }
        person={ person }
        setIsCropAccepted={ setIsCropAccepted }
        setIsCropping={ setIsCropping }
        setStagedPhotoObjURL={ setStagedPhotoObjURL }
        setStagedPhotoUpload={ setStagedPhotoUpload }
        stagedPhotoObjURL={ stagedPhotoObjURL }
        stagedPhotoUpload={ stagedPhotoUpload }
        setIsProcessingPhoto={ setIsProcessingPhoto }
        renderedAsRoute={ renderedAsRoute }
      />
      <PersonDetailAvatarPicker
        setAvatarURL={ setAvatarURL }
        isOpen={ isChoosingAvatar }
        setIsChoosingAvatar={ setIsChoosingAvatar }
      />
    </>
  );
};


export default PersonDetailView;
