import { ReactElement, ReactNode, useRef } from 'react';
import ReactPlayer from 'react-player';

// Components
import SegmentView from '../../components/SegmentView';
import VideoPlayer, { VideoSourceMap } from '../../components/VideoPlayer';

// Utils
import analytics from '../../utils/analytics';
interface Props {
  onComplete: ()=>  void;
  onRetreat: ()=> void;
  autoPlay?: boolean;
  title: string | ReactNode;
  src: VideoSourceMap;
  hidePrimaryAction?: boolean;
  hideSecondaryAction?: boolean;
}

export default function VideoSegmentView({ autoPlay = false, hideSecondaryAction, title, src, onComplete, onRetreat, hidePrimaryAction }: Props ): ReactElement{
  const player = useRef<ReactPlayer|null>( null );

  const handleExitTracking = ( trackingEventName: string ) => {
    analytics.track( trackingEventName,{
      video_name: player.current?.props.url,
      progress: player.current?.getCurrentTime(),
    });
  };

  const handleOnRetreat = () => {
    onRetreat();
    handleExitTracking( 'Touch - Video Back' );
  };

  const handleOnSecondaryAction = () => {
    onComplete();
    handleExitTracking( 'Touch - Video Skip' );
  };

  return (
    <SegmentView
      contentClassName="bg-black"
      full
      title={ title }
      onRetreat={ handleOnRetreat }
      onSecondaryAction={ handleOnSecondaryAction }
      secondaryActionChildren={ 'Skip' }
      secondaryButtonTheme="light"
      hidePrimaryAction={ hidePrimaryAction }
      hideSecondaryAction={ hideSecondaryAction }
      hideLogo={ true }
    >
      <VideoPlayer
        autoPlay={ autoPlay }
        forwardRef={ player }
        onEnded={ onComplete }
        source={ src }
      />
    </SegmentView>
  );
}

