import { cloneElement, ReactElement, useRef } from 'react';

export type OrderedComponentsListProps = {
  items: [number, ReactElement][];
  shouldUpdateOnRender?: boolean;
}

const OrderedComponentsList = ({ items, shouldUpdateOnRender = true }: OrderedComponentsListProps ): ReactElement => {
  const itemsOrder = items.map( item => item[0]);
  const itemsOrderOnMount = useRef<number[]>( itemsOrder );
  const finalOrder = shouldUpdateOnRender ? itemsOrder : itemsOrderOnMount.current;
  const result: ReactElement[] = [];
  items.forEach(( item, i ) =>
    result[finalOrder[i]] = cloneElement( item[1], { key:`orderedItem-${i}` }),
  );
  return <>{result.flat()}</>;
};

export default OrderedComponentsList;
