import { useSelector } from '../store';
import applicationsSelector from '../store/selectors/userApplicationsSelector';
import ApplicationInterface, { ApplicationDataInterface } from '../types/ApplicationInterface';
const LENOX_FORM_NAME = 'LenoxDisabilityInsuranceForm';

const useLenoxApplication = (): {lenoxApplication: ApplicationInterface; lenoxAdvisor: ApplicationDataInterface['relationship_manager']; isApplicationFinished: boolean; isApplicationApproved: boolean } =>{
  const applications = useSelector( applicationsSelector );
  const application =  applications.filter( application=> {
    return application.form_name === LENOX_FORM_NAME;
  });
  const lenoxApplication = application[0];
  const lenoxAdvisor = lenoxApplication.data.relationship_manager;
  const isApplicationFinished = [ 'submitted' , 'pending' , 'approved' , 'declined' , 'ineligible' , 'duplicate' , 'bind' , 'processing' , 'in force' , 'referred' ].includes( lenoxApplication.status );
  const isApplicationApproved = applicationApproved( lenoxApplication );
  return {
    lenoxApplication,
    lenoxAdvisor,
    isApplicationFinished,
    isApplicationApproved,
  };


};

export default useLenoxApplication;

const applicationApproved = ( application: ApplicationInterface ) =>{

  const knockoutQuestion = [ 'sickness_or_injury_a', 'sickness_or_injury_b','other_coverage' ];
  if( +application.answers['hours_worked'] <35 ){
    return false;
  }
  if( application.answers['us_citizen'] ==='false' ){
    return false;
  }
  let isApproved = true;

  knockoutQuestion.forEach( value => {
    if ( application.answers[value] === 'true' ){
      isApproved = false;
    }
  });
  return isApproved;
};

