import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Types
import DescriptiveLink, { DescriptiveLinkProps } from './DescriptiveLink';
import { LocationDescriptor } from 'history';
interface InternalDescriptiveLinkProps extends DescriptiveLinkProps {
  linkLocationObject: LocationDescriptor;
  onClick?: ()=> void;
}

export default function InternalDescriptiveLink({ className, linkLocationObject, descriptionKey, labelKey, onClick }: InternalDescriptiveLinkProps ): ReactElement{
  return (
    <Link to={ linkLocationObject } className={ `${className}` } onClick={ onClick } >
      <DescriptiveLink descriptionKey={ descriptionKey } labelKey={ labelKey } />
    </Link>
  );
}
