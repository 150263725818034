import { useEffect } from 'react';
import { useDispatch, useSelector } from '../store';
import { postExperimentImpression } from '../store/reducers/experimentsReducer';
import ExperimentInterface from '../types/ExperimentInterface';

export const ACTIVE_EXPERIMENTS = {
  DASHBOARD_NOTIFICATION_EXPERIMENT_KEY: 'invites_prompt_on_dashboard',
  // inviteModal.css, PaperPlan_blue.svg, PaperPlane_Yellow.svg, ContactCard.svg, Ticket_clipping.svg
  // were created for the invites_modal_visual_variations experiment.
  INVITES_MODAL_VISUAL_VARIATIONS_EXPERIMENT_KEY: 'invites_modal_visual_variations',
} as const;

type ExperimentKeys = keyof typeof ACTIVE_EXPERIMENTS;

export interface IUseExperiements {
  dashboardNotificationExperiment: {
    isVariantEnabled: boolean;
  };
  inviteModalExperiment: {
    isVariantAEnabled: boolean;
    isVariantBEnabled: boolean;
    isVariantCEnabled: boolean;
  };
}

const isExperimentVariantEnabled = ( experiments: ExperimentInterface[], experimentKey: ExperimentKeys, variant: string ) => {
  return experiments.find( experiment => experiment.key === ACTIVE_EXPERIMENTS[experimentKey])?.variant.key === variant;
};

const useExperiments = ( key: ExperimentKeys ): IUseExperiements => {
  const experiments = useSelector( state => state.experiments.data );
  const dispatch = useDispatch();

  useEffect(() => {
    const variantId = experiments.find( experiment => experiment.key === ACTIVE_EXPERIMENTS[key])?.variant.id;
    if( variantId ) {
      dispatch( postExperimentImpression({ variantId }));
    }
  },[ dispatch, experiments, key ]);

  return {
    dashboardNotificationExperiment: {
      isVariantEnabled: isExperimentVariantEnabled( experiments, 'DASHBOARD_NOTIFICATION_EXPERIMENT_KEY', 'variant' ),
    },
    inviteModalExperiment: {
      isVariantAEnabled: isExperimentVariantEnabled( experiments, 'INVITES_MODAL_VISUAL_VARIATIONS_EXPERIMENT_KEY', 'variant_a' ),
      isVariantBEnabled: isExperimentVariantEnabled( experiments, 'INVITES_MODAL_VISUAL_VARIATIONS_EXPERIMENT_KEY', 'variant_b' ),
      isVariantCEnabled: isExperimentVariantEnabled( experiments, 'INVITES_MODAL_VISUAL_VARIATIONS_EXPERIMENT_KEY', 'variant_c' ),
    },
  };
};

export default useExperiments;
