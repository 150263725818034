import { ReactElement } from 'react';

// Components
import { Button } from './Button';
import Icon from './Icon';
import Translation from './Translation';

// Redux
import { useDispatch } from '../store';
import { TranslationKeys } from '../store/initial/translations';
import { showVideoModal } from '../store/reducers/modalReducer';

// Types
import { VideoSourceMap } from './VideoPlayer';

export interface IOpenVideoButtonProps {
  className?: string;
  videoSrc: VideoSourceMap;
  labelKey: TranslationKeys;
  onEnded?: ()=> void;
}

function OpenVideoButton({ className = '', videoSrc, labelKey, onEnded }: IOpenVideoButtonProps ): ReactElement{
  const dispatch = useDispatch();
  return (
    <Button
      className={ `${className} mt-6 w-max` }
      theme="clean-secondary"
      onClick={ () => dispatch( showVideoModal({ src: videoSrc, onEnded })) }
    >
      <div className="flex items-center">
        <Icon className="text-salamander mr-2" name="play-circle" />
        <Translation translationKey={ labelKey } />
      </div>
    </Button>
  );
}

export default OpenVideoButton;
