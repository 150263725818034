import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';
import InvitePageInterface  from './../../types/InvitePageInterface';

const initialState = {
  data: {
    inviteLandingPages: [] as Array<InvitePageInterface>,
  },
  // for extra reducers
  fetchInvitePageData: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
