import { FC, ReactNode } from 'react';

interface Props {
  label: string | ReactNode;
  name: string;
  className?: string;
  isHidden?: boolean;
  isLarge?: boolean;
}

const FieldLabel: FC<Props> = ({ children, className, isHidden = false, isLarge, label, name }) => {
  return (
    <div
      className={ `
        field
        mb-4
        flex flex-col
        ${isHidden ? 'field--labelHidden' : ''}
        ${isLarge ? 'field--large' : ''}
        ${className}
        `
      }
    >
      <label
        htmlFor={ name }
        className={ `
          field-label
          text-base
          text-black
          pointer-events-none
          whitespace-nowrap
          font-bold
          mb-1
          ${isHidden ? 'sr-only' : ''}
        ` }
      >
        {label}
      </label>
      {children}
    </div>
  );
};

export default FieldLabel;
