import React, { FC, ReactNode } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { TwoOrMoreArray } from '../types/TwoOrMoreArray';
import FieldError from './FieldError';
import Icon from './Icon';


export interface RadioGroupProps extends RadioGroupVisualProps {
  className?: string;
  items: TwoOrMoreArray<RadioGroupItemProps>;
  itemsClassName?: string;
  autoFocus?: boolean;
  label: ReactNode;
  name: string;
  errors: UseFormMethods['errors'];
  register: UseFormMethods['register'];
  showLabel?: boolean;
  itemsFullWidth?: boolean;
  disabled?: boolean;
  wrapItemChildren?: boolean;
}

export interface RadioGroupVisualProps {
  className?: string;
  items: TwoOrMoreArray<RadioGroupItemProps>;
  label: ReactNode;
  name: string;
  showLabel?: boolean;
}

export interface RadioGroupItemProps {
  label: string|ReactNode;
  value: string;
}

export interface RadioItemProps extends RadioGroupItemProps {
  autoFocus?: boolean;
  id: string;
  className?: string;
  alignIconStart?: boolean;
  wrapItemChildren?: boolean;
  inputRef: UseFormMethods['register'];
  name: string;
  disabled?: boolean;
}

export const Radio: FC<RadioItemProps> = ({
  autoFocus,
  className = '',
  alignIconStart = false,
  id,
  inputRef,
  label,
  name,
  value,
  disabled,
  wrapItemChildren,
}) => {

  const contentWrapperArgs = wrapItemChildren ? {
    tag: 'div', attrs: { className: 'radio-item-content-wrapper' },
  } : {
    tag: React.Fragment, attrs: undefined,
  };

  const radioContent = React.createElement(
    contentWrapperArgs.tag,
    contentWrapperArgs.attrs,
    <>
      <div
        className="
              radio-check
              mr-2
              h-8
              w-8
              rounded-full
              border
              border-initialFieldBorder
              flex
              justify-center
              items-center
            "
      >
        <Icon name="check"/>
      </div>
      <span className={ alignIconStart ? 'mt-[6px]' : '' }>
        {label}
      </span>
    </>,
  );

  return (
    <div className={ `radio ${disabled ? 'radio--disabled' : ''} ${className}` }>
      <label
        className={ `cursor-pointer flex
        ${alignIconStart ? 'items-start' : 'items-center'}
        ${wrapItemChildren ? 'h-full' : ''}
        ` }
        htmlFor={ id }
      >
        <input
          className="opacity-0 h-0 w-0 absolute"
          type="radio"
          name={ name }
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={ autoFocus }
          value={ value }
          id={ id }
          ref={ inputRef }
          disabled={ disabled }
        />
        {radioContent}
      </label>
    </div>
  );
};


export const RadioGroup: FC<RadioGroupProps> = ({
  autoFocus,
  className='',
  items,
  itemsClassName='',
  label,
  name,
  register,
  errors,
  showLabel = true,
  itemsFullWidth = false,
  wrapItemChildren = false,
  disabled = false,
}) => {

  // Add needed props
  const itemsModified = items.map( item => Object({
    ...item,
    errors,
    inputRef: register,
    name,
  }));
  const fieldError = errors[name];
  return (
    <fieldset className={
      `radioGroup
      ${ disabled ? 'opacity-50' : ''}
      ${ className }
      `
    }
    >
      <legend className={ `mb-2 font-bold text-black ${showLabel ? '' : 'sr-only'}` }>{label}</legend>
      <div className={ `radio-items-wrapper flex gap-8 ${itemsFullWidth ? 'flex-col' : ''}` }>
        {itemsModified.map(( item: RadioItemProps, idx: number ) => {
          const id = `${name}-radio-${idx}`;
          return(
            <Radio
              { ...item }
              id={ id }
              key={ id }
              name={ name }
              className={ itemsClassName }
              alignIconStart={ itemsFullWidth }
              wrapItemChildren={ wrapItemChildren }
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={ autoFocus && idx === 0 }
              disabled={ disabled }
            />
          );
        })}
      </div>
      {!!fieldError && !disabled &&
        <FieldError
          className="mt-5"
          errorMessage={ fieldError.message }
        />
      }
    </fieldset>
  );
};
