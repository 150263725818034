import styled, { keyframes } from 'styled-components';


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface FadeInProps {
  duration: number;
}

export const FadeIn = styled.div`
  opacity: 1;
  animation-name: ${fadeIn};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: ${( p: FadeInProps ) => 0.1 * ( p.duration + 1 )}s;
`;

export default FadeIn;
