const healthcareDirectiveRoutes = [
  /**
   * Documents - Healthcare Directive Intro
   */
  {
    key: 'healthcare_directive_intro',
    name: 'Healthcare Directive Intro',
    path: '/documents/healthcare-directive/intro',
    template: 'index.html',
  },

  /**
   * Documents - Healthcare Directive Intro
   */
  {
    key: 'healthcare_directive_address',
    name: 'Healthcare Directive Address',
    path: '/documents/healthcare-directive/address',
    template: 'index.html',
  },

  /**
   * Documents - Healthcare Directive Status
   */
  {
    key: 'healthcare_directive',
    name: 'Your Healthcare Directive',
    path: '/documents/healthcare-directive',
    template: 'index.html',
  },

  /**
   * Documents - Healthcare Directive, Agent
   */
  {
    key: 'healthcare_directive_agent',
    name: 'Your Healthcare Directive Agent',
    path: '/documents/healthcare-directive/agent',
    template: 'index.html',
  },

  /**
   * Documents - Healthcare Directive Wishes
   */
  {
    key: 'healthcare_directive_wishes',
    name: 'Your Healthcare Directive Wishes',
    path: '/documents/healthcare-directive/wishes',
    template: 'index.html',
  },

  /**
   * Documents - Healthcare Directive Review
   */
  {
    key: 'healthcare_directive_review',
    name: 'Review Your Healthcare Directive',
    path: '/documents/healthcare-directive/review',
    template: 'index.html',
  },
];

export default healthcareDirectiveRoutes;
