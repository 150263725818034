import { ReactElement } from 'react';
import Translation from '../../components/Translation';
import PersonIcon from '../People/PersonIcon';

// Models
import Person from '../../core-data-service/models/Person';
import { TranslationKeys } from '../../store/initial/translations';
import PersonInterface from '../../types/PersonInterface';

interface Props {
  person: PersonInterface;
  idx: number;
  roleTranslationKey: TranslationKeys;
}

export default function WillRoleSummaryItem({ person, idx, roleTranslationKey }: Props ): ReactElement{
  const PersonObj = new Person( person );
  return (
    <div
      className={ `flex items-center ${idx !== 0 ? '-mt-4' : ''}` }
    >
      <div className="w-circleIcon_sm flex-none">
        <PersonIcon
          className="aspect-w-1 aspect-h-1"
          photo_url={ person.photo_url }
          personName={ person.name }
          onImageLoadDuration={ 1 }
          alt="avatar"
        />
      </div>
      <div className="ml-4">
        {/* TODO This should be a whole translation */}
        { PersonObj.firstName } is designated as your{' '}
        {idx > 0 ?
          `backup${idx > 1 ? ` #${idx}` : ''}`
          :
          'primary'
        } <Translation translationKey={ roleTranslationKey }/>.
      </div>
    </div>
  );
}
