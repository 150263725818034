import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import ActionContainer from '../../../components/ActionContainer';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';
import PropertyListItem from '../PropertyListItem';
import OpenVideoButton from '../../../components/OpenVideoButton';
import PropertySubtypePicker from '../PropertySubtypePicker';

// Hooks
import useAccounts from '../../../hooks/useAccounts';
import useBackstack from '../../../hooks/useBackstack';

// Models
import Property, { PropertySubtype, PropertyType } from '../../../core-data-service/models/Property';

// Routes
import {
  properties as propertiesRoute,
  trust_review as trustReviewRoute,
} from '../../../../core/routes';

// Utils
import { ACCOUNTS_URL_NAMESPACE as urlNamespace } from '../../../../core/constants';
import analytics from '../../../utils/analytics';
import { generateQueryString } from '../../../utils/urlUtils';

export default function AccountsListView(): ReactElement{
  const { push } = useHistory();
  const { goBack } = useBackstack( trustReviewRoute.get());

  const { accounts, accountsCategories } = useAccounts();
  const [ category, setCategory ] = useState<PropertyType|undefined>();

  const handleSetSubtype = ( subtype: PropertySubtype ) => {
    push({
      pathname: propertiesRoute.get({ id: 'create', urlNamespace }),
      search: generateQueryString({ category, subtype }),
    });
  };

  return (
    <SegmentView
      title={ <Translation translationKey="screen.accounts.title" /> }
      onRetreat={ goBack }
      primaryActionLabel={ <Translation translationKey="(button)done" /> }
      onPrimaryAction={ () => {
        analytics.track( 'Touch - Finish Accounts' );
        push( trustReviewRoute.get());
      } }
      subtitle={
        <OpenVideoButton
          labelKey="accounts.video_button"
          videoSrc={{ portrait: 'video.trust.funding', landscape: 'video.trust.funding.landscape' }}
        />
      }
    >
      <div className="md:-m-12 -m-8">
        {/* REFACTOR -  PROPERTY LIST VIEW */}
        {accountsCategories.map(( category, idx ) => {
          return(
            <ActionContainer
              key={ category.value }
              header={ <Translation translationKey={ Property.DISPLAY_KEY[category.value] } /> }
              className="
                py-6
                px-8
                border-t
                border-grey
                first:mt-12
                md:px-12
                md:first:border-none
                md:first:mt-2
              "
              primaryAction={ () => setCategory( category.value ) }
              primaryActionLabel={ <Translation translationKey="form.actions.create.label" /> }
            >
              <ul className="list-none">
                {
                  accounts
                    .filter( account => account.type === category.value )
                    .map(({ name, subtype, id, photo_url, type }, idx ) => {
                      return (
                        <PropertyListItem
                          className="first:mt-2 py-1"
                          key={ id }
                          title={ name || '' }
                          subhead={ <Translation translationKey={ Property.DISPLAY_KEY[subtype] } />  }
                          image={{
                            src: photo_url || Property.getIcon( subtype ),
                            alt: `${subtype} graphic`,
                          }}
                          route={{
                            pathname: propertiesRoute.get({ id, urlNamespace }),
                            search: generateQueryString({ type,subtype }),
                          }}
                        />
                      );
                    })}
              </ul>

            </ActionContainer>
          );
        })}
      </div>

      <PropertySubtypePicker
        onClose={ () => setCategory( undefined ) }
        isOpen={ !!category }
        category={ category }
        onItemSelect={ handleSetSubtype }
      />
    </SegmentView>
  );
}
