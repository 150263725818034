import { ReactElement } from 'react';

// Components
import CurrencyField from '../../../../components/CurrencyField';
import TextField from '../../../../components/TextField';
import Translation from '../../../../components/Translation';

// Hooks
import useTranslations from '../../../../hooks/useTranslations';

// Utils
import { PropertyDetailFormInterface } from '../../../../types/Property/PropertyDetailFormInterface';
import { fieldRequired } from '../../../../formValidators/fieldRequired';

export default function InsurancePropertyForm({
  control,
  errors,
  clearErrors,
  getValues,
  register,
  setValue,
}: PropertyDetailFormInterface ): ReactElement{

  const { getTranslation } = useTranslations();

  const useFormProps = {
    control,
    register,
    setValue,
    errors,
    getValues,
    clearErrors,
  };

  return(
    <>
      <TextField { ...useFormProps }
        name="name"
        label={ <Translation translationKey="property.field.name.label" /> }
        required={ fieldRequired( getTranslation( 'property.field.name.required' )) }
        autoComplete="off"
        className="mb-8"
      />

      <CurrencyField
        errors={ errors }
        label={ <Translation translationKey="form.property.insurance.field.coverage.label" /> }
        name="value"
        control={ control }
        className="mb-8"
      />

      <TextField { ...useFormProps }
        name="term"
        type="number"
        label={ <Translation translationKey="form.property.insurance.field.term.label" /> }
        autoComplete="off"
        className="mb-8"
        placeholder={ getTranslation( 'form.property.insurance.field.term.placeholder' ) }
        inputMode="numeric"
        validate={ e => {
          if( !e.length ) {
            return true;
          }
          const value = Number( e );
          if( value && value >= 0 && value <= 256 ) {
            return true;
          }else {
            return getTranslation( 'form.property.insurance.field.term.error' );
          }
        }
        }
      />

      <CurrencyField
        errors={ errors }
        label={ <Translation translationKey="form.property.insurance.field.premium.label" /> }
        name="purchase_price"
        control={ control }
        className="mb-8"
      />
    </>
  );
}

