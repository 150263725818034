import InheritanceInterface from '../../types/InheritanceInterface';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  data: [] as Array<InheritanceInterface>,
  // for extra reducers
  patchInheritance: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postInheritance: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchInheritances: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
