import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Types
import PersonInterface from '../../types/PersonInterface';

// Utils
import { person as personRoute } from '../../../core/routes';

// Redux
import { useSelector } from '../../store';
import { isFetchingPeople } from '../../store/selectors/peopleSelectors';

// Components
import { Spinner } from '../../components/Spinner';
import SegmentView from '../../components/SegmentView';
import LocationState from '../../types/LocationState';

import { people as peopleRoute } from '../../../core/routes';
import PeopleListItem from './PeopleListItem';
import useBackstack from '../../hooks/useBackstack';
import Translation from '../../components/Translation';
import useMissingPersonData from '../../hooks/useMissingPersonData';
import Relationship from '../../core-data-service/models/Relationship';
import HorizontalRule from '../../components/HorizontalRule';
import AddButton from '../../components/AddButton';

export const CreatePerson = {
  name: 'Add a family member',
  id: 'create',
} as PersonInterface;


export const PeopleListView: FC<{
  people: PersonInterface[];
  title?: string;
}> = ({
  title = <Translation translationKey={ '(lens)family(title)' }/>,
  people,
}) => {

  const location = useLocation<LocationState>();
  const isFetching = useSelector( isFetchingPeople );
  const { goBack } = useBackstack();
  const { push } = useHistory();

  const createPerson = () => push({
    pathname: personRoute.get({ id: 'create' }),
    state: { background: location || { pathname: peopleRoute.get() } },
  });

  const otherPeople: PersonInterface[] = [];
  const family: PersonInterface[] = [];

  people.forEach( person => {
    if( Relationship.TYPE.NOT_FAMILY.includes( person.relationship )) {
      otherPeople.push( person );
    } else {
      family.push( person );
    }
  });

  const hasFamily = family.length > 0;
  const hasOtherPeople = otherPeople.length > 0;

  return(
    <SegmentView
      title={ title }
      onRetreat={ goBack }
      hidePrimaryAction={ true }
    >
      <AddButton
        onClick={ createPerson }
        iconName="addPerson"
        className="mb-4"
        labelTranslationKey="screen.myFamilyList.addButton"
      />
      {
        isFetching ? <Spinner isSpinning /> :
          <>
            <RenderPeopleItems people={ family } />

            {hasOtherPeople && hasFamily &&
              <HorizontalRule className="mt-8 mb-4" />
            }

            {hasOtherPeople &&
              <>
                <h3 className="font-bold mb-4">
                  <Translation translationKey="screen.myFamilyList.category.others.title"/>
                </h3>
                <RenderPeopleItems people={ otherPeople } />
              </>
            }
          </>
      }
    </SegmentView>
  );
};

const RenderPeopleItems = ({ people }: {people: PersonInterface[]}) => {
  const { peopleMissingData }= useMissingPersonData();
  const { push } = useHistory();
  const location = useLocation<LocationState>();

  if( !people.length ) {
    return <></>;
  }

  return (
    <>
      {people
        .map( person => {
          const personDetailLocation = {
            pathname: personRoute.get({ id: person.id }),
            state: { background: location },
          };
          return (
            <PeopleListItem key={ person.id }
              className={ 'mb-4 last:mb-0' }
              person={ person }
              needsData={ peopleMissingData.includes( person ) }
              primaryAction={ () => push( personDetailLocation ) }
              primaryActionShouldFillContainer={ true }
            />
          );
        })
      }
    </>
  );
};

export default PeopleListView;
