/**
 * Return javascript date object + x number of days
 * @param {Number} days
 * @returns {Date}
 */
export const getFutureDate = ( days: number ): Date => {
  const now = new Date();
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + days,
  );
};

export const getAgeFromDate = ( date: Date ): number => {
  const yearInMs = 3.15576e+10; // year = 365.25 days (account for leap years)
  return Math.floor(( new Date().getTime() - date.getTime()) / yearInMs );
};

export const isAdultFromDate = ( date: Date ): boolean => {
  return getAgeFromDate( date ) >= 18;
};

export const isAdultFromAge = ( age: number ): boolean => {
  return age >= 18;
};

export const isDateInPast = ( date: Date ): boolean => {
  const dateTimestamp = date.getTime();
  const nowTimeInMilliseconds = new Date().getTime();
  return dateTimestamp < nowTimeInMilliseconds;
};

export const formatDate = ( isoDateString?: string | null ): string => {
  if( !!isoDateString ) {
    return new Date( isoDateString ).toLocaleDateString( 'en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    });
  } return '';
};

export const sanitizeDate = ( isoDateString?: string | null ): string | null => {
  if( !!isoDateString ){
    return new Date( isoDateString ).toISOString().split( 'T' )[0];
  }
  return null;
};
