import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '..';

// Utils
import { sanitizeDate } from '../../../core/utils/datetimeUtils';
import { trimWhitespace } from '../../../core/utils/stringHelpers';
import WebClientRequest, { WebClientRequestHeaders } from '../../core-data-service/WebClientRequest';
import PersonInterface, { AxiosPeopleResponse, AxiosPersonResponse, NonNormalizedPersonInterface } from '../../types/PersonInterface';
import compressImage, { ImageCompressionOptions } from '../../utils/compressImage';

// Data
import initialState from '../initial/people';
import { updateInvitations } from './invitationsReducer';

type PersonFormData = {
  relationship?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  dob?: string | null;
  gender?: string | null;
  photo_url?: string | null;
  appearance?: string | null;
  height?: number | null;
  weight?: number | null;
  credit_rating?: string | null;
  health_rating?: string | null;
  tobacco?: boolean | null;
  address?: {
    zip?: string | null;
    state?: string | null;
  };
  clients?: PersonInterface[]|null;
}

interface IFetchPeople {
  onSuccess?: ( response: AxiosPeopleResponse )=> void;
}

interface IFetchPerson {
  id: string;
  onSuccess?: ( response: AxiosPersonResponse )=> void;
  onError?: ( error: string )=> void;
}

interface IPostPerson extends PersonFormData {
  onSuccess?: ( response: AxiosPersonResponse )=> void;
  onError?: ( error: string )=> void;
}

interface IPatchPerson extends PersonFormData {
  id?: string;
  onSuccess?: ( response: AxiosPersonResponse )=> void;
  onError?: ( error: string )=> void;
}

interface IDeletePerson {
  onSuccess?: ( response: AxiosResponse )=> void;
  onError?: ( error: string )=> void;
  id: string;
}

interface IUploadPersonPhoto {
  onSuccess?: ( response: AxiosPersonResponse )=> void;
  onError?: ( error: string )=> void;
  id: string;
  photo: File;
  compressionOptions: ImageCompressionOptions;
}

interface IDeletePersonPhoto {
  onSuccess?: ( response: AxiosPersonResponse )=> void;
  onError?: ( error: string )=> void;
  id: string;
}

const normalize = ({ invitations, ...data }: NonNormalizedPersonInterface ) => Object({
  ...data,
  invitation_ids: invitations.map( invitation => invitation.id ),
});


const sanitizePersonData = ( data: PersonFormData ) => {
  if( !!data.dob ){
    data.dob = sanitizeDate( data.dob );
  }

  if( !!data.name ){
    data.name = trimWhitespace( data.name );
  }

  return data;
};

export const fetchPeople = createAsyncThunk(
  'people/fetchPeople',
  async( args: IFetchPeople | void, thunkAPI ) => {
    const url = '/v1/people';
    return WebClientRequest
      .get( url )
      .then(( response: AxiosPeopleResponse ) => {
        const people = response.data.data.map( normalize );
        // const invites = people.map( person => person.invitations ).flat();

        thunkAPI.dispatch( updatePeople( people ));
        // Could be a bug but these are typically fetched only on init
        // thunkAPI.dispatch( updateInvitations( invites ));
        args && args.onSuccess && args.onSuccess( response );
      });
  },
);

export const fetchPerson = createAsyncThunk(
  'people/fetchPerson',
  async({ id, onSuccess, onError }: IFetchPerson, thunkAPI ) => {
    const url = `/v1/people/${id}`;
    return WebClientRequest
      .get( url )
      .then(( response: AxiosPersonResponse ) => {
        thunkAPI.dispatch( updatePerson( normalize( response.data.data )));
        thunkAPI.dispatch( updateInvitations( response.data.data.invitations ));
        onSuccess && onSuccess( response );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);



export const patchPerson = createAsyncThunk(
  'people/patchPerson',
  async({ id, onSuccess, onError, ...data }: IPatchPerson, thunkAPI ) => {
    const url = `/v1/people/${id}`;
    return WebClientRequest
      .patch( url, sanitizePersonData( data ))
      .then(( response: AxiosPersonResponse ) => {
        thunkAPI.dispatch( updatePerson( normalize( response.data.data )));
        thunkAPI.dispatch( updateInvitations( response.data.data.invitations ));
        onSuccess && onSuccess( response );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);


export const postPerson = createAsyncThunk(
  'people/postPerson',
  async({ onSuccess, onError, ...data }: IPostPerson, thunkAPI ) => {

    const url = '/v1/people';
    return WebClientRequest
      .post( url, sanitizePersonData( data ))
      .then(( response: AxiosPersonResponse ) => {
        thunkAPI.dispatch( updatePerson( normalize( response.data.data )));
        thunkAPI.dispatch( updateInvitations( response.data.data.invitations ));
        onSuccess && onSuccess( response );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);

export const deletePerson = createAsyncThunk<
WebClientRequest,
IDeletePerson,
{state: RootState}
>(
  'people/deletePerson',
  async({ onSuccess, onError, id }: IDeletePerson, thunkAPI ) => {
    const url = `/v1/people/${id}`;

    return WebClientRequest
      .delete( url )
      // 204 Empty
      .then(( response: AxiosResponse ) => {
        thunkAPI.dispatch( destroyPerson({ id }));
        onSuccess && onSuccess( response );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);

export const uploadPersonPhoto = createAsyncThunk(
  'people/uploadPersonPhoto',
  async({ id, onSuccess, onError, photo, compressionOptions }: IUploadPersonPhoto, thunkAPI ) => {

    const url = `/v1/people/${id}/photo`;
    const headers: WebClientRequestHeaders = { 'Content-Type': 'multipart/form-data' };

    return compressImage( photo, compressionOptions )
      .then( compressedPhoto => {

        // Build form data
        const data = new FormData();
        data.append( 'photo', compressedPhoto );

        return WebClientRequest
          .post( url, data, headers )
          .then(( response: AxiosPersonResponse ) => {
            thunkAPI.dispatch( updatePerson( normalize( response.data.data )));
            onSuccess && onSuccess( response );
          })
          .catch( error => {
            onError && onError( error );
          });
      });
  },
);

export const deletePersonPhoto = createAsyncThunk(
  'people/deletePersonPhoto',
  async({ id, onError, onSuccess }: IDeletePersonPhoto, thunkAPI ) => {
    const url = `/v1/people/${id}/photo`;
    return WebClientRequest
      .delete( url )
      .then(( response: AxiosPersonResponse ) => {
        thunkAPI.dispatch( updatePerson( normalize( response.data.data )));
        onSuccess && onSuccess( response );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);



const peopleSlice = createSlice({
  name: 'people',
  initialState,

  reducers: {
    updatePeople( people, action: PayloadAction<PersonInterface[]> ){
      if( people.data.length === 0 ){
        people.data = action.payload;
      } else {
        action.payload.forEach( person => {
          const idx = people.data.findIndex( xPerson => xPerson.id === person.id );
          if( idx >= 0 ){
            people.data[idx] = person;
          } else {
            people.data.push( person );
          }
        });
      }
    },

    updatePerson( people, action: PayloadAction<PersonInterface> ){
      const payload = action.payload;
      const idx = people.data.findIndex( person => person.id === payload.id );
      if( idx >= 0 ){
        people.data[idx] = payload;
      } else {
        people.data.push( payload );
      }
    },

    destroyPerson( people, action: PayloadAction<{id: string}> ){
      const payload = action.payload;
      const idx = people.data.findIndex( person => person.id === payload.id );
      if( idx >= 0 ){
        people.data.splice( idx, 1 );
      }
    },
  },

  extraReducers: builder => {

    // fetchPeople.<status>
    builder.addCase( fetchPeople.pending, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.fetchPeople.meta = meta;
    });
    builder.addCase( fetchPeople.fulfilled, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.fetchPeople.meta = meta;
    });
    builder.addCase( fetchPeople.rejected, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.fetchPeople.meta = meta;
    });

    // fetchPerson.<status>
    builder.addCase( fetchPerson.pending, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.fetchPerson.meta = meta;
    });
    builder.addCase( fetchPerson.fulfilled, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.fetchPerson.meta = meta;
    });
    builder.addCase( fetchPerson.rejected, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.fetchPerson.meta = meta;
    });

    // patchPerson.<status>
    builder.addCase( patchPerson.pending, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.patchPerson.meta = meta;
    });
    builder.addCase( patchPerson.fulfilled, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.patchPerson.meta = meta;
    });
    builder.addCase( patchPerson.rejected, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.patchPerson.meta = meta;
    });

    // postPerson.<status>
    builder.addCase( postPerson.pending, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.postPerson.meta = meta;
    });
    builder.addCase( postPerson.fulfilled, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.postPerson.meta = meta;
    });
    builder.addCase( postPerson.rejected, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.postPerson.meta = meta;
    });

    // uploadPersonPhoto.<status>
    builder.addCase( uploadPersonPhoto.pending, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.uploadPersonPhoto.meta = meta;
    });
    builder.addCase( uploadPersonPhoto.fulfilled, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.uploadPersonPhoto.meta = meta;
    });
    builder.addCase( uploadPersonPhoto.rejected, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.uploadPersonPhoto.meta = meta;
    });

    // deletePerson.<status>
    builder.addCase( deletePerson.pending, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.deletePerson.meta = meta;
    });
    builder.addCase( deletePerson.fulfilled, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.deletePerson.meta = meta;
    });
    builder.addCase( deletePerson.rejected, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.deletePerson.meta = meta;
    });

    // deletePersonPhoto.<status>
    builder.addCase( deletePersonPhoto.pending, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.deletePersonPhoto.meta = meta;
    });
    builder.addCase( deletePersonPhoto.fulfilled, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.deletePersonPhoto.meta = meta;
    });
    builder.addCase( deletePersonPhoto.rejected, ( people, action ) => {
      const { arg, ...meta } = action.meta;
      people.deletePersonPhoto.meta = meta;
    });
  },
});


export const { updatePeople, updatePerson, destroyPerson } = peopleSlice.actions;

export default peopleSlice.reducer;
