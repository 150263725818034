import React, { ReactElement } from 'react';
import { Button } from '../../components/Button';
import Translation from '../../components/Translation';
import { TranslationKeys } from '../../store/initial/translations';

interface Props {
  titleKey: TranslationKeys;
  onPrimaryAction: ()=> void;
}

export default function GuardiansSummaryAddChildPrompt({ titleKey, onPrimaryAction }: Props ): ReactElement{
  return (
    <>
      <div className="text-black-70 pb-8 border-b border-grey mb-8">
        <Translation translationKey={ titleKey } />
      </div>
      <div className="flex justify-between items-center">
        <span><Translation translationKey="screen.guardians.nokids.forgettoaddsomeone" /></span>
        <Button small theme="ghost"
          onClick={ onPrimaryAction }
        ><Translation translationKey="screen.guardians.nokids.button.addachild"/></Button>
      </div>
    </>
  );
}
