import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch, RootState } from '../store';
import { push } from '../store/reducers/backstackReducer';

// Routes
import { dashboard as dashboardRoute } from '../../core/routes';

// Types
import LocationState from '../types/LocationState';

export const backstackQueryParam = 'backstackReturnDepth';

/**
 * Peek into the stack
 */
const peek = ( depth = 1 ):
( state: RootState )=> string|null =>
  state => {
    const bs = state.backstack;
    return !!bs.length ? bs[bs.length - depth] : null;
  };


/**
 * Put this on the main router tracking each location change
 */
export const useBSTracker = (): string[] => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const path = pathname + search;
  const initialPath = useRef( path );

  // check for `backstackReturnDepth` in query params
  // this allows us to manually go back X entries
  const params = new URLSearchParams( search );
  const depth: number = parseInt( params.get( backstackQueryParam ) || '0' );

  // track history
  useEffect(() => {
    // update the current path
    initialPath.current = path;
    setTimeout(() => {
      // this filters out redirected paths
      if ( initialPath.current === path ) {
        dispatch( push({ path, depth }));
      }
    }, 10 );
  }, [ dispatch, depth, path ]);

  return useSelector( state => state.backstack );
};


interface UseBackstack {
  goBack: ()=> void;
  destination: string|null;
}


/**
 * Return function to pop history from backstack x depth
 */
const useBackstack = ( fallbackURL = dashboardRoute.get(), depth = 1 ): UseBackstack => {
  const { push } = useHistory();
  const location = useLocation<LocationState>();
  const destination = useSelector( peek( depth + 1 ));

  const goBack = (): void => {
    push( destination || location.state?.background || fallbackURL );
    return;
  };

  return {
    goBack,
    destination: destination || location.state?.background?.pathname || fallbackURL,
  };
};


export default useBackstack;
