import { createElement, FC } from 'react';

import { Spinner } from './Spinner';

export type ButtonTheme = (
  'primary'        | 'secondary'   | 'tertiary' | 'quarternary' | 'disabled' | 'ghost' | 'light' |
  'primary-shadow' |
  'clean'          |
  'clean-danger'   | 'clean-black' | 'clean-secondary' | 'clean-green-700' |
  'flat'           |
  'danger'         |
  'danger-ghost'   |
  'yellow'         |
  'danger-alt'
)

export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  onClick?: ()=> void;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  small?: boolean;
  isPending?: boolean;
  theme?: ButtonTheme;
  element?: 'button'|'div'|'span';
}

const PRIMARY_BUTTON_CLASSES = 'text-white bg-forest hover:bg-forest--hover button-focusStyles min-w-button';

const getButtonTheme = ( theme: ButtonTheme ): string => {
  switch ( theme ) {
  case 'primary':
    return PRIMARY_BUTTON_CLASSES;
  case 'primary-shadow':
    return PRIMARY_BUTTON_CLASSES + ' shadow-button';
  case 'secondary':
    return 'text-forest bg-moss hover:text-white button-focusStyles--dark hover:bg-forest';
  case 'tertiary':
    return 'text-white bg-forest hover:bg-forest--hover button-focusStyles shadow-button min-w-button';
  case 'quarternary':
    return 'text-white bg-salamander hover:bg-salamander--hover button-focusStyles min-w-button';
  case 'disabled':
    return 'bg-black-20 text-black-50';
  case 'light':
    return 'bg-white min-w-button';
  case 'danger':
    return 'text-white bg-red hover:bg-red-error focus-visible:ring-salamander min-w-button';
  case 'danger-alt':
    return 'text-red bg-red-300 rounded !font-light focus-visible:ring-salamander';

  // clean
  case 'clean':
    return 'text-forest hover:text-forest--hover button-focusStyles--clean !py-0 !px-0 !rounded-none';
  case 'clean-black':
    return 'text-black hover:text-black-70 button-focusStyles--clean !py-0 !px-0 !rounded-none';
  case 'clean-secondary':
    return 'text-black-70 hover:text-black button-focusStyles--clean !py-0 !px-0 !rounded-none';
  case 'clean-danger':
    return 'text-red !py-0 !px-0 focus-visible:ring-salamander !rounded-none';

  // flat
  case 'flat':
    return 'text-forest bg-moss rounded hover:text-white button-focusStyles--dark hover:bg-forest';
  // ghost
  case 'danger-ghost':
    return 'text-black hover:text-red !py-0 !px-0';
  case 'ghost':
    return 'border border-grey hover:bg-grey hover:border-grey';
  case 'yellow':
    return 'bg-yellow-300 hover:bg-yellow-600';
  default:
    return '';
  }
};

export const Button: FC<ButtonProps> = ({
  onClick,
  children,
  className = '',
  disabled = false,
  id,
  small = false,
  type = 'button',
  theme = 'primary',
  isPending = false,
  element = 'button',
}) => {
  const childrenClassName = !isPending ? 'opacity-100' : 'opacity-0';
  const sizeClassName = small ? 'py-2 px-4' : 'py-5 px-7';

  return createElement(
    element,
    {
      disabled: disabled || isPending,
      onClick,
      type,
      id: id,
      className: `
        relative
        flex
        font-bold
        items-center
        justify-center
        rounded-full
        transition-colors
        whitespace-nowrap
        ${sizeClassName}
        ${getButtonTheme( disabled ? 'disabled' : theme )}
        ${className}
      `,
    },
    <>
      { isPending && <Spinner isSpinning /> }
      <div className={ `w-full ${childrenClassName}` }>
        { children }
      </div>
    </>,
  );
};

export interface ButtonsProps {
  justify?: 'end' | 'between' | 'start';
}

export const Buttons: FC<ButtonsProps> = ({
  justify = 'between',
  children,
}) => {

  /** using full classname so that purge-css will not purge */
  const justifyClassName = {
    between: 'justify-between',
    end: 'justify-end',
    start: 'justify-start',
  }[justify];

  return(
    <div className={ `flex ${justifyClassName}` }>
      { children }
    </div>
  );
};


export default Button;
