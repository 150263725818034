
const medicalConsentRoutes = [
//  * Documents - Medical Consent Main Screen
//  */
  {
    key: 'medical_consent',
    name: 'Medical Consent',
    path: '/documents/medical-consent',
    template: 'index.html',
  },

  // /**
  //  * Documents - Medical Consent Individual Child's Document
  //  */
  {
    key: 'medical_consent_document',
    name: 'Medical Consent',
    path: '/documents/medical-consent/:wardId',
    template: 'index.html',
  },

  // /**
  //  * Documents - Medical Consent Individual Child's Care Information
  //  */
  {
    key: 'medical_consent_care_info',
    name: 'Care Information',
    path: '/documents/medical-consent/:wardId/care-information',
    template: 'index.html',
  },

  // /**
  //  * Documents - Medical Consent Individual Child's Caretakers
  //  */
  {
    key: 'medical_consent_caretakers',
    name: 'Caretakers',
    path: '/documents/medical-consent/:wardId/caretakers',
    template: 'index.html',
  },

  // /**
  //  * Documents - Medical Consent Edit Caretaker
  //  */
  {
    key: 'medical_consent_caretaker',
    name: 'Caretakers',
    path: '/documents/medical-consent/:wardId/caretaker/:caretakerId',
    template: 'index.html',
  },

  // /**
  //  * Documents - Medical Consent Individual Child's Address
  //  */
  {
    key: 'medical_consent_address',
    name: 'Address',
    path: '/documents/medical-consent/:wardId/address',
    template: 'index.html',
  },

  // /**
  //  * Documents - Medical Consent Individual Child's Medications
  //  */
  {
    key: 'medical_consent_medications',
    name: 'Medications',
    path: '/documents/medical-consent/:wardId/medications',
    template: 'index.html',
  },

  // /**
  //  * Documents - Medical Consent Individual Child's Allergies
  //  */
  {
    key: 'medical_consent_allergies',
    name: 'Allergies',
    path: '/documents/medical-consent/:wardId/allergies',
    template: 'index.html',
  },

  // /**
  //  * Documents - Medical Consent Individual Child's Doctors
  //  */
  {
    key: 'medical_consent_doctors',
    name: 'Doctors',
    path: '/documents/medical-consent/:wardId/doctors',
    template: 'index.html',
  },
];

export default medicalConsentRoutes;
