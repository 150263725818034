import axios, { AxiosResponse } from 'axios';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Actions
import { hasAuthenticated, hasCompletedOnboarding } from './authReducer';
import { updatePerson } from './peopleReducer';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';
import { stringToBoolean } from '../../../core/utils/booleanUtils';

// Constants
import { ONE_MINUTE } from '../../../core/constants';

import appConfig from '../../../core/appConfig';

import initialState from '../initial/user';

import analytics, { identifiyUser } from '../../utils/analytics';
import UserInterface, { UserAttributes, UserEntitlements } from '../../types/UserInterface';
import { RootState } from '..';
import { socket, WebsocketEvent } from '../../providers/WebsocketProvider';

const CORE_DATA_SERVICE_BOOLEAN = 'BOOLEAN';
const CORE_DATA_SERVICE_INTEGER = 'INTEGER';
const CORE_DATA_SERVICE_STRING = 'STRING';

export const isValidStringUserAttribute = ( key: string ): boolean => {
  // branch link params that should be passed to the backend begin with `ua_`
  return key.startsWith( 'ua_' ) || [ 'app.trustSignedTrustDate','app.trustSignedPourOverWillDate' ].includes( key );
};

interface IFetchUser {
  onSuccess?: ( response: AxiosResponse )=> void;
}

interface IPostUser {
  onSuccess?: ( response: AxiosResponse )=> void;
}

interface IPatchUser {
  onSuccess?: ( response: AxiosResponse )=> void;
  onError?: ( error: string )=> void;
  email?: string | null;
  phone?: string | null;
  referral_id?: string | null;
}

interface IDeleteUser {
  onSuccess?: ( response: AxiosResponse )=> void;
}

interface ISetPartnerCode {
  partnerCode: string;
}

interface ISetUserAttributes extends Partial<UserAttributes>{
  onSuccess?: ( response: AxiosResponse )=> void;
}

export const fetchUser = createAsyncThunk<
  WebClientRequest,
  IFetchUser | void,
  {state: RootState}
>(
  'user/fetchUser',
  async( arg: IFetchUser | void, thunkAPI ) => {
    const url = '/v1/users/me';
    return WebClientRequest
      .get( url )
      .then( response => {
        const user = response.data.data;
        const person = response.data.data.person;
        thunkAPI.dispatch( updateUser( user ));
        thunkAPI.dispatch( updatePerson( person ));
        thunkAPI.dispatch( hasAuthenticated( true ));

        // @TODO: Can this be more global or on the database to determin
        const hasUserCompletedOnboarding =
          user.status === 'ACTIVE' &&
          !!person.address.zip &&
          !!person.name &&
          !!person.email;

        identifiyUser( user );

        const hasUserCompletedOnboardingPreviously = thunkAPI.getState().auth.hasCompletedOnboarding;

        if( hasUserCompletedOnboardingPreviously !== hasUserCompletedOnboarding ) {
          thunkAPI.dispatch( hasCompletedOnboarding( hasUserCompletedOnboarding ));
        }
        arg && arg.onSuccess && arg.onSuccess( response );
      });
  },
);


export const patchUser = createAsyncThunk(
  'user/patchUser',
  async({ onSuccess, onError, ...data }: IPatchUser, thunkAPI ) => {
    const url = '/v1/users/me';
    return WebClientRequest
      .patch( url, data )
      .then( response => {
        const user = response.data.data;
        const person = response.data.data.person;
        thunkAPI.dispatch( updateUser( user ));
        thunkAPI.dispatch( updatePerson( person ));
        identifiyUser( user );
        onSuccess && onSuccess( response );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);


/**
 * Create a guest user
 */
export const postUser = createAsyncThunk(
  'user/postUser',
  async( arg: IPostUser | void, thunkAPI ) => {
    return axios
      .post( combineURLs( appConfig.app.baseurl, '/api/getGuestUser' ))
      .then( response => {
        // no need how to identify the user via analytics since it's guest user
        const access_token = response.data.access_token;
        const user = response.data.user;
        const person = response.data.user.person;

        WebClientRequest.token = access_token;
        thunkAPI.dispatch( updateUser( user ));
        thunkAPI.dispatch( updatePerson( person ));

        const createdTime = ( new Date( user.created_at )).getTime();
        const now = Date.now();
        if( now - createdTime <= ONE_MINUTE ){
          analytics.track( 'NewGuestUser' );
        }
        arg && arg.onSuccess && arg.onSuccess( response );
      })
      .catch( e => {
        // no-op
      });
  },
);

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async( arg: IDeleteUser | void, thunkAPI ) => {
    const url = '/v1/users/me';
    return WebClientRequest
      .delete( url )
      .then( response => {
        arg && arg.onSuccess && arg.onSuccess( response );
      });
  },
);


export const setUserPartnerCode = createAsyncThunk(
  'user/setPartnerCode',
  async({ partnerCode }: ISetPartnerCode, thunkAPI ) => {
    const url = '/v1/user_partners';
    const data = {
      'partner_code': partnerCode,
    };
    /**
     * this can return a 409 if user _already_ has a conflicting partner code
     * this is a no-op
     */
    return WebClientRequest
      .post( url, data );
  },
);


export const setUserAttributes = createAsyncThunk(
  'user/setUserAttributes',
  async({ onSuccess, ...data }: ISetUserAttributes, thunkAPI ) => {
    const url = '/v2/attributes';

    const transformedData = Object.entries( data ).map( entry => {
      const [ key, value ] = entry;
      const dataObject: {
        key: string;
        value: boolean | number | string | null;
        type: typeof CORE_DATA_SERVICE_BOOLEAN | typeof CORE_DATA_SERVICE_INTEGER | typeof CORE_DATA_SERVICE_STRING |null;
      } = {
        key,
        value: null,
        type: null,
      };
      switch( typeof value ) {
      case 'string':
        /**
         * Generally speaking, strings passed here should be parsed as booleans
         * unless the key is in the string "allow list" returned by the function above
         */
        if ( isValidStringUserAttribute( key )) {
          dataObject['value'] = value;
          dataObject['type'] = CORE_DATA_SERVICE_STRING;
        } else {
          dataObject['value'] = stringToBoolean( value );
          dataObject['type'] = CORE_DATA_SERVICE_BOOLEAN;
        }
        break;
      case 'boolean':
        dataObject['value'] = value;
        dataObject['type'] = CORE_DATA_SERVICE_BOOLEAN;
        break;
      case 'number':
        dataObject['value'] = value;
        dataObject['type'] = CORE_DATA_SERVICE_INTEGER;
        break;
      default:
        break;
      }
      return dataObject;
    });
    return WebClientRequest
      .post( url, transformedData )
      .then( response => {
        thunkAPI.dispatch( saveAttributes( response.data.data ));
        onSuccess && onSuccess( response );
      });
  },
);


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    updateUser( user, action ){
      // Extract `person` from payload, and set `user.person_id`
      // Note: We don't want any `person` object on the user

      const { person, ...payload } = action.payload;
      payload.person_id = person.id;

      const normalizedPayload = payload as UserInterface;
      // Mutate `user` with values from the rest of the payload
      user.data = { ...user.data, ...normalizedPayload };

      // let the websocket service know who we are
      socket.emit( WebsocketEvent.identifyUser, payload.id );
      // @TODO: Should update `person` in person store?
    },

    updateUserEntitlements( user, action: PayloadAction<UserEntitlements> ){
      user.data.entitlements = action.payload;
    },

    saveAttributes( user, action: PayloadAction<UserAttributes> ){
      user.data.attributes = action.payload;
    },

    // use sparingly, this will not unset values or set anything to "null"
    saveAttribute( user, action: PayloadAction<UserAttributes> ){
      user.data.attributes = { ...user.data.attributes, ...action.payload };
    },
  },


  extraReducers: builder => {

    // fetchUser status
    builder.addCase( fetchUser.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.fetchUser.meta = meta;
    });
    builder.addCase( fetchUser.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.fetchUser.meta = meta;
    });
    builder.addCase( fetchUser.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.fetchUser.meta = meta;
    });

    // patchUser status
    builder.addCase( patchUser.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.patchUser.meta = meta;
    });
    builder.addCase( patchUser.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.patchUser.meta = meta;
    });
    builder.addCase( patchUser.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.patchUser.meta = meta;
    });

    // postUser status
    builder.addCase( postUser.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.postUser.meta = meta;
    });
    builder.addCase( postUser.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.postUser.meta = meta;
    });
    builder.addCase( postUser.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.postUser.meta = meta;
    });

    // deleteUser status
    builder.addCase( deleteUser.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.deleteUser.meta = meta;
    });
    builder.addCase( deleteUser.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.deleteUser.meta = meta;
    });
    builder.addCase( deleteUser.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.deleteUser.meta = meta;
    });

    // setUserPartnerCode status
    builder.addCase( setUserPartnerCode.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserPartnerCode.meta = meta;
    });
    builder.addCase( setUserPartnerCode.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserPartnerCode.meta = meta;
    });
    builder.addCase( setUserPartnerCode.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserPartnerCode.meta = meta;
    });

    // setUserAttributes status
    builder.addCase( setUserAttributes.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserAttributes.meta = meta;
    });
    builder.addCase( setUserAttributes.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserAttributes.meta = meta;
    });
    builder.addCase( setUserAttributes.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserAttributes.meta = meta;
    });

  },
});

export const { updateUser, saveAttributes, saveAttribute, updateUserEntitlements } = userSlice.actions;

export default userSlice.reducer;
