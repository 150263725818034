
export interface Settings {
  language: 'en-US';
  canShowOnboarding: boolean;
  hasSeenOnboarding: boolean;
}

export const settings = {
  language: 'en-US',
  canShowOnboarding: true,
} as Settings;


export default settings;
