import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '..';

// Constants
import { INITIAL_DISTRIBUTION_AGE, INITIAL_DISTRIBUTION_PERCENTAGE, SECONDARY_DISTRIBUTION_AGE, SECONDARY_DISTRIBUTION_PERCENTAGE } from '../../../core/constants';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

// Interface
import BaseReduction from '../../types/BaseReduction';
import DistributionInterface from '../../types/DistributionInterface';
import InheritanceInterface, { AxiosInheritanceResponse, AxiosInheritancesResponse, NonNormalizedInheritanceInterface } from '../../types/InheritanceInterface';
import PersonInterface from '../../types/PersonInterface';
import { generateQueryString } from '../../utils/urlUtils';

// Data
import initialState from '../initial/inheritances';

interface IFetchInheritances extends BaseReduction<AxiosInheritancesResponse>{
  shouldReset?: boolean;
}

interface IDeleteInheritance extends BaseReduction<AxiosResponse> {
  id: InheritanceInterface['id'];
}

interface IPatchInheritance extends BaseReduction<AxiosInheritanceResponse>{
  id: InheritanceInterface['id'];
  share: InheritanceInterface['share'];
  initialDistributionPercentage?: DistributionInterface['share'];
}

interface IPostInheritance extends BaseReduction<AxiosInheritanceResponse>{
  person_id: PersonInterface['id'];
  share: InheritanceInterface['share'];
}

/** Remove person from response, add person_id property */

export const normalize = (
  { person, heir, ...data }: NonNormalizedInheritanceInterface,
): InheritanceInterface => Object({
  ...data,
  person_id: person.id,
  heir_id: heir.id,
});

export const fetchInheritances = createAsyncThunk(
  'inheritances/fetchInheritances',
  async( args: IFetchInheritances | void, thunkAPI ) => {
    let url = '/v1/inheritances';

    // shouldReset will get all the inheritances but set them back to "equal" shares
    const shouldReset = !!( args && args.shouldReset );

    // @TODO WCR should accept params and pass them to axios
    if ( shouldReset ) {
      url += `?${generateQueryString({ reset: shouldReset })}`;
    }
    return WebClientRequest
      .get( url )
      .then(( response: AxiosInheritancesResponse ) => {
        const inheritances = response.data.data.map( normalize );
        thunkAPI.dispatch( updateInheritances( inheritances ));
        args && args.onSuccess && args.onSuccess( response );
      });
  },
);

/**
 * "operationId": "deleteInheritance",
 * "description": "Delete an inheritance",
 */
export const deleteInheritance = createAsyncThunk(
  'inheritances/deleteInheritance',
  async({ onSuccess, id }: IDeleteInheritance, thunkAPI ) => {

    const url = `/v2/heirs/${id}`;
    return WebClientRequest
      .delete( url )
      // 204 Empty
      .then(( response: AxiosResponse ) => {
        thunkAPI.dispatch( removeInheritance({ id }));
        onSuccess && onSuccess( response );
      });
  },
);

export const patchInheritance = createAsyncThunk<
  WebClientRequest,
  IPatchInheritance,
  {state: RootState}
>(
  'inheritances/patchInheritance',
  async({ id, onSuccess, ..._data }: IPatchInheritance, thunkAPI ) => {

    const url = `/v1/inheritances/${id}`;


    const { share, initialDistributionPercentage } = _data;
    const data = {
      share,
    };

    if( initialDistributionPercentage !== undefined ) {
      // provide fallbacks here in case legacy users' heirs do not have distributions
      // these could change and become incorrect if server defaults do
      const initialDist = thunkAPI.getState().inheritances.data.find( inheritance => inheritance.id === id )?.distributions[0]
        || { age: INITIAL_DISTRIBUTION_AGE, share: INITIAL_DISTRIBUTION_PERCENTAGE };
      const secondaryDist = thunkAPI.getState().inheritances.data.find( inheritance => inheritance.id === id )?.distributions[1]
        || { age: SECONDARY_DISTRIBUTION_AGE, share: SECONDARY_DISTRIBUTION_PERCENTAGE };

      Object.assign( data, {
        distributions: [
          { ...initialDist, share: initialDistributionPercentage }, //overwrite the existing share
          secondaryDist,
        ],
      });
    }


    return WebClientRequest
      .patch( url, data )
      .then(( response: AxiosInheritanceResponse ) => {
        const inheritance = normalize( response.data.data );
        thunkAPI.dispatch( updateInheritance( inheritance ));
        onSuccess && onSuccess( response );
      });
  },
);

/**
 * "operationId": "CreateInheritance",
 * "description": "Create an inheritance",
 */
export const postInheritance = createAsyncThunk(
  'inheritances/postInheritance',
  async({ onSuccess, person_id, ...data }: IPostInheritance, thunkAPI ) => {

    const url = `/v1/people/${person_id}/inheritances`;
    return WebClientRequest
      .post( url, data )
      .then(( response: AxiosInheritanceResponse ) => {
        const inheritance = normalize( response.data.data );
        thunkAPI.dispatch( updateInheritance( inheritance ));
        onSuccess && onSuccess( response );
      });
  },
);


const inheritancesSlice = createSlice({
  name: 'inheritances',
  initialState,

  reducers: {

    updateInheritances( inheritances, action: PayloadAction<InheritanceInterface[]> ){
      if( inheritances.data.length === 0 ){
        inheritances.data = action.payload;
      } else {
        action.payload.forEach( inheritance => {
          const idx = inheritances.data
            .findIndex( xInheritance => xInheritance.id === inheritance.id );
          if( idx >= 0 ){
            inheritances.data[idx] = inheritance;
          } else {
            inheritances.data.push( inheritance );
          }
        });
      }
    },

    updateInheritance( inheritances, action: PayloadAction<InheritanceInterface> ){
      const payload = action.payload;
      const idx = inheritances.data.findIndex( property => property.id === payload.id );
      if( idx >= 0 ){
        inheritances.data[idx] = payload;
      } else {
        inheritances.data.push( payload );
      }
    },

    removeInheritance( inheritances, action: PayloadAction<{id: string}> ){
      const { id } = action.payload;
      inheritances.data = inheritances.data.filter( inheritance => inheritance.id !== id );
    },
  },

  extraReducers: builder => {

    // fetchInheritances.<status>
    builder.addCase( fetchInheritances.pending, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.fetchInheritances.meta = meta;
    });
    builder.addCase( fetchInheritances.fulfilled, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.fetchInheritances.meta = meta;
    });
    builder.addCase( fetchInheritances.rejected, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.fetchInheritances.meta = meta;
    });

    // patchInheritance.<status>
    builder.addCase( patchInheritance.pending, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.patchInheritance.meta = meta;
    });
    builder.addCase( patchInheritance.fulfilled, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.patchInheritance.meta = meta;
    });
    builder.addCase( patchInheritance.rejected, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.patchInheritance.meta = meta;
    });

    // postInheritance.<status>
    builder.addCase( postInheritance.pending, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.postInheritance.meta = meta;
    });
    builder.addCase( postInheritance.fulfilled, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.postInheritance.meta = meta;
    });
    builder.addCase( postInheritance.rejected, ( inheritances, action ) => {
      const { arg, ...meta } = action.meta;
      inheritances.postInheritance.meta = meta;
    });
  },
});


export const { updateInheritances, updateInheritance, removeInheritance } = inheritancesSlice.actions;

export default inheritancesSlice.reducer;
