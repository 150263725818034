import PropertyInterface from '../../types/Property/PropertyInterface';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  data: [] as Array<PropertyInterface>,
  // for extra reducers
  patchProperty: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postProperty: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchProperties: {
    meta: {} as AsyncThunkMetaInterface,
  },
  uploadPropertyPhoto: {
    meta: {} as AsyncThunkMetaInterface,
  },
  deleteProperty: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
