export enum PRODUCT_KEYS {
  pro = 'pro',
  basic = 'basic'
}

export type Product = {
  [key in PRODUCT_KEYS]: {
    sku: string;
  };
}


