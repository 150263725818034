import { ReactElement, ReactNode } from 'react';

import Button from './Button';
import Translation from './Translation';


export interface DocumentIntroProps {
  image: {
    src: string;
    alt: string;
  };
  title: ReactNode;
  data_tid?: string;
  body?: ReactNode;
  children?: ReactNode;
  minToComplete?: string;
  primaryActionLabel?: ReactNode;
  onPrimaryAction: ()=> void;
}

export default function DocumentIntro({
  image,
  title,
  data_tid,
  body,
  children,
  minToComplete,
  onPrimaryAction,
  primaryActionLabel = <Translation translationKey="(screen)welcome(button)begin" />,
}: DocumentIntroProps ): ReactElement{
  return (
    <>
      <div className="sm-only:-mt-12 aspect-w-3 aspect-h-2">
        <img
          src={ image.src }
          alt={ image.alt }
          className="w-full object-cover"
        />
      </div>
      <div className="bg-white p-10 flex-1 flex flex-col justify-between">
        <div>
          <h1 className="text-regular text-2xl mb-4 text-black font-headline">
            {title}
          </h1>
          <div className="mb-4">
            {body || children}
          </div>
          {minToComplete &&
            <time className="text-black-50">~{minToComplete} min</time>
          }
        </div>
        <div className="flex justify-end">
          <Button
            id={ data_tid }
            onClick={ onPrimaryAction }>{primaryActionLabel}
          </Button>
        </div>
      </div>
    </>
  );
}


