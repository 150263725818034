// Components
import ActionContainer from '../../../../components/ActionContainer';
import HorizontalRule from '../../../../components/HorizontalRule';
import PersonSelectedContainer from '../../../../components/PersonSelectedContainer';

// Redux
import { deleteRole } from '../../../../store/reducers/rolesReducer';
import { useDispatch } from '../../../../store';
import { makeSnack } from '../../../../store/reducers/snackbarReducer';

// Utils
import { toTitleCase } from '../../../../../core/utils/stringHelpers';
import stringifyNumber from '../../../../utils/stringifyNumber';

// Models
import { PersonInterface } from '../../../../types/PersonInterface';
import RoleInterface from '../../../../types/RoleInterface';

// Icons
import useTranslations from '../../../../hooks/useTranslations';

interface Props {
  className?: string;
  agent: PersonInterface;
  idx: number;
  role?: RoleInterface;
}

/**
 * return 'primary', 'backup', etc
 * @param idx
 */
const getTier = ( idx: number ): string => {
  const tiers = [ 'primary', 'backup' ];
  return tiers[idx] || `${stringifyNumber( idx + 1 )} backup`;
};

const Agent = ({
  className='',
  agent,
  idx,
  role,
}: Props ): JSX.Element => {

  const { getTranslation } = useTranslations();

  const dispatch = useDispatch();

  const header = toTitleCase( `${getTier( idx )} Healthcare Agent` );

  const handleAgentRemoved = () => {
    const dynamicStringVariables = [ agent.name || '', header ];
    const confirmRemoveAgent =  getTranslation( 'banner.role.general.removed.confirm', dynamicStringVariables );
    const agentRemovedSuccessMessage = getTranslation( 'banner.role.general.removed.success', dynamicStringVariables );
    if ( window.confirm( confirmRemoveAgent )) {
      if( !role ) {
        return;
      }
      dispatch( deleteRole({ id: role.id, onSuccess: () => {
        dispatch( makeSnack({ message: agentRemovedSuccessMessage }));
      } }));
    }
  };

  const getLabel = (): string => {
    return getTranslation( 'role.name.healthcareagent.displayname', [ getTier( idx ) ]);
  };

  return (
    <>
      <ActionContainer
        className={ className }
        header={ header }
        headerClassName="font-bold"
        primaryActionLabel={ getTranslation( '(button)remove' ) }
        primaryAction={ handleAgentRemoved }
        primaryActionTheme={ !!agent ? 'clean-danger' : 'clean' }
      >
        <PersonSelectedContainer
          person={ agent }
          role={ getLabel() }
          translationKey={ 'personSelector.healthcareAgent.label' }
        />
      </ActionContainer>
      <HorizontalRule/>
    </>
  );
};

export default Agent;
