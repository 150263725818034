const willRoutes = [
  /**
   * Documents - Will intro
   */
  {
    key: 'will_intro',
    name: 'Will Intro',
    path: '/documents/will/intro',
    template: 'index.html',
  },

  /**
   * Documents - Will Review
   */
  {
    key: 'will_review',
    name: 'Review Your Will',
    path: '/documents/will/review',
    template: 'index.html',
  },

  /**
   * Documents - Will status
   */
  {
    key: 'will',
    name: 'Your Will',
    path: '/documents/will',
    template: 'index.html',
  },

  /**
   * Documents - Will basics
   */
  {
    key: 'will_basics',
    name: 'Your Will Basics',
    path: '/documents/will/basics',
    template: 'index.html',
  },

  /**
   * Documents - Will Guardians
   */
  {
    key: 'will_guardians',
    name: 'Your Guardians',
    path: '/documents/will/guardians',
    template: 'index.html',
  },

  /**
   * Documents - Will Valuables
   */
  {
    key: 'will_valuables',
    name: 'Your Valuables',
    path: '/documents/will/valuables',
    template: 'index.html',
  },

  /**
   * Documents - Will Valuables
   */
  {
    key: 'will_realEstate',
    name: 'Your Real Estate',
    path: '/documents/will/real-estate',
    template: 'index.html',
  },

  /**
   * Documents - Will Executors
   */
  {
    key: 'will_executors',
    name: 'Your executors',
    path: '/documents/will/executors',
    template: 'index.html',
  },

  /**
   * Pet Guardians
   */
  {
    key: 'will_pets',
    name: 'Pet Guardians',
    path: '/documents/will/pets',
    template: 'index.html',
  },

  /**
   * Documents - Will heirs
   */
  {
    key: 'will_heirs',
    name: 'Your Heirs',
    path: '/documents/will/heirs',
    template: 'index.html',
  },
];

export default willRoutes;
