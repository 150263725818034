import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';
import PowerInterface from '../../types/powersInterface';


const initialState = {
  data: [] as Array<PowerInterface>,
  // for extra reducers
  patchPower: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postPower: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchPowers: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
