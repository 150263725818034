import { UserAttributes } from './../types/UserInterface';
import { PropertyInterface } from './../types/Property/PropertyInterface';
import { TranslationKeys } from './../store/initial/translations/index';
import { useCallback } from 'react';

// Components
import { DocumentSectionStates, SectionStates } from '../components/DocumentSectionOverview';
// Helpers
import { isNumberEqualishTo100 } from '../utils/numberUtils';
import { separateArrayStringsByComma } from '../../core/utils/stringHelpers';

// Hooks
import useExecutors from './useExecutors';
import useHeirs from './useHeirs';
import useGuardians from './useGuardians';
import useProperties from './useProperties';

// Types
import { RoleInterface } from './../types/RoleInterface';
import PersonInterface from '../types/PersonInterface';

// Redux
import { useSelector } from '../store';

const requiredWillSections = {
  basics: 'basics',
  heirs: 'heirs',
  guardians: 'guardians',
  executors: 'executors',
} as const;

const optionalWillSections = {
  pets: 'pets',
  valuables: 'valuables',
  realEstate: 'realEstate',
  review: 'review',
} as const;

export const WillSections = { ...requiredWillSections, ...optionalWillSections } as const;

export type WillSection = keyof typeof WillSections;

export const GetWillSectionTitleKey = ( key: WillSection | undefined ): TranslationKeys => {

  switch ( key ) {
  case WillSections.heirs:
    return 'will.chapter.title.heirs';
  case WillSections.guardians:
    return 'will.chapter.title.guardians';
  case WillSections.valuables:
    return 'will.chapter.title.valueables';
  case WillSections.realEstate:
    return 'will.chapter.title.realestate';
  case WillSections.executors:
    return 'will.chapter.title.executors';
  case WillSections.pets:
    return '(screen)family(category)pets(title)';
  case WillSections.review:
    return 'products.will.status_screen.chapters.print_and_sign.title';
  default:
    return 'will.chapter.title.basics';
  }
};


export type DocumentSectionState = {
  isGated?: boolean;
  state: DocumentSectionStates;
  message?: {
    translationKey: TranslationKeys;
    dynamicStringValues?: string[];
  };
  canFinish?: boolean;
}

export interface WillSectionStates {
  basics: DocumentSectionState;
  heirs: DocumentSectionState;
  guardians: DocumentSectionState;
  pets: DocumentSectionState;
  valuables: DocumentSectionState;
  realEstate: DocumentSectionState;
  executors: DocumentSectionState;
  review: DocumentSectionState;
}

type WillSectionStatus = {
  sectionStates: WillSectionStates;
  next: WillSection;
  hasCompletedWill: boolean;
  canCompleteWill: boolean;
};

const useWillSectionStatus = (): WillSectionStatus =>  {

  const { executors } = useExecutors();
  const { pets } = useProperties();
  const {
    minorChildren,
    roles,
  } = useGuardians();
  const isRenderedInWill = true;

  const userAttributes = useSelector( state => state.user.data.attributes );
  const {
    spouse,
    childrenHeirs,
    sumOfChildrenShares,
    fallbackHeirs,
    sumOfFallbackShares,
  } = useHeirs();

  const getStatusResult =
    useCallback(
      () => {
        const basics = getBasicsState( userAttributes );
        const heirs = getHeirsState( userAttributes, childrenHeirs, fallbackHeirs, sumOfChildrenShares, sumOfFallbackShares, spouse, isRenderedInWill );
        const guardians = getGuardiansState( userAttributes, minorChildren, roles );
        const executorsState = getExecutorsState( executors, userAttributes );
        const requiredSectionStates = [ basics.state, heirs.state, guardians.state, executorsState.state ];

        // the order of this object is important
        const sectionStates = {
          basics,
          heirs,
          executors: executorsState,
          guardians,
          pets: getPetsState( userAttributes, pets ),
          valuables: getValuablesState( userAttributes ),
          realEstate: getRealEstateState( userAttributes ),
          review: getWillReviewState( userAttributes, requiredSectionStates ),
        };
        const next = getNextSection( sectionStates );
        const hasCompletedWill = sectionStates.review.state === 'complete';

        // check if all required sections are complete and that all sections are not in a state of review or error
        const canCompleteWill =
          requiredSectionStates.every( state => state === 'complete' ) &&
          Object.values( sectionStates ).every( section => section.state !== SectionStates.error && section.state !== SectionStates.review );
        return { sectionStates, next, hasCompletedWill, canCompleteWill };
      },
      [
        childrenHeirs,
        userAttributes,
        fallbackHeirs,
        sumOfChildrenShares,
        sumOfFallbackShares,
        minorChildren,
        pets,
        roles,
        executors,
        spouse,
        isRenderedInWill,
      ],
    );

  return getStatusResult();
};

//
// SECTION STATES
//

const getBasicsState = ( userAttributes: UserAttributes ): DocumentSectionState  => {
  return { state: userAttributes['app.willHasConfirmedBasics'] !== undefined ? 'complete' : 'default' };
};

export const getHeirsState = (
  userAttributes: UserAttributes,
  childrenHeirs: PersonInterface[],
  fallbackHeirs: PersonInterface[],
  sumOfChildrenShares: number | undefined,
  sumOfFallbackShares: number | undefined,
  spouse: PersonInterface | undefined,
  isRenderedInWill: boolean,
): DocumentSectionState  => {

  // default or "unstarted" should be displayed in two cases
  // you're starting will heirs _but not_ a pourover will (hence trustHeirsChapterStarted)
  // you're starting trust heirs
  if(( isRenderedInWill && !userAttributes['app.willHeirsChapterStarted'] && !userAttributes['app.trustHeirsChapterStarted'])
  ||( !isRenderedInWill && !userAttributes['app.trustHeirsChapterStarted'])
  ) {
    return { state: 'default' };
  }

  // incomplete
  if( userAttributes['app.hasChildren'] && !childrenHeirs.length ) {
    return {
      state: 'warning',
      message: {
        translationKey: 'will.chapter.error.heirs.missing.children',
      } };
  }

  // needs review
  if ( userAttributes['app.willHeirsChapterNeedsReview']) {
    return { state: 'review' };
  }

  // errors
  if(( !userAttributes['app.hasChildren'] && !userAttributes['app.hasSpouse'] && !userAttributes['app.hasDomesticPartnership'] && !fallbackHeirs.length )
  || (( userAttributes['app.hasDomesticPartnership'] || userAttributes['app.hasSpouse'])&& !spouse )
  ) {
    return {
      state: 'error',
      message: {
        translationKey: 'will.chapter.error.heirs.missing',
      },
    };
  }

  if( childrenHeirs.length && !isNumberEqualishTo100( sumOfChildrenShares || 0 ) && sumOfChildrenShares !== 0 ) {
    return {
      state: 'warning',
      message: {
        translationKey: 'will.chapter.error.heirs.child.invalid',
      },
    };
  }

  if( fallbackHeirs.length &&
      sumOfFallbackShares !== 0 &&
      !isNumberEqualishTo100( sumOfFallbackShares || 0 )
  ) {
    return {
      state: 'warning',
      message: {
        translationKey: 'will.chapter.error.heirs.others.invalid',
      },
      canFinish: true,
    };
  }

  return { state: 'complete' };
};

const getValuablesState = ( userAttributes: UserAttributes ): DocumentSectionState => {
  if( userAttributes['app.willValuablesFinished']) {
    return { state: 'complete' };
  }
  return { state: 'default' };
};

const getRealEstateState = ( userAttributes: UserAttributes ): DocumentSectionState => {
  if( userAttributes['app.willRealEstateFinished']) {
    return { state: 'complete' };
  }
  return { state: 'default' };
};

const getPetsState = ( userAttributes: UserAttributes, pets: PropertyInterface[]): DocumentSectionState => {
  const petsMissingPrimaryGuardians = pets.filter(( pet: PropertyInterface ) => !pet.beneficiaries.length || !pet.beneficiaries[0]);
  if( userAttributes['app.petsChapterStarted'] && ( !pets.length || !petsMissingPrimaryGuardians.length )) {
    return { state: 'complete' };
  }
  if( petsMissingPrimaryGuardians.length ) {
    const petNames = petsMissingPrimaryGuardians.map( pet => pet.name || '' );
    return {
      state: 'error',
      message: {
        translationKey: 'products.will.pets.status.pet.noGuardian',
        dynamicStringValues: [ petNames && separateArrayStringsByComma( petNames ) ],
      },
    };
  }
  return { state: 'default' };
};


// GUARDIANS

export const doAllMinorChildrenHaveGuardians = ( minorChildren: PersonInterface[], roles: RoleInterface[]): boolean => {
  return minorChildren.filter( child => {
    return roles.some( role => child.user_id === role.user_id );
  })?.length === minorChildren.length;
};

const getGuardiansState = ( userAttributes: UserAttributes, minorChildren: PersonInterface[], roles: RoleInterface[]): DocumentSectionState => {
  if ( userAttributes['app.hasUnderageChildren'] === false ) {
    return { state: 'complete' };
  } else if ( userAttributes['app.guardiansChapterStarted']) {
    if ( minorChildren.length === 0 ) {
      return {
        state: 'complete',
        message: {
          translationKey: 'screen.willstatus.guardians.message.noChildrenAdded',
        },
      };
    } else if ( minorChildren.length > 0 ) {
      if ( userAttributes['app.guardiansChapterNeedsReview']) {
        return { state: 'review' };
      } else if ( doAllMinorChildrenHaveGuardians( minorChildren, roles )) {
        return { state: 'complete' };
      } else {
        // @todo: probably should add the names of those with no guardians?
        return {
          state: 'warning',
          message: {
            translationKey: 'will.chapter.error.guardians.missing',
          },
        };
      }
    }
  }
  return { state: 'default' };
};

const getExecutorsState = (
  executors: PersonInterface[],
  userAttributes: UserAttributes,
): DocumentSectionState => {

  if( executors.length > 0 ){
    return { state: 'complete' };
  } else if ( executors.length === 0 && userAttributes['app.executorsChapterStarted']) {
    return { state: 'warning', message: { translationKey: 'will.chapter.error.executors.missing' } };
  }

  return { state: 'default' };
};

const getWillReviewState = ( userAttributes: UserAttributes, requiredSectionStates: DocumentSectionStates[]): DocumentSectionState => {
  const shouldUnGate = requiredSectionStates.every( state => state === 'complete' );
  if( !shouldUnGate ) {
    return { state: 'default', isGated: true };
  }

  if( userAttributes['app.willPreviewViewed']) {
    return { state: 'complete', isGated: false };
  } else {
    return { state: 'default', isGated: false };
  }
};

const getNextSection = ( sectionsState: WillSectionStatus['sectionStates']): WillSection => {
  const sectionKeys = Object.keys( sectionsState ) as ( keyof WillSectionStatus['sectionStates'])[];

  return sectionKeys.filter( section => sectionsState[section].state !== 'complete' )[0];
};

export default useWillSectionStatus;
