import { ReactElement } from 'react';

import Property from '../../../core-data-service/models/Property';
import { REAL_ESTATE_URL_NAMESPACE as urlNamespace } from '../../../../core/constants';

import {
  properties as propertiesRoute,
} from '../../../../core/routes';
import Person from '../../../core-data-service/models/Person';

import useProperties from '../../../hooks/useProperties';
import ActionContainer from '../../../components/ActionContainer';
import PropertyListItem from '../PropertyListItem';

export default function RealEstateListView(): ReactElement{

  const { realEstate } = useProperties();
  const subtypes = Property.SUBTYPES[Property.TYPE.REAL_ESTATE];

  return (
    <>
      {realEstate.length > 0 &&
        <ul className="mb-8 list-none">
          {realEstate.map(( property,idx ) => (
            <ActionContainer
              key={ property.id }
              className="
                border-b border-grey last:border-none
                py-4 first:pb-4 first:py-0
              "
            >
              <PropertyListItem
                title={ property.beneficiaries[0] ? `You left ${property.address?.street} to ${new Person( property.beneficiaries[0]).firstName}.` : `${property.address?.street}` }
                image={{
                  src:  Property.getIcon( subtypes[property.subtype]),
                  alt: `${property.subtype} graphic`,
                }}
                route={ propertiesRoute.get({ id: property.id, urlNamespace },{ category: Property.TYPE.REAL_ESTATE }) }
              />
            </ActionContainer>
          ))}
        </ul>
      }
    </>
  );
}
