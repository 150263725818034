import { ReactElement } from 'react';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

// Components
import Fieldset from '../../../../components/Fieldset';
import SelectField from '../../../../components/SelectField';
import TextField from '../../../../components/TextField';

// Hooks
import useTranslations from '../../../../hooks/useTranslations';

// Utils
import { fieldRequired } from '../../../../formValidators';
import { isValidNumber } from '../../../../formValidators/isValidNumber';

// Types
import { FormInterface } from '../../../../types/FormInterface';

export default function WishesMultipartQuestion({
  question,
  register,
  setValue,
  errors,
  getValues,
}: {
    question: FormInterface ;
    register: UseFormMethods['register'];
    setValue: UseFormMethods['setValue'];
    errors: FieldErrors;
    getValues:  UseFormMethods['getValues'];
  }): ReactElement{
  const { getTranslation } = useTranslations();
  const useFormProps = {
    register,
    setValue,
    errors,
    getValues,
  };
  return(
    <div>
      { question.input.inputs && <Fieldset title={ question.text.question || '' }>
        <div className="grid grid-cols-2 gap-4">
          { question.input.inputs.map(( input, idx ) => {
            switch( input.type ) {
            case 'select' :
              return (
                <SelectField
                  { ...useFormProps }
                  label={ question.text.question }
                  showLabel={ false }
                  name={ input.id || `${idx}` }
                  options={ input.options || [] }
                  uniqueId={ input.id || `${idx}` }
                  key={ idx }
                  required={ input.required }
                  placeholder={ input.placeholder || undefined }
                />
              );
            default:
              return (
                <TextField
                  { ...useFormProps }
                  showLabel={ false }
                  label={ question.text.question }
                  name={ input.id || `${idx}` }
                  key={ idx }
                  inputMode={ input.format === 'number' ? 'numeric' : undefined }
                  placeholder={ input.placeholder || '' }
                  required={ input.required && fieldRequired( getTranslation( 'error.unansweredQuestion' )) }
                  pattern={ input.format === 'number' ? isValidNumber( getTranslation( 'error.requiresValidNumber' )) : undefined }
                />
              );
            }
          })}
        </div>
      </Fieldset>}
    </div>
  );
}
