// Components
import ActionContainer from '../../components/ActionContainer';
import HorizontalRule from '../../components/HorizontalRule';
import InvitationWrapper from '../../components/InvitationWrapper';
import PersonSelectedContainer from '../../components/PersonSelectedContainer';

// Redux
import { deleteRole } from '../../store/reducers/rolesReducer';
import { useDispatch } from '../../store';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import { showContextualModal } from '../../store/reducers/modalReducer';

// Utils
import { toTitleCase } from '../../../core/utils/stringHelpers';
import stringifyNumber from '../../utils/stringifyNumber';

// Models
import { PersonInterface } from '../../types/PersonInterface';
import RoleInterface from '../../types/RoleInterface';

// Icons
import useTranslations from '../../hooks/useTranslations';

import Invitation from '../../core-data-service/models/Invitation';
import Role from '../../core-data-service/models/Role';

interface Props {
  className?: string;
  executor: PersonInterface;
  idx: number;
  role?: RoleInterface;
}

/**
 * return 'primary', 'backup', 'second backup', 'third backup'... etc
 * @param idx
 */
const getTier = ( idx: number ): string => {
  const tiers = [ 'primary', 'backup' ];
  return tiers[idx] || `${stringifyNumber( idx + 1 )} backup`;
};

const Executor = ({
  className='',
  executor,
  idx,
  role,
}: Props ): JSX.Element => {

  const { getTranslation } = useTranslations();

  const dispatch = useDispatch();

  const header = toTitleCase( `${getTier( idx )} Executor` );

  const handleExecutorRemoved = () => {
    const dynamicStringVariables = [ executor.name || '', header ];
    const confirmRemoveExecutor = getTranslation( 'banner.role.general.removed.confirm', dynamicStringVariables );
    const executorRemovedSuccessMessage = getTranslation( 'banner.role.general.removed.success', dynamicStringVariables );
    if( !role ) {
      return;
    }
    dispatch( showContextualModal({
      description: confirmRemoveExecutor,
      dismissLabel: getTranslation( '(button)cancel' ),
      confirmLabel: getTranslation( '(button)remove' ),
      onConfirm: () => {
        dispatch( deleteRole({ id: role.id, onSuccess: () => {
          dispatch( makeSnack({ message: executorRemovedSuccessMessage }));
        } }));
      },
    }));
  };

  const getLabel = (): string => {
    return getTranslation( 'role.name.executor.tieredDisplayName', [ getTier( idx ) ]);
  };

  return (
    <>
      <ActionContainer
        className={ className }
        header={ header }
        primaryActionLabel={ getTranslation( '(button)remove' ) }
        primaryAction={ handleExecutorRemoved }
        primaryActionTheme={ !!executor ? 'clean-danger' : 'clean' }
      >
        <PersonSelectedContainer
          person={ executor }
          role={ getLabel() }
          translationKey={ 'personSelector.executor.label' }
          buttonId="executor-label"
        />
      </ActionContainer>
      <InvitationWrapper
        inviteeId={ executor.id }
        role={ Role.TYPE.EXECUTOR }
        invitationType={ Invitation.TYPE_ROLE }
      />
      <HorizontalRule/>
    </>
  );
};

export default Executor;
