import { ReactElement } from 'react';
import PersonIcon from '../views/People/PersonIcon';

interface PersonProps {
  src?: string | null | undefined;
  name?: string | null | undefined;
}
interface Props {
  avatarClassName?: string;
  className?: string;
  items: PersonProps[];
  limit?: number;
  overlapAmount?: string;
}

export default function OverlappingAvatars({ limit = 4, items, avatarClassName = '', className = '', overlapAmount = '-ml-3' }: Props ): ReactElement{
  const remainingNumberOfItems = items.length - limit;

  return (
    <div className={ `flex ${className}` }>
      {items.slice( 0, limit ).map(( person, i ) => {
        if( person === undefined ) {
          return null;
        }
        return (
          <div
            key={ `avatar-${i}` }
            className={ `w-circleIcon_sm ${i > 0 ? overlapAmount : ''}` }
          >
            <PersonIcon
              alt="avatar"
              personName={ person.name ?? null }
              photo_url={ person.src ?? null }
              style={{ zIndex: limit - i }}
            />
          </div>
        );
      })
      }
      {remainingNumberOfItems > 0 &&
        <div className="h-14 w-14 rounded-full bg-grey flex items-center justify-center -ml-3">
          +{remainingNumberOfItems}
        </div>
      }
    </div>
  );
}
