import { RootState } from '../../store';
import DirectiveInterface from '../../types/DirectiveInterface';

export const directivesSelector = ( state: RootState ): DirectiveInterface => {

  return state.directives.data.length
    ? state.directives.data[0]
    : {} as DirectiveInterface;
};

