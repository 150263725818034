import { ReactElement, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Views
import Checkout from '../../views/Checkout';

// Routes
import {
  checkout as checkoutRoute, dashboard, healthcare_directive_review, medical_consent, poa_review, trust_review, will_review,
} from '../../../core/routes';

// Redux
import { useSelector } from '../../store';
import entitlementsSelector from '../../store/selectors/entitlementsSelector';

// Hooks
import useQuery from '../../hooks/useQuery';

// Helpers
import { DOCUMENTS } from '../../core-data-service/models/Document';
import { DocumentEntitlements } from '../../types/Entitlements/EntitlementInterface';
import { SEARCH_PARAM_DOCUMENT } from '../../../core/constants';

export default function CheckoutRouter(): ReactElement{
  const search = useQuery();
  const document: keyof DocumentEntitlements = search.get( SEARCH_PARAM_DOCUMENT ) as keyof DocumentEntitlements || DOCUMENTS.will;

  const hasAlreadyPurchasedDocument = useSelector( entitlementsSelector )[document].isEnabled;
  const initialPurchaseState = useRef( hasAlreadyPurchasedDocument );
  return (
    <Switch>
      <Route exact path={ checkoutRoute.path }>
        {initialPurchaseState.current && <Redirect to={ getCheckoutNextRoute( document ).get() } /> }
        <Checkout />
      </Route>
    </Switch>
  );
}


export const getCheckoutNextRoute = ( document: keyof DocumentEntitlements ): any => {
  switch ( document ) {
  case DOCUMENTS.will:
    return will_review;
  case DOCUMENTS.trust:
    return trust_review;
  case DOCUMENTS.power_of_attorney:
    return poa_review;
  case DOCUMENTS.healthcare_directive:
    return healthcare_directive_review;
  case DOCUMENTS.medical_consent:
    return medical_consent;
  default:
    return dashboard;
  }
};
