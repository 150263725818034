import { useState, FC, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import { Area } from 'react-easy-crop/types';

import { blobToFile } from '../utils/compressImage';

import RotateRight from '../assets/icons/rotate-right.svg';
import Button from './Button';


const createImage = ( url: string ) => {
  return new Promise<HTMLImageElement>(( resolve, reject ) => {
    const image = new Image();
    image.addEventListener( 'load', () => resolve( image ));
    image.addEventListener( 'error', error => reject( error ));
    image.src = url;
  });
};


const getRadianAngle = ( degreeValue: number ): number => {
  return ( degreeValue * Math.PI ) / 180;
};


const getCroppedImg = async( imageSrc: string, pixelCrop: Area, rotation = 0 ) => {
  const image = await createImage( imageSrc );
  const canvas = document.createElement( 'canvas' );
  const ctx = canvas.getContext( '2d' );

  const maxSize = Math.max( image.width, image.height );
  const safeArea = 2 * (( maxSize / 2 ) * Math.sqrt( 2 ));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  if( !!ctx ){
    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate( safeArea / 2, safeArea / 2 );
    ctx.rotate( getRadianAngle( rotation ));
    ctx.translate( -safeArea / 2, -safeArea / 2 );

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );
    const data = ctx.getImageData( 0, 0, safeArea, safeArea );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round( 0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x ),
      Math.round( 0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y ),
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise<File>( resolve => {
      canvas.toBlob( blob => {
        if( !!blob ){
          resolve( blobToFile( blob, 'croppedImage.jpg' ));
        }
      }, 'image/jpeg' );
    });
  }
};



interface PhotoCropperProps {
  imageSrc: string;
  onCrop: ( photo: File )=> void;
  renderedAsRoute?: boolean;
}

const PhotoCropper: FC<PhotoCropperProps> = ({
  imageSrc,
  onCrop,
  renderedAsRoute,
})=> {

  const [ crop, setCrop ] = useState({ x: 0, y: 0 });
  const [ zoom, setZoom ] = useState( 1 );
  const [ rotation, setRotation ] = useState( 0 );
  const [ croppedAreaPixels, setCroppedAreaPixels ] = useState<Area|undefined>( undefined );
  const [ croppedImage, setCroppedImage ] = useState<File|undefined>( undefined );

  const onCropComplete = useCallback(( croppedArea, croppedAreaPixels ) => {
    setCroppedAreaPixels( croppedAreaPixels );
  }, []);

  const cropTheImage = useCallback( async() => {
    if( !!croppedAreaPixels ){
      try {
        const croppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels,
          rotation,
        );
        setCroppedImage( croppedImage );
      } catch ( error ) {
        console.error( error );
      }
    }
  }, [ croppedAreaPixels, rotation, imageSrc ]);

  useEffect(() => {
    if( !!croppedImage ){
      onCrop( croppedImage );
    }
  }, [ croppedImage, onCrop ]);

  return (
    <div id="PhotoCropper" className={ `${renderedAsRoute ? 'absolute' : 'relative' } flex justify-center inset-0 z-10 bg-white` }>
      {/* Crop container */}
      <div className="flex-1 flex flex-col justify-center max-w-xl p-8">
        <div className="w-full flex justify-center items-center my-4">
          <div className="aspect-w-1 aspect-h-1 relative w-full" >
            <Cropper
              aspect={ 1 }
              crop={ crop }
              cropShape="round"
              image={ imageSrc }
              onCropChange={ setCrop }
              onCropComplete={ onCropComplete }
              onRotationChange={ setRotation }
              onZoomChange={ setZoom }
              showGrid={ false }
              zoom={ zoom }
              rotation={ rotation }
              style={{
                cropAreaStyle: { color: '#fff' },
                mediaStyle: {
                  width: '100%',
                  height: 'auto',
                  maxWidth: 'initial',
                  maxHeight: 'initial',
                },
              }}
            />
          </div>
        </div>
        <div className="flex-grow-0">
          <Slider
            value={ zoom }
            min={ 1 }
            max={ 3 }
            step={ 0.1 }
            aria-labelledby="Zoom"
            onChange={ ( event, zoom ) => typeof zoom === 'number' && setZoom( zoom ) }
          />
          <div className="flex justify-center">
            <Button theme="ghost" className="mr-2" onClick={ () => setRotation( rotation + 90 ) }>
              <img style={{ width: '25px' }} src={ RotateRight } alt="rotate right" />
            </Button>
            <Button onClick={ cropTheImage }
            // isPending={ } @TODO handle pending state while processing
            >
              OK
            </Button>
            {/*
            @TODO enable cancel
            <Button onClick={ cropTheImage }>
              Cancel
            </Button>
            */}
          </div>
        </div>
      </div>
    </div>
  );
};


export default PhotoCropper;
