import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import HeirSummaryListItem from './HeirSummaryListItem';
import HeirsScenarioSummary from './HeirsScenarioSummary';
import Helper from '../../components/Helper';
import SegmentView from '../../components/SegmentView';
import { HeirsScenarioSubhead, HeirsScenarioTitle } from './HeirsStrings';

// Hooks
import useHeirs from '../../hooks/useHeirs';
import useTranslations from '../../hooks/useTranslations';
import useRenderInsuranceUpsell from '../../hooks/useRenderInsuranceUpsell';

// Redux
import { purge } from '../../store/reducers/backstackReducer';
import { useDispatch, useSelector } from '../../store';
import userPersonSelector from '../../store/selectors/userPersonSelector';

// Utils
import { isNumberEqualishTo100 } from '../../utils/numberUtils';
import { getHeirsState } from '../../hooks/useWillSectionStatus';
import analytics from '../../utils/analytics';
import InsuranceHelper from '../../components/InsuranceHelper';
import Translation from '../../components/Translation';
import Person from '../../core-data-service/models/Person';
import { separateArrayStringsByComma } from '../../../core/utils/stringHelpers';
import statesUtil from '../../utils/statesUtil';
import { heirsScenarios } from '.';

// Types
import { VideoSourceMap } from '../../components/VideoPlayer';
import { TranslationKeys } from '../../store/initial/translations';
import InheritanceInterface from '../../types/InheritanceInterface';

// Routes
import { will as willRoute } from '../../../core/routes';
import OpenVideoButton from '../../components/OpenVideoButton';

type HeirsSummaryProps = {
  videoSourceMap: VideoSourceMap;
  videoTranslationKey: TranslationKeys;
  documentRoute: string;
  flowRoute: string;
  completionEvent: string;
}


const HeirsSummary = ({ videoTranslationKey, videoSourceMap, documentRoute, flowRoute, completionEvent }: HeirsSummaryProps ): ReactElement => {

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const {
    spouse,
    spouseInheritance,
    childrenHeirs,
    childrenInheritances,
    fallbackHeirs,
    fallbackInheritances,
    sumOfChildrenShares,
    sumOfFallbackShares,
  } = useHeirs();
  const userAttributes = useSelector( state => state.user.data.attributes );
  const isRenderedInWill = documentRoute === willRoute.get();

  // could be a bug in the future, but backstack cannot work here since we can push you out to the life insurance flow
  const goBack = () => push( documentRoute );
  const hasChildren = !!childrenHeirs.length;
  const heirsState = getHeirsState( userAttributes, childrenHeirs, fallbackHeirs, sumOfChildrenShares, sumOfFallbackShares, spouse, isRenderedInWill );

  const userPerson = useSelector( userPersonSelector );
  const stateCode = userPerson?.address?.state;

  const isInheritanceShares100or0 = ( inheritances: ( InheritanceInterface | undefined )[]) => {
    const inheritancesSum = ( inheritances || [])
      .reduce(( prev: number, current ) => {
        const share = current?.share || 0;
        return share + prev;
      }, 0 );

    return isNumberEqualishTo100(
      inheritancesSum,
      0.01,
    ) || inheritancesSum === 0;
  };

  useEffect(() => {
    dispatch( purge( 'heirs' ));
  }, [ dispatch ]);

  const shouldShowInsuranceUpsells = useRenderInsuranceUpsell();

  const shouldShowInsuranceHelper = ((
    userAttributes['app.hasUnderageChildren'] && childrenHeirs.length
  ) || (
    userAttributes['app.hasSpouse'] && spouse
  ))&& shouldShowInsuranceUpsells;
  const childrenFirstNames = childrenHeirs.length ? childrenHeirs.map( child => new Person( child ).firstName ) : null;
  const spouseFirstName = spouse ? new Person( spouse ).firstName : '';

  return (
    <SegmentView
      onRetreat={ goBack }
      title={ <Translation translationKey="will.chapter.title.heirs"/> }
      subtitle={
        <OpenVideoButton
          videoSrc={ videoSourceMap }
          labelKey={ videoTranslationKey }
        />
      }
      primaryActionLabel={ <Translation translationKey="products.heirs.summary.button"/> }
      hidePrimaryAction={  heirsState.state !== 'complete' && !heirsState.canFinish }
      onPrimaryAction={ () => {
        analytics.track( completionEvent );
        goBack();
      } }
    >
      {shouldShowInsuranceHelper && <div className="border-b border-grey pb-8 mb-8">
        {/* Insurance Upsell */}
        <InsuranceHelper
          body={ <Translation translationKey="heirs.upsell.messagebubble.text" dynamicStringValues={ [ userAttributes['app.hasUnderageChildren'] ? separateArrayStringsByComma( childrenFirstNames ) : spouseFirstName ] }/> }
          primaryActionLabel={ <Translation translationKey="upsell.messagebubble.heirs.button"/> }
          eventProperties={{ button: 'HeirsSummary' }}
        />
      </div> }

      {/* check if has spouse / partner is true */}
      {spouse && ( userAttributes['app.hasSpouse'] || userAttributes['app.hasDomesticPartnership']) && (
        <HeirsScenarioSummary
          primaryAction={ () => push({ pathname: flowRoute, search: `page=${heirsScenarios.a}` }) }
          primaryActionLabel={ getTranslation( '(button)view' ) }
          title={ <HeirsScenarioTitle scenario="primary" className="font-bold text-black mt-2" /> }
          subhead={ <HeirsScenarioSubhead scenario="primary" /> }
          className="border-b border-grey pb-8 mb-8"
        >
          {!!spouse && (
            <HeirSummaryListItem
              person={ spouse }
              inheritance={ spouseInheritance }
              className="my-4 last:mb-0"
              documentRoute={ documentRoute }
            />
          )}
        </HeirsScenarioSummary>
      )}

      {/* check if hasChildren is true */}

      {hasChildren && userAttributes['app.hasChildren'] && (
        <HeirsScenarioSummary
          primaryAction={ () => push({ pathname: flowRoute, search: `page=${heirsScenarios.b}` }) }
          primaryActionLabel={ getTranslation( '(button)view' ) }
          title={ <HeirsScenarioTitle scenario="secondary" className="font-bold text-black mt-2" /> }
          subhead={ <HeirsScenarioSubhead scenario="secondary" /> }
          className="border-b border-grey pb-8 mb-8"
        >
          <>
            {hasChildren &&
              childrenInheritances &&
              childrenHeirs.map(( child, idx ) => (
                <HeirSummaryListItem
                  key={ child.id }
                  person={ child }
                  inheritance={ childrenInheritances.find(
                    inheritance =>
                      inheritance && child.id === inheritance.person_id,
                  ) }
                  className="my-4 last:mb-0"
                  documentRoute={ documentRoute }
                />
              ))}
            {!isInheritanceShares100or0( childrenInheritances ) && (
              <Helper
                className="mt-8"
                body={ <Translation translationKey={ 'will.chapter.error.heirs.child.invalid' }/> }
                theme="error"
              />
            )}
          </>
        </HeirsScenarioSummary>
      )}

      <HeirsScenarioSummary
        primaryAction={
          () => push({ pathname: flowRoute, search: `page=${heirsScenarios.c}` })
        }
        primaryActionLabel={ getTranslation( '(button)view' ) }
        // conditional here to account for if you die... & most people select?
        title={ <HeirsScenarioTitle scenario="fallback" className="font-bold text-black mt-2" /> }
        subhead={ <HeirsScenarioSubhead scenario="fallback" /> }
      >
        {( !!fallbackHeirs && fallbackHeirs.length ) ?
          <>
            {fallbackHeirs.map(( heir, idx ) => (
              <HeirSummaryListItem
                key={ heir.id }
                person={ heir }
                inheritance={ fallbackInheritances.find(
                  inheritance =>
                    inheritance && heir.id === inheritance.person_id,
                ) }
                className="my-4 last:mb-0"
                documentRoute={ documentRoute }
              />
            ))}
            {!isInheritanceShares100or0( fallbackInheritances ) && (
              <Helper
                className="mt-8"
                body={ <Translation translationKey={ 'will.chapter.error.heirs.others.invalid' }/> }
                theme="error"
              />
            )}
          </>
          :
          <Translation translationKey={ 'products.heirs.summary.no_heir.description' } dynamicStringValues={ [ stateCode ? statesUtil.stateByCode( stateCode ) : 'Your State' ] }></Translation>
        }
      </HeirsScenarioSummary>

    </SegmentView>
  );
};

export default HeirsSummary;
