import React, { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import DocumentIntro, { DocumentIntroProps } from '../../components/DocumentIntro';
import Flow from '../../components/Flow';
import Modal from '../../components/Modal';

// Custom hooks
import useFlowQueryParams from '../../hooks/useFlowQueryParams';
import useTranslations from '../../hooks/useTranslations';
import useBackstack from '../../hooks/useBackstack';

// Routes
import VideoSegmentView from '../Video/VideoSegmentView';

// Utils
import analytics from '../../utils/analytics';
import { generateQueryString } from '../../utils/urlUtils';

// Types
import { Keyable } from '../../types/Keyable';
import { VideoSourceMap } from '../../components/VideoPlayer';
import LocationState from '../../types/LocationState';

type DocumentIntroNoPrimaryActionProps = Omit<DocumentIntroProps, 'onPrimaryAction' | 'primaryActionLabel'>;

interface Props {
  onComplete: ()=> void;
  video?: {
    title: string;
    src: VideoSourceMap;
  };
  intro: DocumentIntroNoPrimaryActionProps;
  introTrackingId: string;
  introTrackingData?: Keyable;
}

const SECONDARY_PAGE = 'video';

const DocumentIntroFlow = ({ onComplete, intro, video, introTrackingId, introTrackingData = {} }: Props ): ReactElement => {
  const currentIndex = useFlowQueryParams([ SECONDARY_PAGE ]);

  const { push } = useHistory();
  const location = useLocation<LocationState>();
  const { getTranslation } = useTranslations();
  const { goBack } = useBackstack();

  const flowComponentsToRender: ReactElement[] = [
    <Modal
      closeIcon="close"
      contentClassName="bg-forest p-0"
      onClose={
        goBack
      }
    >
      <DocumentIntro
        body={ intro.body }
        image={ intro.image }
        data_tid={ 'introBegin-button' }
        minToComplete={ intro.minToComplete }
        onPrimaryAction={ () => {
          analytics.track( introTrackingId, introTrackingData );
          video
            ? push({
              pathname: location.pathname,
              search: generateQueryString({ page: SECONDARY_PAGE }),
            })
            : onComplete();
        } }
        title={ intro.title }
        primaryActionLabel={ getTranslation( '(screen)welcome(button)begin' ) }
      />
    </Modal>,
  ];

  if ( video ) {
    flowComponentsToRender.push( <VideoSegmentView
      src={ video.src }
      title={ video.title }
      onRetreat={ goBack }
      onComplete={ onComplete }
      hidePrimaryAction={ true }
    /> );
  }

  return (
    <div className="flex fixed inset-0 bg-white">
      <Flow currentIndex={ currentIndex } >
        {flowComponentsToRender.map(( item, i ) => {
          return (
            React.cloneElement( item, { key: `documentIntro-view-${i}` })
          );
        })}
      </Flow>
    </div>
  );
};

export default DocumentIntroFlow;
