import store from '../../store';
import RoleInterface from '../../types/RoleInterface';

export type RoleTypes = typeof Role.TYPE[keyof typeof Role.TYPE];
class Role {

  static STATUS_CREATED = 'CREATED';
  static STATUS_INVITED = 'INVITED';
  static STATUS_ACCEPTED = 'ACCEPTED';
  static STATUS_DECLINED = 'DECLINED';
  static STATUS_DELETED = 'DELETED';

  static TYPE = {
    // ROLE TYPES
    GRANTOR: 'GRANTOR',
    CO_GRANTOR: 'CO-GRANTOR',
    GUARDIAN: 'GUARDIAN',
    PET_GUARDIAN: 'PET GUARDIAN',
    TRUSTEE: 'TRUSTEE',
    EXECUTOR: 'EXECUTOR',
    HEALTHCARE_AGENT: 'HEALTHCARE AGENT',
    ATTORNEY_IN_FACT: 'ATTORNEY IN FACT',
    CARETAKER: 'TEMPORARY GUARDIAN',
    LENOX_ADVISOR: 'LENOX ADVISOR',
  } as const;

  /**
   * Given a role name and (optional) ward id,
   * return a list of roles sorted by priority
   * @param name
   * @param ward_id
   */
  static allPrioritized( name: string, ward_id?: string ): RoleInterface[]{
    return store.getState().roles.data
      .filter( role => Boolean(
        role.name === name &&
        Boolean( ward_id ? ward_id === role?.ward_id : true )),
      )
      .sort(( first, second ) => first.priority > second.priority ? 1 : -1 );
  }

  /**
   * Gets the current lowest priority for the given role type plus one.
   * @param name
   * @param ward_id
   */
  static getNextRolePriority( name: string, ward_id?: string ): number{
    const roles = Role.allPrioritized( name, ward_id );
    const [ last ] = [ ...roles ].reverse();
    const currentLowestPriority = last?.priority;
    if( !!currentLowestPriority && currentLowestPriority > 0 ){
      return currentLowestPriority + 1;
    }
    return 0;
  }

}

export default Role;
