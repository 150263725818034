import { ReactElement, ReactNode, Component, ErrorInfo } from 'react';
import appConfig from '../../core/appConfig';
import airbrake from '../providers/AirbrakeProvider';


interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError( _: Error ): State{
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch( error: Error, errorInfo: ErrorInfo ): void{
    console.error( 'Uncaught error:', error, errorInfo );
    airbrake.notify( error );
  }

  public render(): ReactNode{
    if( this.state.hasError ) {
      return(
        <div className="text-center m-12">
          <h1 className="text-2xl">Oops!</h1>
          <h2 className="text-xl">Something went wrong</h2>
          <p className="my-6">
            Return to&nbsp;
            <a href={ appConfig.app.baseurl } className="text-forest">
              { appConfig.app.baseurl }
            </a>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;




export function TestErrorBoundary(): ReactElement{
  throw new Error( 'Testing error boundary' );
  return(
    <div>
      This component will break
    </div>
  );
}
