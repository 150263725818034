import { cloneElement, ReactElement } from 'react';
import Translation from '../../components/Translation';
import useUserAttributes from '../../hooks/useUserAttributes';

const DocumentBasicsSummary = (): ReactElement => {
  const { hasSpouse, hasDomesticPartnership, hasChildren, hasUnderageChildren } = useUserAttributes();

  if( hasSpouse === undefined ) {
    return ( <></> );
  }

  const result: ReactElement[] = [];

  // married
  hasSpouse
    ? result.push( <Translation translationKey="will.basicinfo.answersdescription.spouseargument" /> )
    : result.push( <Translation translationKey="will.basicinfo.answersdescription.nosigotherargument"/> );
  if( !hasSpouse ) {
    ( hasDomesticPartnership
      ? result.push( <Translation translationKey="will.basicinfo.answersdescription.legalpartnerargument" /> )
      : result.push( <Translation translationKey="will.basicinfo.answersdescription.nolegalpartnerargument" /> )
    );
  }

  // children
  hasChildren
    ? result.push( <Translation translationKey="will.basicinfo.answersdescription.childrenargument" /> )
    : result.push( <Translation translationKey="will.basicinfo.answersdescription.nochildrenargument" /> );


  // underage children
  if ( hasChildren && hasUnderageChildren ) {
    result.push( <Translation translationKey="will.basicinfo.answersdescription.underagekidsargument" /> );
  }

  return ( <span>{ result.map(( res, idx ) => [ cloneElement( res, { key: idx }) , ' ' ]) } </span> );
};

export default DocumentBasicsSummary;
