import { ReactElement } from 'react';

import useOnImageLoad from '../hooks/useOnImageLoad';



export interface CircleImageProps {
  alt: string;
  className?: string;
  isPending?: boolean;
  onImageLoadDuration?: number;
  imageSrc: string;
  size?: number;
  style?: React.CSSProperties;
}


function CircleImage({
  alt,
  isPending = false,
  onImageLoadDuration = 3,
  imageSrc,
  className='',
  style = {},
}: CircleImageProps ): ReactElement{
  const { onImageLoadStyles, onImageLoad } = useOnImageLoad( onImageLoadDuration );

  return(
    <div
      className={ `
        rounded-full overflow-hidden
        aspect-h-1
        aspect-w-1
        ${isPending ? 'animate-pulse' : ''}
        ${className}`
      }
      style={{
        ...onImageLoadStyles,
        ...style,
      }}
    >
      <img
        className="object-cover"
        src={ imageSrc }
        alt={ alt }
        onLoad={ onImageLoad }
      />
    </div>
  );
}



export default CircleImage;
