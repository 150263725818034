import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

// Components
import CurrencyField from '../../../components/CurrencyField';
import HorizontalRule from '../../../components/HorizontalRule';
import PropertyOwnershipSelector from '../PropertyOwnershipSelector';
import Translation from '../../../components/Translation';
import TextField from '../../../components/TextField';

// Redux
import { useSelector } from '../../../store';

// Utils
import { PropertyDetailFormInterface } from '../../../types/Property/PropertyDetailFormInterface';

// Validators
import { fieldRequired } from '../../../formValidators/fieldRequired';


export default function PropertyDetailForm({
  control,
  errors,
  getValues,
  register,
  setValue,
}: PropertyDetailFormInterface ): ReactElement{

  const coOwnerOptions = useSelector( state => state.people.data ).filter( person => person.relationship !== 'ME' );

  const useFormProps = {
    register,
    setValue,
    errors,
    getValues,
  };

  return(
    <>
      <h2 className="mb-6">
        <Translation translationKey="screen.valuable.create.label.iteminfo"/>
      </h2>

      <TextField { ...useFormProps }
        name="name"
        label="Name (Required)"
        required={ fieldRequired( 'Name' ) }
        autoComplete="off"
      />
      <CurrencyField
        { ...useFormProps }
        label="Purchase price"
        name="purchase_price"
        control={ control }
      />
      <CurrencyField
        { ...useFormProps }
        label="Estimated value"
        name="value"
        control={ control }
      />

      {/* Ownership */}

      <HorizontalRule className="mt-8 border-black-70!"/>
      <h2 className="mb-6">
        <Translation translationKey="screen.valuable.create.label.ownership"/>
      </h2>

      <Controller
        control={ control }
        name="owners"
        render={ ({ onChange, value }) => (
          <PropertyOwnershipSelector
            onChange={ onChange }
            value={ value }
            label="Co-Owner"
            className="mb-8"
            people={ coOwnerOptions }
          />
        ) }
      />

      <Controller
        control={ control }
        name="beneficiaries"
        render={ ({ onChange, value }) => (
          <PropertyOwnershipSelector
            onChange={ onChange }
            value={ value }
            label="Beneficiary"
            className="mb-8"
            people={ coOwnerOptions }
          />
        ) }
      />
    </>
  );
}

