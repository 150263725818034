import { ReactElement } from 'react';

// Redux
import { useSelector } from 'react-redux';
import userPersonSelector from '../../store/selectors/userPersonSelector';

// Routes
import { login } from '../../../core/routes';

// Components
import { Button } from '../../components/Button';

// Utils
import { generateQueryString } from '../../utils/urlUtils';

interface Props {
  onPrimaryAction: ()=> void;
}

export default function IncompleteOnboardingPrompt({ onPrimaryAction }: Props ): ReactElement{

  const userPerson = useSelector( userPersonSelector );
  return (
    <div>
      <p>Looks like you started creating an account with <span className="font-bold text-black">{userPerson?.phone}</span>. We just need a little more info.</p>
      <div>
        <Button
          className="my-6 mx-auto"
          onClick={ onPrimaryAction }
        >
            Continue
        </Button>
      </div>
      <p className="text-center">
          Not you?
        <a
          className="inline-block ml-4 font-bold text-black"
          href={ `/logout?${generateQueryString({ next: login.get() })}` }
        >
          Sign up
        </a>
      </p>
    </div>
  );
}
