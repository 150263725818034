export const centsToUSDollars = ( value: number ): string => {
  return Intl.NumberFormat( 'en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format( value / 100 );
};

export const truncateAmount = ( dollarAmount: number ): string => {
  const compact = Intl.NumberFormat( 'en-US', { notation: 'compact' }).format( dollarAmount );

  return compact.includes( 'K' ) ? compact.toLowerCase() : compact;
};

export const removeCentsFromCurrency = ( value: number ): string => {
  return new Intl.NumberFormat( 'en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0 }).format( value );
};

