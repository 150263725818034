import { useHistory } from 'react-router-dom';

// Components
import IncompleteOnboardingPrompt from './IncompleteOnboardingPrompt';
import Modal from '../../components/Modal';
import OnboardingForms from './OnboardingForms';

// Hooks
import useAuth from '../../hooks/useAuth';
import useBackstack from '../../hooks/useBackstack';
import useQuery from '../../hooks/useQuery';
import useTranslations from '../../hooks/useTranslations';

// Routes
import { dashboard as dashboardRoute } from '../../../core/routes';

// Utils
import { getNextPageFromSearchParams } from '../../utils/urlUtils';
import { getEntitlementNameKey } from '../../utils/entitlementsUtils';
import { separateArrayStringsByComma } from '../../../core/utils/stringHelpers';
import statesUtil from '../../utils/statesUtil';

// Redux
import { useDispatch } from '../../store';
import { pop } from '../../store/reducers/backstackReducer';
import { showContextualModal } from '../../store/reducers/modalReducer';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import { useSelector } from '../../store';
import entitlementsSelector from '../../store/selectors/entitlementsSelector';
import { useState } from 'react';

export const Onboarding = (): JSX.Element => {
  const search = useQuery();
  const dispatch = useDispatch();
  const nextPage = getNextPageFromSearchParams( search );
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const { availableDocuments, unavailableDocuments } = useSelector( entitlementsSelector );
  const { goBack } = useBackstack( dashboardRoute.get());
  const { hasCompletedOnboarding, isGuestUser } = useAuth();
  const userPerson = useSelector( userPersonSelector );
  const userDisplayState = statesUtil.stateByCode( userPerson?.address.state ) || 'your state';
  const isOnboarding = localStorage.getItem( 'isOnboarding' ) === 'true';
  /**
   * these requests must be completed before determining whether a user is not onboarded
   * fetchUser is always made
   * fetchPerson is only made for an existing accout
   */
  const isFetchUserFulfilled = useSelector( state => state.user.fetchUser.meta.requestStatus === 'fulfilled' );
  const isFetchPersonFulfilled = useSelector( state => state.people.fetchPerson.meta.requestStatus !== 'pending' );

  const isReduxCompleted = isFetchUserFulfilled && isFetchPersonFulfilled;

  const [ shouldRenderModal, setShouldRenderModal ] = useState( !isGuestUser && !hasCompletedOnboarding && !isOnboarding && isReduxCompleted );
  const hasUnavailableDocuments = unavailableDocuments.length;

  return(
    <>
      <Modal
        toggleModal={ goBack }
        contentLabel="Signup"
        closeIcon="arrow-left"
      >
        <OnboardingForms
          onComplete={ () => {
            // show the unsuppoted modal if necessary
            if(
              hasUnavailableDocuments
            ) {
              const availableDocumentsTranslations   = separateArrayStringsByComma( availableDocuments.map( document => getTranslation( getEntitlementNameKey( document ))));
              const unavailableDocumentsTranslations = separateArrayStringsByComma( unavailableDocuments.map( document => getTranslation( getEntitlementNameKey( document ))));

              dispatch( showContextualModal({
                description: getTranslation( 'alert.unsupportedDocuments.description', [ unavailableDocumentsTranslations, userDisplayState, availableDocumentsTranslations ]),
                confirmLabel: getTranslation( 'alert.unsupportedDocuments.button' ),
              }));
            }
            // always remove login route from the backstack & redirect
            dispatch( pop());
            push( nextPage?.pathname || dashboardRoute.get());
          } }
        />
      </Modal>

      {/* Warning for users who have already started */}
      <Modal type="contextual" isOpen={ shouldRenderModal }>
        <IncompleteOnboardingPrompt onPrimaryAction={ () => setShouldRenderModal( false ) }/>
      </Modal>
    </>
  );
};


export default Onboarding;
