import { secondsToTimeString } from '../utils/timeUtils';

export function TimerDuration({ className = '', seconds }: {className?: string; seconds: number}): JSX.Element{
  return (
    <time
      aria-label="timer"
      dateTime={ `P${Math.round( seconds )}S` }
      className={ className }
    >
      {secondsToTimeString( seconds )}
    </time>
  );
}
