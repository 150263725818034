import { ReactElement } from 'react';
import useTranslations from '../hooks/useTranslations';
import { TranslationKeys } from '../store/initial/translations';
import appConfig from '../../core/appConfig';
import { ENV_PRODUCTION } from '../../core/constants';


export default function LocalizedImage({ imageKey, className, altText, ...props }: {
  imageKey: TranslationKeys;
  className?: string;
  altText: string;
}): ReactElement{
  const { getTranslation } = useTranslations();
  const imageSrc = getTranslation( imageKey );
  return(
    <img
      src={ imageSrc }
      alt={ altText }
      title={ appConfig.app.deploymentEnv !== ENV_PRODUCTION ? imageKey : '' }
      className={ className }
      { ...props }
    />
  );
}
