import GoalInterface from '../../types/GoalInterface';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  data: [] as Array<GoalInterface>,
  // for extra reducers
  fetchGoals: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchGoal: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postGoal: {
    meta: {} as AsyncThunkMetaInterface,
  },
  patchGoal: {
    meta: {} as AsyncThunkMetaInterface,
  },
  deleteGoal: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
