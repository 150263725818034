import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import PeopleSelector from '../../components/PeopleSelector';
import SegmentView from '../../components/SegmentView';
import HeirListItem from './HeirListItem';
import HeirsScenarioActionContainer from './HeirsScenarioActionContainer';
import Address from '../../core-data-service/models/Address';
import Translation from '../../components/Translation';
// Utils
import Relationship from '../../core-data-service/models/Relationship';
import { toTitleCase } from '../../../core/utils/stringHelpers';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import useHeirs from '../../hooks/useHeirs';
import useTranslations from '../../hooks/useTranslations';
import { useDispatch, useSelector } from '../../store';
// Redux
import { fetchInheritances, postInheritance } from '../../store/reducers/inheritancesReducer';
import userPersonSelector from '../../store/selectors/userPersonSelector';

// Types
import PersonInterface from '../../types/PersonInterface';
import { HeirsScenarioSubhead, HeirsScenarioTitle } from './HeirsStrings';

// Routes
import { trust as trustRoute } from '../../../core/routes';

const SCENARIO = 'fallback';
const PROHIBITED_HEIR_RELATIONSHIPS = 'SPOUSE,CHILDREN,ADVISORS';
const INHERITANCE_PERCENTAGE = 100;

/**
 * Filter callback people for potential fallback heirs
 * @param person
 */
export const filterEligibleFallbackHeirs = (( person: PersonInterface ): boolean => {
  const relationship = new Relationship( person.relationship );
  return Boolean(
    person.relationship !== 'ME' &&
      !relationship.isSpouse &&
      !relationship.isChild &&
      !relationship.is( 'ADVISORS' ),
  );
});

type ScenarioCProps = {
  documentRoute: string;
  flowRoute: string;
}


const ScenarioC = ({ documentRoute, flowRoute }: ScenarioCProps ): ReactElement => {
  const { push } = useHistory();
  const { fallbackHeirs } = useHeirs();

  const { getTranslation } = useTranslations();

  const [ isAddingNewHeir, setIsAddingNewHeir ] = useState<boolean>( false );

  const dispatch = useDispatch();

  const people = useSelector( state => state.people.data )
    .filter( filterEligibleFallbackHeirs )
    // don't include existing fallback heirs in picker list
    .filter( person => fallbackHeirs.findIndex( heirPerson => heirPerson.id === person.id ) < 0 );


  const handleSelect = ( id: string ) => {
    setIsAddingNewHeir( false );
    dispatch( postInheritance({
      person_id: id,
      share: INHERITANCE_PERCENTAGE,
    }));
  };

  // Title translation
  const userPerson = useSelector( userPersonSelector );
  const userAttributes = useSelector( state => state.user.data.attributes );
  const userState = userPerson?.address.state || '';
  const displayState = Address.US_STATES.find( state => state.value === userState )?.label || '';
  const scenarioTitle = getTranslation( 'inheritance.scenario.others.description', [ displayState ]);


  /**
   * In the case where the user can only see scenario C
   * Prevent them from viewing the Heirs Summary Route
   * Do not render a title, Only render a subtitle
   */
  const shouldOnlySeeScenarioC = !userAttributes['app.hasChildren'] && !userAttributes['app.hasSpouse'] && !userAttributes['app.hasDomesticPartnership'];
  const retreatRoute = shouldOnlySeeScenarioC ? documentRoute : flowRoute;

  const { goBack } = useBackstack( retreatRoute );

  return(
    <>
      {isAddingNewHeir &&
        <PeopleSelector
          isOpen
          people={ people }
          prohibitedRelationships={ PROHIBITED_HEIR_RELATIONSHIPS }
          optionTypeLabel={ toTitleCase( getTranslation( 'inheritance.displayname' )) }
          onClose={ id => {
            id ? handleSelect( id ) : setIsAddingNewHeir( false );
          } }
          onSelect={ id => {
            handleSelect( id );
          } }
          titleTranslationKey="products.will.select.an.person.title"
        />
      }
      <SegmentView
        onRetreat={ goBack }
        subhead={ <HeirsScenarioSubhead scenario={ SCENARIO }/> }
        title={ <HeirsScenarioTitle scenario={ SCENARIO }/> }
        onSecondaryAction={ () => push( documentRoute ) }
        secondaryActionChildren={ getTranslation( 'common.finish.later' ) }
        primaryActionLabel={  getTranslation( !!fallbackHeirs && fallbackHeirs.length ? 'products.will.chapter.actions.finish' : '(button)skip' ) }
        onPrimaryAction={ () => push({ pathname: retreatRoute }) }
      >
        <HeirsScenarioActionContainer
          primaryAction={ () => setIsAddingNewHeir( true ) }
          title={ !shouldOnlySeeScenarioC && <p className="text-black-70">{scenarioTitle}</p> }
          subhead={ shouldOnlySeeScenarioC
            ? <p className="mr-2"><Translation translationKey={ 'products.heirs.nofamily.description' } /></p>
            : <p className="font-bold text-black mb-2"><Translation translationKey={ 'products.heirs.scenarios.sections.others.title' }/></p> }
        >
          { fallbackHeirs && fallbackHeirs.length > 0 &&
          fallbackHeirs.map( heir => {
            return (
              <HeirListItem
                className="my-4 last:mb-0"
                key={ heir.id }
                person={ heir }
                documentContext={ documentRoute === trustRoute.get() ? 'trust' : 'will' }
              />
            );
          })
          }
        </HeirsScenarioActionContainer>

      </SegmentView>
    </>
  );
};


export default ScenarioC;
