import 'regenerator-runtime/runtime';
import { configureStore, Middleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { createSelectorHook, createDispatchHook, createStoreHook } from 'react-redux';


import rootReducer from './reducers';

import appConfig from '../../core/appConfig';

import { ENV_DEVELOPMENT, ENV_PRODUCTION } from '../../core/constants';


const logger = createLogger({
  // ...options
  collapsed: true,
});


/**
 * Global application state
 *
 * `configureStore` - https://redux-toolkit.js.org/api/configureStore
 * A friendly abstraction over the standard Redux `createStore` function that
 * adds good defaults to the store setup for a better development experience.
 */
const store = configureStore({
  reducer: rootReducer,
  devTools: appConfig.app.env !== ENV_PRODUCTION,

  /**
     * By default, `configureStore` adds some middleware to the Redux store
     * setup automatically.
     *
     * `getDefaultMiddleware` is useful if you want to add some custom
     * middleware, but also still want to have the default middleware added as
     * well.
     */
  middleware: getDefaultMiddleware => {
    const ignoredSerializations = {
      serializableCheck: {
        ignoredPaths: [
          'modal.data.contextualModal.modalProps',
          'modal.data.childrenHeirsLifeUpsell.modalProps',
          'modal.data.inviteModal.modalProps',
          'modal.data.videoModal.modalProps',
        ],
        ignoredActionPaths: [
          'meta.arg',
          'meta.baseQueryMeta',
          'payload.onConfirm',
          'payload.onDismiss',
          'payload.onSecondaryAction',
          'payload.onPrimaryAction',
          'payload.onSkip',
          'payload.onEnded',
        ],
      },
    };

    return appConfig.app.env !== ENV_DEVELOPMENT
      ? getDefaultMiddleware({
        ...ignoredSerializations,
      })
      : getDefaultMiddleware({

        // Immutability Middleware https://redux-toolkit.js.org/api/immutabilityMiddleware
        // since we are using `immer` shoudn't need this.
        // re: React-immutable-state-invariant Slows Performance #415
        // https://github.com/reduxjs/redux-toolkit/issues/415#issuecomment-596812204
        immutableCheck: false,
        ...ignoredSerializations,

      }).concat( logger );
  },
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>
export const useSelector = createSelectorHook<RootState>();
export const useDispatch: ()=> AppDispatch = createDispatchHook<RootState>();
export const useStore = createStoreHook<RootState>();

export default store;
