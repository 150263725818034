import { ReactElement } from 'react';
import { FieldErrors, UseFormMethods } from 'react-hook-form';

// Components
import { RadioGroup } from '../../../../components/Radio';

// Types
import { FormInterface } from '../../../../types/FormInterface';

export default function WishesRadioQuestion({
  question,
  register,
  setValue,
  errors,
  getValues,
}: {
    question: FormInterface;
    register: UseFormMethods['register'];
    setValue: UseFormMethods['setValue'];
    errors: FieldErrors;
    getValues:  UseFormMethods['getValues'];
  }): ReactElement{
  const useFormProps={
    register,
    setValue,
    errors,
    getValues,
  };
  return(
    <div className="flex flex-col gap-8">
      { question.input.options && <RadioGroup { ...useFormProps }
        itemsFullWidth
        label={ question.text.question }
        name={ question.id }
        items={ question.input.options }
        register={  question.input.required ? register({ required: 'You must answer this question.' }) as UseFormMethods['register']: register  }
      />}
      {question.text.footnote && <p className="text-black-70 text-sm">{question.text.footnote}</p>}
    </div>
  );
}
