const disabilityRoutes = [
  {
    key: 'disability_login',
    name: 'Disability Login',
    path: '/disability/login',
    template: 'index.html',
  },
  {
    key: 'disability_login_code',
    name: 'Disability Login Code',
    path: '/disability/login_code',
    template: 'index.html',
  },
  {
    key: 'disability_welcome',
    name: 'Disability Welcome',
    path: '/disability/welcome',
    template: 'index.html',
  },
  {
    key: 'disability',
    name: 'Disability',
    path: '/disability/',
    template: 'index.html',
  },
  {
    key: 'disability_introduction',
    name: 'Disability Introduction',
    path: '/disability/introduction',
    template: 'index.html',
  },
  {
    key: 'disability_coverage',
    name: 'Disability Coverage',
    path: '/disability/coverage',
    template: 'index.html',
  },
  {
    key: 'disability_application',
    name: 'Disability Application',
    path: '/disability/application',
    template: 'index.html',
  },
  {
    key: 'disability_payment',
    name: 'Disability Payment',
    path: '/disability/payment',
    template: 'index.html',
  },
];

export default disabilityRoutes;
