import { ReactElement, useState } from 'react';

// Components
import HorizontalRule from './HorizontalRule';
import ActionContainer from './ActionContainer';
import Modal from './Modal';
import PersonDetailView from '../views/People/PersonDetailView';
import PersonIcon from '../views/People/PersonIcon';
import Translation from './Translation';
import AddPersonButton from './AddPersonButton';

// Hooks
import useTranslations from '../hooks/useTranslations';

// Types
import Person from '../core-data-service/models/Person';
import PersonInterface from '../types/PersonInterface';
import { TranslationKeys } from '../store/initial/translations';
import { RelationshipTypes } from '../core-data-service/models/Relationship';
import { RoleTypes } from '../core-data-service/models/Role';

interface Props {
  isOpen: boolean;
  onClose: ( id?: PersonInterface['id'])=> void;
  people: PersonInterface[];
  optionTypeLabel: string;
  titleTranslationKey?: TranslationKeys;
  onSelect: ( person: PersonInterface['id'])=> void;
  prohibitedRelationships?: string;
  relationshipType?: RelationshipTypes;
  roleType?: RoleTypes;
}

export default function PeopleSelector({
  isOpen,
  onClose,
  onSelect,
  people,
  optionTypeLabel,
  titleTranslationKey = 'products.will.select.a.person.title',
  prohibitedRelationships,
  relationshipType,
  roleType,
}: Props ): ReactElement{
  const { getTranslation } = useTranslations();
  const hasNoPeopleToDisplay = people.length === 0;
  const [ isAddingNewPerson, setIsAddingNewPerson ] = useState( isOpen && hasNoPeopleToDisplay ? true : false );
  return (
    <>
      <Modal
        isOpen={ isOpen }
        onClose={ onClose }
        type="action-sheet"
      >
        <div className="md:-mb-8 md:-mt-3">
          <ActionContainer
            primaryActionLabel={ getTranslation( 'view.peoplepicker.addnew' ) }
            header={ <span className="font-bold"><Translation translationKey={ titleTranslationKey } dynamicStringValues={ [ optionTypeLabel ] } /></span> }
            primaryAction={ () => {
              setIsAddingNewPerson( true );
            } }
            className="mb-4"
          />
          <HorizontalRule className="sm-only:mx-0 mb-4!"/>
          <div className="overflow-x-scroll overflow-y-hidden whitespace-nowrap pb-6 -mx-8 px-8">
            <ul className="flex md:grid md:grid-cols-4 md:gap-4">
              {people.map(( person,i ) => {
                const _person = new Person( person );
                return (
                  <li key={ person.id } className={ `${i !== 0 ? 'ml-4 md:ml-0' : '' }` }>
                    <button
                      onClick={ () => {
                        onSelect( person.id );
                      } }
                      className="flex flex-col items-center flex-shrink-0">
                      <div className="h-14 w-14 mb-2">
                        <PersonIcon
                          alt={ `${person.name || 'Person'} - avatar` }
                          photo_url={ person.photo_url }
                          personName={ person.name }
                        />
                      </div>
                      <p>{_person.firstName}</p>
                    </button>
                  </li>
                );
              })}
              <li className="col-start-4">
                <AddPersonButton className={ 'ml-4 md:ml-0' } onClick={ () => setIsAddingNewPerson( true ) }/>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
      {isAddingNewPerson &&
        <PersonDetailView
          renderedAsRoute={ false }
          prohibitedRelationships={ prohibitedRelationships }
          relationshipType={ relationshipType }
          roleType={ roleType }
          onSuccess={ ( id: PersonInterface['id']) => {
            onSelect( id );
            setIsAddingNewPerson( false );
          } }
          onClose={ () => {
            setIsAddingNewPerson( false );
            if( hasNoPeopleToDisplay ) {
              onClose();
            }
          } }
        />
      }
    </>
  );
}
