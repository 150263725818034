import React, { ReactElement } from 'react';

import ActionContainer from '../../components/ActionContainer';

interface Props {
  className?: string;
  title: React.ReactNode;
  subhead: React.ReactNode;
  primaryAction?: ()=> void;
  primaryActionLabel?: string;
  children: React.ReactNode;
}

export default function HeirsScenarioSummary({ className = '', title, subhead, primaryAction, primaryActionLabel, children }: Props ): ReactElement{
  return (
    <div className={ `${className}` }>
      <ActionContainer
        primaryActionLabel={ primaryAction && primaryActionLabel  }
        primaryAction={ primaryAction }
        header={ <span>{subhead}</span> }
      >
        {title}
        {children}
      </ActionContainer>
    </div>
  );
}
