import { FC, Children } from 'react';
import { Link } from 'react-router-dom';




export const DashboardSection: FC = ({ children }) => {
  return(
    <section className="container w-full mx-auto">
      <div className="bg-white rounded">
        <div className="flex flex-wrap p-5">
          {
            Children.map( children, ( child, idx ) =>
              <div key={ idx }
                className="w-full p-5 md:w-1/2 lg:w-1/3 object-contain flex"
              >
                { child }
              </div>,
            )
          }
        </div>
      </div>
    </section>
  );
};





interface DashboardSectionBlockHeaderProps {
  ctaLabel: string;
  ctaURL: string;
  mainLabel: string;
  className: string;
}

export const DashboardSectionBlockHeader: FC<DashboardSectionBlockHeaderProps> = ({
  ctaLabel,
  ctaURL,
  mainLabel,
  className,
}) => {
  return(
    <div className={ `flex flex-wrap items-center ${className}` }>
      <h5 className="w-full flex-grow flex-1 font-bold">
        { mainLabel }
      </h5>
      <h5 className="w-full flex-grow flex-1 text-right">
        <Link to={ ctaURL } className="font-bold text-forest">
          { ctaLabel }
        </Link>
      </h5>
    </div>
  );
};






interface DashboardSectionBlockProps {
  className?: string;
  ctaLabel: string;
  ctaURL: string;
  mainLabel: string;
}

export const DashboardSectionBlock: FC<DashboardSectionBlockProps> = ({
  children,
  className = '',
  ctaLabel,
  ctaURL,
  mainLabel,
}) => {
  return(
    <div className={ `w-full block ${className}` }>
      <DashboardSectionBlockHeader
        mainLabel={ mainLabel }
        ctaLabel={ ctaLabel }
        ctaURL={ ctaURL }
        className="mb-6"
      />
      { children }
    </div>
  );
};





export const DashboardSectionAlert: FC = ({ children }) => {
  return(
    <div className="rounded bg-salamander p-5 flex flex-col justify-between h-48">
      { children }
    </div>
  );
};


export default DashboardSection;
