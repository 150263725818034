import { PropertyInterface } from '../types/Property/PropertyInterface';
import { useSelector } from '../store';
import Property from '../core-data-service/models/Property';


interface UseProperties {
  isPendingPropertySave: boolean;
  isFetchingProperties: boolean;
  isPendingPropertyDelete: boolean;
  properties: PropertyInterface[];
  pets: PropertyInterface[];
  realEstate: PropertyInterface[];
  valuables: PropertyInterface[];
}


export default function useProperties(): UseProperties{

  const isPendingPropertyPatch = useSelector( state => state.properties.patchProperty.meta.requestStatus === 'pending' );
  const isPendingPropertyPost = useSelector( state => state.properties.postProperty.meta.requestStatus === 'pending' );
  const isPendingPropertySave = isPendingPropertyPatch || isPendingPropertyPost;
  const isFetchingProperties = useSelector( state => state.properties.patchProperty.meta.requestStatus === 'pending' );
  const isPendingPropertyDelete = useSelector( state => state.properties.patchProperty.meta.requestStatus === 'pending' );

  const properties = useSelector( state => state.properties.data );

  const { pets, valuables, realEstate } = properties.reduce(( acc, curr ) => {
    if ( curr.type === Property.TYPE.PET ) {
      acc.pets.push( curr );
    } else if ( curr.type === Property.TYPE.REAL_ESTATE ) {
      acc.realEstate.push( curr );
    } else if ( Property.VALUABLE_TYPES.includes( curr.type )) {
      acc.valuables.push( curr );
    }
    return acc;
  }, {
    pets: [],
    realEstate: [],
    valuables: [],
  } as {
    pets: PropertyInterface[];
    realEstate: PropertyInterface[];
    valuables: PropertyInterface[];
  });

  return {
    isPendingPropertySave,
    isFetchingProperties,
    isPendingPropertyDelete,
    properties,
    pets,
    realEstate,
    valuables,
  };
}
