


export const splitFirstAndLastName = ( fullName?: string|null ): [string, string] => {
  const split = fullName ? fullName.match( /^(\S+)\s(.*)/ )?.slice( 1 ) : [];
  const firstName = split ? split[0] : '';
  const lastName = split ? split[1] : '';
  return [ firstName || '', lastName || '' ];
};

export const separateArrayStringsByComma = ( values: string[] | null ): string => {
  if( !values || !values.length ) {
    return '';
  }
  if( values.length === 1 ) {
    return values.join();
  }
  if( values.length === 2 ) {
    return `${values[0]} and ${values[1]}`;
  }
  const lastValue = values[values.length - 1];
  const valuesMinusLast = values.slice( 0,-1 );
  return `${valuesMinusLast.join( ', ' )} and ${lastValue}`;
};


/**
 * Remove excess whitespace from string value
 */
export const trimWhitespace = ( value: string ): string =>
  value.trim() // trim whitespace off beggining and end of string
    .replace( /\t/g, ' ' ) // convert tabs to spaces
    .replace( / +/g, ' ' ); // replace any multiple spaces in string to single


export function toTitleCase( str: string ): string{
  return str.replace(
    /\w\S*/g,
    function( txt ){
      return txt.charAt( 0 ).toUpperCase() + txt.substr( 1 ).toLowerCase();
    },
  );
}

// Takes a phrase separated by a string (usually hyphen or space e.g. mother-in-law) and capitalizes all first letters.
export function capitalizeAllFirstLetters( str: string, joiner: string ): string{
  return str
    .split( `${joiner}` )
    .map( word => word === 'in' ? word : toTitleCase( word ))
    .join( `${joiner}` );
}

export const nameInitials = ( name: string ): string => {
  const formattedName = name.trim();
  if ( !formattedName ) {
    return '';
  }
  const initials = formattedName.split( ' ' ).map( word => word ? word[0].toUpperCase() : '' );
  return initials[0] + (( initials.length > 1 ) ? initials[initials.length - 1] : '' );
};

export const currency = ( amount: number ): string => {
  const formatter = new Intl.NumberFormat(
    'en-US',
    {
      style: 'currency',
      currency: 'USD',
    });
  return formatter.format( amount );
};
