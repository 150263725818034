import { useDispatch } from 'react-redux';

// Assets
import ethosLogo from '../../assets/images/logos/ethosLogo.svg';

// Components
import Button from '../../components/Button';

// Redux
import { showModal } from '../../store/reducers/modalReducer';
import { GlobalModals } from '../../store/initial/modal';
import useTranslations from '../../hooks/useTranslations';
import Translation from '../../components/Translation';

const Footer = ({ className = '', centerTextOnMobile = false }: {className?: string; centerTextOnMobile?: boolean}): JSX.Element => {
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();

  return(
    <footer
      className={ `
        ${centerTextOnMobile ? 'text-center' : ''}
        ${className}`
      }
    >
      <div className="container">
        <hr className="md:hidden -mx-8 border-t border-grey"/>
        <div className="md:flex justify-between py-8">
          <div className="md:flex items-end sm-only:mb-4">
            <div className="sm-only:mb-4 mr-6">
              &copy; {new Date().getFullYear()} Ethos Estate Planning, LLC
            </div>
            <div className="flex items-end">
              <img src={ ethosLogo } alt="Ethos Logo" className="w-6 mr-1 mb-[-2px]" />
              An Ethos Technologies company
            </div>
          </div>
          <nav>
            <ul className={ `flex ${centerTextOnMobile ? 'justify-center' : ''}` }>
              <li className="md:ml-6">
                <a href={ getTranslation( 'appinfo.privacy.url' ) } target="_blank" rel="noopener noreferrer nofollow">
                  <Translation translationKey="appinfo.privacy.title"/>
                </a>
              </li>
              <li className="ml-6">
                <a href="https://plan.ethoslife.com/terms/" target="_blank" rel="noopener noreferrer nofollow">Terms</a>
              </li>
              <li className="ml-6">
                <Button theme="clean-secondary" className="!font-regular hover:!text-black-70 !static" onClick={ () => dispatch( showModal( GlobalModals.help )) }>Help</Button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};



export default Footer;
