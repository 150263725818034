// careful here, safari will not `execCommand` in response to an API call
// it must be in direct response to a user action
const copyToClipboard = ( textToCopy: string ) => {
  const textArea = document.createElement( 'textarea' );
  textArea.value = textToCopy;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild( textArea );
  textArea.focus();
  textArea.select();
  document.execCommand( 'copy' );
  textArea.remove();
};

const useClipboard = (): {
  copyToClipboard: ( textToCopy: string )=> void;
} => {
  return {
    copyToClipboard,
  };
};

export default useClipboard;
