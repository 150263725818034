import { useSelector } from '../store';
import PowersInterface from '../types/powersInterface';
import { powersSelector } from '../store/selectors/powersSelector';
import { FormInterface, FormAnswersInterface } from '../types/FormInterface';

type usePowersInterface = {
  powersData: PowersInterface[];
  powers: FormAnswersInterface;
  powersForm: FormInterface[];
  powersWithoutInstructions: FormAnswersInterface;
  instructions: boolean | string | undefined | null;
 }

const usePowers = ():  usePowersInterface => {
  const powersData = useSelector( powersSelector );
  const powersForm = powersData[0] ? powersData[0].form : [];
  const powers = powersData[0] ? powersData[0].powers : {};
  const { instructions, ...powersWithoutInstructions } = powers;
  return { powersData, powers, powersForm, instructions, powersWithoutInstructions };
};

export default usePowers;
