import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Hooks
import useTranslations from '../../hooks/useTranslations';

// Components
import Button from '../../components/Button';
import InternalDescriptiveLink from '../../components/InternalDescriptiveLink';
import Icon from '../../components/Icon';
import { Link } from 'react-router-dom';
import Translation from '../../components/Translation';

// Redux
import { makeSnack } from '../../store/reducers/snackbarReducer';
import { useDispatch, useSelector } from '../../store';

// Routes
import {
  whatIsTomorrow as whatIsTomorrowRoute,
  login as loginRoute,
} from '../../../core/routes';

// Utils
import analytics from '../../utils/analytics';
import {
  LOCAL_STORAGE_DELETED_ACCOUNT,
  LOCAL_STORAGE_SIGNED_OUT,
  ONE_MINUTE,
} from '../../../core/constants';
import { ethosPartnerCodeSelector } from '../../store/selectors/userSelector';

interface DefaultHomeProps {
  primaryAction: ()=> void;
}

export default function DefaultHome({
  primaryAction,
}: DefaultHomeProps ): ReactElement{
  const { location } = useHistory();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();

  // Check for stateful partner code
  const shouldShowSignInLink = !useSelector( ethosPartnerCodeSelector );

  const secondaryAction = () => {
    analytics.track( 'Touch - Login Screen', 'sign in' );
  };

  const deletedTranslation = getTranslation( 'alert.deleteuser.userdeleted' );
  const signedOutTranslation = getTranslation( 'alert.signout.usersignedout' );

  useEffect(() => {
    const now = new Date().getTime();
    if (
      localStorage.getItem( LOCAL_STORAGE_DELETED_ACCOUNT ) &&
      now -
        new Date(
          localStorage.getItem( LOCAL_STORAGE_DELETED_ACCOUNT ) || '',
        ).getTime() <=
        ONE_MINUTE
    ) {
      dispatch( makeSnack({ message: deletedTranslation, theme: 'warning' }));
      localStorage.removeItem( LOCAL_STORAGE_DELETED_ACCOUNT );
    }
    if (
      localStorage.getItem( LOCAL_STORAGE_SIGNED_OUT ) &&
      now -
        new Date(
          localStorage.getItem( LOCAL_STORAGE_SIGNED_OUT ) || '',
        ).getTime() <=
        ONE_MINUTE
    ) {
      dispatch( makeSnack({ message: signedOutTranslation, theme: 'warning' }));
      localStorage.removeItem( LOCAL_STORAGE_SIGNED_OUT );
    }
  }, [ dispatch, deletedTranslation, signedOutTranslation ]);

  return (
    <div className="p-8 md:p-12 flex justify-center h-full bg-white">
      <div className="flex flex-col h-full md:max-w-sm">
        <div className="flex flex-col sm-only:justify-center sm-only:h-1/3">
          <Icon name="woodmark" className="text-forest h-5 w-auto" />
        </div>
        <div className="flex flex-col text-center items-center justify-center flex-1">
          <div className="flex flex-col text-center items-center sm-only:flex-1">
            <h1 className="text-3xl text-black md:2/3 md:text-5xl font-headline">
              <Translation translationKey="epp.onboarding.landing.title" />
            </h1>
            <p className="text-center mx-auto w-4/5 sm:w-80 my-8">
              <Translation translationKey="epp.onboarding.landing.description" />
            </p>
            <Link
              className="flex items-center font-bold hover:text-salamander w-max sm-only:mb-12"
              to={{
                pathname: whatIsTomorrowRoute.get(),
                state: { background: location },
              }}
            >
              <Icon className="text-salamander mr-2" name="play-circle" />
              <span className="text-black">
                <Translation
                  translationKey={ 'dashboard.signedout.video_button' }
                />
              </span>
            </Link>
          </div>
          <Button
            theme="tertiary"
            className="my-6 w-full md:max-w-max md:self-center"
            id={ 'getStarted-landingPage' }
            onClick={ primaryAction }
          >
            <Translation translationKey="epp.onboarding.landing.button.text" />
          </Button>
        </div>
        {shouldShowSignInLink && (
          <div>
            <InternalDescriptiveLink
              descriptionKey="epp.onboarding.landing.signInLink.description"
              labelKey="epp.onboarding.landing.signInLink.ctaLabel"
              linkLocationObject={{
                pathname: loginRoute.get(),
                state: { background: location },
              }}
              onClick={ secondaryAction }
            />
          </div>
        )}
      </div>
    </div>
  );
}
