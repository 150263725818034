import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// Utils
import { useSelector, useDispatch } from '../../store';
import { deleteUser } from '../../store/reducers/userReducer';

// Actions
import { patchPerson } from '../../store/reducers/peopleReducer';
import { patchUser } from '../../store/reducers/userReducer';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import { showContextualModal } from '../../store/reducers/modalReducer';

// Hooks
import useTranslations from '../../hooks/useTranslations';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import Modal from '../../components/Modal';
import TextField from '../../components/TextField';
import SelectField from '../../components/SelectField';
import AddressFields from '../../components/AddressFields';

// Routes
import { dashboard as dashboardRoute } from '../../../core/routes';

// Utils
import userSelector from '../../store/selectors/userSelector';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import appConfig from '../../../core/appConfig';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { splitFirstAndLastName } from '../../../core/utils/stringHelpers';
import Translation from '../../components/Translation';
import { fieldRequired, phoneNumberMinLength, zipMinLength } from '../../formValidators';

// Constants
import { LOCAL_STORAGE_DELETED_ACCOUNT, LOCAL_STORAGE_SIGNED_OUT, PHONE_MIN_LENGTH } from '../../../core/constants';

// Redux
import { showModal } from '../../store/reducers/modalReducer';
import { GlobalModals } from '../../store/initial/modal';

const Profile: FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [ isOpen, setIsOpen ] = useState( true );
  const { getTranslation } = useTranslations();


  // Application state
  // const accessToken = useSelector( state => state.auth.access_token );
  const user = useSelector( userSelector );
  const userPerson = useSelector( userPersonSelector );
  const isPendingPerson = useSelector( state => state.people.patchPerson.meta.requestStatus === 'pending' );
  const isPendingUser = useSelector( state => state.user.patchUser.meta.requestStatus === 'pending' );
  const isPending = isPendingPerson || isPendingUser;

  // Get field default values based on saved data from store
  const { name, gender, address } = userPerson || { name: '', gender: '' };
  const firstName = splitFirstAndLastName( name )[0];
  const lastName = splitFirstAndLastName( name )[1];
  const { phone, email } = user;

  // onSubmit: Should patch user and person
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    const fullName = ( data.firstName + ' ' + data.lastName ) || null;
    data.name = fullName;
    const { phone, email, street, street2, city, state, zip , ...personFields } = data;
    const address = { street, street2, city, state, zip };
    const personData = { id: user.person_id, address, ...personFields };
    const userData = { phone, email };

    const onError = () => {
      dispatch( makeSnack({ message: getTranslation( 'banner.family.person.myinfoupdated.error' ), theme: 'error' }));
    };

    dispatch( patchPerson({ ...personData, onError, onSuccess: () => {
      dispatch( patchUser({ ...userData,
        onSuccess: () => {
          dispatch( makeSnack({ message: getTranslation( 'banner.family.person.myinfoupdated' ), theme: 'warning' }));
        },
        onError,
      }));
    } }));


  };

  const defaultValues = {
    firstName,
    lastName,
    name,
    gender,
    phone,
    email,
    ...address,
  };

  // these fields should only be required if you've already input them
  const addressRequiredFields = {};
  address?.zip && Object.assign( addressRequiredFields, {
    zip: {
      minLength: zipMinLength( getTranslation( 'error.missing.zip' )),
      required: fieldRequired( getTranslation( 'error.missing.zip' )),
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    getValues,
    register,
    setValue,
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
  });

  const useFormProps = {
    errors,
    getValues,
    register,
    setValue,
  };

  // @TODO - trigger close and do this automatically
  const onClose = () => {
    setIsOpen( false );
    setTimeout(() => push( dashboardRoute.get()), 300 );
  };

  /**
   * Dispatch delete account thunk
   */
  const onDeleteAccount = () => {
    dispatch( showContextualModal({
      description: getTranslation( 'profile.delete.confirm.title' ),
      dismissLabel: getTranslation( '(button)cancel' ),
      confirmLabel: getTranslation( '(button)delete' ),
      onConfirm: () => {
        dispatch( deleteUser({ onSuccess: () => {
          localStorage.setItem( LOCAL_STORAGE_DELETED_ACCOUNT, new Date().toISOString());
          window.location.href = combineURLs( appConfig.app.baseurl, '/logout' );
        } }));
      },
    }));
  };

  const onSignOut = () => {
    localStorage.setItem( LOCAL_STORAGE_SIGNED_OUT, new Date().toISOString());
    window.location.href = combineURLs( appConfig.app.baseurl, '/logout' );
  };

  return (
    <Modal
      onClose={ onClose }
      isOpen={ isOpen }
    >
      <h1 className="text-4xl mb-8 text-black font-headline"><Translation translationKey={ 'profile.title' }/></h1>
      <Form
        onSubmit={ onSubmit }
        handleSubmit={ handleSubmit }
        className=""
      >
        <TextField { ...useFormProps }
          name="firstName"
          label="First Name"
          className="mb-4"
          required={ fieldRequired( getTranslation( 'addperson.error.first_name.error' )) }
          normalize={ ( input: string ) => input.trimStart() }
        />
        <TextField { ...useFormProps }
          name="lastName"
          label="Last Name"
          className="mb-4"
          required={ fieldRequired( getTranslation( 'addperson.error.last_name.error' )) }
          normalize={ ( input: string ) => input.trimStart() }
        />
        <TextField { ...useFormProps }
          name="phone"
          disabled
          label={ <Translation translationKey={ 'profile.phone.label' }/> }
          className="mb-1 cursor-not-allowed"
          required={ fieldRequired( getTranslation( 'error.invalid.phone' )) }
          minLength={ phoneNumberMinLength( getTranslation( 'error.invalid.phone' ), PHONE_MIN_LENGTH ) }
          inputClasses="!bg-grey-400 cursor-not-allowed"
          type="tel"
        />
        <div className="mb-4 text-xs flex justify-between"><Translation translationKey={ 'profile.customersupport.contact.title' }/><Button type="button" className="font-bold text-forest" theme="clean-secondary" onClick={ () => dispatch( showModal( GlobalModals.help )) }>Help</Button></div>
        <TextField { ...useFormProps }
          name="email"
          label={ <Translation translationKey={ 'profile.email.label' }/> }
          className="mb-4"
          required={ fieldRequired( getTranslation( 'error.invalid.email' )) }
          type="email"
        />
        <SelectField
          className="mt-6 mb-4"
          { ...useFormProps }
          label={ <Translation translationKey={ 'profile.gender.title' } /> }
          name="gender"
          uniqueId="gender"
          options={
            [
              { label: getTranslation( 'account.signup.name.fields.label.male' ), value: 'MALE' },
              { label: getTranslation( 'account.signup.name.fields.label.female' ), value: 'FEMALE' },
              { label: getTranslation( 'account.signup.name.fields.label.unspecified' ), value: 'UNSPECIFIED' },
            ]
          }
        />
        <AddressFields
          { ...useFormProps }
          required={ addressRequiredFields }
        />
        <div className="mt-10 flex justify-center">
          <Button type="submit" isPending={ isPending }><Translation translationKey={ 'profile.save.button' }/></Button>
        </div>
      </Form>
      <div className="-mx-8 mt-8 border-t border-grey">
        <div className="flex justify-center">
          <Button type="button" theme="clean" className="flex-1 !py-4 hover:bg-forest hover:text-white" onClick={ onSignOut }>
            <Translation translationKey={ 'profile.signout.button' }/>
          </Button>
        </div>
      </div>
      <div className="-mx-8 -mb-8 border-t border-grey">
        <div className="flex justify-center">
          <Button type="button" theme="clean-danger" className="flex-1 !py-4 hover:bg-red hover:text-white" onClick={ onDeleteAccount }>
            <Translation translationKey={ 'profile.delete.button' }/>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Profile;
