
/** PROPERTY ICONS */
import img_401a_dark from './401a_dark.png';
import img_401a_light from './401a_light.png';
import img_401k_dark from './401k_dark.png';
import img_401k_light from './401k_light.png';
import img_403b_dark from './403b_dark.png';
import img_403b_light from './403b_light.png';
import img_457b_dark from './457b_dark.png';
import img_457b_light from './457b_light.png';
import img_529_dark from './529_dark.png';
import img_529_light from './529_light.png';
import img_ad__amp__d_dark from './ad__amp__d_dark.png';
import img_ad__amp__d_light from './ad__amp__d_light.png';
import img_ad__amp__d_offer_dark from './ad__amp__d_offer_dark.png';
import img_ad__amp__d_offer_light from './ad__amp__d_offer_light.png';
import img_airline_miles_dark from './airline_miles_dark.png';
import img_airline_miles_light from './airline_miles_light.png';
import img_airplane_dark from './airplane_dark.png';
import img_airplane_light from './airplane_light.png';
import img_annuity_dark from './annuity_dark.png';
import img_annuity_light from './annuity_light.png';
import img_antiques_dark from './antiques_dark.png';
import img_antiques_light from './antiques_light.png';
import img_appliances_dark from './appliances_dark.png';
import img_appliances_light from './appliances_light.png';
import img_artwork_dark from './artwork_dark.png';
import img_artwork_light from './artwork_light.png';
import img_asset_dark from './asset_dark.png';
import img_asset_light from './asset_light.png';
import img_auto_loan_dark from './auto_loan_dark.png';
import img_auto_loan_light from './auto_loan_light.png';
import img_backpack_dark from './backpack_dark.png';
import img_backpack_light from './backpack_light.png';
import img_bedding_dark from './bedding_dark.png';
import img_bedding_light from './bedding_light.png';
import img_bicycle_dark from './bicycle_dark.png';
import img_bicycle_light from './bicycle_light.png';
import img_bird_dark from './bird.svg';
import img_bird_light from './bird.svg';
import img_boat_dark from './boat_dark.png';
import img_boat_light from './boat_light.png';
import img_books_dark from './books_dark.png';
import img_books_light from './books_light.png';
import img_bracelet_dark from './bracelet_dark.png';
import img_bracelet_light from './bracelet_light.png';
import img_brokerage_account_dark from './brokerage_account_dark.png';
import img_brokerage_account_light from './brokerage_account_light.png';
import img_brokerage_dark from './brokerage_dark.png';
import img_brokerage_light from './brokerage_light.png';
import img_building_dark from './building_dark.png';
import img_building_light from './building_light.png';
import img_camera_dark from './camera_dark.png';
import img_camera_light from './camera_light.png';
import img_car_dark from './car_dark.png';
import img_car_light from './car_light.png';
import img_car_loan_dark from './car_loan_dark.png';
import img_car_loan_light from './car_loan_light.png';
import img_cash_dark from './cash_dark.png';
import img_cash_light from './cash_light.png';
import img_cat_dark from './cat.svg';
import img_cat_light from './cat.svg';
import img_cd_dark from './cd_dark.png';
import img_cd_light from './cd_light.png';
import img_checking_dark from './checking_dark.png';
import img_checking_light from './checking_light.png';
import img_china_dark from './china_dark.png';
import img_china_light from './china_light.png';
import img_clothing_dark from './clothing_dark.png';
import img_clothing_light from './clothing_light.png';
import img_co__dash__op_dark from './co__dash__op_dark.png';
import img_co__dash__op_light from './co__dash__op_light.png';
import img_coats_dark from './coats_dark.png';
import img_coats_light from './coats_light.png';
import img_coins_dark from './coins_dark.png';
import img_coins_light from './coins_light.png';
import img_computer_dark from './computer_dark.png';
import img_computer_light from './computer_light.png';
import img_condo_dark from './condo_dark.png';
import img_condo_light from './condo_light.png';
import img_console_dark from './console_dark.png';
import img_console_light from './console_light.png';
import img_cookware_dark from './cookware_dark.png';
import img_cookware_light from './cookware_light.png';
import img_copyright_dark from './copyright_dark.png';
import img_copyright_light from './copyright_light.png';
import img_corporation_dark from './corporation_dark.png';
import img_corporation_light from './corporation_light.png';
import img_credit_card_dark from './credit_card_dark.png';
import img_credit_card_light from './credit_card_light.png';
import img_crypto_dark from './crypto_dark.png';
import img_crypto_light from './crypto_light.png';
import img_dog_dark from './dog.svg';
import img_dog_light from './dog.svg';
import img_dolls_dark from './dolls_dark.png';
import img_dolls_light from './dolls_light.png';
import img_drone_dark from './drone_dark.png';
import img_drone_light from './drone_light.png';
import img_earrings_dark from './earrings_dark.png';
import img_earrings_light from './earrings_light.png';
import img_education_savings_account_dark from './education_savings_account_dark.png';
import img_education_savings_account_light from './education_savings_account_light.png';
import img_equipment_dark from './equipment_dark.png';
import img_equipment_light from './equipment_light.png';
import img_equity_dark from './equity_dark.png';
import img_equity_light from './equity_light.png';
import img_farm_dark from './farm_dark.png';
import img_farm_light from './farm_light.png';
import img_fixed_annuity_dark from './fixed_annuity_dark.png';
import img_fixed_annuity_light from './fixed_annuity_light.png';
import img_flatware_dark from './flatware_dark.png';
import img_flatware_light from './flatware_light.png';
import img_furniture_dark from './furniture_dark.png';
import img_furniture_light from './furniture_light.png';
import img_games_dark from './games_dark.png';
import img_games_light from './games_light.png';
import img_glassware_dark from './glassware_dark.png';
import img_glassware_light from './glassware_light.png';
import img_group_life_dark from './group_life_dark.png';
import img_group_life_light from './group_life_light.png';
import img_guns_dark from './guns_dark.png';
import img_guns_light from './guns_light.png';
import img_health_savings_account_dark from './health_savings_account_dark.png';
import img_health_savings_account_light from './health_savings_account_light.png';
import img_home_equity_loan_dark from './home_equity_loan_dark.png';
import img_home_equity_loan_light from './home_equity_loan_light.png';
import img_house_dark from './house_dark.png';
import img_house_light from './house_light.png';
import img_instruments_dark from './instruments_dark.png';
import img_instruments_light from './instruments_light.png';
import img_ira_dark from './ira_dark.png';
import img_ira_light from './ira_light.png';
import img_job_benefit_dark from './job_benefit_dark.png';
import img_job_benefit_light from './job_benefit_light.png';
import img_journals_dark from './journals_dark.png';
import img_journals_light from './journals_light.png';
import img_kayak_dark from './kayak_dark.png';
import img_kayak_light from './kayak_light.png';
import img_land_dark from './land_dark.png';
import img_land_light from './land_light.png';
import img_laptop_dark from './laptop_dark.png';
import img_laptop_light from './laptop_light.png';
import img_lighting_dark from './lighting_dark.png';
import img_lighting_light from './lighting_light.png';
import img_limited_partnership_dark from './limited_partnership_dark.png';
import img_limited_partnership_light from './limited_partnership_light.png';
import img_liquor_dark from './liquor_dark.png';
import img_liquor_light from './liquor_light.png';
import img_loan_dark from './loan_dark.png';
import img_loan_light from './loan_light.png';
import img_memorabilia_dark from './memorabilia_dark.png';
import img_memorabilia_light from './memorabilia_light.png';
import img_minor_custodial_account_dark from './minor_custodial_account_dark.png';
import img_minor_custodial_account_light from './minor_custodial_account_light.png';
import img_money_market_dark from './money_market_dark.png';
import img_money_market_light from './money_market_light.png';
import img_motorcycle_dark from './motorcycle_dark.png';
import img_motorcycle_light from './motorcycle_light.png';
import img_movies_dark from './movies_dark.png';
import img_movies_light from './movies_light.png';
import img_music_dark from './music_dark.png';
import img_music_light from './music_light.png';
import img_necklace_dark from './necklace_dark.png';
import img_necklace_light from './necklace_light.png';
import img_non__dash__taxable_brokerage_account_dark from './non__dash__taxable_brokerage_account_dark.png';
import img_non__dash__taxable_brokerage_account_light from './non__dash__taxable_brokerage_account_light.png';
import img_other_bank_dark from './other_bank_dark.png';
import img_other_bank_light from './other_bank_light.png';
import img_other_collection_dark from './other-default.svg';
import img_other_collection_light from './other-default.svg';
import img_other_electronics_dark from './other-default.svg';
import img_other_electronics_light from './other-default.svg';
import img_other_household_dark from '././other-default.svg';
import img_other_household_light from '././other-default.svg';
import img_other_investment_dark from './other_investment_dark.png';
import img_other_investment_light from './other_investment_light.png';
import img_other_jewelry_dark from './other-default.svg';
import img_other_jewelry_light from './other-default.svg';
import img_other_loan_dark from './other_loan_dark.png';
import img_other_loan_light from './other_loan_light.png';
import img_other_pet_dark from './other-pet.svg';
import img_other_pet_light from './other-pet.svg';
import img_other_property_dark from './other_property_dark.png';
import img_other_property_light from './other_property_light.png';
import img_other_recreation_dark from './other-default.svg';
import img_other_recreation_light from './other-default.svg';
import img_other_vehicle_dark from './other-default.svg';
import img_other_vehicle_light from './other-default.svg';
import img_partnership_dark from './partnership_dark.png';
import img_partnership_light from './partnership_light.png';
import img_patent_dark from './patent_dark.png';
import img_patent_light from './patent_light.png';
import img_pension_dark from './pension_dark.png';
import img_pension_light from './pension_light.png';
import img_permanent_life_dark from './permanent_life_dark.png';
import img_permanent_life_light from './permanent_life_light.png';
import img_personal_loan_dark from './personal_loan_dark.png';
import img_personal_loan_light from './personal_loan_light.png';
import img_phone_dark from './phone_dark.png';
import img_phone_light from './phone_light.png';
import img_photos_dark from './photos_dark.png';
import img_photos_light from './photos_light.png';
import img_profit_sharing_plan_dark from './profit_sharing_plan_dark.png';
import img_profit_sharing_plan_light from './profit_sharing_plan_light.png';
import img_recipes_dark from './recipes_dark.png';
import img_recipes_light from './recipes_light.png';
import img_ring_dark from './ring_dark.png';
import img_ring_light from './ring_light.png';
import img_roth_401k_dark from './roth_401k_dark.png';
import img_roth_401k_light from './roth_401k_light.png';
import img_roth_ira_dark from './roth_ira_dark.png';
import img_roth_ira_light from './roth_ira_light.png';
import img_rv_dark from './rv_dark.png';
import img_rv_light from './rv_light.png';
import img_savings_dark from './savings_dark.png';
import img_savings_light from './savings_light.png';
import img_season_tickets_dark from './season_tickets_dark.png';
import img_season_tickets_light from './season_tickets_light.png';
import img_sep_ira_dark from './sep_ira_dark.png';
import img_sep_ira_light from './sep_ira_light.png';
import img_simple_ira_dark from './simple_ira_dark.png';
import img_simple_ira_light from './simple_ira_light.png';
import img_skateboard_dark from './skateboard_dark.png';
import img_skateboard_light from './skateboard_light.png';
import img_skis_dark from './skis_dark.png';
import img_skis_light from './skis_light.png';
import img_sleeping_bag_dark from './sleeping_bag_dark.png';
import img_sleeping_bag_light from './sleeping_bag_light.png';
import img_snowboard_dark from './snowboard_dark.png';
import img_snowboard_light from './snowboard_light.png';
import img_snowmobile_dark from './snowmobile_dark.png';
import img_snowmobile_light from './snowmobile_light.png';
import img_snowshoes_dark from './snowshoes_dark.png';
import img_snowshoes_light from './snowshoes_light.png';
import img_stamps_dark from './stamps_dark.png';
import img_stamps_light from './stamps_light.png';
import img_stereo_dark from './stereo_dark.png';
import img_stereo_light from './stereo_light.png';
import img_stock_plan_dark from './stock_plan_dark.png';
import img_stock_plan_light from './stock_plan_light.png';
import img_student_loan_dark from './student_loan_dark.png';
import img_student_loan_light from './student_loan_light.png';
import img_surfboard_dark from './surfboard_dark.png';
import img_surfboard_light from './surfboard_light.png';
import img_suv_dark from './suv_dark.png';
import img_suv_light from './suv_light.png';
import img_tablet_dark from './tablet_dark.png';
import img_tablet_light from './tablet_light.png';
import img_tax_debt_dark from './tax_debt_dark.png';
import img_tax_debt_light from './tax_debt_light.png';
import img_tent_dark from './tent_dark.png';
import img_tent_light from './tent_light.png';
import img_term_life_dark from './term_life_dark.png';
import img_term_life_light from './term_life_light.png';
import img_thrift_savings_plan_dark from './thrift_savings_plan_dark.png';
import img_thrift_savings_plan_light from './thrift_savings_plan_light.png';
import img_timeshare_dark from './timeshare_dark.png';
import img_timeshare_light from './timeshare_light.png';
import img_tools_dark from './tools_dark.png';
import img_tools_light from './tools_light.png';
import img_trademark_dark from './trademark_dark.png';
import img_trademark_light from './trademark_light.png';
import img_trailer_dark from './trailer_dark.png';
import img_trailer_light from './trailer_light.png';
import img_truck_dark from './truck_dark.png';
import img_truck_light from './truck_light.png';
import img_tv_dark from './tv_dark.png';
import img_tv_light from './tv_light.png';
import img_vacation_dark from './vacation_dark.png';
import img_vacation_light from './vacation_light.png';
import img_van_dark from './van_dark.png';
import img_van_light from './van_light.png';
import img_variable_annuity_dark from './variable_annuity_dark.png';
import img_variable_annuity_light from './variable_annuity_light.png';
import img_vinyl_dark from './vinyl_dark.png';
import img_vinyl_light from './vinyl_light.png';
import img_vr_dark from './vr_dark.png';
import img_vr_light from './vr_light.png';
import img_watch_dark from './watch_dark.png';
import img_watch_light from './watch_light.png';
import img_wine_dark from './wine_dark.png';
import img_wine_light from './wine_light.png';

const propertyIcons = {
  img_401a_dark,
  img_401a_light,
  img_401k_dark,
  img_401k_light,
  img_403b_dark,
  img_403b_light,
  img_457b_dark,
  img_457b_light,
  img_529_dark,
  img_529_light,
  img_ad__amp__d_dark,
  img_ad__amp__d_light,
  img_ad__amp__d_offer_dark,
  img_ad__amp__d_offer_light,
  img_airline_miles_dark,
  img_airline_miles_light,
  img_airplane_dark,
  img_airplane_light,
  img_annuity_dark,
  img_annuity_light,
  img_antiques_dark,
  img_antiques_light,
  img_appliances_dark,
  img_appliances_light,
  img_artwork_dark,
  img_artwork_light,
  img_asset_dark,
  img_asset_light,
  img_auto_loan_dark,
  img_auto_loan_light,
  img_backpack_dark,
  img_backpack_light,
  img_bedding_dark,
  img_bedding_light,
  img_bicycle_dark,
  img_bicycle_light,
  img_bird_dark,
  img_bird_light,
  img_boat_dark,
  img_boat_light,
  img_books_dark,
  img_books_light,
  img_bracelet_dark,
  img_bracelet_light,
  img_brokerage_account_dark,
  img_brokerage_account_light,
  img_brokerage_dark,
  img_brokerage_light,
  img_building_dark,
  img_building_light,
  img_camera_dark,
  img_camera_light,
  img_car_dark,
  img_car_light,
  img_car_loan_dark,
  img_car_loan_light,
  img_cash_dark,
  img_cash_light,
  img_cat_dark,
  img_cat_light,
  img_cd_dark,
  img_cd_light,
  img_checking_dark,
  img_checking_light,
  img_china_dark,
  img_china_light,
  img_clothing_dark,
  img_clothing_light,
  img_co__dash__op_dark,
  img_co__dash__op_light,
  img_coats_dark,
  img_coats_light,
  img_coins_dark,
  img_coins_light,
  img_computer_dark,
  img_computer_light,
  img_condo_dark,
  img_condo_light,
  img_console_dark,
  img_console_light,
  img_cookware_dark,
  img_cookware_light,
  img_copyright_dark,
  img_copyright_light,
  img_corporation_dark,
  img_corporation_light,
  img_credit_card_dark,
  img_credit_card_light,
  img_crypto_dark,
  img_crypto_light,
  img_dog_dark,
  img_dog_light,
  img_dolls_dark,
  img_dolls_light,
  img_drone_dark,
  img_drone_light,
  img_earrings_dark,
  img_earrings_light,
  img_education_savings_account_dark,
  img_education_savings_account_light,
  img_equipment_dark,
  img_equipment_light,
  img_equity_dark,
  img_equity_light,
  img_farm_dark,
  img_farm_light,
  img_fixed_annuity_dark,
  img_fixed_annuity_light,
  img_flatware_dark,
  img_flatware_light,
  img_furniture_dark,
  img_furniture_light,
  img_games_dark,
  img_games_light,
  img_glassware_dark,
  img_glassware_light,
  img_group_life_dark,
  img_group_life_light,
  img_guns_dark,
  img_guns_light,
  img_health_savings_account_dark,
  img_health_savings_account_light,
  img_home_equity_loan_dark,
  img_home_equity_loan_light,
  img_house_dark,
  img_house_light,
  img_instruments_dark,
  img_instruments_light,
  img_ira_dark,
  img_ira_light,
  img_job_benefit_dark,
  img_job_benefit_light,
  img_journals_dark,
  img_journals_light,
  img_kayak_dark,
  img_kayak_light,
  img_land_dark,
  img_land_light,
  img_laptop_dark,
  img_laptop_light,
  img_lighting_dark,
  img_lighting_light,
  img_limited_partnership_dark,
  img_limited_partnership_light,
  img_liquor_dark,
  img_liquor_light,
  img_loan_dark,
  img_loan_light,
  img_memorabilia_dark,
  img_memorabilia_light,
  img_minor_custodial_account_dark,
  img_minor_custodial_account_light,
  img_money_market_dark,
  img_money_market_light,
  img_motorcycle_dark,
  img_motorcycle_light,
  img_movies_dark,
  img_movies_light,
  img_music_dark,
  img_music_light,
  img_necklace_dark,
  img_necklace_light,
  img_non__dash__taxable_brokerage_account_dark,
  img_non__dash__taxable_brokerage_account_light,
  img_other_bank_dark,
  img_other_bank_light,
  img_other_collection_dark,
  img_other_collection_light,
  img_other_electronics_dark,
  img_other_electronics_light,
  img_other_household_dark,
  img_other_household_light,
  img_other_investment_dark,
  img_other_investment_light,
  img_other_jewelry_dark,
  img_other_jewelry_light,
  img_other_loan_dark,
  img_other_loan_light,
  img_other_pet_dark,
  img_other_pet_light,
  img_other_property_dark,
  img_other_property_light,
  img_other_recreation_dark,
  img_other_recreation_light,
  img_other_vehicle_dark,
  img_other_vehicle_light,
  img_partnership_dark,
  img_partnership_light,
  img_patent_dark,
  img_patent_light,
  img_pension_dark,
  img_pension_light,
  img_permanent_life_dark,
  img_permanent_life_light,
  img_personal_loan_dark,
  img_personal_loan_light,
  img_phone_dark,
  img_phone_light,
  img_photos_dark,
  img_photos_light,
  img_profit_sharing_plan_dark,
  img_profit_sharing_plan_light,
  img_recipes_dark,
  img_recipes_light,
  img_ring_dark,
  img_ring_light,
  img_roth_401k_dark,
  img_roth_401k_light,
  img_roth_ira_dark,
  img_roth_ira_light,
  img_rv_dark,
  img_rv_light,
  img_savings_dark,
  img_savings_light,
  img_season_tickets_dark,
  img_season_tickets_light,
  img_sep_ira_dark,
  img_sep_ira_light,
  img_simple_ira_dark,
  img_simple_ira_light,
  img_skateboard_dark,
  img_skateboard_light,
  img_skis_dark,
  img_skis_light,
  img_sleeping_bag_dark,
  img_sleeping_bag_light,
  img_snowboard_dark,
  img_snowboard_light,
  img_snowmobile_dark,
  img_snowmobile_light,
  img_snowshoes_dark,
  img_snowshoes_light,
  img_stamps_dark,
  img_stamps_light,
  img_stereo_dark,
  img_stereo_light,
  img_stock_plan_dark,
  img_stock_plan_light,
  img_student_loan_dark,
  img_student_loan_light,
  img_surfboard_dark,
  img_surfboard_light,
  img_suv_dark,
  img_suv_light,
  img_tablet_dark,
  img_tablet_light,
  img_tax_debt_dark,
  img_tax_debt_light,
  img_tent_dark,
  img_tent_light,
  img_term_life_dark,
  img_term_life_light,
  img_thrift_savings_plan_dark,
  img_thrift_savings_plan_light,
  img_timeshare_dark,
  img_timeshare_light,
  img_tools_dark,
  img_tools_light,
  img_trademark_dark,
  img_trademark_light,
  img_trailer_dark,
  img_trailer_light,
  img_truck_dark,
  img_truck_light,
  img_tv_dark,
  img_tv_light,
  img_vacation_dark,
  img_vacation_light,
  img_van_dark,
  img_van_light,
  img_variable_annuity_dark,
  img_variable_annuity_light,
  img_vinyl_dark,
  img_vinyl_light,
  img_vr_dark,
  img_vr_light,
  img_watch_dark,
  img_watch_light,
  img_wine_dark,
  img_wine_light,
};


export default propertyIcons;
