const VARIABLE_REGEX = /@\([^)]*\)/g;

export function parseTranslation( translation: string, variables: ( string | undefined )[]): string{
  if( !translation ) {
    return '';
  }
  const matches = translation.match( VARIABLE_REGEX );
  if( !matches ) {
    return translation;
  }
  const parts = translation.split( VARIABLE_REGEX  );

  variables.slice( 0,matches.length ).forEach(( variable,idx ) => {
    parts.splice(( idx * 2 ) + 1, 0, variable || '' );
  });

  return parts.join( '' );
}
