import ApplicationInterface from '../../types/ApplicationInterface';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  data: [] as Array<ApplicationInterface>,
  // for extra reducers
  fetchApplications: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchApplication: {
    meta: {} as AsyncThunkMetaInterface,
  },
  patchApplication: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
