import { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

// Components
import Form from '../../../../components/Form';
import SegmentView from '../../../../components/SegmentView';
import Translation from '../../../../components/Translation';
import { TextArea } from '../../../../components/TextArea';

// Hooks
import useBackstack from '../../../../hooks/useBackstack';
import useTranslations from '../../../../hooks/useTranslations';

// Redux
import { useDispatch, useSelector } from '../../../../store';

// Types
import SanitizedFormData from '../../../../../core/types/SanitizedFormData';
import { medical_consent_care_info } from '../../../../../core/routes';
import { patchPerson } from '../../../../store/reducers/peopleReducer';
import { isPendingPersonSave } from '../../../../store/selectors/peopleSelectors';
import { getPersonByID } from '../../../../utils/people/getPersonByID';
import { makeSnack } from '../../../../store/reducers/snackbarReducer';
import { TranslationKeys } from '../../../../store/initial/translations';

export default function MedicalConsentAllergiesForm(): ReactElement{
  const { wardId } = useParams<{ wardId: string}>();
  const { goBack } = useBackstack( medical_consent_care_info.get({ wardId }));
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const { push } = useHistory();
  const person = useSelector( getPersonByID( wardId ));
  const INPUT_ALLERGIES = 'allergies';
  const allergiesTitle = <Translation translationKey={ 'medicalconsent.screen.allergies.title' }/>;
  const submitButtonTitle: TranslationKeys = person?.allergies ? '(button)update' : '(button)done';
  const defaultValues ={ allergies: person?.allergies };

  const { register, setValue, getValues, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues,
  });

  const useFormProps = {
    register,
    setValue,
    getValues,
    errors,
  };

  const onSubmit: SubmitHandler<SanitizedFormData> = data =>{
    dispatch( patchPerson({ id: person?.id, ...data, onSuccess: () => {
      dispatch( makeSnack({ message: getTranslation( 'form.actions.update.success', [ person?.name || '' ]) }));
      push( medical_consent_care_info.get({ wardId }));
    },
    onError: ( error: string ) => {
      dispatch( makeSnack({ message: error, theme: 'error' }));
    } }));
  };

  const isRequestPending = useSelector( isPendingPersonSave );

  return (
    <Form handleSubmit={ handleSubmit } onSubmit={ onSubmit }>
      <SegmentView onRetreat={ goBack }
        primaryActionLabel={ <Translation translationKey={ submitButtonTitle }/> }
        primaryActionButtonType={ 'submit' }
        title={ allergiesTitle }
        primaryActionButtonIsPending={ isRequestPending }
      >
        <TextArea
          { ...useFormProps }
          label={ allergiesTitle }
          showLabel={ false }
          name={ INPUT_ALLERGIES }
          placeholder={ getTranslation( 'medicalconsent.screen.allergies.placeholdertext' ) }/>
      </SegmentView>
    </Form>
  );
}
