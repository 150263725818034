import { ChildrenHeirsLifeUpsellModalProps } from './../../views/modals/ChildrenHeirsLifeUpsellModal';
import { GlobalContextualModalProps } from '../../components/GlobalContextualModal';
import { InviteModalProps } from '../../components/InviteModal';
import { ShareActionSheetProps } from '../../components/ShareActionSheet';
import { GlobalVideoModalProps } from '../../views/modals/GlobalVideoModal';
import { EditCaretakerActionSheetProps } from '../../components/EditCaretakerActionSheet';

export type GlobalModal = keyof typeof GlobalModals

export const GlobalModals = {
  lenoxAdvisor: 'lenoxAdvisor',
  help: 'help',
  shareActionSheet: 'shareActionSheet',
  childrenHeirsLifeUpsell: 'childrenHeirsLifeUpsell',
  inviteModal: 'inviteModal',
  contextualModal: 'contextualModal',
  videoModal: 'videoModal',
  editCaretakerActionSheet: 'editCaretakerActionSheet',
} as const;

export type GenericModals = typeof GlobalModals.lenoxAdvisor | typeof GlobalModals.help;

type BaseModalState = {
  isOpen: boolean;
  isRendered: boolean;
}

interface BaseModalStateProps<T> extends BaseModalState {
  modalProps: T;
}

export const initialProps = {
  shareActionSheet: {
    inviteId: '',
  },
  inviteModal: {
    firstNames: [],
    roleLabel: '',
  },
  contextualModal: {
    title: '',
    description: '',
    onConfirm: (): undefined => undefined,
    confirmLabel: '',
    onDismiss: (): undefined => undefined,
    dismissLabel: '',
  },
  childrenHeirsLifeUpsell: {
    onSecondaryAction: (): undefined => undefined,
  },
  videoModal: {
    src: {},
  },
  editCaretakerActionSheet: {
    caretakerID: '',
  },
};

const initialState = {
  data: {
    childrenHeirsLifeUpsell: {
      isRendered: false,
      isOpen: false,
      modalProps: initialProps.childrenHeirsLifeUpsell,
    },
    contextualModal: {
      isRendered: false,
      isOpen: false,
      modalProps: initialProps.contextualModal,
    },
    inviteModal: {
      isRendered: false,
      isOpen: false,
      modalProps: initialProps.inviteModal,
    },
    lenoxAdvisor: {
      isRendered: false,
      isOpen: false,
    },
    help: {
      isRendered: false,
      isOpen: false,
    },
    shareActionSheet: {
      isRendered: false,
      isOpen: false,
      modalProps: initialProps.shareActionSheet,
    },
    videoModal: {
      isRendered: false,
      isOpen: false,
      modalProps: initialProps.videoModal,
    },
    editCaretakerActionSheet: {
      isRendered: false,
      isOpen: false,
      modalProps: initialProps.editCaretakerActionSheet,
    },
  } as {
    inviteModal: BaseModalStateProps<InviteModalProps>;
    childrenHeirsLifeUpsell: BaseModalStateProps<ChildrenHeirsLifeUpsellModalProps>;
    contextualModal: BaseModalStateProps<GlobalContextualModalProps>;
    lenoxAdvisor: BaseModalState;
    help: BaseModalState;
    shareActionSheet: BaseModalStateProps<ShareActionSheetProps>;
    videoModal: BaseModalStateProps<GlobalVideoModalProps>;
    editCaretakerActionSheet: BaseModalStateProps<EditCaretakerActionSheetProps>;
},
};

export default initialState;
