import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import ActionContainer from '../../../components/ActionContainer';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';
import PropertyListItem from '../PropertyListItem';
import PropertySubtypePicker from '../PropertySubtypePicker';
import OpenVideoButton from '../../../components/OpenVideoButton';

// Hooks
import useBackstack from '../../../hooks/useBackstack';
import useTranslations from '../../../hooks/useTranslations';
import useValuables from '../../../hooks/useValuables';

// Models
import Property, { PropertySubtype, PropertyType } from '../../../core-data-service/models/Property';

// Routes
import {
  properties as propertiesRoute,
  will as willRoute,
} from '../../../../core/routes';

// Utils
import { VALUABLES_URL_NAMESPACE as urlNamespace } from '../../../../core/constants';
import analytics from '../../../utils/analytics';
import { VideoSourceMap } from '../../../components/VideoPlayer';

export const valuablesVideoSourceMap: VideoSourceMap = { portrait: 'video.will.valuables', landscape: 'video.will.valuables.landscape' };

export default function ValuablesListView(): ReactElement{
  const { push } = useHistory();
  const { goBack } = useBackstack( willRoute.get());
  const { getTranslation } = useTranslations();

  const { valuablesCategories, valuables } = useValuables();
  const [ category, setCategory ] = useState<PropertyType|undefined>();

  const handleSetSubtype = ( subtype: PropertySubtype ) => {
    push({
      pathname: propertiesRoute.get({ id: 'create', urlNamespace }),
      search: `?category=${category}&subtype=${subtype}`,
    });
  };

  return (
    <SegmentView
      title="Valuables"
      onRetreat={ goBack }
      primaryActionLabel={ getTranslation( 'products.will.chapter.actions.finish' ) }
      onPrimaryAction={ () => {
        analytics.track( 'Touch - Finish Valuables' );
        push( willRoute.get());
      } }
      subtitle={
        <p className="mt-8">
          <Translation translationKey="screen.valuables.subtitle" />
          <OpenVideoButton
            videoSrc={ valuablesVideoSourceMap }
            labelKey="tile.videoaccess.willchapter.defaulttitle"
          />
        </p>
      }
    >
      <ul className="list-none">
        {valuablesCategories.map(( category, idx ) => {
          return(
            <ActionContainer
              key={ category.value }
              header={ <Translation translationKey={ Property.DISPLAY_KEY[category.value] } /> }
              className={ `
                ${idx < valuablesCategories.length - 1 ? 'pb-6' : ''}
                ${idx !== 0 ? 'pt-6 border-t border-grey' : ''}
              ` }
              primaryAction={ () => setCategory( category.value ) }
              primaryActionLabel="Add"
            >
              {
                valuables
                  .filter( valuable => valuable.type === category.value )
                  .map(({ name, subtype, id, photo_url }, idx ) => {
                    return (
                      <PropertyListItem
                        className={ `${idx === 0 ? 'mt-2' : ''} py-1` }
                        key={ id }
                        title={ name || '' }
                        subhead={ <Translation translationKey={ Property.DISPLAY_KEY[subtype] } />  }
                        image={{
                          src: photo_url || Property.getIcon( subtype ),
                          alt: `${subtype} graphic`,
                        }}
                        route={ propertiesRoute.get({ id, urlNamespace }) }
                      />
                    );
                  })}

            </ActionContainer>
          );
        })}
      </ul>
      <PropertySubtypePicker
        onClose={ () => setCategory( undefined ) }
        isOpen={ !!category }
        category={ category }
        onItemSelect={ handleSetSubtype }
      />
    </SegmentView>
  );
}
