import { useState, SyntheticEvent } from 'react';



/**
 * Spread these on your image styles property <img style={{ ...styles }} />
 */
interface OnImageLoadStyles {
  transition: string;
  opacity: number;
}

interface OnImageLoadHook {
  onImageLoadStyles: OnImageLoadStyles;

  /**
   * Pass this to your img.onload property <img onLoad={ onImageLoad } />
   */
  onImageLoad: ( e: SyntheticEvent<HTMLImageElement, Event> )=> void;
}


/**
 * fade in after image is downloaded (less jerky)
 * @param duration
 * @returns OnImageLoadHook
 */
const useOnImageLoad = ( duration = 3 ): OnImageLoadHook => {

  const [ isLoaded, setIsLoaded ] = useState<boolean>( false );

  const onImageLoadStyles = {
    transition: `opacity .${ duration }s ease-in`,
    opacity: isLoaded ? 1 : 0,
  };

  const onImageLoad = () => setIsLoaded( true );

  return {
    onImageLoadStyles,
    onImageLoad,
  };
};


export default useOnImageLoad;
