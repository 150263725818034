import React, { ReactElement } from 'react';

// components
import Translation from '../../../components/Translation';
import WillGuardiansSummaryItem from './WillGuardiansSummaryItem';
import WillGuardiansCatchAllSummary from './WillGuardiansCatchAllSummary';

// Hooks
import useGuardians from '../../../hooks/useGuardians';

// Models / Types
import RoleInterface from '../../../types/RoleInterface';
import Person from '../../../core-data-service/models/Person';
import { separateArrayStringsByComma } from '../../../../core/utils/stringHelpers';

export interface RolesByPriority {
  [key: string]: RoleInterface[] | null;
}

export default function WillGuardiansSummary(): ReactElement{

  const {
    minorChildren,
    roles,
  } = useGuardians();

  if( !roles || !roles.length ) {
    return <></>;
  }

  const rolesByPriority = roles.reduce(( acc: RolesByPriority, cur ) => {
    acc[cur['priority']] = [ ...acc[cur['priority']] || [], cur ];
    return acc;
  }, {});

  const hasOnlyOneGuardianPerPriority = Object.values( rolesByPriority ).every( guardians => guardians && guardians.length <= 1 );


  return (

    hasOnlyOneGuardianPerPriority ?

      // if every child has the same guardian
      <div className="flex flex-col gap-4">
        {Object.values( rolesByPriority )
          .map(( roles, idx ) => roles?.map( role =>
            <WillGuardiansSummaryItem
              key={ `will-guardian-summary-item-${idx}` }
              priority={ idx }
              granteeId={ role.grantee_id }
              minorChildren={ minorChildren }
            />,
          ))
        }
        {!rolesByPriority[1] &&
          <div className="flex">
            <div className="w-circleIcon_sm flex-shrink-0"></div>
            <div className="ml-4">
              <Translation
                translationKey={ 'screen.willstatus.guardians.message.somechildrenlackbackupguardians' }
                dynamicStringValues={ [ separateArrayStringsByComma( minorChildren.map( child => new Person( child ).firstName )) ] }
              />
            </div>
          </div>
        }
      </div>
      :
      // if not every child has the same guardian and falbacks
      <div>
        <WillGuardiansCatchAllSummary roles={ roles }/>
      </div>
  );
}
