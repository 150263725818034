
import { ReactElement, ReactNode } from 'react';
import { LocationDescriptorObject } from 'history';
import { Link } from 'react-router-dom';

// Components
import ActionContainer from '../../components/ActionContainer';
import CircleImage from '../../components/CircleImage';

interface Props {
  className?: string;
  title: string;
  subhead?: ReactNode;
  route: LocationDescriptorObject;
  image: {
    src: string;
    alt: string;
  };
}

export default function PropertyListItem({ className = '', title, image, subhead, route }: Props ): ReactElement{
  return (
    <li className={ `group -m-3 ${className}` }>
      <ActionContainer
        header={
          <Link to={ route } className="flex-1 p-3 transition-colors rounded-md group-hover:bg-black-5">
            <div className="flex flex-row">
              <div className="w-11">
                <CircleImage
                  className="aspect-w-1 aspect-h-1"
                  imageSrc={ image.src }
                  onImageLoadDuration={ 1 }
                  alt={ image.alt }
                />
              </div>
              <div className="ml-4 flex-1 flex justify-center">
                <div className="flex flex-col flex-1 justify-center">
                  <div>
                    { title }
                  </div>
                  {subhead &&
                    <div className="text-black-50">
                      {subhead}
                    </div>
                  }
                </div>
                <span className="text-forest group-hover:text-forest--hover font-bold">Edit</span>
              </div>
            </div>
          </Link>
        }
      />
    </li>
  );
}
