import { createSlice } from '@reduxjs/toolkit';

// initialState
import initialState from '../initial/settings';

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    saveSettings( settings, action ){
      Object.assign( settings, action.payload );
    },
  },
});


export const { saveSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
