import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import Translation from '../../components/Translation';

// Hooks
import useDocumentPDF from '../../hooks/useDocumentPDF';
import useTranslations from '../../hooks/useTranslations';

// Models
import Document, { AvailableDocumentType } from '../../core-data-service/models/Document';

// Redux
import { useDispatch } from '../../store';
import { useSelector } from '../../store';
import userSelector from '../../store/selectors/userSelector';
import { makeSnack } from '../../store/reducers/snackbarReducer';

// Routes
import { dashboard as dashboardRoute, trust_review as trustReviewRoute } from '../../../core/routes';
import { TranslationKeys } from '../../store/initial/translations';

// Utils
import { documentToTitleAndDescription } from '../../../client/utils/documentUtils';

interface DocumentCompleteProps{
  documentType: AvailableDocumentType;
  isShown?: boolean;
  hasSeenView: boolean;
  onClickEdit: ()=> void;
  onPrimaryAction?: ()=> void;
  onRender?: ()=> void;
  onRetreat?: ()=> void;
}

export default function DocumentComplete({ documentType, isShown, hasSeenView, onClickEdit, onPrimaryAction, onRender, onRetreat }: DocumentCompleteProps ): ReactElement{
  const { push } = useHistory();
  const { email } = useSelector( userSelector );
  const dispatch = useDispatch();
  const handleRetreat = onRetreat ? onRetreat : ()=> push( dashboardRoute.get());
  const { getTranslation } = useTranslations();
  const { documentTitle, documentDescription } = documentToTitleAndDescription( documentType );

  const emailSuccessSnack = ()=> {
    dispatch( makeSnack({ message: getTranslation( 'banner.document.email.sent', [ email || 'your email' ]), theme: 'warning' }));
  };

  const { openDocument, emailDocument, isFetchingDocument } =  useDocumentPDF( documentType );

  const getPrimaryButtonInfo = (): { textKey: TranslationKeys; onClick: ()=> void; idValue: string } => {
    if ( documentType === Document.TYPE_TRUST && !hasSeenView ) {
      return {
        textKey: 'screen.document.completion.button.edited',
        onClick: () => push( trustReviewRoute.get()),
        idValue:'edit-button',
      };
    } else if ( hasSeenView ) {
      return {
        textKey: 'screen.document.completion.button.unchanged',
        onClick: ()=> emailDocument({ onSuccess: emailSuccessSnack }),
        idValue:'email-copy-button',
      };
    } else {
      return {
        textKey: 'screen.document.completion.button.done',
        idValue:'done-button',
        onClick: () => push( dashboardRoute.get()),

      };
    }
  };

  useEffect(()=> {
    onRender && onRender();
    if ( !hasSeenView ) {
      emailDocument();
    }
  },[]);

  return (
    <Modal
      contentClassName="!bg-moss"
      onClose={ handleRetreat }
      isOpen={ isShown }
    >
      <div className="flex-1 flex flex-col justify-center align-middle container--lg">
        <div className="flex flex-col  text-center">
          <div className="flex justify-center mb-4 sm-only:mb-6">
            <Icon  name="guarantee-check" className="text-salamander" />
          </div>
          <h1 id="modal-title"className="text-3xl font-headline text-black text-center mb-4 sm-only:mb-6">
            <Translation translationKey={ hasSeenView ? 'screen.document.completion.title.unchanged' : 'screen.document.completion.title.edited' } dynamicStringValues={ [ getTranslation( documentTitle ) ] }/>
          </h1>
          <p className="md:w-4/5 self-center mb-6">
            <Translation translationKey={ documentDescription } dynamicStringValues={ [ getTranslation( documentTitle ) ] } />
          </p>
          <Button
            className="w-min px-6 self-center mb-8"
            isPending={ isFetchingDocument }
            id={ getPrimaryButtonInfo().idValue }
            onClick={ ()=> {
              getPrimaryButtonInfo().onClick();
              onPrimaryAction && onPrimaryAction();
            } }
          >
            <Translation translationKey={ getPrimaryButtonInfo().textKey } />
          </Button>
        </div>
      </div>
      <div className="flex w-full h-12">
        <Button className="flex-1 border-r-2 border-grey" theme="clean" onClick={ onClickEdit }>
          <Translation translationKey="screen.document.completion.button.edit" />
        </Button>
        <Button onClick={ openDocument } className="flex-1" theme="clean">
          <Translation translationKey="screen.document.completion.button.openpdf" />
        </Button>
      </div>
    </Modal>
  );
}

