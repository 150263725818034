import { ReactElement, useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

// Components
import Icon from './Icon';
import { TimerDuration } from './TimerDuration';

interface Props {
  areCaptionsEnabled: boolean;
  areCaptionsShown: boolean;
  className: string;
  duration: number;
  hasPlayed: boolean;
  isPlaying: boolean;
  isMuted?: boolean;
  loaded: number;
  fractionPlayed: number;
  playedSeconds: number;
  onCaptionsToggle: ()=> void;
  onMuteToggle: ()=> void;
  onControlsHidden: ( areControlsVisible: boolean )=> void;
  onControlsShown: ( areControlsVisible: boolean )=> void;
  onPlayToggle: ()=> void;
  onScrubStart: ( e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement> )=> void;
  onScrubEnd: ( e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement> )=> void;
  onScrubChange: ( e: React.ChangeEvent<HTMLInputElement> )=> void;
}

export default function VideoControls({
  areCaptionsShown = false,
  areCaptionsEnabled,
  className = '',
  duration,
  hasPlayed,
  isMuted = false,
  isPlaying = false,
  loaded,
  onCaptionsToggle,
  onControlsHidden,
  onControlsShown,
  onMuteToggle,
  onPlayToggle,
  onScrubStart,
  onScrubEnd,
  onScrubChange,
  fractionPlayed,
  playedSeconds,
}: Props ): ReactElement{

  const [ shouldShowControls, setShouldShowControls ] = useState<boolean>( false );
  const mouseTimeout = useRef<number | null>( null );

  // if a timer is running cancel it
  const cancelInactivityTimer = useCallback(
    () => {
      if( mouseTimeout.current ) {
        setShouldShowControls( true );
        window.clearTimeout( mouseTimeout.current );
      }
    },
    [ mouseTimeout ],
  );

  // handle mouseMove events to start inactivity time and show / hide controls
  const handleMouseMove = useCallback(() => {
    cancelInactivityTimer();
    onControlsShown && onControlsShown( true );
    mouseTimeout.current = window.setTimeout(() => {
      setShouldShowControls( false );
      onControlsHidden && onControlsHidden( false );
    }, 3000 );
  },[ onControlsHidden, onControlsShown, cancelInactivityTimer ]);

  const handleOnPlayToggle = () => {
    onPlayToggle();
    handleMouseMove();
    setShouldShowControls( true );
  };

  // this shows video controls but also always shows controls when paused on touch devices
  const canVideoControlsShow = ( shouldShowControls && hasPlayed ) || ( !isPlaying && hasPlayed && isMobile );

  return (
    <div
      className={ `VideoControls ${canVideoControlsShow ? 'VideoControls--active' : ''} ${className} items-end overflow-hidden` }
    >
      {/* Hidden Play Button which expands to the size of the video player  */}
      <button
        onMouseMove={ handleMouseMove }
        id={ 'video-play-pause-button' }
        className={ `absolute inset-0 flex-1 w-full flex items-center justify-center focus:outline-none ${shouldShowControls || !isPlaying ? '' : 'cursor-none'}` }
        onClick={ handleOnPlayToggle }
      >
        {!hasPlayed && !isPlaying &&
          <div className="rounded-full bg-white border-forest border-3 flex items-center justify-center w-24 h-24">
            <Icon name="play" className="text-forest h-10 w-10 ml-2"/>
          </div>
        }
        <div className="sr-only">{isPlaying ? 'Pause' : 'Play'}</div>
      </button>

      {/* Controls Container */}
      <div
        onMouseMove={ cancelInactivityTimer }
        className={ `
          VideoControls-container
          group
        ` }
      >
        {/* Play / Pause */}
        <button id="video-inline-play-pause-button" className="focus:outline-none" aria-label="play pause toggle" onClick={ handleOnPlayToggle }>
          <Icon className="text-white h-5 w-4" name={ isPlaying ? 'pause' : 'play'  } />
        </button>

        {/* Timeline Container */}
        <div className="relative text-white flex-1 flex flex-col justify-center ml-3 h-full">

          {/* loaded progress */}
          <div className="absolute flex items-center left-[10px] right-[10px]">
            <progress
              className="VideoControls-loaded"
              max="1"
              value={ Math.ceil( loaded ) }
            />
          </div>

          {/* played progress */}
          <div className="absolute flex items-center left-[10px] right-[10px]">
            <progress
              className="VideoControls-played"
              max="1"
              value={ fractionPlayed }
            />
          </div>

          {/* playhead */}
          <input
            className="VideoControls-playhead"
            type="range"
            min="0"
            max="1"
            value={ fractionPlayed }
            onMouseDown={ onScrubStart }
            onTouchStart={ onScrubStart }
            onMouseUp={ onScrubEnd }
            onTouchEnd={ onScrubEnd }
            onChange={ onScrubChange }
            step="any"
          />
        </div>

        {/* Duration */}
        <div className="relative w-11 flex items-center justify-end mr-1">
          <TimerDuration className="absolute right-0 text-white text-right font-bold b" seconds={ duration - playedSeconds } />
        </div>

        {/* CC */}
        {areCaptionsEnabled &&
          <button id="closed-caption-button"className="mx-2 focus:outline-none" aria-label="closed caption toggle " onClick={ onCaptionsToggle }>
            <Icon className="h-6 w-6 text-white" name={ areCaptionsShown ? 'closedCaption-on' : 'closedCaption-off'  } />
          </button>
        }

        {/* Mute */}
        <button id="mute-button"className="mx-2 focus:outline-none" aria-label="volume toggle " onClick={ onMuteToggle }>
          <Icon className="h-6 w-6 text-white" name={ isMuted ? 'volume-off' : 'volume-up'  } />
        </button>

      </div>
    </div>
  );
}
