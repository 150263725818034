import { ReactElement } from 'react';
import Invitation, { InvitationResponse, InvitationStatus } from '../core-data-service/models/Invitation';

import Icon from './Icon';
import Translation from './Translation';

export interface InvitationStatusButtonProps {
  inviteStatus: InvitationResponse | InvitationStatus;
  inviteeName?: string;
  onClick?: ()=> void;
}

const BUTTON_CLASSES = 'flex-1 rounded-md flex h-full w-full justify-center items-center hover:bg-forest hover:text-white button-focusStyles ';

export function InvitationStatusButton({
  onClick,
  inviteStatus,
  inviteeName='',
}: InvitationStatusButtonProps ): ReactElement{
  return (
    <div className="flex h-10 mb-8 bg-moss text-forest rounded-md relative items-center text-center" >
      { inviteStatus === Invitation.STATUS.CREATED &&
        <>
          <button
            className={ BUTTON_CLASSES }
            onClick={ onClick }
          >
            { /* TODO: invite call-to-action text should come from translations */ }
            <Icon
              className="w-5 h-5"
              name={ 'addPerson' }
            />
            <span className="ml-2 font-bold">
              <Translation translationKey="actionsheet.invitations.title" dynamicStringValues={ [ inviteeName ] }/>
            </span>
          </button>
        </>
      }
      {inviteStatus === Invitation.STATUS.SENT &&
        <>
          <button
            className={ BUTTON_CLASSES }
            onClick={ onClick }
          >
            <span className="font-normal">
              <Translation translationKey="role.tile.invite.button.status.pending"/>
            </span>
            <span className="font-bold ml-2">
              <Translation translationKey="role.tile.invite.button.action.resend" />
            </span>
          </button>
        </>
      }
      {inviteStatus === Invitation.RESPONSE.ACCEPTED && <p className="flex-1 text-forest font-bold">
        <Translation translationKey="invite.status.button.accepted.label"/>
      </p>}
    </div>
  );
}

export default InvitationStatusButton;
