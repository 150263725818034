import { FC, Dispatch, SetStateAction, useState } from 'react';
import CircleImage from '../../components/CircleImage';
import Modal from '../../components/Modal';


export const AVATARS_BASE_URL = 'https://cdn.tmro.me/avatars/';


const AVATARS: string[] = [
  // 'BlankPerson',
  'f-01-05-t0',
  'f-01-05-t1',
  'f-01-05-t2',
  'f-01-05-t3',
  'f-06-15-t0',
  'f-06-15-t1',
  'f-06-15-t2',
  'f-06-15-t3',
  'f-16-25-t0',
  'f-16-25-t1',
  'f-16-25-t2',
  'f-16-25-t3',
  'f-26-35-t0',
  'f-26-35-t1',
  'f-26-35-t2',
  'f-26-35-t3',
  'f-36-45-t0',
  'f-36-45-t1',
  'f-36-45-t2',
  'f-36-45-t3',
  'f-46-55-t0',
  'f-46-55-t1',
  'f-46-55-t2',
  'f-46-55-t3',
  'f-56-65-t0',
  'f-56-65-t1',
  'f-56-65-t2',
  'f-56-65-t3',
  'f-66-75-t0',
  'f-66-75-t1',
  'f-66-75-t2',
  'f-66-75-t3',
  'f-76-85-t0',
  'f-76-85-t1',
  'f-76-85-t2',
  'f-76-85-t3',
  'm-01-05-t0',
  'm-01-05-t1',
  'm-01-05-t2',
  'm-01-05-t3',
  'm-06-15-t0',
  'm-06-15-t1',
  'm-06-15-t2',
  'm-06-15-t3',
  'm-16-25-t0',
  'm-16-25-t1',
  'm-16-25-t2',
  'm-16-25-t3',
  'm-26-35-t0',
  'm-26-35-t1',
  'm-26-35-t2',
  'm-26-35-t3',
  'm-36-45-t0',
  'm-36-45-t1',
  'm-36-45-t2',
  'm-36-45-t3',
  'm-46-55-t0',
  'm-46-55-t1',
  'm-46-55-t2',
  'm-46-55-t3',
  'm-56-65-t0',
  'm-56-65-t1',
  'm-56-65-t2',
  'm-56-65-t3',
  'm-66-75-t0',
  'm-66-75-t1',
  'm-66-75-t2',
  'm-66-75-t3',
  'm-76-85-t0',
  'm-76-85-t1',
  'm-76-85-t2',
  'm-76-85-t3',
];

const isAdult = ( avi: string ) => parseInt( avi.split( '-' )[1]) >= 25;
const FEMALE_AVATARS = AVATARS.filter( avi => avi[0] === 'f' && isAdult( avi ));
const MALE_AVATARS = AVATARS.filter( avi => avi[0] === 'm' && isAdult( avi ));
const KID_AVATARS = AVATARS.filter( avi => !isAdult( avi ));

const CATEGORIES = new Map<string, string[]>([
  [ 'f', FEMALE_AVATARS ],
  [ 'm', MALE_AVATARS ],
  [ 'k', KID_AVATARS ],
]);

const buildURL = ( avi: string ) => AVATARS_BASE_URL + avi + '.jpg';

type AvatarCategoryOption = 'f'|'m'|'k';


const PersonDetailAvatarPicker: FC<{
  setAvatarURL: ( photo_url: string )=> void;
  isOpen: boolean;
  setIsChoosingAvatar: Dispatch<SetStateAction<boolean>>;
}> = ({
  setAvatarURL,
  isOpen,
  setIsChoosingAvatar,
}) => {

  const [ category, setCategory ] = useState<AvatarCategoryOption>( 'f' );

  return(
    <Modal
      isOpen={ isOpen }
      onClose={ () => setIsChoosingAvatar( false ) }
    >
      <h1 className="text-4xl mb-6">
        Choose an avatar
      </h1>
      <nav className="mb-6">
        <AvatarTabs
          activeTab={ category }
          onClick={ setCategory }
          items={ [
            {
              label: 'Female',
              category: 'f',
            },
            {
              label: 'Male',
              category: 'm',
            },
            {
              label: 'Kids',
              category: 'k',
            },
          ] }
        />
      </nav>

      <div className="grid grid-cols-4 gap-6">
        {
          CATEGORIES.get( category || 'f' )?.map( avatar => {
            const avatarURL = buildURL( avatar );
            return(
              <button key={ avatar }
                onClick={
                  () => {
                    setAvatarURL( avatarURL );
                    setIsChoosingAvatar( false );
                  }
                }
              >
                <CircleImage
                  className="w-full aspect-w-1 aspect-h-1"
                  imageSrc={ avatarURL }
                  alt="avatar image" // @TODO @a11y - avatars possibly deprecated.
                />
              </button>
            );
          })
        }
      </div>

    </Modal>
  );
};

export default PersonDetailAvatarPicker;

type AvatarTab = {
  label: string;
  category: AvatarCategoryOption;
}
interface IAvatarTabs {
  items: AvatarTab[];
  onClick: Dispatch<SetStateAction<AvatarCategoryOption>>;
  activeTab: AvatarCategoryOption;
}

const AvatarTabs = ({ items, activeTab, onClick }: IAvatarTabs ) => {
  return (
    <ul className="flex" role="tablist" aria-label="Avatar Filters">
      {items.map(({ category, label }, i ) => {
        const isActiveTab = category === activeTab;
        return (
          <li key={ category } className="mr-4">
            <button
              role="tab"
              aria-selected={ isActiveTab }
              aria-controls={ `panel-${i + 1}` }
              id={ `tab-${i + 1}` }
              tabIndex={ isActiveTab ? 0 : -1 }
              onClick={ () => onClick( category ) }
              className={ `border-b-3 pb-1 ${getTabClasses( isActiveTab )}` }
            >
              {label}
            </button>
          </li>
        );
      },
      )}
    </ul>
  );
};

const getTabClasses = ( isActive: boolean ): string => {
  return isActive ? 'border-blue-400' : 'border-transparent';
};
