import { ReactElement, ReactNode } from 'react';
import Button from './Button';
import Icon from './Icon';

export interface MissingPersonDataProps{
  title: ReactNode;
  body: ReactNode;
  primaryActionLabel: ReactNode;
  onPrimaryAction: ()=> void;
}
export default function MissingPersonData({
  title,
  body,
  primaryActionLabel,
  onPrimaryAction,

}: MissingPersonDataProps ): ReactElement{
  return (
    <div className="p-4 flex-1 flex flex-col items-center">

      <Icon className="w-16 h-16 md:w-auto mb-8 text-red" name={ 'x-circle' }/>
      <div className={ 'md:w-3/4' }>
        <h1 className="text-center mb-4 text-black font-headline">
          {title}
        </h1>
        <div className="text-center mb-8 flex justify-center">
          {body}
        </div>
      </div>
      <div className="flex flex-1 items-end mb-4">
        <Button theme={ 'danger' } onClick={ onPrimaryAction }>{primaryActionLabel}</Button>
      </div>
    </div>
  );
}
