import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { withOrientationChange } from 'react-device-detect';
import VideoControls from './VideoControls';
import analytics from '../utils/analytics';
import { TranslationKeys } from '../store/initial/translations';
import useTranslations from '../hooks/useTranslations';
import { useHistory } from 'react-router-dom';

export type VideoSourceMap = {
  portrait?: TranslationKeys;
  landscape?: TranslationKeys;
}
export interface VideoPlayerProps{
  className?: string;
  forwardRef?: MutableRefObject<ReactPlayer | null>;
  isLandscape?: boolean;
  isPortrait?: boolean|undefined;
  onEnded?: ()=> void;
  source: VideoSourceMap;
  autoPlay?: boolean;
  subtitles?: {
    label: string;
    kind: string;
    src: string;
    srcLang: string;
    default?: boolean;
  }[];
  height?: string;
  width?: string;
}

const CUE_LINE_POSITION_DEFAULT = -2;
const CUE_LINE_POSITION_WITH_CONTROLS = -3;
const VIDEO_PORTRAIT_SMALL_CLASSES = 'sm-only:object-cover';

interface PlayerState {
  areCaptionsShown: boolean;
  duration: number;
  hasPlayed: boolean;
  isDevicePortrait: boolean | undefined;
  isMuted: boolean;
  isPlaying: boolean;
  isScrubbing: boolean;
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
  wasPlayingBeforeScrub: boolean;
}

const VideoPlayer: FC<VideoPlayerProps>  = ({
  className = '',
  isLandscape,
  isPortrait,
  onEnded,
  source,
  height='100%',
  width='100%',
  subtitles,
  forwardRef,
  autoPlay = false,
}) => {
  const player = useRef<ReactPlayer>();
  const videoElement = player.current?.getInternalPlayer();
  const [ playerState, setPlayerState ] = useState<PlayerState>({
    areCaptionsShown: false,
    duration: 0,
    hasPlayed: false,
    isDevicePortrait: undefined,
    isMuted: false,
    isPlaying: autoPlay,
    isScrubbing: false,
    loaded: 0,
    loadedSeconds: 0,
    played: 0,
    playedSeconds: 0,
    wasPlayingBeforeScrub: false,
  });

  const history = useHistory();
  const { getTranslation } = useTranslations();

  const getVideoSource = ( source: VideoSourceMap, isDevicePortrait: boolean|undefined ): string => {
    const { portrait, landscape } = source;
    if ( portrait && landscape ) {
      return isDevicePortrait ? getTranslation( portrait ) : getTranslation( landscape );
    }
    return portrait ? getTranslation( portrait ) : landscape ? getTranslation( landscape ) : '';
  };

  // helper function for setting state
  const handleSetPlayerState = useCallback(
    ( settings: Partial<PlayerState> ) => {
      setPlayerState({ ...playerState, ...settings });
    },
    [ playerState ],
  );

  const handleVideoViewedTracking = () => {
    analytics.track( 'Video - View', {
      video_name: player.current?.props.url,
    });
  };

  const handleTogglePlay = () => {
    if( !playerState.hasPlayed ) {
      handleVideoViewedTracking();
    }
    handleSetPlayerState({ isPlaying: !playerState.isPlaying, hasPlayed: true });
  };

  const setIsPlaying = ( isPlaying: boolean ) => {
    handleSetPlayerState({ isPlaying });
  };

  const handleOnEnded = () => {
    onEnded && onEnded();
    // Track if video is aplyed until the end
    analytics.track( 'Video Complete', {
      video_name: player.current?.props.url,
    });
  };

  const toggleCaptions = useCallback(
    ( isShown: boolean ) => {
      if( videoElement && videoElement.textTracks ) {
        for ( let i = 0; i < videoElement.textTracks.length; i++ ) {
          videoElement.textTracks[i].mode = isShown ? 'showing' : 'hidden';
        }
      }
    }, [ videoElement ],
  );

  const onCaptionsToggle = () => {
    handleSetPlayerState({ areCaptionsShown: !playerState.areCaptionsShown });
    toggleCaptions( !playerState.areCaptionsShown );
  };

  const onMuteToggle = () => {
    handleSetPlayerState({ isMuted: !playerState.isMuted });
  };

  const setCueLinePositioning = useCallback(
    ( areControlsVisible: boolean ) => {
      if( videoElement?.textTracks?.activeCues && videoElement?.textTracks?.activeCues[0]) {
        for ( let i = 0; i < videoElement.textTracks.length; i++ ) {
          videoElement.textTracks[i].cues.forEach(( cue: VTTCue ) => cue.line = areControlsVisible ? CUE_LINE_POSITION_WITH_CONTROLS : CUE_LINE_POSITION_DEFAULT );
        }
      }
    },
    [ videoElement ],
  );


  // when a history change occurs, pause the video
  // this only happens when you onboard via the will intro
  // since video is maintained in the background state
  useEffect(() => {
    handleSetPlayerState({ isPlaying: false });
  }, [ history.length ]);

  // hide captions on load
  useEffect(() => {
    toggleCaptions( playerState.areCaptionsShown );
  }, [ toggleCaptions, playerState.areCaptionsShown ]);

  // If video is set to autoplay then immediately track and set hasPlayed to true
  useEffect(() => {
    if( autoPlay && playerState.isPlaying && !playerState.hasPlayed ) {
      handleSetPlayerState({ hasPlayed: true, isPlaying: true });
      handleVideoViewedTracking();
    }
  }, [ autoPlay, playerState.isPlaying, playerState.hasPlayed, handleSetPlayerState ]);

  // grab correct video and set sizing
  useEffect(()=> {
    // video should cover if portrait on small devices
    if( isPortrait && videoElement ) {
      videoElement.classList.add( VIDEO_PORTRAIT_SMALL_CLASSES );
    } else if( videoElement && videoElement.classList && videoElement.classList.contains( VIDEO_PORTRAIT_SMALL_CLASSES )) {
      videoElement.classList.remove( VIDEO_PORTRAIT_SMALL_CLASSES );
    }
    if (  playerState.isDevicePortrait === undefined && isPortrait !== isLandscape ) {
      handleSetPlayerState({ isDevicePortrait: isPortrait });
    }
  },[ playerState.isDevicePortrait, handleSetPlayerState, videoElement, isPortrait, isLandscape ]);

  // Don't render video until we know the video orientation
  if( playerState.isDevicePortrait === undefined && isPortrait !== isLandscape ){
    return( <></> );
  }

  return(
    <div className={ `flex-1 relative ${className}` }>

      <VideoControls
        className="absolute inset-0 z-10"
        areCaptionsShown={ playerState.areCaptionsShown }
        areCaptionsEnabled={ !!subtitles }
        duration={ playerState.duration }
        hasPlayed={ playerState.hasPlayed }
        isMuted={ playerState.isMuted }
        isPlaying={ playerState.isPlaying }
        loaded={ playerState.loaded }
        onScrubStart={ e => {
          handleSetPlayerState({
            isPlaying: false,
            wasPlayingBeforeScrub: playerState.isPlaying,
          });
        } }
        onScrubEnd={ e => {
          const element = e.target as HTMLInputElement;
          player.current?.seekTo( parseFloat( element.value ));
          if( playerState.wasPlayingBeforeScrub ) {
            setIsPlaying( true );
          }
        } }
        onScrubChange={ e => handleSetPlayerState({ played: parseFloat( e.target.value ) }) }
        fractionPlayed={ playerState.played }
        playedSeconds={ playerState.playedSeconds }
        onCaptionsToggle={ onCaptionsToggle }
        onControlsHidden={ setCueLinePositioning }
        onControlsShown={ setCueLinePositioning }
        onMuteToggle={ onMuteToggle }
        onPlayToggle={ handleTogglePlay }
      />

      <ReactPlayer
        playsinline={ true }
        muted={ playerState.isMuted }
        progressInterval={ 25 }
        ref={ el => {
          if( el ){
            player.current = el;
            if( forwardRef ) {
              forwardRef.current = el;
            }
          }
        }  }
        full={ 'true' }
        controls={ false }
        playing={ playerState.isPlaying }
        onClickPreview={ handleTogglePlay }
        onEnded={ handleOnEnded }
        height={ height }
        width={ width }
        onProgress={ handleSetPlayerState }
        onDuration={ e => handleSetPlayerState({ duration: e }) }
        url={ getVideoSource( source, playerState.isDevicePortrait ) }
        config={{ file: subtitles ? { tracks: subtitles } : undefined }}
      />
    </div>
  );
};

export default withOrientationChange( VideoPlayer );

