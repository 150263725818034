
/**
 * Type guard to help typescript to understand if dynamic key is valid
 * https://stackoverflow.com/questions/58960077/how-to-check-if-a-strongly-typed-object-contains-a-given-key-in-typescript-witho
 * @param key
 * @param obj
 */
function isObjKey<T>( key: string|number|symbol, obj: T ): key is keyof T{
  return key in obj;
}


export default isObjKey;
