
import { DOCUMENTS_SUPPORTED } from '../core-data-service/models/Document';
import { TranslationKeys } from '../store/initial/translations';
import { DocumentSupported, Entitlements, ProductDocument } from '../types/Entitlements/EntitlementInterface';

export function isDocumentSupported( document: ProductDocument ): document is DocumentSupported{
  return document in DOCUMENTS_SUPPORTED;
}

export const getEntitlementNameKey = ( entitlementName: keyof Entitlements ): TranslationKeys => {
  let result: TranslationKeys;

  switch ( entitlementName ) {
  case 'trust':
    result = 'dashboard.products.trust.title';
    break;
  case 'will':
    result = 'dashboard.signedin.products.will.title';
    break;
  case 'healthcare_directive':
    result = 'dashboard.products.directive.title';
    break;
  case 'insurance':
    result = 'dashboard.signedin.products.life.title';
    break;
  case 'medical_consent':
    result = 'dashboard.products.medicalconsent.title';
    break;
  case 'power_of_attorney':
    result = 'dashboard.products.poa.title';
    break;
  }

  return result;
};
