import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';

interface StatesDict {
  [key: string]: {
    spouse_inheritance_floor: string;
  };
}

const initialState = {
  data: {
    config: {
      states: {} as StatesDict,
    },
  },
  fetchConfig: {
    meta: {} as AsyncThunkMetaInterface,
  },
};

export default initialState;
