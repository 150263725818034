import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Redux
import { useSelector, useDispatch } from '../../store';

// Utils
import inputMask from '../../utils/inputMask';
import useTranslation from '../../hooks/useTranslations';
import { EXPECTED_LENGTH } from '../../utils/inputMask/maskPhone';
import analytics from '../../utils/analytics';


// Components
import { Button } from '../../components/Button';
import ExternalDescriptiveLink from '../../components/ExternalDescriptiveLink';
import Form from '../../components/Form';
import Translation from '../../components/Translation';

// Actions
import { sendCode } from '../../store/reducers/authReducer';

// Constants
import { TextField } from '../../components/TextField';
import appConfig from '../../../core/appConfig';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';
import { fieldRequired, phoneNumberMinLength } from '../../formValidators';
import { ethosPartnerCodeSelector } from '../../store/selectors/userSelector';
import { Checkbox } from '../../components/Checkbox';
import { LOCAL_STORAGE_TCPA, LOCAL_STORAGE_TERMS } from '../../../core/constants';
import { EXTERNAL_ID_PARAM, getAnonymousID } from '../../utils/anonymousID';

interface SendCodeFormProps {
  onSuccess?: ()=> void;
  className?: string;
}

export interface SendCodeFormData extends SanitizedFormData {
  phone: string;
  tcpaAgreed: string;
  termsAgreed: string;
}

const SendCodeForm: FC<SendCodeFormProps> = ({
  className,
  onSuccess,
}) => {
  // Instantiate an action dispatcher
  const dispatch = useDispatch();

  const phone = useSelector( state => state.auth?.sendCode?.data?.phone ) || '';
  const hasEthosPartnerCode = useSelector( ethosPartnerCodeSelector );
  const shouldShowSignInLink = !hasEthosPartnerCode;

  const defaultValues = {
    phone,
    // Track agreement in localstorage because this is annoying
    tcpaAgreed: localStorage.getItem( LOCAL_STORAGE_TCPA ) ?? undefined,
    termsAgreed: localStorage.getItem( LOCAL_STORAGE_TERMS ) ?? undefined,
  };

  // @TODO: should this be a more re-usable / different layout to handle this
  // This is to handle an issue on mobile where coming from a scrolled page
  // will put the input above the viewport.
  useEffect(() => {
    window.scrollTo( 0,0 );
  }, []);

  useEffect(() => {
    analytics.track( 'View - Registration Phone' );
  }, []);

  // Form hooks
  const { register, setValue, getValues, handleSubmit, formState: { errors } } = useForm<SendCodeFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues,
  });

  const useFormProps = { register, setValue, getValues, errors };

  const { getTranslation } = useTranslation();

  // onSubmit handler
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    const now = new Date().toISOString();
    // if not previously agreed, set in localstorage
    !localStorage.getItem( LOCAL_STORAGE_TCPA ) && localStorage.setItem( LOCAL_STORAGE_TCPA, now );
    !localStorage.getItem( LOCAL_STORAGE_TERMS ) && localStorage.setItem( LOCAL_STORAGE_TERMS, now );
    dispatch( sendCode({ data: { phone: data.phone || null }, onSuccess }));
  };

  const getDestinationURL = () => {
    const ethosLoginUrl = new URL( getTranslation( 'ethos.policy.signin.url.string.v2' ), appConfig.ethos.baseUrl );
    ethosLoginUrl.searchParams.set( EXTERNAL_ID_PARAM, getAnonymousID());
    return ethosLoginUrl.toString();
  };

  // Application state
  const isPending = useSelector( state => state.auth?.sendCode?.meta?.requestStatus === 'pending' );

  return(
    <Form
      onSubmit={ onSubmit }
      className={ `${className} flex flex-col flex-1` }
      handleSubmit={ handleSubmit }
    >
      <h1 className="text-3xl mb-4 text-black font-headline">
        <Translation translationKey={ hasEthosPartnerCode ? 'screen.onboarding.phone.title.ethos.policy.holder' : 'screen.onboarding.phone.title' }/>
      </h1>
      <div>
        <TextField { ...useFormProps }
          large
          label="Phone"
          className="mb-4"
          minLength={ phoneNumberMinLength( getTranslation( 'screen.onboarding.phone.subtitle.error' ), EXPECTED_LENGTH ) }
          name="phone"
          normalize={ inputMask.phone }
          placeholder={ getTranslation( 'screen.onboarding.phone.field.placeholder' ) }
          required={ fieldRequired( getTranslation( 'profile.phone.label' )) }
          showLabel={ false }
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={ true }
          type="tel"
        />
        <div className="mb-6">
          <Checkbox
            { ...useFormProps }
            name="tcpaAgreed"
            label={ <p className="text-xs">
            I confirm that I consent to receive phone calls, emails, and text messages from Ethos Estate Planning, LLC and its affiliates (collectively, “Ethos”) as well as its agents, insurance carriers and service providers that may contact me via calls as well as text and SMS messages for advertising and marketing purposes at the phone number entered above or otherwise provided. I understand that (i) calls, texts and SMS messages may be auto-dialed and/or generated by an artificial or pre-recorded voice; (ii) I may opt out of these communications at any time by responding to an SMS message with “stop” or contacting <a href="https://www.ethoslife.com/contact-us" rel="noreferrer" target="_blank" className="underline">Ethos</a>; (iii) message and data rates may apply; and (iv) my consent to these communications is not a condition of any purchase. I also acknowledge having received and read the <a className="underline" href="https://www.ethoslife.com/sms-message-and-reminder-terms/" rel="noreferrer" target="_blank">Ethos Message and Reminder Terms & Conditions</a>.
            </p> }
            id="tcpaAgreed"
            required={ fieldRequired( 'Please agree to the above' ) }
            leftAlign
          />
          <Checkbox
            { ...useFormProps }
            name="termsAgreed"
            label={ <p className="text-xs">
            I confirm that I have read and agree to the <a href="https://plan.ethoslife.com/terms/" className="underline" target="_blank" rel="noreferrer">Terms of Service</a> and <a href="https://privacy.ethoslife.com/policies" target="_blank" className="underline" rel="noreferrer">Online Privacy Policy</a>.
            </p> }
            id="termsAgreed"
            required={ fieldRequired( 'Please agree to the above' ) }
            leftAlign
          />
        </div>
      </div>

      {shouldShowSignInLink && <ExternalDescriptiveLink
        className="mb-8"
        descriptionKey="screen.onboarding.phone.managepolicy.label"
        labelKey="screen.onboarding.phone.managepolicy.button"
        destinationUrl={ getDestinationURL() }
      />}

      <div className="flex justify-end flex-1">
        <div>
          <Button type="submit" isPending={ isPending } id={ 'onboarding-continue-button' }>
            <Translation translationKey={ 'onboarding.confirmation.code.button' } />
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default SendCodeForm;
