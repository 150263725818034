import { ReactElement, useState } from 'react';

// Components
import ActionContainer from '../../components/ActionContainer';
import Button from '../../components/Button';
import Person from '../../core-data-service/models/Person';
import PersonIcon from '../People/PersonIcon';
import Translation from '../../components/Translation';
import InvitationWrapper from '../../components/InvitationWrapper';
import PeopleSelector from '../../components/PeopleSelector';
import QuickAddCircleButton from '../../components/QuickAddCircleButton';

// Hooks
import useGuardians from '../../hooks/useGuardians';
import useTranslations from '../../hooks/useTranslations';

// Redux
import { useDispatch, useSelector } from '../../store';
import { postRole, deleteRole } from '../../store/reducers/rolesReducer';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import { showContextualModal } from '../../store/reducers/modalReducer';

// Types
import PersonInterface from '../../types/PersonInterface';
import RoleInterface from '../../types/RoleInterface';

// Models
import Role from '../../core-data-service/models/Role';
import { toTitleCase } from '../../../core/utils/stringHelpers';
import Invitation from '../../core-data-service/models/Invitation';

interface GuardianListItemProps {
  className?: string;
  ward: PersonInterface;
  role?: RoleInterface;
  isFallback?: boolean;
  showAddButton?: boolean;
}

const GuardiansListItem = ({
  className = '',
  ward,
  role,
  isFallback = false,
  showAddButton = true,
}: GuardianListItemProps ): ReactElement => {

  // Component state
  const [ isAddingGuardian, setIsAddingGuardian ] = useState<boolean>( false );

  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();

  const guardian = useSelector( state =>
    state.people.data.find( person => person.id === role?.grantee_id ),
  );

  const wardPerson = new Person( ward );
  const wardFirstName = wardPerson.firstName;
  const guardianPerson = guardian && new Person( guardian );
  const guardianFirstName = guardianPerson?.firstName || guardian?.name || '';

  const { potentialGuardians } = useGuardians();
  const alreadyGuardians = Role.allPrioritized( Role.TYPE.GUARDIAN, ward.id );
  const potentialNewGuardians = potentialGuardians
    .filter( person => !alreadyGuardians
      .find( role => role.grantee_id === person.id ));

  /**
   * Add a guardian to this ward
   */
  const addGuardian = () => {
    setIsAddingGuardian( true );
  };

  /**
   * Remove a guardian from this ward
   */
  const removeGuardian = () => {
    if ( !role || !guardian || !guardian.name ) {
      return;
    }
    const translationVars = [ guardian.name, wardFirstName ];
    dispatch( showContextualModal({
      description: getTranslation( 'banner.role.guardian.removed.confirm', translationVars ),
      dismissLabel: getTranslation( '(button)cancel' ),
      confirmLabel: getTranslation( '(button)remove' ),
      onConfirm: () => {
        dispatch( deleteRole({ id: role.id, onSuccess: () => {
          dispatch( makeSnack({ message: getTranslation( 'banner.role.guardian.removed.success', translationVars ) }));
        } }));

      },
    }));
  };
  const boldWard = ( <span className="font-bold">{wardFirstName}'s</span> );
  const boldWardHeader = ( <span>{boldWard} Guardian</span> );
  const designatedGuardianPrefix = !!guardian ? guardianFirstName : 'No one';
  const designatedGuardianText = ( <span>{designatedGuardianPrefix} is designated as {boldWard} {!!guardian && isFallback ? 'backup' : ''} legal guardian</span> );

  const handlePersonSelected = ( id: string ) => {
    setIsAddingGuardian( false );
    dispatch( postRole({
      grantee_id: id,
      name: Role.TYPE.GUARDIAN,
      ward: { id: ward.id },
    }));
  };

  return (
    <>
      {isAddingGuardian && <PeopleSelector
        isOpen
        people={ potentialNewGuardians }
        onSelect={ id => {
          handlePersonSelected( id );
        } }
        onClose={ id => {
          id ? handlePersonSelected( id ) : setIsAddingGuardian( false );
        }
        }
        optionTypeLabel={ toTitleCase( Role.TYPE.GUARDIAN ) }
      />}
      <div className={ `flex ${className}` }>
        <div className={ 'flex' }>
          <div
            className={ 'w-circleIcon_sm' }
          >
            {guardian
              ? <PersonIcon
                alt="avatar"
                personName={ guardian.name }
                photo_url={ guardian.photo_url }
                style={{ zIndex: 1 }}
              />
              : <QuickAddCircleButton onClick={ addGuardian } className={ 'z-10' } />
            }
          </div>
          <div
            className={ 'w-circleIcon_sm -ml-3' }
          >
            <PersonIcon
              alt="avatar"
              personName={ ward.name ?? null }
              photo_url={ ward.photo_url ?? null }
              style={{ zIndex: 0 }}
            />
          </div>
        </div>
        <ActionContainer
          className="flex-1 ml-4"
          headerClassName="mb-4"
          buttonId="add-existing-person"
          header={ isFallback ? 'Backup' : boldWardHeader }
          primaryActionLabel={ !!guardian ? getTranslation( '(button)remove' ) : getTranslation( '(button)add' ) }
          primaryAction={ !!guardian ? removeGuardian : addGuardian }
          primaryActionTheme={ !!guardian ? 'clean-danger' : 'clean' }
        >
          <div>
            { designatedGuardianText }
            {
              !!guardian && showAddButton &&
            <Button
              className="mt-4"
              theme="ghost"
              small
              onClick={ addGuardian }
            >
              { isFallback ? <Translation translationKey="screen.guardians.button.addanotherbackup" />: <Translation translationKey="screen.guardians.button.addbackup" /> }
            </Button>
            }
          </div>
        </ActionContainer>
      </div>
      { guardian?.id && <div className="mt-4">
        <InvitationWrapper
          inviteeId={ guardian?.id }
          role={ Role.TYPE.GUARDIAN }
          invitationType={ Invitation.TYPE_ROLE }
        />
      </div>}
    </>
  );
};

export default GuardiansListItem;
