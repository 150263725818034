import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import WebClientRequest from '../../core-data-service/WebClientRequest';
import initialState from '../initial/applications';
import ApplicationInterface ,{ AxiosApplicationResponse, AxiosApplicationsResponse } from '../../types/ApplicationInterface';


interface IFetchApplications {
  onSuccess?: ( response: AxiosApplicationsResponse )=> void;
}

interface IFetchApplication {
  id: string;
  onSuccess?: ( response: AxiosApplicationResponse )=> void;
}

interface IPatchApplication {
  id: string;
  onSuccess?: ( response: AxiosApplicationResponse )=> void;
  onError?: ( response: AxiosApplicationResponse )=> void;

}

export const fetchApplications = createAsyncThunk(
  'applications/fetchApplications',
  async( arg: IFetchApplications | void, thunkAPI )=> {
    const url = '/v1/applications';
    return WebClientRequest
      .get( url )
      .then(( response: AxiosApplicationsResponse ) => {
        const applications = response.data.data;
        thunkAPI.dispatch( updateApplications( applications ));
        arg && arg.onSuccess && arg.onSuccess( response );
      });
  },
);

export const fetchApplication = createAsyncThunk(
  'applications/fetchApplication',
  async( arg: IFetchApplication, thunkAPI )=> {
    const url = `/v1/applications/${arg.id}`;
    return WebClientRequest
      .get( url )
      .then( response => {
        const applications = response.data.data;
        thunkAPI.dispatch( updateApplications( applications ));
        arg.onSuccess && arg.onSuccess( response );
      });
  },
);

export const patchApplication = createAsyncThunk(
  'applications/patchApplication',
  async({ id, onSuccess, onError, ...data }: IPatchApplication, thunkAPI )=> {
    const url = `v1/applications/${id}`;
    return WebClientRequest
      .patch( url, data )
      .then( response => {
        const applications = response.data.data;
        thunkAPI.dispatch( updateApplication( applications ));
        onSuccess && onSuccess( response );
      }).catch( error => {
        onError && onError( error );
      });
  },
);

const applicationSlice = createSlice({
  name:'applications',
  initialState,
  reducers:{
    updateApplications( applications, action: PayloadAction<ApplicationInterface[]> ){
      if( applications.data.length === 0 ){
        applications.data = action.payload;
      } else {
        action.payload.forEach( newApplication =>{
          const idx = applications.data.findIndex( existingApplication => existingApplication.id === newApplication.id );
          if( idx >= 0 ){
            applications.data[idx] =  newApplication;
          }else{
            applications.data.push( newApplication );
          }
        });
      }
    },

    updateApplication( application, action: PayloadAction<ApplicationInterface> ){
      const payload = action.payload;
      const idx = application.data.findIndex( application=>application.id === payload.id );
      if( idx>=0 ){
        application.data[idx] = payload;
      }else{
        application.data.push( payload );
      }
    },
  },

  extraReducers: builder => {

    //fetchApplications
    builder.addCase( fetchApplications.pending, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.fetchApplications.meta = meta;
    });
    builder.addCase( fetchApplications.fulfilled, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.fetchApplications.meta = meta;
    });
    builder.addCase( fetchApplications.rejected, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.fetchApplications.meta = meta;
    });

    //fetchApplication
    builder.addCase( fetchApplication.pending, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.fetchApplication.meta = meta;
    });
    builder.addCase( fetchApplication.fulfilled, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.fetchApplication.meta = meta;
    });
    builder.addCase( fetchApplication.rejected, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.fetchApplication.meta = meta;
    });

    //patchApplication
    builder.addCase( patchApplication.pending, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.patchApplication.meta = meta;
    });
    builder.addCase( patchApplication.fulfilled, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.patchApplication.meta = meta;
    });
    builder.addCase( patchApplication.rejected, ( applications, action ) =>{
      const { arg, ...meta }= action.meta;
      applications.patchApplication.meta = meta;
    });
  },
});

export const { updateApplications, updateApplication } = applicationSlice.actions;
export default applicationSlice.reducer;
