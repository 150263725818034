import { ReactElement, useEffect, useState } from 'react';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import Modal from '../../components/Modal';
import TextField from '../../components/TextField';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import useQuery from '../../hooks/useQuery';

// Redux
import { dashboard as dashboardRoute } from '../../../core/routes';
import SanitizedFormData from '../../../core/types/SanitizedFormData';
import { useDispatch } from '../../store';
import { makeSnack } from '../../store/reducers/snackbarReducer';

// Utils
import appConfig from '../../../core/appConfig';


export default function PasswordReset(): ReactElement{

  const { goBack } = useBackstack();
  const { push } = useHistory();
  const dispatch = useDispatch();

  // Query parameters
  const query = useQuery();
  const token = query.get( 't' );
  const user = query.get( 'u' );

  // Component state
  const [ isOpen, setIsOpen ] = useState( true );
  const [ isPending, setIsPending ] = useState<boolean>( false );

  // @TODO - trigger close and do this automatically
  const onClose = () => {
    setIsOpen( false );
    setTimeout(() => goBack(), 300 );
  };

  /**
   * This form doesn't need any auth credentials or to store anything in redux
   * but still getting 401 Unauthorized ?
   */
  const onSubmit: SubmitHandler<SanitizedFormData> = async({ password }) => {
    setIsPending( true );

    const url = combineURLs( appConfig.api.baseurl, 'v1/passwordReset' );

    const data = {
      token,
      user,
      password,
    };

    return axios.patch( url, data )
      .then( response => {

        dispatch( makeSnack({ message: 'Your password has been updated.' }));

        setIsPending( false );
        reset();
        setIsOpen( false );
        setTimeout(() => push( dashboardRoute.get()));
      })
      .catch( error => {

        dispatch( makeSnack({
          message: error.message || 'Sorry, something went wrong',
          theme: 'error',
        }));

        setIsPending( false );
        reset();
      });
  };

  /**
   * If missing needed query params, navigate away from this page.
   */
  useEffect(() => {
    if( !token || !user ){
      push( dashboardRoute.get());
    }
  }, [ token, user, push ]);


  const {
    formState: { errors },
    handleSubmit,
    getValues,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const minLength: RegisterOptions['minLength'] = {
    value: 8,
    message: 'Please enter at least 8 characters',
  };

  const required: RegisterOptions['required'] = {
    value: true,
    message: 'This field is required',
  };

  const useFormProps = {
    maxLength: 64,
    errors,
    getValues,
    register,
    setValue,
  };

  return (
    <Modal
      onClose={ onClose }
      isOpen={ isOpen }
    >
      <h1 className="text-lg mb-8">Reset Password</h1>
      <Form
        onSubmit={ onSubmit }
        handleSubmit={ handleSubmit }
      >
        <TextField { ...useFormProps }
          name="password"
          label="Password"
          className="mb-4"
          type="password"
          minLength={ minLength }
          required={ required }
        />
        <TextField { ...useFormProps }
          name="verifyPassword"
          label="Verify Password"
          className="mb-4"
          type="password"
          minLength={ minLength }
          required={ required }
          validate={
            value => {
              if( watch( 'password' ) !== value ){
                return 'Passwords don\'t match';
              } else {
                return true;
              }
            }
          }
        />
        <div className="flex justify-end items-end flex-1">
          <div>
            <Button type="submit" isPending={ isPending }>
              Reset Password
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
