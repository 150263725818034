import { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { useSelector } from '../store';

import { SNACKBAR_TRANSITION_DURATION_OUT, SNACKBAR_TRANSITION_DURATION_IN } from '../settings';
import { useCurrentBreakpoint } from '../hooks/useCurrentBreakpoint';


type SnackbarTheme = 'success' | 'warning' | 'error'

export interface SnackBarProps {
  className?: string;
  duration?: number;
  isClosing?: boolean;
  uniqueId?: string;
  message: string;
  theme?: SnackbarTheme;
}

const getSnackbarThemeStyle = ( theme: SnackbarTheme ) => {
  switch( theme ) {
  case 'success':
    return 'bg-green';
  case 'error':
    return 'bg-red';
  case 'warning':
    return 'bg-salamander';
  }
};

const snackbarsPortal = document.querySelector( '#snackbars-portal' ) as HTMLElement;

export const Snackbar: FC<SnackBarProps> = ({
  className = '',
  message,
  theme = 'success',
  isClosing = false,
  uniqueId,
}) => {

  const { breakpoint } = useCurrentBreakpoint();
  const isMobile = breakpoint === 'small';

  return createPortal(
    <AnimatePresence>
      {!isClosing &&

        // change animation props based on screen width
        <motion.div
          layout
          key={ uniqueId }
          initial={{ opacity: 0, y: isMobile ? -50 : 50, scale: .75 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: isMobile ? -20 : 20, scale: .75 }}
          transition={{ ease: 'anticipate', duration: ( isClosing ? SNACKBAR_TRANSITION_DURATION_OUT : SNACKBAR_TRANSITION_DURATION_IN ) / 1000 }}
          className={ `
          inline-flex
          items-center
          justify-center
          px-8
          py-5
          w-full
          md:w-auto
          md:rounded-full
          text-center
          text-white
          font-medium
          ${getSnackbarThemeStyle( theme )}
          ${className}
        ` }
        >
          {/*

            @a11y

            Role: `alert`
            https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_alert_role

            To maximize compatibility, some people recommend adding a redundant
            aria-live="assertive" when using this role. However, adding both
            aria-live and role="alert" causes double speaking issues in
            VoiceOver on iOS. (as of 3/5/2021)
            https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions#roles_with_implicit_live_region_attributes

          */}
          <span
            style={{ marginTop: '-3px' }}
            role="alert"
            className="pointer-events-auto max-w-md"
            // aria-live="assertive"
          >{message}</span>
        </motion.div>
      }
    </AnimatePresence>,
    snackbarsPortal,
  );
};


const SnackbarManager: FC = () => {
  const snacks = useSelector( state => state.snackbar.items );
  return(
    <>
      { snacks.map(( snack, idx ) =>
        <Snackbar key={ snack.uniqueId || `Snack${idx}` } { ...snack } /> )
      }
    </>
  );
};



export default SnackbarManager;
