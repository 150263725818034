import { ReactElement, useEffect, useState } from 'react';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import WebClientRequest from '../../core-data-service/WebClientRequest';
import useBackstack from '../../hooks/useBackstack';
import useQuery from '../../hooks/useQuery';

const BRANCH_LINK = 'https://app.tmro.com/hH8PtlREb4';

export default function EmailVerification(): ReactElement{

  const { goBack } = useBackstack();

  // Query parameters
  const query = useQuery().get( 'q' );

  // Component state
  const [ isOpen, setIsOpen ] = useState( true );
  const [ headerText, setHeaderText ] = useState<string>( 'Verifying...' );
  const [ subHeaderText, setSubHeaderText ] = useState<string>( '...' );

  // @TODO - trigger close and do this automatically
  const onClose = () => {
    setIsOpen( false );
    setTimeout(() => goBack(), 300 );
  };

  useEffect(() => {
    const data = { query };
    WebClientRequest
      .post( 'v1/verifyEmail/', data )
      .then( response => {
        setHeaderText( 'Success!' );
        setSubHeaderText( 'Thank you for verifying your email.' );
      })
      .catch( error => {
        setHeaderText( 'Unable to Verify' );
        setSubHeaderText( 'Please verify using your work email address.' );
      });
  }, [ query ]);

  return (
    <Modal
      onClose={ onClose }
      isOpen={ isOpen }
    >
      <h1 className="text-3xl mb-8">Email Verification</h1>
      <h2 className="text-2xl mb-2">{ headerText }</h2>
      <h2 className="text-sm">{ subHeaderText }</h2>
      <div className="my-6">
        <a href={ BRANCH_LINK }>
          <Button>
            Re-Open App
          </Button>
        </a>
      </div>
    </Modal>
  );
}
