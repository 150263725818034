import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Components
import { DashboardDocumentCard } from './DashboardDocumentCard';
import Translation from '../../components/Translation';
import { DashboardSignedOutNotPurchasedDocumentSection } from '../../components/DashboardSignedOutNotPurchasedDocumentSection';
import { Card, CardImage } from '../../components/Card';
import InsuranceHelper from '../../components/InsuranceHelper';
import { DashboardSignedInDocumentSection } from '../../components/DashboardSignedInDocumentSection';

// Hooks
import useAuth from '../../hooks/useAuth';
import useTranslations from '../../hooks/useTranslations';
import useUserAttributes from '../../hooks/useUserAttributes';
import useWillSectionStatus from '../../hooks/useWillSectionStatus';

// Redux
import entitlementsSelector from '../../store/selectors/entitlementsSelector';
import { GlobalModals } from '../../store/initial/modal';
import { showModal } from '../../store/reducers/modalReducer';
import { useSelector } from '../../store';
import productsSelector from '../../store/selectors/productsSelector';
import useRenderInsuranceUpsell from '../../hooks/useRenderInsuranceUpsell';

// Routes
import {
  disability,
  trust,
  lifeInsurance as lifeInsuranceRoute,
  poa,
  will,
  healthcare_directive,
  medical_consent,
} from '../../../core/routes';

// Types
import { DocumentEntitlements, Entitlements } from '../../types/Entitlements/EntitlementInterface';

// Utils
import { SESSION_STORAGE_POUR_OVER_WILL } from '../../../core/constants';
import analytics from '../../utils/analytics';
import isDefined from '../../utils/isDefined';
import { ethosPartnerCodeSelector } from '../../store/selectors/userSelector';
import { BASIC_DOCUMENTS, PRO_DOCUMENTS } from '../../core-data-service/models/Document';
import DashboardInsuranceCard from '../../components/DashboardInsuranceCard';
interface DashboardDocument{
  shouldHide?: boolean;
  title: ReactNode;
  data_tid?: string;
  body: ReactNode;
  image: CardImage;
  name: keyof Entitlements;
  primaryActionLabel: ReactNode;
  userHasStarted?: boolean;
  primaryAction?: ()=> void;
isEnabled?: boolean;
isSupported?: boolean;}
export default function DashboardDocuments(): ReactNode{

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const { availableDocuments, unavailableDocuments, insurance, ...documentEntitlements } = useSelector( entitlementsSelector );
  const { pro, basic } = useSelector( productsSelector );
  const hasPurchasedAProduct = pro.hasPurchased || basic.hasPurchased;

  const {
    documents: {
      disability: { hasStartedIntroduction, hasCompletedPayment },
      will: { hasConfirmedWillBasics, shouldBeReviewed: shouldReviewWill },
      trust: { hasConfirmedTrustBasics, hasViewedPreview, shouldBeReviewed: shouldReviewTrust },
      poa: { hasStartedPOADocument },
      healthcareDirective: { hasStartedDocument: hasStartedHealthcareDirectiveDocument },
    },
  } = useUserAttributes();
  const { hasAuthenticated, hasCompletedOnboarding } = useAuth();
  const { hasCompletedWill } = useWillSectionStatus();

  const hasUserStartedWill = !!hasConfirmedWillBasics && hasAuthenticated;
  const hasUserStartedDisability = isDefined( hasStartedIntroduction )  && hasAuthenticated;
  const hasUserFinishedDisability = isDefined( hasCompletedPayment )  && hasAuthenticated;
  const hasFinishedTrust = hasViewedPreview;
  const isLoggedIn = hasAuthenticated && hasCompletedOnboarding;

  const hasEthosPartnerCode = useSelector( ethosPartnerCodeSelector );

  // this shows the insurance bubble on dashbaord
  const isInsuranceUpsellShown = useRenderInsuranceUpsell() && !hasEthosPartnerCode;
  // this shows the policies section
  const shouldRenderInsuranceTile = !hasEthosPartnerCode && isLoggedIn;

  const documents: DashboardDocument[] = [
    // LENOX
    {
      shouldHide: insurance.disability.feature !== 'enabled',
      data_tid : 'LENOX',
      title: <Translation translationKey={ 'dashboard.products.disability.title' }/>,
      body: getDisabilityCtaBody( hasUserFinishedDisability,hasUserStartedDisability ),
      image: { url: getTranslation( 'dashboard.sections.tools.icons.balloon' ), alt: 'Disability Insurance illustration', rounded: true },
      primaryAction: () => {
        analytics.track( 'Touch - Disability Insurance Lens' );
        if( hasUserFinishedDisability ) {
          dispatch( showModal( GlobalModals.lenoxAdvisor ));
        }else{
          push( disability.get());
        }
      },
      name: 'insurance',
      primaryActionLabel: getDisabilityCtaButtonLabel( hasUserFinishedDisability, hasUserStartedDisability ),
      userHasStarted: hasUserStartedDisability,
    },
    // WILL
    {
      isEnabled: documentEntitlements.will.isAccessible,
      isSupported: documentEntitlements.will.isSupported,
      name: 'will',
      data_tid: 'will-document' ,
      title: <Translation translationKey={ 'dashboard.signedin.products.will.title' }/>,
      body: getWillCtaBody( isLoggedIn, hasCompletedWill, hasUserStartedWill, !!shouldReviewWill ),
      image: { url: getTranslation( 'dashboard.ethos.tools.icons.will' ), alt: 'Will illustration', rounded: true },
      primaryAction: () => {
        analytics.track( 'Touch - Will Lens' );
        //@TODO remove when backstack cleanup complete
        sessionStorage.removeItem( SESSION_STORAGE_POUR_OVER_WILL );
        push( will.get());
      },
      primaryActionLabel: getWillCtaButtonLabel( isLoggedIn, hasCompletedWill, hasUserStartedWill, !!shouldReviewWill ),
      userHasStarted: hasUserStartedWill,
    },
    // LIFE INSURANCE
    {
      isEnabled: insurance.life.feature !== 'unavailable',
      name: 'insurance',
      data_tid: 'lifeInsurance-document',
      title: isLoggedIn?
        <Translation translationKey={ 'dashboard.signedin.products.life.title' }/> :
        <Translation translationKey={ 'dashboard.signedout.products.life.title' }/>,
      body: isLoggedIn?
        <Translation translationKey={ 'dashboard.signedin.products.life.description' }/>:
        <Translation translationKey={ 'dashboard.signedout.products.life.description' }/>,
      image: { url: getTranslation( 'dashboard.ethos.tools.icons.life' ), alt: 'Life Insurance illustration', rounded: true },
      primaryAction: () => {
        analytics.track( 'Touch - Life Lens' );
        insurance.life.feature !== 'unavailable' && push( lifeInsuranceRoute.get());
      },
      primaryActionLabel: isLoggedIn?
        <Translation translationKey={ 'dashboard.signedin.products.life.button' }/> :
        <Translation translationKey={ 'dashboard.signedout.products.life.button' }/>,
      userHasStarted: false,
    },
    // TRUST
    {
      isEnabled: documentEntitlements.trust.isAccessible,
      isSupported: documentEntitlements.trust.isSupported,
      data_tid:'trust-document',
      name: 'trust',
      title: <Translation translationKey={ 'dashboard.products.trust.title' }/>,
      body: getTrustBody( hasFinishedTrust, hasConfirmedTrustBasics, shouldReviewTrust ),
      image: { url: getTranslation( 'dashboard.ethos.tools.icons.trust' ), alt: 'Trust illustration', rounded: true },
      primaryAction: () => {
        analytics.track( 'Touch - Trust Lens' );
        push( trust.get());
      },
      primaryActionLabel: getTrustCTA( hasFinishedTrust, hasConfirmedTrustBasics, isLoggedIn, shouldReviewTrust ),
      userHasStarted: hasConfirmedTrustBasics,
    },
    // Power of Attorney
    {
      isEnabled: documentEntitlements.power_of_attorney.isAccessible,
      isSupported: documentEntitlements.power_of_attorney.isSupported,
      name: 'power_of_attorney',
      data_tid:'poa-document',
      title: <Translation translationKey="dashboard.products.poa.title" />,
      body: isLoggedIn?
        <Translation translationKey="dashboard.signedin.products.poa.description" />:
        <Translation translationKey="dashboard.signedout.products.poa.description" />,
      image: { url: getTranslation( 'dashboard.ethos.tools.icons.poa' ), alt: 'Power of Attorney illustration', rounded: true },
      primaryAction: () => {
        analytics.track( 'Touch - POA Lens' );
        push( poa.get());
      },
      primaryActionLabel: <Translation translationKey="dashboard.products.poa.button" />,
      userHasStarted: hasStartedPOADocument,
    },
    // Healthcare Directive
    {
      isEnabled: documentEntitlements.healthcare_directive.isAccessible,
      isSupported: documentEntitlements.healthcare_directive.isSupported,
      data_tid:'healthcare-document',
      name: 'healthcare_directive',
      title: <Translation translationKey="dashboard.products.directive.title" />,
      body: isLoggedIn?
        <Translation translationKey="dashboard.signedin.products.directive.description" />:
        <Translation translationKey="dashboard.signedout.products.directive.description" />,
      image: { url: getTranslation( 'dashboard.ethos.tools.icons.health' ), alt: 'Healthcare Directive illustration', rounded: true },
      primaryActionLabel: <Translation translationKey="dashboard.products.directive.button" />,
      primaryAction: () => {
        analytics.track( 'Touch - Healthcare Directive Lens' );
        push( healthcare_directive.get());
      },

      userHasStarted: hasStartedHealthcareDirectiveDocument,
    },
    // Medical Consent
    {
      isEnabled: documentEntitlements.medical_consent.isAccessible,
      isSupported: documentEntitlements.medical_consent.isSupported,
      name: 'medical_consent',
      data_tid:'medicalConsent-document',
      title: <Translation translationKey="dashboard.products.medicalconsent.title" />,
      body: isLoggedIn?
        <Translation translationKey="dashboard.signedin.products.medicalconsent.description" />:
        <Translation translationKey="dashboard.signedout.products.medicalconsent.description" />,
      image: { url: getTranslation( 'dashboard.ethos.tools.icons.medical' ), alt: 'Medical Consent illustration', rounded: true },
      primaryActionLabel: <Translation translationKey="dashboard.products.medicalconsent.button" />,
      primaryAction: () => {
        analytics.track( 'Touch - Medical Consent Lens' );
        push( medical_consent.get());
      },
    },
  ];

  const filteredDocuments =( allDocuments: DashboardDocument[], acceptedDocuments: Array<keyof Entitlements> )=>{
    return(
      allDocuments.filter( document=>acceptedDocuments.includes( document.name as keyof DocumentEntitlements )).map(( document, i ) => (
        !document.shouldHide && <DashboardDocumentCard
          documentName={ document.name as keyof DocumentEntitlements }
          isEnabled={ document.isEnabled }
          isSupported={ document.isSupported }
          shouldShowProLabel={ false }
          data_tid={ document.data_tid }
          key={ `document-${i}` }
          title={ document.title }
          primaryAction={ document.primaryAction }
          primaryActionLabel={ document.primaryActionLabel }
          media={ hasPurchasedAProduct ? document.image : undefined }
          notPurchased={ !hasPurchasedAProduct }
        >
          { document.body }
        </DashboardDocumentCard>
      )));
  };

  return (
    ( hasPurchasedAProduct )?
      <>
        <DashboardSignedInDocumentSection title={ <Translation translationKey={ 'dashboard.sections.tools.header' } /> } > {filteredDocuments( documents, PRO_DOCUMENTS )}</DashboardSignedInDocumentSection>
        {( shouldRenderInsuranceTile ) && <DashboardSignedInDocumentSection title={ <Translation translationKey={ 'dashboard.sections.policies.header' } /> } > {filteredDocuments( documents, [ 'insurance' ])}</DashboardSignedInDocumentSection>}
      </>
      :
      <>
        { isInsuranceUpsellShown && <DashboardSignedOutNotPurchasedDocumentSection
          title={ <Translation translationKey="dashboard.ethos.description.title" /> }
          priceLabel={ <Translation translationKey="dashboard.ethos.description.price" /> }
          description={ <Translation translationKey="dashboard.ethos.description.description" /> }
        >
          <DashboardInsuranceCard />
        </DashboardSignedOutNotPurchasedDocumentSection>}
        <DashboardSignedOutNotPurchasedDocumentSection
          title={ <Translation
            translationKey="dashboard.plans.basic.title"/> }
          priceLabel={ basic.price.displayValue }
          description={ <Translation translationKey="dashboard.plans.basic.subtitle"/> }
        >
          {filteredDocuments( documents, BASIC_DOCUMENTS )}
        </DashboardSignedOutNotPurchasedDocumentSection>
        <DashboardSignedOutNotPurchasedDocumentSection
          title={ <Translation translationKey="dashboard.plans.pro.title"/> }
          priceLabel={ pro.price.displayValue }
          description={ <Translation translationKey="dashboard.plans.pro.subtitle"/> }
        >
          {filteredDocuments( documents.filter( document=>!BASIC_DOCUMENTS.includes( document.name as keyof DocumentEntitlements )), PRO_DOCUMENTS )}
        </DashboardSignedOutNotPurchasedDocumentSection>
      </>

  );
}

const getWillCtaBody = ( isLoggedIn: boolean, hasCompletedWill: boolean, hasUserStartedWill: boolean, willNeedsReview: boolean ): string | ReactNode => {
  if ( willNeedsReview ) {
    return getReviewLabel( <Translation translationKey="dashboard.signedin.products.will.needsreview.description" /> );
  } else if( isLoggedIn && hasCompletedWill ) {
    return <Translation translationKey={ 'dashboard.signedin.products.will.completed.description' }/>;
  } else if ( hasUserStartedWill ) {
    return <Translation translationKey={ 'dashboard.signedin.products.will.inprogress.description' }/>;
  } else {
    return <Translation translationKey={ 'dashboard.signedin.products.will.unstarted.description' }/>;
  }
};

const getWillCtaButtonLabel = ( isLoggedIn: boolean, hasCompletedWill: boolean, hasUserStartedWill: boolean, willNeedsReview: boolean ): string | ReactNode => {
  if ( isLoggedIn ){
    if ( willNeedsReview ) {
      return <Translation translationKey={ 'dashboard.signedin.products.will.needsreview.button' } />;
    } else if( hasCompletedWill ) {
      return <Translation translationKey={ 'dashboard.signedin.products.will.completed.button' }/>;
    } else if ( hasUserStartedWill ) {
      return <Translation translationKey={ 'dashboard.signedin.products.will.inprogress.button' }/>;
    }
  }
  return <Translation translationKey={ 'dashboard.signedin.products.will.unstarted.button' }/>;
};

const getDisabilityCtaBody = ( hasCompletedDisability: boolean, hasStartedDisability: boolean ): string | ReactNode => {
  if( hasCompletedDisability ) {
    return <Translation translationKey={ 'dashboard.products.disability.completed.description' }/>;
  } else if ( hasStartedDisability ) {
    return <Translation translationKey={ 'dashboard.products.disability.inprogress.description' }/>;
  } else {
    return <Translation translationKey={ 'dashboard.products.disability.unstarted.description' }/>;
  }
};
const getDisabilityCtaButtonLabel = ( hasCompletedDisability: boolean, hasStartedDisability: boolean ): string | ReactNode => {
  if ( hasCompletedDisability ) {
    return <Translation translationKey={ 'dashboard.products.disability.completed.button' }/>;
  } else if ( hasStartedDisability ) {
    return <Translation translationKey={ 'dashboard.products.disability.inprogress.button' }/>;
  } else {
    return <Translation translationKey={ 'dashboard.products.disability.unstarted.button' }/>;
  }
};
const getTrustBody = ( hasFinishedTrust: boolean | undefined, hasStartedTrust: boolean | undefined, trustNeedsReview?: boolean ): ReactNode => {
  if( trustNeedsReview ){
    return getReviewLabel( <Translation translationKey="dashboard.signedin.products.trust.needsreview.description" /> );
  } else if ( !hasStartedTrust ) {
    return <Translation translationKey="dashboard.signedin.products.trust.unstarted.description"/>;
  } else if ( hasStartedTrust && !hasFinishedTrust ) {
    return <Translation translationKey="dashboard.signedin.products.trust.inprogress.description" />;
  } else {
    return <Translation translationKey="dashboard.signedin.products.trust.completed.description" />;
  }
};
const getTrustCTA = ( hasFinishedTrust: boolean | undefined, hasStartedTrust: boolean | undefined, isLoggedIn: boolean, trustNeedsReview?: boolean ): ReactNode => {
  if ( !isLoggedIn ) {
    return <Translation translationKey="dashboard.signedin.products.trust.unstarted.button" />;
  }
  if( trustNeedsReview ) {
    return <Translation translationKey="dashboard.signedin.products.trust.needsreview.button" />;
  } else if ( !hasStartedTrust ) {
    return <Translation translationKey="dashboard.signedin.products.trust.unstarted.button" />;
  } else if ( hasStartedTrust && !hasFinishedTrust ) {
    return <Translation translationKey="dashboard.signedin.products.trust.inprogress.button"/>;
  } else {
    return <Translation translationKey="dashboard.signedin.products.trust.completed.button" />;
  }
};

const getReviewLabel = ( children: ReactNode ) => {
  return <span className="text-salamander bg-buttercup rounded-sm p-1 mt-1">{children}</span>;
};
