import { ReactElement } from 'react';
import { Controller, ControllerProps, FieldErrors, UseFormMethods } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

interface Props {
  className?: string;
  control: UseFormMethods['control'];
  label: string|React.ReactNode;
  name: string;
  errors: FieldErrors;
  rules?: ControllerProps<'input'>['rules'];
}

const CurrencyField = ({ className, control, errors, label, name, rules }: Props ): ReactElement => {
  const fieldError = errors[name];

  return (
    <Controller
      name={ name }
      control={ control }
      rules={ rules }
      render={ ({ value, onChange }, { invalid }) => {
        return (
          <FieldLabel
            label={ label }
            name={ name }
            className={ className }
          >
            <NumberFormat
              value={ value }
              thousandSeparator={ true }
              inputMode="decimal"
              decimalScale={ 2 }
              prefix="$ "
              onValueChange={ v => onChange( v.value ) }
            />
            {!!fieldError &&
              <FieldError
                className="mt-2"
                errorMessage={ fieldError.message }
              />
            }
          </FieldLabel>
        );
      } }/>
  );
};

export default CurrencyField;
