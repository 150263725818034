import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import appConfig from '../../../core/appConfig';
import InvitePageInterface from '../../types/InvitePageInterface';

// Data
import initialState from '../initial/wordpress';

interface IFetchInvitePageData  {
  role?: string;
  onSuccess?: ( response: InvitePageInterface )=> void;
  onError?: ( response: AxiosResponse )=> void;
}

export const fetchInvitePageData = createAsyncThunk(
  'wordpress/fetchInvitePageData',
  async({ role = 'default', onSuccess, onError }: IFetchInvitePageData, thunkAPI ) => {

    return fetch( `${appConfig.wordpress.apiUrl}/invites?slug=${role}` )
      .then( response => {
        return response.json();
      })
      .then(( data: InvitePageInterface[]) => {
        const pageData = data[0];
        thunkAPI.dispatch( updateInvitePageData( pageData ));
        onSuccess && onSuccess( pageData );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);

const wordpressSlice = createSlice({
  name: 'wordpress',
  initialState,

  reducers: {
    updateInvitePageData( wordpress, action: PayloadAction<InvitePageInterface> ){
      const payload = action.payload;
      wordpress.data.inviteLandingPages.push( payload );
    },

  },

  extraReducers: builder => {
    // fetchInvitation.<status>
    builder.addCase( fetchInvitePageData.pending, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitePageData.meta = meta;
    });
    builder.addCase( fetchInvitePageData.fulfilled, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitePageData.meta = meta;
    });
    builder.addCase( fetchInvitePageData.rejected, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitePageData.meta = meta;
    });
  },
});

export const { updateInvitePageData } = wordpressSlice.actions;

export default wordpressSlice.reducer;
