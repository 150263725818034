import { ProductDocument } from '../../types/Entitlements/EntitlementInterface';

enum Document {

	TYPE_WILL = 'Will',
  TYPE_POUROVER_WILL = 'PouroverWill',
  TYPE_SIMPLE_WILL = 'SimpleWill',

	TYPE_TRUST = 'Trust',
	TYPE_BELONGINGS = 'BelongingsReport',
	TYPE_ASSETS = 'AssetsReport',
	TYPE_MEDICAL_CONSENT = 'MedicalConsent',
	TYPE_HEALTHCARE_DIRECTIVE = 'HealthcareDirective',
	TYPE_POWER_OF_ATTORNEY = 'PowerOfAttorney',

	STATUS_PREVIEW = 'PREVIEW',
	STATUS_SUCCESS = 'SUCCESS',
	STATUS_FAIL = 'FAIL',
}


export const DOCUMENTS_SUPPORTED = {
  will: 'will',
  trust: 'trust',
  power_of_attorney: 'power_of_attorney',
  healthcare_directive: 'healthcare_directive',
  medical_consent: 'medical_consent',
} as const;

export const DOCUMENTS_UNSUPPORTED = {
} as const;

export const DOCUMENTS = {
  ...DOCUMENTS_SUPPORTED,
  ...DOCUMENTS_UNSUPPORTED,
} as const;

export const PRO_DOCUMENTS: Array<ProductDocument> = [
  DOCUMENTS.will,
  DOCUMENTS.power_of_attorney,
  DOCUMENTS.trust,
  DOCUMENTS.healthcare_directive,
  DOCUMENTS.medical_consent,
];

export const BASIC_DOCUMENTS: Array<ProductDocument> = [
  DOCUMENTS.will,
];

export type AvailableDocumentType = Document.TYPE_WILL | Document.TYPE_TRUST | Document.TYPE_MEDICAL_CONSENT | Document.TYPE_HEALTHCARE_DIRECTIVE | Document.TYPE_POWER_OF_ATTORNEY | Document.TYPE_POUROVER_WILL | Document.TYPE_SIMPLE_WILL;

export default Document;
