import React, { ReactElement, ReactNode } from 'react';
export interface DashboardSignedOutNotPurchasedDocumentSectionProps {
  title?: ReactNode;
  priceLabel?: ReactNode;
  description?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const DashboardSignedOutNotPurchasedDocumentSection = ({ title, priceLabel, description, children, className }: DashboardSignedOutNotPurchasedDocumentSectionProps ): ReactElement => {
  return (
    <div className={ `mb-8 ${className}` }>
      <hr className="sm-only:-mx-8 mb-8 border-t border-grey"/>
      <div className="md:grid grid-cols-2">
        <div className="flex flex-wrap md:block">
          <div className="flex items-center">
            <h2 className="mb-2 mr-4 text-3xl font-headline text-black">{title}</h2>
          </div>
          <p className="sm-only:hidden mt-2 text-dark-grey">{description}</p>
          <div className="flex items-center justify-start md:mt-2">
            <div className="flex items-center justify-center min-w-[50px] bg-moss text-forest rounded-md h-6 px-2">{priceLabel}</div>
          </div>
        </div>
        <div className="sm-only:mt-4 grid gap-y-6">{children}</div>
      </div>
    </div>
  );
};
