import AddressInterface from '../types/AddressInterface';
import { Keyable } from '../types/Keyable';

export const addressTransformer = ( data: Keyable ): { address: Partial<AddressInterface> } => {
  const { street, street2, city, state, zip } = data;
  return {
    address: {
      street,
      street2,
      city,
      state,
      zip,
    },
  };
};

export const isAddressComplete = ( address?: AddressInterface ): boolean => {
  return Boolean( !!address?.street && !!address?.city && !!address?.state && !!address.zip );
};

export const prettyPrintAddress = ( address: AddressInterface ): string => {
  const streets = [ address.street, address.street2 ].filter( line => !!line ).join( ' ' );
  const base = `${streets} ${address.city}, ${address.state} ${address.zip}`;
  return base;
};
