import useQuery from './useQuery';

/**
 * Given a page name (or null) find where that page name lives in the
 * given array of page names.
 * If the page name value is undefined then return the default 0th index
 * @param page
 * @param pageNames
 */
export function getCurrentIndex(
  pageName: string|null,
  pageNames: string[],
): number{
  return Math.max( 0, [ null, ...pageNames ]
    .findIndex( name => name === pageName ));
}

/**
 * the default/0th index in the flow should not be included in list of page names
 * @param pageNames
 * @param queryParamKey
 */
export default function useCurrentIndex(
  pageNames: string[],
  initialPage?: string,
  queryParamKey = 'page',
): number{
  const query = useQuery();
  const pageName = initialPage || query.get( queryParamKey );
  return getCurrentIndex( pageName, pageNames );
}
