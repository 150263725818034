import { TranslationKeys } from '../../store/initial/translations';
import isObjKey from '../../utils/isObjKey';


export interface RelationshipOption {
  value: string;
  translationKey: TranslationKeys;
}

export type RelationshipTypes = keyof typeof Relationship.TYPE

class Relationship {
  constructor(
    private readonly _relationship: string,
  ){}

  public is( category?: string ): boolean{
    if( !category ){
      return true;
    } else if( !!category && isObjKey( category, Relationship.TYPE )){
      return Relationship.TYPE[category].includes( this._relationship );
    }
    return false;
  }

  public get isHeir(): boolean{
    return Relationship.TYPE.HEIR.includes( this._relationship );
  }

  public get isHusbandOrWife(): boolean{
    return Relationship.TYPE.HUSBAND_WIFE.includes( this._relationship );
  }

  /**
   * TODO: app.hasSpouse userAttr does not mesh well with isSpouse
   * It can actually mean husband, wife, partner, etc.
   */
  public get isSpouse(): boolean{
    return Relationship.TYPE.SPOUSE.includes( this._relationship );
  }

  public get isBoo(): boolean{
    return Relationship.TYPE.SIGNIFICANT_OTHER.includes( this._relationship );
  }

  public get isChild(): boolean{
    return Relationship.TYPE.CHILDREN.includes( this._relationship );
  }

  public get isBioChild(): boolean{
    return Relationship.TYPE.BIOLOGICAL_CHILDREN.includes( this._relationship );
  }

  public get isStepChild(): boolean{
    return Relationship.TYPE.STEPCHILDREN.includes( this._relationship );
  }

  public get isFamily(): boolean{
    return Relationship.TYPE.IMMEDIATE_FAMILY.includes( this._relationship );
  }

  public get isEx(): boolean{
    return Relationship.TYPE.EX_SPOUSE.includes( this._relationship );
  }

  public get isMe(): boolean{
    return Relationship.NAMES.ME === this._relationship;
  }

  public static NAMES = {
    ME: 'ME',

    // SPOUSE
    HUSBAND: 'HUSBAND',
    WIFE: 'WIFE',
    DOMESTIC_PARTNER: 'DOMESTIC PARTNER',
    CIVIL_UNION_PARTNER: 'CIVIL UNION PARTNER',


    // EX-SPOUSE
    EX_HUSBAND: 'EX-HUSBAND',
    EX_WIFE: 'EX-WIFE',
    EX_PARTNER: 'EX-PARTNER',

    // SIGNIFICANT OTHERS
    GIRLFRIEND: 'GIRLFRIEND',
    BOYFRIEND: 'BOYFRIEND',
    FIANCE: 'FIANCE',
    CO_PARENT: 'CO-PARENT',

    // SIBLINGS
    BROTHER: 'BROTHER',
    SISTER: 'SISTER',
    STEPBROTHER: 'STEPBROTHER',
    STEPSISTER: 'STEPSISTER',
    HALF_BROTHER: 'HALF-BROTHER',
    HALF_SISTER: 'HALF-SISTER',

    // CHILDREN
    SON: 'SON',
    DAUGHTER: 'DAUGHTER',
    STEPSON: 'STEPSON',
    STEPDAUGHTER: 'STEPDAUGHTER',
    CHILD: 'CHILD',

    GODDAUGHTER: 'GODDAUGHTER',
    GODSON: 'GODSON',
    GODCHILD: 'GODCHILD',

    // PARENTS
    MOTHER: 'MOTHER',
    FATHER: 'FATHER',
    STEPFATHER: 'STEPFATHER',
    STEPMOTHER: 'STEPMOTHER',

    // RELATIVES
    GRANDMOTHER: 'GRANDMOTHER',
    GRANDFATHER: 'GRANDFATHER',
    GREAT_GRANDMOTHER: 'GREAT GRANDMOTHER',
    GREAT_GRANDFATHER: 'GREAT GRANDFATHER',
    AUNT: 'AUNT',
    UNCLE: 'UNCLE',
    NIECE: 'NIECE',
    NEPHEW: 'NEPHEW',
    COUSIN: 'COUSIN',
    GRANDSON: 'GRANDSON',
    GRANDDAUGHTER: 'GRANDDAUGHTER',
    GREAT_GRANDSON: ' GREAT GRANDSON',
    GREAT_GRANDDAUGHTER: 'GREAT GRANDDAUGHTER',

    // IN-LAWS
    BROTHER_IN_LAW: 'BROTHER-IN-LAW',
    SISTER_IN_LAW: 'SISTER-IN-LAW',
    SON_IN_LAW: 'SON-IN-LAW',
    DAUGHTER_IN_LAW: 'DAUGHTER-IN-LAW',
    MOTHER_IN_LAW: 'MOTHER-IN-LAW',
    FATHER_IN_LAW: 'FATHER-IN-LAW',

    // TRUSTED FRIENDS
    FRIEND: 'FRIEND',
    GODMOTHER: 'GODMOTHER',
    GODFATHER: 'GODFATHER',
    GODPARENT: 'GODPARENT',

    // TRUSTED ADVISORS
    ATTORNEY: 'ATTORNEY',

    ACCOUNTANT: 'ACCOUNTANT',

    FINANCIAL_ADVISOR: 'FINANCIAL ADVISOR',

    PHYSICIAN: 'PHYSICIAN',

    // ORGANIZATION
    ORGANIZATION: 'ORGANIZATION',

    // OTHER
    OTHER: 'OTHER',

    UNKNOWN: 'UNKNOWN',
  }

  public static TYPE = {
    HEIR: [
      Relationship.NAMES.HUSBAND, Relationship.NAMES.WIFE,
      Relationship.NAMES.DOMESTIC_PARTNER,
      Relationship.NAMES.CIVIL_UNION_PARTNER,
      Relationship.NAMES.SON, Relationship.NAMES.DAUGHTER, Relationship.NAMES.CHILD,
      Relationship.NAMES.STEPSON, Relationship.NAMES.STEPDAUGHTER,
    ],

    IMMEDIATE_FAMILY: [
      Relationship.NAMES.WIFE, Relationship.NAMES.HUSBAND,
      Relationship.NAMES.DOMESTIC_PARTNER,
      Relationship.NAMES.CIVIL_UNION_PARTNER,
      Relationship.NAMES.DAUGHTER, Relationship.NAMES.SON,Relationship.NAMES.CHILD,
      Relationship.NAMES.STEPDAUGHTER, Relationship.NAMES.STEPSON,
      Relationship.NAMES.MOTHER, Relationship.NAMES.FATHER,
      Relationship.NAMES.STEPMOTHER, Relationship.NAMES.STEPFATHER,
    ],

    HUSBAND_WIFE: [
      Relationship.NAMES.HUSBAND, Relationship.NAMES.WIFE,
    ],

    SPOUSE: [
      Relationship.NAMES.HUSBAND, Relationship.NAMES.WIFE,
      Relationship.NAMES.DOMESTIC_PARTNER,
      Relationship.NAMES.CIVIL_UNION_PARTNER,
    ],

    SIGNIFICANT_OTHER: [
      Relationship.NAMES.HUSBAND, Relationship.NAMES.WIFE,
      Relationship.NAMES.DOMESTIC_PARTNER,
      Relationship.NAMES.CIVIL_UNION_PARTNER,
      Relationship.NAMES.FIANCE,
      Relationship.NAMES.BOYFRIEND, Relationship.NAMES.GIRLFRIEND,
    ],

    EX_SPOUSE: [
      Relationship.NAMES.EX_HUSBAND, Relationship.NAMES.EX_WIFE, Relationship.NAMES.EX_PARTNER,
    ],

    BIOLOGICAL_CHILDREN: [
      Relationship.NAMES.SON, Relationship.NAMES.DAUGHTER, Relationship.NAMES.CHILD,
    ],

    STEPCHILDREN: [
      Relationship.NAMES.STEPSON, Relationship.NAMES.STEPDAUGHTER,
    ],
    GODCHILDREN: [
      Relationship.NAMES.GODCHILD, Relationship.NAMES.GODDAUGHTER, Relationship.NAMES.GODSON,
    ],

    CHILDREN: [
      Relationship.NAMES.SON, Relationship.NAMES.DAUGHTER, Relationship.NAMES.CHILD,
      Relationship.NAMES.STEPSON, Relationship.NAMES.STEPDAUGHTER,
      Relationship.NAMES.GODCHILD, Relationship.NAMES.GODDAUGHTER, Relationship.NAMES.GODSON,
    ],

    CHILDREN_IN_LAW: [
      Relationship.NAMES.DAUGHTER_IN_LAW, Relationship.NAMES.SON_IN_LAW,
    ],

    GRANDCHILDREN: [
      Relationship.NAMES.GRANDSON, Relationship.NAMES.GRANDDAUGHTER,
    ],

    GREAT_GRANDCHILDREN: [
      Relationship.NAMES.GREAT_GRANDSON, Relationship.NAMES.GREAT_GRANDDAUGHTER,
    ],

    SIBLINGS: [
      Relationship.NAMES.BROTHER, Relationship.NAMES.SISTER,
      Relationship.NAMES.STEPBROTHER, Relationship.NAMES.STEPSISTER,
      Relationship.NAMES.HALF_BROTHER, Relationship.NAMES.HALF_SISTER,
    ],

    SIBLINGS_IN_LAW: [
      Relationship.NAMES.SISTER_IN_LAW, Relationship.NAMES.BROTHER_IN_LAW,
    ],

    BIOLOGICAL_PARENTS: [
      Relationship.NAMES.MOTHER, Relationship.NAMES.FATHER,
    ],

    PARENTS: [
      Relationship.NAMES.MOTHER, Relationship.NAMES.FATHER,
      Relationship.NAMES.STEPFATHER, Relationship.NAMES.STEPMOTHER,
    ],

    PARENTS_IN_LAW: [
      Relationship.NAMES.MOTHER_IN_LAW, Relationship.NAMES.FATHER_IN_LAW,
    ],

    GRANDPARENTS: [
      Relationship.NAMES.GRANDMOTHER, Relationship.NAMES.GRANDFATHER,
    ],

    GREAT_GRANDPARENTS: [
      Relationship.NAMES.GREAT_GRANDMOTHER, Relationship.NAMES.GREAT_GRANDFATHER,
    ],

    RELATIVES: [
      Relationship.NAMES.GRANDMOTHER, Relationship.NAMES.GRANDFATHER,
      Relationship.NAMES.GREAT_GRANDMOTHER, Relationship.NAMES.GREAT_GRANDFATHER,
      Relationship.NAMES.AUNT, Relationship.NAMES.UNCLE,
      Relationship.NAMES.NIECE, Relationship.NAMES.NEPHEW,
      Relationship.NAMES.COUSIN,
      Relationship.NAMES.GRANDDAUGHTER, Relationship.NAMES.GRANDSON,
      Relationship.NAMES.GREAT_GRANDSON, Relationship.NAMES.GREAT_GRANDDAUGHTER,
    ],

    COUSINS: [
      Relationship.NAMES.COUSIN,
    ],

    FRIENDS: [
      Relationship.NAMES.FRIEND,
      Relationship.NAMES.GODMOTHER, Relationship.NAMES.GODFATHER,
    ],

    ADVISORS: [
      Relationship.NAMES.ACCOUNTANT,
      Relationship.NAMES.ATTORNEY,
      Relationship.NAMES.FINANCIAL_ADVISOR,
      Relationship.NAMES.PHYSICIAN,
    ],

    PHYSICIAN: [
      Relationship.NAMES.PHYSICIAN,
    ],

    FIANCES: [
      Relationship.NAMES.FIANCE,
    ],
    NOT_FAMILY: [
      Relationship.NAMES.FRIEND,
      Relationship.NAMES.ACCOUNTANT,
      Relationship.NAMES.ATTORNEY,
      Relationship.NAMES.FINANCIAL_ADVISOR,
      Relationship.NAMES.PHYSICIAN,
      Relationship.NAMES.ORGANIZATION,
      Relationship.NAMES.UNKNOWN,
    ],
  }

  static OPTIONS: Array<RelationshipOption> = [
    { value: 'ME', translationKey: 'relationship.me.displayname' },
    { value: 'WIFE', translationKey: 'relationship.wife.displayname' },
    { value: 'HUSBAND', translationKey: 'relationship.husband.displayname' },
    { value: 'DOMESTIC PARTNER', translationKey: 'relationship.domesticpartner.displayname' },
    { value: 'CIVIL UNION PARTNER', translationKey: 'relationship.civilunion.displayname' },
    { value: 'DAUGHTER', translationKey: 'relationship.daughter.displayname' },
    { value: 'STEPDAUGHTER', translationKey: 'relationship.stepdaughter.displayname' },
    { value: 'DAUGHTER-IN-LAW', translationKey: 'relationship.daughterinlaw.displayname' },
    { value: 'SON', translationKey: 'relationship.son.displayname' },
    { value: 'STEPSON', translationKey: 'relationship.stepson.displayname' },
    { value: 'SON-IN-LAW', translationKey: 'relationship.soninlaw.displayname' },
    { value: 'MOTHER', translationKey: 'relationship.mom.displayname' },
    { value: 'FATHER', translationKey: 'relationship.dad.displayname' },
    { value: 'STEPMOTHER', translationKey: 'relationship.stepmom.displayname' },
    { value: 'STEPFATHER', translationKey: 'relationship.stepdad.displayname' },
    { value: 'MOTHER-IN-LAW', translationKey: 'relationship.motherinlaw.displayname' },
    { value: 'FATHER-IN-LAW', translationKey: 'relationship.fatherinlaw.displayname' },
    { value: 'SISTER', translationKey: 'relationship.sister.displayname' },
    { value: 'BROTHER', translationKey: 'relationship.brother.displayname' },
    { value: 'STEPSISTER', translationKey: 'relationship.stepsister.displayname' },
    { value: 'STEPBROTHER', translationKey: 'relationship.stepbrother.displayname' },
    { value: 'HALF-SISTER', translationKey: 'relationship.halfsister.displayname' },
    { value: 'HALF-BROTHER', translationKey: 'relationship.halfbrother.displayname' },
    { value: 'SISTER-IN-LAW', translationKey: 'relationship.sisterinlaw.displayname' },
    { value: 'BROTHER-IN-LAW', translationKey: 'relationship.brotherinlaw.displayname' },
    { value: 'GRANDMOTHER', translationKey: 'relationship.grandmother.displayname' },
    { value: 'GRANDFATHER', translationKey: 'relationship.grandfather.displayname' },
    { value: 'AUNT', translationKey: 'relationship.aunt.displayname' },
    { value: 'UNCLE', translationKey: 'relationship.uncle.displayname' },
    { value: 'NIECE', translationKey: 'relationship.niece.displayname' },
    { value: 'NEPHEW', translationKey: 'relationship.nephew.displayname' },
    { value: 'COUSIN', translationKey: 'relationship.cousin.displayname' },
    { value: 'GRANDDAUGHTER', translationKey: 'relationship.granddaughter.displayname' },
    { value: 'GRANDSON', translationKey: 'relationship.grandson.displayname' },
    { value: 'FRIEND', translationKey: 'relationship.friend.displayname' },
    { value: 'GIRLFRIEND', translationKey: 'relationship.girlfriend.displayname' },
    { value: 'BOYFRIEND', translationKey: 'relationship.boyfriend.displayname' },
    { value: 'FIANCE', translationKey: 'relationship.fiance' },
    { value: 'ORGANIZATION', translationKey: 'relationship.organization.displayname' },
    { value: 'ATTORNEY', translationKey: 'relationship.attorney.displayname' },
    { value: 'ACCOUNTANT', translationKey: 'relationship.accountant.displayname' },
    { value: 'FINANCIAL ADVISOR', translationKey: 'relationship.financialadvisor.displayname' },
    { value: 'EX-HUSBAND', translationKey: 'relationship.ex-husband' },
    { value: 'EX-WIFE', translationKey: 'relationship.ex-wife' },
    { value: 'EX-PARTNER', translationKey: 'relationship.ex-partner' },
    { value: 'PHYSICIAN', translationKey: 'relationship.physician.displayname' },
  ]
}

// type RelationshipEnum = ( typeof Relationship.OPTIONS )[number]['value'];





export default Relationship;
