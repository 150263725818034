import { ReactElement } from 'react';
import FieldsetLegend, { IFieldsetLegend } from './FieldsetLegend';

interface Props {
  children: ReactElement;
  className?: string;
  disabled?: boolean;
  title?: IFieldsetLegend['children'];
  titleClassName?: IFieldsetLegend['className'];
}

export default function Fieldset({ children, className = '', disabled, title, titleClassName = '' }: Props ): ReactElement{
  return (
    <fieldset
      className={ `mb-12 ${className}` }
      disabled={ disabled }
    >
      {title && <FieldsetLegend className={ `mb-4 ${titleClassName}` }>{title}</FieldsetLegend>}
      {children}
    </fieldset>
  );
}
