import React, { ReactElement } from 'react';

import { ReactComponent as addGuardian } from '../assets/icons/addGuardian.svg';
import { ReactComponent as addPerson } from '../assets/icons/addPerson.svg';
import { ReactComponent as alertCircle } from '../assets/icons/alert-circle.svg';
import { ReactComponent as arrowLeft } from '../assets/icons/arrow-left.svg';
import { ReactComponent as badge } from '../assets/icons/badge.svg';
import { ReactComponent as checkCircle } from '../assets/icons/check-circle.svg';
import { ReactComponent as check } from '../assets/icons/check.svg';
import { ReactComponent as chevron } from '../assets/icons/chevron.svg';
import { ReactComponent as circle } from '../assets/icons/circle.svg';
import { ReactComponent as close } from '../assets/icons/close.svg';
import { ReactComponent as closedCaptionOff } from '../assets/icons/closedCaption-off.svg';
import { ReactComponent as closedCaptionOn } from '../assets/icons/closedCaption-on.svg';
import { ReactComponent as cog } from '../assets/icons/cog.svg';
import { ReactComponent as guaranteeCheck } from '../assets/icons/guarantee-check.svg';
import { ReactComponent as info } from '../assets/icons/info.svg';
import { ReactComponent as logoMark } from '../assets/icons/logoMark.svg';
import { ReactComponent as manageAccounts } from '../assets/icons/manageAccounts.svg';
import { ReactComponent as minus } from '../assets/icons/minus.svg';
import { ReactComponent as pdf } from '../assets/icons/pdf.svg';
import { ReactComponent as pause } from '../assets/icons/pause.svg';
import { ReactComponent as paw } from '../assets/icons/paw.svg';
import { ReactComponent as phoneInHand } from '../assets/icons/phoneInHand.svg';
import { ReactComponent as playCircle } from '../assets/icons/play-circle.svg';
import { ReactComponent as play } from '../assets/icons/play.svg';
import { ReactComponent as plus } from '../assets/icons/plus.svg';
import { ReactComponent as prohibitedDocument } from '../assets/icons/prohibited-document.svg';
import { ReactComponent as documentOutline } from '../assets/icons/documentOutline.svg';
import { ReactComponent as property } from '../assets/icons/property.svg';
import { ReactComponent as rotateRight } from '../assets/icons/rotate-right.svg';
import { ReactComponent as volumeOff } from '../assets/icons/volume-off.svg';
import { ReactComponent as volumeUp } from '../assets/icons/volume-up.svg';
import { ReactComponent as xCircle } from '../assets/icons/x-circle.svg';
import { ReactComponent as sparkle } from '../assets/icons/sparkle.svg';
import { ReactComponent as ContactCard }  from '../assets/icons/ContactCard.svg';
import { ReactComponent as PaperPlane_fill }  from '../assets/icons/PaperPlane_fill.svg';
import { ReactComponent as PaperPlane_stroke }  from '../assets/icons/PaperPlane_stroke.svg';
import { ReactComponent as woodmark }  from '../assets/icons/woodmark.svg';

export type Icons =
'addGuardian'         |
'addPerson'           |
'alert-circle'        |
'arrow-left'          |
'badge'               |
'check-circle'        |
'check'               |
'chevron'             |
'circle'              |
'close'               |
'closedCaption-off'   |
'closedCaption-on'    |
'cog'                 |
'documentOutline'     |
'guarantee-check'     |
'info'                |
'logoMark'            |
'manageAccounts'      |
'minus'               |
'pause'               |
'paw'                 |
'phoneInHand'         |
'play-circle'         |
'play'                |
'prohibited-document' |
'property'            |
'rotate-right'        |
'sparkle'             |
'volume-off'          |
'volume-up'           |
'pdf'                 |
'plus'                |
'x-circle'            |
'contact-card'        |
'paper-plane-fill'    |
'paper-plane-stroke'  |
'woodmark';

type IconComponent  = React.FC<React.SVGProps<SVGSVGElement>> | undefined
type IconComponents = { [K in Icons]?: IconComponent }

const icons: IconComponents = {
  addGuardian,
  addPerson,
  'alert-circle': alertCircle,
  'arrow-left': arrowLeft,
  badge,
  'check-circle': checkCircle,
  check,
  chevron,
  circle,
  close,
  'closedCaption-off': closedCaptionOff,
  'closedCaption-on': closedCaptionOn,
  cog,
  documentOutline,
  'guarantee-check': guaranteeCheck,
  info,
  logoMark,
  manageAccounts,
  minus,
  pdf,
  paw,
  pause,
  phoneInHand,
  'play-circle': playCircle,
  play,
  plus,
  'prohibited-document': prohibitedDocument,
  property,
  'rotate-right': rotateRight,
  'sparkle': sparkle,
  'volume-off': volumeOff,
  'volume-up': volumeUp,
  'x-circle': xCircle,
  'contact-card': ContactCard,
  'paper-plane-fill': PaperPlane_fill,
  'paper-plane-stroke': PaperPlane_stroke,
  woodmark,
};

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: keyof IconComponents;
  className?: string;
}

const Icon = ( props: IconProps ): ReactElement => {
  const CurrentIcon = icons[props.name];
  if( CurrentIcon !== undefined ){
    return <CurrentIcon { ...props }/>;
  } else {
    return <>Undefined icon name: {props.name}</>;
  }
};

export default Icon;
