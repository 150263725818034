import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

// Components
import CurrencyField from '../../../components/CurrencyField';
import HorizontalRule from '../../../components/HorizontalRule';
import PropertyOwnershipSelector from '../PropertyOwnershipSelector';
import TextField from '../../../components/TextField';
import Translation from '../../../components/Translation';
import AddressFields from '../../../components/AddressFields';

// Redux
import { useSelector } from '../../../store';

// Types
import { PropertyDetailFormInterface } from '../../../types/Property/PropertyDetailFormInterface';

// Hooks
import useTranslations from '../../../hooks/useTranslations';

// Validators
import { fieldRequired } from '../../../formValidators/fieldRequired';
import { zipMinLength } from '../../../formValidators';



export default function RealEstatePropertyForm({
  control,
  errors,
  getValues,
  register,
  setValue,
}: PropertyDetailFormInterface ): ReactElement{
  const coOwnerOptions = useSelector( state => state.people.data ).filter( person => person.relationship !== 'ME' );

  const { getTranslation } = useTranslations();

  const useFormProps = {
    register,
    setValue,
    errors,
    getValues,
  };

  const requiredAddressFields={
    street: {
      required: fieldRequired( getTranslation( 'address.error.missingstreet' )),
    },
    city: {
      required: fieldRequired( getTranslation( 'address.error.missingcity' )),
    },
    state: {
      required: fieldRequired( getTranslation( 'address.error.missingstate' )),
    },
    zip: {
      minLength: zipMinLength( getTranslation( 'error.missing.zip' )),
      required: fieldRequired( getTranslation( 'error.missing.zip' )),
    } };

  return (
    <>
      <h2 className="mb-6 capitalize">
        <Translation translationKey="form.realEstate.section.location.title" />
      </h2>

      <AddressFields
        { ...useFormProps }
        required={ requiredAddressFields }
      />

      <HorizontalRule className="mt-8 border-black-70!"/>

      <h2 className="mb-6 capitalize">
        <Translation translationKey="form.realEstate.section.finances.title" />
      </h2>

      <div>
        <CurrencyField
          errors={ errors }
          label="Estimated value"
          name="value"
          control={ control }
        />
        <CurrencyField
          errors={ errors }
          label="Estimated mortgage balance"
          name="balance"
          control={ control }
        />
        <TextField { ...useFormProps }
          name="institution_name"
          label="Name of lender"
        />
      </div>

      <HorizontalRule className="mt-8 border-black-70!"/>

      <h2 className="mb-6">
        <Translation translationKey="screen.valuable.create.label.ownership"/>
      </h2>

      <Controller
        control={ control }
        name="owners"
        render={ ({ onChange,value }) => (
          <PropertyOwnershipSelector
            onChange={ onChange }
            value={ value }
            label="Co-Owner"
            className="mb-8"
            people={ coOwnerOptions }
          />
        ) }
      />

      <Controller
        control={ control }
        name="beneficiaries"
        render={ ({ onChange, value }) => (
          <PropertyOwnershipSelector
            onChange={ onChange }
            value={ value }
            label="Beneficiary"
            className="mb-8"
            people={ coOwnerOptions }
          />
        ) }
      />

    </>
  );
}
