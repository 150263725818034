import { FC, cloneElement } from 'react';
import { useSelector } from '../store';

// Modals
import ShareActionSheet from './ShareActionSheet';
import LenoxAdvisor from './LenoxAdvisor';
import HelpModal from './HelpModal';
import InviteModal from './InviteModal';
import ChildrenHeirsUpsellModal from '../views/modals/ChildrenHeirsLifeUpsellModal';
import GlobalContextualModal from './GlobalContextualModal';
import GlobalVideoModal from '../views/modals/GlobalVideoModal';
import EditCaretakerActionSheet from './EditCaretakerActionSheet';

const ModalController: FC = () => {
  const modalState = useSelector( state => state.modal.data );

  const globalModals = [
    // add modal here...
    { state: modalState.childrenHeirsLifeUpsell, component: <ChildrenHeirsUpsellModal { ...modalState.childrenHeirsLifeUpsell.modalProps }/> },
    { state: modalState.contextualModal, component: <GlobalContextualModal { ...modalState.contextualModal.modalProps } /> },
    { state: modalState.inviteModal, component: <InviteModal { ...modalState.inviteModal.modalProps } /> },
    { state: modalState.lenoxAdvisor, component: <LenoxAdvisor /> },
    { state: modalState.help, component: <HelpModal /> },
    { state: modalState.shareActionSheet, component: <ShareActionSheet { ...modalState.shareActionSheet.modalProps } /> },
    { state: modalState.videoModal, component: <GlobalVideoModal { ...modalState.videoModal.modalProps }/> },
    { state: modalState.editCaretakerActionSheet, component: <EditCaretakerActionSheet{ ...modalState.editCaretakerActionSheet.modalProps } /> },
  ];
  return(
    <>
      {/**
       * 'isRendered' will append the modal to the DOM
       * 'isOpen' will handle whether or not to display it, this allows for
       * Modal transitions to happen as we expect
       * Note: this isn't a final solution, maybe `isOpen` should be a modalProp? maybe onClose should be one too?
       */}
      {globalModals.map(( modal, idx ) => {
        return (
          modal.state.isRendered && cloneElement( modal.component, { key: `modal-${idx}` })
        );
      })}
    </>
  );
};



export default ModalController;
