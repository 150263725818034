// Components
import ActionContainer from '../../../../components/ActionContainer';
import HorizontalRule from '../../../../components/HorizontalRule';
import PersonSelectedContainer from '../../../../components/PersonSelectedContainer';

// Redux
import { deleteRole } from '../../../../store/reducers/rolesReducer';
import { useDispatch } from '../../../../store';
import { makeSnack } from '../../../../store/reducers/snackbarReducer';
import { showContextualModal } from '../../../../store/reducers/modalReducer';

// Utils
import { toTitleCase } from '../../../../../core/utils/stringHelpers';
import stringifyNumber from '../../../../utils/stringifyNumber';

// Models
import { PersonInterface } from '../../../../types/PersonInterface';
import RoleInterface from '../../../../types/RoleInterface';

// Icons
import useTranslations from '../../../../hooks/useTranslations';


interface Props {
  className?: string;
  attorneyInFact: PersonInterface;
  idx: number;
  role?: RoleInterface;
}

/**
 * return 'primary', 'backup', 'second backup', 'third backup'... etc
 * @param idx
 */
const getTier = ( idx: number ): string => {
  const tiers = [ 'primary', 'backup' ];
  return tiers[idx] || `${stringifyNumber( idx + 1 )} backup`;
};

const AttorneyInFact = ({
  className='',
  attorneyInFact,
  idx,
  role,
}: Props ): JSX.Element => {

  const { getTranslation } = useTranslations();

  const dispatch = useDispatch();

  const header = toTitleCase( `${getTier( idx )} Attorney In Fact` );

  const handleAttorneyInFactRemoved = () => {
    const dynamicStringVariables = [ attorneyInFact.name || '', header ];
    const confirmRemoveAttorney =  getTranslation( 'banner.role.general.removed.confirm', dynamicStringVariables );
    const attorneyRemovedSuccessMessage = getTranslation( 'banner.role.general.removed.success', dynamicStringVariables );
    if( !role ) {
      return;
    }
    dispatch( showContextualModal({
      description: confirmRemoveAttorney,
      onConfirm: () => {
        dispatch( deleteRole({ id: role.id, onSuccess: () => {
          dispatch( makeSnack({ message: attorneyRemovedSuccessMessage }));
        } }));
      },
      dismissLabel: getTranslation( '(button)cancel' ),
      confirmLabel: getTranslation( '(button)remove' ),
    }));
  };

  const getLabel = (): string => {
    return getTranslation( 'role.name.attorneyinfact.displayname', [ getTier( idx ) ]);
  };

  return (
    <>
      <ActionContainer
        className={ className }
        header={ header }
        headerClassName="font-bold"
        primaryActionLabel={ getTranslation( '(button)remove' ) }
        primaryAction={ handleAttorneyInFactRemoved }
        primaryActionTheme={ !!attorneyInFact ? 'clean-danger' : 'clean' }
      >
        <PersonSelectedContainer
          person={ attorneyInFact }
          role={ getLabel() }
          translationKey={ 'personSelector.attorneyInFact.label' }
        />
      </ActionContainer>
      <HorizontalRule/>
    </>
  );
};

export default AttorneyInFact;
