import { useCallback, useEffect, useRef } from 'react';
import { parseTranslation } from '../../core/utils/parseTranslation';
import WebClientRequest from '../core-data-service/WebClientRequest';
import { useDispatch, useSelector } from '../store';
import { TranslationKeys } from '../store/initial/translations';
import { logImpression } from '../store/reducers/experimentsReducer';

export type GetTranslationType = ( translationKey: TranslationKeys, dynamicStringValues?: string[])=> string

function useTranslations(
  translationKey?: TranslationKeys | undefined,
  dynamicStringValues: string[] = [],
): {
  translation?: string;
  getTranslation: GetTranslationType;
}{
  const dispatch = useDispatch();
  const accessToken = useSelector( state => state.auth.access_token );
  const allTranslations = useSelector( state => state.translations.data );
  const language = useSelector( state => state.settings.language );
  const translations = allTranslations[language];
  const tests = useSelector( state => state.translations.meta.tests );
  const translation = !!translationKey
    ? parseTranslation( translations[translationKey], dynamicStringValues )
    : undefined;
  const impressionsReported = useSelector( state => state.experiments.meta.impressionsReported );
  const variantId = useRef( !!translationKey ? tests[translationKey] : undefined );
  const hasAlreadyReportedImpression = useRef( !!variantId.current ? impressionsReported.includes( variantId.current ) : false );

  const impressionLogger = useCallback(
    ()=> {
      hasAlreadyReportedImpression.current = !!variantId.current ? impressionsReported.includes( variantId.current ) : false;
      if( !!variantId.current && !hasAlreadyReportedImpression.current ){
      // report
        const url = '/v1/impressions';
        const data = { variant_id: variantId.current };
        WebClientRequest
          .post( url, data )
          .then( _ => dispatch( logImpression({ variantId: variantId.current })));
      }
    },
    [ impressionsReported, dispatch ]);
  /**
   * Report impression if not already reported
   */
  useEffect(() => {
    impressionLogger();
  }, [
    accessToken,
    dispatch,
    hasAlreadyReportedImpression,
    translationKey,
    variantId,
    tests,
    impressionLogger,
  ]);
  const getTranslation = ( key: TranslationKeys, _dynamicStringValues: string[] = dynamicStringValues ): string=>{
    translationKey = key;
    variantId.current = !!translationKey ? tests[translationKey] : undefined;
    impressionLogger();
    return !!translationKey
      ? parseTranslation( translations[translationKey], _dynamicStringValues )
      : translations[translationKey];
  };
  return {
    translation,
    getTranslation,
  };
}

export default useTranslations;
