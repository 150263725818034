import PersonInterface from '../../types/PersonInterface';
import PropertyInterface from '../../types/Property/PropertyInterface';

export const getPropertiesUniqueBeneficiaries = ( properties: PropertyInterface[]): PersonInterface[] =>  {
  if( !properties || !properties.length ) {
    return [];
  }
  const result: PersonInterface[] = [];
  const ids = new Set<PersonInterface['id']>();
  properties.forEach( property => {
    if( property.beneficiaries.length ) {
      property.beneficiaries.forEach( beneficiary => {
        if( !ids.has( beneficiary.id )) {
          result.push( beneficiary );
        }
        ids.add( beneficiary.id );
      });
    }
  });
  return result;
};
