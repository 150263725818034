import { RootState } from '..';
import Person from '../../core-data-service/models/Person';
import PersonInterface from '../../types/PersonInterface';

const minorChildrenSelector = ( state: RootState ): Array<PersonInterface> => {
  return state.people.data
    .filter( _person => {
      const person = new Person( _person );
      return Boolean(
        person.isChild &&
      Boolean( !person.isAdult || person.age === null ),
      );
    });
};
export default minorChildrenSelector;
