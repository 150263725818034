import { ReactElement } from 'react';
import ActionContainer from '../../components/ActionContainer';
import PersonIcon from './PersonIcon';
import PersonInterface from '../../types/PersonInterface';
import Relationship, { RelationshipOption } from '../../core-data-service/models/Relationship';
import Person from '../../core-data-service/models/Person';
import useTranslations from '../../hooks/useTranslations';
import { ButtonTheme } from '../../components/Button';

interface Props {
  className?: string;
  person: PersonInterface;
  primaryActionLabel?: string;
  primaryActionShouldFillContainer?: boolean;
  primaryAction?: ()=> void;
  primaryActionTheme?: ButtonTheme;
  needsData?: boolean;
}

export default function PeopleListItem({
  person,
  primaryAction,
  primaryActionLabel,
  primaryActionShouldFillContainer,
  primaryActionTheme,
  className = '',
  needsData = false,
}: Props ): ReactElement{
  const _person = new Person( person );

  // @TODO: gets relationship display name function / wait for data
  const relationshipOptions = Relationship.OPTIONS.find( entry => entry.value === person.relationship ) as RelationshipOption;
  const { translation } = useTranslations( relationshipOptions?.translationKey? relationshipOptions.translationKey: undefined );
  const relationshipDisplayName = translation? translation: person.relationship.toLowerCase();
  if( !relationshipOptions && !relationshipDisplayName ) {
    return <></>;
  }

  return (
    <ActionContainer
      className={ `${className} relative group p-4 rounded bg-moss` }
      key={ person.id }
      primaryAction={ primaryAction }
      primaryActionLabel={ needsData?'Resolve':primaryActionLabel }
      primaryActionTheme={ needsData?'clean-danger':'clean' }
      primaryActionShouldFillContainer={ primaryActionShouldFillContainer }
      header={
        <div className="flex-1 flex flex-row">
          <div className="w-16">
            <PersonIcon
              className="aspect-w-1 aspect-h-1"
              photo_url={ person.photo_url }
              personName={ person.name }
              onImageLoadDuration={ 1 }
              alt="avatar"
            />
          </div>
          <div className="ml-4 flex-1 flex flex-col justify-center">
            <div className="self-stretch flex justify-between">
              <div>
                <span className="whitespace-nowrap text-black font-bold">
                  { _person.firstName }
                </span>
                <span className="whitespace-nowrap capitalize mt-2 block">
                  { relationshipDisplayName }{_person.isChild && _person.isAdult && ', Over 18'}
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {primaryActionShouldFillContainer &&
        <div className={ `absolute z-10 inset-0 pointer-events-none transition-all rounded  ${needsData ? 'bg-red-error opacity-25 group-hover:opacity-50' : 'group-hover:bg-black-5'}` }/>
      }
    </ActionContainer>
  );
}
