import { ReactElement, useEffect } from 'react';
import { Card } from './Card';
import analytics from '../utils/analytics';
import Translation from './Translation';
import appConfig from '../../core/appConfig';
import { LOCAL_STORAGE_UTM_ATTRIBUTION } from '../../core/constants';
import useTranslations from '../hooks/useTranslations';
import { EXTERNAL_ID_PARAM, getAnonymousID } from '../utils/anonymousID';

export default function DashboardInsuranceCard(): ReactElement{

  const handleClick = () => {
    const ethosUrl = new URL( appConfig.ethos.baseUrl );
    ethosUrl.searchParams.set( EXTERNAL_ID_PARAM, getAnonymousID());

    // persist initial UTM params for Ethos
    const utmAttribution = window.localStorage.getItem( LOCAL_STORAGE_UTM_ATTRIBUTION );
    if( utmAttribution ) {
      try {
        const initialUtmParams = new URLSearchParams( atob( utmAttribution ));

        initialUtmParams.forEach(( value, key ) => {
          ethosUrl.searchParams.append( key, value );
        });
      } catch ( e ) {
        // do nothing
      }
    }

    const destination = ethosUrl.toString();
    analytics.track( 'Touch - Upsell Button', {
      button: 'dashboardInsuranceCard',
      destination,
    });
    window.location.assign( destination );
  };

  useEffect(() => {
    analytics.track( 'View - Upsell Button', { button: 'dashboardInsuranceCard' });
  }, []);

  const { getTranslation } = useTranslations();

  return ( <Card
    className="p-4 md:p-6 bg-white rounded dashboardInsuranceCard overflow-hidden flex-col"
    title={ <Translation translationKey="dashboard.ethos.helper.title" /> }
    primaryActionLabel={ <Translation translationKey="dashboard.ethos.helper.cta"/> }
    primaryAction={ handleClick }
    primaryActionTheme="primary"
    primaryActionContainerClassName="w-full"
    mediaComponent={ <img src={ getTranslation( 'dashboard.ethos.helper.image' ) } alt="a family that needs insurance" width="160px" height="110px"/> }
    mediaClasses="mx-auto mb-5"
  >
    <p className="absolute top-5 -right-20 min-w-[250px] bg-salamander--light p-4 text-center text-salamander font-bold uppercase transform rotate-45">
      <Translation translationKey="dashboard.ethos.helper.sash"/>
    </p>
    <p><Translation translationKey="dashboard.ethos.helper.description" /></p>
  </Card> );
}
