import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import DefaultHome from './default';
import SpouseHome from './spouse';

// Hooks
import useTranslations from '../../hooks/useTranslations';
import useQuery from '../../hooks/useQuery';

// Routes
import { dashboard as dashboardRoute } from '../../../core/routes';

// Utils
import analytics from '../../utils/analytics';
import {
  SEARCH_PARAM_PARTNER_CODE,
  SPOUSE_PARTNER_CODE,
} from '../../../core/constants';

export default function Home(): ReactElement{
  const { push } = useHistory();
  const { getTranslation } = useTranslations();

  // Check for query string parameter partner code
  const partnerCode = useQuery().get( SEARCH_PARAM_PARTNER_CODE );
  const hasSpousePartnerCode = partnerCode === SPOUSE_PARTNER_CODE;

  const viewName = hasSpousePartnerCode
    ? 'Spouse Login Screen'
    : 'Login Screen';

  const buttonCopy = hasSpousePartnerCode
    ? getTranslation( 'spouse.home.button' )
    : 'get started';

  const primaryAction = () => {
    analytics.track( `Touch - ${viewName}`, buttonCopy );
    push({
      pathname: dashboardRoute.get(),
    });
  };

  useEffect(() => {
    analytics.track( `View - ${viewName}` );
  }, []);

  if ( hasSpousePartnerCode ) {
    return <SpouseHome primaryAction={ primaryAction } />;
  }

  return <DefaultHome primaryAction={ primaryAction } />;
}
