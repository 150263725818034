import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Utils
import { useDispatch, useSelector } from '../../store';
import { splitFirstAndLastName } from '../../../core/utils/stringHelpers';
import analytics from '../../utils/analytics';
import { fieldRequired } from '../../formValidators';

// Actions
import { patchPerson } from '../../store/reducers/peopleReducer';

// Hooks
import useFlow from '../../hooks/useFlow';
import useTranslations from '../../hooks/useTranslations';

// Components
import Button from '../../components/Button';
import Flow from '../../components/Flow';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import Translation from '../../components/Translation';

// Redux
import userPersonSelector from '../../store/selectors/userPersonSelector';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';


export interface PromptForNameProps {
  onCancel: ()=> void;
  onSuccess: ()=> void;
  placeholder?: string;
}


export interface PromptForNameFormData extends SanitizedFormData {
  firstName: string;
  lastName: string;
}


const PromptForName: FC<PromptForNameProps> = ({
  onCancel,
  onSuccess,
}) => {

  const { currentIndex } = useFlow();

  // Application state
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const userPerson = useSelector( userPersonSelector );
  const isPending = useSelector( state => state.people?.patchPerson?.meta?.requestStatus === 'pending' );

  // Split saved name on first empty space
  const usersNames = splitFirstAndLastName( userPerson?.name );

  // Form hooks
  const {
    formState,
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
  } = useForm<PromptForNameFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      firstName: usersNames[0],
      lastName: usersNames[1],
    },
  });

  const { errors } = formState;
  const useFormProps = { register, setValue, getValues, errors };

  /**
   * Form submit handler
   */
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    if ( !!data.firstName && !!data.lastName && userPerson ) {
      // combine first and last name
      const name = `${data.firstName.trim()} ${data.lastName.trim()}`;

      dispatch( patchPerson({ id: userPerson.id, name, onSuccess }));
    } else {
      trigger();
    }
  };

  useEffect(() => {
    analytics.track( 'View - Registration Name' );
  }, []);

  return (
    <Form
      onSubmit={ onSubmit }
      className="flex flex-col flex-1"
      handleSubmit={ handleSubmit }
    >
      <Flow currentIndex={ currentIndex }>
        <>
          <h1 className="text-3xl mb-6 text-black font-headline">{ <Translation translationKey={ 'account.signup.name.title' } /> }</h1>
          <TextField { ...useFormProps }
            large
            label={ <Translation translationKey={ 'screen.onboarding.name.first' } /> }
            minLength={ 1 }
            name="firstName"
            required={ fieldRequired( getTranslation( 'screen.onboarding.name.error.missing.first' )) }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={ true }
            className="mb-4"
          />
          <TextField { ...useFormProps }
            large
            label={ <Translation translationKey={ 'screen.onboarding.name.last' } /> }
            minLength={ 1 }
            name="lastName"
            required={ fieldRequired( getTranslation( 'screen.onboarding.name.error.missing.last' )) }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            className="mb-12"
          />
          <div className="flex justify-end">
            <Button type="submit" isPending={ isPending } id="onboarding-continue-button"
            >Continue</Button>
          </div>
        </>
      </Flow>
    </Form>
  );
};

export default PromptForName;
