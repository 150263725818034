import { FC, ReactElement, ReactNode } from 'react';

import ActionContainer from './ActionContainer';
import Icon from './Icon';

// interface
import Translation from './Translation';
import analytics from '../utils/analytics';

export const SectionStates = {
  complete: 'complete',
  default: 'default',
  error: 'error',
  warning: 'warning',
  review: 'review',
} as const;

export type DocumentSectionStates = keyof typeof SectionStates
export interface DocumentSectionOverviewProps  {
  title: string | ReactNode;
  primaryActionId?: string;
  state?: DocumentSectionStates;
  isGated?: boolean;
  isNotManagedInThisDocument?: boolean;
  className?: string;
  beginAction: ()=> void;
  detailsAction?: ()=> void;
}

export const  DocumentSectionOverview: FC<DocumentSectionOverviewProps > = ({
  state = SectionStates.default,
  isGated = false,
  isNotManagedInThisDocument = false,
  title,
  beginAction,
  detailsAction,
  className = '',
  children,
  primaryActionId,
}) => {
  const isNotDefaultState = state !== SectionStates.default;
  const isInNeedOfReview = state === SectionStates.review;

  return (
    <ActionContainer
      primaryActionId={ primaryActionId }
      className={ `mb-8 pb-8 border-b border-grey flex flex-col gap-8 ${className}` }
      header={
        <div className="flex flex-1 justify-between items-center">
          {/* Status Icon */}
          <div className="flex items-center">
            <DocumentSectionOverviewIcon state={ state } isIconMuted={ isNotManagedInThisDocument } />
            <span className="font-bold">{title}</span>
            { isInNeedOfReview &&
              <span className="text-salamander bg-buttercup rounded-sm p-1 ml-2">
                <Translation translationKey="screen.willstatus.chapter.label.needsreview" />
              </span>
            }
          </div>
        </div>
      }
      primaryAction={
        isGated
          ? undefined
          :  () => {
            analytics.track( `Touch - ${title}` );
            if ( isNotDefaultState && detailsAction ) {
              detailsAction();
            } else {
              beginAction();
            }
          } }
      primaryActionLabel={ getPrimaryActionLabel( state ) }
    >

      {children}
    </ActionContainer>
  );
};

export default DocumentSectionOverview;

interface DocumentSectionOverviewStateProps {
  state: DocumentSectionOverviewProps['state'];
  isIconMuted: DocumentSectionOverviewProps['isNotManagedInThisDocument'];
}

const DocumentSectionOverviewIcon = ({ state, isIconMuted }: DocumentSectionOverviewStateProps ) => {

  const getIconName = ( state: DocumentSectionOverviewStateProps['state']) => {
    switch ( state ) {
    case 'complete':
      return 'check-circle';
    case 'warning':
      return 'alert-circle';
    case 'error':
      return 'alert-circle';
    case 'review':
      return 'alert-circle';
    default:
      return 'check-circle';
    }
  };

  const getIconStyles = ( state: DocumentSectionOverviewProps['state']): string => {
    switch ( state ) {
    case 'complete':
      return 'text-forest';
    case 'warning':
      return 'text-yellow';
    case 'review':
      return 'text-yellow';
    case 'error':
      return 'text-salamander';
    default:
      return '';
    }
  };

  const iconName = getIconName( state );
  const iconStyles = getIconStyles( state );

  return (
    <div className="mr-4 h-8 w-8 relative">
      {state === 'default' ?
        <div
          className="absolute
            inset-0
            rounded-full
            border
            border-grey"
        />
        :
        <Icon
          name={ iconName }
          className={ `${iconStyles} ${isIconMuted ? 'opacity-50' : ''}` }
        />
      }
    </div>
  );
};


const getPrimaryActionLabel = ( state: DocumentSectionOverviewProps['state']): ReactElement =>  {
  switch ( state ) {
  case 'complete':
    return <Translation translationKey="screen.willstatus.chapter.button.details"/>;
  case 'warning':
  case 'error':
    return <Translation translationKey="screen.willstatus.chapter.button.resolve"/>;
  case 'review':
    return <Translation translationKey="screen.willstatus.chapter.button.review"/>;
  default:
    return <Translation translationKey="screen.willstatus.chapter.button.begin"/>;
  }
};

