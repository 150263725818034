import useUserAttributes from './useUserAttributes';
import { DocumentSectionState } from './useWillSectionStatus';
import { SectionStates } from '../components/DocumentSectionOverview';
import { TranslationKeys } from '../store/initial/translations';
import useAgents from './useAgents';
import { useSelector } from '../store';
import { canFormBeSubmit } from '../utils/formUtils';
import { directivesSelector } from '../store/selectors/directivesSelector';

export const DirectiveSections = {
  address: 'address',
  agent: 'agent',
  wishes: 'wishes',
  review: 'review',
} as const;

type DirectiveSection = keyof typeof DirectiveSections;

export const DirectiveSectionTitleKey = ( key: DirectiveSection ): TranslationKeys => {
  switch ( key ){
  case DirectiveSections.address:
    return 'healthcaredirective.chapter.title.personaldetails';
  case DirectiveSections.agent:
    return 'healthcaredirective.chapter.title.agent';
  case DirectiveSections.wishes:
    return 'healthcaredirective.chapter.title.wishes';
  case DirectiveSections.review:
    return 'healthcaredirective.chapter.title.reviewandprint';
  }
};

export interface DirectiveSectionStates {
    address: DocumentSectionState;
    agent: DocumentSectionState;
    wishes: DocumentSectionState;
    review: DocumentSectionState;
}

type DirectiveSectionStatus = {
    sectionStates: DirectiveSectionStates;
    next: DirectiveSection;
    canCompleteDirective: boolean;
    hasCompletedDirective: boolean;
}

const useDirectiveSectionStatus = (): DirectiveSectionStatus => {
  const { healthcareDirective } = useUserAttributes().documents;
  const { agents } = useAgents();
  const directive = useSelector( directivesSelector );

  const shouldMarkWishesComplete = !!directive.form
    ? canFormBeSubmit( directive.form, directive.directives )
    : false;
  const canCompleteDirective = agents.length > 0 && shouldMarkWishesComplete && !!healthcareDirective.hasConfirmedAddress;
  const hasCompletedDirective =  canCompleteDirective && !!healthcareDirective.hasViewedDocument;

  const sectionStates: DirectiveSectionStates = {
    address: { state: healthcareDirective.hasConfirmedAddress ? SectionStates.complete : SectionStates.default },
    agent: getAgentSectionState( !!agents.length, healthcareDirective.hasStartedAgentChapter ),
    wishes: { state: shouldMarkWishesComplete
      ? SectionStates.complete
      : healthcareDirective.hasStartedWishesChapter
        ? SectionStates.review
        : SectionStates.default,
    },
    review: { state: healthcareDirective.hasViewedDocument
      ? SectionStates.complete
      : SectionStates.default,
    isGated: !canCompleteDirective,
    },
  };

  const next = getNextSection( sectionStates );
  return { sectionStates, next, hasCompletedDirective, canCompleteDirective };
};

const getAgentSectionState = ( hasAgent: boolean, hasStartedChapter?: boolean ): DocumentSectionState => {
  if( hasAgent ) {
    return {
      state: SectionStates.complete,
    };
  } else if( hasStartedChapter ) {
    return {
      state: SectionStates.warning,
      message: { translationKey: 'healthcaredirective.chapter.error.healthcareagent.missing' },
    };
  } else {
    return {
      state: SectionStates.default,
    };
  }
};

const getNextSection = ( sectionsState: DirectiveSectionStatus['sectionStates']): DirectiveSection => {
  const sectionKeys = Object.keys( sectionsState ) as ( keyof DirectiveSectionStatus['sectionStates'])[];

  return sectionKeys.filter( section => sectionsState[section].state !== SectionStates.complete )[0] || DirectiveSections.review;
};

export default useDirectiveSectionStatus;
