import { FC } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { Location } from 'history';

// Components
import Header from './Header';
import Footer from './Footer';

// Hooks
import useAuth from '../../hooks/useAuth';
import useQuery from '../../hooks/useQuery';

// Routes
import { dashboard as dashboardRoute } from '../../../core/routes';

// Types
import LocationState from '../../types/LocationState';

// Uitls
import { SEARCH_PARAM_BACKGROUND } from '../../../core/constants';

export interface DefaultLayoutProps {
  acceptedRoutes?: string[];
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({
  acceptedRoutes,
  children,
}) => {

  // Render two different screens (or the same screen in a different context)
  // at the same URL, depending on how you got there.
  // https://reactrouter.com/web/example/modal-gallery
  const { hasUserRegistered } = useAuth();
  const location = useLocation<LocationState>();
  const defaultLocation = { pathname: dashboardRoute.get() };
  const backgroundSearchParam = useQuery().get( SEARCH_PARAM_BACKGROUND );
  const background = backgroundSearchParam ? { pathname: backgroundSearchParam } as Location : location.state?.background;

  // @TODO - i hate this solution - Josh
  // don't render the template if a route or background state doesn't exist in acceptedRoutes
  // I wasn't able to figure out how to get background routes in the main router

  if(
    acceptedRoutes?.indexOf( location.pathname ) === -1 &&
    acceptedRoutes?.indexOf( `/${location.pathname.split( '/' )[1]}` ) === -1 &&
    // @TODO: this is a temporary fix to help with medical consent routes, make this better and less brittle
    acceptedRoutes?.indexOf( `/${location.pathname.split( '/' ).slice( 1,3 ).join( '/' )}` ) === -1 &&
    acceptedRoutes?.indexOf( `/${location.pathname.split( '/' ).slice( 2,4 ).join( '/' )}` ) === -1 &&
    ( !background || acceptedRoutes?.indexOf( background.pathname ) === -1 ) &&
    ( !background || acceptedRoutes?.indexOf( `/${background.pathname.split( '/' )[1]}` ) === -1 )
  ) {
    return <></>;
  }

  return(
    <div
      className={ 'flex-1 flex flex-col' }
    >
      <Header
        isLoggedIn={ hasUserRegistered }
      />
      <main className="flex-1 flex flex-col">
        <Switch location={ background || location || defaultLocation }>
          {children}
        </Switch>
      </main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
