import { FormAnswersInterface, FormInterface } from '../types/FormInterface';


/**
 * Parse conditional statements contained in form questions, rendering only those valid
 */
export const getConditionalQuestions =
( formQuestions: FormInterface[], formAnswers: FormAnswersInterface ): FormInterface[] => {
  return formQuestions.filter( question => !question.hidden ).filter( question => {
    return !!question.conditions
      ? question.conditions.every( conditionSet => {
        return conditionSet.some( condition => formAnswers[condition.id] === condition.value );
      })
      : true;
  });
};

/**
 * Determine which questions are shown based on conditionals
 * If each required question shown has an answer, it should be considered "valid"
 */
export const canFormBeSubmit =
( formQuestions: FormInterface[],  formAnswers: FormAnswersInterface ): boolean => {
  const currentlyShownQuestions = getConditionalQuestions( formQuestions, formAnswers );
  return currentlyShownQuestions.filter( question => question.input.required ).every( question => !!formAnswers[question.id]);
};

/**
 * If a previously supplied answer is no longer necessary, remove it from the answers array
 */
export const filterFormAnswers =
( formQuestions: FormInterface[],  formAnswers: FormAnswersInterface ): FormAnswersInterface => {
  const validQuestions = getConditionalQuestions( formQuestions, formAnswers ).map( question => question.id );
  return Object.entries( formAnswers ).reduce(( acc, [ key, value ]) => {
    if( validQuestions.includes( key )) {
      acc[key] = value;
    }
    return acc;
  }, {} as FormAnswersInterface );
};
