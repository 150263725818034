import { ReactElement } from 'react';

// components
import Translation from '../../../components/Translation';
import PersonIcon from '../../People/PersonIcon';

// Redux
import { useSelector } from '../../../store';

// Types
import { PersonInterface } from '../../../types/PersonInterface';
import { RoleInterface } from '../../../types/RoleInterface';
import Person from '../../../core-data-service/models/Person';

// Utils
import { separateArrayStringsByComma } from '../../../../core/utils/stringHelpers';

interface IGuardianSummaryItem {
  className?: string;
  granteeId: RoleInterface['grantee_id'];
  priority: number;
  minorChildren: PersonInterface[];
}

const WillGuardiansSummaryListItem = ({ granteeId, priority, minorChildren, className = '' }: IGuardianSummaryItem ): ReactElement => {
  const guardian = useSelector( state =>
    state.people.data.find( person => person.id === granteeId ),
  );
  if( !guardian || !minorChildren.length ) {
    return <></>;
  }

  const person = new Person( guardian );
  const firstNamesOfChildren = separateArrayStringsByComma( minorChildren.map( child => new Person( child ).firstName ));
  const translationKey = priority === 0 ? 'screen.guardians.tile.subtitle.XisguardianforY' : 'screen.guardians.tile.subtitle.XisbackupguardianforY';

  return (
    <div className={ `flex items-center ${className}` }>
      <div className="w-circleIcon_sm h-circleIcon_sm flex-none">
        <PersonIcon
          photo_url={ guardian?.photo_url || null }
          personName={ guardian?.name }
          alt={ person.firstName }
        />
      </div>
      <div className="text-black-70 ml-4">
        <Translation translationKey={ translationKey } dynamicStringValues={ [ person.firstName, firstNamesOfChildren ] } />
      </div>
    </div>
  );
};

export default WillGuardiansSummaryListItem;
