import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';



export interface ImageCompressionOptions {
  onProgress?: ( progress: number )=> void;
  maxWidthOrHeight?: number;
}

export const MAX_IMAGE_SIZE = 5242880;

export const blobToFile = ( theBlob: Blob, fileName: string ): File => {
  return new File([ theBlob ], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
};

export const convertHeicToJpeg = async( imageFile: File ): Promise<File> => {
  if( imageFile.type === 'image/heic' || imageFile.type === 'image/heif' ) {
    return heic2any({
      blob: imageFile,
      toType: 'image/jpeg',
    })
      .then( blob => blobToFile( blob as Blob, imageFile.name ));
  }
  return imageFile;
};

export const compressIfNeeded = async( image: File, callback: ( _image: File )=> void|File ): Promise<void | File> => {
  imageCompression.getDataUrlFromFile( image )
    .then(( data: string ) => {
      const img = new Image();
      img.onload = () => {
        ( img.width * img.height >= MAX_IMAGE_SIZE )
          ? compressImage( image, { maxWidthOrHeight: 1500 }).then(( _image: File ) => callback( _image ))
          : callback( image );
      };
      img.src = data;
    });
};

/**
 * Browser Image Compression -
 * Javascript module to be run in the web browser for image compression.
 * https://github.com/Donaldcwl/browser-image-compression#readme
 */
const compressImage = async(
  imageFile: File,
  options?: ImageCompressionOptions,
): Promise<File> => {

  const _options = {
    maxWidthOrHeight: 500,
    ...options,
  };

  // keep gifs animated 😈
  if( imageFile.type === 'image/gif' ) {
    return Promise.resolve( imageFile );
  }

  return convertHeicToJpeg( imageFile )
    .then( file => imageCompression( file, _options ))
    .catch( error => {
      console.error( error );
      return imageFile;
    });
};


export default compressImage;
