import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

// components
import { Button } from '../../components/Button';
import Modal from '../../components/Modal';
import Translation from '../../components/Translation';

// hooks
import useBackstack from '../../hooks/useBackstack';
import useHeirs from '../../hooks/useHeirs';
import useTranslations from '../../hooks/useTranslations';

// Types / Models
import Person from '../../core-data-service/models/Person';

// routes
import { lifeInsurance } from '../../../core/routes';


// utils
import analytics from '../../utils/analytics';
import { isArrayGreaterThanOne } from '../../utils/ArrayHelpers';
import { separateArrayStringsByComma } from '../../../core/utils/stringHelpers';

interface Props {
  onClose?: ()=> void;
  onPrimaryAction?: ()=> void;
  onSecondaryAction: ()=> void;
  primaryActionEventId: string;
  isPending: boolean;
}

export default function LifeUpsellModal({
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  primaryActionEventId,
  isPending,
}: Props ): ReactElement{
  const { goBack } = useBackstack();
  const { push } =  useHistory();
  const { getTranslation } = useTranslations();

  const handleOnClose = () => {
    !!onClose ? onClose() : goBack();
  };

  const handlePrimaryAction = () => {
    !!onPrimaryAction ? onPrimaryAction() : push( lifeInsurance.get());
    analytics.track( 'Touch - Upsell Button', { button: primaryActionEventId });
  };

  const { spouse, childrenHeirs } = useHeirs();
  const spouseAndChildrenNames = [];
  if ( spouse ) {
    spouseAndChildrenNames.push( new Person( spouse ).firstName );
  }
  if( childrenHeirs.length ) {
    childrenHeirs.forEach( child => spouseAndChildrenNames.push( new Person( child ).firstName ));
  }

  return (
    <Modal
      onClose={ handleOnClose }
      contentClassName="p-0 m-0"
    >
      <div
        className="
          flex
          sm-only:flex-1
          !sm-only:h-full
          -mt-12
          relative
          md:h-[750px]
        "
      >
        <img
          src={ getTranslation( 'lifeInsurance.upsell.modal.backgroundImage' ) }
          className="
            absolute
            h-full
            inset-0
            transform-gpu
            object-cover
          "
          alt="illustration of sun and clouds"
        />
        <div
          className="
            flex-1
            flex
            flex-col
            justify-center
            relative
            text-center
          "
        >
          <div className="p-10 md:p-18 md:pb-4 mt-8 md:mt-4">
            <h1 className="text-2xl md:text-4xl mb-8 font-headline text-black">

              {spouseAndChildrenNames.length ?
                <Translation
                  translationKey={
                    isArrayGreaterThanOne( spouseAndChildrenNames ) ? 'lifeInsurance.upsell.modal.title.variant-b.multiple' : 'lifeInsurance.upsell.modal.title.variant-b.singular'
                  }
                  dynamicStringValues={ [ separateArrayStringsByComma( spouseAndChildrenNames ) ] }
                />
                :
                <Translation translationKey="lifeInsurance.upsell.modal.title" />
              }

            </h1>
            <p className="mb-8"><Translation translationKey="lifeInsurance.upsell.modal.body" /></p>
          </div>
          <div
            className="
              w-full
              p-8 pt-0
              flex
              flex-col
              items-center
            "
          >
            <Button
              className="mb-6 shadow-button"
              onClick={ handlePrimaryAction }
            >
              <Translation translationKey="lifeInsurance.upsell.modal.primaryAction.label"/>
            </Button>
            <Button
              theme="clean-secondary"
              className="text-black-70 "
              onClick={ onSecondaryAction }
              isPending={ isPending }
            >
              <Translation translationKey="lifeInsurance.upsell.modal.secondaryAction.label" />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
