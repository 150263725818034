import { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from '../../../components/Button';
import { Card } from '../../../components/Card';
import DocumentComplete from '../DocumentComplete';
import SegmentView from '../../../components/SegmentView';
import OrderedComponentsList from '../../../components/OrderedComponentsList';
import Translation from '../../../components/Translation';

// Models
import Document from '../../../core-data-service/models/Document';

// Hooks
import useAccounts from '../../../hooks/useAccounts';
import useOpenDocumentPDF from '../../../hooks/useDocumentPDF';
import useTranslations from '../../../hooks/useTranslations';
import useUserAttributes from '../../../hooks/useUserAttributes';

// Redux
import { useDispatch, useSelector } from '../../../store';
import { setUserAttributes } from '../../../store/reducers/userReducer';
import { showModal, showVideoModal } from '../../../store/reducers/modalReducer';
import { GlobalModals } from '../../../store/initial/modal';

// Routes
import {
  accounts as accountsRoute,
  dashboard as dashboardRoute,
  pourover_will as pourOverWillRoute,
  trust as trustRoute,
  trust_funding as trustFundingRoute,
  will_intro as willIntroRoute,
} from '../../../../core/routes';

export const trustResetAttrs = {
  'app.trustPreviewViewed': false,
  'app.trustHasSignedTrust': false,
  'app.trustSignedTrustDate': '',
};

export default function TrustReview(): ReactElement{
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const [ hasClosedModal, setHasClosedModal ] = useState( false );

  const isReviewOrEditPending = useSelector( state => state.user.setUserAttributes.meta.requestStatus === 'pending' );

  const { networth,
    documents: {
      trust: {
        hasSignedTrust,
        hasViewedPreview,
        trustSignedDate,
        hasViewedTrustWrapUpVideo,
        hasViewedPourOverWillVideo,
        hasSignedPourOverWill,
        pourOverSignedDate,
      },
      will: { willPreviewViewed: hasCompletedWill },
    },
  } = useUserAttributes();
  const { accounts } = useAccounts();
  const hasAccounts = accounts.length;
  const documentType = Document.TYPE_TRUST;

  const { openDocument, isFetchingDocument, hasErrorFetchingDocument } =  useOpenDocumentPDF( Document.TYPE_TRUST );

  useEffect(() => {
    if( !hasViewedTrustWrapUpVideo ) {
      dispatch( showVideoModal({
        src: {
          portrait: 'video.trust.wrapup',
          landscape: 'video.trust.wrapup.landscape',
        },
        onSkip: ()=> dispatch( setUserAttributes({ 'app.trustWrapUpVideoViewed': true })),
        onEnded: ()=> dispatch( setUserAttributes({ 'app.trustWrapUpVideoViewed': true })) }));
    }
  }, [ dispatch, hasViewedTrustWrapUpVideo ]);

  const handlePrintAndSignPrimaryAction = () => {
    if( hasSignedTrust ) {
      dispatch( setUserAttributes({ 'app.trustHasSignedTrust': false }));
    } else {
      dispatch( setUserAttributes({
        'app.trustHasSignedTrust': true,
        'app.trustSignedTrustDate': new Intl.DateTimeFormat( 'en-US', { dateStyle: 'long' }).format( new Date()),
      }));
    }
  };

  const getPourOverData = ()=> {
    if ( hasSignedPourOverWill ) {
      return {
        title: <Translation translationKey="products.trust.manager.action_feed.cards.pouroverwill.states.signed.title" />,
        primaryAction: ()=> dispatch( setUserAttributes(
          {
            'app.trustHasSignedPourOverWill': false,
            'app.trustSignedPourOverWillDate': '',
          },
        )),
        primaryActionLabel: <Translation translationKey="products.trust.manager.action_feed.cards.pouroverwill.states.signed.button" />,
        content: <Translation translationKey="products.trust.manager.action_feed.cards.pouroverwill.states.signed.description" dynamicStringValues={ [ pourOverSignedDate || '' ] } />,
      };
    } else if ( hasCompletedWill ) {
      return {
        title: <Translation translationKey="products.trust.manager.action_feed.cards.pouroverwill.states.intro.title" />,
        primaryAction: () => {
          !hasViewedPourOverWillVideo
            ? dispatch( showVideoModal({
              src: {
                portrait: 'video.trust.pourover',
                landscape: 'video.trust.pourover.landscape',
              },
              hasViewedKey: 'app.viewedPourOverWillVideo',
              onSkip: () => push( pourOverWillRoute.get()),
              onEnded: () => push( pourOverWillRoute.get()) }))
            : push( pourOverWillRoute.get());
        },
        primaryActionLabel: <Translation translationKey="products.trust.manager.action_feed.cards.pouroverwill.states.intro.button" />,
        content: <Translation translationKey="products.trust.manager.action_feed.cards.pouroverwill.states.intro.description" />,
      };
    } else {
      return {
        title: <Translation translationKey="products.trust.manager.action_feed.cards.createwill.title" />,
        primaryAction: () => push( willIntroRoute.get()),
        primaryActionLabel: <Translation translationKey="products.trust.manager.action_feed.cards.createwill.button" />,
        content: <Translation translationKey="products.trust.manager.action_feed.cards.createwill.description" />,
      };
    }
  };

  const { content: pourOverWillContent, ...pourOverWillCardProps } = getPourOverData();

  const handleMakeChangesAction = () => {
    dispatch( setUserAttributes({ ...trustResetAttrs, onSuccess: () => {
      push( trustRoute.get());
    } }));
  };

  const setTrustReviewedAttribute = ( isTrustPreviewViewed: boolean, onSuccess?: ()=> void ) => {
    dispatch( setUserAttributes({ 'app.trustPreviewViewed': isTrustPreviewViewed, onSuccess }));
  };

  const handleDocumentCompleteModalRetreat = ()=> {
    setHasClosedModal( true );
  };

  const hasViewedTrustPreview = useRef( !!hasViewedPreview );
  const isDocumentCompleteModalShown = hasViewedTrustWrapUpVideo && !hasViewedTrustPreview.current && !hasClosedModal;

  return <SegmentView
    backgroundColorClass="bg-moss"
    contentBackgroundColorClass="bg-moss"
    hidePrimaryAction={ true }
    retreatIcon="close"
    onRetreat={ () => push( dashboardRoute.get())  }
    onSecondaryAction={ () => dispatch( showModal( GlobalModals.help ))  }
    secondaryActionChildren={ <Translation translationKey="(button)help"/> }
    secondaryButtonTheme="clean-secondary"
  >
    <div className="max-w-lg mx-auto">
      <h1 className="text-3xl font-headline text-black text-center mb-4">
        <Translation translationKey="products.trust.manager.title"/>
      </h1>

      <hr className="text-forest py-4"/>

      <div className="flex justify-center items-center mb-8">
        <Button
          className="mr-4"
          onClick={ openDocument }
          theme="tertiary"
          small
          disabled={ hasErrorFetchingDocument }
          isPending={ isFetchingDocument }
        >
          <Translation translationKey="products.trust.manager.buttons.open.title" />
        </Button>

        <Button
          onClick={ handleMakeChangesAction }
          theme="clean-secondary"
          isPending={ isReviewOrEditPending }
        >
          <Translation translationKey="products.trust.manager.buttons.review.title" />
        </Button>
      </div>

      <h1 className="text-center mb-8">
        <Translation translationKey="products.trust.manager.action_feed.title" />
      </h1>

      <OrderedComponentsList
        shouldUpdateOnRender={ false }
        items={ [
          [ hasSignedTrust ? 5 : 0,
            <Card
              primaryAction={ handlePrintAndSignPrimaryAction }
              primaryActionLabel={
                hasSignedTrust
                  ? <Translation translationKey="trust.tile.print.button.title.signed"/>
                  : <Translation translationKey="trust.tile.print.button.title.unsigned"/>
              }
              title={ <Translation translationKey="trust.tile.print.title"/> }
              className="p-4 bg-white shadow-sm hover:shadow-md transition-shadow rounded-md mb-4"
            >
              {hasSignedTrust ?
                <Translation translationKey="trust.tile.print.description.signed" dynamicStringValues={ [ trustSignedDate || '' ] }/>
                :
                <Translation translationKey="trust.tile.print.description.unsigned"/>
              }
            </Card>,
          ],
          [ 1,
            <Card
              { ...pourOverWillCardProps }
              className="p-4 bg-white shadow-sm hover:shadow-md transition-shadow rounded-md mb-4"
            >
              { pourOverWillContent }
            </Card>,
          ],
          [ hasSignedPourOverWill ? 4 : 2,
            <Card
              primaryAction={ () => push( trustFundingRoute.get()) }
              primaryActionLabel={ <Translation translationKey="trust.tile.funding.button.title"/> }
              title={ <Translation translationKey="trust.tile.funding.title"/> }
              className="p-4 bg-white shadow-sm hover:shadow-md transition-shadow rounded-md mb-4"
            >
              <Translation translationKey="trust.tile.funding.description"/>
            </Card>,
          ],
          [ 3,
            <Card
              primaryAction={ () => push( accountsRoute.get()) }
              primaryActionLabel={ <Translation translationKey={ hasAccounts ? 'trust.tile.accounts.summary.button.title' : 'trust.tile.accounts.button.title' }/> }
              title={ <Translation translationKey={ hasAccounts ? 'trust.tile.accounts.summary.title' : 'trust.tile.accounts.title' }/> }
              className="p-4 bg-white shadow-sm hover:shadow-md transition-shadow rounded-md mb-4"
            >
              {hasAccounts ?
                <div className="flex items-start pt-2 pb-1">

                  <span className="text-forest text-4xl" style={{ lineHeight: .9 }}>
                    {Intl.NumberFormat( 'en-US', { style: 'currency', currency: 'USD', notation: 'compact' }).format( networth || 0 )}
                  </span>

                  <p className="ml-4 mb-0">
                    <Translation
                      translationKey="trust.tile.accounts.summary.description"
                      dynamicStringValues={ [
                        accounts.length.toString(),
                        accounts.length > 1
                          ? getTranslation( 'trust.accounts.plural' )
                          : getTranslation( 'trust.accounts.singular' ),
                      ] }
                    />
                  </p>

                </div>
                :
                <Translation translationKey="trust.tile.accounts.description"/>
              }
            </Card>,
          ]]
        }
      />

    </div>
    <DocumentComplete
      documentType={ documentType }
      isShown={ isDocumentCompleteModalShown }
      hasSeenView={ !!hasViewedTrustPreview.current }
      onClickEdit={ handleMakeChangesAction }
      onPrimaryAction={ ()=> setHasClosedModal( true ) }
      onRender={ ()=> setTrustReviewedAttribute( true ) }
      onRetreat={ handleDocumentCompleteModalRetreat }
    />
  </SegmentView>;
}
