import { ReactElement } from 'react';
// Components
import ActionSheet from '../../../../components/ActionSheet';

// Hooks
import useDocumentPDF from '../../../../hooks/useDocumentPDF';

// Types
import PersonInterface from '../../../../types/PersonInterface';

import { useDispatch, useSelector } from '../../../../store';
import Document from '../../../../core-data-service/models/Document';
import { makeSnack } from '../../../../store/reducers/snackbarReducer';
import userPersonSelector from '../../../../store/selectors/userPersonSelector';
import useTranslations from '../../../../hooks/useTranslations';
// Redux

interface IProps {
  isVisble: boolean;
  handleClose: ()=> void;
  wardId: PersonInterface['id'];
}

export const MedicalConsentDocumentActionSheet = ({ isVisble, handleClose, wardId }: IProps ): ReactElement => {
  const { openDocument, emailDocument } = useDocumentPDF( Document.TYPE_MEDICAL_CONSENT, { child: { id: wardId } });
  const userPerson = useSelector( userPersonSelector );
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();

  return (
    <ActionSheet
      isOpen={ isVisble }
      handleClose={ handleClose }
      title={ getTranslation( 'dashboard.products.medicalconsent.title' ) }
      buttonsToRender={{
        open: {
          label: getTranslation( 'document.button.newTab' ),
          action: () => {
            openDocument();
            handleClose();
          },
        },
        email: {
          label: getTranslation( 'document.button.email' ),
          action: () => {
            emailDocument();
            dispatch( makeSnack({ message: getTranslation( 'document.medicalConsent.email.success', [ userPerson?.email || '' ]) }));
            handleClose();
          },
        },
      }}
    />
  );
};
