
import { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import { Button } from '../../components/Button';
import Icon from '../../components/Icon';
import { Modal } from '../../components/Modal';
import Translation from '../../components/Translation';

// Hooks
import useHeirs from '../../hooks/useHeirs';

// Redux
import { hideModal } from '../../store/reducers/modalReducer';
import { useDispatch, useSelector } from '../../store';

// Routes
import {
  lifeInsurance as lifeInsuranceRoute,
} from '../../../core/routes';

// Types
import Person from '../../core-data-service/models/Person';
import LocationState from '../../types/LocationState';

// Utils
import { separateArrayStringsByComma } from '../../../core/utils/stringHelpers';
import analytics from '../../utils/analytics';

export interface ChildrenHeirsLifeUpsellModalProps {
  onSecondaryAction?: ()=> void;
}

export default function ChildrenHeirsLifeUpsellModal({ onSecondaryAction }: ChildrenHeirsLifeUpsellModalProps ): ReactElement{
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation<LocationState>();
  const isOpen = useSelector( state => state.modal.data.childrenHeirsLifeUpsell.isOpen );
  const { childrenHeirs } = useHeirs();

  const handleSecondaryAction = () => {
    dispatch( hideModal( 'childrenHeirsLifeUpsell' ));
    onSecondaryAction && onSecondaryAction();
  };

  const handlePrimaryAction = () => {
    analytics.track( 'Touch - Upsell Button', { button: 'childModalPrompt' });
    dispatch( hideModal( 'childrenHeirsLifeUpsell' ));
    push({
      pathname: lifeInsuranceRoute.get(),
      state: { background: location },
    });
  };

  return (
    <Modal
      isOpen={ isOpen }
      onClose={ handleSecondaryAction }
      contentClassName="bg-green-400"
      type="contextual"
    >
      <div className="text-center mx-auto max-w-[225px]">

        <div className="w-[24px] h-[28px] mx-auto mb-6">
          <Icon name="badge" className="text-forest" />
        </div>

        <h1 className="text-black mb-6">
          <Translation
            translationKey="heirs.children.lifeUpsell.modal.title"
            dynamicStringValues={ [ separateArrayStringsByComma( childrenHeirs.map( person => new Person( person ).firstName || '' )) ] }
          />
        </h1>

        <Button
          onClick={ handlePrimaryAction }
          className="mx-auto mb-6"
        >
          <Translation translationKey="heirs.children.lifeUpsell.modal.primaryAction" />
        </Button>

        <Button
          onClick={ handleSecondaryAction }
          theme="clean-secondary"
          className="mx-auto"
        >
          <Translation translationKey="heirs.children.lifeUpsell.modal.secondaryAction" />
        </Button>

      </div>
    </Modal>
  );
}
