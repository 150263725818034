import { ReactElement, useEffect } from 'react';
import { Controller } from 'react-hook-form';

// Components
import HorizontalRule from '../../../components/HorizontalRule';
import PropertyOwnershipSelector from '../PropertyOwnershipSelector';
import TextField from '../../../components/TextField';

// Hooks
import useTranslations from '../../../hooks/useTranslations';
// import PersonIcon from '../../People/PersonIcon';

// Redux
import { useSelector } from '../../../store';


// Utils
import { PropertyDetailFormInterface } from '../../../types/Property/PropertyDetailFormInterface';
import { fieldRequired } from '../../../formValidators/fieldRequired';



export default function PetsPropertyForm({
  control,
  errors,
  clearErrors,
  getValues,
  property,
  register,
  setValue,
}: PropertyDetailFormInterface ): ReactElement{

  const { getTranslation } = useTranslations();

  const useFormProps = {
    register,
    setValue,
    errors,
    getValues,
    clearErrors,
  };

  useEffect(() => {
    if( getValues ) {
      const beneficiaries = getValues( 'beneficiaries' );
      if( beneficiaries ) {
        const [ guardian, backup ] = beneficiaries;
        setValue( 'guardian', guardian );
        setValue( 'backup', backup );
      }
    }
  }, [ setValue, register, getValues ]);

  // Filter out "ME" and currently select guardians
  const guardianOptions = useSelector( state => state.people.data ).filter( person => person.relationship !== 'ME' && Object.values( getValues([ 'guardian', 'backup' ])).indexOf( person.id ) === -1 );

  return(
    <>
      {/* Icon to be replaced with forthcoming component */}
      {/* <div className="h-24 w-24 mb-8">
        <PersonIcon
          photo_url={ Property.getIcon( property?.subtype || subtype ) }
          personName={ property?.name || '' }
          alt="pet avatar"
        />
      </div> */}

      <TextField { ...useFormProps }
        name="name"
        label={ getTranslation( 'pet.form.inputs.name.label' ) }
        required={ fieldRequired( getTranslation( 'pet.form.inputs.name.required' )) }
        autoComplete="off"
        className="mb-8"
      />

      {/* Ownership */}
      <h2 className="mb-4 mt-8 font-bold">
        {getTranslation( 'pet.form.section.guardians.title' )}
      </h2>

      <HorizontalRule className="mt-6 mb-6 border-black-70!"/>

      <Controller
        control={ control }
        name="guardian"
        rules={{ required: fieldRequired( getTranslation( 'pet.form.inputs.primary.required' )) }}
        render={ ({ onChange, value }) => {
          return (
            <PropertyOwnershipSelector
              onChange={ e => {
                onChange( e );
                clearErrors( 'guardian' );
              } }
              value={ value }
              error={ errors.guardian }
              label={ getTranslation( 'pet.form.inputs.primary.label' ) }
              className="mb-6"
              people={ guardianOptions }
            />
          );
        } }
      />

      <HorizontalRule className="mt-6 mb-6 border-black-70!"/>

      <Controller
        control={ control }
        name="backup"
        render={ ({ onChange, value }) => (
          <PropertyOwnershipSelector
            onChange={ e => {
              onChange( e );
              clearErrors( 'backup' );
            } }
            value={ value }
            label={ getTranslation( 'pet.form.inputs.backup.label' ) }
            error={ errors.backup }
            people={ guardianOptions }
          />
        ) }
      />
    </>
  );
}

