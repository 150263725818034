import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import Translation from '../../components/Translation';

// Hooks
import useTranslations from '../../hooks/useTranslations';

// Redux
import { patchPerson } from '../../store/reducers/peopleReducer';
import { useSelector, useDispatch } from '../../store';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import { fetchUser } from '../../store/reducers/userReducer';

// Utils
import analytics from '../../utils/analytics';
import inputMask from '../../utils/inputMask';
import { fieldRequired, zipMinLength } from '../../formValidators';


interface PromptForZipProps {
  className?: string;
  onCancel?: ()=> void;
  onSuccess?: ()=> void;
}

interface PromptForZipFormData extends SanitizedFormData {
  zip: string;
}

const PromptForZip: FC<PromptForZipProps> = ({
  className,
  onCancel,
  onSuccess,
}) => {

  // Application state
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const userPerson = useSelector( userPersonSelector );
  const isPending = useSelector( state => state.people?.patchPerson?.meta?.requestStatus === 'pending' );
  const zip = userPerson?.address?.zip || '';

  // Form hooks
  const { register, getValues, setValue, handleSubmit, formState } = useForm<PromptForZipFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      zip,
    },
  });

  useEffect(() => {
    analytics.track( 'View - Registration Zip' );
  }, []);

  const { errors } = formState;
  const useFormProps = { register, getValues, setValue, errors };

  // onSubmit handler
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    const address = {
      zip: data.zip,
    };

    userPerson && dispatch( patchPerson({ id: userPerson.id, address, onSuccess: () => {
      dispatch( fetchUser({ onSuccess: response => {
        onSuccess && onSuccess(); // move forward in the parent flow

      } }));
    } }));
  };

  return(
    <Form
      className={ className }
      handleSubmit={ handleSubmit }
      onSubmit={ onSubmit }
    >
      <h1 className="text-3xl mb-6 text-black font-headline"><Translation translationKey={ 'account.signup.location.title' }/></h1>
      <TextField { ...useFormProps }
        large
        className="mb-6"
        label={ <Translation translationKey={ 'screen.onboarding.zipcode.field.placeholder' }/> }
        minLength={ zipMinLength( getTranslation( 'screen.onboarding.zipcode.error.invalid' )) }
        name="zip"
        normalize={ inputMask.zip }
        required={ fieldRequired( getTranslation( 'screen.onboarding.zipcode.error.invalid' )) }
        type="tel"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={ true }
      />
      <p className="mb-6 text-black-50">
        <Translation translationKey={ 'account.signup.location.desc' } />
      </p>
      <div className="flex justify-end">
        <Button
          isPending={ isPending }
          type="submit"
          id="onboarding-continue-button"
        ><Translation translationKey="(button)continue"/></Button>
      </div>
    </Form>

  );
};

export default PromptForZip;
