import { ReactElement, ReactNode } from 'react';

interface Props {
  className?: string;
  errorMessage: string | ReactNode;
}

export default function FieldError({ errorMessage, className = '' }: Props ): ReactElement{
  return (
    <div
      role="alert"
      className={ `text-red-error font-bold ${className}` }>
      { errorMessage }
    </div>
  );
}
