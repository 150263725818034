// Redux
import { useSelector } from '../store';
import invitationsSelector from '../store/selectors/invitationsSelector';

// Types
import Person from '../core-data-service/models/Person';
import Invitation from '../core-data-service/models/Invitation';
import Role from '../core-data-service/models/Role';
import PersonInterface from '../types/PersonInterface';
import RoleInterface from '../types/RoleInterface';
import minorChildrenSelector from '../store/selectors/minorChildrenSelector';

type RolesGroupedByWard = {
  [key: string]: string[];
}

export type RolesGroupedByGrantee = {
  [key: string]: {
    primary: string[];
    secondary: string[];
  };
}

const useGuardians = (): {
  minorChildren: PersonInterface[];
  potentialGuardians: PersonInterface[];
  roles: RoleInterface[];
  hasAddedUnderageChildren: boolean;
  uninvitedGuardianFirstNames: string[];
  rolesGroupedByWard: RolesGroupedByWard;
  rolesGroupedByGrantee: RolesGroupedByGrantee;
} => {

  const roles = useSelector( state =>
    state.roles.data
      .filter( role => role.name === Role.TYPE.GUARDIAN )
      .sort(( first, second ) => first.priority > second.priority ? 1 : -1 ),
  );

  const invites = useSelector( invitationsSelector );

  // so we know primary, backup on each
  const rolesGroupedByWard = roles.reduce(( acc, role ) => {
    const { ward_id, grantee_id } = role;
    if( !acc[ward_id]) {
      acc[ward_id] = [];
    }
    acc[role.ward_id].push( grantee_id );
    return acc;
  }, {} as RolesGroupedByWard );

  const rolesGroupedByGrantee = Object.entries( rolesGroupedByWard ).reduce(( acc, role ) => {
    const [ ward_id, grantee_ids ] = role;
    grantee_ids.forEach(( grantee, idx ) => {
      if( !acc[grantee]) {
        acc[grantee] = {
          primary: [],
          secondary:[],
        };
      }
      acc[grantee][idx === 0 ? 'primary' : 'secondary'].push( ward_id );
    });
    return acc;
  }, {} as RolesGroupedByGrantee );

  const uninvitedGuardianFirstNames = Object.values( roles.reduce(( acc, role ) => {
    const { grantee_id, invitation_id } = role;
    const invite = invites.find( invitation => invitation.id === invitation_id );
    const name = invite?.invitee?.name;
    const hasNotBeenInvited = invite?.status === Invitation.STATUS.CREATED;
    if( name && hasNotBeenInvited && !acc[grantee_id]) {
      acc[grantee_id] = name ;
    }
    return acc;
  }, {} as {[key: string]: string}));

  const minorChildren = useSelector( minorChildrenSelector );

  const hasAddedUnderageChildren = minorChildren.length > 0;

  const potentialGuardians = useSelector( state =>
    state.people.data
      .filter( _person => {
        const person = new Person( _person );
        return Boolean(
          person.isAdult !== false &&
          _person.relationship !== 'ME' &&
          !minorChildren.find( child => child.id === _person.id ) &&
          !person.relationship.is( 'ADVISORS' ) &&
          !person.relationship.is( 'PHYSICIAN' ));
      }));

  return {
    minorChildren,
    potentialGuardians,
    roles,
    hasAddedUnderageChildren,
    uninvitedGuardianFirstNames,
    rolesGroupedByWard,
    rolesGroupedByGrantee,
  };
};


export default useGuardians;
