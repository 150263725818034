import { useState, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import HeirListItem from './HeirListItem';
import { HeirsScenarioSubhead, HeirsScenarioTitle } from './HeirsStrings';
import HeirsScenarioActionContainer from './HeirsScenarioActionContainer';
import InsuranceHelper from '../../components/InsuranceHelper';
import PersonDetailView from '../People/PersonDetailView';
import SegmentView from '../../components/SegmentView';

// hooks
import useBackstack from '../../hooks/useBackstack';
import useHeirs from '../../hooks/useHeirs';
import useTranslations from '../../hooks/useTranslations';
import { useDispatch, useSelector } from '../../store';
import useUserAttributes from '../../hooks/useUserAttributes';

// Redux
import userPersonSelector from '../../store/selectors/userPersonSelector';
import { setUserAttributes } from '../../store/reducers/userReducer';
import { showChildrenHeirsUpsellLifeModal } from '../../store/reducers/modalReducer';

// Types / Utils
import Address from '../../core-data-service/models/Address';
import analytics from '../../utils/analytics';
import { RelationshipTypes } from '../../core-data-service/models/Relationship';
import { heirsScenarios } from '.';

// Routes
import { trust as trustRoute } from '../../../core/routes';
import useRenderInsuranceUpsell from '../../hooks/useRenderInsuranceUpsell';

const RELATIONSHIP_TYPE: RelationshipTypes = 'CHILDREN';

type ScenarioBProps = {
  documentRoute: string;
  flowRoute: string;
}


const ScenarioB = ({ documentRoute, flowRoute }: ScenarioBProps ): ReactElement => {

  const { push } = useHistory();
  const { goBack } = useBackstack( flowRoute );
  const userPerson = useSelector( userPersonSelector );
  const dispatch = useDispatch();
  const [ isAddingChild, setIsAddingChild ] = useState( false );

  const { getTranslation } = useTranslations();

  const shouldViewInsuranceUpsells = useRenderInsuranceUpsell();

  const { childrenHeirs } = useHeirs();
  const { hasChildren } = useUserAttributes();
  const userState = userPerson?.address.state || '';
  const displayState = Address.US_STATES.find( state => state.value === userState )?.label || '';
  const scenarioTitle = getTranslation( 'inheritance.scenario.children.description', [ displayState ]);

  const proceed = () => push({ pathname: flowRoute, search: `page=${heirsScenarios.c}` });
  const scenario = 'secondary';

  const { hasSeenChildrenHeirsLifeModal } = useUserAttributes();

  const shouldShowInsuranceHelper = !!childrenHeirs.length && hasSeenChildrenHeirsLifeModal && shouldViewInsuranceUpsells;
  const shouldShowInsuranceModal = !hasSeenChildrenHeirsLifeModal && shouldViewInsuranceUpsells;

  const handlePrimaryAction = () => {
    if( shouldShowInsuranceModal ) {
      dispatch( showChildrenHeirsUpsellLifeModal({ onSecondaryAction: proceed }));
      analytics.track( 'View - Upsell Button', { button: 'childModalPrompt' });
      dispatch( setUserAttributes({ 'app.hasSeenChildrenHeirsLifeModal': true }));
    } else {
      proceed();
    }
  };

  return (
    <SegmentView
      onRetreat={ goBack }
      onPrimaryAction={ handlePrimaryAction }
      subhead={ <HeirsScenarioSubhead scenario={ scenario } /> }
      title={ <HeirsScenarioTitle scenario={ scenario } /> }
      onSecondaryAction={ () => push( documentRoute ) }
      hidePrimaryAction={ hasChildren && !childrenHeirs.length }
      secondaryActionChildren={ getTranslation( 'common.finish.later' ) }
      primaryActionLabel={ getTranslation( 'products.heirs.scenarios.buttons.next' ) }
    >
      <HeirsScenarioActionContainer
        primaryAction={
          () =>
            setIsAddingChild( true )
        }
        primaryActionLabel="(button)add.children"
        title={ <p className="text-black-70">{scenarioTitle}</p> }
        subhead={ <p className="font-bold text-black mb-2">{getTranslation( 'products.heirs.scenarios.sections.children.title' )}</p> }
        iconName={ 'addGuardian' }
      >

        <PersonDetailView
          isOpen={ isAddingChild }
          renderedAsRoute={ false }
          relationshipType={ RELATIONSHIP_TYPE }
          onSuccess={ () => {
            setIsAddingChild( false );
            if( hasSeenChildrenHeirsLifeModal ) {
              dispatch( setUserAttributes({ 'app.hasSeenChildrenHeirsLifeModal': false }));
            }
          } }
          onClose={ () => {
            setIsAddingChild( false );
          } }
        />
        {childrenHeirs.length > 0 &&
          <div className="mt-8">
            {childrenHeirs.map(( child, i ) => (
              <HeirListItem
                className="mb-4 last:mb-0"
                key={ child.id }
                person={ child }
                documentContext={ documentRoute === trustRoute.get() ? 'trust' : 'will' }
              />
            ))}
            {shouldShowInsuranceHelper &&
              <InsuranceHelper className="mt-8" eventProperties={{ button: 'HeirsIndividual' }} />
            }
          </div>
        }
      </HeirsScenarioActionContainer>

    </SegmentView>
  );
};


export default ScenarioB;
