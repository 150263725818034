import { AxiosResponse } from 'axios';


export const GoalTypes = {
  'GET WILL': 'GET WILL',
  'BUY INSURANCE': 'BUY INSURANCE',
  'GET TRUST': 'GET TRUST',
  'GET POWER OF ATTORNEY':'GET POWER OF ATTORNEY',
  'GET HEALTHCARE DIRECTIVE': 'GET HEALTHCARE DIRECTIVE',
  'GET MEDICAL CONSENT': 'GET MEDICAL CONSENT',
} as const;
export type GoalType = keyof typeof GoalTypes;

/**
 * @property string $id
 * @property string $user_id
 * @property string $type
 * @property string $preference
 * @property string $expected_at
 * @property string $achieved_at
 * @property string $updated_at
 * @property string $created_at
 *
 * @property User $creator
 * @property Collection $owners
 * @property Collection $recipients
 */
interface GoalInterface {
  id: string;
  user_id: string;
  type: string;
  preference: string;
  expected_at: string;
  achieved_at: string;
  updated_at: string;
  created_at: string;
  // creator : User;
  // owners : Collection;
  // recipients : Collection;
}


interface GoalResponse {
  data: GoalInterface;
}

interface GoalsResponse {
  data: GoalInterface[];
}

export type AxiosGoalsResponse = AxiosResponse<GoalsResponse>;
export type AxiosGoalResponse = AxiosResponse<GoalResponse>;

export default GoalInterface;
