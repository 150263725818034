import { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

// Components
import Form from '../../../../components/Form';
import SegmentView from '../../../../components/SegmentView';
import Translation from '../../../../components/Translation';
import { TextArea } from '../../../../components/TextArea';

// Hooks
import useBackstack from '../../../../hooks/useBackstack';
import useTranslations from '../../../../hooks/useTranslations';

// Redux
import { useDispatch, useSelector } from '../../../../store';

// Types
import SanitizedFormData from '../../../../../core/types/SanitizedFormData';
import { medical_consent_care_info } from '../../../../../core/routes';
import { getPersonByID } from '../../../../utils/people/getPersonByID';
import { patchPerson } from '../../../../store/reducers/peopleReducer';
import { makeSnack } from '../../../../store/reducers/snackbarReducer';

export default function MedicalConsentMedicationsForm(): ReactElement{
  const { wardId } = useParams<{ wardId: string}>();
  const { goBack } = useBackstack( medical_consent_care_info.get({ wardId }));
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const person = useSelector( getPersonByID( wardId ));
  const INPUT_MEDICATIONS = 'medications';
  const submitButtonTitle = person?.medications ? '(button)update' : '(button)done';
  const defaultValues = { medications: person?.medications };
  const medicationsTitle = <Translation translationKey={ 'medicalconsent.screen.medications.title' }/> ;

  const { register, setValue, getValues, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues,
  });

  const useFormProps = {
    register,
    setValue,
    getValues,
    errors,
  };

  const onSubmit: SubmitHandler<SanitizedFormData> = data =>{
    dispatch( patchPerson({ id: person?.id, ...data, onSuccess: () => {
      dispatch( makeSnack({ message: getTranslation( 'form.actions.update.success', [ person?.name || '' ]) }));
      push( medical_consent_care_info.get({ wardId }));
    },
    onError: ( error: string ) => {
      dispatch( makeSnack({ message: error, theme: 'error' }));
    } }));
  };

  return (
    <Form handleSubmit={ handleSubmit } onSubmit={ onSubmit }>
      <SegmentView onRetreat={ goBack }
        primaryActionLabel={ <Translation translationKey={ submitButtonTitle }/> }
        primaryActionButtonType={ 'submit' }
        title={ medicationsTitle }
      >
        <TextArea
          { ...useFormProps }
          label={ medicationsTitle }
          showLabel={ false }
          name={ INPUT_MEDICATIONS }
          placeholder={ getTranslation( 'medicalconsent.screen.medications.placeholdertext' ) }/>
      </SegmentView>
    </Form>
  );
}
