import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import Modal from '../../components/Modal';

// Components
import PhotoCropper from '../../components/PhotoCropper';

// Types
import PersonInterface from '../../types/PersonInterface';



const PersonDetailAvatarCropper: FC<{
  dispatchPhotoUpload: ( photo: File, person: PersonInterface, onSuccess?: ()=> void )=> void;
  isCropAccepted: boolean;
  isCropping: boolean;
  person: PersonInterface|undefined;
  setIsCropAccepted: Dispatch<SetStateAction<boolean>>;
  setIsCropping: ( isCropping: boolean )=> void;
  setIsProcessingPhoto: Dispatch<SetStateAction<boolean>>;
  setStagedPhotoObjURL: Dispatch<SetStateAction<string|undefined>>;
  setStagedPhotoUpload: Dispatch<SetStateAction<File|null>>;
  stagedPhotoObjURL?: string;
  stagedPhotoUpload: File|null;
  renderedAsRoute?: boolean;
}> = ({
  dispatchPhotoUpload,
  isCropAccepted,
  isCropping,
  person,
  setIsCropAccepted,
  setIsCropping,
  setIsProcessingPhoto,
  setStagedPhotoObjURL,
  setStagedPhotoUpload,
  stagedPhotoObjURL,
  stagedPhotoUpload,
  renderedAsRoute,
}) => {


  /**
   * When user is done cropping the photo...
   */
  const onCrop = ( photo: File ) => {
    setStagedPhotoObjURL( URL.createObjectURL( photo ));
    setStagedPhotoUpload( photo );
    setIsCropping( false );
    setIsCropAccepted( true );
  };

  // If `stagedPhotoObjURL` is removed, but somehow `isCropping` is still true
  // then set `isCropping` to false.
  useEffect(() => {
    if( !stagedPhotoObjURL && isCropping ){
      setIsCropping( false );
    }
  }, [ stagedPhotoObjURL, setIsCropping, isCropping ]);

  // If `isCropAccepted` prop is true, and person exists, upload photo
  useEffect(() => {
    if( isCropAccepted && !!person && !!stagedPhotoUpload ){
      const onPatchSuccess = () => {
        setStagedPhotoObjURL( undefined );
        setIsProcessingPhoto( false );
      };
      setIsCropAccepted( false );
      dispatchPhotoUpload( stagedPhotoUpload, person, onPatchSuccess );
    }
  }, [
    dispatchPhotoUpload,
    isCropAccepted,
    person,
    setIsCropAccepted,
    setIsProcessingPhoto,
    setStagedPhotoObjURL,
    stagedPhotoUpload,
  ]);

  // Don't show cropper if not cropping or no photo to crop
  if( !isCropping || !stagedPhotoObjURL ){
    return null;
  }

  return(
    <Modal
      isOpen={ isCropping }
      type="contextual"
      className="min-h-[500px]"
    >
      <PhotoCropper
        imageSrc={ stagedPhotoObjURL }
        onCrop={ onCrop }
        renderedAsRoute={ renderedAsRoute }
      />
    </Modal>
  );
};

export default PersonDetailAvatarCropper;
