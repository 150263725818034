import { ReactElement } from 'react';


// Assets
import userIconDefault from '../../assets/images/user-icon-default.png';

// Components
import CircleImage, { CircleImageProps } from '../../components/CircleImage';
import InitialsAvatar, { InitialsAvatarProps } from '../../components/InitialsAvatar';


interface PersonIconProps extends Omit<CircleImageProps, 'imageSrc'>,
  Omit<InitialsAvatarProps, 'personName'> {
  photo_url: CircleImageProps['imageSrc']|null;
  personName?: InitialsAvatarProps['personName']|null;
  style?: React.CSSProperties|undefined;
}

function PersonIcon({
  photo_url,
  personName,
  style,
  className,
  ...props
}: PersonIconProps ): ReactElement{

  return(
    ( photo_url || !personName )
      ? <CircleImage { ...props }
        imageSrc={ photo_url || userIconDefault }
        className={ `shadow-circle-icon ${className}` }
        style={ style }
      /> :
      <InitialsAvatar { ...props }
        personName={ personName }
        className={ `shadow-circle-icon ${className}` }
        style={ style }
      />
  );
}

export default PersonIcon;
