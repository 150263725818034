import { ReactElement } from 'react';

// Components
import PersonIcon from '../People/PersonIcon';
import InvitationWrapper from '../../components/InvitationWrapper';

// Types / Utils
import Role from '../../core-data-service/models/Role';
import { separateArrayStringsByComma, toTitleCase } from '../../../core/utils/stringHelpers';

// Models
import Person from '../../core-data-service/models/Person';

// Redux
import { useSelector } from '../../store';
import peopleSelector from '../../store/selectors/peopleSelectors';
import { combinedRole } from './InvitesList';
interface InvitesListItemProps {
  granteeId: string;
  combinedRole: combinedRole;
  className?: string;
}

export default function InvitesListItem({
  combinedRole,
  granteeId,
  className = '',
}: InvitesListItemProps ): ReactElement{

  const { primary, secondary, executor, trustee } = combinedRole;

  const people = useSelector( peopleSelector );
  const invitedPerson = people.find( person => person.id === granteeId );

  if ( !invitedPerson ) {
    return <></>;
  }

  const firstName = new Person( invitedPerson ).firstName;
  const photoUrl = invitedPerson.photo_url;

  let primaryLabel, secondaryLabel, executorLabel, trusteeLabel, inviteToRender;
  if( primary?.length ) {
    primaryLabel = `Primary ${toTitleCase( Role.TYPE.GUARDIAN )} for `;
    const primaryNames = primary.map( ward_id => {
      const wardPerson = people.find( person => person.id === ward_id );
      return wardPerson ? new Person( wardPerson ).firstName : '';
    });
    primaryLabel += `${separateArrayStringsByComma( primaryNames.filter( name => !!name ))}`;
  }
  if( secondary?.length ) {
    secondaryLabel = `Backup ${toTitleCase( Role.TYPE.GUARDIAN )} for `;
    const secondaryNames = secondary.map( ward_id => {
      const wardPerson = people.find( person => person.id === ward_id );
      return wardPerson ? new Person( wardPerson ).firstName : '';
    });
    secondaryLabel += `${separateArrayStringsByComma( secondaryNames.filter( name => !!name ))}`;
  }

  if ( executor !== undefined ) {
    executorLabel = getRoleLabel( executor, toTitleCase( Role.TYPE.EXECUTOR ));
  }
  if ( trustee !== undefined ) {
    trusteeLabel = getRoleLabel( trustee, toTitleCase( Role.TYPE.TRUSTEE ));
  }

  const labels = [ primaryLabel, secondaryLabel, executorLabel, trusteeLabel ].flatMap( string => string || []);
  const rolesLabels = separateArrayStringsByComma( labels );

  if ( primary?.length || secondary?.length ) {
    inviteToRender = Role.TYPE.GUARDIAN;
  } else if( executor !== undefined ) {
    inviteToRender = Role.TYPE.EXECUTOR;
  } else if( trustee !== undefined ) {
    inviteToRender = Role.TYPE.TRUSTEE;
  }

  return (
    <li className={ `${ className } flex flex-col mt-6 border-grey-400 border-b-2 first:mt-0 last:-mb-6 last:border-b-0` }>
      <div className={ 'flex flex-row mb-4' }>
        <div className="h-12 w-12">
          <PersonIcon
            className="h-12 w-12 "
            photo_url={ photoUrl }
            personName={ invitedPerson.name }
            alt={ firstName }
          />
        </div>
        <div className={ 'flex flex-col justify-center ml-4' }>
          <span className={ 'font-bold text-left' }>{ firstName }</span>
          <span className={ 'text-left mt-1' }>{ rolesLabels }</span>
        </div>
      </div>
      <InvitationWrapper
        inviteeId={ granteeId }
        role={ inviteToRender }
        statusButtonClickEvent="Touch - Invite List"
      />
    </li>
  );
}


const getRoleLabel = ( rolePriority: number, role: string ) => {
  switch( rolePriority ) {
  case( 0 ):
    return `Primary ${role}`;
  case( 1 ):
    return `Backup ${role}`;
  default:
    return `Backup ${role} #${rolePriority}`;
  }
};
