import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { disability_login } from '../../../core/routes';
import useBackstack from '../../hooks/useBackstack';
import BLACKROCK from '../../assets/images/disabilityInsurance/BLACKROCK.jpeg';
import SegmentView from '../../components/SegmentView';
import Button from '../../components/Button';
import HorizontalRule from '../../components/HorizontalRule';
import unum from '../../assets/images/disabilityInsurance/Unum_logo_blue.png';
import Translation from '../../components/Translation';
export default function BlackRockLandingPage(): ReactElement{
  const image = {
    src: BLACKROCK,
    alt: 'Basics intro illustration',
  };
  const { push } = useHistory();
  const { goBack } = useBackstack();
  const buttonOnClick = () => {
    push( disability_login.get());
  };
  return (
    <SegmentView
      onRetreat={ goBack }
      className="-mx-8 -mb-4"
      hidePrimaryAction={ true }
      hideLogo={ true }
      hideSecondaryAction={ true }
      shouldHideBackButton={ true }
    >
      <div className="flex flex-col flex-1">
        <div className="-mt-24 mb-8 p-8 pb-12 pt-48 md:-mx-12 md:-mt-12 md:p-12 relative h-64">
          <img
            src={ image.src }
            alt={ image.alt }
            className="inset-0 absolute object-cover object-bottom h-full w-full"
          />
        </div>
        <div className="flex flex-col sm-only:px-8 text-center">
          <Translation translationKey={ 'disability.landing.body' }/>
          <div className="flex justify-center">
            <Button className={ 'm-8 w-3/5' } onClick={ buttonOnClick }>Get Started</Button>
          </div>
          <div className="flex justify-center">
            <HorizontalRule className={ 'w-4/5' }/>
          </div>
          <div className="flex justify-center">
            <img src={ unum } alt={ 'unum logo' }
              className={ 'w-18 h-6 m-4' }/>
          </div>
          <Translation translationKey={ 'disability.landing.footer' }/>
        </div>
      </div>
    </SegmentView>
  );
}
