import { SnackBarProps } from '../../components/Snackbar';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  items: [] as Array<SnackBarProps>,
  swallowSnack: {
    meta: {} as AsyncThunkMetaInterface,
  },
};

export default initialState;
