import React, { ReactElement, useCallback, useEffect, useState } from 'react';

// hooks
import useFlow from '../../hooks/useFlow';
import useAuth from '../../hooks/useAuth';

// Components
import Flow from '../../components/Flow';
import PromptForEmail from './PromptForEmail';
import PromptForName from './PromptForName';
import PromptForZip from './PromptForZip';
import PromptForState from './PromptForState';
import Authenticate from '../Authenticate';

// Redux
import { hasCompletedOnboarding } from '../../store/reducers/authReducer';
import { useDispatch, useSelector } from '../../store';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import userSelector from '../../store/selectors/userSelector';

interface Props {
  onComplete: ()=> void;
}

export default function OnboardingForms({ onComplete }: Props ): ReactElement{
  const { isGuestUser } = useAuth();
  const hasUserCompletedOnboarding = useSelector( state => state.auth.hasCompletedOnboarding );
  const user = useSelector( userSelector );
  const userPerson = useSelector( userPersonSelector );
  const dispatch = useDispatch();

  const [ items, setItems ] = useState([
    [ Authenticate, isGuestUser ],
    [ PromptForZip, !userPerson?.address?.zip ],
    [ PromptForName, !userPerson?.name ],
    [ PromptForEmail , !user?.email ],
  ]);


  useEffect(() => {
    const conditionalItems = [ PromptForState, !userPerson?.address?.state ];
    if( userPerson?.address?.zip && !userPerson?.address?.state ) {
      setItems([ ...items, conditionalItems ]);
    }
  }, [ userPerson?.address?.zip ]);

  useEffect(() => {
    localStorage.setItem( 'isOnboarding', JSON.stringify( true ));
    return () => {
      localStorage.setItem( 'isOnboarding', JSON.stringify( false ));
    };
  }, []);

  const computedNeedsList = JSON.stringify( items.map( item => item[1]));
  // JSON.stringify-ing the computed array will prevent excessive re-rendering
  const { currentIndex, retreat, proceed, setCurrentIndex } = useFlow( JSON.parse( computedNeedsList ).findIndex( Boolean ));

  const onCompleteCallback = useCallback( onComplete, [ onComplete ]);

  // Side Effect -> setCurrentIndex - or if all requirements are met, set to -1
  useEffect(() => {
    if( currentIndex < 0 || currentIndex >= items.length ){
      onCompleteCallback();
      if( !hasUserCompletedOnboarding ) {
        dispatch( hasCompletedOnboarding( true ));
      }
    }
  }, [ currentIndex, items.length, onCompleteCallback, dispatch, hasUserCompletedOnboarding ]);


  return (
    <>
      <Flow
        currentIndex={ currentIndex }
      >
        {
          items.map(( item, idx ) => {
            const Item = item[0] as React.ElementType;
            return(
              <Item key={ `PromptForName.Item.${idx}` }
                onCancel={ idx > 0 ? retreat : () => alert( 'go back' )  }
                onSuccess={ proceed }
                setParentFlowIndex={ setCurrentIndex }
              />
            );
          })
        }
      </Flow>
    </>
  );
}
