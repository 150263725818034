import { ReactElement, useState } from 'react';

// Components
import Modal from '../components/Modal';
import Button from '../components/Button';
import Translation from '../components/Translation';

// Routes
import { dashboard as dashboardRoute } from '../../core/routes';

// Hooks
import useTranslations from '../hooks/useTranslations';
import useBackstack from '../hooks/useBackstack';

// utils
import appConfig from '../../core/appConfig';
import LocalizedImage from '../components/LocalizedImage';

export default function IosPrompt(): ReactElement{
  const [ isOpen, setIsOpen ] = useState( true );
  const { getTranslation } = useTranslations();
  const { goBack } = useBackstack( dashboardRoute.get());
  const { iosUrl } = appConfig.marketing;

  // @TODO - trigger close and do this automatically
  const onClose = () => {
    setIsOpen( false );
    setTimeout(() => goBack(), 300 );
  };

  return (
    <Modal
      onClose={ onClose }
      isOpen={ isOpen }
      toggleModal={ onClose }
      contentClassName="bg-moss p-12"
    >
      <a target="_none" href={ iosUrl } className="flex-1 flex items-center sm-only:-mt-modalCloseButtonOffset" >
        <div className="flex flex-1 flex-col justify-center items-center">
          <div className="w-32 mt-4 mb-12">
            <div className="aspect-h-1 aspect-w-1">
              <LocalizedImage className="" imageKey="(alert)suggestedupgrade(header)appImage" altText="Tomorrow app store icon" />
            </div>
          </div>
          <h1 className="text-4xl font-headline text-black text-center mb-8 mx-4">{ getTranslation( '(alert)suggestedupgrade(header)text' ) }</h1>
          <p className="text-center text-black-70 text-md mb-12">{ getTranslation( '(alert)suggestedupgrade(subheader)text' ) }</p>
          <div className="flex">
            <Button><Translation translationKey="(alert)suggestedupgrade(button)upgrade" /></Button>
          </div>
        </div>
      </a>
    </Modal>
  );
}
