import { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// Routes
import { dashboard as dashboardRoute } from '../../../core/routes';

// Components
import Translation from '../../components/Translation';
import SegmentView from '../../components/SegmentView';
import { Card } from '../../components/Card';
import List, { ListItemProps } from '../../components/List';
import { Icons } from '../../components/Icon';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import { useSelector } from '../../store';
import useHeirs from '../../hooks/useHeirs';
import useTranslations from '../../hooks/useTranslations';

// Types
import { PRODUCT_KEYS } from '../../types/ProductsInterface';

// Redux
import productsSelector from '../../store/selectors/productsSelector';

// Utils
import analytics from '../../utils/analytics';
import { DocumentEntitlements } from '../../types/Entitlements/EntitlementInterface';
import { PURCHASE_PAGE } from '.';
import { generateQueryString } from '../../utils/urlUtils';
import appConfig from '../../../core/appConfig';
import { separateArrayStringsByComma } from '../../../core/utils/stringHelpers';
import Person from '../../core-data-service/models/Person';
import { LOCAL_STORAGE_UTM_ATTRIBUTION } from '../../../core/constants';
import { EXTERNAL_ID_PARAM, getAnonymousID } from '../../utils/anonymousID';

interface IPricingSelectionView {
  document: keyof DocumentEntitlements;
  selectedProduct: PRODUCT_KEYS;
}

export  const LIST_ITEM_SETTINGS: {icon: Icons; iconClasses: string} = { icon: 'check', iconClasses: 'text-salamander w-3 h-3 mr-3 flex-none' };



export default function PricingSelectionView({ document, selectedProduct }: IPricingSelectionView ): ReactElement{

  const { goBack } = useBackstack( dashboardRoute.get());
  const products = useSelector( productsSelector );
  const location = useLocation();
  const { getTranslation } = useTranslations();
  const { push } = useHistory();
  const { childrenHeirs } = useHeirs();
  const heirsString = childrenHeirs.length
    ? separateArrayStringsByComma( childrenHeirs.map( person => new Person( person ).firstName || '' ))
    : 'your heirs';

  const isBasic = selectedProduct === PRODUCT_KEYS.basic;

  const listItems: ListItemProps[] = [
    {
      ...LIST_ITEM_SETTINGS,
      children: <Translation translationKey="products.estateplan.paywall.upsell.list.one"/>,
    },
    {
      ...LIST_ITEM_SETTINGS,
      children: <Translation translationKey="products.estateplan.paywall.upsell.list.two"/>,
    },
    {
      ...LIST_ITEM_SETTINGS,
      children: <Translation translationKey="products.estateplan.paywall.upsell.list.three"/>,
    },
    {
      ...LIST_ITEM_SETTINGS,
      children: <Translation translationKey="products.estateplan.paywall.upsell.list.four"/>,
    },
    {
      ...LIST_ITEM_SETTINGS,
      children: <Translation translationKey="products.estateplan.paywall.upsell.list.five"/>,
    },
    {
      ...LIST_ITEM_SETTINGS,
      children: <Translation translationKey="products.estateplan.paywall.upsell.list.six"/>,
    },
  ];

  const handleProceed = () => {
    analytics.track( 'Touch - Plan Screen', { currentSelection: selectedProduct });

    push({
      pathname: location.pathname,
      search: generateQueryString({
        page: PURCHASE_PAGE,
        product: selectedProduct,
        document,
      }),
    });
  };

  const handleUpsell = () => {
    const ethosUrl = new URL( appConfig.ethos.baseUrl );
    ethosUrl.searchParams.set( EXTERNAL_ID_PARAM, getAnonymousID());

    // persist initial UTM params for Ethos
    const utmAttribution = window.localStorage.getItem( LOCAL_STORAGE_UTM_ATTRIBUTION );
    if( utmAttribution ) {
      try {
        const initialUtmParams = new URLSearchParams( atob( utmAttribution ));
        initialUtmParams.forEach(( value, key ) => {
          ethosUrl.searchParams.append( key, value );
        });
      } catch ( e ) {
      // do nothing
      }
    }

    const destination = ethosUrl.toString();

    analytics.track( 'Touch - Upsell Button', {
      currentSelection: selectedProduct,
      button: 'LifeInsuranceCheckout',
      destination,
    });

    window.open( destination );
  };


  useEffect(() => {
    analytics.track( 'View - Plan Screen' );
  }, []);

  const cardClasses = 'flex flex-col h-full justify-between gap-6 bg-white px-6 py-10 md:py-6 rounded hover:translate';

  return (
    <SegmentView
      full
      hidePrimaryAction
      onRetreat={ goBack }
      contentClassName="sm-only:bg-grey-400 overflow-y-auto pb-8 container--lg pt-24 sm-only:pt-18"
    >
      <div className="md:container--sm flex flex-col gap-8 md:gap-12">
        <h1 className="text-3xl md:text-5xl text-center font-headline"><Translation translationKey="products.estateplan.paywall.title" /></h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <Card
            className={ `${cardClasses} order-2 sm:order-1 sm:max-h-[270px]` }
            title={ isBasic
              ? <Translation translationKey="products.estateplan.paywall.basic.title"/>
              : <Translation translationKey="products.estateplan.paywall.pro.title"/>
            }
            primaryActionTheme="secondary"
            primaryActionLabel={
              isBasic
                ? <Translation translationKey="products.estateplan.paywall.basic.cta"/>
                : <Translation translationKey="products.estateplan.paywall.pro.cta"/>
            }
            primaryAction={ handleProceed }
            contentContainerClassName="h-full"
            primaryActionContainerClassName="sm:mt-auto"
          >
            <p>
              { isBasic
                ? <Translation translationKey="products.estateplan.paywall.basic.description"/>
                : <Translation translationKey="products.estateplan.paywall.pro.description"/>
              }
            </p>
            <p className="mt-4"><span className="text-forest bg-moss rounded-sm py-1 px-2 mt-1">{products[selectedProduct].price.displayValue}</span></p>
          </Card>
          <Card
            className={ `${cardClasses} border border-forest sm:order-2` }
            title={ isBasic
              ? <Translation translationKey="products.estateplan.paywall.upsell.basic.title"/>
              : <Translation translationKey="products.estateplan.paywall.upsell.pro.title"/>
            }
            primaryActionTheme="primary"
            primaryActionLabel={ isBasic
              ? <Translation translationKey="products.estateplan.paywall.upsell.basic.cta"/>
              : <Translation translationKey="products.estateplan.paywall.upsell.pro.cta"/>
            }
            primaryAction={ handleUpsell }
          >
            <img src={ getTranslation( 'products.estateplan.paywall.upsell.image' ) } alt="life insurance badge" className="absolute -top-7 md:-top-8 right-3 md:-right-8 w-20 md:w-24"/>
            <p className="my-4">
              <Translation
                translationKey="products.estateplan.paywall.upsell.description"
                dynamicStringValues={ [ heirsString ] }
              />
            </p>
            <List
              type="ul"
              uniqueKey="ethosLifeBenefits"
              itemClasses="mb-2 w-full text-xs"
              items={ listItems }
            />
            <p className="my-4"><span className="text-forest bg-moss rounded-sm py-1 px-2 mt-1">from $7/month</span></p>
          </Card>
        </div>
      </div>
    </SegmentView>
  );
}
