// Redux
import { useSelector } from '../store';
import invitationsSelector from '../store/selectors/invitationsSelector';

// Types
import Person from '../core-data-service/models/Person';
import Role from '../core-data-service/models/Role';
import Invitation from '../core-data-service/models/Invitation';
import PersonInterface from '../types/PersonInterface';
import RoleInterface from '../types/RoleInterface';
import isDefined from '../utils/isDefined';


const useExecutors = (): {
  executors: PersonInterface[];
  hasBackupExecutor: boolean;
  potentialExecutors: PersonInterface[];
  potentialNewExecutors: PersonInterface[];
  roles: RoleInterface[];
  uninvitedExecutorFirstNames: string[];
} => {

  const roles = useSelector( state =>
    state.roles.data
      .filter( role => role.name === Role.TYPE.EXECUTOR )
      .sort(( first, second ) => first.priority > second.priority ? 1 : -1 ),
  );

  const invitations = useSelector( invitationsSelector );

  const hasBackupExecutor = roles.length > 1;

  const uninvitedExecutorFirstNames = Object.values( roles.reduce(( acc, role ) => {
    const { grantee_id, invitation_id } = role;
    const invite = invitations.find( invitation => invitation.id === invitation_id );
    const name = invite?.invitee?.name;
    const hasNotBeenInvited = invite?.status === Invitation.STATUS.CREATED;
    if( name && hasNotBeenInvited && !acc[grantee_id]) {
      acc[grantee_id] = name;
    }
    return acc;
  }, {} as {[key: string]: string}));

  const people = useSelector( state => state.people.data );

  const executors: PersonInterface[] = roles
    .map( role => people.find( person => person.id === role.grantee_id ))
    .filter( isDefined );

  const potentialExecutors = people
    .filter( _person => {
      const person = new Person( _person );
      return Boolean( _person.relationship !== 'ME' && person.isAdult !== false );
    });

  const alreadyExecutors = Role.allPrioritized( Role.TYPE.EXECUTOR );
  const potentialNewExecutors = potentialExecutors.filter( person => !alreadyExecutors.find( role => role.grantee_id === person.id ));

  return {
    executors,
    hasBackupExecutor,
    potentialExecutors,
    potentialNewExecutors,
    roles,
    uninvitedExecutorFirstNames,
  };
};


export default useExecutors;
