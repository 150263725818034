import { ReactElement, useEffect } from 'react';

// Components
import EthosSimplifiedModal from './EthosSimplifiedModal';

// Types
import { GoalTypes } from '../../types/GoalInterface';

// Redux
import { useDispatch, useSelector } from '../../store';
import { patchGoal, postGoal } from '../../store/reducers/goalsReducer';
import userSelector from '../../store/selectors/userSelector';

// Routes
import { LOCAL_STORAGE_UTM_ATTRIBUTION } from '../../../core/constants';

// Utils
import analytics from '../../utils/analytics';
import { EXTERNAL_ID_PARAM, getAnonymousID } from '../../utils/anonymousID';


export default function LifeInsurance(): ReactElement{
  const dispatch = useDispatch();
  const user = useSelector( userSelector );


  // Set Life Insurance Goal when user is dropped directly into Life Insurance
  const lifeInsuranceGoal = useSelector( state => state.goals.data
    .find( goal => goal.type === GoalTypes['BUY INSURANCE']));
  useEffect(() => {
    if( !lifeInsuranceGoal?.id ){
      dispatch( postGoal({ type: GoalTypes['BUY INSURANCE'] }));
    } else {
      dispatch( patchGoal({
        id: lifeInsuranceGoal.id,
        type: GoalTypes['BUY INSURANCE'],
      }));
    }
  }, [ dispatch, lifeInsuranceGoal?.id ]);

  const buttonOnClick = ( baseApplicationUrl: string ) => {
    const applicationUrl = new URL( baseApplicationUrl );

    // persist initial UTM params for Ethos
    const utmAttribution = window.localStorage.getItem( LOCAL_STORAGE_UTM_ATTRIBUTION );
    if( utmAttribution ) {
      try {
        const initialUtmParams = new URLSearchParams( atob( utmAttribution ));

        initialUtmParams.forEach(( value, key ) => {
          applicationUrl.searchParams.append( key, value );
        });
      } catch ( e ) {
        // do nothing
      }
    }

    applicationUrl.searchParams.append( EXTERNAL_ID_PARAM, getAnonymousID());

    const destination = applicationUrl.toString();

    analytics.track( 'LifeApplicationStarted', { destination });

    // read cookie and append params
    window.open( destination, '_blank' );
  };

  return (
    <div className="flex fixed inset-0 bg-white">
      <EthosSimplifiedModal quoteButtonOnClick={ buttonOnClick } />
    </div>
  );
}
