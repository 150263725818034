import { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

// Components
import Form from '../../../../components/Form';
import SegmentView from '../../../../components/SegmentView';
import Translation from '../../../../components/Translation';
import AddressFields from '../../../../components/AddressFields';

// Hooks
import useBackstack from '../../../../hooks/useBackstack';
import useTranslations from '../../../../hooks/useTranslations';

// Redux
import { useDispatch, useSelector } from '../../../../store';
import { makeSnack } from '../../../../store/reducers/snackbarReducer';
import { isPendingPersonSave } from '../../../../store/selectors/peopleSelectors';
import { patchPerson } from '../../../../store/reducers/peopleReducer';
import userPersonSelector from '../../../../store/selectors/userPersonSelector';

// Types
import SanitizedFormData from '../../../../../core/types/SanitizedFormData';

// Routes
import { medical_consent_care_info } from '../../../../../core/routes';
import { getPersonByID } from '../../../../utils/people/getPersonByID';

// Forms
import { fieldRequired, zipMinLength } from '../../../../formValidators';

export default function MedicalConsentAddressForm(): ReactElement{
  const { wardId } = useParams<{ wardId: string}>();
  const { goBack } = useBackstack( medical_consent_care_info.get({ wardId }));
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const { push } = useHistory();
  const child = useSelector( getPersonByID( wardId ));
  const userPerson = useSelector( userPersonSelector );
  const userPersonAddress = userPerson?.address;

  const addressTitle = <Translation translationKey="screen.youraddress.title.enter" />;
  const submitButtonTitle = userPersonAddress ? '(button)update' : '(button)done';
  const defaultValues ={ ...userPersonAddress };

  const requiredAddressFields={
    street: {
      required: fieldRequired( getTranslation( 'address.error.missingstreet' )),
    },
    city: {
      required: fieldRequired( getTranslation( 'address.error.missingcity' )),
    },
    state: {
      required: fieldRequired( getTranslation( 'address.error.missingstate' )),
    },
    zip: {
      minLength: zipMinLength( getTranslation( 'error.missing.zip' )),
      required: fieldRequired( getTranslation( 'error.missing.zip' )),
    } };

  const { register, setValue, getValues, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues,
  });

  const useFormProps = {
    register,
    setValue,
    getValues,
    errors,
  };

  const onSubmit: SubmitHandler<SanitizedFormData> = _data => {
    const data = { address: { ..._data } };
    dispatch( patchPerson({ id: userPerson?.id, ...data, onSuccess: () => {
      dispatch( makeSnack({ message: getTranslation( 'form.actions.update.success', [ child?.name || '' ]) }));
      push( medical_consent_care_info.get({ wardId }));
    },
    onError: ( error: string ) => {
      dispatch( makeSnack({ message: error, theme: 'error' }));
    } }));
  };

  const isRequestPending = useSelector( isPendingPersonSave );

  return (
    <Form handleSubmit={ handleSubmit } onSubmit={ onSubmit }>
      <SegmentView onRetreat={ goBack }
        primaryActionLabel={ <Translation translationKey={ submitButtonTitle }/> }
        primaryActionButtonType="submit"
        title={ addressTitle }
        primaryActionButtonIsPending={ isRequestPending }
      >
        <AddressFields { ...useFormProps } required={ requiredAddressFields }/>
      </SegmentView>
    </Form>
  );
}
