import { useState, useCallback } from 'react';
import { MODAL_TRANSITION_DURATION_MS } from '../../core/constants';
import analytics from '../utils/analytics';

type NestedCallbackType = ( cb?: CallbackType )=> void
type CallbackType = ()=> void
type FlowReturn = {
  currentIndex: number;
  retreat: NestedCallbackType;
  proceed: NestedCallbackType;
  setCurrentIndex: ( idx: number, cb?: CallbackType )=> void;
}

const useFlow = ( initialIndex = 0 ): FlowReturn => {
  const [ currentIndex, setCurrentIndex ] = useState( initialIndex );

  const handleSetCurrentIndex = ( idx: number, cb?: ()=> void ) => {
    setCurrentIndex( idx );
    // event handlers by default will receieve event as first arg
    cb && cb instanceof Function && setTimeout( cb, MODAL_TRANSITION_DURATION_MS );
  };

  /**
   * Set current index state to currentIndex - 1
   * and report to analytics
   */
  const retreat = useCallback<NestedCallbackType>( cb => {
    const index = currentIndex - 1;
    handleSetCurrentIndex( index, cb );
    analytics.page({ index });
  }, [ currentIndex ]);

  /**
   * Set current index state to currentIndex + 1
   * and report page change to analytics
   */
  const proceed = useCallback<NestedCallbackType>( cb => {
    const index = currentIndex + 1;
    handleSetCurrentIndex( index, cb );
    analytics.page({ index });
  }, [ currentIndex ]);

  return { currentIndex, retreat, proceed , setCurrentIndex: handleSetCurrentIndex } ;
};

export default useFlow;
