import { ReactElement } from 'react';
import CommaSeparatedStrings from '../../../components/CommaSeparatedStrings';
import { DocumentSectionStates } from '../../../components/DocumentSectionOverview';
import Person from '../../../core-data-service/models/Person';
import useTranslations from '../../../hooks/useTranslations';
import { TranslationKeys } from '../../../store/initial/translations';
import PersonInterface from '../../../types/PersonInterface';

interface Props {
  className?: string;
  beneficiaries: PersonInterface[] | undefined;
  sectionState: DocumentSectionStates;
  propertyLabel: string;
  fallbackTranslationKey: TranslationKeys;
}

export default function WillPropertySummary({ className = '', beneficiaries, sectionState, propertyLabel, fallbackTranslationKey }: Props ): ReactElement{
  const { getTranslation } = useTranslations();
  if( sectionState === 'default' ) {
    return <></>;
  }
  return (
    <div className={ className } >
      {sectionState === 'complete' && beneficiaries?.length ?
        <>
            You've left some {propertyLabel} to{' '}
          <CommaSeparatedStrings items={ beneficiaries.map( person => new Person( person ).firstName ) }/>
            .
        </> : getTranslation( fallbackTranslationKey )
      }
    </div>
  );
}
