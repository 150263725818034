import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { poa as poaRoute } from '../../../../core/routes';

import PowerGrantQuestions from './PowerGrantQuestions';
import { setUserAttributes } from '../../../store/reducers/userReducer';
import { useDispatch } from '../../../store';
import useUserAttributes from '../../../hooks/useUserAttributes';

export default function PoaPowers(): ReactElement{
  const { push } = useHistory();
  const dispatch = useDispatch();
  const {
    documents:{ poa },
  } = useUserAttributes();

  useEffect(
    ()=>{
      if( !poa.powersChapterStarted ) {
        dispatch( setUserAttributes({ 'app.powersChapterStarted': true }));
      }
    },[ dispatch ]);

  return (
    <div className="flex fixed inset-0 sm-only:bg-white bg-defaultBg">
      <PowerGrantQuestions onSuccess={ () => {
        dispatch( setUserAttributes({ 'app.powersChapterFinished': true, onSuccess: ()=>{
          push( poaRoute.get());
        } }));
      } }/>
    </div>
  );
}
