import { ReactElement } from 'react';

//  Components
import MedicalConsentChildListItem from './MedicalConsentChildListItem';

// Types
import PersonInterface from '../../../types/PersonInterface';


interface Props {
  minorChildren: PersonInterface[];
}

export default function MedicalConsentChildSummaryList({ minorChildren }: Props ): ReactElement{
  return (
    <>
      { minorChildren.map( ward => {
        return <MedicalConsentChildListItem key={ ward.id } className="mt-6 pt-8" ward={ ward } />;
      })}
    </>
  );
}
