import { ReactElement, ReactNode } from 'react';
import { SubmitHandler, useForm, UseFormOptions } from 'react-hook-form';

// Redux
import { useSelector } from '../../../../store';

// Components
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import WishesMultipartQuestion from './WishesMultipartQuestion';
import WishesRadioQuestion from './WishesRadioQuestion';

// Types
import SanitizedFormData from '../../../../../core/types/SanitizedFormData';
import { FormAnswersInterface, FormInterface } from '../../../../types/FormInterface';
import { Keyable } from '../../../../types/Keyable';
import { directivesSelector } from '../../../../store/selectors/directivesSelector';

// An array of IDs that should be cast as numbers
const INPUTS_TO_CAST = new Set([ 'number' ]);

export default function WishesStep({
  question,
  proceedLabel,
  handleDirectiveUpdate,
  retreatButton,
}: {
    question: FormInterface;
    proceedLabel: ReactNode;
    handleDirectiveUpdate: ( data: FormAnswersInterface )=> void;
    retreatButton?: ReactElement;
}): ReactElement{
  const { directives: existingDirective } = useSelector( directivesSelector );

  //@TODO don't love the cast here but not sure how else to get around this
  const defaultValues = question.input.type === 'multipart'
    ? existingDirective[question.id] as UseFormOptions['defaultValues']
    : { [question.id]: existingDirective[question.id] };

  const { register, setValue, getValues, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues,
  });

  const useFormProps = {
    register,
    setValue,
    errors,
    getValues,
  };

  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    if( !!data ) {
      const answer: Keyable = {};
      if( question.input.type === 'multipart' ) {
        // @TODO RHF valueAsNumber
        // For each key of "data", if the key is in the set, cast it!
        Object.keys( data ).forEach( key => {
          if( INPUTS_TO_CAST.has( key )) {
            Object.assign( data, {
              [key]: Number( data[key]),
            });
          }
        });
        answer[question.id] = data;
      } else {
        Object.assign( answer, data );
      }
      handleDirectiveUpdate( answer );
    }
  };

  return (
    <Form
      onSubmit={ onSubmit }
      handleSubmit={ handleSubmit }
      className="flex flex-col items-start gap-4 min-h-[400px] healthcare-directive"
    >
      { question.input.type === 'radio' &&
        <WishesRadioQuestion
          question={ question }
          { ...useFormProps }
        />
      }
      { question.input.type === 'multipart' &&
        <WishesMultipartQuestion
          question={ question }
          { ...useFormProps }
        />
      }
      <div className="flex mt-auto w-full">
        { retreatButton }
        <Button className="ml-auto" theme="primary" type="submit">{
          proceedLabel
        }</Button>
      </div>
    </Form>
  );
}
