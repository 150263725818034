import { useState } from 'react';

// hooks
import useQuery from './useQuery';

// Types / Models
import { PropertyType, PropertySubtype } from '../core-data-service/models/Property';
import PropertyInterface from '../types/Property/PropertyInterface';

interface IPropertyParams {
  category?: PropertyType;
  subtype?: PropertySubtype;
}

// Used to grab params off of property routes when creating or editing

export function usePropertyParams( property?: PropertyInterface ): IPropertyParams{
  const params = useQuery();
  const categoryParam = params.get( 'category' ) as PropertyType;
  const subtypeParam = params.get( 'subtype' ) as PropertySubtype;

  const [ category ] = useState<PropertyType|undefined>( categoryParam || property?.type );
  const [ subtype ] = useState<PropertySubtype|undefined>( subtypeParam || property?.subtype );

  return { category, subtype };
}
