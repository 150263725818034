import { RootState } from '..';
import PersonInterface from '../../types/PersonInterface';


export const userPersonSelector = ( state: RootState ): PersonInterface | undefined => {
  const person_id = state.user.data.person_id;
  return state.people.data.find( person => person.id === person_id );
};

export const isPendingPersonSave = ( state: RootState ): boolean => {
  return state.people.patchPerson.meta.requestStatus === 'pending';
};


export default userPersonSelector;
