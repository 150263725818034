import { ReactElement } from 'react';
import Person from '../core-data-service/models/Person';
import { TranslationKeys } from '../store/initial/translations';
import PersonInterface from '../types/PersonInterface';
import PersonIcon from '../views/People/PersonIcon';
import Translation from './Translation';

interface Props {
  person: PersonInterface;
  role: string;
  translationKey: TranslationKeys;
  buttonId?: string;
}

export default function PersonSelectedContainer({ person, role, translationKey,buttonId }: Props ): ReactElement{

  const { firstName } = new Person( person );

  return (
    <div className="mt-4 flex flex-1 flex-row justify-between items-start relative group p-4 rounded bg-moss">
      <div className="w-16">
        <PersonIcon
          className="aspect-w-1 aspect-h-1"
          photo_url={ person.photo_url }
          personName={ person.name }
          onImageLoadDuration={ 1 }
          alt="avatar"
        />
      </div>
      <div id={ buttonId } className="ml-4 flex-1 flex-col justify-center self-stretch flex">
        <span>
          <Translation translationKey={ translationKey } dynamicStringValues={ [ firstName, role.toLowerCase() ] } />
        </span>
      </div>
    </div>
  );
}
