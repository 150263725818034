import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActionContainer from '../../../../../components/ActionContainer';

// Components / Views
import SegmentView from '../../../../../components/SegmentView';
import Translation from '../../../../../components/Translation';
import AddButton from '../../../../../components/AddButton';
import PersonIcon from '../../../../People/PersonIcon';
import PeopleSelector from '../../../../../components/PeopleSelector';
import Button from '../../../../../components/Button';

// Routes
import {
  medical_consent_document as medicalConsentDocRoute,
} from '../../../../../../core/routes';

// Hooks
import useBackstack from '../../../../../hooks/useBackstack';
import { useDispatch, useSelector } from '../../../../../store';
import useTranslations from '../../../../../hooks/useTranslations';
import usePhysicians from '../../../../../hooks/usePhysicians';

// Selectors
import { getPersonByID } from '../../../../../utils/people/getPersonByID';

// Types
import Relationship from '../../../../../core-data-service/models/Relationship';
import PersonInterface from '../../../../../types/PersonInterface';
import Person from '../../../../../core-data-service/models/Person';

// Utils
import { toTitleCase } from '../../../../../../core/utils/stringHelpers';

// Redux
import { patchPerson } from '../../../../../store/reducers/peopleReducer';
import { makeSnack } from '../../../../../store/reducers/snackbarReducer';

export default function MedicalConsentDoctorManagement(): ReactElement{
  const [ isAddingDoctor, setIsAddingDoctor ] = useState( false );
  const { wardId } = useParams<{ wardId: string}>();
  const child = useSelector( getPersonByID( wardId ));
  const childName = child?.name || '';

  const { goBack } = useBackstack( medicalConsentDocRoute.get({ wardId }));

  const { getTranslation } = useTranslations();
  const dispatch = useDispatch();

  const { physicians, existingPhysicians, potentialPhysicians } = usePhysicians( wardId );

  if ( !child ) {
    return (
      <div>This child doesn't exist</div>
    );
  }

  const handlePersonSelected = ( id: PersonInterface['id']) => {
    setIsAddingDoctor( false );
    const selectedPhysician = physicians.find( physician => physician.id === id );
    const updatedClients = [ ...selectedPhysician?.clients || [], child ];
    dispatch( patchPerson({
      id,
      clients: updatedClients,
      onSuccess: () => {
        dispatch( makeSnack({ message: getTranslation( 'form.actions.update.success', [ child?.name || '' ]) }));
      },
      onError: ( error: string ) => {
        dispatch( makeSnack({ message: error, theme: 'error' }));
      },
    }));
  };

  const handleDoctorRemoved = ( id: PersonInterface['id']) => {
    const physician = existingPhysicians.find( physician => physician.id === id );
    if ( !physician ) {
      return;
    }
    const firstName = new Person( physician ).firstName;
    const removalTranslation = getTranslation( 'medicalconsent.banner.doctor.removed', [ firstName ]);
    dispatch( patchPerson({
      id,
      clients: physician.clients?.filter( client => client.id !== wardId ),
      onSuccess: () => {
        dispatch( makeSnack({ message: removalTranslation }));
      },
    }));
  };

  return (
    <SegmentView
      onRetreat={ goBack }
      title={ <Translation translationKey="medicalconsent.screen.doctors.title" dynamicStringValues={ [ childName ] }/> }
      hidePrimaryAction={ true }
    >

      <AddButton
        onClick={ ()=> setIsAddingDoctor( true ) }
        iconName={ 'addGuardian' }
        labelTranslationKey={ 'medicalconsent.button.doctor.add' }
      />

      {isAddingDoctor && <PeopleSelector
        isOpen
        people={ potentialPhysicians }
        relationshipType={ 'PHYSICIAN' }
        onSelect={ id => handlePersonSelected( id ) }
        onClose={ id => id ?
          handlePersonSelected( id ) :
          setIsAddingDoctor( false )
        }
        optionTypeLabel={ toTitleCase( Relationship.NAMES.PHYSICIAN ) }
      />}

      {
        existingPhysicians.map( physician => {
          const _physician = new Person( physician );
          return <ActionContainer
            headerClassName={ 'mt-6' }
            key={ physician.id }
            header={
              <div className="w-full">
                <div className="flex-1 flex flex-row">
                  <div className="w-16">
                    <PersonIcon
                      className="aspect-w-1 aspect-h-1"
                      photo_url={ physician.photo_url }
                      personName={ physician.name }
                      onImageLoadDuration={ 1 }
                      alt="avatar"
                    />
                  </div>
                  <div className="flex-1 flex flex-col justify-center">
                    <div className="self-stretch flex justify-between">
                      <div>
                        <span className="ml-4 whitespace-nowrap text-black font-bold">
                          { _physician.firstName }
                        </span>
                        <span className="ml-4 whitespace-nowrap mt-2 block">
                          { toTitleCase( physician.relationship ) }
                        </span>
                      </div>
                    </div>
                  </div>
                  <Button theme="clean-danger" onClick={ () => handleDoctorRemoved( physician.id ) }><Translation translationKey="(button)remove"/></Button>
                </div>
              </div>
            }
          />;
        })
      }
    </SegmentView>
  );
}

