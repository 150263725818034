import React, { ReactElement, ReactNode } from 'react';
export interface DashboardSignedInDocumentSectionProps {
  title?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const DashboardSignedInDocumentSection = ({ title, children, className }: DashboardSignedInDocumentSectionProps ): ReactElement => {
  return (

    <div className={ `${className}` }>
      <hr className="sm-only:-mx-8 border-t border-grey mb-8"/>
      <h2 className="mb-4 font-bold">{title}</h2>
      <div className="mb-8 grid md:grid-cols-2 gap-y-6 md:gap-x-12 md:gap-y-8">{children}</div>
    </div>
  );
};
