import { ReactElement } from 'react';

// components
import TextField, { TextFieldProps } from './TextField';

export default function PercentageField({
  label,
  register,
  name,
  className = '',
  setValue,
  errors,
  showLabel = false,
  normalize,
}: TextFieldProps ): ReactElement{
  return (
    <div className={ `PercentageField flex w-full relative ${className}` }>
      <TextField
        label={ label }
        register={ () => register({ required: true, pattern: /[0-9]?[0-9]?[0-9]/ }) }
        name={ name }
        setValue={ setValue }
        showLabel={ showLabel }
        type="number"
        step="1"
        inputClasses="pr-6"
        className="flex-1"
        errors={ errors }
        normalize={ normalize }
      />
      <div className="absolute right-0 bottom-8 text-black-50 text-5xl">%</div>
    </div>
  );
}
