import { ReactElement } from 'react';

// Components
import Button from '../../components/Button';
import Translation from '../../components/Translation';

// Hooks
import useProperties from '../../hooks/useProperties';
import useTranslations from '../../hooks/useTranslations';

// Redux
import { useDispatch } from '../../store';
import { showContextualModal } from '../../store/reducers/modalReducer';

// Types
import { PropertyDetailFormInterface } from '../../types/Property/PropertyDetailFormInterface';

interface Props {
  onDelete: PropertyDetailFormInterface['onDelete'];
  property: PropertyDetailFormInterface['property'] ;
}

export default function PropertyFormActions({ onDelete, property }: Props ): ReactElement{
  const {
    isFetchingProperties,
    isPendingPropertySave,
    isPendingPropertyDelete,
  } = useProperties();

  const { getTranslation } = useTranslations();
  const dispatch = useDispatch();

  const isPending = isFetchingProperties || isPendingPropertySave || isPendingPropertyDelete;
  const propertyName = property?.name || property?.address?.street || '';

  return (
    <div className="flex justify-between mt-14">
      {
        !!property ?
          <Button type="button"
            theme="clean-danger"
            isPending={ isPendingPropertyDelete }
            onClick={
              () => {
                dispatch( showContextualModal({
                  description: getTranslation( 'form.actions.remove.confirmation.v2', [ propertyName ]),
                  dismissLabel: getTranslation( '(button)cancel' ),
                  confirmLabel: getTranslation( '(button)remove' ),
                  onConfirm: () => {
                    onDelete();
                  },
                }));
              }
            }
          >
            <Translation translationKey="form.actions.remove.label"/>
          </Button>
          :
          <div></div>
      }
      <Button type="submit" isPending={ isPending }>
        <Translation translationKey={ !!property ? 'form.actions.update.label' : 'form.actions.create.label' }/>
      </Button>
    </div>
  );
}
