import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import RoleInterface, { AxiosRoleResponse, AxiosRolesResponse, NonNormalizedRoleInterface } from '../../types/RoleInterface';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

// Data
import initialState from '../initial/roles';
import { AxiosResponse } from 'axios';
import { RootState } from '..';
import { RoleTypes } from '../../core-data-service/models/Role';
import { updateInvitation } from './invitationsReducer';

interface IFetchRoles {
  onSuccess?: ( response: AxiosRolesResponse )=> void;
}

interface IFetchRole {
  onSuccess?: ( response: AxiosRoleResponse )=> void;
  id: string;
}

interface IDeleteRole {
  onSuccess?: ( response: AxiosResponse )=> void;
  id: string;
}

type IPostRole = {
  onSuccess?: ( response: AxiosRoleResponse )=> void;
  grantee_id: string;
  name: RoleTypes;
  ward?: {
    id: string;
  };
}

interface IPatchRole extends IPostRole {
  id: string;
}

const normalize = ({ grantee, ward, invitation, ...data }: NonNormalizedRoleInterface ) => Object({
  ...data,
  grantee_id: grantee.id,
  ward_id: ward?.id || null,
  invitation_id: invitation?.id || null,
});

export const fetchRole = createAsyncThunk(
  'roles/fetchRole',
  async({ id, onSuccess }: IFetchRole, thunkAPI ) => {

    const url = `/v2/roles/${id}`;
    return WebClientRequest
      .get( url )
      .then(( response: AxiosRoleResponse ) => {
        thunkAPI.dispatch( updateRole( normalize( response.data.data )));
        thunkAPI.dispatch( updateInvitation(  response.data.data.invitation ));
        onSuccess && onSuccess( response );
      });
  },
);


export const fetchRoles = createAsyncThunk(
  'roles/fetchRoles',
  async( args: IFetchRoles | void, thunkAPI ) => {
    const url = '/v2/roles';
    return WebClientRequest
      .get( url )
      .then(( response: AxiosRolesResponse ) => {
        const roles = response.data.data.map( normalize );
        thunkAPI.dispatch( updateRoles( roles ));
        // This could be a bug, but `fetchRoles` is typically dispatched on init
        // response.data.data.map( role => thunkAPI.dispatch( updateInvitation( role.invitation )));
        args && args.onSuccess && args.onSuccess( response );
      });
  },
);


export const patchRole = createAsyncThunk(
  'roles/patchRole',
  async({ id, onSuccess, ...data }: IPatchRole, thunkAPI ) => {

    const url = `/v2/roles/${id}`;
    return WebClientRequest
      .patch( url, data )
      .then(( response: AxiosRoleResponse ) => {
        thunkAPI.dispatch( updateRole( normalize( response.data.data )));
        thunkAPI.dispatch( updateInvitation(  response.data.data.invitation ));
        onSuccess && onSuccess( response );
      });
  },
);


export const postRole = createAsyncThunk<
  WebClientRequest,
  IPostRole,
  {state: RootState}
>(
  'roles/postRole',
  async({ onSuccess, grantee_id, name, ward, ..._data }: IPostRole, thunkAPI ) => {

    /**
     * find the max `role.priority` within context of given role and +1 so that
     * the newly created role will be at the end of the list priority wise
     */
    let priority = -1;
    thunkAPI.getState().roles.data
      .forEach( role => {
        if( role.name === name ){
          if( !ward || ward.id === role.ward_id ){
            priority = Math.max( priority, role.priority );
          }
        }
      });
    priority++;

    const data = { name, ward, priority, ..._data };
    const url = `/v2/people/${grantee_id}/roles`;
    return WebClientRequest
      .post( url, data )
      .then(( response: AxiosRoleResponse ) => {
        thunkAPI.dispatch( updateRole( normalize( response.data.data )));
        thunkAPI.dispatch( updateInvitation(  response.data.data.invitation ));
        onSuccess && onSuccess( response );
      });
  },
);

export const deleteRole = createAsyncThunk<
  WebClientRequest,
  IDeleteRole,
  {state: RootState}
>(
  'roles/deleteRole',
  async({ onSuccess, id }: IDeleteRole, thunkAPI ) => {

    const url = `/v2/roles/${id}`;
    return WebClientRequest
      .delete( url )
      // empty 204
      .then(( response: AxiosResponse ) => {
        thunkAPI.dispatch( destroyRole({ id }));
        onSuccess && onSuccess( response );
      });
  },
);

/**
 * Allows users to replace an existing or empty role with a new or a person
 * previously assigned a different priority for this role
 *
 * Replicating logic from tomorrow-ios/Source/RoleAPICalls.swift -> ReplaceRole
 *
 * TODO account for this once we actually need it
 *
export const replaceRole = createAsyncThunk<
  Promise<void>,
  IPatchRole,
  {state: RootState}
>(
  'roles/replaceRole',
  async({ onSuccess, id, grantee_id }: IPatchRole, thunkAPI ) => {

    const roleToReplace = thunkAPI.getState().roles.data
      .find( role => role.id === id );

    const ward = { id: roleToReplace?.ward_id };

    // The new role will be set to the lowest priority,
    // unless it already has a priority
    const priority = typeof roleToReplace?.priority === 'number'
      ? roleToReplace.priority
      : Role.getNextRolePriority( roleToReplace.name, roleToReplace.ward_id );

    // Search for existing role of same name, grantee, ward
    const roleToUpdate = thunkAPI.getState().roles.data
      .find( role =>
        role.name === roleToReplace?.name &&
        role.ward_id === roleToReplace.ward_id &&
        role.grantee_id === grantee_id,
      );

    const _onSuccess = response => {
      // Delete the role we are replacing, then update the store.
      thunkAPI.dispatch( deleteRole({ id }));

      // Accepting a role may add a person to your Tomorrow.
      thunkAPI.dispatch( fetchPeople());

      onSuccess instanceof Function && onSuccess( response );
    };

    // Do one of two things after the roleToReplace is deleted...
    // ... if the replacing role already exists. Then update its priority.
    if( !!roleToUpdate ){
      thunkAPI.dispatch( patchRole({
        id: roleToUpdate.id,
        priority,
        onSuccess: _onSuccess,
      }));

    // ... if the new person wasn't already assigned to this role
    // than create a new role for them
    } else {
      thunkAPI.dispatch( postRole({
        grantee_id,
        name: roleToReplace?.name,
        ward,
        priority,
        onSuccess: _onSuccess,
      }));
    }
  },
);
 */

const rolesSlice = createSlice({
  name: 'roles',
  initialState,

  reducers: {

    updateRoles( roles, action: PayloadAction<RoleInterface[]> ){
      if( roles.data.length === 0 ){
        roles.data = action.payload;
      } else {
        action.payload.forEach( role => {
          const idx = roles.data.findIndex( xRole => xRole.id === role.id );
          if( idx >= 0 ){
            roles.data[idx] = role;
          } else {
            roles.data.push( role );
          }
        });
      }
    },

    updateRole( roles, action: PayloadAction<RoleInterface> ){
      const payload = action.payload;
      const idx = roles.data.findIndex( role => role.id === payload.id );
      if( idx >= 0 ){
        roles.data[idx] = payload;
      } else {
        roles.data.push( payload );
      }
    },

    destroyRole( roles, action: PayloadAction<{id: string}> ){
      const payload = action.payload;
      const idx = roles.data.findIndex( role => role.id === payload.id );
      if( idx >= 0 ){
        roles.data.splice( idx, 1 );
      }
    },

  },

  extraReducers: builder => {

    // fetchRole.<status>
    builder.addCase( fetchRole.pending, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.fetchRole.meta = meta;
    });
    builder.addCase( fetchRole.fulfilled, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.fetchRole.meta = meta;
    });
    builder.addCase( fetchRole.rejected, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.fetchRole.meta = meta;
    });

    // fetchRoles.<status>
    builder.addCase( fetchRoles.pending, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.fetchRoles.meta = meta;
    });
    builder.addCase( fetchRoles.fulfilled, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.fetchRoles.meta = meta;
    });
    builder.addCase( fetchRoles.rejected, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.fetchRoles.meta = meta;
    });

    // patchRole.<status>
    builder.addCase( patchRole.pending, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.patchRole.meta = meta;
    });
    builder.addCase( patchRole.fulfilled, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.patchRole.meta = meta;
    });
    builder.addCase( patchRole.rejected, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.patchRole.meta = meta;
    });

    // postRole.<status>
    builder.addCase( postRole.pending, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.postRole.meta = meta;
    });
    builder.addCase( postRole.fulfilled, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.postRole.meta = meta;
    });
    builder.addCase( postRole.rejected, ( roles, action ) => {
      const { arg, ...meta } = action.meta;
      roles.postRole.meta = meta;
    });
  },
});


export const { updateRoles, updateRole, destroyRole } = rolesSlice.actions;

export default rolesSlice.reducer;
