import { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Components
import WillReview, { WillReviewFlowPages } from '../../../views/Documents/Will/WillReview';

// Hooks
import useRenderInsuranceUpsell from '../../../hooks/useRenderInsuranceUpsell';
import useUserAttributes from '../../../hooks/useUserAttributes';

// Redux
import { useSelector } from '../../../store';
import productsSelector from '../../../store/selectors/productsSelector';

// Routes
import { checkout, will, will_review } from '../../../../core/routes';

// Utils
import { generateQueryString } from '../../../utils/urlUtils';
import useWillSectionStatus from '../../../hooks/useWillSectionStatus';
import { DOCUMENTS } from '../../../core-data-service/models/Document';

export default function WillReviewRouter(): ReactElement{

  const { basic } = useSelector( productsSelector );
  const { canCompleteWill } = useWillSectionStatus();

  return (
    <Switch>
      {!canCompleteWill    && <Redirect to={ will.get() } />}

      {!basic.hasPurchased && <Redirect to={{ pathname: checkout.path, search: generateQueryString({ document: DOCUMENTS.will }) }} />}

      <Route path={ will_review.path }>
        <Route exact path={ will_review.path } component={ GetWillReviewComponent } /> :
        <WillReview />
      </Route>
    </Switch>
  );
}

const GetWillReviewComponent = (): ReactElement => {
  const { documents: { will: { hasViewedFinishedWillVideo, hasViewedFinishedWillUpsell } } } = useUserAttributes();
  const shouldRenderLifeInsuranceUpsell = useRenderInsuranceUpsell();

  if( hasViewedFinishedWillVideo ) {
    if( shouldRenderLifeInsuranceUpsell && !hasViewedFinishedWillUpsell ) {
      return <Redirect to={{ pathname: will_review.path, search: `${generateQueryString({ page: WillReviewFlowPages.upsell })}` }} />;
    }
    return <Redirect to={{ pathname: will_review.path, search: `${generateQueryString({ page: WillReviewFlowPages.completed })}` }} />;
  } else {
    return <></>;
  }
};
