import { AxiosResponse } from 'axios';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components / Views
import Flow from '../../components/Flow';
import SendCodeForm from './SendCodeForm';
import VerifyCodeForm from './VerifyCodeForm';

// Hooks
import useQuery from '../../hooks/useQuery';
import useFlow from '../../hooks/useFlow';

// Types
import LocationState from '../../types/LocationState';

// Routes
import { dashboard as dashboardRoute } from '../../../core/routes';
import { LOCAL_STORAGE_REFERRAL_ID } from '../../../core/constants';
import { patchUser } from '../../store/reducers/userReducer';
import { useDispatch } from '../../store';
import PersonInterface from '../../types/PersonInterface';

interface AuthenticateProps {
  onSuccess: ()=> void;
  setParentFlowIndex: ( index: number )=> void;
}

/**
 * Authentication flow
 */
const Authenticate: FC<AuthenticateProps> = ({ onSuccess, setParentFlowIndex }) => {

  const { push } = useHistory();
  const search = useQuery();
  const location = useLocation<LocationState>();
  const { currentIndex, setCurrentIndex } = useFlow();
  const locationState = { background: location.state?.background || dashboardRoute.get() };
  const dispatch = useDispatch();

  const sendCodeOnSuccess = () => {
    setCurrentIndex( 1 );
    push({
      pathname: location.pathname,
      search: search.toString(),
      state: locationState,
    });
  };

  const verifyCodeOnSuccess = () => {
    // if we have an ethos referral, consume and patch user
    const referral_id = localStorage.getItem( LOCAL_STORAGE_REFERRAL_ID );
    if ( referral_id ) {
      localStorage.removeItem( LOCAL_STORAGE_REFERRAL_ID );
      dispatch( patchUser({
        referral_id,
        onSuccess: ( response: AxiosResponse<{ data: { person: PersonInterface } }> ) => {
          const { name, address ,email } = response.data.data.person;
          const isDataCompleted = name && address.zip && email && address.state;
          if ( isDataCompleted ) {

            setParentFlowIndex( -1 );
          } else {
            onSuccess();
          }
        },
        onError: () => {
          // if there was a referral error, simply continue forward in the onboarding flow
          onSuccess();
        },
      }));
    } else {
      onSuccess();
    }
  };

  return (

    <Flow currentIndex={ currentIndex }>
      <SendCodeForm onSuccess={ sendCodeOnSuccess } />
      <VerifyCodeForm onCancel={ () => setCurrentIndex( 0 ) } onSuccess={ verifyCodeOnSuccess } />
    </Flow>

  );
};


export default Authenticate;
