import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Routes
import {
  trustees as trusteesRoute,
  trust as trustRoute,
} from '../../../core/routes';

// Components
import Flow from '../../components/Flow';
import SegmentView from '../../components/SegmentView';
import TrusteesSummary from './TrusteesSummary';
import InvitesList from '../Invites/InvitesList';

// Hooks
import useChangingTrustWarningModal from '../../hooks/useChangingTrustWarningModal';
import useFlowQueryParams from '../../hooks/useFlowQueryParams';
import useTranslations from '../../hooks/useTranslations';
import useUserAttributes from '../../hooks/useUserAttributes';

// Redux
import { useDispatch } from '../../store';
import { setUserAttributes } from '../../store/reducers/userReducer';

// Types
import Role from '../../core-data-service/models/Role';

export const trusteePages = {
  invites: 'invites',
};

export default function Trustees(): ReactElement{

  const { push } = useHistory();

  const { getTranslation } = useTranslations();

  const {
    documents: {
      trust: {
        hasStartedTrusteesChapter,
        hasSignedTrust,
      },
    },
  }= useUserAttributes();

  const dispatch =  useDispatch();

  const currentIndex = useFlowQueryParams([ ...Object.values( trusteePages ) ]);


  useEffect(() => {
    if ( !hasStartedTrusteesChapter ) {
      dispatch( setUserAttributes({
        'app.trusteesChapterStarted': true,
      }));
    }
  }, [ currentIndex, dispatch, hasStartedTrusteesChapter ]);

  useChangingTrustWarningModal( !!hasSignedTrust );

  return (
    <div className="flex fixed inset-0 bg-white">
      <Flow currentIndex={ currentIndex }>

        {/* 0 = default/undefined (no page query param) */}
        <TrusteesSummary />

        {/* 1 = ...?page=invites */}
        <SegmentView
          onRetreat={ () => push( trusteesRoute.get()) }
          onPrimaryAction={ () => push(
            trustRoute.get(),
          ) }
          primaryActionLabel={ getTranslation( '(button)done' ) }
          title={ `Invite your ${Role.TYPE.TRUSTEE.toLowerCase()}s.` }
        >
          <InvitesList roleType={ Role.TYPE.TRUSTEE }/>
        </SegmentView>
      </Flow>
    </div>
  );
}
