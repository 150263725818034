import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getFutureDate } from '../../../core/utils/datetimeUtils';
import GoalInterface, { AxiosGoalResponse, AxiosGoalsResponse, GoalType }from '../../types/GoalInterface';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

// Data
import initialState from '../initial/goals';

// Types
import BaseReduction from '../../types/BaseReduction';

type IFetchGoals = BaseReduction<AxiosGoalsResponse>

interface IFetchGoal extends BaseReduction<AxiosGoalResponse> {
  id: GoalInterface['id'];
}

interface IPostGoal extends BaseReduction<AxiosGoalResponse>{
  type: GoalType;
  expected_at?: Date;
}

interface IPatchGoal extends IPostGoal {
  id: GoalInterface['id'];
}

export const fetchGoals = createAsyncThunk(
  'goals/fetchGoals',
  async( arg: IFetchGoals | void, thunkAPI ) => {
    const url = '/v1/goals';
    return WebClientRequest
      .get( url )
      .then(( response: AxiosGoalsResponse ) => {
        thunkAPI.dispatch( updateGoals( response.data.data ));
        arg && arg.onSuccess && arg.onSuccess( response );
      });
  },
);

export const fetchGoal = createAsyncThunk(
  'goals/fetchGoal',
  async({ id, onSuccess }: IFetchGoal, thunkAPI ) => {
    const url = `/v1/goals/${id}`;
    return WebClientRequest
      .get( url )
      .then(( response: AxiosGoalResponse ) => {
        thunkAPI.dispatch( updateGoal( response.data.data ));
        onSuccess && onSuccess( response );
      });
  },
);



export const patchGoal = createAsyncThunk(
  'goals/patchGoal',
  async({ id, onSuccess, ...data }: IPatchGoal, thunkAPI ) => {

    const url = `/v1/goals/${id}`;
    return WebClientRequest
      .patch( url, data )
      .then(( response: AxiosGoalResponse ) => {
        thunkAPI.dispatch( updateGoal(  response.data.data ));
        onSuccess && onSuccess( response );
      });
  },
);


export const postGoal = createAsyncThunk(
  'goals/postGoal',
  async({ onSuccess, type, expected_at }: IPostGoal, thunkAPI ) => {

    const data = {
      expected_at: expected_at || getFutureDate( 7 ),
      type,
    };

    const url = '/v1/goals';
    return WebClientRequest
      .post( url, data )
      .then(( response: AxiosGoalResponse ) => {
        thunkAPI.dispatch( updateGoal(  response.data.data ));
        onSuccess && onSuccess( response );
      });
  },
);


const goalsSlice = createSlice({
  name: 'goals',
  initialState,

  reducers: {

    updateGoals( goals, action: PayloadAction<GoalInterface[]> ){
      if( goals.data.length === 0 ){
        goals.data = action.payload;
      } else {
        action.payload.forEach( newGoal => {
          const idx = goals.data.findIndex( existingGoal => existingGoal.id === newGoal.id );
          if( idx >= 0 ){
            goals.data[idx] = newGoal;
          } else {
            goals.data.push( newGoal );
          }
        });
      }
    },

    updateGoal( goals, action: PayloadAction<GoalInterface> ){
      const newGoal = action.payload;
      const idx = goals.data.findIndex( existingGoal => existingGoal.id === newGoal.id );
      if( idx >= 0 ){
        goals.data[idx] = newGoal;
      } else {
        goals.data.push( newGoal );
      }
    },
  },

  extraReducers: builder => {

    // fetchGoals.<status>
    builder.addCase( fetchGoals.pending, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.fetchGoals.meta = meta;
    });
    builder.addCase( fetchGoals.fulfilled, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.fetchGoals.meta = meta;
    });
    builder.addCase( fetchGoals.rejected, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.fetchGoals.meta = meta;
    });

    // fetchGoal.<status>
    builder.addCase( fetchGoal.pending, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.fetchGoal.meta = meta;
    });
    builder.addCase( fetchGoal.fulfilled, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.fetchGoal.meta = meta;
    });
    builder.addCase( fetchGoal.rejected, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.fetchGoal.meta = meta;
    });

    // patchGoal.<status>
    builder.addCase( patchGoal.pending, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.patchGoal.meta = meta;
    });
    builder.addCase( patchGoal.fulfilled, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.patchGoal.meta = meta;
    });
    builder.addCase( patchGoal.rejected, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.patchGoal.meta = meta;
    });

    // postGoal.<status>
    builder.addCase( postGoal.pending, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.postGoal.meta = meta;
    });
    builder.addCase( postGoal.fulfilled, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.postGoal.meta = meta;
    });
    builder.addCase( postGoal.rejected, ( goals, action ) => {
      const { arg, ...meta } = action.meta;
      goals.postGoal.meta = meta;
    });
  },
});


export const { updateGoals, updateGoal } = goalsSlice.actions;

export default goalsSlice.reducer;
