import { TranslationKeys } from '../store/initial/translations';
import { DocumentSectionState } from './useWillSectionStatus';
import useAttorneyInFact from './useAttorneyInFact';
import useUserAttributes from './useUserAttributes';
import { SectionStates } from '../components/DocumentSectionOverview';

export const PoaSections = {
  attorneyInFact: 'attorneyInFact',
  powers: 'powers',
  specialInstructions: 'specialInstructions',
  review: 'review',
} as const;

export const SPECIAL_INSTRUCTIONS = 'instructions';
export const GENERAL_AUTHORITY = 'general_authority';

type PoaSection = keyof typeof PoaSections;

export const PoaSectionTitleKey = ( key: PoaSection ): TranslationKeys => {
  switch ( key ){
  case PoaSections.attorneyInFact:
    return 'poa.chapter.title.attorneyinfact';
  case PoaSections.powers:
    return 'poa.chapter.title.powers';
  case PoaSections.specialInstructions:
    return 'poa.chapter.title.specialinstructions';
  case PoaSections.review:
    return 'poa.chapter.title.printandsign';
  }
};
export interface PoaSectionStates {
    attorneyInFact: DocumentSectionState;
    powers: DocumentSectionState;
    specialInstructions: DocumentSectionState;
    review: DocumentSectionState;
}
type PoaSectionStatus = {
    sectionStates: PoaSectionStates;
    next: PoaSection;
    canCompletePoa: boolean;
    hasCompletedPoa: boolean;
}
const usePoaSectionStatus = (): PoaSectionStatus => {
  const { attorneysInFact } = useAttorneyInFact();

  const { documents: { poa } } = useUserAttributes();
  const canCompletePoa = attorneysInFact.length > 0 && !!poa.powersChapterFinished;
  const hasCompletedPoa =  canCompletePoa && !!poa.poaDocumentViewed;

  const sectionStates: PoaSectionStates = {
    attorneyInFact: getAttorneySectionState( !!attorneysInFact.length, poa.attorneyChapterStarted ),
    powers: { state: poa.powersChapterFinished ? SectionStates.complete : poa.powersChapterStarted? SectionStates.review : SectionStates.default },
    specialInstructions: { state: poa.instructionsChapterStarted ? SectionStates.complete : SectionStates.default },
    review: { state: poa.poaDocumentViewed ? SectionStates.complete:  SectionStates.default, isGated: !canCompletePoa },
  };

  const next = getNextSection( sectionStates );
  return { sectionStates, next, hasCompletedPoa, canCompletePoa };
};

const getAttorneySectionState = ( hasAttorneyInFact: boolean, hasStartedChapter?: boolean ): DocumentSectionState => {
  if( hasAttorneyInFact ) {
    return {
      state: SectionStates.complete,
    };
  } else if( hasStartedChapter ) {
    return {
      state: SectionStates.warning,
      message: { translationKey: 'poa.chapter.error.attorneyinfact.missing' },
    };
  } else {
    return {
      state: SectionStates.default,
    };
  }
};

const getNextSection = ( sectionsState: PoaSectionStatus['sectionStates']): PoaSection => {
  const sectionKeys = Object.keys( sectionsState ) as ( keyof PoaSectionStatus['sectionStates'])[];

  return sectionKeys.filter( section => sectionsState[section].state !== SectionStates.complete )[0] || PoaSections.review;
};

export default usePoaSectionStatus;

