import { useSelector } from '../store';
import { UserAttributes } from '../types/UserInterface';

export interface IUseUserAttributes {
  hasChildren: UserAttributes['app.hasChildren'];
  hasDomesticPartnership: UserAttributes['app.hasDomesticPartnership'];
  hasSpouse: UserAttributes['app.hasSpouse'];
  hasUnderageChildren: UserAttributes['app.hasUnderageChildren'];
  incomeBelowThreshold: UserAttributes['financial.income.below_threshold'];
  hasSeenExecutorsInviteModal: UserAttributes['app.hasSeenExecutorsInviteModal'];
  hasSeenGuardiansInviteModal: UserAttributes['app.hasSeenGuardiansInviteModal'];
  hasSeenTrusteesInviteModal: UserAttributes['app.hasSeenTrusteesInviteModal'];
  hasSeenChildrenHeirsLifeModal: UserAttributes['app.hasSeenChildrenHeirsLifeModal'];
  networth: UserAttributes['financial.networth'];
  hasGeneratedSpousePerkCode: UserAttributes['app.hasGeneratedSpousePerkCode'];

  documents: {
    disability: {
      hasStartedIntroduction: UserAttributes['app.disability.hasStartedIntroduction'];
      hasCompletedCoverage: UserAttributes['app.disability.hasCompletedCoverage'];
      hasCompletedApplication: UserAttributes['app.disability.hasCompletedApplication'];
      hasCompletedPayment: UserAttributes['app.disability.hasCompletedPayment'];
      hasCompletedConsent: UserAttributes['app.disability.hasCompletedConsent'];
    };
    will: {
      hasConfirmedWillBasics: UserAttributes['app.willHasConfirmedBasics'];
      hasViewedHeirsVideo: UserAttributes['app.viewedHeirsVideo'];
      hasViewedGuardiansVideo: UserAttributes['app.viewedGuardiansVideo'];
      shouldBeReviewed: UserAttributes['app.willNeedsReview'];
      willHeirsChapterStarted: UserAttributes['app.willHeirsChapterStarted'];
      willHeirsChapterNeedsReview: UserAttributes['app.willHeirsChapterNeedsReview'];
      hasStartedGuardiansChapter: UserAttributes['app.guardiansChapterStarted'];
      guardiansChapterNeedsReview: UserAttributes['app.guardiansChapterNeedsReview'];
      hasViewedValuablesVideo: UserAttributes['app.viewedValuablesVideo'];
      willValuablesFinished: UserAttributes['app.willValuablesFinished'];
      petsChapterStarted: UserAttributes['app.petsChapterStarted'];
      willRealEstateFinished: UserAttributes['app.willRealEstateFinished'];
      executorsChapterStarted: UserAttributes['app.executorsChapterStarted'];
      hasViewedExecutorsVideo: UserAttributes['app.viewedExecutorsVideo'];
      willPreviewViewed: UserAttributes['app.willPreviewViewed'];
      hasViewedFinishedWillVideo: UserAttributes['app.viewedFinishedWillVideo'];
      hasViewedFinishedWillUpsell: UserAttributes['app.viewedFinishedWillUpsell'];
      willIntroFinished: UserAttributes['app.willIntroFinished'];
    };
    poa: {
      hasStartedPOADocument: UserAttributes['app.poaDocumentStarted'];
      poaAddressConfirmed: UserAttributes['app.poaAddressConfirmed'];
      attorneyChapterStarted: UserAttributes['app.attorneyChapterStarted'];
      powersChapterStarted: UserAttributes['app.powersChapterStarted'];
      powersChapterFinished: UserAttributes['app.powersChapterFinished'];
      instructionsChapterStarted: UserAttributes['app.instructionsChapterStarted'];
      poaDocumentViewed: UserAttributes['app.poaDocumentViewed'];
    };
    healthcareDirective: {
      hasStartedDocument: UserAttributes['app.healthcareDirectiveDocumentStarted'];
      hasConfirmedAddress: UserAttributes['app.healthcareDirectiveAddressConfirmed'];
      hasStartedAgentChapter: UserAttributes['app.healthcareDirectiveAgentChapterStarted'];
      hasStartedWishesChapter: UserAttributes['app.healthcareDirectiveWishesChapterStarted'];
      hasViewedDocument: UserAttributes['app.healthcareDirectiveDocumentViewed'];
    };
    trust: {
      hasConfirmedTrustBasics: UserAttributes['app.trustHasConfirmedBasics'];
      shouldBeReviewed: UserAttributes['app.trustNeedsReview'];
      hasViewedIntroVideo: UserAttributes['app.viewedTrustIntroVideo'];
      hasViewedPreview: UserAttributes['app.trustPreviewViewed'];
      hasSignedPourOverWill: UserAttributes['app.trustHasSignedPourOverWill'];
      hasStartedTrusteesChapter: UserAttributes['app.trusteesChapterStarted'];
      hasViewedTrusteeBackupAlert: UserAttributes['app.trusteeBackupReminderAlertViewed'];
      hasStartedTrustHeirsSection: UserAttributes['app.trustHeirsChapterStarted'];
      hasDelayedPayment: UserAttributes['app.trustHasDelayedPayment'];
      hasSignedTrust: UserAttributes['app.trustHasSignedTrust'];
      pourOverSignedDate: UserAttributes['app.trustSignedPourOverWillDate'];
      trustSignedDate: UserAttributes['app.trustSignedTrustDate'];
      hasViewedTrustWrapUpVideo: UserAttributes['app.trustWrapUpVideoViewed'];
      hasViewedTrustAccountsVideo: UserAttributes['app.viewedTrustAccountsVideo'];
      hasViewedTrusteeVideo:  UserAttributes['app.viewedTrusteeVideo'];
      hasViewedPourOverWillVideo:  UserAttributes['app.viewedPourOverWillVideo'];
    };
  };

}

export default function useUserAttributes(): IUseUserAttributes{
  const userAttributes = useSelector( state => state.user.data.attributes );

  return {
    hasChildren: userAttributes['app.hasChildren'],
    hasDomesticPartnership: userAttributes['app.hasDomesticPartnership'],
    hasSpouse: userAttributes['app.hasSpouse'],
    hasUnderageChildren: userAttributes['app.hasUnderageChildren'],
    incomeBelowThreshold: userAttributes['financial.income.below_threshold'],
    hasSeenExecutorsInviteModal: userAttributes['app.hasSeenExecutorsInviteModal'],
    hasSeenGuardiansInviteModal: userAttributes['app.hasSeenGuardiansInviteModal'],
    hasSeenTrusteesInviteModal: userAttributes['app.hasSeenTrusteesInviteModal'],
    hasSeenChildrenHeirsLifeModal: userAttributes['app.hasSeenChildrenHeirsLifeModal'],
    networth: userAttributes['financial.networth'],
    hasGeneratedSpousePerkCode: userAttributes['app.hasGeneratedSpousePerkCode'],

    documents: {
      disability: {
        hasStartedIntroduction: userAttributes[ 'app.disability.hasStartedIntroduction' ],
        hasCompletedCoverage: userAttributes[ 'app.disability.hasCompletedCoverage' ],
        hasCompletedApplication: userAttributes[ 'app.disability.hasCompletedApplication' ],
        hasCompletedPayment: userAttributes[ 'app.disability.hasCompletedPayment' ],
        hasCompletedConsent: userAttributes[ 'app.disability.hasCompletedConsent' ],
      },
      will: {
        hasConfirmedWillBasics: userAttributes['app.willHasConfirmedBasics'],
        hasViewedHeirsVideo: userAttributes['app.viewedHeirsVideo'],
        hasViewedGuardiansVideo: userAttributes['app.viewedGuardiansVideo'],
        shouldBeReviewed: userAttributes['app.willNeedsReview'],
        willHeirsChapterStarted: userAttributes['app.willHeirsChapterStarted'],
        willHeirsChapterNeedsReview: userAttributes['app.willHeirsChapterNeedsReview'],
        hasStartedGuardiansChapter: userAttributes['app.guardiansChapterStarted'],
        guardiansChapterNeedsReview: userAttributes['app.guardiansChapterNeedsReview'],
        hasViewedValuablesVideo: userAttributes['app.viewedValuablesVideo'],
        willValuablesFinished: userAttributes['app.willValuablesFinished'],
        hasViewedFinishedWillVideo: userAttributes['app.viewedFinishedWillVideo'],
        petsChapterStarted: userAttributes['app.petsChapterStarted'],
        willRealEstateFinished: userAttributes['app.willRealEstateFinished'],
        executorsChapterStarted: userAttributes['app.executorsChapterStarted'],
        hasViewedExecutorsVideo: userAttributes['app.viewedExecutorsVideo'],
        willPreviewViewed: userAttributes['app.willPreviewViewed'],
        hasViewedFinishedWillUpsell: userAttributes['app.viewedFinishedWillUpsell'],
        willIntroFinished: userAttributes['app.willIntroFinished'],
      },
      poa: {
        hasStartedPOADocument: userAttributes['app.poaDocumentStarted'],
        poaAddressConfirmed: userAttributes['app.poaAddressConfirmed'],
        attorneyChapterStarted: userAttributes['app.attorneyChapterStarted'],
        powersChapterStarted : userAttributes['app.powersChapterStarted'],
        powersChapterFinished: userAttributes['app.powersChapterFinished'],
        instructionsChapterStarted: userAttributes['app.instructionsChapterStarted'],
        poaDocumentViewed: userAttributes['app.poaDocumentViewed'],
      },
      healthcareDirective: {
        hasStartedDocument: userAttributes['app.healthcareDirectiveDocumentStarted'],
        hasConfirmedAddress: userAttributes['app.healthcareDirectiveAddressConfirmed'],
        hasStartedAgentChapter: userAttributes['app.healthcareDirectiveAgentChapterStarted'],
        hasStartedWishesChapter: userAttributes['app.healthcareDirectiveWishesChapterStarted'],
        hasViewedDocument: userAttributes['app.healthcareDirectiveDocumentViewed'],
      },
      trust: {
        hasConfirmedTrustBasics: userAttributes['app.trustHasConfirmedBasics'],
        shouldBeReviewed: userAttributes['app.trustNeedsReview'],
        hasViewedIntroVideo: userAttributes['app.viewedTrustIntroVideo'],
        hasViewedPreview: userAttributes['app.trustPreviewViewed'],
        hasSignedPourOverWill: userAttributes['app.trustHasSignedPourOverWill'],
        hasStartedTrusteesChapter: userAttributes['app.trusteesChapterStarted'],
        hasViewedTrusteeBackupAlert: userAttributes['app.trusteeBackupReminderAlertViewed'],
        hasStartedTrustHeirsSection: userAttributes['app.trustHeirsChapterStarted'],
        hasDelayedPayment: userAttributes['app.trustHasDelayedPayment'],
        hasSignedTrust: userAttributes['app.trustHasSignedTrust'],
        pourOverSignedDate: userAttributes['app.trustSignedPourOverWillDate'],
        trustSignedDate: userAttributes['app.trustSignedTrustDate'],
        hasViewedTrustWrapUpVideo: userAttributes['app.trustWrapUpVideoViewed'],
        hasViewedTrustAccountsVideo: userAttributes['app.viewedTrustAccountsVideo'],
        hasViewedTrusteeVideo:  userAttributes['app.viewedTrusteeVideo'],
        hasViewedPourOverWillVideo:  userAttributes['app.viewedPourOverWillVideo'],
      },
    },
  };
}
