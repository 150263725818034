const poaRoutes = [
  /**
   * Documents - Power of Attorney intro
   */
  {
    key: 'poa_intro',
    name: 'Power of Attorney Intro',
    path: '/documents/poa/intro',
    template: 'index.html',
  },

  /**
   * Documents - Power of Attorney status
   */
  {
    key: 'poa',
    name: 'Your Power of Attorney',
    path: '/documents/poa',
    template: 'index.html',
  },

  /**
   * Documents - Power of Attorney, Attorney-In-Fact
   */
  {
    key: 'poa_attorney_in_fact',
    name: 'Your Attorney In Fact',
    path: '/documents/poa/attorneys-in-fact',
    template: 'index.html',
  },

  /**
   * Documents - Power of Attorney Powers
   */
  {
    key: 'poa_powers',
    name: 'Your Power of Attorney powers',
    path: '/documents/poa/powers',
    template: 'index.html',
  },

  /**
   * Documents - Power of Attorney Powers
   */
  {
    key: 'poa_special_instructions',
    name: 'Your Power of Attorney instructions',
    path: '/documents/poa/instructions',
    template: 'index.html',
  },

  /**
   * Documents - Power of Attorney Review
   */
  {
    key: 'poa_review',
    name: 'Review Your Power of Attorney',
    path: '/documents/poa/review',
    template: 'index.html',
  },
];

export default poaRoutes;
