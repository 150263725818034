import { LocationDescriptorObject } from 'history';
import { SEARCH_PARAM_BACKGROUND } from '../../core/constants';
import { Keyable } from '../types/Keyable';

const _encode = ( v: string ) => {
  if ( v === undefined || v === null ) {
    return '';
  }
  return encodeURIComponent( v );
};

export const generateQueryString = ( params: Keyable ): string => Object
  .keys( params )
  .map( key => ( _encode( key ) + '=' + _encode( params[key])))
  .join( '&' );


export const getNextPageFromSearchParams = ( search: URLSearchParams ): LocationDescriptorObject | undefined => {
  const nextPathname = search.get( 'next' );
  if( !nextPathname ) {
    return undefined;
  }

  const result = {
    pathname: nextPathname,
    state: {},
  };
  const backgroundPathname = search.get( SEARCH_PARAM_BACKGROUND );
  if( backgroundPathname ) {
    result.state = {
      background: {
        pathname: backgroundPathname,
      },
    };
  }
  return result;
};
