import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Components
import AddButton from '../../../components/AddButton';
import InsuranceHelper from '../../../components/InsuranceHelper';
import PropertySubtypePicker from '../PropertySubtypePicker';
import RealEstateListView from './RealEstateListView';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';

// Hooks
import useBackstack from '../../../hooks/useBackstack';
import useTranslations from '../../../hooks/useTranslations';

// Routes
import {
  will as willRoute,
  properties as propertiesRoute,
} from '../../../../core/routes';

// Redux
import { useDispatch, useSelector } from '../../../store';
import { setUserAttributes } from '../../../store/reducers/userReducer';
import Property, { PropertySubtype, PropertyType } from '../../../core-data-service/models/Property';

// Utils
import analytics from '../../../utils/analytics';
import { REAL_ESTATE_URL_NAMESPACE as urlNamespace } from '../../../../core/constants';
import useRenderInsuranceUpsell from '../../../hooks/useRenderInsuranceUpsell';

export default function RealEstate(): ReactElement{

  const { goBack } = useBackstack( willRoute.get());
  const dispatch = useDispatch();
  const { push } =  useHistory();
  const { getTranslation } = useTranslations();
  const userAttributes = useSelector( state => state.user.data.attributes );
  const hasStartedRealEstate = userAttributes['app.willRealEstateFinished'];
  const shouldShowInsuranceHelper = useRenderInsuranceUpsell();

  const [ category, setCategory ] = useState<PropertyType|undefined>();

  const handleSetSubtype = ( subtype: PropertySubtype ) => {
    push({
      pathname: propertiesRoute.get({ id: 'create', urlNamespace }),
      search: `?category=${category}&subtype=${subtype}`,
    });
  };

  // Set user has started on enter
  useEffect(() => {
    if( !hasStartedRealEstate ) {
      dispatch( setUserAttributes({ 'app.willRealEstateFinished': true }));
    }
  }, [ hasStartedRealEstate, dispatch ]);

  return (
    <div className="flex fixed inset-0 bg-white">
      <SegmentView
        title={ <Translation translationKey="will.chapter.title.realestate"/> }
        onRetreat={ goBack }
        primaryActionLabel={ getTranslation( 'products.will.chapter.actions.finish' ) }
        onPrimaryAction={ () => {
          analytics.track( 'Touch - Finish Real Estate' );
          goBack();
        } }
      >
        <p className="text-black-70 mb-8">
          <Translation translationKey="realestate.subheader"/>
        </p>

        <AddButton
          className="mb-8"
          onClick={ () => {
            setCategory( Property.TYPE.REAL_ESTATE );
          } }
          iconName="property"
          labelTranslationKey="screen.accounts.button.add"
        />

        <RealEstateListView />

        { shouldShowInsuranceHelper && <InsuranceHelper
          className="mb-4"
          eventProperties={{ button: 'RealEstateSummary' }}
          primaryActionLabel={ <Translation translationKey="upsell.messagebubble.realestate.button" /> }
          body={ <Translation translationKey="upsell.messagebubble.realestate.message"/> }
        />}

        <PropertySubtypePicker
          onClose={ () => setCategory( undefined ) }
          isOpen={ !!category }
          category={ category }
          onItemSelect={ handleSetSubtype }
        />
      </SegmentView>
    </div>
  );
}
