import { ReactElement, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';

// Utils
import { isFetchingPeople, familySelector } from '../../../store/selectors/peopleSelectors';
import { people as peopleRoute, person as personRoute } from '../../../../core/routes';
import { sortPeople } from '../../People';
import { useSelector } from '../../../store';

// Components
import FadeIn from '../../../components/FadeIn';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { DashboardSectionBlock } from '../DashboardSection';
import PersonIcon from '../../People/PersonIcon';
import Person from '../../../core-data-service/models/Person';
import Scroll from '../../../components/Scroll';
import useMissingPersonData from '../../../hooks/useMissingPersonData';
import Helper from '../../../components/Helper';
import { useHistory } from 'react-router';
import Translation from '../../../components/Translation';
import AddPersonButton from '../../../components/AddPersonButton';

export const FamilySectionBlock = ({ className = '' }: {className?: string}): ReactElement => {
  const familyMembers = useSelector( familySelector );
  const peopleSorted = useMemo(() => familyMembers.sort( sortPeople ), [ familyMembers ]);
  const isFetching = useSelector( isFetchingPeople );
  const { hasMissingData } = useMissingPersonData();
  const location = useLocation();
  const { push } = useHistory();

  return(
    <DashboardSectionBlock
      mainLabel="Family"
      ctaLabel="View all"
      ctaURL={ peopleRoute.get() }
      className={ className }
    >
      <div className="bg-white rounded px-2 pt-4">
        <Scroll
          direction="x"
          className="-mx-2 px-2 pb-2"
        >
          {
            isFetching ? <div className="flex-1 flex flex-wrap items-center justify-center"><LoadingSpinner /></div> :
              peopleSorted
                .map(( person, idx ) => {
                  const _person = new Person( person );
                  return (
                    <Link key={ person.id }
                      to={{
                        pathname: personRoute.get({ id: person.id }),
                        state: { background: location },
                      }}
                      className="inline-block"
                    >
                      <FadeIn key={ person.id } duration={ idx }>
                        <div className="flex flex-col items-center">
                          <div className="w-14 h-14 mx-2 mb-2">
                            <PersonIcon
                              className="h-14 w-14"
                              photo_url={ person.photo_url }
                              personName={ person.name }
                              alt={ person.name || '' }
                            />
                          </div>
                          <span className="text-black-70 block text-center">{_person.firstName}</span>
                        </div>
                      </FadeIn>
                    </Link>
                  );
                },
                )
          }
          <AddPersonButton className="mx-2" />
        </Scroll>
        {hasMissingData &&
          <Helper
            className={ 'md:w-1/2 mt-4' }
            body={ <Translation translationKey={ 'alert.document.missingdata.title' }/> }
            theme={ 'error' }
            primaryActionLabel={ <Translation translationKey={ 'alert.document.missingdata.button' }/> }
            primaryAction={ ()=>push( peopleRoute.get()) }
          />
        }
      </div>
    </DashboardSectionBlock>
  );
};


export default FamilySectionBlock;
