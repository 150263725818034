import { PropertyType } from './../core-data-service/models/Property';
import { TranslationKeys } from './../store/initial/translations/index';
import Property from '../core-data-service/models/Property';
import { useSelector } from '../store';
import PropertyInterface from '../types/Property/PropertyInterface';

interface ValuablesCategory {
  translationKey: TranslationKeys;
  value: PropertyType;
}

interface UseValuables {
  valuables: PropertyInterface[];
  valuablesCategories: ValuablesCategory[];
}

export default function useValuables(): UseValuables{

  const valuables = useSelector( state => state.properties.data
    .filter( property => Property.VALUABLE_TYPES.includes( property.type )));

  const types = Object.values( Property.VALUABLE_TYPES );

  const valuablesCategories = types
    .filter( type => Property.CLASSIFICATION[type] === Property.CLASSIFICATION_TANGIBLE )
    .map( type => ({ translationKey: Property.DISPLAY_KEY[type], value: type }));

  return {
    valuables,
    valuablesCategories,
  };
}
