import { ReactElement } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Components
import Form from '../../../components/Form';
import Translation from '../../../components/Translation';
import AddressFields from '../../../components/AddressFields';
import SegmentView from '../../../components/SegmentView';

// Redux
import { useDispatch, useSelector } from '../../../store';
import { patchPerson } from '../../../store/reducers/peopleReducer';
import { makeSnack } from '../../../store/reducers/snackbarReducer';
import userPersonSelector from '../../../store/selectors/userPersonSelector';
import { isPendingPersonSave } from '../../../store/selectors/peopleSelectors';

// Hooks
import useBackstack from '../../../hooks/useBackstack';
import useUserAttributes from '../../../hooks/useUserAttributes';

// Types
import SanitizedFormData from '../../../../core/types/SanitizedFormData';

// Routes
import { dashboard as dashboardRoute, healthcare_directive } from '../../../../core/routes';
import { fieldRequired, zipMinLength } from '../../../formValidators';
import useTranslations from '../../../hooks/useTranslations';
import { setUserAttributes } from '../../../store/reducers/userReducer';
import { useHistory } from 'react-router-dom';
import SelectField from '../../../components/SelectField';

const DirectiveAddress = (): ReactElement => {
  const dispatch = useDispatch();
  const { getTranslation }=useTranslations();
  const userPerson = useSelector( userPersonSelector );
  const { hasConfirmedAddress } = useUserAttributes().documents.healthcareDirective;
  const { push } = useHistory();

  const {
    formState: { errors },
    handleSubmit,
    getValues,
    register,
    setValue,
  } = useForm({
    defaultValues: {
      gender: userPerson?.gender,
      street: userPerson?.address.street,
      street2: userPerson?.address.street2,
      city: userPerson?.address.city,
      state: userPerson?.address.state,
      zip: userPerson?.address.zip,
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
  });

  const useFormProps = {
    errors,
    getValues,
    register,
    setValue,
  };

  const useFormPropsSelect = {
    ...useFormProps,
    required: {
      value: true,
      message: 'Please select a gender',
    },
  };

  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    const { gender, ...address } = data;
    dispatch( patchPerson({
      id: userPerson?.id,
      gender,
      address,
      onSuccess: () => {
        dispatch( makeSnack({ message: 'Personal details confirmed' }));
        if( !hasConfirmedAddress ) {
          dispatch( setUserAttributes({ 'app.healthcareDirectiveAddressConfirmed': true }));
        }
        push( healthcare_directive.get());
      },
    }));
  };

  const { goBack } = useBackstack( dashboardRoute.get());
  const requiredAddressFields={
    street: {
      required: fieldRequired( getTranslation( 'address.error.missingstreet' )),
    },
    city: {
      required: fieldRequired( getTranslation( 'address.error.missingcity' )),
    },
    state: {
      required: fieldRequired( getTranslation( 'address.error.missingstate' )),
    },
    zip: {
      minLength: zipMinLength( getTranslation( 'error.missing.zip' )),
      required: fieldRequired( getTranslation( 'error.missing.zip' )),
    } };

  const isPendingConfirmation = useSelector( isPendingPersonSave );

  return <Form onSubmit={ onSubmit } handleSubmit={ handleSubmit }>
    <SegmentView
      title={ <Translation translationKey="screen.healthcaredirective.persondetails.title" /> }
      onRetreat={ goBack }
      retreatIcon={ hasConfirmedAddress ? 'arrow-left' : 'close' }
      primaryActionLabel={ <Translation translationKey="(button)confirm" /> }
      primaryActionButtonType="submit"
      primaryActionButtonIsPending={ isPendingConfirmation }
    >

      <p className="font-bold text-black mb-6">{ getTranslation( 'account.signup.gender.title' ) }</p>
      <Translation translationKey={ 'healthcaredirective.persondetails.gender.subtitle' } />

      <SelectField
        className="pb-8 mt-6 mb-8 border-b border-black-20"
        { ...useFormPropsSelect }
        label={ getTranslation( 'profile.gender.title' ) }
        name="gender"
        uniqueId="gender"
        showLabel={ false }
        options={
          [
            { label: getTranslation( 'account.signup.name.fields.label.male' ), value: 'MALE' },
            { label: getTranslation( 'account.signup.name.fields.label.female' ), value: 'FEMALE' },
            { label: getTranslation( 'account.signup.name.fields.label.unspecified' ), value: 'UNSPECIFIED' },
          ]
        }
      />

      <AddressFields
        { ...useFormProps }
        required={ requiredAddressFields }
      />
    </SegmentView>
  </Form>;
};

export default DirectiveAddress;
