import { FC, useCallback, useEffect } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';



export interface PhotoDropzoneProps {
  onDrop: DropzoneOptions['onDrop'];
  shouldOpenFileDialog: boolean;
  setShouldOpenFileDialog: ( shouldOpenFileDialog: boolean )=> void;
}


const PhotoDropzone: FC<PhotoDropzoneProps> = ({
  onDrop = console.log,  // eslint-disable-line no-console
  children,
  shouldOpenFileDialog,
  setShouldOpenFileDialog,
}) => {

  const {
    getRootProps,
    getInputProps,
    // isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
    // isFileDialogActive,
    open,
  } = useDropzone({
    accept: 'image/*,image/heic,image/heif',
    multiple: false,
    onDrop: useCallback( onDrop, [ onDrop ]),
  });

  const opacity = isDragActive ? 'opacity-50' : '';

  // Handle "open" commands given from parent
  useEffect(() => {
    if( shouldOpenFileDialog ){
      open();
      setShouldOpenFileDialog( false );
    }
  }, [ shouldOpenFileDialog, setShouldOpenFileDialog, open ]);

  return(
    <div { ...getRootProps() }>
      <input { ...getInputProps() } />
      <div className={ `${opacity} relative flex items-center justify-center text-center` }>
        <span className="absolute">{
          isDragAccept
            ?'👍'
            : isDragReject
              ? '👎'
              : ''
        }</span>
        { children }
      </div>
    </div>
  );
};


export default PhotoDropzone;
