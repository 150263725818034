import React, { ReactElement, createRef, useRef } from 'react';
import { UseFormMethods } from 'react-hook-form';
import inputMask from '../utils/inputMask';
import { sanitizeFieldArray } from './Form';


/**
 * The delimiter for the inputs MUST === `.` in order to take advantage of
 * react-hook-form's magic dot/bracket notation for nested form fields.
 * https://react-hook-form.com/api#register
 *
 * For example when setting the name of a `CodeField` to `code` we produce
 * an array of inputs whose names are `code.0, code.1` etc.
 * When we submit the form, the form values object will look like this:
 *
 * {
 *    'someOtherField': 'foobar',
 *    'code': ['4', '2', '0', '9', '1', '1'],
 * }
 *
 * Our `Form` component's `sanitize` method now handles this field value of
 * type === `Array`, by joining them into a single string.
 *
 * @warning `defaultValues` must be set on the form init to avoid a bug when
 * calling the form's reset() method. Basically sometimes after reset the first
 * input change is not caught by the onChange handler, which can cause some
 * unexpected behavior. https://fantashit.com/working-with-react-hook-form/
 * Something like this will work - code: Array( CODE_FIELD_LENGTH ).fill( '' )
 */
export const DELIMETER = '.';

interface CodeProps {
  className?: string;
  formState: UseFormMethods['formState'];
  length: number;
  name: string;
  placeholder?: string;
  register: UseFormMethods['register'];
  setValue: UseFormMethods['setValue'];
  watch: UseFormMethods['watch'];
}

export default function CodeField({
  className = '',
  formState,
  length = 6,
  name,
  register,
  setValue,
  watch,
}: CodeProps ): ReactElement{

  // Current sanitized field value
  const currentValue = sanitizeFieldArray( watch( name ));
  const maxFocus = currentValue?.length || 0;

  // create refs
  const inputRefs = useRef( Array( length ).fill( createRef()));

  // Utilities
  const getIndex = ( name: string ): number => parseInt( name.split( '.' )[1]);
  const getName = ( index: number ): string => `${name}${DELIMETER}${index}`;

  // Navigation
  const maxIdx = length - 1;
  const selectAll = ( input: HTMLInputElement ) =>
    setTimeout(() => input.setSelectionRange( 0, input.value.length, 'backward' ), 1 );
  const focusOn = ( index: number ) => {
    const input = inputRefs.current[index];
    input.focus();
    selectAll( input );
  };
  const proceed = ( index: number ) => focusOn( Math.min( index + 1, maxIdx ));
  const retreat = ( index: number ) => focusOn( Math.max( index - 1, 0 ));

  // Handle removing character, so no empty cells in middle of code
  const spreadValues = (): void => {
    const dataArray = currentValue.split( '' );
    inputRefs.current.forEach(( input, idx ) =>
      setValue( input.name, dataArray[idx] || '' ));
  };


  const handlePaste = ( e: React.ClipboardEvent<HTMLInputElement> ) => {
    e.preventDefault();
    const pastedValue = inputMask.code( e.clipboardData.getData( 'text' ));

    if( pastedValue ) {
      const dataArray = pastedValue.trim().split( '' );
      inputRefs.current.forEach(( ref,i ) => setValue( ref.name, dataArray[i]));
      inputRefs.current[maxIdx].focus();
    }
  };

  // move along the code as values are entered
  const handleChange = ( e: React.FormEvent<HTMLInputElement> ) => {
    const { value, maxLength, name: inputName } = e.target as HTMLInputElement;
    const index = getIndex( inputName );

    // set value
    setValue( inputName, inputMask.code( value ));
    spreadValues();


    if( value.length >= maxLength ) {
      proceed( index );
    }
  };

  // force focus to first empty cell
  const handleFocus = ( e: React.FocusEvent ) => {
    const { name } = e.target as HTMLInputElement;
    const index = getIndex( name );
    if( maxFocus >= 0 && index > maxFocus ){
      focusOn( maxFocus );
    }
  };

  // handle keypress events
  const handleKeyDown = ( e: React.KeyboardEvent ) => {
    const input = e.target as HTMLInputElement;
    const { value, name: inputName, selectionStart, maxLength } = input;
    const index = getIndex( inputName );
    const isCursorAtBeg = selectionStart === 0;
    const isCursorAtEnd = selectionStart === maxLength;
    const isValueEmpty = value.length === 0;
    const isKeyPressDigit = !/\D/.test( e.key );

    // set value of current cell if cursor at beginning of cell
    if( !isValueEmpty && isCursorAtBeg && isKeyPressDigit ){
      setValue( inputName, e.key );
      return setTimeout(() => proceed( index ), 10 );
    }

    // set value of next cell if cursor at end of cell
    if( isCursorAtEnd && isKeyPressDigit ){
      setValue( getName( index + 1 ), e.key );
      return proceed( index );
    }

    // remove previous cell value on backspace in empty cell
    if( e.key === 'Backspace' && isValueEmpty ) {
      setValue( getName( index - 1 ), '' );
      return retreat( index );
    }

    // remove next cell value on delete if cursor at end of cell
    if( e.key === 'Delete' && isCursorAtEnd ){
      setValue( getName( index + 1 ), '' );
      return spreadValues();
    }

    if( e.key === 'ArrowLeft' && isCursorAtBeg ){
      return retreat( index );
    }

    if( e.key === 'ArrowRight' ){
      return proceed( index );
    }
  };

  // select all on mouse click
  const handleMouseUp = ( e: React.MouseEvent ) => {
    const input = e.target as HTMLInputElement;
    selectAll( input );
  };

  return (
    <div className={ `flex ${className}` }>
      {Array.from( Array( length )).map(( _,i ) => {
        const id = getName( i );
        /**
         * @a11y ARIA: tab role - since we we are not allowing focus past the
         * value of `maxFocus` - we need to also disable tabbing to an input
         * element that is not focusable, so the user can skip to the next item
         * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role#best_practices
         */
        const tabIndex = i > currentValue.length ? -1 : 0;
        return (
          <input
            className="
              codeField
              text-center
              w-10
              rounded-md
              text-2xl
              outline-none
              focus:outline-none
              border-initialFieldBorder
              focus:shadow-inputFocus
              focus:border-forest
              focus:ring-0
              select-all
              px-0
            "
            type="text"
            name={ id }
            key={ id }
            step="1"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={ i === 0 }
            maxLength={ 1 }
            pattern="\d*"
            inputMode="numeric"
            autoComplete="off"
            ref={ ref  => {
              if( ref ) {
                register( ref, { required: true, maxLength: 1, pattern: /^-?[0-9]\d*\.?\d*$/ });
                inputRefs.current[i] = ref;
              }
            } }
            onChange={ handleChange }
            onPaste={ handlePaste }
            onKeyDown={ handleKeyDown }
            onFocus={ handleFocus }
            onMouseUp={ handleMouseUp }
            tabIndex={ tabIndex }
          />
        );
      })}
    </div>
  );
}
