import React, { ReactElement } from 'react';
import Button, { ButtonTheme } from './Button';

interface ActionContainerProps {
  children?: React.ReactNode;
  header?: React.ReactNode;
  headerClassName?: string;
  primaryAction?: ()=> void;
  primaryActionId?: string;
  buttonId?: string;
  primaryActionLabel?: string | ReactElement;
  primaryActionShouldFillContainer?: boolean;
  primaryActionTheme?: ButtonTheme;
  className?: string;
}

export default function ActionContainer({
  children,
  header,
  headerClassName = '',
  primaryAction,
  primaryActionId,
  primaryActionLabel = 'Edit',
  primaryActionShouldFillContainer = true,
  primaryActionTheme = 'clean',
  className = '',
  buttonId,
}: ActionContainerProps ): ReactElement{
  return (
    <div
      id={ primaryActionId }
      className={ `
        ${className}
        ${primaryActionShouldFillContainer ? 'relative' : ''}
      ` }
    >
      <div className={ `flex justify-between items-start ${headerClassName}` }>
        {header ? header : <div></div>}
        {primaryAction &&
          <Button
            id={ buttonId }
            className={ `${primaryActionShouldFillContainer ? 'button--fill' : ''} ` }
            small
            theme={ primaryActionTheme }
            onClick={ primaryAction }
          >
            {primaryActionLabel}
          </Button>
        }
      </div>
      {children && children}
    </div>
  );
}
