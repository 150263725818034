import { ReactElement } from 'react';

// Components
import InvitesListItem from './InvitesListItem';

// Hooks
import useExecutors from '../../hooks/useExecutors';
import useGuardians from '../../hooks/useGuardians';
import useTrustees from '../../hooks/useTrustees';

// Types / Utils
import { RoleTypes } from '../../core-data-service/models/Role';

// Models
import Role from '../../core-data-service/models/Role';

type InvitesListProps = {
  roleType?: RoleTypes;
}

export type combinedRole = {
  primary?: string[];
  secondary?: string[];
  executor?: number;
  trustee?: number;
};

export default function InvitesList({
  roleType,
}: InvitesListProps ): ReactElement{
  const { roles: executorRoles } = useExecutors();
  const { rolesGroupedByGrantee } = useGuardians();
  const { roles: trusteeRoles } = useTrustees();

  const combinedRoles: {
    [key: string]: combinedRole;
  } = {};

  if( !roleType || roleType === Role.TYPE.GUARDIAN ) {
    Object.entries( rolesGroupedByGrantee ).forEach( entry => {
      const [ grantee_id, groupings ] = entry;
      const { primary, secondary } = groupings;
      combinedRoles[grantee_id] = {};
      combinedRoles[grantee_id]['primary'] = primary;
      combinedRoles[grantee_id]['secondary'] = secondary;
    });
  }

  if ( !roleType || roleType === Role.TYPE.EXECUTOR ) {
    executorRoles.forEach(( role,idx ) => {
      const { grantee_id } = role;
      if ( !combinedRoles[grantee_id]) {
        combinedRoles[grantee_id] = {};
      }
      combinedRoles[grantee_id]['executor'] = idx;
    });
  }

  if( !roleType || roleType === Role.TYPE.TRUSTEE ) {
    trusteeRoles.forEach(( role, idx ) => {
      const { grantee_id } = role;
      if ( !combinedRoles[grantee_id]) {
        combinedRoles[grantee_id] = {};
      }
      combinedRoles[grantee_id]['trustee'] = idx;
    });
  }

  const combinedRolesArray = Object.entries( combinedRoles );

  return (
    <ul>
      {
        combinedRolesArray.map(( combinedRole, idx ) => {
          const [ grantee_id, groupings ] = combinedRole;

          return (
            <InvitesListItem
              key={ idx }
              granteeId={ grantee_id }
              combinedRole={ groupings }
            />
          );
        })
      }
    </ul>
  );
}

