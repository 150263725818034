export const ENV_DEVELOPMENT = 'development';
export const ENV_INTEGRATION = 'integration';
export const ENV_STAGING = 'staging';
export const ENV_PRODUCTION = 'production';

export const VALUABLES_URL_NAMESPACE = 'valuables';
export const REAL_ESTATE_URL_NAMESPACE = 'real-estate';
export const ACCOUNTS_URL_NAMESPACE = 'accounts';
export const PETS_URL_NAMESPACE = 'pets';

export const LOCAL_STORAGE_DELETED_ACCOUNT = 'accountDeleted';
export const LOCAL_STORAGE_SIGNED_OUT = 'signedOut';
export const LOCAL_STORAGE_UTM_ATTRIBUTION = 'TMRO_AD';
export const LOCAL_STORAGE_REFERRAL_ID = 'referral_id';
export const LOCAL_STORAGE_TCPA = 'tcpa_agreed';
export const LOCAL_STORAGE_TERMS = 'terms_agreed';

export const SESSION_STORAGE_POUR_OVER_WILL = 'pourOverWillEntered';

export const SEARCH_PARAM_DOCUMENT = 'document';
export const SEARCH_PARAM_BACKGROUND = 'background';
export const SEARCH_PARAM_PARTNER_CODE = 'partner_code';
export const SEARCH_PARAM_EMAIL = 'email';
export const SEARCH_PARAM_ZIP = 'zip';
export const SEARCH_PARAM_FIRST_NAME = 'nameFirst';
export const SEARCH_PARAM_LAST_NAME = 'nameLast';

export const ETHOS_POLICY_HOLDER_PARTNER_CODE = 'ETHOSPOLICYHOLDER';
export const SPOUSE_PARTNER_CODE = 'NE8DN1381F';

export const QUERY_REFERRAL_ID = 'ref_id';
export const WEB_PLATFORM = 'web';

export const ONE_MINUTE = 60 * 1000;
export const MODAL_TRANSITION_DURATION_MS = 350;
export const PHONE_MIN_LENGTH = 10;

export const INITIAL_DISTRIBUTION_AGE = 27;
export const INITIAL_DISTRIBUTION_PERCENTAGE = 30;
export const SECONDARY_DISTRIBUTION_AGE = 35;
export const SECONDARY_DISTRIBUTION_PERCENTAGE = 100;

export const LIFEINSURANCE_FALLBACK_APPLICATION_PATH = '/quotes/00000000-0000-0000-0000-000000000000/apply?utm_medium=web_partner';
export const LIFEINSURANCE_REFERRAL_URL = 'https://app.ethoslife.com';
