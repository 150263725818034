import { ReactElement, useEffect, useRef } from 'react';

// Components
import Modal from './Modal';

// Hooks
import useClipboard from '../hooks/useClipboard';
import useTranslations from '../hooks/useTranslations';

// Redux
import { patchInvitation } from '../store/reducers/invitationsReducer';
import { useDispatch, useSelector } from '../store';
import { makeSnack } from '../store/reducers/snackbarReducer';
import invitationsSelector from '../store/selectors/invitationsSelector';

// Types
import Invitation from '../core-data-service/models/Invitation';
import Role from '../core-data-service/models/Role';

// Providers
import branch from 'branch-sdk';
import userSelector from '../store/selectors/userSelector';

import analytics from '../utils/analytics';
import { hideModal } from '../store/reducers/modalReducer';
import { GlobalModals } from '../store/initial/modal';
import ActionSheet from './ActionSheet';

enum ShareTypes {
  copy = 'copy',
  text = 'text',
  email = 'email'
}

export type ShareActionSheetProps = {
  inviteId: string;
}

export default function ShareActionSheet({
  inviteId,
}: ShareActionSheetProps ): ReactElement{
  const { copyToClipboard } = useClipboard();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const user = useSelector( userSelector );

  const isOpen = useSelector( state => state.modal.data.shareActionSheet.isOpen );
  const handleClose = () => dispatch( hideModal( GlobalModals.shareActionSheet ));

  const invite = useSelector( invitationsSelector ).find( invite => invite?.id === inviteId );

  const firstName = invite?.invitee?.name;
  const inviteRole = invite?.context?.name;

  // get and set branchLink on render since Safari doesn't
  // like document.execCommand in response to API call
  const branchLink = useRef( `${window.location.protocol}//${window.location.host}/invite/${inviteId}` );

  const translationKey = inviteRole ? 'invitation.messages.role.v2' : 'invitation.messages.relationship.v2';
  const dynamicStringValues = inviteRole ? [ inviteRole === Role.TYPE.EXECUTOR ? 'an' : 'a', inviteRole.toLowerCase(), branchLink.current ] : [ branchLink.current ];
  const invitationMessage = getTranslation( translationKey, dynamicStringValues );

  const handleError = () => {
    dispatch( makeSnack({
      message: 'There was a problem generating an invite.',
      theme: 'error',
    }));
  };


  useEffect(() => {
    const linkData = {
      channel: 'web',
      alias: '',
      data: {
        'referredBy': user.id ,
        'invitationId': inviteId,
        // we want all users to have this experience in web
        // locally this will throw a 400 in the branch API, IP addresses are not valid in links
        '$fallback_url': branchLink.current,
      },
    };

    branch.link( linkData, ( _err, link ) => {
      if( link ) {
        branchLink.current = link;
      }
    });
  }, []);

  const handleShareTypeClicked = ( shareType: ShareTypes, shareAction: ()=> void )  => {
    if( !branchLink ) {
      handleError();
      return;
    }

    analytics.track( 'Touch - Invite Action Sheet', {
      response: buttonsToRender[shareType].label,
    });

    dispatch( patchInvitation({
      id: inviteId,
      status: Invitation.STATUS.SENT,
    }));
    shareAction();
    handleClose();
  };

  const buttonsToRender = {
    text: {
      label: 'SMS',
      action: () => handleShareTypeClicked( ShareTypes.text, () => {
        const textUrl = `sms:?&body=${invitationMessage}`;
        window.open( textUrl, '_self' );
      }),
    },
    email: {
      label: 'Email',
      action: () => handleShareTypeClicked( ShareTypes.email, () => {
        const emailUrl = `mailto:?subject=Preparing for my future&body=${invitationMessage}`;
        window.open( emailUrl, '_self' );
      }),
    },
    copy: {
      label: 'Copy Link',
      action: () => handleShareTypeClicked( ShareTypes.copy, () => {
        copyToClipboard( branchLink.current );
        dispatch( makeSnack({
          message: 'Copied Invite Link to clipboard',
        }));
      }),
    },
  };

  const handleModalDismissed = () => {
    // should only fire when modal is manually closed
    analytics.track( 'Touch - Invite Action Sheet', {
      response: 'Cancel',
    });
  };

  return (
    <ActionSheet
      title={ `Invite ${firstName}` }
      buttonsToRender={ buttonsToRender }
      isOpen={ isOpen }
      handleClose={ handleClose }
      handleModalDismissed={ handleModalDismissed }
    />
  );
}
