import Relationship from '../core-data-service/models/Relationship';
import { useSelector } from '../store';
import peopleSelector from '../store/selectors/peopleSelectors';
import PersonInterface from '../types/PersonInterface';

const usePhysicians = ( wardId: PersonInterface['id']): {
  physicians: PersonInterface[];
  existingPhysicians: PersonInterface[];
  potentialPhysicians: PersonInterface[];
} => {
  const physicians = useSelector( peopleSelector ).filter( person => Relationship.TYPE.PHYSICIAN.includes( person.relationship ));

  const existingPhysicians = physicians.filter( physicians => physicians.clients?.some( client => client.id === wardId ));

  const potentialPhysicians = physicians.filter( physicians => physicians.clients?.every( client => client.id !== wardId ));

  return {
    physicians,
    existingPhysicians,
    potentialPhysicians,
  };
};

export default usePhysicians;
