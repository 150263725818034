import PersonInterface from '../types/PersonInterface';
import { useSelector } from '../store';
import Role from '../core-data-service/models/Role';
import isDefined from '../utils/isDefined';
import Person from '../core-data-service/models/Person';
import roleInterface from '../types/RoleInterface';

const useAgents = (): {
    agents: PersonInterface[];
    potentialNewAgents: PersonInterface[];
    roles: roleInterface[];
} => {

  const roles = useSelector( state =>
    state.roles.data
      .filter( role=> role.name === Role.TYPE.HEALTHCARE_AGENT )
      .sort(( first, second )=> first.priority > second.priority ? 1 : -1 ));

  const people = useSelector( state => state.people.data );

  const agents: PersonInterface[] = roles
    .map( role=>people.find( person => person.id === role.grantee_id ))
    .filter( isDefined );

  const minorChildren: PersonInterface[] = useSelector( state => state.people.data
    .filter( _person => {
      const person = new Person( _person );
      return Boolean(
        person.isChild &&
                    Boolean( !person.isAdult || person.age === null ),
      );
    }),
  );

  const potentialAgents = people
    .filter( _person => {
      const person = new Person( _person );
      return Boolean( _person.relationship !== 'ME' && person.isAdult !== false );
    });

  const potentialNewAgents: PersonInterface[] = potentialAgents
    .filter( person => ( !agents.includes( person ) && !minorChildren.includes( person )));

  return {
    agents,
    potentialNewAgents,
    roles,
  };
};
export default useAgents;
