import { cloneElement, ReactElement, useEffect } from 'react';

// Components
import ScenarioA from './ScenarioA';
import ScenarioB from './ScenarioB';
import ScenarioC from './ScenarioC';
import HeirsSummary from './HeirsSummary';
import Flow from '../../components/Flow';

// Hooks
import useQuery from '../../hooks/useQuery';
import useFlowQueryParams from '../../hooks/useFlowQueryParams';
import useUserAttributes from '../../hooks/useUserAttributes';

// Utils
import analytics from '../../utils/analytics';

// Redux
import { useDispatch } from '../../store';
import { setUserAttributes } from '../../store/reducers/userReducer';

// Types
import { UserAttributes } from '../../types/UserInterface';
import { VideoSourceMap } from '../../components/VideoPlayer';
import { will_heirs as heirsRoute, will as willRoute } from '../../../core/routes';

export const getHeirsInitialScenarioPage = ( userAttributes: UserAttributes ): string => {
  const hasNoLifePartner = !userAttributes['app.hasSpouse'] && !userAttributes['app.hasDomesticPartnership'];
  const hasNoChildren = !userAttributes['app.hasChildren'];
  return !hasNoLifePartner
    ? heirsScenarios.a
    : !hasNoChildren
      ? heirsScenarios.b
      : heirsScenarios.c;
};

export const heirsScenarios = {
  a: 'a',
  b: 'b',
  c: 'c',
};

export const heirsVideoSourceMap: VideoSourceMap = { portrait: 'video.will.heirs', landscape: 'video.will.heirs.landscape' };

export function Heirs(): ReactElement{

  const dispatch = useDispatch();

  const { hasChildren,
    hasDomesticPartnership,
    hasSpouse, documents: { will: { willHeirsChapterNeedsReview } } } = useUserAttributes();

  const routingProps = {
    documentRoute: willRoute.get(),
    flowRoute: heirsRoute.get(),
  };

  const validScenarios = [
    { page: heirsScenarios.a, component: <ScenarioA { ...routingProps } />, condition: hasSpouse || hasDomesticPartnership },
    { page: heirsScenarios.b, component: <ScenarioB { ...routingProps }/>, condition: hasChildren },
    { page: heirsScenarios.c, component: <ScenarioC { ...routingProps } />, condition: true  },
  ].filter( scenario => scenario.condition );

  const currentIndex = useFlowQueryParams( validScenarios.map( scen => scen.page ));
  const page = useQuery().get( 'page' );


  useEffect(() => {
    if( page && validScenarios.map( scen => scen.page ).includes( page )) {
      analytics.track( 'View - Heirs', { scenario: page });
    }
  }, [ page ]);

  useEffect(() => {
    const attrs = { 'app.willHeirsChapterStarted': true };
    if( willHeirsChapterNeedsReview ) {
      Object.assign( attrs, { 'app.willHeirsChapterNeedsReview' : false });
    }
    dispatch( setUserAttributes( attrs ));
  }, [ willHeirsChapterNeedsReview, dispatch ]);

  return (
    <div className="flex fixed inset-0 bg-white">
      <Flow currentIndex={ currentIndex }>
        <HeirsSummary videoSourceMap={ heirsVideoSourceMap } videoTranslationKey="products.will.heirs.summary.video_button" completionEvent={ 'Touch - Finish Heirs' } { ...routingProps } />
        { validScenarios.map( scenario => cloneElement( scenario.component, { key: scenario.page }))}
      </Flow>
    </div>
  );
}


export default Heirs;
