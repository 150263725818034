import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';

// Assets
import confettiAnimation from '../assets/lottie/confetti--blue.json';

// Components
import { Button } from '../components/Button';
import Footer from '../templates/DefaultLayout/Footer';
import HorizontalRule from '../components/HorizontalRule';
import Icon from '../components/Icon';
import Loading from './Loading';
import VideoPlayer from '../components/VideoPlayer';

// Types
import InvitePageInterface, { IPrimarySectionCard } from '../types/InvitePageInterface';

// Utils
import { parseTranslation } from '../../core/utils/parseTranslation';
import { generateQueryString } from '../utils/urlUtils';

// Redux
import { fetchInvitation } from '../store/reducers/invitationsReducer';
import { fetchInvitePageData } from '../store/reducers/wordpressReducer';
import { useDispatch, useSelector } from '../store';
import { makeSnack } from '../store/reducers/snackbarReducer';
import userSelector from '../store/selectors/userSelector';

// Routes
import { dashboard as dashboardRoute, login } from '../../core/routes';
import Helper from '../components/Helper';

export default function InviteLanding(): ReactElement{
  const [ isAnimationComplete, setIsAnimationComplete ] = useState( false );
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string}>();
  const [ pageData, setPageData ] = useState<InvitePageInterface>();
  const location = useLocation();
  const { push } = useHistory();
  const user = useSelector( userSelector );

  const handleError = useCallback(()=> {
    push( dashboardRoute.get());
    dispatch( makeSnack({
      message: 'There was a problem retrieving your invite.',
      theme: 'error',
    }));
  }, [ dispatch, push ]);

  const invitation = useSelector( store => store.invitations.data.find( invite => invite.id === id ));

  useEffect(() => {
    // server side fetch failed
    if ( window.invitation_status === '404' ) {
      return handleError();
    // server side fetch successful
    } else if( invitation ) {
      return setPageData( window.invitation_content );
    }
    dispatch( fetchInvitation({ id, onSuccess: response => {
      const invitation = response.data.data;
      dispatch( fetchInvitePageData({ role: invitation.context?.name, onSuccess: data => setPageData( data ), onError: handleError }));
    }, onError: handleError }));
  }, [ dispatch, id, handleError ]);


  if( !pageData || !invitation ) {
    return <Loading />;
  }

  const {
    title,
    acf: {
      subtitle,
      primaryAction_label,
      primaryAction_disclaimer,
      primarySection,
      video,
      secondary_section,
    },
  } = pageData;

  const {
    invitor: {
      name: invitorName,
    },
    user_id,
  } = invitation;

  const handleBeginInviteAcceptance = () => {
    if( user_id === user.id ) {
      dispatch( makeSnack({
        message: 'You cannot accept your own invite',
        theme: 'error',
      }));
      return;
    }
    push({
      pathname: login.get(),
      search: generateQueryString({ next: `${location.pathname}/accepted` }),
      state: { background: location },
    });
  };


  return (
    <>
      <div className="flex-1 relative">
        <div className="container--sm mb-18">
          <Lottie
            className={ `absolute top-0 transform transition-opacity duration-300 -translate-y-1/4 -z-1 ${isAnimationComplete ? 'opacity-0' : ''}` }
            animationData={ confettiAnimation }
            onComplete={ () => setIsAnimationComplete( true ) }
            loop={ false }
            autoPlay={ true }
          />
          <div className="text-center max-w-lg mx-auto">
            <Icon name="logoMark" className="w-8 h-8 mx-auto mt-8 md:mt-12 mb-8 text-forest"/>
            <h1 className="font-headline text-4xl text-black mb-8">
              {parseTranslation( title.rendered, [ invitorName ])}
            </h1>
            <h3 className="mb-8">{parseTranslation( subtitle, [ invitorName ])}</h3>
            <Button
              theme="tertiary"
              className="mx-auto"
              onClick={ handleBeginInviteAcceptance }
            >
              {primaryAction_label}
            </Button>
          </div>

          {primaryAction_disclaimer && primaryAction_disclaimer.body && primaryAction_disclaimer.title &&
            <Helper
              className="!mt-8 max-w-sm mx-auto"
              title={ primaryAction_disclaimer.title }
              body={ parseTranslation( primaryAction_disclaimer.body, [ invitorName ]) }
            />
          }
        </div>


        {primarySection?.isVisible &&
          <>
            <HorizontalRule className="mb-12 !mx-0"/>
            <section className="container--sm">
              {primarySection.title && <h2 className="text-center font-bold mb-12">{primarySection.title}</h2>}
              <ul className="grid md:grid-cols-2 gap-8 mb-12">
                {primarySection.cards?.map(( card: IPrimarySectionCard ) => {
                  if( !card.title || !card.body ) {
                    return null;
                  }
                  return (
                    <li key={ card.title } className="bg-moss py-7 px-8 rounded-lg">
                      <h3 className="font-bold mb-2 text-black">{card.title}</h3>
                      <p>{card.body}</p>
                    </li>
                  );
                })}
              </ul>
            </section>
          </>
        }

        {video?.isVisible && video?.videoSrc &&
          <>
            <HorizontalRule className="mb-12 !mx-0"/>
            <div className="container--sm mb-12">
              {video.title && <h2 className="text-center font-bold mb-12">{video.title}</h2>}
              <div className="aspect-w-16 aspect-h-9">
                <VideoPlayer source={ video.videoSrc  }/>
              </div>
            </div>
          </>
        }

        {secondary_section?.isVisible &&
          <>
            <HorizontalRule className="mb-12 !mx-0"/>
            <div className="container--sm text-center mb-12">
              <h3 className="font-bold text-black mb-4">{secondary_section.title}</h3>
              <p className="mb-6">{secondary_section.body}</p>
              <Button theme="tertiary" className="mx-auto" onClick={ () => push( login.get()) }>{secondary_section.primaryAction_label}</Button>
            </div>
          </>
        }
      </div>
      <Footer centerTextOnMobile={ true } className="bg-defaultBg sm-only:pb-28 py-8"/>
    </>

  );
}
