import { ReactElement, useEffect } from 'react';
// Hooks
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { backstackQueryParam } from '../hooks/useBackstack';
import useExperiments from '../hooks/useExperiments';

// Redux
import { useDispatch } from '../store';
import { hideModal } from '../store/reducers/modalReducer';
import { setUserAttributes } from '../store/reducers/userReducer';

// Components
import Modal, { ModalType } from '../components/Modal';
import Button, { ButtonTheme } from '../components/Button';
import Translation from '../components/Translation';
import Icon, { Icons } from './Icon';

// Types
import Role from '../core-data-service/models/Role';
import analytics from '../utils/analytics';
import { GlobalModals } from '../store/initial/modal';
import LocationState from '../types/LocationState';

export interface InviteModalProps {
  firstNames: string[];
  roleLabel: string;
  onDismiss?: ()=> void;
}

const getTitleTranslationString = ( roleLabel: string ) => {
  switch( roleLabel ){
  case( Role.TYPE.GUARDIAN ):
    return 'modal.invitation.guardian.title.singleinvitee';
  case( Role.TYPE.EXECUTOR ):
    return 'modal.invitation.executor.title.singleinvitee';
  case( Role.TYPE.TRUSTEE ):
    return 'modal.invitation.trustee.title.singleinvitee';
  default:
    return 'modal.invitation.role.title.singleinvitee';
  }
};

const getMultipleTitleTranslationString = ( roleLabel: string ) => {
  switch( roleLabel ){
  case( Role.TYPE.GUARDIAN ):
    return 'modal.invitation.guardian.title.multipleinvitees';
  case( Role.TYPE.EXECUTOR ):
    return 'modal.invitation.executor.title.multipleinvitees';
  case( Role.TYPE.TRUSTEE ):
    return 'modal.invitation.trustee.title.multipleinvitees';
  default:
    return 'modal.invitation.role.title.multipleinvitees';
  }
};

export default function InviteModal({
  firstNames,
  roleLabel,
  onDismiss,
}: InviteModalProps ): ReactElement{
  const { push } = useHistory();
  const dispatch = useDispatch();

  const isOnePersonInvited = firstNames.length === 1;

  const titleTranslationString = getTitleTranslationString( roleLabel );
  const multipleTitleTranslationString = getMultipleTitleTranslationString( roleLabel );

  const inviteAnalyticsProperties = { variant: roleLabel.toLowerCase() };
  const location = useLocation<LocationState>();

  const { inviteModalExperiment: { isVariantAEnabled, isVariantBEnabled, isVariantCEnabled } } = useExperiments( 'INVITES_MODAL_VISUAL_VARIATIONS_EXPERIMENT_KEY' );

  useEffect(() => {
    analytics.track( 'View - Invitation Modal', inviteAnalyticsProperties );
    switch( roleLabel ) {
    case ( Role.TYPE.GUARDIAN ):
      dispatch( setUserAttributes({
        'app.hasSeenGuardiansInviteModal': true,
      }));
      break;
    case ( Role.TYPE.EXECUTOR ):
      dispatch( setUserAttributes({
        'app.hasSeenExecutorsInviteModal': true,
      }));
      break;
    case ( Role.TYPE.TRUSTEE ):
      dispatch( setUserAttributes({
        'app.hasSeenTrusteesInviteModal': true,
      }));
      break;
    }
  }, []);

  const experimentOptions = {
    buttonTheme: 'tertiary' as ButtonTheme,
    background: '',
    icon: 'info' as Icons,
    iconColor: '',
    shouldShowHeaderIcon: false,
    shouldShowButtonIcon: false,
    modalType: 'contextual' as ModalType,
    innerBorderClass: 'false',
  };
  if( isVariantAEnabled ){
    experimentOptions.buttonTheme = 'primary';
    experimentOptions.icon = 'paper-plane-stroke';
    experimentOptions.background = '!bg-buttercup';
    experimentOptions.iconColor = 'text-salamander';
    experimentOptions.shouldShowHeaderIcon = true;
  }else if( isVariantBEnabled ){
    experimentOptions.background = '!bg-moss';
    experimentOptions.icon = 'contact-card';
    experimentOptions.iconColor = 'text-forest';
    experimentOptions.shouldShowHeaderIcon = true;
  }else if( isVariantCEnabled ){
    experimentOptions.modalType = 'bottom-contextual';
    experimentOptions.background = 'border-ticket-clipping !bg-moss md:max-w-[340px] !rounded-none -mb-2 sm-only:mx-2 !p-4';
    experimentOptions.shouldShowButtonIcon = true;
    experimentOptions.iconColor = 'text-white';
    experimentOptions.innerBorderClass = 'border-dashed border-2 rounded-md border-moss--dark pt-6 pb-2 px-8 sm-only:-mt-12 mb-8';
  }
  return (
    <Modal
      type={ experimentOptions.modalType }
      className="text-center"
      contentClassName={ experimentOptions.background }
    >
      <div className={ experimentOptions.innerBorderClass }>
        {experimentOptions.shouldShowHeaderIcon && <Icon name={ experimentOptions.icon } className={ `mx-auto mt-4 mb-4 ${experimentOptions.iconColor}` }/>}
        <p className="mb-4 font-bold text-black">
          { isOnePersonInvited
            ? <Translation translationKey={ titleTranslationString } dynamicStringValues={ firstNames } />
            : <Translation translationKey={ multipleTitleTranslationString } dynamicStringValues={ firstNames.slice( 0,2 ) } />
          }
        </p>
        <p className="mb-4">
          <Translation translationKey={ 'modal.invitation.role.description' } dynamicStringValues={ [ roleLabel.toLowerCase() ] } />
        </p>
        <div className="flex flex-col justify-between">
          <Button
            theme={ experimentOptions.buttonTheme }
            onClick={ () => {
              analytics.track( 'Touch - Invite', inviteAnalyticsProperties );
              dispatch( hideModal( GlobalModals.inviteModal ));
              push({ pathname: location.pathname, search: `?page=invites&${backstackQueryParam}=1` });
            } }
            className= { 'mx-auto mb-4 shadow-button' }
          ><p className="flex items-center">
              {isOnePersonInvited
                ? <Translation translationKey="modal.invitation.role.button.singleinvitee" dynamicStringValues={ firstNames }/>
                : <Translation translationKey="modal.invitation.role.button.multipleinvitees" />
              }
              {experimentOptions.shouldShowButtonIcon && <Icon name={ 'paper-plane-fill' }  className={ `ml-4 ${experimentOptions.iconColor}` }/>}
            </p>
          </Button>
        </div>
      </div>
      <Button
        theme="clean-secondary"
        id="cancel-button"
        onClick={ () => {
          dispatch( hideModal( GlobalModals.inviteModal ));
          onDismiss && onDismiss();
        } }
        className="mx-auto"
      >
        <Translation translationKey="modal.invitation.role.button.cancel" />
      </Button>
    </Modal>
  );
}
