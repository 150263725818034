import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

// Data
import initialState from '../initial/powers';
import {
  AxiosPowerResponse,
  AxiosPowersResponse,
  PowerInterface,
} from '../../types/powersInterface';
import BaseReduction from '../../types/BaseReduction';

type IFetchPowers = BaseReduction<AxiosPowersResponse>

interface IDeletePower extends BaseReduction<AxiosResponse> {
  id: string;
}

interface IPatchPower extends BaseReduction<AxiosPowerResponse> {
  id: string;
}

interface IPostPower extends BaseReduction<AxiosPowerResponse> {
  person_id: string;
}

export const fetchPowers = createAsyncThunk(
  'powers/fetchPowers',
  async( args: IFetchPowers | void, thunkAPI ) => {
    const url = '/v1/powers_of_attorney';
    return WebClientRequest
      .get( url )
      .then(( response: AxiosPowersResponse ) => {
        const power = response.data.data;
        thunkAPI.dispatch( updatePowers( power ));
        args && args.onSuccess && args.onSuccess( response );
      });
  },
);

export const deletePower = createAsyncThunk(
  'powers/deletePower',
  async({ onSuccess, id }: IDeletePower, thunkAPI ) => {

    const url = `/v1/powers_of_attorney/${id}`;
    return WebClientRequest
      .delete( url )
      // 204 Empty
      .then(( response: AxiosResponse ) => {
        thunkAPI.dispatch( removePower({ id }));
        onSuccess && onSuccess( response );
      });
  },
);

export const patchPower = createAsyncThunk(
  'powers/patchPower',
  async({ id, onSuccess, ...data }: IPatchPower, thunkAPI ) => {

    const url = `/v1/powers_of_attorney/${id}`;
    return WebClientRequest
      .patch( url, data )
      .then(( response: AxiosPowerResponse ) => {
        const power =response.data.data;
        thunkAPI.dispatch( updatePower( power ));
        onSuccess && onSuccess( response );
      });
  },
);

export const postPower = createAsyncThunk(
  'powers/postPower',
  async({ onSuccess, person_id, ...data }: IPostPower, thunkAPI ) => {

    const url = '/v1/powers_of_attorney';
    return WebClientRequest
      .post( url, data )
      .then(( response: AxiosPowerResponse ) => {
        const power = response.data.data;
        thunkAPI.dispatch( updatePower( power ));
        onSuccess && onSuccess( response );
      });
  },
);


const PowersSlice = createSlice({
  name: 'powers',
  initialState,

  reducers: {

    updatePowers( powers, action: PayloadAction<PowerInterface[]> ){
      if( powers.data.length === 0 ){
        powers.data = action.payload;
      } else {
        action.payload.forEach( power => {
          const idx = powers.data
            .findIndex( xPower => xPower.id === power.id );
          if( idx >= 0 ){
            powers.data[idx] = power;
          } else {
            powers.data.push( power );
          }
        });
      }
    },

    updatePower( powers, action: PayloadAction<PowerInterface> ){
      const payload = action.payload;
      const idx = powers.data.findIndex( power => power.id === payload.id );
      if( idx >= 0 ){
        powers.data[idx] = payload;
      } else {
        powers.data.push( payload );
      }
    },

    removePower( powers, action: PayloadAction<{id: string}> ){
      const { id } = action.payload;
      powers.data = powers.data.filter( power => power.id !== id );
    },
  },

  extraReducers: builder => {

    // fetchPowers.<status>
    builder.addCase( fetchPowers.pending, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.fetchPowers.meta = meta;
    });
    builder.addCase( fetchPowers.fulfilled, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.fetchPowers.meta = meta;
    });
    builder.addCase( fetchPowers.rejected, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.fetchPowers.meta = meta;
    });

    // patchPower.<status>
    builder.addCase( patchPower.pending, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.patchPower.meta = meta;
    });
    builder.addCase( patchPower.fulfilled, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.patchPower.meta = meta;
    });
    builder.addCase( patchPower.rejected, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.patchPower.meta = meta;
    });

    // postPower.<status>
    builder.addCase( postPower.pending, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.postPower.meta = meta;
    });
    builder.addCase( postPower.fulfilled, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.postPower.meta = meta;
    });
    builder.addCase( postPower.rejected, ( powers, action ) => {
      const { arg, ...meta } = action.meta;
      powers.postPower.meta = meta;
    });
  },
});


export const { updatePowers, updatePower, removePower } = PowersSlice.actions;

export default PowersSlice.reducer;
