import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

// Data
import initialState from '../initial/invitations';
import { InvitationResponse, InvitationStatus } from '../../core-data-service/models/Invitation';
import BaseReduction from '../../types/BaseReduction';
import InvitationInterface, { AxiosInvitationResponse, AxiosInvitationsResponse } from '../../types/InvitationInterface';

const INVITATIONS_URL_BASE = '/v1/invitations';

type IFetchInvitations = BaseReduction<AxiosInvitationsResponse>

interface IFetchInvitation extends BaseReduction<AxiosInvitationResponse> {
  id: InvitationInterface['id'];
}

interface IPatchInvitation extends BaseReduction<AxiosInvitationResponse> {
  id: InvitationInterface['id'];
  status?: InvitationStatus;
  response?: InvitationResponse;
}

export const fetchInvitations = createAsyncThunk(
  'invitation/fetchInvitations',
  async( arg: IFetchInvitations | void, thunkAPI ) => {

    const url = INVITATIONS_URL_BASE;

    return WebClientRequest
      .get( url )
      .then( response => {
        const invitations = response.data.data;
        thunkAPI.dispatch( updateInvitations( invitations ));
        arg && arg.onSuccess && arg.onSuccess( response );
      })
      .catch( e => {
        arg && arg.onError && arg.onError( e );
      });
  },
);

export const fetchInvitation = createAsyncThunk(
  'invitation/fetchInvitation',
  async({ id, onSuccess, onError }: IFetchInvitation, thunkAPI ) => {

    const url = `${INVITATIONS_URL_BASE}/${id}`;

    return WebClientRequest
      .get( url )
      .then(( response: AxiosInvitationResponse ) => {
        const invitation = response.data.data;
        thunkAPI.dispatch( updateInvitation( invitation ));
        onSuccess && onSuccess( response );
      })
      .catch( e => {
        onError && onError( e );
      });
  },
);

export const patchInvitation = createAsyncThunk(
  'invitation/patchInvitation',
  async({ id, onSuccess, onError, ..._data }: IPatchInvitation, thunkAPI ) => {

    const url = `${INVITATIONS_URL_BASE}/${id}`;

    const { status, response } = _data;
    const data = {
      status,
      response,
    };

    return WebClientRequest
      .patch( url, data )
      .then(( response: AxiosInvitationResponse ) => {
        const invitation = response.data.data;
        thunkAPI.dispatch( updateInvitation( invitation ));

        onSuccess && onSuccess( response );
      })
      .catch( e => {
        onError && onError( e );
      });
  },
);


const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,

  reducers: {
    updateInvitations( invites, action: PayloadAction<InvitationInterface[]> ){
      if( invites.data.length === 0 ){
        invites.data = action.payload;
      } else {
        action.payload.forEach( invite => {
          const idx = invites.data
            .findIndex( xInvite => xInvite.id === invite.id );
          if( idx >= 0 ){
            invites.data[idx] = invite;
          } else {
            invites.data.push( invite );
          }
        });
      }
    },
    updateInvitation( invites, action: PayloadAction<InvitationInterface> ){
      const payload = action.payload;
      const idx = invites.data.findIndex( invite => invite.id === payload.id );
      if( idx >= 0 ){
        invites.data[idx] = payload;
      } else {
        invites.data.push( payload );
      }
    },
    destroyInvitation( invites, action: PayloadAction<{id: string}> ){
      const payload = action.payload;
      const idx = invites.data.findIndex( invite => invite.id === payload.id );
      if( idx >= 0 ){
        invites.data.splice( idx, 1 );
      }
    },
  },

  extraReducers: builder => {
    // fetchInvitations.<status>
    builder.addCase( fetchInvitations.pending, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitations.meta = meta;
    });
    builder.addCase( fetchInvitations.fulfilled, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitations.meta = meta;
    });
    builder.addCase( fetchInvitations.rejected, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitations.meta = meta;
    });
    // fetchInvitation.<status>
    builder.addCase( fetchInvitation.pending, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitation.meta = meta;
    });
    builder.addCase( fetchInvitation.fulfilled, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitation.meta = meta;
    });
    builder.addCase( fetchInvitation.rejected, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.fetchInvitation.meta = meta;
    });
    // patchInvitation.<status>
    builder.addCase( patchInvitation.pending, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.patchInvitation.meta = meta;
    });
    builder.addCase( patchInvitation.fulfilled, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.patchInvitation.meta = meta;
    });
    builder.addCase( patchInvitation.rejected, ( invitations, action ) => {
      const { arg, ...meta } = action.meta;
      invitations.patchInvitation.meta = meta;
    });
  },
});

export const { updateInvitation, updateInvitations, destroyInvitation } = invitationsSlice.actions;

export default invitationsSlice.reducer;
