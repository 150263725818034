import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState from '../initial/backstack';


const backstackSlice = createSlice({
  name: 'backstack',
  initialState,
  reducers: {

    push( bs, action: PayloadAction<{ path: string; depth: number }> ){
      const { path, depth = 0 } = action.payload;
      if( bs.length > 0 ){

        // Handle case where action is fired but the path hasn't changed...
        if( bs[bs.length - 1] === path ){
          return bs;
        }

        // Allow ability to manually skip x entries before checking diff.
        if( depth > 0 ){
          bs = bs.slice( 0, -depth );
        }

        // Handle case where returning to a previous screen after an action.
        // for example: Adding a person, belonging, or asset.
        // If the current_path matches the 2nd to last path,
        // remove the last history instance instead of adding a new one.
        if( bs.length >= 2 ){
          const pathBeforeLast = bs[bs.length - 2];
          if( path === pathBeforeLast ){
            bs.pop();
            return bs;
          }
        }

      }
      bs.push( path );
    },

    /** Remove top of stack, either first or multiple */
    pop( bs, action: PayloadAction<number|undefined> ){
      return bs.slice( 0, -1 * ( action.payload || 1 ));
    },

    // Remove all history containing given string, while preserving top
    purge( bs, action: PayloadAction<string> ){
      const matchString: string = action.payload;
      const top = bs[bs.length - 1];
      const purged = bs.filter( item => !item.includes( matchString ));
      return [ ...purged, ...( top ? [ top ] : [])  ];
    },

  },
});

export const { push, pop, purge } = backstackSlice.actions;

export default backstackSlice.reducer;
