import { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Components
import AuthenticatedRoute from '../../AuthenticatedRoute';

// Hooks
import useWillSectionStatus from '../../../hooks/useWillSectionStatus';

// Redux
import { useSelector } from '../../../store';
import entitlementsSelector from '../../../store/selectors/entitlementsSelector';

// Routes
import {
  will_heirs,
  will as willRoute,
  will_intro,
  will_review,
  will_basics,
  will_pets,
  will_realEstate,
  will_valuables,
  will_guardians,
  will_executors,
} from '../../../../core/routes';

// Views
import DocumentBasics from '../../../views/Documents/DocumentBasics';
import Will from '../../../views/Documents/Will';
import WillIntro from '../../../views/Documents/Will/WillIntro';
import Executors from '../../../views/Executors';
import Guardians from '../../../views/Guardians';
import Heirs from '../../../views/Heirs';
import Pets from '../../../views/Property/Pets';
import RealEstate from '../../../views/Property/RealEstate';
import Valuables from '../../../views/Property/Valuables';
import WillReviewRouter from './WillReviewRouter';

// Helpers
import { UNSUPPORTED_DOCUMENT_FALLBACK_ROUTE } from '../../../../core/routerConstants';
import { DOCUMENTS } from '../../../core-data-service/models/Document';

export default function WillRouter(): ReactElement{
  const { hasCompletedWill } = useWillSectionStatus();
  const userAttributes = useSelector( state => state.user.data.attributes );
  const { availableDocuments } = useSelector( entitlementsSelector );
  const isDocumentSupported = availableDocuments.includes( DOCUMENTS.will );

  return (
    <Switch>
      {!isDocumentSupported && <Redirect to={ UNSUPPORTED_DOCUMENT_FALLBACK_ROUTE }/>}
      <Route path={ willRoute.path }>
        <>
          <Route exact path={ willRoute.path }>
            { hasCompletedWill && userAttributes['app.willPreviewViewed'] ?
              <Redirect to={{ pathname: will_review.path }} /> :

              !!userAttributes['app.willHasConfirmedBasics'] ?
                <Will /> :
                !!userAttributes['app.willIntroFinished'] ?
                  <Redirect to={ will_basics.path } />:
                  <Redirect to={ will_intro.path } />
            }
          </Route>

          {/* make the specific routes for the will intro pattern */}

          <Route exact path={ will_intro.path } component={ WillIntro } />
          <AuthenticatedRoute exact path={ will_basics.path } component={ DocumentBasics } />
          <AuthenticatedRoute exact path={ will_heirs.path } component={ Heirs } />
          <AuthenticatedRoute exact path={ will_executors.path } component={ Executors }/>
          <AuthenticatedRoute exact path={ will_guardians.path } component={ Guardians }/>
          <AuthenticatedRoute exact path={ will_pets.path } component={ Pets }/>
          <AuthenticatedRoute exact path={ will_valuables.path } component={ Valuables }/>
          <AuthenticatedRoute exact path={ will_realEstate.path } component={ RealEstate }/>
          <AuthenticatedRoute path={ will_review.path } component={ WillReviewRouter } />
        </>
      </Route>
    </Switch>
  );
}

