import { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ActionContainer from '../../../../components/ActionContainer';

// Components / Views
import Helper from '../../../../components/Helper';
import SegmentView from '../../../../components/SegmentView';
import Translation from '../../../../components/Translation';
import PersonIcon from '../../../People/PersonIcon';

// Routes
import {
  medical_consent_address,
  medical_consent_allergies,
  medical_consent_doctors,
  medical_consent_document as medicalConsentDocRoute,
  medical_consent_medications,
} from '../../../../../core/routes';

// Hooks
import useBackstack from '../../../../hooks/useBackstack';
import { getPersonByID } from '../../../../utils/people/getPersonByID';
import { useSelector } from '../../../../store';
import usePhysicians from '../../../../hooks/usePhysicians';

// Types
import Person from '../../../../core-data-service/models/Person';

// Helpers
import { toTitleCase } from '../../../../../core/utils/stringHelpers';
import { isAddressComplete, prettyPrintAddress } from '../../../../utils/address';

// Redux
import userPersonSelector from '../../../../store/selectors/userPersonSelector';

// styles
const titleStyle = 'pt-6 pb-6 font-bold border-t border-grey text-black';
const subtitleSyle = 'pb-4 text-black-70';

export default function MedicalConsentCareInformationManagement(): ReactElement{
  const { wardId } = useParams<{ wardId: string}>();
  const child = useSelector( getPersonByID( wardId ));
  const { push } = useHistory();

  const userPersonAddress = useSelector( userPersonSelector )?.address;
  const isCompleteAddress = !!userPersonAddress && isAddressComplete( userPersonAddress );

  const { goBack } = useBackstack( medicalConsentDocRoute.get({ wardId }));

  const { existingPhysicians } = usePhysicians( wardId );

  const childName = child?.name || '';
  const childAllergies = child?.allergies;
  const childMedications = child?.medications;


  if ( !child ) {
    return (
      <div>This child doesn't exist</div>
    );
  }

  return (
    <SegmentView
      onRetreat={ goBack }
      title={ <Translation translationKey="medicalconsent.screen.childsinformation.title" dynamicStringValues={ [ childName ] }/> }
      hidePrimaryAction={ true }
    >
      <ActionContainer
        header={ <Translation translationKey={ 'medicalconsent.screen.childsinformation.chapter.address.title' } /> }
        className={ 'pt-2 font-bold text-black' }
        primaryAction={ () => push( medical_consent_address.get({ wardId })) }
        primaryActionLabel={ <Translation translationKey="(button)add" /> }
      >
      </ActionContainer>
      <div className={ subtitleSyle }>{
        ( isCompleteAddress && userPersonAddress )
          ? <p className="mt-4">{prettyPrintAddress( userPersonAddress )}</p>
          : <Helper
            className="mt-6 mb-4"
            theme="error"
            body={ <Translation translationKey="medicalconsent.screen.childsinformation.chapter.address.error.incomplete"/> }
          />
      }</div>
      <ActionContainer
        header={ <Translation translationKey={ 'medicalconsent.screen.childsinformation.chapter.medications.title' } /> }
        className={ titleStyle }
        primaryAction={ () => push( medical_consent_medications.get({ wardId })) }
        primaryActionLabel={ <Translation translationKey="(button)add" /> }
      >
      </ActionContainer>
      {childMedications && <div className={ subtitleSyle }>{childMedications}</div> }
      <ActionContainer
        header={ <Translation translationKey={ 'medicalconsent.screen.childsinformation.chapter.allergies.title' } /> }
        className={ titleStyle }
        primaryAction={ () => push( medical_consent_allergies.get({ wardId })) }
        primaryActionLabel={ <Translation translationKey="(button)add" /> }
      >
      </ActionContainer>
      {childAllergies &&
        <div className={ subtitleSyle }>{childAllergies}</div>
      }
      <ActionContainer
        header={ <Translation translationKey={ 'medicalconsent.screen.childsinformation.chapter.doctors.title' } /> }
        className={ titleStyle }
        primaryAction={ () => push( medical_consent_doctors.get({ wardId })) }
        primaryActionLabel={ existingPhysicians.length ? <Translation translationKey="(button)edit" /> : <Translation translationKey="(button)add" /> }
      >
        { existingPhysicians.map( physician => {
          const _physician = new Person( physician );
          return <div className="w-full mt-4" key={ physician.id }>
            <div className="flex-1 flex flex-row">
              <div className="w-16">
                <PersonIcon
                  className="aspect-w-1 aspect-h-1"
                  photo_url={ physician.photo_url }
                  personName={ physician.name }
                  onImageLoadDuration={ 1 }
                  alt="avatar"
                />
              </div>
              <div className="flex-1 flex flex-col justify-center">
                <div className="self-stretch flex justify-between">
                  <div>
                    <span className="ml-4 whitespace-nowrap text-black font-bold">
                      { _physician.firstName }
                    </span>
                    <span className="ml-4 whitespace-nowrap mt-2 block font-regular text-black-70">
                      { toTitleCase( physician.relationship ) }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>;
        })
        }
      </ActionContainer>

    </SegmentView>
  );
}
