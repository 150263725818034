import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components / Views
import ActionContainer from '../../components/ActionContainer';
import Executor from './Executor';
import SegmentView from '../../components/SegmentView';
import Translation from '../../components/Translation';
import AddButton from '../../components/AddButton';

// Hooks
import useExecutors from '../../hooks/useExecutors';
import useBackstack from '../../hooks/useBackstack';
import useTranslations from '../../hooks/useTranslations';
import useUserAttributes from '../../hooks/useUserAttributes';

// Redux
import { useDispatch } from '../../store';
import { postRole } from '../../store/reducers/rolesReducer';
import { showInviteModal } from '../../store/reducers/modalReducer';

// Routes
import { will_executors as executorsRoute, will as willRoute } from '../../../core/routes';

// Types
import Role from '../../core-data-service/models/Role';

// Utils
import analytics from '../../utils/analytics';
import PeopleSelector from '../../components/PeopleSelector';
import { toTitleCase } from '../../../core/utils/stringHelpers';
import OpenVideoButton from '../../components/OpenVideoButton';
import { VideoSourceMap } from '../../components/VideoPlayer';

export const executorsVideoSourceMap: VideoSourceMap = { portrait: 'video.will.executors', landscape: 'video.will.executors.landscape' };


export default function ExecutorsSummary(): ReactElement{

  const { push } = useHistory();
  const dispatch = useDispatch();
  const { goBack } = useBackstack( willRoute.get());
  const { getTranslation } = useTranslations();

  const [ isAddingExecutor, setIsAddingExecutor ] = useState<boolean>( false );

  const { hasBackupExecutor, roles, uninvitedExecutorFirstNames, executors, potentialNewExecutors } = useExecutors();

  const { hasSeenExecutorsInviteModal } = useUserAttributes();

  const proceed = () => push( hasBackupExecutor
    ? willRoute.get()
    : executorsRoute.get({}, { page: 'warning' }));
  /**
   * check if it has a fallback, route to warning if false
   */
  const onPrimaryAction = () => {
    analytics.track( 'Touch - Finish Executors' );
    if ( hasSeenExecutorsInviteModal || uninvitedExecutorFirstNames.length === 0 ) {
      proceed();
    } else {
      dispatch( showInviteModal({
        firstNames: uninvitedExecutorFirstNames,
        roleLabel: Role.TYPE.EXECUTOR,
        onDismiss: proceed,
      }));
    }
  };

  const handlePersonSelected = ( id: string ) => {
    setIsAddingExecutor( false );
    dispatch( postRole({
      grantee_id: id,
      name: Role.TYPE.EXECUTOR,
    }));
  };

  return (
    <>
      {isAddingExecutor && <PeopleSelector
        isOpen
        people={ potentialNewExecutors }
        optionTypeLabel={ toTitleCase( Role.TYPE.EXECUTOR ) }
        onClose={ id => {
          id ? handlePersonSelected( id ) : setIsAddingExecutor( false );
        } }
        onSelect={ id => {
          handlePersonSelected( id );
        } }
        titleTranslationKey="products.will.select.an.person.title"
      />}
      <SegmentView
        onRetreat={ goBack }
        title={ <Translation translationKey="will.chapter.title.executors"/> }
        subtitle={
          <>
            <Translation translationKey="(screen)executor.subtitle" />
            <OpenVideoButton
              videoSrc={ executorsVideoSourceMap }
              labelKey="role.exectuor.video_button"
            />
          </>
        }
        primaryActionLabel={ getTranslation( 'products.will.chapter.actions.finish' ) }
        hidePrimaryAction={ false }
        onPrimaryAction={ onPrimaryAction }
      >
        {
          executors.map(( executor, idx ) =>
            <Executor key={ `Executor${idx}` }
              className="mb-4"
              executor={ executor }
              idx={ idx }
              role={ roles.find( role => role.grantee_id === executor.id ) }
            />,
          )
        }
        <ActionContainer
          header={ executors.length > 0 ? executors.length > 1 ? `Backup Executor #${executors.length}` : 'Backup Executor' : 'Primary Executor' }
        >
          <AddButton
            onClick={ () => setIsAddingExecutor( true ) }
            buttonId="add-person"
            iconName={ 'addPerson' }
            className={ 'mt-4' }
          />
        </ActionContainer>
      </SegmentView>
    </>
  );
}
