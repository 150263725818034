import { ReactElement } from 'react';
import Translation from '../../components/Translation';
import useTranslations from '../../hooks/useTranslations';
import { useSelector } from '../../store';

interface HeirsScenarios {
  className?: string;
  scenario: 'primary' | 'secondary' | 'fallback';
}

export const HeirsScenarioSubhead = ({ scenario }: HeirsScenarios ): ReactElement => {
  const userAttributes = useSelector( state => state.user.data.attributes );
  if( !userAttributes ) {
    return <></>;
  }
  const hasPrimaryPartner = userAttributes['app.hasSpouse'] || userAttributes['app.hasDomesticPartnership'];

  let result;
  switch ( scenario ) {
  case 'primary':
    result = <Translation translationKey="inheritance.scenarioa" />;
    break;
  case 'secondary':
    result = !hasPrimaryPartner ? <Translation translationKey="inheritance.scenarioa" /> : <Translation translationKey="inheritance.scenariob" />;
    break;
  case 'fallback':
    result = userAttributes?.['app.hasChildren'] && hasPrimaryPartner
      ? <Translation translationKey="inheritance.scenarioc" />
      : userAttributes?.['app.hasChildren'] || hasPrimaryPartner
        ? <Translation translationKey="inheritance.scenariob" />
        : '';
    break;
  }
  return <span className="whitespace-nowrap">{result}</span>;
};

export const HeirsScenarioTitle = ({ className='', scenario }: HeirsScenarios ): ReactElement => {
  const { getTranslation } = useTranslations();
  const userAttributes = useSelector( state => state.user.data.attributes );

  const hasPrimaryPartner = userAttributes['app.hasSpouse'] || userAttributes['app.hasDomesticPartnership'];
  const primaryPartnerTitle = userAttributes['app.hasSpouse'] ? getTranslation( 'products.heirs.scenarios.sections.spouse.title' ).toLowerCase() : getTranslation( 'products.heirs.scenarios.sections.partner.title' ).toLowerCase();

  let result;
  switch ( scenario ) {
  case 'primary':
    result = <Translation
      translationKey="inheritance.spouseorlegalpartner.title"
      dynamicStringValues={ [ primaryPartnerTitle ] }
    />;
    break;
  case 'secondary':
    result = !hasPrimaryPartner ? <Translation translationKey="inheritance.kids.title" /> : <Translation translationKey="inheritance.kids.hasspouseorlegalpartner.title" dynamicStringValues={ [ primaryPartnerTitle ] } />;
    break;
  case 'fallback':
    result = userAttributes?.['app.hasChildren'] && hasPrimaryPartner
      ? <Translation translationKey="inheritance.other.hasspouseorlegalpartnerandkids.title" dynamicStringValues={ [ primaryPartnerTitle ] }/>
      : userAttributes?.['app.hasChildren'] && !hasPrimaryPartner
        ? <Translation translationKey="inheritance.other.haskids.title"/>
        : hasPrimaryPartner ?
          <Translation translationKey="inheritance.other.hasspouseorlegalpartner.title" dynamicStringValues={ [ primaryPartnerTitle ] }/>
          :
          'Heirs'; // The case in which we only show scenario C
    break;
  }
  if( !userAttributes ) {
    return <></>;
  }
  return <div className={ className }>{result}</div>;
};
