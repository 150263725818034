import { ReactElement } from 'react';
import { Route } from 'react-router-dom';

// Views
import HealthcareDirective from '../../../views/Documents/Directive';
import DirectiveAddress from '../../../views/Documents/Directive/DirectiveAddress';
import Agents from '../../../views/Documents/Directive/Agents';
import DirectiveReview from '../../../views/Documents/Directive/DirectiveReview';
import HealthcareDirectiveIntro from '../../../views/Documents/Directive/healthcareDirectiveIntro';
import Wishes from '../../../views/Documents/Directive/Wishes/index';

// Hooks
import useDirectiveSectionStatus from '../../../hooks/useDirectiveSectionStatus';

// Routes
import {
  healthcare_directive_intro as introRoute,
  healthcare_directive_address as addressRoute,
  healthcare_directive as healthcareDirectiveRoute,
  healthcare_directive_agent as agentRoute,
  healthcare_directive_wishes as wishesRoute,
  healthcare_directive_review as reviewRoute,
} from '../../../../core/routes';
import { useSelector } from '../../../store';

// Redux
import productsSelector from '../../../store/selectors/productsSelector';
import useUserAttributes from '../../../hooks/useUserAttributes';

// Helpers
import { directivesSelector } from '../../../store/selectors/directivesSelector';
import DocumentRouter from '../DocumentRouter';

export default function DirectiveRouter(): ReactElement{
  const { hasViewedDocument } = useUserAttributes().documents.healthcareDirective;
  const { pro } = useSelector( productsSelector );
  const { canCompleteDirective } = useDirectiveSectionStatus();
  const directive = useSelector( directivesSelector );

  // this is not a great "tell" of whether someone has viewed the intro, but it's satisfactory
  // what's initially returned after posting a goal _does not_ have a form, so we can't key off of that.
  // there is likely a race condition on the server, or something we're not adequately waiting for
  const hasViewedIntro = !!directive?.id;

  const config = {
    attributes: {
      hasViewedDocument: !!hasViewedDocument,
      hasViewedIntro,
      canCompleteDocument: canCompleteDirective,
      hasPurchasedDocument: pro.hasPurchased,
    },
    intro: {
      path: introRoute.path,
      component: HealthcareDirectiveIntro,
    },
    overview: {
      path: healthcareDirectiveRoute.path,
      component: HealthcareDirective,
    },
    review: {
      path: reviewRoute.path,
      component: DirectiveReview,
    },
  };

  return (
    <DocumentRouter
      document="healthcare_directive"
      { ...config }
    >
      <Route exact path={ addressRoute.path } component={ DirectiveAddress } />
      <Route exact path={ agentRoute.path } component={ Agents }/>
      <Route exact path={ wishesRoute.path } component={ Wishes }/>
    </DocumentRouter>
  );
}
