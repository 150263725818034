import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useInterval from '@use-it/interval';
import { isAndroid } from 'react-device-detect';

// Components
import Button from '../../components/Button';
import CodeField from '../../components/CodeField';
import Form from '../../components/Form';
import Translation from '../../components/Translation';
import { TextField } from '../../components/TextField';

// Actions
import { verifyCode, resetSendCode, sendCode } from '../../store/reducers/authReducer';

// Utils
import { useDispatch, useSelector } from '../../store';
import maskPhone from '../../utils/inputMask/maskPhone';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import useTranslation from '../../hooks/useTranslations';
import { fieldRequired } from '../../formValidators';
import analytics from '../../utils/analytics';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';
import inputMask from '../../utils/inputMask';
import { ethosPartnerCodeSelector } from '../../store/selectors/userSelector';


const CODE_TTL = 1800; // in seconds (hardcoded to +30 MINUTES in CoreDataService)
const MAX_NUMBER_OF_TRIES = 3;
const CODE_FIELD_LENGTH = 6;

interface VerifyCodeFormProps {
  className?: string;
  onCancel?: ()=> void;
  onSuccess?: ()=> void;
}

const VerifyCodeForm: FC<VerifyCodeFormProps> = ({
  className = '',
  onSuccess,
  onCancel,
}) => {

  // Application state
  const isVerifyPending = useSelector( state => state.auth?.verifyCode?.meta?.requestStatus === 'pending' );
  const isUserPending = useSelector( state => state.user?.patchUser?.meta?.requestStatus === 'pending' );

  // include user in pending state to account for referral case
  const isPending = ( isVerifyPending || isUserPending );

  const isSending = useSelector( state => state.auth?.sendCode?.meta?.requestStatus === 'pending' );
  const sentAt = useSelector( state => state.auth?.sendCode?.updatedAt || new Date());
  const phone = useSelector( state => state.auth?.sendCode?.data?.phone );
  const rejectedCount = useSelector( state => state.auth?.verifyCode?.rejectedCount );
  const hasEthosPartnerCode = useSelector( ethosPartnerCodeSelector );
  const { getTranslation } = useTranslation();

  // Use state to count number of seconds since `sentAt` time
  const [ age, setAge ] = useState<number>(
    Math.floor( Math.abs( new Date( sentAt ).getTime() - new Date().getTime()) / 1000 ),
  );
  const numberOfSeconds = CODE_TTL; // update age only once after expires since we aren't displaying a counter
  useInterval(() => {
    setAge( currentAge => currentAge + numberOfSeconds );
  }, numberOfSeconds * 1000 );

  // Instantiate an action dispatcher
  const dispatch = useDispatch();

  // Initialize form
  const { register, setValue, getValues, handleSubmit, reset, errors, formState, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      code: isAndroid? '' :Array( CODE_FIELD_LENGTH ).fill( '' ),
    },
  });

  const useFormProps = {
    register,
    setValue,
    getValues,
    formState,
    errors,
    watch,
  };

  // onSubmit handler
  const onSubmit: SubmitHandler<SanitizedFormData> = ({ code }) => {
    const data = { code };
    dispatch( verifyCode({ data, onSuccess }));
  };

  /**
   * Resend code function
   */
  const resend = () => {
    reset();
    const data = { phone: phone || null };

    const onSuccess = () => {
      dispatch( makeSnack({ message: getTranslation( 'account.signup.code.resend.banner' ) }));
    };

    dispatch( sendCode({ data, onSuccess }));
  };

  useEffect(() => {
    analytics.track( 'View - Registration Verify Phone' );
  }, []);

  // Invalidate code status after it is expired or too many failed attempts
  useEffect(() => {
    if( rejectedCount >= MAX_NUMBER_OF_TRIES || age >= ( CODE_TTL - 5 )){
      dispatch( resetSendCode());
      onCancel instanceof Function && onCancel();
    }
  }, [ age, dispatch, rejectedCount, onCancel ]);

  // If form is not valid, make a snack with the form error
  useEffect(() => {
    if( !!errors.code ){
      dispatch( makeSnack({ message: getTranslation( 'screen.verification.code.banner.failed' ), theme: 'error' }));
    }
  }, [ errors, dispatch ]);

  const codeField = ()=>{
    if( isAndroid ){
      return <TextField { ...useFormProps }
        large
        label="Phone"
        className="mb-4"
        name="code"
        normalize={ inputMask.code }
        placeholder={ getTranslation( 'onboarding.confirmation.code.placeholder' ) }
        required={ fieldRequired( getTranslation( 'profile.verificationCode.label' )) }
        showLabel={ false }
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={ true }
        maxLength={ CODE_FIELD_LENGTH }
        type="tel"
        autoComplete={ 'off' }
      />;
    }else{
      return <CodeField { ...useFormProps } name="code" length={ CODE_FIELD_LENGTH } className="mb-10" />;
    }
  };
  return(
    <Form
      onSubmit={ onSubmit }
      className={ `${className} flex-1 flex flex-col` }
      handleSubmit={ handleSubmit }
    >
      <h1 className="text-3xl mb-10 text-black font-headline">
        <Translation translationKey={ hasEthosPartnerCode ? 'account.signup.code.title.ethos.policy.holder' : 'account.signup.code.title' } /> <span className="whitespace-nowrap">{ phone || maskPhone( '000-000-0000' ) }.</span>
      </h1>
      <div>
        {codeField()}
      </div>
      <div className="flex justify-between items-center">
        <div>
          <Translation translationKey={ 'account.signup.code.resend.title' }/>
          <Button
            theme="clean"
            type="button"
            disabled={ isSending }
            onClick={ resend } >{ isSending ? '...' : <Translation translationKey={ 'account.signup.code.resend.link' } />}</Button>
        </div>
        <div>
          <Button type="submit" isPending={ isPending } id="onboarding-continue-button">
            <Translation translationKey={ 'onboarding.confirmation.code.button' } />
          </Button>
        </div>
      </div>
    </Form>
  );
};


export default VerifyCodeForm;
