import { SelectOption } from '../components/SelectField';
import Relationship from '../core-data-service/models/Relationship';
import useTranslations from './useTranslations';


/**
 * Given person.relationship, check if value is of type in given list of
 * approved relationship types
 * @param relationshipName
 * @param acceptedTypes
 */
export const isOfType = (
  relationshipName: string,
  acceptedTypes: string[],
): boolean => {

  if( acceptedTypes.length === 0 ){
    return true;
  } else {
    return acceptedTypes
      .some( ofType => new Relationship( relationshipName )
        .is( ofType ));
  }
};

/**
 *
 * @param relationshipName
 * @param rejectedTypes
 */
export const isNotOfType = (
  relationshipName: string,
  rejectedTypes: string[],
): boolean => {

  if( rejectedTypes.length === 0 ) {
    return true;
  } else {
    return !rejectedTypes
      .some( notType => new Relationship( relationshipName )
        .is( notType ));
  }
};


/**
 * Custom hook returns select option labels from translation service.
 * Can't use `translationSelector` here because we cannot use `useSelector`
 * inside a callback function (.map).
 */
const useRelationshipOptions = ({ ofType, notType }: {
    ofType?: string;
    notType?: string;
  }): Array<SelectOption> => {
  const acceptedTypes: string[] = !!ofType ? ofType.split( ',' ) : [];
  const rejectedTypes: string[] = !!notType ? notType.split( ',' ) : [];
  const { getTranslation } = useTranslations();
  return Relationship.OPTIONS
    .slice( 1, Relationship.OPTIONS.length )
    .filter( opt => Boolean(
      isOfType( opt.value, acceptedTypes ) &&
      isNotOfType( opt.value, rejectedTypes ),
    ))
    .map( opt => {
      return Object({
        value: opt.value,
        label: getTranslation( opt.translationKey ),
      }) as SelectOption;
    });
};

export default useRelationshipOptions;
