import { useState } from 'react';
import { StripeCardNumberElementOptions, StripeCardNumberElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardCvcElementChangeEvent } from '@stripe/stripe-js';

// Components
import FieldLabel from './FieldLabel';
import React from 'react';

interface IStripeField {
  className?: string;
  label: string;
  name: string;
  options?: StripeCardNumberElementOptions ;
  render: ({
    onChange,
    options,
  }: {
    onChange: ( e:
      StripeCardNumberElementChangeEvent |
      StripeCardExpiryElementChangeEvent |
      StripeCardCvcElementChangeEvent
    )=> void;
    options: StripeCardNumberElementOptions;
  })=> React.ReactNode;
}

const StripeField = ({ className = '', label, name, render, options }: IStripeField ): React.ReactElement => {
  const [ isEmpty, setIsEmpty ] = useState<boolean>( true );

  const onChange = ( e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent ) => {
    if( e.empty !== isEmpty ) {
      setIsEmpty( e.empty );
    }
  };

  return (
    <FieldLabel
      className={ className }
      label={ label }
      name={ name }
    >
      {render({ onChange, options: { ...options } })}
    </FieldLabel>
  );
};

export default StripeField;
