import { ChangeEvent, ReactElement, useState } from 'react';

// Components
import DevOnlyUserAttributesButton from '../../components/devOnly/DevOnlyUserAttributesButton';
import Modal from '../../components/Modal';
import { Switch } from '@material-ui/core';

// Redux
import { useDispatch, useSelector } from '../../store';
import { setUserAttributes } from '../../store/reducers/userReducer';

// Utils
import Button from '../../components/Button';
import { Keyable } from '../../types/Keyable';
import SanitizedFormData from '../../../core/types/SanitizedFormData';
import Icon from '../../components/Icon';

interface IAttributeSwitchProps {
  attributeName: string;
  value: boolean;
  onChange: ( event: ChangeEvent<HTMLInputElement> )=> void;
  onReset: ()=> void;
}

export function UserAttributeSwitch({ attributeName, value, onChange, onReset }: IAttributeSwitchProps ): ReactElement{
  return (
    <div className="flex items-center justify-between w-full">
      <label htmlFor={ attributeName }>{attributeName}</label>
      <Switch checked={ value } onChange={ onChange } id={ attributeName } />
      <button onClick={ onReset }><Icon name="close"/></button>
    </div>
  );
}

export default function DevOnlyUserAttributesModal(): ReactElement{
  const [ isModalOpen, setIsModalOpen ] = useState( false );
  const dispatch = useDispatch();

  const userAttributesFromStore: Keyable = useSelector( state => state.user.data.attributes );
  const userAttrKeysToRender = Object.keys( userAttributesFromStore ).filter( key => key.includes( 'app.' ));

  const handleResetAll = ()=> {
    const newAttributes = userAttrKeysToRender.reduce(( acc, curr ) => {
      acc[curr] = null;
      return acc;
    }, {} as SanitizedFormData );
    dispatch( setUserAttributes( newAttributes ));
  };

  return (
    <>
      {isModalOpen
        ? <Modal
          className="z-50"
          isOpen={ isModalOpen }
          onClose={ ()=> setIsModalOpen( false ) }
        >
          <h1 className="text-[24px] text-center mb-4 text-black font-headline">
            User Attributes Toggle <span role="img" aria-label="Magic Wand">🪄</span>
          </h1>
          <span className="my-4">Toggle the switch to set the attribute to true or false, click the <Icon className="inline mx-2" name="close"/> icon to remove the attribute.</span>
          <div>
            {
              userAttrKeysToRender.length
                ? <>
                  <div className="flex items-center justify-center w-full my-4">
                    <Button theme="clean-danger" onClick={ handleResetAll } type="button" >Reset All</Button>
                  </div>
                  {userAttrKeysToRender.map(( key, idx )=> {
                    const value = userAttributesFromStore[key];
                    return(
                      <UserAttributeSwitch
                        key={ idx }
                        attributeName={ key }
                        value={ value }
                        onChange={ e => {
                          dispatch( setUserAttributes({ [key]: e.target.checked }));
                        } }
                        onReset={ () => {
                          dispatch( setUserAttributes({ [key]: null }));
                        }
                        }
                      />
                    );
                  })}
                </>
                : <span>There are no attributes set</span>
            }
          </div>
        </Modal>
        : <DevOnlyUserAttributesButton handleClick={ ()=> setIsModalOpen( true ) } />
      }
    </>
  );
}
