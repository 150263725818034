const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
];

const deca = [
  'twent',
  'thirt',
  'fort',
  'fift',
  'sixt',
  'sevent',
  'eight',
  'ninet',
];

/**
 * Turn an integer into 'first' 'second', 'third', 'twenty-first' etc...
 * Only works up to 99
 * @param num
 */
export default function stringifyNumber( num: number ): string{
  if ( num < 20 ) {
    return special[ num ];
  }
  if ( num % 10 === 0 ) {
    return deca[Math.floor( num / 10 ) - 2 ] + 'ieth';
  }
  return deca[Math.floor( num / 10 ) - 2 ] + 'y-' + special[num % 10];
}
