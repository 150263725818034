import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Actions
import { patchUser } from '../../store/reducers/userReducer';
import { makeSnack } from '../../store/reducers/snackbarReducer';

// Utils
import { useSelector, useDispatch } from '../../store';
import userSelector from '../../store/selectors/userSelector';
import analytics from '../../utils/analytics';
import { fieldRequired } from '../../formValidators';
import useTranslations from '../../hooks/useTranslations';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import Translation from '../../components/Translation';



interface PromptForEmailProps {
  className?: string;
  onCancel?: ()=> void;
  onSuccess?: ()=> void;
}

interface PromptForEmailFormData extends SanitizedFormData {
  email: string;
}

const PromptForEmail: FC<PromptForEmailProps> = ({
  className,
  onCancel,
  onSuccess,
}) => {

  // Application state
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const user = useSelector( userSelector );
  const isPending = useSelector( state => state.user?.patchUser?.meta?.requestStatus === 'pending' );
  const email = user?.email || '';


  // Form hooks
  const { register, setValue, getValues, handleSubmit, formState } = useForm<PromptForEmailFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      email,
    },
  });

  const { errors } = formState;
  const useFormProps = { register, setValue, getValues, errors };

  // onSubmit handler
  const onSubmit: SubmitHandler<SanitizedFormData> = ({ email }) => {
    dispatch( patchUser({ email, onSuccess, onError }));
  };

  // onError typically will only happen if email already in use
  const onError = ( e: string ) => {
    // don't blindly pass along every message
    if( e === 'Email address already in use.' ) {
      dispatch( makeSnack({ theme: 'error', message: getTranslation( 'screen.onboarding.email.error.alreadyinuse' ) }));
    }
  };

  useEffect(() => {
    analytics.track( 'View - Registration Email' );
  }, []);

  return(
    <Form
      className={ className }
      handleSubmit={ handleSubmit }
      onSubmit={ onSubmit }
    >
      <h1 className="text-3xl mb-6 text-black font-headline"><Translation translationKey={ 'account.signup.email.title' }/></h1>
      <TextField { ...useFormProps }
        large
        className="mb-6"
        label={ <Translation translationKey={ 'screen.onboarding.email.address' } /> }
        name="email"
        required={ fieldRequired( getTranslation( 'error.invalid.email' )) }
        type="email"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={ true }
      />
      <p className="mb-6 text-black-50">
        <Translation translationKey={ 'account.signup.email.desc' }/>
      </p>
      <div className="flex justify-end">
        <Button
          isPending={ isPending }
          id="onboarding-continue-button"
          type="submit"
        >Continue</Button>
      </div>
    </Form>
  );
};

export default PromptForEmail;
