import { RootState } from '..';
import PersonInterface from '../../types/PersonInterface';
import Relationship from '../../core-data-service/models/Relationship';


const peopleSelector = ( state: RootState ): Array<PersonInterface> => {
  return state.people.data.filter( person => person.relationship !== Relationship.NAMES.ME );
};

export const familySelector = ( state: RootState ): Array<PersonInterface> => {
  return state.people.data.filter( person => ![ Relationship.NAMES.ME, Relationship.NAMES.FINANCIAL_ADVISOR ].includes( person.relationship ));
};

export const isFetchingPeople = ( state: RootState ): boolean => {
  return state.people.fetchPeople.meta.requestStatus === 'pending';
};

export const isPendingPersonSave = ( state: RootState ): boolean => {
  return Boolean(
    state.people.patchPerson.meta.requestStatus === 'pending' ||
    state.people.postPerson.meta.requestStatus === 'pending',
  );
};


export const isPendingPersonPhotoUpload = ( state: RootState ): boolean => {
  return state.people.uploadPersonPhoto.meta.requestStatus === 'pending';
};


export const isPendingPersonDelete = ( state: RootState ): boolean =>{
  return state.people.deletePerson.meta.requestStatus === 'pending';
};



export default peopleSelector;
