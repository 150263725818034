import { ReactElement, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

// Components
import Form from '../../../components/Form';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';
import { TextArea } from '../../../components/TextArea';

// Hooks
import useBackstack from '../../../hooks/useBackstack';
import useUserAttributes from '../../../hooks/useUserAttributes';
import useTranslations from '../../../hooks/useTranslations';
import usePowers from '../../../hooks/usePowers';
import { SPECIAL_INSTRUCTIONS } from '../../../hooks/usePoaSectionStatus';

// Routes
import { poa as poaRoute } from '../../../../core/routes';

// Redux
import { useDispatch, useSelector } from '../../../store';
import { patchPower } from '../../../store/reducers/powersReducer';
import { setUserAttributes } from '../../../store/reducers/userReducer';

// Types
import SanitizedFormData from '../../../../core/types/SanitizedFormData';
import { isPendingPowersSave } from '../../../store/selectors/powersSelector';

export default function PoaSpecialInstructions(): ReactElement{
  const { goBack } = useBackstack( poaRoute.get());
  const { push } = useHistory();
  const { powersData, powers } = usePowers();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const {
    documents:{ poa },
  } = useUserAttributes();

  const defaultValues = useMemo(()=>{
    return { instructions: powers[SPECIAL_INSTRUCTIONS] };
  },[ powers ]);

  useEffect(()=> {
    if ( !poa.instructionsChapterStarted ) {
      dispatch( setUserAttributes({
        'app.instructionsChapterStarted': true,
      }));
    }
  },[ dispatch, poa.instructionsChapterStarted ]);

  const isRequestPending = useSelector( isPendingPowersSave );

  const { register, setValue, getValues, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues,
  });

  const useFormProps = {
    register,
    setValue,
    getValues,
    errors,
  };

  const onSubmit: SubmitHandler<SanitizedFormData> = data =>{
    const postPowers = {
      ...powersData[0],
    };
    postPowers.powers  = { ...powers };
    postPowers.powers.instructions = data[SPECIAL_INSTRUCTIONS];
    dispatch( patchPower({
      onSuccess: () => push( poaRoute.get()),
      ...postPowers,
    }));

  };

  return (
    <Form handleSubmit={ handleSubmit } onSubmit={ onSubmit }>
      <SegmentView onRetreat={ goBack }
        primaryActionLabel={ 'Done' }
        primaryActionButtonType={ 'submit' }
        title={ <Translation translationKey={ 'poa.chapter.specialinstructions.title' }/> }
        subtitle={ <Translation translationKey={ 'poa.chapter.specialinstructions.subtitle' }/> }
        primaryActionButtonIsPending={ isRequestPending }
      >
        <TextArea
          { ...useFormProps }
          label={ 'instructions' }
          showLabel={ false }
          name={ 'instructions' }
          placeholder={ getTranslation( 'poa.chapter.specialinstructions.entry.placeholder' ) }/>
      </SegmentView>
    </Form>
  );
}
