import { TranslationKeys } from './../../store/initial/translations/index';
import PropertyInterface from '../../types/Property/PropertyInterface';

/** PROPERTY ICONS */
import tomorrowDark from '../../assets/images/tomorrow_dark.png';
import propertyIcons from '../../assets/images/properties';


/**
 * types
 */
type PropertyIconFilenames = keyof typeof propertyIcons
export type PropertyType = typeof Property.TYPE[keyof typeof Property.TYPE]
export type PropertyTypeKeys = keyof typeof Property.TYPE
export type SubTypes = {
  [K in PropertyType]: {
    [key: string]: string;
  };
}
/**
 * @todo: type of all possible subtypes since this below just === 'string'
 */
export type PropertySubtype = typeof Property.SUBTYPES[PropertyType][number]
export type PropertyValuableTypes = typeof Property.VALUABLE_TYPES[number]

class Property {

  constructor(
    private readonly _data: PropertyInterface,
  ){}

  public get photoUrl(): string{
    return this._data.photo_url || Property.getIcon( this._data.subtype );
  }

  /**
   * given subtype return appropriate icon url
   * assuming the filenames and exported property names from `propertyIcons`
   * module match the below func = replacing special chars and whitespace etc.
   */
  static getIcon( subtype: PropertySubtype|undefined ): string{
    if( !!subtype ){
      const iconName = 'img_' + subtype
        .replace( '&', '__amp__' )
        .replace( '-', '__dash__' )
        .replace( ' ', '_' )
        .toLowerCase() + '_dark' as PropertyIconFilenames;
      return propertyIcons[iconName] || tomorrowDark;
    }
    return tomorrowDark;
  }

  static CLASSIFICATION_TANGIBLE = 'TANGIBLE';
  static CLASSIFICATION_INTANGIBLE = 'INTANGIBLE';

  static TYPE = {
    // TANGIBLE PROPERTY TYPES
    COLLECTION: 'COLLECTION',
    ELECTRONIC: 'ELECTRONIC',
    HOUSEHOLD: 'HOUSEHOLD',
    JEWELRY: 'JEWELRY',
    VEHICLE: 'VEHICLE',
    PET: 'PET',
    RECREATION: 'RECREATION',

    // Intangible
    BANK: 'BANK',
    INVESTMENT: 'INVESTMENT',
    INSURANCE: 'INSURANCE',
    REAL_ESTATE: 'REAL ESTATE',
    PRIVATE_BUSINESS: 'PRIVATE BUSINESS',
    INTELLECTUAL_PROPERTY: 'INTELLECTUAL PROPERTY',
    LIABILITY: 'LIABILITY',
  } as const;

  static TYPES = [
    Property.TYPE.VEHICLE, Property.TYPE.JEWELRY, Property.TYPE.HOUSEHOLD,
    Property.TYPE.ELECTRONIC, Property.TYPE.RECREATION, Property.TYPE.PET, Property.TYPE.COLLECTION,



    Property.TYPE.BANK, Property.TYPE.INVESTMENT,
    Property.TYPE.INSURANCE, Property.TYPE.REAL_ESTATE,
    Property.TYPE.PRIVATE_BUSINESS, Property.TYPE.INTELLECTUAL_PROPERTY,
    Property.TYPE.LIABILITY,
  ];

  static REAL_ESTATE_TYPES = [
    'HOUSE', 'CONDO', 'TOWNHOUSE', 'CO-OP', 'VACATION', 'TIMESHARE', 'LAND', 'BUILDING', 'OTHER PROPERTY',
  ];

  static VALUABLE_TYPES: PropertyType[] = [
    Property.TYPE.ELECTRONIC, Property.TYPE.JEWELRY, Property.TYPE.HOUSEHOLD, Property.TYPE.VEHICLE, Property.TYPE.RECREATION, Property.TYPE.COLLECTION,
  ];

  static ACCOUNT_TYPES: PropertyType[] = [
    Property.TYPE.BANK, Property.TYPE.INVESTMENT, Property.TYPE.INSURANCE, Property.TYPE.REAL_ESTATE, Property.TYPE.PRIVATE_BUSINESS, Property.TYPE.INTELLECTUAL_PROPERTY, Property.TYPE.LIABILITY,
  ];

  static CLASSIFICATION = {
    [Property.TYPE.COLLECTION]: Property.CLASSIFICATION_TANGIBLE,
    [Property.TYPE.ELECTRONIC]: Property.CLASSIFICATION_TANGIBLE,
    [Property.TYPE.HOUSEHOLD]: Property.CLASSIFICATION_TANGIBLE,
    [Property.TYPE.JEWELRY]: Property.CLASSIFICATION_TANGIBLE,
    [Property.TYPE.VEHICLE]: Property.CLASSIFICATION_TANGIBLE,
    [Property.TYPE.PET]: Property.CLASSIFICATION_TANGIBLE,
    [Property.TYPE.RECREATION]: Property.CLASSIFICATION_TANGIBLE,

    [Property.TYPE.BANK]: Property.CLASSIFICATION_INTANGIBLE,
    [Property.TYPE.INVESTMENT]: Property.CLASSIFICATION_INTANGIBLE,
    [Property.TYPE.INSURANCE]: Property.CLASSIFICATION_INTANGIBLE,
    [Property.TYPE.REAL_ESTATE]: Property.CLASSIFICATION_INTANGIBLE,
    [Property.TYPE.PRIVATE_BUSINESS]: Property.CLASSIFICATION_INTANGIBLE,
    [Property.TYPE.INTELLECTUAL_PROPERTY]: Property.CLASSIFICATION_INTANGIBLE,
    [Property.TYPE.LIABILITY]: Property.CLASSIFICATION_INTANGIBLE,
  }

  static SUBTYPES: SubTypes = {
    [Property.TYPE.COLLECTION]: {
      AIRLINE_MILES: 'AIRLINE MILES',
      BOOKS: 'BOOKS',
      COATS: 'COATS',
      COINS: 'COINS',
      DOLLS: 'DOLLS',
      GAMES: 'GAMES',
      INSTRUMENTS: 'INSTRUMENTS',
      JOURNALS: 'JOURNALS',
      MOVIES: 'MOVIES',
      MUSIC: 'MUSIC',
      RECIPES: 'RECIPES',
      VINYL: 'VINYL',
      OTHER_COLLECTION: 'OTHER COLLECTION',
    },
    [Property.TYPE.ELECTRONIC]: {
      CAMERA: 'CAMERA',
      COMPUTER: 'COMPUTER',
      LAPTOP: 'LAPTOP',
      STEREO: 'STEREO',
      TABLET: 'TABLET',
      PHONE: 'PHONE',
      TV: 'TV',
      CONSOLE: 'CONSOLE',
      DRONE: 'DRONE',
      VR: 'VR',
      OTHER_ELECTRONICS: 'OTHER ELECTRONICS',
    },

    [Property.TYPE.HOUSEHOLD]: {
      ARTWORK: 'ARTWORK',
      APPLIANCES: 'APPLIANCES',
      ANTIQUES: 'ANTIQUES',
      BEDDING: 'BEDDING',
      CLOTHING: 'CLOTHING',
      CHINA: 'CHINA',
      COOKWARE: 'COOKWARE',
      FLATWARE: 'FLATWARE',
      FURNITURE: 'FURNITURE',
      GLASSWARE: 'GLASSWARE',
      OTHER_HOUSEHOLD: 'OTHER HOUSEHOLD',
    },

    [Property.TYPE.JEWELRY]: {
      BRACELET: 'BRACELET',
      EARRINGS: 'EARRINGS',
      NECKLACE: 'NECKLACE',
      RING: 'RING',
      WATCH: 'WATCH',
      OTHER_JEWELRY: 'OTHER JEWELRY',
    },

    [Property.TYPE.VEHICLE]: {
      AIRPLANE: 'AIRPLANE',
      CAR: 'CAR',
      VAN: 'VAN',
      BOAT: 'BOAT',
      MOTORCYCLE: 'MOTORCYCLE',
      RV: 'RV',
      SUV: 'SUV',
      TRUCK: 'TRUCK',
      TRAILER: 'TRAILER',
      OTHER_VEHICLE: 'OTHER VEHICLE',
    },

    [Property.TYPE.PET]: {
      CAT: 'CAT',
      DOG: 'DOG',
      BIRD: 'BIRD',
      OTHER_PET: 'OTHER PET',
    },

    [Property.TYPE.RECREATION]: {
      BACKPACK: 'BACKPACK',
      BICYCLE: 'BICYCLE',
      SKATEBOARD: 'SKATEBOARD',
      SKIS: 'SKIS',
      SLEEPING_BAG: 'SLEEPING BAG',
      SNOWBOARD: 'SNOWBOARD',
      SNOWSHOES: 'SNOWSHOES',
      SNOWMOBILE: 'SNOWMOBILE',
      SURFBOARD: 'SURFBOARD',
      TENT: 'TENT',
      KAYAK: 'KAYAK',
      GUNS: 'GUNS',
      OTHER_RECREATION: 'OTHER RECREATION',
    },

    [Property.TYPE.BANK]: {
      CHECKING: 'CHECKING',
      SAVINGS: 'SAVINGS',
      CRYPTO: 'CRYPTO',
    },

    [Property.TYPE.INVESTMENT]: {
      BROKERAGE: 'BROKERAGE',
      '401K': '401K',
      IRA: 'IRA',
      '529': '529',
      EQUITY: 'EQUITY',
      CRYPTO: 'CRYPTO',
      ETF: 'ETF',
      STOCK: 'STOCK',
      MUTUAL_FUND: 'MUTUAL FUND',
    },

    [Property.TYPE.INSURANCE]: {
      'AD&D': 'AD&D',
      TERM_LIFE: 'TERM LIFE',
      PERMANENT_LIFE: 'PERMANENT LIFE',
      ANNUITY: 'ANNUITY',
      FIXED_ANNUITY: 'FIXED ANNUITY',
      JOB_BENEFIT: 'JOB BENEFIT',
      GROUP_LIFE: 'GROUP LIFE',
    },

    [Property.TYPE.REAL_ESTATE]: {
      HOUSE: 'HOUSE',
      CONDO: 'CONDO',
      CO_OP: 'CO-OP',
      VACATION: 'VACATION',
      TIMESHARE: 'TIMESHARE',
      LAND: 'LAND',
      OTHER_PROPERTY: 'OTHER PROPERTY',
    },

    [Property.TYPE.PRIVATE_BUSINESS]: {
      CORPORATION: 'CORPORATION',
      FARM: 'FARM',
      PARTNERSHIP: 'PARTNERSHIP',
    },

    [Property.TYPE.INTELLECTUAL_PROPERTY]: {
      COPYRIGHT: 'COPYRIGHT',
      PATENT: 'PATENT',
      TRADEMARK: 'TRADEMARK',
    },

    [Property.TYPE.LIABILITY]: {
      CREDIT_CARD: 'CREDIT CARD',
      CAR_LOAN: 'CAR LOAN',
      // HOME_EQUITY_LOAN: 'HOME EQUITY LOAN',
      PERSONAL_LOAN: 'PERSONAL LOAN',
      STUDENT_LOAN: 'STUDENT LOAN',
      TAX_DEBT: 'TAX DEBT',
      // AUTO_LOAN: 'AUTO LOAN',
      // LOAN: 'LOAN',
      // OTHER_LIABILITY: 'OTHER LIABILITY',
    },
  };

  static DISPLAY_KEY: Record<PropertyType | string, TranslationKeys> = {
    [Property.TYPE.COLLECTION]: '(belonging)(category)collections(displayname)',
    [Property.TYPE.ELECTRONIC] : '(belonging)(category)electronics(displayname)',
    [Property.TYPE.HOUSEHOLD] : '(belonging)(category)household(displayname)',
    [Property.TYPE.JEWELRY] : '(belonging)(category)jewelry(displayname)',
    [Property.TYPE.VEHICLE] : '(belonging)(category)vehicles(displayname)',
    [Property.TYPE.PET] : '(belonging)(category)pets(displayname)',
    [Property.TYPE.RECREATION] : '(belonging)(category)recreation(displayname)',

    [Property.TYPE.BANK] : '(account)(category)bank(displayname)',
    [Property.TYPE.INVESTMENT] : '(account)(category)investment(displayname)',
    [Property.TYPE.INSURANCE] : '(account)(category)insurance(displayname)',
    [Property.TYPE.REAL_ESTATE] : '(account)(category)realestate(displayname)',
    [Property.TYPE.PRIVATE_BUSINESS] : '(account)(category)business(displayname)',
    [Property.TYPE.INTELLECTUAL_PROPERTY] : '(account)(category)ip(displayname)',
    [Property.TYPE.LIABILITY] : '(account)(category)debt(displayname)',

    // Subtypes
    [Property.SUBTYPES[Property.TYPE.COLLECTION].AIRLINE_MILES]: '(belonging)(type)airlinemiles(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].BOOKS]: '(belonging)(type)books(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].COINS]: '(belonging)(type)coins(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].DOLLS]: '(belonging)(type)dolls(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].COATS]: '(belonging)(type)coats(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].GAMES]: '(belonging)(type)games(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].INSTRUMENTS]: '(belonging)(type)instruments(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].JOURNALS]: '(belonging)(type)journals(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].MOVIES]: '(belonging)(type)movies(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].MUSIC]: '(belonging)(type)music(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].RECIPES]: '(belonging)(type)recipes(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].VINYL]: '(belonging)(type)vinyl(displayname)',
    [Property.SUBTYPES[Property.TYPE.COLLECTION].OTHER_COLLECTION]: '(belonging)(type)othercollection(displayname)',

    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].CAMERA]: '(belonging)(type)camera(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].COMPUTER]: '(belonging)(type)computer(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].LAPTOP]: '(belonging)(type)laptop(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].STEREO]: '(belonging)(type)stereo(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].TABLET]: '(belonging)(type)tablet(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].PHONE]: '(belonging)(type)phone(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].TV]: '(belonging)(type)tv(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].CONSOLE]: '(belonging)(type)console(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].DRONE]: '(belonging)(type)drone(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].VR]: '(belonging)(type)vr(displayname)',
    [Property.SUBTYPES[Property.TYPE.ELECTRONIC].OTHER_ELECTRONICS]: '(belonging)(type)otherelectronics(displayname)',

    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].ARTWORK]: '(belonging)(type)artwork(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].APPLIANCES]: '(belonging)(type)appliances(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].ANTIQUES]: '(belonging)(type)antiques(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].BEDDING]: '(belonging)(type)bedding(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].CLOTHING]: '(belonging)(type)clothing(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].CHINA]: '(belonging)(type)china(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].COOKWARE]: '(belonging)(type)cookware(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].FURNITURE]: '(belonging)(type)furniture(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].FLATWARE]: '(belonging)(type)flatware(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].GLASSWARE]: '(belonging)(type)glassware(displayname)',
    [Property.SUBTYPES[Property.TYPE.HOUSEHOLD].OTHER_HOUSEHOLD]: '(belonging)(type)otherhousehold(displayname)',

    [Property.SUBTYPES[Property.TYPE.JEWELRY].BRACELET]: '(belonging)(type)bracelet(displayname)',
    [Property.SUBTYPES[Property.TYPE.JEWELRY].EARRINGS]: '(belonging)(type)earrings(displayname)',
    [Property.SUBTYPES[Property.TYPE.JEWELRY].NECKLACE]: '(belonging)(type)necklace(displayname)',
    [Property.SUBTYPES[Property.TYPE.JEWELRY].RING]: '(belonging)(type)ring(displayname)',
    [Property.SUBTYPES[Property.TYPE.JEWELRY].WATCH]: '(belonging)(type)watch(displayname)',
    [Property.SUBTYPES[Property.TYPE.JEWELRY].OTHER_JEWELRY]: '(belonging)(type)otherjewelry(displayname)',

    [Property.SUBTYPES[Property.TYPE.VEHICLE].AIRPLANE]: '(belonging)(type)airplane(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].CAR]: '(belonging)(type)car(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].VAN]: '(belonging)(type)van(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].BOAT]: '(belonging)(type)boat(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].MOTORCYCLE]: '(belonging)(type)motorcycle(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].RV]: '(belonging)(type)rv(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].SUV]: '(belonging)(type)suv(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].TRUCK]: '(belonging)(type)truck(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].TRAILER]: '(belonging)(type)trailer(displayname)',
    [Property.SUBTYPES[Property.TYPE.VEHICLE].OTHER_VEHICLE]: '(belonging)(type)othervehicle(displayname)',

    [Property.SUBTYPES[Property.TYPE.PET].CAT]: '(belonging)(type)cat(displayname)',
    [Property.SUBTYPES[Property.TYPE.PET].DOG]: '(belonging)(type)dog(displayname)',
    [Property.SUBTYPES[Property.TYPE.PET].BIRD]: '(belonging)(type)bird(displayname)',
    [Property.SUBTYPES[Property.TYPE.PET].OTHER_PET]: '(belonging)(type)otherpet(displayname)',

    [Property.SUBTYPES[Property.TYPE.RECREATION].BACKPACK]: '(belonging)(type)backpack(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].BICYCLE]: '(belonging)(type)bicycle(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].SKATEBOARD]: '(belonging)(type)skateboard(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].SKIS]: '(belonging)(type)skis(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].SLEEPING_BAG]: '(belonging)(type)sleepingbag(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].SNOWBOARD]: '(belonging)(type)snowboard(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].SNOWSHOES]: '(belonging)(type)snowshoes(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].SNOWMOBILE]: '(belonging)(type)snowmobile(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].SURFBOARD]: '(belonging)(type)surfboard(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].TENT]: '(belonging)(type)tent(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].KAYAK]: '(belonging)(type)kayak(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].GUNS]: '(belonging)(type)guns(displayname)',
    [Property.SUBTYPES[Property.TYPE.RECREATION].OTHER_RECREATION]: '(belonging)(type)otherrecreation(displayname)',

    [Property.SUBTYPES[Property.TYPE.BANK].CHECKING]: '(account)(type)checking(displayname)',
    [Property.SUBTYPES[Property.TYPE.BANK].SAVINGS]: '(account)(type)savings(displayname)',
    [Property.SUBTYPES[Property.TYPE.BANK].CRYPTO]: '(account)(type)crypto(displayname)',

    [Property.SUBTYPES[Property.TYPE.INVESTMENT].BROKERAGE]: '(account)(type)brokerage(displayname)',
    [Property.SUBTYPES[Property.TYPE.INVESTMENT]['401K']]: '(account)(type)a401k(displayname)',
    [Property.SUBTYPES[Property.TYPE.INVESTMENT].IRA]: '(account)(type)ira(displayname)',
    [Property.SUBTYPES[Property.TYPE.INVESTMENT]['529']]: '(account)(type)a529plan(displayname)',
    [Property.SUBTYPES[Property.TYPE.INVESTMENT].CRYPTO]: '(account)(type)crypto(displayname)',
    [Property.SUBTYPES[Property.TYPE.INVESTMENT].EQUITY]: '(account)(type)equity(displayname)',
    [Property.SUBTYPES[Property.TYPE.INVESTMENT].STOCK]: '(account)(type)stock(displayname)',
    [Property.SUBTYPES[Property.TYPE.INVESTMENT].MUTUAL_FUND]: '(account)(type)mutualfund(displayname)',
    [Property.SUBTYPES[Property.TYPE.INVESTMENT].ETF]: '(account)(type)etf(displayname)',

    [Property.SUBTYPES[Property.TYPE.INSURANCE]['AD&D']]: '(account)(type)add(displayname)',
    [Property.SUBTYPES[Property.TYPE.INSURANCE].TERM_LIFE]: '(account)(type)term(displayname)',
    [Property.SUBTYPES[Property.TYPE.INSURANCE].PERMANENT_LIFE]: '(account)(type)permanent(displayname)',
    [Property.SUBTYPES[Property.TYPE.INSURANCE].ANNUITY]: '(account)(type)annuity(displayname)',
    [Property.SUBTYPES[Property.TYPE.INSURANCE].FIXED_ANNUITY]: '(account)(type)fixedannuity(displayname)',
    [Property.SUBTYPES[Property.TYPE.INSURANCE].JOB_BENEFIT]: '(account)(type)jobbenefit(displayname)',
    [Property.SUBTYPES[Property.TYPE.INSURANCE].GROUP_LIFE]: '(account)(type)grouplife(displayname)',

    [Property.SUBTYPES[Property.TYPE.REAL_ESTATE].HOUSE]: '(account)(type)house(displayname)',
    [Property.SUBTYPES[Property.TYPE.REAL_ESTATE].CONDO]: '(account)(type)condo(displayname)',
    [Property.SUBTYPES[Property.TYPE.REAL_ESTATE].CO_OP]: '(account)(type)coop(displayname)',
    [Property.SUBTYPES[Property.TYPE.REAL_ESTATE].VACATION]: '(account)(type)vacation(displayname)',
    [Property.SUBTYPES[Property.TYPE.REAL_ESTATE].TIMESHARE]: '(account)(type)timeshare(displayname)',
    [Property.SUBTYPES[Property.TYPE.REAL_ESTATE].LAND]: '(account)(type)land(displayname)',
    [Property.SUBTYPES[Property.TYPE.REAL_ESTATE].OTHER_PROPERTY]: '(account)(type)otherrealestate(displayname)',

    [Property.SUBTYPES[Property.TYPE.PRIVATE_BUSINESS].CORPORATION]: '(account)(type)corporation(displayname)',
    [Property.SUBTYPES[Property.TYPE.PRIVATE_BUSINESS].FARM]: '(account)(type)farm(displayname)',
    [Property.SUBTYPES[Property.TYPE.PRIVATE_BUSINESS].PARTNERSHIP]: '(account)(type)partnership(displayname)',

    [Property.SUBTYPES[Property.TYPE.INTELLECTUAL_PROPERTY].COPYRIGHT]: '(account)(type)copyright(displayname)',
    [Property.SUBTYPES[Property.TYPE.INTELLECTUAL_PROPERTY].PATENT]: '(account)(type)patent(displayname)',
    [Property.SUBTYPES[Property.TYPE.INTELLECTUAL_PROPERTY].TRADEMARK]: '(account)(type)trademark(displayname)',

    [Property.SUBTYPES[Property.TYPE.LIABILITY].CREDIT_CARD]: '(account)(type)creditcard(displayname)',
    [Property.SUBTYPES[Property.TYPE.LIABILITY].CAR_LOAN]: '(account)(type)carloan(displayname)',
    [Property.SUBTYPES[Property.TYPE.LIABILITY].PERSONAL_LOAN]: '(account)(type)personalloan(displayname)',
    [Property.SUBTYPES[Property.TYPE.LIABILITY].STUDENT_LOAN]: '(account)(type)studentloan(displayname)',
    [Property.SUBTYPES[Property.TYPE.LIABILITY].TAX_DEBT]: '(account)(type)taxdebt(displayname)',
  }

}

export default Property;
