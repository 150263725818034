
/* eslint-disable no-console*/
const log = {
  info: ( message: string ): void => console.log( `%c${message}`, 'color: blue; font-weight: bold;' ),
  error: ( message: string ): void => console.log( `%c${message}`, 'color: red; font-weight: bold;' ),
  orange: ( message: string ): void => console.log( `%c${message}`, 'color: white; background-color: orange; font-weight: bold; padding: .5rem;' ),
};
/* eslint-enable no-console*/

export default log;
