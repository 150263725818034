import { useSelector } from '../store';
import Invitation, { InvitationResponse, InvitationStatus } from '../core-data-service/models/Invitation';
import invitationsSelector from '../store/selectors/invitationsSelector';

interface IUseInvitationHook {
  id?: string;
  url?: string;
  status: InvitationResponse | InvitationStatus;
  firstName: string;
}

const useInvitation = ( personId: string, invitationType = Invitation.TYPE_RELATIONSHIP, roleType = '' ): IUseInvitationHook => {

  const invitations = useSelector( invitationsSelector );

  // roles contain invitations, as do people objects
  // if there is a specific role, find it within the roles
  // otherwise grab from the people object
  const invitation =  roleType
    ? invitations.find( invitation => invitation?.invitee?.id === personId && invitation?.context?.name === roleType )
    : invitations.find( invitation => invitation?.invitee?.id === personId && invitation.type === invitationType );
  const id = invitation?.id;
  const url = invitation?.url;
  // TODO properly type me in response
  const status = invitation?.response as InvitationResponse || invitation?.status as InvitationStatus;

  const firstName = invitation?.invitee?.name || '';

  return {
    id,
    url,
    status,
    firstName,
  };
};

export default useInvitation;
