import { FieldErrors, RegisterOptions, UseFormMethods, Validate } from 'react-hook-form';
import { FC } from 'react';
import FieldLabel from './FieldLabel';
import FieldError from './FieldError';

export interface TextAreaProps {
    autoFocus?: boolean;
    autoComplete?: 'on'|'off';
    children?: never;
    className?: string;
    disabled?: boolean;
    errors: FieldErrors;
    label: string|React.ReactNode;
    maxLength?: number;
    minLength?: RegisterOptions['minLength'];
    name: string;
    placeholder?: string;
    register: UseFormMethods['register'];
    required?: RegisterOptions['required'];
    pattern?: RegisterOptions['pattern'];
    showLabel?: boolean;
    style?: React.CSSProperties;
    validate?: Validate;
    inputClasses?: string;
    inputMode?:  'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    rowCount?: number;
}
export const TextArea: FC<TextAreaProps> =({
  autoFocus,
  autoComplete='off',
  className = '',
  errors,
  disabled,
  inputClasses,
  label,
  maxLength,
  minLength = 0,
  name,
  placeholder = '',
  register,
  pattern,
  required = false,
  showLabel = true,
  style,
  validate,
  inputMode,
  rowCount = 10,
})=>{
  const registerOptions = {
    maxLength,
    minLength,
    required,
    pattern,
    validate,
  } as RegisterOptions;

  const fieldError = errors[name];
  return (
    <FieldLabel label={ label } name={ name } isHidden={ !showLabel } className={ className }>
      <textarea
        className={ `input ${inputClasses}` }
        maxLength={ maxLength }
        name={ name }
        placeholder={ placeholder }
        ref={ register( registerOptions ) }
        style={{ ...style }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={ autoFocus }
        aria-invalid={ !!fieldError }
        inputMode={ inputMode }
        autoComplete={ autoComplete }
        disabled={ disabled }
        rows = { rowCount }
      />
      {
        !!fieldError &&
            <FieldError
              className="mt-2"
              errorMessage={ fieldError.message }
            />
      }
    </FieldLabel>
  );
};
