import { FC } from 'react';

import { nameInitials } from '../../core/utils/stringHelpers';

export interface InitialsAvatarProps {
  className?: string;
  personName: string;
  style?: React.CSSProperties;
}


const InitialsAvatar: FC<InitialsAvatarProps> = ({
  personName,
  className='',
  style = {},
}) => {

  const initials = nameInitials( personName );

  return(
    <div className={
      `
      text-forest
      rounded-full
      overflow-hidden
      aspect-h-1
      aspect-w-1
      ${className}`
    } style={ style }
    >
      <svg viewBox="0 0 100 100">
        <title>{`${personName}'s initial`}</title>
        <circle cx="50" cy="50" r="50" fill="currentColor"/>
        <text
          style={{ fontSize: '3rem', fontFamily: 'GT America, helvetica neue', fontWeight: 400 }}
          textAnchor="middle"
          dominantBaseline="central"
          x="50"
          y="50"
          fill="white"
        >
          { initials }
        </text>
      </svg>
    </div>
  );
};

export default InitialsAvatar;
