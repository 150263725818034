import { ReactElement } from 'react';

// Components
import DescriptiveLink from './DescriptiveLink';

// Types
import { DescriptiveLinkProps } from './DescriptiveLink';


interface ExternalDescriptiveLinkProps extends DescriptiveLinkProps {
  destinationUrl: string;
  onClick?: ()=> void;
}

export default function ExternalDescriptiveLink({ className, destinationUrl, descriptionKey, labelKey, onClick }: ExternalDescriptiveLinkProps ): ReactElement{

  return (
    <a
      href={ destinationUrl }
      target="_blank"
      rel="noopener noreferrer nofollow"
      className={ `${className}` }
      onClick={ onClick }
    >
      <DescriptiveLink descriptionKey={ descriptionKey } labelKey={ labelKey } />
    </a>
  );
}
