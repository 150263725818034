import { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { medical_consent, medical_consent_care_info } from '../../../../../core/routes';
import { toTitleCase } from '../../../../../core/utils/stringHelpers';
import ActionContainer from '../../../../components/ActionContainer';
import AddButton from '../../../../components/AddButton';
import PeopleSelector from '../../../../components/PeopleSelector';
import ProductUpsellHelper from '../../../../components/ProductUpsellHelper';

// Components / Views
import SegmentView from '../../../../components/SegmentView';
import Translation from '../../../../components/Translation';
import Role from '../../../../core-data-service/models/Role';
import Helper from '../../../../components/Helper';

// Routes
import {
  checkout as checkoutRoute,
} from '../../../../../core/routes';

// Hooks
import useCaretakers from '../../../../hooks/useCaretakers';
import useTranslations from '../../../../hooks/useTranslations';
import { useDispatch, useSelector } from '../../../../store';
import { postRole } from '../../../../store/reducers/rolesReducer';
import productsSelector from '../../../../store/selectors/productsSelector';
import CaretakerDocumentSummaryItem from './CaretakerDocumentSummaryItem';

// Utils
import { getEntitlementNameKey } from '../../../../utils/entitlementsUtils';
import { DOCUMENTS } from '../../../../core-data-service/models/Document';
import { generateQueryString } from '../../../../utils/urlUtils';
import { getPersonByID } from '../../../../utils/people/getPersonByID';
import { isAddressComplete } from '../../../../utils/address';

// Redux
import userPersonSelector from '../../../../store/selectors/userPersonSelector';
import Person from '../../../../core-data-service/models/Person';
import entitlementsSelector from '../../../../store/selectors/entitlementsSelector';

export default function MedicalConsentDocumentManagement(): ReactElement{
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const dispatch = useDispatch();

  const { wardId } = useParams<{ wardId: string}>();
  const child = useSelector( getPersonByID( wardId ));
  const openCareInfo = () => {
    push( medical_consent_care_info.get({ wardId }));
  };
  const userPerson = useSelector( userPersonSelector );
  const isCareInfoComplete = Boolean( userPerson?.address );
  const childPerson = child && new Person ( child );
  const childFirstName = childPerson?.firstName || child?.name ||'';

  const userPersonAddress = useSelector( userPersonSelector )?.address;
  const isCompleteAddress = !!userPersonAddress && isAddressComplete( userPersonAddress );

  // Component state
  const [ isAddingCaretaker, setIsAddingCaretaker ] = useState<boolean>( false );
  const { caretakers, potentialCaretakers } = useCaretakers( wardId );

  // temporarily prevent multiple caretakers until MX-876 done
  const [ firstCaretaker, ...rest ] = caretakers;
  const products = useSelector( productsSelector );

  const hasAlreadyPurchasedDocument = useSelector( entitlementsSelector )[DOCUMENTS.medical_consent].isEnabled;

  const handlePersonSelected = ( id: string ) => {
    setIsAddingCaretaker( false );
    dispatch( postRole({
      grantee_id: id,
      name: Role.TYPE.CARETAKER,
      ward: { id: wardId },
    }));
  };

  // @TODO: Make a nicer user experience here when a person doesn't exist and the navigate to this route
  if ( !child ) {
    return (
      <div>This person doesn't exist</div>
    );
  }

  return (
    <SegmentView
      onRetreat={ () => push( medical_consent.get()) }
      title={ <Translation translationKey={ 'medicalconsent.screen.listofchildren.childtile.title' } dynamicStringValues={ [ childFirstName ] }/> }
      hidePrimaryAction={ true }
    >
      {isAddingCaretaker && <PeopleSelector
        isOpen
        roleType={ Role.TYPE.CARETAKER }
        people={ potentialCaretakers }
        optionTypeLabel={ toTitleCase( Role.TYPE.CARETAKER ) }
        onClose={ id => {
          id ? handlePersonSelected( id ) : setIsAddingCaretaker( false );
        } }
        onSelect={ id => {
          handlePersonSelected( id );
        } }
        titleTranslationKey="products.will.select.an.person.title"
      />}
      <ActionContainer
        header={ <Translation translationKey="medicalconsent.screen.listofcartakers.chapter.careinformation.title" /> }
        headerClassName="font-bold"
        primaryActionLabel={ getTranslation( '(button)view' ) }
        primaryAction={ openCareInfo }
      >
        <div className="mt-4">
          <Translation translationKey="medicalconsent.screen.listofcartakers.chapter.careinformation.subtitle"/>
        </div>
        {!isCompleteAddress &&
        <Helper
          className="mt-8"
          theme={ 'error' }
          body={ <Translation translationKey="medicalconsent.screen.childsinformation.chapter.address.error.incomplete"/> }
        />}
      </ActionContainer>

      {!isCareInfoComplete &&
        <Helper
          className="mt-0"
          theme={ 'error' }
          body={ <Translation translationKey="medicalconsent.screen.listofcartakers.chapter.careinformation.error.incomplete"/> }
        />
      }

      <ActionContainer
        className="mt-4 border-t border-grey"
        header={ <Translation translationKey="medicalconsent.screen.listofcartakers.chapter.caretakers.title" /> }
        headerClassName="font-bold pt-8"
      >
        <div className="mt-4"><Translation translationKey="medicalconsent.screen.listofcartakers.chapter.caretakers.subtitle"/></div>
      </ActionContainer>

      { !!firstCaretaker
        ? <CaretakerDocumentSummaryItem person={ firstCaretaker } canOpenDocument={ isCompleteAddress } />
        : <AddButton
          labelTranslationKey="medicalconsent.screen.listofcartakers.button.addcaretaker"
          onClick={ () => setIsAddingCaretaker( true ) }
          iconName={ 'addPerson' }
          className={ 'mt-6' }
        />}

      {!hasAlreadyPurchasedDocument &&
        <ProductUpsellHelper
          className="mt-8"
          title={ getTranslation( 'products.pro.chapter.print.not_started.card.title', [ getTranslation( getEntitlementNameKey( DOCUMENTS.medical_consent )) ]) }
          description={ getTranslation( 'products.pro.chapters.print.not_started.card.description' ) }
          primaryActionLabel={ getTranslation( 'products.pro.chapter.print.button', [ products.pro.price.displayValue ]) }
          primaryAction={ () => push({ pathname: checkoutRoute.get(), search: generateQueryString({ document: DOCUMENTS.medical_consent }) }) }
        />
      }

    </SegmentView>
  );
}
