import { useEffect } from 'react';

// Redux
import { useDispatch } from '../store';
import { showContextualModal } from '../store/reducers/modalReducer';

// Hooks
import useTranslations from './useTranslations';

function useChangingTrustWarningModal( shouldShow: boolean ){
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();

  useEffect(()=> {
    if ( shouldShow ) {
      dispatch( showContextualModal(
        { title: getTranslation( 'products.trust.alerts.making_changes.title' ),
          confirmLabel: getTranslation( 'products.trust.alerts.making_changes.button' ),
          imageAboveHeaderSource: getTranslation( 'modal.warning.image.url' ),
        }));
    }
  }, [ shouldShow ]);
}

export default useChangingTrustWarningModal;
