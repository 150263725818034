const maskDate = ( value: string ): string => {
  // check if first two values...add a slash...etc
  const x = value.replace( /\D/g, '' ).match( /(\d{0,2})(\d{0,2})(\d{0,4})/ );
  return !!x
    ? !x[2] ? x[1] : x[1] + '/' + x[2] + ( x[3] ? '/' + x[3] : '' )
    : '';
};


export default maskDate;
