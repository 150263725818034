import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

// components
import HeirListItem from './HeirListItem';
import HeirsScenarioActionContainer from './HeirsScenarioActionContainer';
import { HeirsScenarioSubhead, HeirsScenarioTitle } from './HeirsStrings';
import InsuranceHelper from '../../components/InsuranceHelper';
import SegmentView from '../../components/SegmentView';
import PersonDetailView from '../People/PersonDetailView';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import useHeirs from '../../hooks/useHeirs';
import useTranslations from '../../hooks/useTranslations';
import useUserAttributes from '../../hooks/useUserAttributes';
import useRenderInsuranceUpsell from '../../hooks/useRenderInsuranceUpsell';


// Redux
import { useSelector } from '../../store';
import { userPersonSelector } from '../../store/selectors/userPersonSelector';

// Types
import Address from '../../core-data-service/models/Address';
import { RelationshipTypes } from '../../core-data-service/models/Relationship';
import { heirsScenarios } from '.';
import { trust } from '../../../core/routes';

const RELATIONSHIP_TYPE: RelationshipTypes = 'SPOUSE';

type ScenarioAProps = {
  documentRoute: string;
  flowRoute: string;
}


const HeirsScenarioA = ({ documentRoute, flowRoute }: ScenarioAProps ): ReactElement => {
  const { push } = useHistory();
  const { goBack } = useBackstack( flowRoute );

  const [ isAddingSpouse, setIsAddingSpouse ] = useState( false );

  const { getTranslation } = useTranslations();

  const userAttributes = useSelector( state => state.user.data.attributes );
  const { spouse, childrenHeirs } = useHeirs();

  // Determine what next step in flow should be
  const hasNoChildren = childrenHeirs.length === 0 && !userAttributes?.['app.hasChildren'];

  const nextPage = !hasNoChildren
    ? heirsScenarios.b
    : heirsScenarios.c;


  const proceed = () => push({ pathname: flowRoute, search: `page=${nextPage}` });
  const scenario = 'primary';

  // @TODO - cleanup - Get users state & percent of spouse default inheritance
  const userPerson = useSelector( userPersonSelector );
  const userState = userPerson?.address.state || '';
  const displayState = Address.US_STATES.find( state => state.value === userState )?.label || '';
  const configStates = useSelector( state => state.config.data.config.states );
  const statePercent = configStates[userState]?.spouse_inheritance_floor || '';
  const spouseLabel = userAttributes?.['app.hasDomesticPartnership'] ? getTranslation( 'products.heirs.scenarios.sections.partner.title' ) : getTranslation( 'products.heirs.scenarios.sections.spouse.title' );
  const scenarioTitle = getTranslation( 'products.heirs.scenarios.sections.spouse.description', [ displayState, spouseLabel.toLowerCase(), `${statePercent}%` ]);
  const { hasChildren } = useUserAttributes();

  const shouldRenderInsuranceHelper = useRenderInsuranceUpsell() && !hasChildren;

  return (
    <SegmentView
      onRetreat={ goBack }
      title={ <HeirsScenarioTitle scenario={ scenario } /> }
      subhead={ <HeirsScenarioSubhead scenario={ scenario } /> }
      hidePrimaryAction={ !spouse }
      primaryActionLabel={ getTranslation( 'products.heirs.scenarios.buttons.next' ) }
      onSecondaryAction={ () =>  push( documentRoute ) }
      secondaryActionChildren={ getTranslation( 'common.finish.later' ) }
      onPrimaryAction={ proceed }
    >
      {
        isAddingSpouse &&
          <PersonDetailView
            renderedAsRoute={ false }
            relationshipType={ RELATIONSHIP_TYPE }
            onSuccess={ () => {
              setIsAddingSpouse( false );
            } }
            onClose={ () => {
              setIsAddingSpouse( false );
            } }
          />
      }
      <HeirsScenarioActionContainer
        title={ <p className="text-black-70">{scenarioTitle}</p> }
        subhead={ <p className="font-bold text-black mb-2">{ spouseLabel }</p> }
        primaryAction={ !spouse ?
          () =>
            setIsAddingSpouse( true )
          : undefined }
        primaryActionLabel={ '(button)add.spouse' }
        hasSpouse={ spouse !== undefined }
      >
        {spouse &&
        <>
          <HeirListItem
            person={ spouse }
            className="mt-8"
            documentContext={ documentRoute === trust.get() ? 'trust' : 'will' }
          />
          {shouldRenderInsuranceHelper &&
            <InsuranceHelper
              className="mt-8"
              eventProperties={{ button: 'HeirsIndividual' }}
            />
          }
        </>
        }
      </HeirsScenarioActionContainer>

    </SegmentView>
  );
};


export default HeirsScenarioA;

