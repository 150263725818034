import { ReactElement, useEffect } from 'react';

// Components
import AgentsSummary from './AgentsSummary';

// Redux
import { useDispatch } from '../../../../store';
import { setUserAttributes } from '../../../../store/reducers/userReducer';

export default function Agents(): ReactElement{
  const dispatch =  useDispatch();
  useEffect(() => {
    dispatch( setUserAttributes({
      'app.healthcareDirectiveAgentChapterStarted': true,
    }));
  },[ dispatch ]);

  return (
    <div className="flex fixed inset-0 bg-white">
      <AgentsSummary />
    </div>
  );
}
