import { ReactElement } from 'react';

// Components
import ActionContainer from '../../components/ActionContainer';
import PersonIcon from '../People/PersonIcon';

// Types
import PersonInterface from '../../types/PersonInterface';
import Person from '../../core-data-service/models/Person';
import InheritanceInterface from '../../types/InheritanceInterface';
import { InheritanceDocumentContext } from './InheritanceShareForm';

// hooks
import { useHistory, useLocation } from 'react-router-dom';
import { DistributionTypes, getDistributionType } from '../../hooks/useHeirs';
import useTranslations from '../../hooks/useTranslations';

// routes
import {
  heir as heirRoute,
} from '../../../core/routes';

// Redux
import { useDispatch, useSelector } from '../../store';
import Translation from '../../components/Translation';
import { showContextualModal } from '../../store/reducers/modalReducer';
import { fetchInheritances } from '../../store/reducers/inheritancesReducer';

interface Props {
  person: PersonInterface;
  className?: string;
  documentContext: InheritanceDocumentContext;
}

export default function HeirListItem({
  person,
  className = '',
  documentContext,
}: Props ): ReactElement{

  const { push } = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();

  /** Find inheritance */
  const inheritance = useSelector( state => state.inheritances.data
    .find( inheritance => inheritance.person_id === person?.id ));

  const shareEditorLocation = {
    pathname: heirRoute.get({ id: person.id, documentContext }),
    state: { background: location },
  };

  const _person = new Person( person );

  const editShares = () => push( shareEditorLocation );

  const showRemoveDisinheritanceModal = () => {
    dispatch( showContextualModal({
      title: getTranslation( 'alert.heir.disinherit.confirmation.trust.title', [ _person.firstName ]),
      confirmLabel: getTranslation( 'alert.heir.disinherit.confirmation.trust.firstbutton.title' ),
      dismissLabel: getTranslation( 'alert.heir.disinherit.confirmation.trust.secondbutton.title' ),
      onDismiss: () => {
        dispatch( fetchInheritances({ shouldReset: true }));
      },
      titleClassName: 'text-red mb-0',
      confirmButtonTheme: 'danger',
    }));
  };


  const isDisinherited = inheritance?.share === 0;

  const primaryAction = isDisinherited ? showRemoveDisinheritanceModal : editShares;


  const inheritancePercentage = `${inheritance?.share || 0}%`;

  return (
    <div className={ `${className}` }>
      <ActionContainer
        headerClassName={ `relative group p-4 rounded ${isDisinherited ? 'bg-red-300' : 'bg-moss'}` }
        key={ person.id }
        primaryAction={ primaryAction }
        primaryActionLabel={ <Translation translationKey="(button)change" /> }
        primaryActionTheme={ isDisinherited ? 'clean-danger' :'clean' }
        primaryActionShouldFillContainer
        header={
          <div className="flex-1 flex flex-row">
            <div className="w-16">
              <PersonIcon
                className="aspect-w-1 aspect-h-1"
                photo_url={ person.photo_url }
                personName={ person.name }
                onImageLoadDuration={ 1 }
                alt="avatar"
              />
            </div>
            <div className="ml-4 flex-1 flex flex-col justify-center">
              <div className="self-stretch flex justify-between">
                <div>
                  <span className="whitespace-nowrap text-black font-bold">
                    { _person.firstName }
                  </span>
                  <span className="whitespace-nowrap mt-2 block">
                    {isDisinherited
                      ? <Translation translationKey="tile.heir.share.subtitle.disinherited" />
                      : documentContext === 'will' || !inheritance
                        ? inheritancePercentage
                        : <Translation translationKey={ getInheritanceKey( inheritance, _person ) } dynamicStringValues={ [ inheritancePercentage ] }/>
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

const getInheritanceKey = ( inheritance: InheritanceInterface, person: Person ) => {

  switch( getDistributionType( inheritance, person )) {
  case DistributionTypes.immediate:
    return 'products.heirs.inheritances.timing.options.paid_now.summary.v2';
  case DistributionTypes.immediateAndLater:
    return 'products.heirs.inheritances.timing.options.paid_now_and_later.summary.v2';
  case DistributionTypes.atAge:
    return 'products.heirs.inheritances.timing.options.paid_at_age.summary.v2';
  default:
    return 'products.heirs.inheritances.timing.options.paid_in_stages.summary.v2' ;
  }
};
