import PersonInterface from '../../types/PersonInterface';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  data: [] as Array<PersonInterface>,
  // for extra reducers
  fetchPeople: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchPerson: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postPerson: {
    meta: {} as AsyncThunkMetaInterface,
  },
  patchPerson: {
    meta: {} as AsyncThunkMetaInterface,
  },
  deletePerson: {
    meta: {} as AsyncThunkMetaInterface,
  },
  uploadPersonPhoto: {
    meta: {} as AsyncThunkMetaInterface,
  },
  deletePersonPhoto: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
