import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

// Data
import initialState from '../initial/directives';
import BaseReduction from '../../types/BaseReduction';
import DirectiveInterface, { AxiosDirectiveResponse, AxiosDirectivesResponse } from '../../types/DirectiveInterface';

type IFetchDirectives = BaseReduction<AxiosDirectivesResponse>

interface IFetchDirective extends BaseReduction<AxiosDirectiveResponse> {
  id: string;
}

type IPatchDirective = IFetchDirective

export const fetchDirectives = createAsyncThunk(
  'directives/fetchDirectives',
  async( args: IFetchDirectives | void, thunkAPI ) => {
    const url = '/v1/healthcare_directives';
    return WebClientRequest
      .get( url )
      .then(( response: AxiosDirectivesResponse ) => {
        const directive = response.data.data;
        thunkAPI.dispatch( updateDirectives( directive ));
        args && args.onSuccess && args.onSuccess( response );
      });
  },
);

export const fetchDirective = createAsyncThunk(
  'directives/fetchDirectives',
  async({ id, onSuccess }: IFetchDirective, thunkAPI ) => {
    const url = `/v1/healthcare_directives/${id}`;
    return WebClientRequest
      .get( url )
      .then(( response: AxiosDirectiveResponse ) => {
        const directive = response.data.data;
        thunkAPI.dispatch( updateDirective( directive ));
        onSuccess && onSuccess( response );
      });
  },
);

export const patchDirective = createAsyncThunk(
  'directives/patchDirective',
  async({ id, onSuccess, ...data }: IPatchDirective, thunkAPI ) => {

    const url = `/v1/healthcare_directives/${id}`;
    return WebClientRequest
      .patch( url, data )
      .then(( response: AxiosDirectiveResponse ) => {
        const directive = response.data.data;
        thunkAPI.dispatch( updateDirective( directive ));
        onSuccess && onSuccess( response );
      });
  },
);


const DirectivesSlice = createSlice({
  name: 'directives',
  initialState,

  reducers: {

    /**
     * Unlike most reducers, we don't "update" existing directives. If you create a directive
     * before logging in, you get the case where there are "two" directives which makes
     * determining the correct one a difficult task!
     */
    updateDirectives( directives, action: PayloadAction<DirectiveInterface[]> ){
      directives.data = action.payload;
    },

    updateDirective( directives, action: PayloadAction<DirectiveInterface> ){
      const payload = action.payload;
      const idx = directives.data.findIndex( directive => directive.id === payload.id );
      if( idx >= 0 ){
        directives.data[idx] = payload;
      } else {
        directives.data.push( payload );
      }
    },
  },

  extraReducers: builder => {

    // fetchDirectives.<status>
    builder.addCase( fetchDirectives.pending, ( directives, action ) => {
      const { arg, ...meta } = action.meta;
      directives.fetchDirectives.meta = meta;
    });
    builder.addCase( fetchDirectives.fulfilled, ( directives, action ) => {
      const { arg, ...meta } = action.meta;
      directives.fetchDirectives.meta = meta;
    });
    builder.addCase( fetchDirectives.rejected, ( directives, action ) => {
      const { arg, ...meta } = action.meta;
      directives.fetchDirectives.meta = meta;
    });

    // patchDirective.<status>
    builder.addCase( patchDirective.pending, ( directives, action ) => {
      const { arg, ...meta } = action.meta;
      directives.patchDirective.meta = meta;
    });
    builder.addCase( patchDirective.fulfilled, ( directives, action ) => {
      const { arg, ...meta } = action.meta;
      directives.patchDirective.meta = meta;
    });
    builder.addCase( patchDirective.rejected, ( directives, action ) => {
      const { arg, ...meta } = action.meta;
      directives.patchDirective.meta = meta;
    });
  },
});


export const { updateDirectives, updateDirective } = DirectivesSlice.actions;

export default DirectivesSlice.reducer;
