import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components / Views
import Agent from './Agent';
import HorizontalRule from '../../../../components/HorizontalRule';
import SegmentView from '../../../../components/SegmentView';
import Translation from '../../../../components/Translation';
import AddButton from '../../../../components/AddButton';

// Hooks
import useBackstack from '../../../../hooks/useBackstack';
import useTranslations from '../../../../hooks/useTranslations';

// Redux
import { useDispatch } from '../../../../store';
import { postRole } from '../../../../store/reducers/rolesReducer';

// Routes
import { healthcare_directive as HealthcareDirectiveRoute } from '../../../../../core/routes';

// Types
import Role from '../../../../core-data-service/models/Role';

// Utils
import analytics from '../../../../utils/analytics';
import PeopleSelector from '../../../../components/PeopleSelector';
import { toTitleCase } from '../../../../../core/utils/stringHelpers';
import useAgents from '../../../../hooks/useAgents';

export default function AgentsSummary(): ReactElement{

  const { push } = useHistory();
  const dispatch = useDispatch();
  const { goBack } = useBackstack( HealthcareDirectiveRoute.get());
  const { getTranslation } = useTranslations();

  const [ isAddingAgent, setIsAddingAgent ] = useState<boolean>( false );

  const { roles, agents, potentialNewAgents } = useAgents();

  /**
   * check if it has a fallback, route to warning if false
   */
  const onPrimaryAction = () => {
    analytics.track( 'Touch - Finish Agents' );
    push( HealthcareDirectiveRoute.get());

  };

  const handlePersonSelected = ( id: string ) => {
    setIsAddingAgent( false );
    dispatch( postRole({
      grantee_id: id,
      name: Role.TYPE.HEALTHCARE_AGENT,
    }));
  };

  const hasExistingAgent = agents.length > 0;

  return (
    <>
      {isAddingAgent && <PeopleSelector
        isOpen
        people={ potentialNewAgents }
        optionTypeLabel={ toTitleCase( Role.TYPE.HEALTHCARE_AGENT ) }
        onClose={ id => {
          id ? handlePersonSelected( id ) : setIsAddingAgent( false );
        } }
        onSelect={ id => {
          handlePersonSelected( id );
        } }
        titleTranslationKey="products.will.select.an.person.title"
      />}
      <SegmentView
        onRetreat={ goBack }
        title={ <Translation translationKey={ 'healthcaredirective.chapter.title.agent' }/> }
        primaryActionLabel={ getTranslation( '(button)done' ) }
        hidePrimaryAction={ false }
        onPrimaryAction={ onPrimaryAction }
        subtitle={ <Translation translationKey="screen.healthcaredirective.agent.subtitle" /> }
      >
        <HorizontalRule className="md:hidden"/>
        {agents.length > 0 &&
          agents.map(( agent, idx ) =>
            <Agent key={ `agent${idx}` }
              className="mb-8"
              agent={ agent }
              idx={ idx }
              role={ roles.find( role => role.grantee_id === agent.id ) }
            />,
          )
        }
        <span className="font-bold">
          <Translation translationKey={ hasExistingAgent ? 'tile.role.title.backup' : 'tile.role.healthcareagent.title.primary' } />
        </span>
        <AddButton
          onClick={ () => setIsAddingAgent( true ) }
          iconName={ 'addPerson' }
          className={ 'mt-4' }
        />
      </SegmentView>
    </>
  );
}
