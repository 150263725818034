import { ReactElement, ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components / Views
import ActionContainer from '../../components/ActionContainer';
import Trustee from './Trustee';
import SegmentView from '../../components/SegmentView';
import Translation from '../../components/Translation';
import AddButton from '../../components/AddButton';

// Hooks
import useTrustees from '../../hooks/useTrustees';
import useBackstack from '../../hooks/useBackstack';
import useTranslations from '../../hooks/useTranslations';
import useUserAttributes from '../../hooks/useUserAttributes';

// Redux
import { useDispatch } from '../../store';
import { postRole } from '../../store/reducers/rolesReducer';
import { showContextualModal, showInviteModal } from '../../store/reducers/modalReducer';

// Routes
import { trust as trustRoute } from '../../../core/routes';

// Types
import Role from '../../core-data-service/models/Role';

// Utils
import analytics from '../../utils/analytics';
import PeopleSelector from '../../components/PeopleSelector';
import { toTitleCase } from '../../../core/utils/stringHelpers';
import { setUserAttributes } from '../../store/reducers/userReducer';
import stringifyNumber from '../../utils/stringifyNumber';
import OpenVideoButton from '../../components/OpenVideoButton';


export const getTrusteeDescription = ( idx: number ): ReactNode => {
  const descriptions = [
    <Translation translationKey="products.trust.chapters.trustees.sections.primary.description"/>,
    <Translation translationKey="products.trust.chapters.trustees.sections.backup.description" />,
    <></>,
  ];
  return descriptions[Math.min( idx, 2 )];
};

const getTrusteeHeader = ( idx: number ): ReactNode => {
  const headers = [
    <Translation translationKey="products.trust.chapters.trustees.sections.primary.title" />,
    <Translation translationKey="products.trust.chapters.trustees.sections.backup.title" />,
  ];

  if( idx > 1 ) {
    return <span>{toTitleCase( stringifyNumber( idx ))} {headers[1]}</span>;
  }

  return headers[idx];
};

export default function TrusteesSummary(): ReactElement{

  const { push } = useHistory();
  const dispatch = useDispatch();
  const { goBack } = useBackstack( trustRoute.get());
  const { getTranslation } = useTranslations();

  const [ isAddingTrustee, setIsAddingTrustee ] = useState<boolean>( false );

  const {
    roles,
    trustees,
    potentialNewTrustees,
    uninvitedTrusteesFirstNames,
  } = useTrustees();

  const { hasSeenTrusteesInviteModal, documents: { trust: { hasViewedTrusteeBackupAlert } } } = useUserAttributes();

  const proceed = () => push( trustRoute.get());
  /**
   * check if it has a fallback, route to warning if false
   */
  const onPrimaryAction = () => {
    analytics.track( 'Touch - Finish Trustees' );
    if ( !hasViewedTrusteeBackupAlert && trustees.length === 1 ) {
      dispatch( showContextualModal(
        {
          title: getTranslation( 'products.trust.chapters.trustees.alerts.backup_reminder.title' ),
          description: getTranslation( 'products.trust.chapters.trustees.alerts.backup_reminder.description' ),
          confirmLabel: getTranslation( 'products.trust.chapters.trustees.alerts.backup_reminder.button' ),
          onConfirm: () => {
            dispatch( setUserAttributes({ 'app.trusteeBackupReminderAlertViewed': true }));
          },
        },
      ));
    } else if( !hasSeenTrusteesInviteModal && trustees.length > 0 ) {
      dispatch( showInviteModal({
        firstNames: uninvitedTrusteesFirstNames,
        roleLabel: Role.TYPE.TRUSTEE,
        onDismiss: proceed,
      }));
    } else {
      proceed();
    }
  };

  const handlePersonSelected = ( id: string ) => {
    setIsAddingTrustee( false );
    dispatch( postRole({
      grantee_id: id,
      name: Role.TYPE.TRUSTEE,
    }));
  };

  return (
    <>
      {isAddingTrustee && <PeopleSelector
        isOpen
        people={ potentialNewTrustees }
        optionTypeLabel={ toTitleCase( Role.TYPE.TRUSTEE ) }
        onClose={ id => {
          id ? handlePersonSelected( id ) : setIsAddingTrustee( false );
        } }
        onSelect={ id => {
          handlePersonSelected( id );
        } }
        titleTranslationKey="products.will.select.a.person.title"
      />}
      <SegmentView
        onRetreat={ goBack }
        title={ <Translation translationKey="products.trust.chapters.trustees.title"/> }
        subtitle={ <>
          <Translation translationKey="products.trust.chapters.trustees.description" />
          <OpenVideoButton
            labelKey="products.trust.intro.video_button"
            videoSrc={{ portrait: 'video.trust.trustee', landscape: 'video.trust.trustee.landscape' }}
          />
        </>
        }
        primaryActionLabel={ getTranslation( 'products.will.chapter.actions.finish' ) }
        hidePrimaryAction={ false }
        onPrimaryAction={ onPrimaryAction }
      >
        {
          trustees.map(( trustee, idx ) =>
            <Trustee key={ `Trustee${idx}` }
              className="mb-4"
              trustee={ trustee }
              idx={ idx }
              role={ roles.find( role => role.grantee_id === trustee.id ) }
            />,
          )
        }
        <ActionContainer
          header={ getTrusteeHeader( trustees.length ) }
          primaryActionShouldFillContainer={ false }
          headerClassName="mb-2 text-black font-bold"
        >
          { getTrusteeDescription( trustees.length ) }
          <AddButton
            onClick={ () => setIsAddingTrustee( true ) }
            iconName={ 'addPerson' }
            className={ 'mt-4' }
          />
        </ActionContainer>
      </SegmentView>
    </>
  );
}
