import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';
import InvitationInterface from '../../types/InvitationInterface';

const data = [] as Array<InvitationInterface>;

if ( window.invitation_data ) {
  data.push( window.invitation_data );
}

const initialState = {
  data,
  // for extra reducers
  fetchInvitations: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchInvitation: {
    meta: {} as AsyncThunkMetaInterface,
  },
  patchInvitation: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
