import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from '../store';
import { setUserAttributes } from '../store/reducers/userReducer';
import { UserAttributes } from '../types/UserInterface';

// Cmponents
import { Button } from './Button';
import { Modal } from './Modal';
import Translation from './Translation';
import VideoPlayer, { VideoSourceMap } from './VideoPlayer';

interface VideoModalProps {
  isOpen: boolean;
  source: VideoSourceMap;
  onClose: ()=> void;
  onSkip?: ()=> void;
  onEnded?: ()=> void;
  hasViewedKey?: keyof UserAttributes;
}

export default function VideoModal({ hasViewedKey, isOpen, source, onClose, onSkip, onEnded }: VideoModalProps ): ReactElement{
  const dispatch = useDispatch();
  const userAttributes = useSelector( state => state.user.data.attributes );

  useEffect(() => {
    if( hasViewedKey && !userAttributes[hasViewedKey]) {
      dispatch( setUserAttributes({ [hasViewedKey]: true }));
    }
  }, [ hasViewedKey, dispatch, userAttributes ]);

  return (
    <Modal
      isOpen={ isOpen }
      onClose={ onClose }
      className="m-0 h-screen w-screen"
      contentClassName="p-0 bg-transparent !w-full !h-full rounded-none pointer-events-none shadow-none"
    >
      <div className="absolute inset-0 flex">
        <VideoPlayer source={ source } className="pointer-events-auto"
          onEnded={ onEnded }/>
      </div>
      {onSkip &&
        <Button id="video-skip-button" onClick={ onSkip } theme="light" small className="absolute z-10 top-4 right-4 md:top-0 md:right-0 pointer-events-auto">
          <Translation translationKey="(button)skip"/>
        </Button>
      }
    </Modal>
  );
}
