import JWTInterface from '../types/JWTInterface';


export const decodeJWT = ( accessToken: string ): JWTInterface|undefined => {
  try {
    const tokenParts = accessToken.split( '.' );
    const header = JSON.parse( atob( tokenParts[0]));
    const payload = JSON.parse( atob( tokenParts[1]));
    const signature = tokenParts[2];
    return [
      header,
      payload,
      signature,
    ];
  } catch( error ) {
    console.error( '[cryptoUtils/decodeJWT] Token decode failed.' );
  }
};
