import { useLocation } from 'react-router-dom';
import LocationState from '../types/LocationState';



/**
 * A custom hook that builds on `useLocation` to parse query string parameters
 */
function useQuery(): URLSearchParams{
  return new URLSearchParams( useLocation<LocationState>().search );
}

export default useQuery;
