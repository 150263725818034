import { RootState } from '../../store';
import PowersInterface from '../../types/powersInterface';

export const powersSelector = ( state: RootState ): PowersInterface[] => {
  return state.powers.data;
};

export const isPendingPowersSave = ( state: RootState ): boolean => {
  return state.powers.patchPower.meta.requestStatus === 'pending';
};
