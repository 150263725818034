import { ENV_DEVELOPMENT, ENV_PRODUCTION } from './constants';

const {
  AIRBRAKE_PROJECT_ID,
  AIRBRAKE_PROJECT_KEY,
  CORE_DATA_SERVICE_BASE_URL,
  CORE_DATA_SERVICE_CLIENT_ID,
  CORE_DATA_SERVICE_CLIENT_SECRET,
  SEGMENT_CUSTOM_SCRIPT_SRC,
  STRIPE_KEY,
  DEBUG,
  GTAG_ID,
  LOG_LEVEL,
  NODE_ENV,
  PRODUCT_BASIC_SKU,
  PRODUCT_PRO_SKU,
  REACT_APP_AIRBRAKE_PROJECT_ID,
  REACT_APP_AIRBRAKE_PROJECT_KEY,
  REACT_APP_BRANCH_KEY,
  REACT_APP_CORE_DATA_SERVICE_BASE_URL,
  REACT_APP_DEPLOYMENT_ENV,
  REACT_APP_PRODUCT_BASIC_SKU,
  REACT_APP_PRODUCT_PRO_SKU,
  REACT_APP_SEGMENT_API_KEY,
  REACT_APP_WEB_SERVER_BASE_URL,
  REACT_APP_GTAG_ID,
  REACT_APP_SEGMENT_CUSTOM_SCRIPT_SRC,
  REACT_APP_STRIPE_KEY,
  REACT_APP_WEBSOCKET_URL,
  REACT_APP_ETHOS_BASE_URL,
  REACT_APP_SALESFORCE_ORGID,
  REACT_APP_SALESFORCE_BASE,
  REDIS_HOST,
  REDIS_PORT,
  SEGMENT_API_KEY,
  SERVER_PORT,
  SESSION_ENGINE,
  SESSION_NAME,
  SESSION_SECRET,
  SESSION_SECURE,
  SESSION_TTL,
  WEB_SERVER_BASE_URL,
} = process.env;

const env = NODE_ENV || ENV_PRODUCTION;

const appConfig = {
  app: {
    baseurl: WEB_SERVER_BASE_URL || REACT_APP_WEB_SERVER_BASE_URL || 'https://app.tomorrow.me',
    ethosBaseUrl: 'https://estateplanning.ethoslife.com',
    debug: !!JSON.parse( DEBUG || 'false' ) || NODE_ENV === ENV_DEVELOPMENT,
    env,
    deploymentEnv: REACT_APP_DEPLOYMENT_ENV || env,
    port: parseInt( SERVER_PORT || '8080' ),
  },
  api: {
    baseurl: CORE_DATA_SERVICE_BASE_URL || REACT_APP_CORE_DATA_SERVICE_BASE_URL || 'https://api.tomorrow.me/',
    client_credentials: {
      id: CORE_DATA_SERVICE_CLIENT_ID,
      secret: CORE_DATA_SERVICE_CLIENT_SECRET,
    },
    websocket: REACT_APP_WEBSOCKET_URL || 'https://websocket.tomorrow.me',
  },
  branch: {
    key: REACT_APP_BRANCH_KEY || '',
  },
  log: {
    level: LOG_LEVEL || 'info',
  },
  segment: {
    apiKey: SEGMENT_API_KEY || REACT_APP_SEGMENT_API_KEY || '',
    customScriptSrc: SEGMENT_CUSTOM_SCRIPT_SRC || REACT_APP_SEGMENT_CUSTOM_SCRIPT_SRC || '',
  },
  session: {
    engine: SESSION_ENGINE || 'redis',
    name: SESSION_NAME || 'tomorrow-web-app-sess',
    ttl:  parseInt( SESSION_TTL || '1209600000' ),
    secret: SESSION_SECRET,
    isSecure: !!JSON.parse( SESSION_SECURE || 'false' ) || NODE_ENV === ENV_PRODUCTION,
  },
  airbrake: {
    projectId: parseInt( AIRBRAKE_PROJECT_ID || REACT_APP_AIRBRAKE_PROJECT_ID || '1' ),
    projectKey: AIRBRAKE_PROJECT_KEY || REACT_APP_AIRBRAKE_PROJECT_KEY || '_',
  },
  gtag: {
    id: GTAG_ID || REACT_APP_GTAG_ID || '',
  },
  redis: {
    host: REDIS_HOST || 'localhost',
    port: parseInt( REDIS_PORT || '6379' ),
  },
  fb: {
    pixelId: '371304739897905',
  },
  wordpress: {
    apiUrl: 'https://tomorrow.me/wp-json/wp/v2',
  },
  stripe: {
    key: STRIPE_KEY || REACT_APP_STRIPE_KEY || '',
  },
  products: {
    // premiumWill: {
    //   sku: PREMIUM_WILL_SKU || REACT_APP_PREMIUM_WILL_SKU || '',
    // },
    basic: {
      sku: PRODUCT_BASIC_SKU || REACT_APP_PRODUCT_BASIC_SKU || '',
    },
    pro: {
      sku: PRODUCT_PRO_SKU || REACT_APP_PRODUCT_PRO_SKU || '',
    },
  },
  marketing: {
    iosUrl: 'https://app.tmro.com/DCZbDjrwqfb',
  },
  ethos: {
    baseUrl: REACT_APP_ETHOS_BASE_URL || 'https://app.ethoslife.com',
  },
  salesforce: {
    orgid: REACT_APP_SALESFORCE_ORGID || '',
    baseUrl: REACT_APP_SALESFORCE_BASE || '',
  },
};


export default appConfig;
