// Defaults
import AsyncThunkMetaInterface from '../../../types/AsyncThunkMetaInterface';
import defaultSettings, { Settings } from '../settings';
import defaultJSON from './default.json';

export interface TranslationDict {
  [key: string]: string;
}

// Initial state
export const initialState = {
  data: {
    'en-US': defaultJSON,
  },
  meta: {
    tests: {} as TranslationDict,
  },
  fetchTranslations: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


// Get default values and infer type
export const defaultLang: Settings['language'] = defaultSettings.language;
export const defaultDict: TranslationDict = initialState.data[defaultLang];
export type TranslationKeys = keyof typeof defaultJSON


export default initialState;
