import { ReactElement, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LocationDescriptorObject } from 'history';

// Components
import Form from '../../components/Form';
import InsurancePropertyForm from './Accounts/Forms/InsurancePropertyForm';
import IntagiblePropertyDefaultForm from './Accounts/Forms/IntangiblePropertyDefaultForm';
import LiabilityPropertyForm from './Accounts/Forms/LiabilityPropertyForm';
import PetsPropertyForm from './Pets/PetsPropertyForm';
import PrivateBusinessPropertyForm from './Accounts/Forms/PrivateBusinessPropertyForm';
import PropertyFormActions from './PropertyFormActions';
import RealEstatePropertyForm from './RealEstate/RealEstatePropertyForm';
import SegmentView from '../../components/SegmentView';
import Translation from '../../components/Translation';
import ValuablesPropertyForm from './Valuables/ValuablesPropertyForm';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import useTranslations from '../../hooks/useTranslations';

// Redux
import { useDispatch, useSelector } from '../../store';
import { pop } from '../../store/reducers/backstackReducer';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import { deleteProperty, patchProperty, postProperty } from '../../store/reducers/propertiesReducer';

// Routes
import {
  accounts,
  will_pets as petsRoute,
  will_realEstate as realEstateRoute,
  will_valuables as valuablesRoute,
} from '../../../core/routes';

// Types
import AddressInterface from '../../types/AddressInterface';
import PersonInterface from '../../types/PersonInterface';
import Property, { PropertyType } from '../../core-data-service/models/Property';
import { AxiosPropertyResponse } from '../../types/Property/PropertyInterface';

// Utils
import { ACCOUNTS_URL_NAMESPACE, PETS_URL_NAMESPACE, REAL_ESTATE_URL_NAMESPACE } from '../../../core/constants';
import analytics from '../../utils/analytics';
import SanitizedFormData from '../../../core/types/SanitizedFormData';
import { Keyable } from '../../types/Keyable';
import { usePropertyParams } from '../../hooks/usePropertyParams';


export interface PropertyFormValues extends AddressInterface {
  name: string;
  value: string;
  number: string;
  purchase_price: string;
  institution_name: string;
  owners: PersonInterface['id'] | null;
  beneficiaries: PersonInterface['id'][] | PersonInterface['id'] | null;
}

export default function PropertyDetailView(): ReactElement{
  const dispatch = useDispatch();
  const location = useLocation();
  const { getTranslation } = useTranslations();

  const handleGoBack = () => {
    dispatch( pop());
    goBack();
  };

  // Get property
  const { id } = useParams<{ id?: string}>();
  const properties = useSelector( state => state.properties.data );
  const property = properties?.find( property => property.id === id );
  const userPerson = useSelector( userPersonSelector );
  const { category, subtype } = usePropertyParams( property );
  const { goBack } = useBackstack( getPropertyBackRoute( location ));

  // Form stuff
  const defaultValues = useMemo(
    () => {
      const potentialBeneficiaries = property?.beneficiaries.filter( owner => owner.id !== userPerson?.id ).map( people => people.id ) || null;

      let beneficiaries: string | string[] | null = potentialBeneficiaries;
      if( category !== Property.TYPE.PET ) {
        beneficiaries = beneficiaries ? beneficiaries[0] : null;
      }

      return {
        name: property?.name || '',
        number: property?.number || null,
        ...property?.address,
        balance: property?.balance?.toString() || null,
        institution_name: property?.institution_name,
        value: property?.value?.toString() || null,
        term: property?.term?.toString() || null,
        purchase_price: property?.purchase_price?.toString() || null,
        owners: property?.owners.filter( owner => owner.id !== userPerson?.id ).map( people => people.id )[0],
        beneficiaries,
      } as PropertyFormValues;
    },
    [ property, userPerson, category ],
  ) as PropertyFormValues;

  const {
    formState: { errors },
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    shouldUnregister: false,
  });

  /**
   * Do this after successfully creating a new property
   * @param property
   */
  const onPostSuccess = ( response: AxiosPropertyResponse ) => {
    const property = response.data.data;
    dispatch( makeSnack({ message: getTranslation( 'form.actions.create.success.v2', [ property?.name|| property?.subtype ]) }));
    handleGoBack();
  };

  /**
   * Do this after successfully updating an existing property
   * @param property
   */
  const onPatchSuccess = ( response: AxiosPropertyResponse ) => {
    const property = response.data.data;
    analytics.track( `Touch - Add ${property.type}`, { type: property.subtype });
    dispatch( makeSnack({ message: getTranslation( 'form.actions.update.success', [ property?.name|| property?.subtype ]) }));
    goBack();
  };

  const onSubmit: SubmitHandler<SanitizedFormData> = ( formData ): void => {

    if( Boolean( !property?.type && !category ) || ( !property?.subtype && !subtype )){
      throw new Error(
        'PropertyForm.onSubmit -> Missing property type or subtype',
      );
    }
    const {
      street,
      street2,
      city,
      state,
      zip,
      balance,
      backup,
      beneficiaries,
      institution_name,
      guardian,
      number,
      name,
      owners,
      purchase_price,
      term,
      value,
    } = formData;

    const address = {
      street,
      street2,
      city,
      state,
      zip,
    };

    const data: Keyable = {
      address,
      beneficiaries: beneficiaries ? [{ id: beneficiaries }] : [],
      institution_name,
      name,
      number,
      owners: owners? [{ id: owners }] : [],
      type: category || property?.type,
      subtype: subtype || property?.subtype,
    };

    // Numbers
    Object.keys({ balance, term, value, purchase_price }).forEach( formProperty => {
      if( formData[formProperty] !== undefined ) {
        data[formProperty] = Number( formData[formProperty]) || null;
      }
    });

    // PETS CUSTOM BENEFICIARIES
    if( category === Property.TYPE.PET ) {
      const petsBeneficiaries = [];
      if( guardian ) {
        petsBeneficiaries.push({ id: guardian });
      }
      if( backup ) {
        petsBeneficiaries.push({ id: backup });
      }
      data.beneficiaries = petsBeneficiaries;
    }

    if( !!property ) {
      dispatch( patchProperty({
        ...data,
        id: property.id,
        onSuccess: ( response: AxiosPropertyResponse ) => onPatchSuccess( response ),
      }));
    } else {
      dispatch( postProperty({ ...data,
        onSuccess: ( response: AxiosPropertyResponse ) => onPostSuccess( response ),
      }));
    }
  };

  const onDelete = () => {
    if( !!property ){
      dispatch( deleteProperty({ id: property.id, onSuccess: () => {
        dispatch( makeSnack({
          message: getTranslation( 'form.actions.remove.success.v2', [ property.name || 'property' ]),
          theme: 'warning',
        }));
      },
      }));
    }
    goBack();
  };

  /**
   * Update form values after property is fetched from API
   */
  useEffect(() => {
    reset( defaultValues );
  }, [ defaultValues, reset ]);

  const formProps = {
    errors,
    clearErrors,
    getValues,
    register,
    setValue,
    control,
  };

  if( !category ) {
    return <></>;
  }

  return(
    <SegmentView
      title={
        property?.name ||
        <span className="capitalize">
          <Translation translationKey={ Property.DISPLAY_KEY[subtype || ''] } />
        </span>
      }
      onRetreat={ handleGoBack }
      hidePrimaryAction={ true }
    >
      <Form
        onSubmit={ onSubmit }
        handleSubmit={ handleSubmit }
      >

        {renderPropertyForm( category, formProps )}

        <PropertyFormActions
          onDelete={ onDelete }
          property={ property }
        />
      </Form>
    </SegmentView>
  );
}

const SimpleIntangibles: PropertyType[] = [ Property.TYPE.BANK, Property.TYPE.INVESTMENT, Property.TYPE.INTELLECTUAL_PROPERTY ];
// @todo - fix any
const renderPropertyForm = ( category: PropertyType, formProps: any ) => {

  if( SimpleIntangibles.includes( category )) {
    return <IntagiblePropertyDefaultForm { ...formProps } />;
  }
  if( category === Property.TYPE.INSURANCE ) {
    return <InsurancePropertyForm { ...formProps } />;
  }
  if( category === Property.TYPE.PRIVATE_BUSINESS ) {
    return <PrivateBusinessPropertyForm { ...formProps } />;
  }
  if( category === Property.TYPE.LIABILITY ) {
    return <LiabilityPropertyForm { ...formProps } />;
  }
  if( category === Property.TYPE.PET ) {
    return <PetsPropertyForm { ...formProps }  />;
  }
  if( category === Property.TYPE.REAL_ESTATE ) {
    return <RealEstatePropertyForm { ...formProps } />;
  }
  return <ValuablesPropertyForm { ...formProps } />;
};

const getPropertyBackRoute = ( location: LocationDescriptorObject ) => {
  const path = location?.pathname;

  if( path?.includes( ACCOUNTS_URL_NAMESPACE )) {
    return accounts.get();
  }

  if( path?.includes( PETS_URL_NAMESPACE )) {
    return petsRoute.get();
  }

  if( path?.includes( REAL_ESTATE_URL_NAMESPACE )) {
    return realEstateRoute.get();
  }

  return valuablesRoute.get();
};
