import io from 'socket.io-client';
import appConfig from '../../core/appConfig';

// Redux
import store from '../store';
import { fetchDirective } from '../store/reducers/directivesReducer';
import { updateInheritances, normalize as normalizeInheritance } from '../store/reducers/inheritancesReducer';
import { destroyInvitation } from '../store/reducers/invitationsReducer';
// import { updateProperty } from '../store/reducers/propertiesReducer';
import { destroyRole } from '../store/reducers/rolesReducer';
import { setUserAttributes, updateUserEntitlements } from '../store/reducers/userReducer';
import { NonNormalizedInheritanceInterface } from '../types/InheritanceInterface';

// Types
// import PropertyInterface from '../types/Property/PropertyInterface';
import { UserEntitlements } from '../types/UserInterface';

export const WebsocketEvent = {
  identifyUser: 'identify',
  userEntitlementsUpdated: 'UserEntitlementsUpdated',
  roleDeleted: 'RoleDeleted',
  propertyBeneficiaryDeleted: 'PropertyBeneficiaryDeleted',
  propertyOwnerDeleted: 'PropertyOwnerDeleted',
  invitationDeleted: 'InvitationDeleted',
  healthcareDirectiveCreated: 'HealthcareDirectiveCreated',
  inheritancesRecalculated: 'InheritancesRecalculated',
} as const;

export const socket = io( appConfig.api.websocket, {
  // this is default, but JSDOM is unhappy without it: https://github.com/facebook/jest/issues/8613
  transports: [ 'polling', 'websocket' ],
});

socket.on( WebsocketEvent.userEntitlementsUpdated, ( res: { data: { entitlements: UserEntitlements}}) => {
  store.dispatch(  updateUserEntitlements( res.data.entitlements ));
});

socket.on( WebsocketEvent.roleDeleted, ( res: { data: {role_id: string}}) => {
  /**
   * If you are removing an attorney in fact and your document is no longer valid because of it, make sure to "reset"
   * applicable user attr
   */
  const roles = store.getState().roles.data;
  const shouldResetPoaDocumentViewedAttr = roles.find( role => ( role.name === 'ATTORNEY IN FACT' && role.id === res.data.role_id ))
   && roles.filter( role => role.name === 'ATTORNEY IN FACT' ).length === 1;

  if( shouldResetPoaDocumentViewedAttr ) {
    store.dispatch( setUserAttributes({ 'app.poaDocumentViewed': false }));
  }
  store.dispatch( destroyRole({ id: res.data.role_id }));
});

// socket.on( WebsocketEvent.propertyBeneficiaryDeleted, ( res: { data: PropertyInterface }) => {
//   store.dispatch( updateProperty( res.data ));
// });

// socket.on( WebsocketEvent.propertyOwnerDeleted, ( res: { data: PropertyInterface }) => {
//   store.dispatch( updateProperty( res.data ));
// });

socket.on( WebsocketEvent.invitationDeleted, ( res: { data: {invitation_id: string} }) => {
  store.dispatch( destroyInvitation({ id: res.data.invitation_id }));
});

socket.on( WebsocketEvent.healthcareDirectiveCreated, ( res: { data: {id: string}}) => {
  store.dispatch( fetchDirective({ id: res.data.id }));
});

// TODO figure out how to use me effectively
// this is fired when an inheritance is deleted and usually chained with fetchInheritances
// socket.on( 'InheritanceDeleted', ( res: { data: { inheritance_id: string}}) => {
//   store.dispatch( removeInheritance({ id: res.data.inheritance_id }));
// });

socket.on( WebsocketEvent.inheritancesRecalculated, ( res: { data: NonNormalizedInheritanceInterface[] }) => {
  store.dispatch( updateInheritances( res.data.map( normalizeInheritance )));
});

socket.on( 'connect', () => {
  if( store.getState().user?.data?.id ) {
    socket.emit( WebsocketEvent.identifyUser, store.getState().user.data.id );
  }
});
