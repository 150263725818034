import { ReactElement } from 'react';
import { useHistory } from 'react-router';

// Components
import { Button } from '../components/Button';

// Hooks
import useBackstack from '../hooks/useBackstack';

// Routes
import { dashboard as dashboardRoute } from '../../core/routes';


export default function FourOhFour(): ReactElement{
  const { location, push } = useHistory();
  const { goBack } = useBackstack();
  return (
    <div className="container w-full flex-1 flex flex-col justify-center">
      <h1 className="text-4xl mb-8 front-bold">Sorry, page not found.</h1>
      <div className="flex">
        {/* if location has history show go back button */}
        {location.state &&
          <Button small theme="ghost" className="mr-4" onClick={ goBack }>Go Back</Button>
        }
        <Button small  theme="ghost" onClick={ () => push( dashboardRoute.get()) }>Return home</Button>
      </div>
    </div>
  );
}
