export type InvitationResponse = typeof Invitation.RESPONSE[keyof typeof Invitation.RESPONSE];
export type InvitationStatus = typeof Invitation.STATUS[keyof typeof Invitation.STATUS];


class Invitation {
  static STATUS = {
    CREATED: 'created',
    SENT: 'sent',
  } as const;

  static RESPONSE = {
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
  } as const;

  static TYPE_ROLE = 'role';
  static TYPE_RELATIONSHIP = 'relationship';
  static TYPE_PROPERTY_OWNER = 'property owner';
  static TYPE_PROPERTY_BENEFICIARY = 'property beneficiary';
  static TYPE_PET_GUARDIAN = 'pet guardian';
  static TYPE_INHERITANCE = 'inheritance';
  static TYPE_REFERRAL = 'referral';
}

export default Invitation;
