import React, { ReactElement } from 'react';

interface Props {
  items: string[];
  limit?: number;
  className?: string;
}

export default function CommaSeparatedStrings({ items, limit = 4, className = '' }: Props ): ReactElement{
  const _limit = Math.min( items.length, limit );
  return (
    <span className={ `${className}` }>
      {items.length >1 ?
        items.slice( 0, _limit )
          .map(( value, i ) => (
            <span key={ `${value}-${i}` }>
              {i !== _limit - 1 ?
                `${value}${i === _limit - 2 ? '' : ','} `
                :
                `${items.length > _limit ?
                  `${value} and ${items.length - _limit} more`
                  :
                  `and ${value}`}`
              }
            </span>
          ))
        :
        items[0]
      }
    </span>
  );
}
