import { Children } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';

// Hooks
import useAuth from '../hooks/useAuth';

// Routes
import SignInModal from '../views/SignInModal';

type AuthenticatedRouteProps = {
  path: RouteProps['path'];
  component?: React.ElementType;
  exact?: RouteProps['exact'];
};
const AuthenticatedRoute: React.FunctionComponent<AuthenticatedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { hasUserRegistered } = useAuth();
  const location = useLocation();
  return (
    <Route
      { ...rest }
      render={ () => {
        if ( hasUserRegistered ) {
          if ( Component ) {
            return <Component />;
          } else {
            return rest.children;
          }
        } else {
          return <SignInModal routeAfterSignIn={ location.pathname }/>;
        }
      }
      }
    />
  );
};

export default AuthenticatedRoute;
