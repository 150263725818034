import { ReactElement, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

// Components
import Helper from './Helper';
import Translation from './Translation';

// Routes
import {
  dashboard,
  lifeInsurance as lifeInsuranceRoute,
} from '../../core/routes';

// Types
import LocationState from '../types/LocationState';

// Utils
import analytics from '../utils/analytics';


interface SegmentEventProps {
  [key: string]: string;
}

interface Props {
  eventProperties: SegmentEventProps;
  className?: string;
  trackingEventId?: string;
  body?: string | React.ReactNode;
  primaryActionLabel?: string | React.ReactNode;
}

export default function InsuranceHelper({ className='', eventProperties, body, primaryActionLabel }: Props ): ReactElement{
  const { push } = useHistory();
  const location = useLocation<LocationState>();

  const events = useRef( eventProperties );

  useEffect(() => {
    analytics.track( 'View - Upsell Button', events.current );
  }, []);

  return (
    <Helper
      className={ className }
      body={
        body || <Translation translationKey="upsell.messagebubble.heirs.message" />
      }
      primaryActionLabel={
        primaryActionLabel || <Translation translationKey="upsell.messagebubble.heirsteir1.button" />
      }
      primaryAction={ () => {
        push({
          pathname: lifeInsuranceRoute.get(),
          state: { background: location || { pathname: dashboard.get() } },
        });
        analytics.track( 'Touch - Upsell Button', eventProperties );
      } }
    />
  );
}
