

export const EXPECTED_LENGTH = 17;

const maskPhone = ( value: string ): string => {

  const countryCode = '+1';

  if( !value || value === countryCode || value === countryCode.slice( 0,1 )) {
    return '';
  }

  const splitValue: Array<string> = value
    .replace( countryCode, '' )
    .replace( /\D/g, '' )
    .replace( /[0,1]{0,1}/, '' )
    .match( /(\d{0,3})(\d{0,3})(\d{0,4})/ ) || [];

  const areaCode = splitValue[1];
  const prefix = splitValue[2];
  const lineNumber = splitValue[3];
  const separator = !!lineNumber.length ? '-' : '';

  const phoneNumber = !prefix.length ? areaCode
    : `(${areaCode}) ${prefix}${separator}${lineNumber}`;

  return `${countryCode} ${phoneNumber}`;
};


export default maskPhone;
