// Redux
import { useSelector } from '../store';

// Types
import Person from '../core-data-service/models/Person';
import Role from '../core-data-service/models/Role';
import PersonInterface from '../types/PersonInterface';
import RoleInterface from '../types/RoleInterface';
import isDefined from '../utils/isDefined';
import minorChildrenSelector from '../store/selectors/minorChildrenSelector';

const useCaretakers = ( wardId: PersonInterface['id']): {
  caretakers: PersonInterface[];
  potentialCaretakers: PersonInterface[];
  roles: RoleInterface[];
} => {

  const people = useSelector( state => state.people.data );

  const roles = useSelector( state =>
    state.roles.data
      .filter( role => role.name === Role.TYPE.CARETAKER && role.ward_id === wardId )
      .sort(( first, second ) => first.priority > second.priority ? 1 : -1 ),
  );

  const caretakers: PersonInterface[] = roles
    .map( role => people.find( person => person.id === role.grantee_id ))
    .filter( isDefined );

  const minorChildren = useSelector( minorChildrenSelector );

  // @TODO: Refactor duplicated logic between this and guardians
  const potentialCaretakers = useSelector( state =>
    state.people.data
      .filter( _person => {
        const person = new Person( _person );
        return Boolean(
          person.isAdult !== false &&
          _person.relationship !== 'ME' &&
          !minorChildren.find( child => child.id === _person.id ) &&
          !person.relationship.is( 'PHYSICIAN' ));
      }))
    .filter( caretaker => !roles.find( role => role.grantee_id === caretaker.id && role.ward_id === wardId ));

  return {
    caretakers,
    potentialCaretakers,
    roles,
  };
};


export default useCaretakers;
