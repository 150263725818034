import { ReactElement } from 'react';

// Components
import CurrencyField from '../../../../components/CurrencyField';
import TextField from '../../../../components/TextField';
import Translation from '../../../../components/Translation';

// Hooks
import useTranslations from '../../../../hooks/useTranslations';

// Utils
import { PropertyDetailFormInterface } from '../../../../types/Property/PropertyDetailFormInterface';
import { fieldRequired } from '../../../../formValidators/fieldRequired';

export default function IntagiblePropertyDefaultForm({
  control,
  errors,
  clearErrors,
  getValues,
  register,
  setValue,
}: PropertyDetailFormInterface ): ReactElement{

  const { getTranslation } = useTranslations();

  const useFormProps = {
    register,
    setValue,
    errors,
    getValues,
    clearErrors,
  };

  return(
    <>
      <TextField { ...useFormProps }
        name="name"
        label={ <Translation translationKey="property.field.name.label" /> }
        required={ fieldRequired( getTranslation( 'property.field.name.required' )) }
        autoComplete="off"
        className="mb-8"
      />

      <CurrencyField
        errors={ errors }
        label={ <Translation translationKey="property.field.balance.label" /> }
        name="balance"
        control={ control }
        className="mb-8"
      />

      <TextField { ...useFormProps }
        name="number"
        label={ <Translation translationKey="property.field.accountNumber.label" /> }
        autoComplete="off"
        className="mb-8"
      />
    </>
  );
}

