import { FC, ReactElement } from 'react';
import SegmentView from '../../components/SegmentView';
import useBackstack from '../../hooks/useBackstack';

interface InsuranceLicense {
  state: string;
  license: string;
}

export const LICENSES: InsuranceLicense[] = [
  { state: 'AK', license: '100139016, 100138521' },
  { state: 'AL', license: '779799, 777185' },
  { state: 'AR', license: '100169143, 7896630' },
  { state: 'AZ', license: '1800015974, 7896630' },
  { state: 'CA', license: '0L57249, 0L45711' },
  { state: 'CO', license: '526652, 525647' },
  { state: 'CT', license: '002545885, 002544539' },
  { state: 'DC', license: '3000051676, 3000046911' },
  { state: 'DE', license: '3000051695, 3000046895' },
  { state: 'FL', license: 'L098956, W379552' },
  { state: 'GA', license: '193140, 3115909' },
  { state: 'HI', license: '448295, 447789' },
  { state: 'IA', license: '1002284413, 7896630' },
  { state: 'ID', license: '600416, 598811' },
  { state: 'IL', license: '3000051733, 7896630' },
  { state: 'IN', license: '3211515, 3208198' },
  { state: 'KS', license: '814555175-0, 7896630' },
  { state: 'KY', license: 'DOI-940396, DOI-939132' },
  { state: 'LA', license: '717757, 716423' },
  { state: 'MA', license: '2019973, 2017728' },
  { state: 'MD', license: '3000051724, 3000046865' },
  { state: 'ME', license: 'AGN280460, PRN279741' },
  { state: 'MI', license: '0111379, 7896630' },
  { state: 'MN', license: '40506242, 40504486' },
  { state: 'MO', license: '8400595, 8394945' },
  { state: 'MS', license: '15030787, 10463573' },
  { state: 'MT', license: '3000059577, 3000046918' },
  { state: 'NC', license: '814555175, 7896630' },
  { state: 'ND', license: '3000051613, 7896630' },
  { state: 'NE', license: '100261860, 7896630' },
  { state: 'NH', license: '2359709, 2358644' },
  { state: 'NJ', license: '1630508, 1629420' },
  { state: 'NM', license: '100014954, 441354' },
  { state: 'NV', license: '3211014, 3208056' },
  { state: 'NY', license: 'LA-1457055, 7896630' },
  { state: 'OH', license: '1135377, 1132601' },
  { state: 'OK', license: '100280694, 100279377' },
  { state: 'OR', license: '100302773, 7896630' },
  { state: 'PA', license: '799577, 797640' },
  { state: 'RI', license: '3000051603, 3000046863' },
  { state: 'SC', license: '210918, 844693' },
  { state: 'SD', license: '10019366, 40436095' },
  { state: 'TN', license: '2354733, 2353580' },
  { state: 'TX', license: '2158060, 2150504' },
  { state: 'UT', license: '600201, 598594' },
  { state: 'VA', license: '141066, 1017060' },
  { state: 'VT', license: '3211123, 3208422' },
  { state: 'WA', license: '938139, 937501' },
  { state: 'WI', license: '100223808, 7896630' },
  { state: 'WV', license: '100234702, 7896630' },
  { state: 'WY', license: '328766, 327687' },
];


const Paragraph: FC = ({ children }) =>
  <p className="mb-4">{ children }</p>;


export default function LicenseInfo({
  isFromIOS = false,
}: {
  isFromIOS?: boolean;
}): ReactElement{

  const { goBack } = useBackstack();

  return (
    <main className="flex fixed inset-0 bg-white">
      <SegmentView
        onRetreat={ goBack }
        title="Tomorrow's Approach to Life Insurance."
        hidePrimaryAction
        shouldHideBackButton={ isFromIOS }
      >
        <Paragraph>
          Tomorrow makes it simple to get the life insurance coverage you need.
        </Paragraph>
        <Paragraph>
          Once you complete your Will and Trust using Tomorrow, you now have your
          most important legal documents in place. Congratulations on completing
          this important first step! Now your Trust Fund needs the right
          financial backing to ensure your family has enough income to thrive. We
          know you work hard and are doing smart things with your money, but
          chances are you haven't yet saved enough cash for your family to live
          off of if anything were to happen to you.
        </Paragraph>
        <Paragraph>
          That's where life insurance steps in.
        </Paragraph>
        <Paragraph>
          Life insurance is an affordable way to fill in the gap between what
          your family will need and what you have saved. There are many types of
          life insurance, but most financial advisors will tell you that term
          life insurance is not only the most affordable way to look out for your
          family, but also the right kind of life insurance for most people.
        </Paragraph>
        <Paragraph>
          Now that you've set up your Tomorrow Trust Fund, we have enough
          information to recommend the right type and amount of life insurance.
          Then with answers to a handful of questions, we get you the best quotes
          from top carriers with strong financials, and we help you buy your
          policy from right inside the app.  We make it as simple and painless as
          possible.
        </Paragraph>
        <Paragraph>
        Get started today and protect your family's financial future!
        </Paragraph>

        <h1 className="font-headline mb-4">
         Tomorrow's Life Insurance Licenses
        </h1>

        <Paragraph>
          Tomorrow delivers personalized life insurance coverage recommendations
          and quotes, which means we need to hold insurance licenses in every
          state we do business. All solicitations and applications to purchase
          insurance in Tomorrow are made through Tomorrow Insurance Services, LLC
          via Joshua Heckathorn, principal broker, who is licensed in 50 states
          and the District of Columbia. Our licenses are as follows:
        </Paragraph>
        <ul>
          {
            LICENSES.map( license =>
              <li key={ license.state }>
                { license.state }: { license.license }
              </li>,
            )
          }
        </ul>
      </SegmentView>
    </main>
  );
}

