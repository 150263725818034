import { ReactElement } from 'react';

// Components
import Modal from './Modal';

export interface ActionSheetProps  {
  title: string;
  buttonsToRender: {[key: string]: {label: string; action: ()=> void}};
  isOpen: boolean;
  handleClose: ()=> void;
  handleModalDismissed?: ()=> void;
}
export default function ActionSheet({
  title,
  buttonsToRender,
  isOpen,
  handleClose,
  handleModalDismissed,
}:
  ActionSheetProps ): ReactElement{

  return (
    <Modal
      isOpen={ isOpen }
      type="action-sheet"
      toggleModal={ handleClose }
      onClose={ handleModalDismissed }
    >
      <ul className="text-center -m-8">
        <li className="py-4 font-bold">
          {title}
        </li>
        {Object.values( buttonsToRender ).map(( button, idx ) => {
          return (
            <li key={ idx } className="border-grey border-t font-bold text-forest">
              <button onClick={ button.action } className="py-5 w-full h-full focus:outline-none">
                {button.label}
              </button>
            </li>
          );
        })
        }
      </ul>
    </Modal>
  );
}
