import React, { ReactElement, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Flow from '../../../components/Flow';
import LifeUpsellModal from '../../LifeInsurance/LifeUpsellModal';
import VideoSegmentView from '../../Video/VideoSegmentView';

// Hooks
import useFlowQueryParams from '../../../hooks/useFlowQueryParams';
import useUserAttributes from '../../../hooks/useUserAttributes';
import useHasPurchasedAndCompletedTrust from '../../../hooks/useHasPurchasedAndCompletedTrust';

// Models
import Document from '../../../core-data-service/models/Document';

// Redux
import { useDispatch, useSelector } from '../../../store';
import { setUserAttributes } from '../../../store/reducers/userReducer';

// Routes
import {
  will as willRoute,
  will_review as willReviewRoute,
} from '../../../../core/routes';

// Types
import { VideoSourceMap } from '../../../components/VideoPlayer';
import useRenderInsuranceUpsell from '../../../hooks/useRenderInsuranceUpsell';
import DocumentComplete from '../DocumentComplete';
import useQuery from '../../../hooks/useQuery';

// Utils
import { generateQueryString } from '../../../utils/urlUtils';

export const WillReviewFlowPages = {
  upsell: 'upsell',
  completed: 'completed',
};

export default function WillReview(): ReactElement{
  const { push } =  useHistory();
  const dispatch = useDispatch();

  const hasPurchasedAndCompletedTrust = useHasPurchasedAndCompletedTrust();

  const documentType = hasPurchasedAndCompletedTrust ? Document.TYPE_POUROVER_WILL : Document.TYPE_SIMPLE_WILL;

  const isAttrsPending = useSelector( state => state.user.setUserAttributes.meta.requestStatus === 'pending' );

  const { documents: { will: { willPreviewViewed } } } = useUserAttributes();
  const hasSeenCurrentWill = useRef( willPreviewViewed );

  const setWillReviewedAttribute = ( isWillPreviewViewed: boolean, onSuccess?: ()=> void ) => {
    dispatch( setUserAttributes({ 'app.willPreviewViewed': isWillPreviewViewed, onSuccess }));
  };

  const handleMakeChangesAction = () => {
    setWillReviewedAttribute( false, () => push( willRoute.get()));
  };

  const handlePushToWillReview = () => {
    dispatch( setUserAttributes({
      'app.viewedFinishedWillUpsell': true,
      onSuccess: () => {
        push({
          pathname: willReviewRoute.get(),
          search: generateQueryString({ page: WillReviewFlowPages.completed }),
        });
      },
    }));
  };

  // TODO respect user attribute associated with viewing insurance upsell
  const shouldViewInsuranceUpsells = useRenderInsuranceUpsell();

  const queryParamsToRender = shouldViewInsuranceUpsells
    ? Object.values( WillReviewFlowPages )
    : Object.values( WillReviewFlowPages ).filter( val => val !== 'upsell' );

  /* If life insurance should be hidden, let's remove it */
  const initialPage = useQuery().get( 'page' ) || undefined;
  const currentIndex = useFlowQueryParams( queryParamsToRender, initialPage );

  const videoProps: VideoSourceMap = { portrait: 'video.will.finish', landscape: 'video.will.finish.landscape' };

  const reactComponentsToRender: ReactElement[] = [
    <VideoSegmentView
      src={ videoProps }
      title="Congratulations"
      onComplete={ () => {
        dispatch( setUserAttributes({
          'app.viewedFinishedWillVideo': true,
          onSuccess: () => {
            push({
              pathname: willReviewRoute.get(),
              search: `?page=${queryParamsToRender[0]}`,
            });
          },
        }));
      } }
      onRetreat={ () => push( willRoute.get()) }
      hidePrimaryAction={ true }
    />,
    <DocumentComplete
      documentType={ documentType }
      hasSeenView={ !!hasSeenCurrentWill.current }
      onRender={ ()=> setWillReviewedAttribute( true ) }
      onClickEdit={ handleMakeChangesAction }
    />,
  ];

  // add the insurance modal if necessary
  if( shouldViewInsuranceUpsells ) {
    reactComponentsToRender.splice( 1, 0,
      <LifeUpsellModal
        onClose={ handlePushToWillReview }
        onSecondaryAction={ handlePushToWillReview }
        isPending={ isAttrsPending }
        primaryActionEventId="CompletedWillFullScreen"
      /> );
  }

  return (
    <div className="flex fixed inset-0 bg-white">
      <Flow currentIndex={ currentIndex } >
        {reactComponentsToRender.map(( item, i ) => {
          return (
            React.cloneElement( item, { key: `willReview-view-${i}` })
          );
        })}
      </Flow>
    </div>
  );
}
