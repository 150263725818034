import { useState, useEffect } from 'react';
import { BREAKPOINT_LARGE, BREAKPOINT_MEDIUM } from './../settings';


type breakpoint = {
  breakpoint: 'small' | 'medium' | 'large';
  width: number;
}

const getClientWidth = (): number => window.innerWidth
|| document.documentElement.clientWidth
|| document.body.clientWidth;

const getBreakpoint = ( width = getClientWidth()): breakpoint => {
  return {
    breakpoint: width >= BREAKPOINT_LARGE ? 'large' : width >= BREAKPOINT_MEDIUM ? 'medium' : 'small',
    width,
  };
};

export const useCurrentBreakpoint = ( onLoadOnly?: boolean ): breakpoint   => {

  const [ hasRan, setHasRan ] = useState( false );
  const [ breakpoint, setBreakpoint ] = useState<breakpoint>( getBreakpoint());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    if( !hasRan ) {
      setHasRan( true );

      // save current window width in the state object
      setBreakpoint( getBreakpoint());
    }

    // only get breakpoint once
    if( onLoadOnly ) {
      return;
    }

    // timeoutId for debounce mechanism
    let timeoutId: number;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout( timeoutId );
      // change width from the state object after 150 milliseconds
      timeoutId = window.setTimeout(() => setBreakpoint( getBreakpoint()), 150 );
    };
    // set resize listener
    window.addEventListener( 'resize', resizeListener );

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener( 'resize', resizeListener );
    };
  }, [ hasRan, onLoadOnly ]);

  return breakpoint;
};
