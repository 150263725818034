import { ReactElement } from 'react';

// Components
import OverlappingAvatars from '../../components/OverlappingAvatars';
import CommaSeparatedStrings from '../../components/CommaSeparatedStrings';

interface ProfileProps {
  src: string;
  name: string;
}
interface Props {
  profiles: ProfileProps[];
  firstNames?: string[];
}

export default function DocumentSectionProfiles({ profiles, firstNames }: Props ): ReactElement{
  const profilesCount = profiles.length;

  const nameStrings = firstNames || profiles.map( profile => profile.name );

  const className = profilesCount > 3
    ? 'flex-col'
    : 'flex flex-row';

  const paragraphStyleClasses = profilesCount > 3 ? 'mt-4' : 'ml-3';

  return(
    <div className={ className } >
      <OverlappingAvatars
        items={ profiles }
        limit={ 4 }
      />
      <div className={ 'flex flex-col justify-center' }>
        <p className={ paragraphStyleClasses }>
          <CommaSeparatedStrings
            items={ nameStrings }
          />
        </p>
      </div>
    </div>
  );
}
