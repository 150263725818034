import { ReactElement, useEffect, useState } from 'react';
import CircleImage from '../../components/CircleImage';
import Modal from '../../components/Modal';
import Translation from '../../components/Translation';
import Property, { PropertySubtype, PropertyType } from '../../core-data-service/models/Property';

interface Props {
  category: PropertyType | undefined;
  isOpen: boolean;
  onClose: ()=> void;
  onItemSelect: ( subtype: PropertySubtype )=> void;
}

export default function PropertySubtypePicker({
  category,
  isOpen,
  onClose,
  onItemSelect,
}: Props ): ReactElement{

  const [ isVisible, setIsVisible ] = useState<boolean>( isOpen );

  // @TODO - global refactor of modal transitions needed
  useEffect(() => {
    setIsVisible( isOpen );
  }, [ isOpen ]);

  if( !category ) {
    return <></>;
  }

  const subtypes = Property.SUBTYPES[category];

  return (
    <Modal
      toggleModal={ value => setIsVisible( value ) }
      onClose={ onClose }
      isOpen={ isVisible }
    >
      <h1 className="text-3xl mb-8 font-headline text-black"><Translation translationKey="screen.choosevaluable.title"/> </h1>
      <ul className="grid grid-cols-2 md:grid-cols-3 gap-8">
        {
          subtypes
            ? Object.values( subtypes )
              .map( subtype => {
                return(
                  <li className="text-center" key={ subtype }>
                    <button
                      className="block w-full"
                      onClick={ () => onItemSelect( subtype ) }
                    >
                      <CircleImage
                        imageSrc={ Property.getIcon( subtype ) }
                        alt="icon name"
                        className="mb-4"
                      />
                      <span className="capitalize"><Translation translationKey={ Property.DISPLAY_KEY[subtype] }/></span>
                    </button>
                  </li>
                );
              })

            :null
        }
      </ul>
    </Modal>
  );
}
