
/**
 * `isDefined` type guard:
 *
 * Use it as your type predicate:
 *    const foo: number[] = [1, 2, undefined, 4].filter(isDefined)
 *
 * Using a proper type guard (instead of taking a shortcut and relying on
 * implicit coercion) helps TypeScript pick this specific overload.
 *
 * https://stackoverflow.com/questions/43010737/way-to-tell-typescript-compiler-array-prototype-filter-removes-certain-types-fro
 */
export default function isDefined<T>( argument: T | undefined ): argument is T{
  return argument !== undefined;
}
