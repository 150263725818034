import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from '..';
import { MODAL_TRANSITION_DURATION_MS } from '../../../core/constants';
import { GlobalContextualModalProps } from '../../components/GlobalContextualModal';
import { InviteModalProps } from '../../components/InviteModal';
import { ShareActionSheetProps } from '../../components/ShareActionSheet';
import { ChildrenHeirsLifeUpsellModalProps } from './../../views/modals/ChildrenHeirsLifeUpsellModal';
import { GlobalVideoModalProps } from '../../views/modals/GlobalVideoModal';

import initialState, { GenericModals, GlobalModal, GlobalModals, initialProps } from '../initial/modal';
import { EditCaretakerActionSheetProps } from '../../components/EditCaretakerActionSheet';


const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    // TODO can we standardize the `showModal` function?
    showShareModal( modalState, action: PayloadAction<ShareActionSheetProps> ){
      modalState.data.shareActionSheet = { ...modalState.data.shareActionSheet, isOpen: true, isRendered: true, modalProps: { ...action.payload } };
    },
    showInviteModal( modalState, action: PayloadAction<InviteModalProps> ){
      modalState.data.inviteModal = { ...modalState.data.inviteModal, isOpen: true, isRendered: true, modalProps: { ...action.payload } };
    },
    showChildrenHeirsUpsellLifeModal( modalState, action: PayloadAction<ChildrenHeirsLifeUpsellModalProps> ){
      modalState.data.childrenHeirsLifeUpsell = { ...modalState.data.childrenHeirsLifeUpsell, isOpen: true, isRendered: true, modalProps: { ...action.payload } };
    },
    showVideoModal( modalState, action: PayloadAction<GlobalVideoModalProps> ){
      modalState.data.videoModal = { ...modalState.data.videoModal, isOpen: true, isRendered: true, modalProps: { ...action.payload } };
    },
    showContextualModal( modalState, action: PayloadAction<GlobalContextualModalProps> ){
      modalState.data.contextualModal = { ...modalState.data.contextualModal, isOpen: true, isRendered: true, modalProps: { ...action.payload } };
    },
    showEditCaretakerModal( modalState, action: PayloadAction<EditCaretakerActionSheetProps> ){
      modalState.data.editCaretakerActionSheet = { ...modalState.data.editCaretakerActionSheet, isOpen: true, isRendered: true, modalProps: { ...action.payload } };
    },
    showModal( modalState, action: PayloadAction<GenericModals> ){
      const modalName = action.payload;
      modalState.data[modalName] = {
        ...modalState.data[modalName], isOpen: true, isRendered: true,
      };
    },
    hideModal( modalState, action: PayloadAction<GlobalModal> ){
      const modalName = action.payload;
      modalState.data[modalName].isOpen = false;
      setTimeout(() => {
        store.dispatch( removeModal( action.payload ));
      }, MODAL_TRANSITION_DURATION_MS );
    },
    removeModal( modalState, action: PayloadAction<GlobalModal> ){
      const modalName = action.payload;
      modalState.data[modalName].isRendered = false;
      switch( modalName ) {
      case( GlobalModals.inviteModal ):
      case( GlobalModals.childrenHeirsLifeUpsell ):
      case ( GlobalModals.shareActionSheet ):
      case( GlobalModals.videoModal ):
      case( GlobalModals.editCaretakerActionSheet ):
      case ( GlobalModals.contextualModal ):
        modalState.data[modalName].modalProps = initialProps[modalName];
        break;
      default:
        // no-op, no clean up
        break;
      }
    },
  },
});

export const {
  hideModal,
  removeModal,
  showChildrenHeirsUpsellLifeModal,
  showContextualModal,
  showModal,
  showInviteModal,
  showShareModal,
  showVideoModal,
  showEditCaretakerModal,
} = modalSlice.actions;

export default modalSlice.reducer;
