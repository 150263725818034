import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router';

// Components
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';
import DocumentSectionOverview, { DocumentSectionStates, SectionStates } from '../../../components/DocumentSectionOverview';
import DocumentBasicsSummary from '../DocumentBasicsSummary';
import DocumentNextButton, { DocumentNextButtonProps } from '../DocumentNextButton';
import Helper from '../../../components/Helper';
import DocumentSectionProfiles from '../DocumentSectionProfiles';
import OpenVideoButton from '../../../components/OpenVideoButton';
import ProductUpsellHelper from '../../../components/ProductUpsellHelper';
import WillRoleSummaryItem from '../DocumentRoleSummaryItem';

// Routes
import {
  trust_heirs as trustHeirsRoute,
  dashboard as dashboardRoute,
  trustees as trusteesRoute,
  trust_basics as basicsRoute,
  trust_review as trustReviewRoute,
  checkout as checkoutRoute,
} from '../../../../core/routes';

// Hooks
import useTrustees from '../../../hooks/useTrustees';
import useHeirs from '../../../hooks/useHeirs';
import useUserAttributes from '../../../hooks/useUserAttributes';
import { getHeirsState } from '../../../hooks/useWillSectionStatus';
import useTranslations from '../../../hooks/useTranslations';

// Models
import Person from '../../../core-data-service/models/Person';

// Utils
import { getHeirsInitialScenarioPage } from '../../Heirs';
import { trustHeirsVideoSourceMap } from '../../Heirs/TrustHeirs';
import { generateQueryString } from '../../../utils/urlUtils';
import { getEntitlementNameKey } from '../../../utils/entitlementsUtils';
import { DOCUMENTS } from '../../../core-data-service/models/Document';

// Redux
import { useDispatch } from '../../../store';
import { showContextualModal, showModal, showVideoModal } from '../../../store/reducers/modalReducer';
import { useSelector } from '../../../store';
import { setUserAttributes } from '../../../store/reducers/userReducer';
import productsSelector from '../../../store/selectors/productsSelector';
import useTrustSectionStatus, { TrustSection, TrustSections } from '../../../hooks/useTrustSectionStatus';
import { GlobalModals } from '../../../store/initial/modal';


const Trust = (): ReactElement => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const userAttributes = useSelector( state => state.user.data.attributes );

  const { trustees } = useTrustees();
  const products = useSelector( productsSelector );

  const { sectionStates, next: nextSectionToComplete } = useTrustSectionStatus();
  const { getTrusteesSectionState } = useTrustees();
  const { message: trusteesTranslationInfo } = getTrusteesSectionState();

  const { documents: {
    trust: {
      hasStartedTrustHeirsSection,
      hasViewedTrusteeVideo,
      hasDelayedPayment,
      hasViewedIntroVideo,
    },
    will: {
      hasViewedHeirsVideo,
    },
  } } = useUserAttributes();

  const { heirs,
    childrenHeirs,
    fallbackHeirs,
    sumOfChildrenShares,
    sumOfFallbackShares,
    spouse } = useHeirs();

  const { getTranslation } = useTranslations();

  const initialHeirsRoute = trustHeirsRoute.get({}, { page: getHeirsInitialScenarioPage( userAttributes ) });

  const isRenderedInWill = false;
  const trustHeirsState = getHeirsState(
    userAttributes,
    childrenHeirs,
    fallbackHeirs,
    sumOfChildrenShares,
    sumOfFallbackShares,
    spouse,
    isRenderedInWill,
  );

  useEffect(() => {
    if ( !hasViewedIntroVideo ) {
      dispatch( showVideoModal({
        src: { portrait: 'video.trust.intro', landscape: 'video.trust.intro.landscape' },
        hasViewedKey: 'app.viewedTrustIntroVideo',
        onSkip: () => push( basicsRoute.get()),
        onEnded: () => push( basicsRoute.get()),
      }));
    }
  }, [ dispatch, push, hasDelayedPayment, hasViewedIntroVideo ]);

  const handleReviewAndPrint = () => push( trustReviewRoute.get());

  const getDocumentNextButtonProps = ( section: TrustSection, sectionState: DocumentSectionStates ): DocumentNextButtonProps => {
    const buttonTranslationKey = ( sectionState === SectionStates.error || sectionState === SectionStates.warning )
      ?'screen.willstatus.chapter.button.resolve'
      :'screen.willstatus.chapter.button.begin';

    switch( section ) {
    case TrustSections.basics:
      return {
        sectionLabelTranslationKey: 'trust.chapter.title.basics',
        buttonAction: () => push( basicsRoute.get()),
        buttonTranslationKey,
      };

    case TrustSections.trustees:
      return {
        sectionLabelTranslationKey: 'trust.chapter.title.trustees',
        buttonAction: () => push( trusteesRoute.get()),
        buttonTranslationKey,
      };
    case TrustSections.heirs:
      return {
        sectionLabelTranslationKey: 'trust.chapter.title.heirs',
        buttonAction: () => push( trustHeirsRoute.get()),
        buttonTranslationKey,
      };
    case TrustSections.review:
      return {
        sectionLabelTranslationKey: 'trust.chapter.title.reviewandprint',
        buttonAction: handleReviewAndPrint,
        buttonTranslationKey,
      };
    }
  };

  const { documents: { trust: { shouldBeReviewed } } } = useUserAttributes();

  useEffect(() => {
    if( shouldBeReviewed ) {
      dispatch( showContextualModal({
        title: getTranslation( 'products.trust.needsreview.title' ),
        description: getTranslation( 'products.trust.needsreview.description' ),
        confirmLabel: getTranslation( 'products.trust.needsreview.confirm' ),
        onConfirm: () => dispatch( setUserAttributes({ 'app.trustNeedsReview': false })),
        contentClassName: '!bg-moss',
        iconAboveHeader: 'alert-circle',
      }));
    }
  }, [ dispatch ]);

  return (
    <SegmentView
      contentClassName="pb-14 md:pb-36"
      title={ <Translation translationKey={ 'screen.will.status.trust' } /> }
      subtitle={
        <OpenVideoButton
          labelKey="products.trust.intro.video_button"
          videoSrc={{ portrait: 'video.trust.intro', landscape: 'video.trust.intro.landscape' }}
        />
      }
      retreatIcon="close"
      hidePrimaryAction
      onRetreat={ () => push( dashboardRoute.get()) }
      secondaryActionChildren={
        <Translation translationKey="(button)help"/>
      }
      onSecondaryAction={ () => {
        dispatch( showModal( GlobalModals.help ));
      } }
    >
      <DocumentNextButton
        { ...getDocumentNextButtonProps( nextSectionToComplete, sectionStates[nextSectionToComplete].state ) }
      />

      <DocumentSectionOverview
        state={ sectionStates.basics.state }
        primaryActionId={ 'trust-basic-section' }
        title={ <Translation translationKey={ 'will.chapter.title.basics' }/> }
        beginAction={ () => push( basicsRoute.get()) }
        detailsAction={ () => push( basicsRoute.get()) }
      >
        <DocumentBasicsSummary />
      </DocumentSectionOverview>

      <DocumentSectionOverview
        state={ sectionStates.trustees.state }
        primaryActionId={ 'trust-trustees-section' }
        title={ <Translation translationKey={ 'trust.chapter.title.trustees' }/> }
        beginAction={ () => {
          !hasViewedTrusteeVideo
            ? dispatch( showVideoModal({
              src: { portrait: 'video.trust.trustee', landscape: 'video.trust.trustee.landscape' },
              onSkip: () => push( trusteesRoute.get()) ,
              onEnded: () => push( trusteesRoute.get()) ,
              hasViewedKey: 'app.viewedTrusteeVideo' },
            ))
            : push( trusteesRoute.get());
        } }
        detailsAction={ () => push( trusteesRoute.get()) }
      >
        {trusteesTranslationInfo && sectionStates.trustees.state !== SectionStates.complete ?
          <Helper
            className="mt-0"
            theme={ sectionStates.trustees.state }
            body={ <Translation { ...trusteesTranslationInfo } /> }
          />
          : trustees
            .map(( trustee, idx ) =>
              <WillRoleSummaryItem
                idx={ idx }
                key={ trustee.id }
                person={ trustee }
                roleTranslationKey="role.name.trustee.displayname"
              />,
            )
        }
      </DocumentSectionOverview>

      <DocumentSectionOverview
        state={ sectionStates.heirs.state }
        primaryActionId={ 'trust-heirs-section' }
        title={ <Translation translationKey={ 'trust.chapter.title.heirs' }/> }
        beginAction={ () => {
          !hasViewedHeirsVideo
            ? dispatch( showVideoModal({
              src: trustHeirsVideoSourceMap,
              hasViewedKey: 'app.viewedHeirsVideo',
              onSkip: () => push( initialHeirsRoute ),
              onEnded: () => push( initialHeirsRoute ),
            }))
            : push( initialHeirsRoute );
        } }
        detailsAction={ () => push( initialHeirsRoute ) }
      >
        {trustHeirsState.state !== 'complete' && trustHeirsState.state !== 'default' && trustHeirsState.state !== 'review'
          ?
          <Helper
            className="mt-0"
            theme={ trustHeirsState.state }
            body={ trustHeirsState.message && <Translation { ...trustHeirsState.message } /> }
          />
          :
          // only show profiles if section started
          hasStartedTrustHeirsSection && <DocumentSectionProfiles
            profiles={ heirs.map( heir => {
              return { src: heir.photo_url || '', name: heir.name || '' };
            }) }
            firstNames={
              heirs.map( heir => {
                const firstName = new Person( heir ).firstName;
                return ( firstName );
              })
            }
          />
        }
      </DocumentSectionOverview>

      <DocumentSectionOverview
        state={ sectionStates.review.state }
        primaryActionId={ 'trust-reviewandprint-section' }
        isGated={ nextSectionToComplete !== TrustSections.review }
        title={ <Translation translationKey={ 'trust.chapter.title.reviewandprint' }/> }
        beginAction={ handleReviewAndPrint }
        detailsAction={ () => push( trustReviewRoute.get()) }
        className="border-none -mb-8"
      />

      {!products.pro.hasPurchased &&
        <ProductUpsellHelper
          className="mt-8"
          title={ getTranslation( 'products.pro.chapter.print.not_started.card.title', [ getTranslation( getEntitlementNameKey( DOCUMENTS.trust )) ]) }
          description={ getTranslation( 'products.pro.chapters.print.not_started.card.description' ) }
          primaryActionLabel={ getTranslation( 'products.pro.chapter.print.button', [ products.pro.price.displayValue ]) }
          primaryAction={ () => push({ pathname: checkoutRoute.get(), search: generateQueryString({ document: DOCUMENTS.trust }) }) }
        />
      }

    </SegmentView>
  );
};

export default Trust;
