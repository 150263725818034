import { FC, ReactElement } from 'react';
import SegmentView from '../../components/SegmentView';
import useBackstack from '../../hooks/useBackstack';
import Button from '../../components/Button';
import { showModal } from '../../store/reducers/modalReducer';
import { GlobalModals } from '../../store/initial/modal';
import { useDispatch } from '../../store';
import useTranslations from '../../hooks/useTranslations';
import Translation from '../../components/Translation';

const Section: FC<{ id: string }> = ({ children, id }) =>
  <li id={ id } className="text-xl">{ children }</li>;

const SubSectionList: FC = ({ children }) =>
  <ol className="pl-0">{ children }</ol>;

const SubSection: FC<{ id: string }> = ({ children, id }) =>
  <li id={ id } className="text-sm">{ children }</li>;

const Paragraph: FC = ({ children }) =>
  <p className="my-2 text-sm">{ children }</p>;

const UnorderedList: FC = ({ children }) =>
  <ul className="list-disc mt-3 ml-4">{ children }</ul>;

const UnorderedListItem: FC = ({ children }) =>
  <li className="mb-2">{ children }</li>;




export default function TermsOfService({
  isFromIOS = false,
}: {
  isFromIOS?: boolean;
}): ReactElement{

  const { goBack } = useBackstack();

  const { getTranslation } = useTranslations();

  const dispatch = useDispatch();
  return (
    <main className="flex fixed inset-0 bg-white">
      <SegmentView
        onRetreat={ goBack }
        title="Terms of Service"
        hidePrimaryAction
        shouldHideBackButton={ isFromIOS }
      >
        <p className="mb-4">
          Last Updated: July 9, 2019
        </p>

        <Paragraph>
          These Terms of Service (“<em><u>Terms</u></em>”) apply to your
          access to and use of the websites, applications and other online
          products and services (collectively, our
          “<em><u>Services</u></em>”) provided by Ethos Estate Planning, LLC, or
          any of its subsidiaries and affiliates (collectively,
          “<em><u>Ethos Estate Planning, LLC</u></em>” or “<em><u>we</u></em>”). &nbsp;

          <u>
            By clicking to agree to these Terms or by otherwise using the
            Services, you agree to these Terms. If you do not agree to these
            Terms, including the mandatory arbitration provision and class
            action waiver in Section 21, do not access or use our
            Services.
          </u>

        </Paragraph>

        <Paragraph>
          If you have any questions about these Terms or our Services, please
          contact us at &nbsp;
          <a href="mailto:support@ethoslife.com"><u>support@ethoslife.com</u></a>.
          Please refer to our Privacy Policy &nbsp;
          (<a href={ getTranslation( 'appinfo.privacy.url' ) }><u><Translation translationKey="appinfo.privacy.url" /></u></a>)
          for information about how we collect, use and disclose information
          about you.
        </Paragraph>

        <Paragraph>
          In addition to these Terms, we may ask you to accept additional
          terms that apply to specific features, products or services
          provided by Ethos Estate Planning, LLC. To the extent any additional terms conflict
          with these Terms, the additional terms govern with respect to your
          access to or use of the applicable feature, product or service.
        </Paragraph>

        <SubSectionList>
          <Section id="1-0">
            Overview of Our Services

            <Paragraph>
              You are permitted to use the Services to (a) create, prepare
              and execute estate planning documents such as a will, a trust,
              powers of attorney, and an advance healthcare directive
              (collectively, “<em><u>Estate Planning Documents</u></em>”),
              (b) list assets in your trust schedule of assets, if you have
              opted to create a trust, (c) purchase life insurance policies
              through the Services (“<em><u>Insurance Policies</u></em>”),
              and (d) consolidate and track your financial information in one
              place to allow you to make investment, insurance, estate
              planning and other similar decisions.
            </Paragraph>

          </Section>

          <Section id="2-0">
            Ethos Estate Planning, LLC provides No Financial or Estate Planning, Legal or Tax
            Advice

            <SubSectionList>
              <SubSection id="2-1">
                You agree that Ethos Estate Planning, LLC is not a law firm or an attorney, and
                may not perform services performed by an attorney or provide
                legal advice or guidance. No attorney-client relationship or
                privilege is created between you and Ethos Estate Planning, LLC. Instead you
                are representing yourself in any legal matter you undertake,
                including any legal matter relating in any manner to your use
                of the Services.  Additionally, you agree that Ethos Estate Planning, LLC is
                not a financial or estate planner, investment or tax advisor.
                Neither Ethos Estate Planning, LLC nor the Services are intended to provide any
                financial planning, investment, legal, or tax advice, though
                Ethos Estate Planning, LLC does sell insurance products as addressed below. &nbsp;

                <u>
                  The information included on the Services is not an offer
                  to sell any security or product in any state or other
                  jurisdiction.
                </u>

              </SubSection>
              <SubSection id="2-2">
                You are solely responsible and liable for any and all
                financial, tax, investment or legal decisions you make or
                related agreement that you become a party to regardless of
                whether you use the Services to assist you in making such
                decisions or entering into such agreement.  You waive all
                claims against Ethos Estate Planning, LLC for, any loss or damage that may
                occur because of your financial, tax, investment, legal or
                other similar decisions and you agree that Ethos Estate Planning, LLC shall
                not be liable for any such losses or damages.
              </SubSection>
            </SubSectionList>

          </Section>

          <Section id="3-0">
            Eligibility

            <SubSectionList>
              <SubSection id="3-1">
                You represent and warrant that you: (a) are at least 18 years
                old; (b) have not previously been suspended or removed from
                using our Services; (c) have full power and authority to
                enter into these Terms and in doing so will not violate any
                other agreement to which you are a party; (d) are not a
                national or resident of any country to which the United
                States has embargoed goods or services; (e) have not been
                identified as a “Specially Designated National” by the Office
                of Foreign Assets Control; (f) have not been placed on the
                U.S. Commerce Department’s Denied Persons List; (g) will not
                use our Services outside the United States and (h) do not and
                will not have more than one account on the Services.
              </SubSection>
              <SubSection id="3-2">
                If you are using the Services on behalf of an entity, (a) you
                represent and warrant that you are authorized to bind that
                entity to these Terms, (b) that entity agrees to be
                responsible for you and any other user that accesses the
                Services on its behalf, including for your and their
                compliance with these Terms, and (c) all references to "you"
                will refer to you and that entity, jointly.
              </SubSection>
            </SubSectionList>

          </Section>


          <Section id="4-0">
            User Accounts and Account Security

            <Paragraph>
              You will need to register for an account to access some or all
              of our Services. You agree to provide accurate account
              information and promptly update this information if it changes.
              You also agree to maintain the security of your credentials and
              account and promptly notify us if you discover or suspect that
              someone has accessed your account without your authorization.
              Your username and password are personal to you and may not be
              shared with any third parties.  You are responsible for all
              activities that occur regarding your credentials or otherwise
              under your account.
            </Paragraph>
          </Section>

          <Section id="5-0">
            Estate Planning Documents

            <Paragraph>
              If you prepare any Estate Planning Documents using the
              Services, the following terms and conditions apply:
            </Paragraph>

            <SubSectionList>
              <SubSection id="5-1">
                Any documents provided by Ethos Estate Planning, LLC or used by Ethos Estate Planning, LLC in
                connection with the Services (including any Estate Planning
                Documents) are not a substitute for the advice or services of
                an estate and financial planner or an attorney. You authorize
                us to create your Estate Planning Documents via the Services
                using the information you provide via the Services. We will
                create your Estate Planning Documents; however, you agree
                that you are solely responsible for (a) the information you
                provide, (b) reviewing the Estate Planning Documents, and (c)
                ensuring you agree with terms of any Estate Planning
                Documents before you sign them. You agree that you are also
                solely responsible for independently determining whether the
                Estate Planning Documents and filing instructions provided by
                Ethos Estate Planning, LLC comply with the laws, regulations and rules of your
                applicable jurisdiction.
              </SubSection>
              <SubSection id="5-2">
                The requirements for preparing Estate Planning Documents vary
                depending on local, state and federal laws, regulations, and
                the court’s interpretation in your jurisdiction. We cannot
                guarantee that all of the information provided by Ethos Estate Planning, LLC is
                completely current or correct. Because of changes in the law,
                variations between and within jurisdictions, and different
                interpretations of the law, you should consult with a
                licensed attorney in your jurisdiction regarding the
                applicability of the Services or the Estate Planning
                Documents prepared by Ethos Estate Planning, LLC to your particular
                circumstances.
              </SubSection>
              <SubSection id="5-3">
                In the event of your death or incapacitation, to the extent
                applicable, you authorize us to (a) work and communicate with
                a person authorized to act on your behalf, or with your
                verified immediate family member(s) to have your account
                transition to a “deceased” status and (b) provide access to
                your will to your designated executor, your trust to your
                designated trustee, your powers of attorney to your
                designated attorney-in-fact, and your advance healthcare
                directive to your designated healthcare agent.
              </SubSection>
              <SubSection id="5-4">
                You also agree to the following legal disclaimer:

                <UnorderedList>
                  <UnorderedListItem>
                    Ethos Estate Planning, LLC is an online provider of legal “fill in the
                    blank” forms and general legal information. Ethos Estate Planning, LLC is
                    not a law firm and we do not provide legal advice or
                    represent you in any way.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    By using this app or our legal forms, you are not
                    accessing attorney services or legal advice. Ethos Estate Planning, LLC's
                    services, including our app, our legal forms, and any
                    legal information we provide, are not a substitute for
                    the advice of an attorney.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    By using this app or our legal forms, you are not
                    establishing an attorney-client relationship with
                    Ethos Estate Planning, LLC or any of its employees, or with any attorney.
                    Any information you provide us is not protected by
                    attorney-client privilege or as work product.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    In accessing this app, the user assumes responsibility
                    to provide correct and up-to-date information.
                    Inaccuracies in information provided could affect the
                    validity or enforceability of the documents generated
                    by the app. You will read any and all Ethos Estate Planning, LLC
                    documents that you download and print prior to signing
                    them. You take sole responsibility for the accuracy of
                    your personal information contained in Ethos Estate Planning, LLC
                    documents that you sign.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Each person’s legal situation is different and complex
                    situations may require legal advice and representation.
                    It is not possible for the app to identify all
                    situations in which legal representation is
                    recommended, necessary or desirable. We do not review
                    your documents or any information you input for
                    accuracy or legal sufficiency, draw legal conclusions,
                    provide legal advice or apply the law to the facts of
                    your particular situation.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Each user has the obligation to make his or her own
                    determination as to whether to seek the advice of a
                    lawyer. Ethos Estate Planning, LLC recommends that all users consider
                    reviewing their estate plan and estate planning
                    documents with an attorney.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Different states have different rules that may affect
                    the enforceability or validity of planning documents,
                    and each state’s laws may change over time. Ethos Estate Planning, LLC
                    cannot ensure that the documents generated through use
                    of the app will be enforceable or valid in all states
                    or in every instance. Each user assumes the
                    responsibility to seek legal review of their documents
                    and estate plan.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Guardianship appointments are determined by courts in
                    most states. While a parent's choice of guardian is
                    given deference, there is no guarantee that a parent’s
                    choice of guardian will be upheld by a court. Seek
                    legal advice to determine how best to protect your
                    family.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Executor appointments are determined by courts in most
                    states. While your choice of Executor is given
                    deference, there is no guarantee that your choice of
                    Executor will be upheld by a court. Seek legal advice
                    to determine how best to choose an appropriate
                    Executor.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Users should update their documents as their life
                    circumstances change. It is up to users to determine
                    when they need to update their documents. Failure to do
                    so may affect the validity or enforceability of
                    planning documents.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Certain estate planning documents will not be valid or
                    enforceable unless you follow detailed and specific
                    steps to properly execute the documents. Failure to
                    precisely follow directions may result in invalid or
                    unenforceable planning documents. If you have any
                    questions you should seek the advice of an attorney.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Will and trust provisions can have financial and tax
                    consequences. The financial and tax provisions will not
                    be ideal for every situation and we do not guarantee
                    that our provisions are appropriate for you. Seek legal
                    advice to determine what specific tax or financial
                    planning is best for you.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Executors, guardians, trust creators and trustees
                    should consult with an attorney to determine their
                    legal rights and obligations under estate planning
                    documents and local law. In addition, the
                    implementation of, or failure to follow, will and trust
                    provisions can have financial and tax consequences for
                    estates, trusts, executors, trustees, guardians, heirs
                    and beneficiaries. Executors, guardians, trust creators
                    and trustees should consult with an attorney for
                    guidance.
                  </UnorderedListItem>
                </UnorderedList>
              </SubSection>
            </SubSectionList>
          </Section>

          <Section id="6-0">
            Purchase &amp; Sale of Insurance Policies

            <Paragraph>
              If you purchase Insurance Policies using the Services, the
              following terms and conditions apply:
            </Paragraph>

            <Paragraph>
              Ethos Estate Planning, LLC is a licensed broker of Insurance Policies and we work
              with certain third party partners, including insurance
              carriers, underwriters and reinsurers in connection with the
              Insurance Policies (“<em><u>Partners</u></em>”). You authorize
              us to (a) provide you insurance quotes based on information you
              provide to us about yourself, which information you expressly
              authorize us to share with insurance partners, including
              protected health information and other personally identifiable
              information in compliance with applicable law (b) submit your
              application for the Insurance Policies you choose to purchase,
              (c) process such application, (d) work with and communicate
              with our Partners to the extent necessary to permit you to
              purchase such Insurance Policies, (e) pay any insurance
              premiums for the Insurance Policies obtained through the
              Services, and (f) receive any compensation that any Partner
              agrees to pay Ethos Estate Planning, LLC in connection with the provision of the
              Services. If applicable, we will only share such compensation
              with other duly licensed persons.  Additionally, you designate
              us as your agent or broker of record in connection with all
              Insurance Policies you purchase and authorize us to communicate
              such designation to our Partners or anyone else who we believe
              needs such information.  Any insurance-related material
              described on or provided through the Services is provided for
              informational purposes only and you should refer to the actual
              insurance policy for complete details of coverage of a
              particular insurance policy.  The Insurance Policies described
              on the Services may not be available in all states or other
              jurisdictions and may have restrictions, limitations and terms
              regarding coverage. A policy’s approval is not guaranteed and
              is subject to underwriting factors such as your personal health
              history and the specific underwriting guidelines of the Partner
              issuing the policy.
            </Paragraph>


            <Paragraph>
              <u>
                Any recommendations we provide to you in connection with
                Insurance Policies are based on information you provide to
                us, are provided for your personal information and education
                only and are not a substitute for professional advice.
                Further, the amount of insurance coverage you need may differ
                from what we recommend depending on information we did not
                request of you. You have the sole obligation to determine
                what Insurance Policies to purchase and whether to accept
                these recommendations.
              </u>
            </Paragraph>

          </Section>

          <Section id="7-0">
            User to User Communication and Sharing

            <Paragraph>
              The Services may permit you and Ethos Estate Planning, LLC to communicate with
              any person or entity you add to the Services or authorize to
              have access to your Estate Planning Documents or Insurance
              Policies (each, a “<em><u>Contact</u></em>”). For example, if
              you designate a Contact as the trustee of your trust, the
              Services may automatically send that Contact a message
              notifying them of your designation and asking them whether they
              choose to accept such role.  You may also share your Estate
              Planning Documents with your Contacts through the Services
              (e.g. you may share your will with your designated
              beneficiaries or your attorney). You are solely responsible for
              any communications and interactions that you make or that you
              authorize us to make with your Contacts.
            </Paragraph>
          </Section>

          <Section id="8-0">
            Third Party Services and Products

            <Paragraph>
              The Services may contain links to third party websites and
              materials and we may make recommendations or send you offers
              for certain third party services, products and materials that
              we think may be interesting to you based on information
              provided by you (e.g. we may recommend insurance policies for
              you based on your specified preferences). However, these links,
              recommendations and offers are provided as a convenience and we
              are not liable for any websites, products, services, materials
              or information made available by any third party. If you choose
              to purchase any third party products or services, you are
              solely responsible for your purchasing decisions and if you
              choose to visit any third party websites, you may be subject to
              additional or different terms and conditions and privacy
              policies.
            </Paragraph>
          </Section>

          <Section id="9-0">
            Account Information

            <Paragraph>
              You may sync third party accounts with the Services in order to
              permit Ethos Estate Planning, LLC to retrieve your information that is maintained
              online by such third parties
              (“<u><em>Account Information</em></u>”). By using the
              Services, you authorize Ethos Estate Planning, LLC to access your Account
              Information, on your behalf as your agent, and you authorize
              such third parties to disclose your information to us. When you
              provide Account Information through the Services, you will be
              directly connected to the website for the third party you have
              identified. Ethos Estate Planning, LLC will submit information including
              usernames and passwords that you provide to log into the
              Services. You hereby authorize and permit Ethos Estate Planning, LLC to use
              information submitted by you to accomplish the foregoing and to
              configure the Services so that they are compatible with the
              third party sites for which you submit your information. Solely
              to provide the Account Information to you as part of the
              Services, you grant Ethos Estate Planning, LLC a limited power of attorney, and
              appoint Ethos Estate Planning, LLC as your attorney-in-fact and agent, to access
              third party sites, retrieve and use your information with the
              full power and authority to do and perform each thing necessary
              in connection with such activities, as you could do in person. &nbsp;

              <u>
                You acknowledge and agree that when Ethos Estate Planning, LLC is accessing and
                retrieving Account Information from third party sites,
                Ethos Estate Planning, LLC is acting as your agent, and not as the agent of or
                on behalf of the third party that operates the third party
                site.
              </u>

              You understand and agree that any third party accounts and
              sites are not provided by Ethos Estate Planning, LLC and Ethos Estate Planning, LLC is not
              responsible for any Services-related issues arising from or in
              connection with such third party accounts or your Account
              Information. You agree to keep your Account Information up to
              date and accurate.
            </Paragraph>
          </Section>

          <Section id="10-0">
            User Content

            <SubSectionList>
              <SubSection id="10-1">
                In order to use certain aspects of the Services, the Services
                may allow you to provide, create, post, store, submit,
                upload, and share (a) certain personal information about
                yourself and your Contacts, including yours and their names,
                birthdates, state of residency, role (e.g. beneficiary,
                trustee, etc.), financial and estate planning information,
                information you provide to us about your health or life
                status, such as health conditions or treatments, whether you
                are tobacco user, whether you have ever been pregnant, etc.,
                and other details about yourself and your Contacts, (b)
                Account Information, and (c) other information, material,
                content, profiles, documents, lists, messages, and photos.
                The content described in clauses (a), (b) and (c) of the
                previous sentence are collectively,
                "<em><u>User Content</u></em>". As between you and Ethos Estate Planning, LLC,
                you retain all rights in and to your User Content, subject to
                the license grant below.
              </SubSection>

              <SubSection id="10-2">
                You grant Ethos Estate Planning, LLC and its affiliates a perpetual,
                irrevocable, nonexclusive, royalty-free, worldwide,
                fully-paid, and sub-licensable license to use, reproduce,
                modify, adapt, publish, translate, create derivative works
                from, distribute, publicly perform and display your User
                Content and any name or username provided in connection with
                your User Content in all media formats and channels now known
                or later developed without compensation to you, but solely as
                necessary for Ethos Estate Planning, LLC to provide the Services to you. When
                you post, upload, submit or otherwise share User Content on
                or through our Services, you understand that your User
                Content may be displayed publicly and shared, including with
                your Contacts and any other person or entity who you
                designate via the Services.
              </SubSection>

              <SubSection id="10-3">
                You represent and warrant that you have the rights to provide
                us the User Content and that we have the rights to use such
                User Content as described in these Terms.  You agree not to
                create, post, store or share any User Content that violates
                these Terms or for which you do not have all the rights
                necessary to grant us the license described above. Although
                we have no obligation to screen, edit or monitor User
                Content, we may delete or remove User Content at any time and
                for any reason.
              </SubSection>
            </SubSectionList>
          </Section>

          <Section id="11-0">
            Prohibited Conduct and Content

            <SubSectionList>
              <SubSection id="11-1">
                You agree not to violate any applicable law, contract,
                intellectual property or other third-party right or commit a
                tort, and you are solely responsible for your conduct while
                accessing or using our Services. Without limiting the
                foregoing, you agree not to:

                <UnorderedList>
                  <UnorderedListItem>
                    engage in any harassing, threatening, intimidating,
                    predatory or stalking conduct;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    use or attempt to use another user’s account without
                    authorization from that user and Ethos Estate Planning, LLC;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    use our Services in any manner that could interfere with,
                    disrupt, negatively affect or inhibit other users from
                    fully enjoying our Services or that could damage,
                    disable, overburden or impair the functioning of our
                    Services in any manner;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    reverse engineer any aspect of our Services or do
                    anything that might discover source code or bypass or
                    circumvent measures employed to prevent or limit access
                    to any part of our Services;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    attempt to circumvent any content-filtering techniques we
                    employ or attempt to access any feature or area of our
                    Services that you are not authorized to access;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    develop or use any third-party applications that interact
                    with our Services without our prior written consent;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    use any data mining, robots or similar data gathering or
                    extraction methods; or
                  </UnorderedListItem>
                  <UnorderedListItem>
                    use our Services for any illegal or unauthorized purpose,
                    or engage in, encourage or promote any activity that
                    violates these Terms.
                  </UnorderedListItem>
                </UnorderedList>
              </SubSection>
              <SubSection id="11-2">
                You agree that you will only post or otherwise share User
                Content that is non-confidential and that you have all
                necessary rights to disclose. You agree not to create, post,
                store or share any User Content that:

                <UnorderedList>
                  <UnorderedListItem>
                    is unlawful, libelous, defamatory, obscene,
                    pornographic, indecent, lewd, suggestive, harassing,
                    threatening, invasive of privacy or publicity rights,
                    abusive, inflammatory or fraudulent;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    would constitute, encourage or provide instructions for
                    a criminal offense, violate the rights of any party or
                    otherwise create liability or violate any local, state,
                    national or international law;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    may infringe any patent, trademark, trade secret,
                    copyright or other intellectual or proprietary right of
                    any party;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    contains or depicts any statements, remarks or claims
                    that do not reflect your honest views and experiences;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    impersonates, or misrepresents your affiliation with,
                    any person or entity;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    contains any unsolicited promotions, political
                    campaigning, advertising or solicitations;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    contains any private or personal information of a third
                    party without such third party’s consent;
                  </UnorderedListItem>
                  <UnorderedListItem>
                    contains any viruses, corrupted data or other harmful,
                    disruptive or destructive files or content; or
                  </UnorderedListItem>
                  <UnorderedListItem>
                    is, in our sole judgment, objectionable or that
                    restricts or inhibits any other person from using or
                    enjoying our Services, or that may expose Ethos Estate Planning, LLC or
                    others to any harm or liability of any type.
                  </UnorderedListItem>
                </UnorderedList>
              </SubSection>
            </SubSectionList>
          </Section>

          <Section id="12-0">
            Limited License; Copyright and Trademark

            <Paragraph>
              Our Services and the text, graphics, images, photographs,
              videos, illustrations, trademarks, trade names, service marks,
              logos, slogans and other content contained therein
              (collectively, the “<u><em>Ethos Estate Planning, LLC Content</em></u>”) are
              owned by or licensed to Ethos Estate Planning, LLC and are protected under both
              United States and foreign laws. Except as explicitly stated in
              these Terms, Ethos Estate Planning, LLC and our licensors reserve all rights in
              and to our Services and the Ethos Estate Planning, LLC Content. You are hereby
              granted a limited, nonexclusive, nontransferable,
              non-sublicensable, revocable license to access and use our
              Services and Ethos Estate Planning, LLC Content for your own personal use;
              however, such license is subject to these Terms and your
              compliance with these Terms, and does not include any right to
              (a) sell, resell or commercially use or otherwise exploit our
              Services or Ethos Estate Planning, LLC Content; (b) copy, reproduce, distribute,
              publicly perform or publicly display Ethos Estate Planning, LLC Content, except
              as expressly permitted by us or our licensors; (c) modify the
              Ethos Estate Planning, LLC Content, remove any proprietary rights notices or
              markings, or otherwise make any derivative uses of our Services
              or Ethos Estate Planning, LLC Content; (d) use any data mining, robots or similar
              data gathering or extraction methods; and (e) use our Services
              or Ethos Estate Planning, LLC Content other than for their intended purposes as
              described in these Terms. Any use of our Services or Ethos Estate Planning, LLC
              Content other than as specifically authorized in these Terms,
              without our prior written permission, is strictly prohibited
              and will automatically terminate the license granted in these
              Terms.
            </Paragraph>
          </Section>

          <Section id="13-0">
            Feedback

            <Paragraph>
              Any questions, comments, suggestions, testimonials, ideas,
              original or creative materials or other information you submit
              about Ethos Estate Planning, LLC or our products or Services (collectively,
              “<u><em>Feedback</em></u>”), is non-confidential and will
              become the sole property of Ethos Estate Planning, LLC. We will own exclusive
              rights, including, without limitation, all intellectual
              property rights, in and to Feedback and will be entitled to the
              unrestricted use and dissemination of Feedback for any purpose,
              commercial or otherwise, without acknowledgment or compensation
              to you.
            </Paragraph>
          </Section>

          <Section id="14-0">
            Copyright Complaints

            <Paragraph>
              We have a policy of limiting access to our Services and
              terminating the accounts of users who infringe the intellectual
              property rights of others. If you believe that anything on our
              Services infringes any copyright that you own or control, you
              may notify Ethos Estate Planning, LLC through the contact information provided
              below:
            </Paragraph>

            <table className="table-auto mb-2 text-sm">
              <tr>
                <td className="p-2 align-top">
                  Mailing Address:
                </td>
                <td className="p-2">
                  Ethos Estate Planning, LLC
                  <br />1606 Headway Circle
                  <br />#9013
                  <br />Austin, TX 78754
                  <br />United States
                </td>
              </tr>

              <tr>
                <td className="p-2">
                  E-Mail Address:
                </td>
                <td className="p-2">
                  <a href="mailto:support@ethoslife.com"><u>support@ethoslife.com</u></a>
                </td>
              </tr>
            </table>

            <Paragraph>
              Please see 17 U.S.C. §512(c)(3) for the requirements of a
              proper notification. Also, please note that if you knowingly
              misrepresent that any activity or material on our Services is
              infringing, you may be liable to Ethos Estate Planning, LLC for certain costs and
              damages.
            </Paragraph>
          </Section>

          <Section id="15-0">
            Electronic Communication

            <Paragraph>
              By creating a Ethos Estate Planning, LLC account, you consent to receive
              communications from Ethos Estate Planning, LLC (e.g., via telephone, email, and
              other electronic communications, or by posting notices to the
              website). These communications may include notices about your
              account (e.g., password changes and other transactional
              information), as well as communications provided as part of the
              Services or for marketing purposes, and are part of your
              relationship with us. You consent to being provided with any
              notices, disclosures, information, and other materials in
              electronic form (collectively
              "<em><u>Electronic Records</u></em>"), rather than in paper
              form in accordance with The Electronic Signatures in Global and
              National Commerce Act ("<u><em>E-Sign</em></u>"). Your consent
              to receive Electronic Records applies to all notices,
              disclosures, documents, records or other materials of any kind
              that Ethos Estate Planning, LLC may be required to provide to you. You agree that
              any notices, agreements, disclosures or other communications
              that we send to you electronically will satisfy any legal
              communication requirements, including, but not limited to, that
              such communications be in writing. You further understand that
              checking a box, clicking “accept” (or similar word) on our
              website, or responding via telephone keypress constitutes your
              signature. You should maintain copies of electronic
              communications from us by printing a paper copy or saving an
              electronic copy. You may wish to print out all Electronic
              Records and keep them for your records. If you have any trouble
              printing out, downloading, and/or accessing any Electronic
              Records, you may <Button theme="clean-secondary" className="font-regular hover:!text-black-70 !inline underline" onClick={ () => dispatch( showModal( GlobalModals.help )) }>contact us</Button>.
              In order for you to access and retain Electronic Records sent
              by Ethos Estate Planning, LLC, you must have the following hardware and software:
              a computer capable of reading html and text files, a modem or
              other means of accessing the Internet, and a browser capable of
              accessing and displaying the Ethos Estate Planning, LLC website. To print the
              Electronic Records, you will also need a printer. You may
              withdraw your consent by <Button theme="clean-secondary" className="font-regular hover:!text-black-70 !inline underline" onClick={ () => dispatch( showModal( GlobalModals.help )) }>contacting us</Button>.
              However, the services provided by Ethos Estate Planning, LLC are only available
              if you agree to receive Electronic Records, and you understand
              that withdrawing such consent may result in your account being
              closed.
            </Paragraph>
          </Section>

          <Section id="16-0">
            Indemnification

            <Paragraph>
              To the fullest extent permitted by applicable law, you agree to
              indemnify, defend, and hold harmless Ethos Estate Planning, LLC, each of our
              subsidiaries and affiliates, or our licensors and Partners and
              each of our respective officers, directors, agents, partners
              and employees (individually and collectively, the
              “<em><u>Ethos Estate Planning, LLC Parties</u></em>”) from and against any loss,
              liability, claim, demand, damages, fines and any related
              expenses or costs ("<em><u>Claims</u></em>") arising out of or
              related to (a) your access to or use of our Services; (b) your
              User Content or Feedback; (c) your violation of these Terms;
              (d) your violation, misappropriation or infringement of any
              rights of another (including intellectual property rights or
              privacy rights); (e) your conduct in connection with our
              Services; or (f) any violation by you of any federal, state, or
              local laws, statutes, rules, or regulations. You agree to
              promptly notify the Ethos Estate Planning, LLC Parties of any third party Claims,
              cooperate with the Ethos Estate Planning, LLC Parties in defending such Claims
              and pay all fees, costs and expenses associated with defending
              such Claims (including, but not limited to, attorneys' fees,
              costs and expenses).  You also agree that the Ethos Estate Planning, LLC Parties
              will have control of the defense or settlement of any third
              party Claims. This indemnity is in addition to, and not in lieu
              of, any other indemnities set forth in a written agreement
              between you and Ethos Estate Planning, LLC or the other Ethos Estate Planning, LLC Parties.
            </Paragraph>
          </Section>

          <Section id="17-0">
            Disclaimers

            <SubSectionList>
              <SubSection id="17-1">
                <u>
                  We do not control, endorse or take responsibility for any
                  User Content, third-party content available on or linked to
                  our Services or products or services offered by a third
                  party that are advertised through the Services (including
                  any Insurance Policies).
                </u>
              </SubSection>
              <SubSection id="17-2">
                <u>
                  Your use of our Services is at your sole risk. Our
                  Services are provided “as is” and “as available” without
                  warranties of any kind, either express or implied,
                  including, but not limited to, implied warranties of
                  merchantability, fitness for a particular purpose, title,
                  and non-infringement. In addition, Ethos Estate Planning, LLC does not
                  represent or warrant that our Services are accurate,
                  complete, reliable, current or error-free. While Ethos Estate Planning, LLC
                  attempts to make your access to and use of our Services
                  safe, we cannot and do not represent or warrant that our
                  Services or servers are free of viruses or other harmful
                  components. You assume the entire risk as to the quality
                  and performance of the Services. Additionally, you
                  acknowledge that information you store or transfer through
                  our Services may become irretrievably lost or corrupted or
                  temporarily unavailable due to a variety of causes,
                  including software failures, protocol changes by third
                  party providers, internet outages, force majeure events or
                  other disasters, scheduled or unscheduled maintenance, or
                  other causes either within or outside our control. You are
                  solely responsible for backing up and maintaining duplicate
                  copies of any information you store or transfer through our
                  Services.
                </u>
              </SubSection>
            </SubSectionList>
          </Section>

          <Section id="18-0">
            Limitation of Liability

            <SubSectionList>
              <SubSection id="18-1">
                <u>
                  To the maximum extent permitted by applicable law,
                  Ethos Estate Planning, LLC and the other Ethos Estate Planning, LLC Parties will not be liable
                  to you under any theory of liability—whether based in
                  contract, tort, negligence, strict liability, warranty, or
                  otherwise—for any indirect, consequential, exemplary,
                  incidental, punitive or special damages or lost profits,
                  even if Ethos Estate Planning, LLC or the other Ethos Estate Planning, LLC Parties have been
                  advised of the possibility of such damages.
                </u>
              </SubSection>
              <SubSection id="18-2">
                <u>
                  The total liability of Ethos Estate Planning, LLC and the other Ethos Estate Planning, LLC
                  Parties, for any claim arising out of or relating to these
                  Terms or our Services, regardless of the form of the
                  action, is limited to the greater of the amount paid, if
                  any, by you to access or use our Services, or, if you have
                  not paid any amounts for the use of any Services, the
                  amount of $100.
                </u>
              </SubSection>
              <SubSection id="18-3">
                <u>
                  The limitations set forth in this Section (a) will apply
                  even if the remedies otherwise provided under these Terms,
                  at law or in equity, fail of their essential purpose, and
                  regardless of the form or cause of action or the alleged
                  basis of the claim, and (b) will not limit or exclude
                  liability for the gross negligence, fraud or intentional
                  misconduct of Ethos Estate Planning, LLC or the other Ethos Estate Planning, LLC Parties or for
                  any other matters in which liability cannot be excluded or
                  limited under applicable law. Additionally, some
                  jurisdictions do not allow the exclusion or limitation of
                  incidental or consequential damages, so the above
                  limitations or exclusions may not apply to you.
                </u>
              </SubSection>
            </SubSectionList>
          </Section>

          <Section id="19-0">
            Release

            <Paragraph>
              To the fullest extent permitted by applicable law, you release
              Ethos Estate Planning, LLC and the other Ethos Estate Planning, LLC Parties from responsibility,
              liability, claims, demands, and/or damages (actual and
              consequential) of every kind and nature, known and unknown
              (including, but not limited to, claims of negligence), arising
              out of or related to disputes between users and the acts or
              omissions of third parties. &nbsp;

              <u>
                You expressly waive any rights you may have under California
                Civil Code § 1542 as well as any other statute or common law
                principles that would otherwise limit the coverage of this
                release to include only those claims which you may know or
                suspect to exist in your favor at the time of agreeing to
                this release.
              </u>

            </Paragraph>
          </Section>

          <Section id="20-0">
            Transfer and Processing Data

            <Paragraph>
              By accessing or using our Services, you consent to the
              processing, transfer and storage of information about you in
              and to the United States and other countries, where you may not
              have the same rights and protections as you do under local law.
            </Paragraph>
          </Section>

          <Section id="21-0">
            Dispute Resolution; Binding Arbitration


            <Paragraph>
              <u>
                Please read the following section carefully because it
                requires you to arbitrate certain disputes and claims with
                Ethos Estate Planning, LLC and limits the manner in which you can seek relief
                from us.
              </u>
            </Paragraph>

            <SubSectionList>
              <SubSection id="21-1">
                Except for small claims disputes in which you or Ethos Estate Planning, LLC
                seek to bring an individual action in small claims court
                located in the county of your billing address or disputes in
                which you or Ethos Estate Planning, LLC seeks injunctive or other equitable
                relief for the alleged unlawful use of intellectual property,
                you and Ethos Estate Planning, LLC waive your rights to a jury trial and to
                have any dispute arising out of or related to these Terms or
                our Services resolved in court. Instead, all disputes arising
                out of or relating to these Terms or our Services will be
                resolved through confidential binding arbitration held in
                Seattle, Washington in accordance with the Streamlined
                Arbitration Rules and Procedures ("<em><u>Rules</u></em>") of
                the Judicial Arbitration and Mediation Services
                ("<em><u>JAMS</u></em>"), which are available on the JAMS
                website and hereby incorporated by reference. You either
                acknowledge and agree that you have read and understand the
                rules of JAMS or waive your opportunity to read the rules of
                JAMS and any claim that the rules of JAMS are unfair or
                should not apply for any reason.
              </SubSection>
              <SubSection id="21-2">
                You and Ethos Estate Planning, LLC agree that any dispute arising out of or
                related to these Terms or our Services is personal to you and
                Ethos Estate Planning, LLC and that any dispute will be resolved solely through
                individual arbitration and will not be brought as a class
                arbitration, class action or any other type of representative
                proceeding.
              </SubSection>
              <SubSection id="21-3">
                You and Ethos Estate Planning, LLC agree that these Terms affect interstate
                commerce and that the enforceability of this Section 21 will
                be substantively and procedurally governed by the Federal
                Arbitration Act, 9 U.S.C. § 1, et seq.
                (the "<em><u>FAA</u></em>"), to the maximum extent permitted
                by applicable law. As limited by the FAA, these Terms and the
                JAMS Rules, the arbitrator will have exclusive authority to
                make all procedural and substantive decisions regarding any
                dispute and to grant any remedy that would otherwise be
                available in court; provided, however, that the arbitrator
                does not have the authority to conduct a class arbitration or
                a representative action, which is prohibited by these Terms.
                The arbitrator may only conduct an individual arbitration and
                may not consolidate more than one individual’s claims,
                preside over any type of class or representative proceeding
                or preside over any proceeding involving more than one
                individual. You and Ethos Estate Planning, LLC agree that for any arbitration
                you initiate, you will pay the filing fee and Ethos Estate Planning, LLC will
                pay the remaining JAMS fees and costs.  For any arbitration
                initiated by Ethos Estate Planning, LLC, Ethos Estate Planning, LLC will pay all JAMS fees and
                costs.  You and Ethos Estate Planning, LLC agree that the state or federal
                courts of the State of Washington and the United States
                sitting in Seattle, Washington have exclusive jurisdiction
                over any appeals and the enforcement of an arbitration award.
              </SubSection>
              <SubSection id="21-4">
                Any claim arising out of or related to these Terms or our
                Services must be filed within one year after such claim
                arose; otherwise, the claim is permanently barred, which
                means that you and Ethos Estate Planning, LLC will not have the right to assert
                the claim.
              </SubSection>
              <SubSection id="21-5">
                You have the right to opt out of binding arbitration within
                thirty (30) days of the date you first accepted the terms of
                this Section 21 by emailing &nbsp;
                <a href="mailto:support@ethoslife.com"><u>support@ethoslife.com</u></a>.
                In order to be effective, the opt out notice must include
                your full name and clearly indicate your intent to opt out of
                binding arbitration. By opting out of binding arbitration, you
                are agreeing to resolve Disputes in accordance with Section
                22.
              </SubSection>
            </SubSectionList>
          </Section>

          <Section id="22-0">
            Governing Law and Venue

            <Paragraph>
              <u>
                These Terms and your access to and use of our Services will
                be governed by and construed and enforced in accordance with
                the laws of Washington, without regard to conflict of law
                rules or principles (whether of Washington or any other
                jurisdiction) that would cause the application of the laws of
                any other jurisdiction. Any dispute between the parties that
                is not subject to arbitration or cannot be heard in small
                claims court will be resolved in the state or federal courts
                of Washington and the United States, respectively, sitting
                in Seattle, Washington.
              </u>
            </Paragraph>
          </Section>

          <Section id="23-0">
            Changes to these Terms

            <Paragraph>
              We may make changes to these Terms from time to time. If we
              make changes, we will provide you notice by posting the amended
              Terms to our Services and updating the “Last Updated” date
              above. We may also attempt to notify you by sending an email
              notification to the address associated with your account or by
              providing notice through our Services. Unless we say otherwise
              in our notice of changes, the amended Terms will be effective
              immediately and your continued access to and use of our
              Services after we provide notice will confirm your acceptance
              of the changes. If you do not agree to the amended Terms, you
              must stop accessing and using our Services.
            </Paragraph>
          </Section>

          <Section id="24-0">
            Termination

            <Paragraph>
              We reserve the right, without notice and in our sole
              discretion, to terminate your right to access or use our
              Services at any time. Without limiting the foregoing, we may,
              in our sole discretion and without liability to you, with or
              without prior notice and at any time, modify or discontinue,
              temporarily or permanently, any portion of our Services.  We
              are not responsible for any loss or harm related to your
              inability to access or use our Services.
            </Paragraph>
          </Section>

          <Section id="25-0">
            Severability

            <Paragraph>
              If any provision or part of a provision of these Terms is
              unlawful, void or unenforceable, that provision or part of the
              provision is deemed severable from these Terms and does not
              affect the validity and enforceability of any remaining
              provisions.
            </Paragraph>
          </Section>

          <Section id="26-0">
            Export Compliance

            <Paragraph>
              The Services are only intended for use by persons located in
              the United States.  If you choose to access the Services from
              outside the United States, you do so at your own risk and you
              are responsible for compliance with all local laws.
              Additionally, all or part of our Services may be subject to
              U.S. export control and economic sanctions laws
              (“<u><em>Export Controls</em></u>”). You agree to abide by all
              Export Controls as they relate to your access and use of our
              Services.
            </Paragraph>
          </Section>

          <Section id="27-0">
            Apple Requirements.

            <Paragraph>
              If you downloaded the Services from the Mac App Store, the
              following terms also apply to you:
            </Paragraph>

            <SubSectionList>
              <SubSection id="27-1">
                Acknowledgement: You acknowledge that this Agreement is
                between you and Ethos Estate Planning, LLC only, and not with Apple, and
                Ethos Estate Planning, LLC, not Apple, is solely responsible for the Services
                and the content thereof.
              </SubSection>
              <SubSection id="27-2">
                Scope of License: The license granted to you for the Services
                is a limited, non-transferable license to use the Services on
                a Mac product that you own or control and as permitted by the
                Usage Rules set forth in the terms of service applicable to
                the Mac App Store.
              </SubSection>
              <SubSection id="27-3">
                Maintenance and Support: Ethos Estate Planning, LLC and not Apple is solely
                responsible for providing any maintenance and support
                services, for which additional fees may apply, with respect
                to the Services. You acknowledge that Apple has no obligation
                whatsoever to furnish any maintenance and support services
                with respect to the Services.
              </SubSection>
              <SubSection id="27-4">
                Warranty: Ethos Estate Planning, LLC is solely responsible for any product
                warranties, whether express or implied by law, to the extent
                not effectively disclaimed. In the event of any failure of
                the Services to conform to any applicable warranty, you may
                notify Apple, and Apple will refund the purchase price for
                the Services to you. To the maximum extent permitted by
                applicable law, Apple will have no other warranty obligation
                whatsoever with respect to the Services, and any other
                claims, losses, liabilities, damages, costs or expenses
                attributable to any failure to conform to any warranty will
                be Ethos Estate Planning, LLC’s sole responsibility.
              </SubSection>
              <SubSection id="27-5">
                Product Claims: Ethos Estate Planning, LLC, not Apple, is responsible for
                addressing any user or third party claims relating to the
                Services or the user’s possession and/or use of the Services,
                including, but not limited to: (a) product liability claims;
                (b) any claim that the Services fail to conform to any
                applicable legal or regulatory requirement; and (c) claims
                arising under consumer protection or similar legislation.
              </SubSection>
              <SubSection id="27-6">
                Intellectual Property Rights: You acknowledge that, in the
                event of any third party claim that the Services or your
                possession and use of the Services infringes that third
                party’s intellectual property rights, Ethos Estate Planning, LLC, not Apple,
                will be solely responsible for the investigation, defense,
                settlement and discharge of any such intellectual property
                infringement claim.
              </SubSection>
              <SubSection id="27-7">
                Legal Compliance: You represent and warrant that (a) you are
                not located in a country that is subject to a U.S. Government
                embargo, or that has been designated by the U.S. Government
                as a terrorist-supporting country; and (b) you are not listed
                on any U.S. Government list of prohibited or restricted
                parties.
              </SubSection>
              <SubSection id="27-8">
                Developer Contact Info: Direct any questions, complaints or
                claims by mail to: Ethos Estate Planning, LLC, 1606 Headway
                Circle #9013 Austin, TX 78754, or by email: &nbsp;
                <a href="support@ethoslife.com">
                  <u>
                    support@ethoslife.com
                  </u>
                </a>
              </SubSection>
              <SubSection id="27-9">
                Third Party Terms of Agreement: You must comply with any
                applicable third party terms of agreement when using the
                Services.
              </SubSection>
              <SubSection id="27-10">
                Third Party Beneficiary: You acknowledge and agree that Apple
                and Apple’s subsidiaries are third party beneficiaries of
                this Agreement, and that, upon your acceptance of the terms
                and conditions of the Agreement, Apple will have the right
                (and will be deemed to have accepted the right) to enforce
                the Agreement against you as a third party beneficiary
                thereof.
              </SubSection>
            </SubSectionList>
          </Section>

          <Section id="28-0">
            Premium Upgrade Bonus Offer

            <Paragraph>
              Ethos Estate Planning, LLC's premium upgrade bonus offer is valid for existing
              qualifying users who successfully refer a new Ethos Estate Planning, LLC user.
              The premium upgrade bonus is one Ethos Estate Planning, LLC Plus upgrade, when
              the bonus criteria are met, from Ethos Estate Planning, LLC's inventory of
              available incentives for this offer. The Ethos Estate Planning, LLC Plus
              membership current value is $39.99/year and is limited to its
              feature set at the time of the award. Ethos Estate Planning, LLC may change the
              features and pricing of Ethos Estate Planning, LLC Plus at any time. The bonus
              may be awarded for one year or longer at Ethos Estate Planning, LLC's discretion.
              Ethos Estate Planning, LLC displays the odds of winning the bonus and how the
              odds improve when certain actions are taken. The potential for
              winning the bonus will be credited only if the invitation is
              sent trough the Ethos Estate Planning, LLC platform. If awarded, the bonus will
              be credited to the qualified user's account within one week.
              Any bonus not claimed within 30 days may expire. The bonus has
              no cash value. Ethos Estate Planning, LLC reserves the right to change the offer
              terms or terminate the offer at any time without notice. The
              offer is limited to one per user. The offer is not
              transferable, saleable, or valid in conjunction with certain
              other offers and is available to U.S. residents only, excluding
              Puerto Rico, the U.S. Virgin Islands and other territories.
              Ethos Estate Planning, LLC may decline requests to enroll in the offer at its
              discretion. Other restrictions may apply.
            </Paragraph>
          </Section>

          <Section id="29-0">
            Miscellaneous

            <Paragraph>
              These Terms constitute the entire agreement between you and
              Ethos Estate Planning, LLC relating to your access to and use of our Services.
              The failure of Ethos Estate Planning, LLC to exercise or enforce any right or
              provision of these Terms will not operate as a waiver of such
              right or provision. The section titles in these Terms are for
              convenience only and have no legal or contractual effect.
              Except as otherwise provided herein, these Terms are intended
              solely for the benefit of the parties and are not intended to
              confer third party beneficiary rights upon any other person or
              entity.
            </Paragraph>
          </Section>
        </SubSectionList>

      </SegmentView>
    </main>
  );
}
