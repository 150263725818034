import { ReactElement } from 'react';

// Hooks
import Translation from './Translation';

// Types
import { TranslationKeys } from '../store/initial/translations';

export interface DescriptiveLinkProps {
  className?: string;
  descriptionKey: TranslationKeys;
  labelKey: TranslationKeys;
}

export default function DescriptiveLink({ descriptionKey, labelKey }: DescriptiveLinkProps ): ReactElement{
  return (
    <div className="flex justify-between bg-moss--dark hover:bg-moss rounded py-3 px-4">
      <Translation translationKey={ descriptionKey } />
      <span className="text-forest font-bold">
        <Translation translationKey={ labelKey } />
      </span>
    </div>
  );
}
