import { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Components
import { hideModal } from '../store/reducers/modalReducer';
import { GlobalModals } from '../store/initial/modal';
import ActionSheet from './ActionSheet';

// Hooks
import useTranslations from '../hooks/useTranslations';

// Redux
import { useDispatch, useSelector } from '../store';
import { deleteRole } from '../store/reducers/rolesReducer';
import { makeSnack } from '../store/reducers/snackbarReducer';

// Selectors
import { getPersonByID } from '../utils/people/getPersonByID';

// Routes
import { medical_consent_caretaker } from '../../core/routes';

// Models
import PersonInterface from '../types/PersonInterface';
import Person from '../core-data-service/models/Person';
import useCaretakers from '../hooks/useCaretakers';

export interface EditCaretakerActionSheetProps {
  caretakerID: PersonInterface['id'];
}

export default function EditCaretakerActionSheet({ caretakerID }: EditCaretakerActionSheetProps ): ReactElement{
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { wardId } = useParams<{wardId: string}>();
  const { getTranslation } = useTranslations();

  const { roles } = useCaretakers( wardId );

  const caretaker = useSelector( getPersonByID( caretakerID ));
  const caretakerRole = roles.find( role => role.grantee_id === caretaker?.id );
  const isOpen = useSelector( state => state.modal.data.editCaretakerActionSheet.isOpen );

  const handleClose = () => dispatch( hideModal( GlobalModals.editCaretakerActionSheet ));
  const firstName = caretaker ? new Person( caretaker ).firstName : '';
  const caretakerRemovedSuccessMessage = getTranslation( 'medicalconsent.banner.role.caretaker.removed', [ firstName ]);

  const handleEditClicked = ()  => {
    push( medical_consent_caretaker.get({ wardId: wardId, caretakerId: caretakerID }));
    dispatch( hideModal( GlobalModals.editCaretakerActionSheet ));
  };

  const handleRemoveClicked= ()=> {
    if( !caretakerRole ) {
      return;
    }
    dispatch( deleteRole({ id: caretakerRole.id, onSuccess: () => {
      handleClose();
      dispatch( makeSnack({ message: caretakerRemovedSuccessMessage }));
    } }));
  };

  const buttonsToRender = {
    edit: {
      label: getTranslation( 'medicalconsent.actionsheet.caretaker.editpersondetails' ),
      action: () => handleEditClicked(),
    },
    remove: {
      label: getTranslation( 'medicalconsent.actionsheet.caretaker.remove' ),
      action: () => handleRemoveClicked(),
    },
  };

  return (
    <ActionSheet
      title={ firstName }
      buttonsToRender={ buttonsToRender }
      isOpen={ isOpen }
      handleClose={ handleClose }
    />
  );
}

