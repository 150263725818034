import React, { FC, ReactNode, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Components
import Button from './Button';
import Form from './Form';
import StripeField from './StripeField';
import TextField from './TextField';
import useTranslations from '../hooks/useTranslations';
import { fieldRequired } from '../formValidators/fieldRequired';

// Types
import SanitizedFormData from '../../core/types/SanitizedFormData';

import stripeHelper from '../utils/stripeHelper';

const ERROR_MESSAGE = 'Something went wrong...';

export interface PaymentFormProps {
  contentBeforeForm?: ReactNode;
  contentAfterForm?: ReactNode;
  userEmail?: string;
  className?: string;
  billingDetails: {address: {postal_code: string}};
  skipButtonLabel?: ReactNode;
  sku: string;
  submitButtonLabel: ReactNode;
  onSkip?: ()=> void;
  onSuccess?: ()=> void;
  onSubmit?: ( onSuccess: ()=> void, onError: ()=> void, email: string )=> void;
  onError?: ( err: string )=> void;
}

export interface PaymentFormData extends SanitizedFormData {
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
  cardName: string;
}

const elementOptions = {
  style: {
    base: {
      color: 'rgba(0,0,0,0.7)',
      lineHeight: '21px',
      '::placeholder': {
        // injected in stripe iFrame, css vars don't work
        color: '#d4d4d4',
      },
    },
    invalid: {},
  },
};


export const StripePaymentForm: FC<PaymentFormProps> = ({
  submitButtonLabel,
  contentBeforeForm,
  contentAfterForm,
  className='',
  userEmail,
  billingDetails,
  sku,
  onSubmit,
  onSuccess,
  onError,
}) => {

  const { register, setValue, getValues, handleSubmit, formState: { errors } } = useForm<PaymentFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {},
  });

  const [ isPending, setIsPending ] = useState( false );
  const [ isDisabled, setIsDisabled ] = useState( false );
  const setProcessing = ( state: boolean ) =>{
    setIsPending( state );
    setIsDisabled( state );
  };

  const stripe = useStripe();
  const elements = useElements();

  const useFormProps = { register, setValue, getValues, errors };

  const handleError = ( err: string ) =>{
    setProcessing( false );
    return onError && onError( err );
  };

  const handleOnSubmit: SubmitHandler<SanitizedFormData> = async data => {
    setProcessing( true );
    const email = userEmail || data.email || '';
    return onSubmit && onSubmit( async() => {
      if( !elements ) {
        return handleError( ERROR_MESSAGE );
      }
      const cardNumber =  elements.getElement( CardNumberElement );
      if( !stripe || !cardNumber ) {
        return handleError( ERROR_MESSAGE );
      }
      try {
        const cardToken = await stripe.createToken( cardNumber, { name: data.cardName || '',
          address_zip: billingDetails?.address?.postal_code || '' },
        );
        const associatedPaymentToken = cardToken?.token?.id;
        const response = await stripeHelper.processPayment( associatedPaymentToken, sku );
        if ( response?.status === 201 ) {
          return onSuccess && onSuccess();
        }
        return handleError( ERROR_MESSAGE );
      } catch ( e ) {
        return handleError( e as string || ERROR_MESSAGE );
      }
    },
    () => setProcessing( false ),
    email );
  };

  const { getTranslation } = useTranslations();

  return (
    <Form
      onSubmit={ handleOnSubmit }
      className={ `${className} flex flex-col flex-1` }
      handleSubmit={ handleSubmit }
    >
      <div className="md:grid md:grid-cols-2 md:gap-x-16 align-center mb-2">
        <div>
          { contentBeforeForm &&
            <div className="mb-6">{contentBeforeForm}</div>
          }
        </div>

        <div>
          <p className="font-bold text-black pt-4 mb-8 border-t border-black-20 sm-only:hidden">{ getTranslation( 'products.estateplan.paywall.checkout.payment' ) }</p>

          <TextField { ...useFormProps }
            label="Cardholder Name"
            type="text"
            name="cardName"
            required={ fieldRequired( 'Name' ) }
            className="mb-4 md:mb-6"
            isDarkBg
          />
          <StripeField
            className="mb-4 md:mb-6"
            name="card"
            label="Card Number"
            options={ elementOptions }
            render={ props => <CardNumberElement { ...props } /> }
          />
          <div className="grid grid-cols-2 gap-x-8 md:mb-4">
            <StripeField
              name="cardExpiry"
              label="Exp. Date"
              options={ elementOptions }
              render={ props => <CardExpiryElement { ...props } /> }
            />
            <StripeField
              name="cvcLabel"
              label="Security Code"
              options={ elementOptions }
              render={ props => <CardCvcElement { ...props } /> }
            />
          </div>

          { contentAfterForm &&
            <div className="mb-2 md:mb-8">
              {contentAfterForm}
            </div>
          }
        </div>
      </div>

      <Button
        className="shadow-button md:max-w-sm md:px-32"
        type="submit"
        id={ 'StripePaymentForm-primaryAction' }
        isPending={ isPending }
        disabled={ isDisabled }
      >
        {submitButtonLabel}
      </Button>

    </Form>
  );
};




export default StripePaymentForm;
