import React, { FC, ReactElement } from 'react';
import Icon, { Icons } from './Icon';

export interface ListItemProps {
  icon?: Icons;
  iconClasses?: string;
  className?: string;
  children?: React.ReactNode;
}

interface Props {
  items: ListItemProps[];
  itemClasses?: string;
  className?: string;
  uniqueKey: string;
  type?: 'ul' | 'ol';
}

const OrderedList: FC<{className: string}> = ({ children, className='' }) => {
  return <ol className={ className }>{children}</ol>;
};

const UnorderedList: FC<{className: string}> = ({ children, className='' }) => {
  return <ul className={ className }>{children}</ul>;
};

const ListItem: FC<ListItemProps> = ({ icon, children, className = '', iconClasses = '' }) => {
  return <li className={ `${className} flex` }>
    {icon && <Icon name={ icon } className={ `${iconClasses} flex-shrink-0 mr-3` }/>}
    <div>
      {children}
    </div>
  </li>;
};

export default function List({ items, className = '', itemClasses='', type = 'ul', uniqueKey }: Props ): ReactElement{
  const renderedItems = items.map(( item, i ) =>
    <ListItem key={ `${i}-${uniqueKey}` } icon={ item.icon } iconClasses={ item.iconClasses } className={ itemClasses }>{item.children}</ListItem> );

  return (
    type === 'ul' ?
      <UnorderedList className={ className }>{renderedItems}</UnorderedList>
      :
      <OrderedList className={ className }>{renderedItems}</OrderedList>
  );
}
