import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Routes
import { properties as propertiesRoute, will as willRoute } from '../../../../core/routes';

// Components
import ActionContainer from '../../../components/ActionContainer';
import AddButton from '../../../components/AddButton';
import PropertySubtypePicker from '../PropertySubtypePicker';
import PropertyRolePicker from '../../../components/PropertyRolePicker';
import SegmentView from '../../../components/SegmentView';
// import InsuranceHelper from '../../../components/InsuranceHelper';

// Hooks
import useBackstack from '../../../hooks/useBackstack';
import { useDispatch, useSelector } from '../../../store';
import useProperties from '../../../hooks/useProperties';
import useTranslations from '../../../hooks/useTranslations';

// Models
import Property, { PropertySubtype } from '../../../core-data-service/models/Property';
import { PETS_URL_NAMESPACE as urlNamespace } from '../../../../core/constants';

// Reducers
import { setUserAttributes } from '../../../store/reducers/userReducer';
import analytics from '../../../utils/analytics';

function PetsSummary(): ReactElement{
  const [ isAddModalVisible, setIsAddModalVisible ] = useState( false );
  const { goBack } = useBackstack( willRoute.get());
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const { pets } = useProperties();
  const userAttributes = useSelector( state => state.user.data.attributes );

  const handleExitPets = ( callback: ()=> void ) => {
    analytics.track( 'Touch - Finish Pet Guardians' );
    if( !userAttributes['app.petsChapterStarted']) {
      dispatch( setUserAttributes({
        'app.petsChapterStarted': true,
        onSuccess: callback,
      }));
    } else {
      callback();
    }
  };

  const handleSetSubtype = ( subtype: PropertySubtype ) => {
    setIsAddModalVisible( false );
    push({
      pathname: propertiesRoute.get({ id: 'create', urlNamespace }),
      search: `?category=${Property.TYPE.PET}&subtype=${subtype}`,
    });
  };

  return (
    <SegmentView
      onRetreat={ () => handleExitPets( goBack ) }
      title={ getTranslation( 'products.will.chapter.pets.title' ) }
      primaryActionLabel={ getTranslation( 'products.will.chapter.actions.finish' ) }
      subtitle={ getTranslation( 'products.will.chapter.pets.subtitle' ) }
      onPrimaryAction={ () => {
        handleExitPets(() => push( willRoute.get()));
      } }
    >
      <AddButton
        onClick={ () => setIsAddModalVisible( true ) }
        iconName="paw"
        labelTranslationKey="products.will.pets.actions.create"
      />

      {pets.map( pet => (
        <ActionContainer
          key={ pet.id }
          primaryActionShouldFillContainer={ true }
          className="bg-moss rounded p-6 mt-6"
          header={ <p className="font-bold text-black">{pet.name}</p> }
          headerClassName="mb-3"
          primaryAction={ () => push({
            pathname: propertiesRoute.get({ id: pet.id, urlNamespace }),
            search: `?category=${Property.TYPE.PET}&subtype=${pet.subtype}`,
          }) }
        >
          <PropertyRolePicker propertyItem={ pet } hasQuickAdd={ true } />
        </ActionContainer>
      ))}
      <PropertySubtypePicker
        onClose={ () => setIsAddModalVisible( false ) }
        isOpen={ isAddModalVisible }
        category={ 'PET' }
        onItemSelect={ handleSetSubtype }
      />
    </SegmentView>
  );
}

export default PetsSummary;
