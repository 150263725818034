import { ReactElement } from 'react';

// Components
import PersonIcon from '../People/PersonIcon';
import Translation from '../../components/Translation';

// Types
import PersonInterface from '../../types/PersonInterface';
import InheritanceInterface from '../../types/InheritanceInterface';
import Person from '../../core-data-service/models/Person';

// Routes
import { will } from '../../../core/routes';

// Hooks
import { DistributionTypes, getDistributionType } from '../../hooks/useHeirs';

// Constants
import { SECONDARY_DISTRIBUTION_AGE } from '../../../core/constants';


interface Props {
  person: PersonInterface;
  inheritance?: InheritanceInterface;
  className?: string;
  documentRoute: string;
}

export default function HeirSummaryListItem({
  person,
  inheritance,
  className = '',
  documentRoute,
}: Props ): ReactElement{
  const _person = new Person( person );

  const getHeirDescription = () => {
    const dynamicStringValues = [ _person.firstName, `${inheritance?.share}%` ];

    if( documentRoute === will.get() || !inheritance ) {
      return <Translation translationKey="products.heirs.inheritances.timing.status" dynamicStringValues={ dynamicStringValues } />;
    }

    switch( getDistributionType( inheritance, _person )){
    case DistributionTypes.disinherited:
      return <Translation translationKey="products.heirs.inheritances.timing.options.disinherited" dynamicStringValues={ [ _person.firstName ] } />;
    case DistributionTypes.immediate:
      return <Translation translationKey="products.heirs.inheritances.timing.status.paid_now" dynamicStringValues={ dynamicStringValues } />;
    case DistributionTypes.immediateAndLater:
      return <Translation translationKey="products.heirs.inheritances.timing.status.paid_now_and_later" dynamicStringValues={ dynamicStringValues } />;
    case DistributionTypes.staged:
      return <Translation translationKey="products.heirs.inheritances.timing.status.paid_in_stages" dynamicStringValues={ dynamicStringValues } />;
    case DistributionTypes.atAge:
      return <Translation translationKey="products.heirs.inheritances.timing.status.paid_at_age" dynamicStringValues={ [ ...dynamicStringValues, SECONDARY_DISTRIBUTION_AGE.toString() ] } />;
    case DistributionTypes.heldInTrust:
      return <Translation translationKey="products.heirs.inheritances.timing.status.held_in_trust" dynamicStringValues={ dynamicStringValues } />;
    }

  };
  return (
    <div className={ `${className} flex` }>
      <div className="h-12 w-12 mr-4 rounded-full overflow-hidden">
        <PersonIcon
          className="w-full aspect-w-1 aspect-h-1"
          photo_url={ person.photo_url }
          personName={ person.name }
          alt={ `${_person.firstName}'s avatar` }
        />
      </div>
      <div style={{ transform: 'translateY(14px)', color: 'text-black' }}>
        {getHeirDescription()}
      </div>
    </div>
  );
}
