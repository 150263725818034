import { compile, parse, Token, Key } from 'path-to-regexp';
import { REAL_ESTATE_URL_NAMESPACE, VALUABLES_URL_NAMESPACE, PETS_URL_NAMESPACE, ACCOUNTS_URL_NAMESPACE } from '../constants';
import disabilityRoutes from './disabilityRoutes';
import poaRoutes from './documents/poaRoutes';
import healthcareDirectiveRoutes from './documents/healthcareDirectiveRoutes';
import medicalConsentRoutes from './documents/medicalConsentRoutes';
import trustRoutes from './documents/trustRoutes';
import willRoutes from './documents/willRoutes';
import staticRoutes from './staticRoutes';


interface RouteOptions {
  key: string;
  name: string;
  path: string;
  status?: number;
  template: string;
}

interface QueryParams {
  [key: string]: string;
}

interface PathArgs {
  [key: string]: string;
}


interface Route extends RouteOptions {
  get: ( args?: PathArgs, queryParams?: QueryParams )=> string | undefined;
  // getSearch: ( queryParams?: QueryParams )=> string|undefined;
}


/**
 * Make `route` object with magic `get` method
 */
export const makeRoute = ( options: RouteOptions ): Route => {
  return {
    ...options,

    /**
     * Pass given options to compile method from path-to-regexp
     * any "express style" paths will be translated with given key/val obj
     * ex: given { path: "/foo/:foo_id/:bar", foo_id: '8675308', bar: 'baz' }
     *     return -> "/foo/8675309/baz"
     */
    get: ( args = {}, queryParams = {}) => {

      // Throw if passed invalid args
      const tokens = parse( options.path );
      const isKey = ( token: Token ): token is Key => typeof token !== 'string'; // typegaurd
      const validArgs = tokens.filter( isKey ).map( token => token.name );
      if( !Object.keys( args ).every( arg => validArgs.includes( arg ))) {
        throw new TypeError(
          `Route.get received an invalid argument keyname - valid options are: ${validArgs}`,
        );
      }

      // The compile function will return a function for transforming parameters into a valid path:
      // https://github.com/pillarjs/path-to-regexp#compile-reverse-path-to-regexp
      const pathname = compile( options.path, { encode: encodeURIComponent })( args );
      const search = new URLSearchParams( queryParams ).toString();
      return !!search ? `${pathname}?${search}` : pathname;
    },
  };
};


/**
 * Routes object will be an ordered dict
 */
const routes = new Map();
const routesList: RouteOptions[] =[
  ...trustRoutes,
  ...willRoutes,
  ...poaRoutes,
  ...healthcareDirectiveRoutes,
  ...medicalConsentRoutes,
  ...disabilityRoutes,
  ...staticRoutes,

  /**
   * Ios Prompt
   */
  {
    key: 'appStore',
    name: 'Download the ios App',
    path: '/appStore',
    template: 'index.html',
  },

  /**
   * Dashboard
   */
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    template: 'index.html',
  },

  /**
   * Home
   */
  {
    key: 'home',
    name: 'Home',
    path: '/',
    template: 'index.html',
  },

  /**
   * Life Insurance
   */
  {
    key: 'lifeInsurance',
    name: 'Life Insurance',
    path: '/life-insurance',
    template: 'index.html',
  },

  /**
   * Login
   */
  {
    key: 'login',
    name: 'Login',
    path: '/login',
    template: 'index.html',
  },

  /**
   * Signup
   */
  {
    key: 'signup',
    name: 'Signup',
    path: '/signup',
    template: 'index.html',
  },

  /**
   * Profile
   */
  {
    key: 'profile',
    name: 'Profile',
    path: '/profile',
    template: 'index.html',
  },

  /**
   * People
   */
  {
    key: 'people',
    name: 'Family List View',
    path: '/family',
    template: 'index.html',
  },
  {
    key: 'person',
    name: 'Person Detail View',
    path: '/family/:id',
    template: 'index.html',
  },

  /**
   * Property
   */
  {
    key: 'properties',
    name: 'Property detail views',
    path: `/properties/:urlNamespace(assets|${ACCOUNTS_URL_NAMESPACE}|${VALUABLES_URL_NAMESPACE}|${REAL_ESTATE_URL_NAMESPACE}|${PETS_URL_NAMESPACE})/:id`,
    template: 'index.html',
  },

  /**
 * Invites - Send Invites
 */
  {
    key: 'sendInvites',
    name: 'Send Invites',
    path: '/send-invites',
    template: 'index.html',
  },

  /**
   * Invites - View
   */
  {
    key: 'invite',
    name: 'View Invitation',
    path: '/invite/:id',
    template: 'index.html',
  },

  /**
   * Invites - Accepted
   */
  {
    key: 'inviteAccepted',
    name: 'Invitation Accepted',
    path: '/invite/:id/accepted',
    template: 'index.html',
  },

  /**
   * Inheritance Share
   */
  {
    key: 'heir',
    name: 'Edit a Persons Share of the Inheritance',
    path: '/heirs/:id/:documentContext',
    template: 'index.html',
  },

  /**
   * What is Tomorrow
   */
  {
    key: 'whatIsTomorrow',
    name: 'What is Tomorrow?',
    path: '/what-is-tomorrow',
    template: 'index.html',
  },

  /**
   * Reset password
   */
  {
    key: 'resetPassword',
    name: 'Reset Password',
    path: '/reset-password',
    template: 'index.html',
  },

  /**
   * Email Verification
   */
  {
    key: 'emailVerification',
    name: 'Email Verification',
    path: '/verify',
    template: 'index.html',
  },

  /**
   * Test error boundary
   */
  {
    key: 'testErrorBoundary',
    name: 'Test Error Boundary',
    path: '/test-error-boundary',
    template: 'index.html',
  },

  {
    key: 'blackRockLandingPage',
    name: 'BlackRock',
    path: '/blackrock/',
    template: 'index.html',
  },
  {
    key: 'checkout',
    name: 'Checkout',
    path: '/checkout',
    template: 'index.html',
  },

  /**
   * 404 - Not Found
   */
  {
    key: '404',
    status: 404,
    name: '404',
    path: '*',
    template: 'index.html',
  },

];

routesList.forEach( route => {
  return routes.set( route.key, makeRoute( route ));
});

/**
 * Module exports
 */
export const accounts = routes.get( 'accounts' );
export const appStore = routes.get( 'appStore' );
export const checkout = routes.get( 'checkout' );
export const dashboard = routes.get( 'dashboard' );
export const will_guardians = routes.get( 'will_guardians' );
export const will_executors = routes.get( 'will_executors' );
export const will_realEstate = routes.get( 'will_realEstate' );
export const heir = routes.get( 'heir' );
export const home = routes.get( 'home' );
export const invite = routes.get( 'invite' );
export const inviteAccepted = routes.get( 'inviteAccepted' );
export const lifeInsurance = routes.get( 'lifeInsurance' );
export const login = routes.get( 'login' );
export const people = routes.get( 'people' );
export const person = routes.get( 'person' );
export const will_pets = routes.get( 'will_pets' );
export const pourover_will = routes.get( 'pourOverWill' );
export const properties = routes.get( 'properties' );
export const profile = routes.get( 'profile' );
export const sendInvites = routes.get( 'sendInvites' );
export const signup = routes.get( 'signup' );
export const will = routes.get( 'will' );
export const will_basics = routes.get( 'will_basics' );
export const will_heirs = routes.get( 'will_heirs' );
export const will_intro = routes.get( 'will_intro' );
export const will_review = routes.get( 'will_review' );
export const will_valuables = routes.get( 'will_valuables' );
export const trust = routes.get( 'trust' );
export const trust_review = routes.get( 'trust_review' );
export const trust_basics = routes.get( 'trust_basics' );
export const trustees = routes.get( 'trustees' );
export const trust_heirs = routes.get( 'trust_heirs' );
export const trust_funding = routes.get( 'trust_funding' );
export const whatIsTomorrow = routes.get( 'whatIsTomorrow' );
export const iosLicenseInfo = routes.get( 'iosLicenseInfo' );
export const iosLegalDisclaimer = routes.get( 'iosLegalDisclaimer' );
export const iosTermsOfService = routes.get( 'iosTermsOfService' );
export const resetPassword = routes.get( 'resetPassword' );
export const emailVerification = routes.get( 'emailVerification' );
export const testErrorBoundary = routes.get( 'testErrorBoundary' );
export const blackRockLanding = routes.get( 'blackRockLandingPage' );
export const disability_login = routes.get( 'disability_login' );
export const disability_login_code = routes.get( 'disability_login_code' );
export const disability_welcome = routes.get( 'disability_welcome' );
export const disability_coverage = routes.get( 'disability_coverage' );
export const disability_application = routes.get( 'disability_application' );
export const disability = routes.get( 'disability' );
export const disability_payment = routes.get( 'disability_payment' );
export const disability_introduction = routes.get( 'disability_introduction' );
export const fourOhFour = routes.get( '404' );
export const poa = routes.get( 'poa' );
export const healthcare_directive = routes.get( 'healthcare_directive' );
export const healthcare_directive_intro = routes.get( 'healthcare_directive_intro' );
export const healthcare_directive_address = routes.get( 'healthcare_directive_address' );
export const healthcare_directive_agent = routes.get( 'healthcare_directive_agent' );
export const healthcare_directive_wishes = routes.get( 'healthcare_directive_wishes' );
export const healthcare_directive_review = routes.get( 'healthcare_directive_review' );
export const poa_intro = routes.get( 'poa_intro' );
export const poa_attorney_in_fact = routes.get( 'poa_attorney_in_fact' );
export const poa_powers = routes.get( 'poa_powers' );
export const poa_special_instructions = routes.get( 'poa_special_instructions' );
export const poa_review = routes.get( 'poa_review' );
export const medical_consent = routes.get( 'medical_consent' );
export const medical_consent_document = routes.get( 'medical_consent_document' );
export const medical_consent_care_info = routes.get( 'medical_consent_care_info' );
export const medical_consent_caretaker = routes.get( 'medical_consent_caretaker' );
export const medical_consent_address = routes.get( 'medical_consent_address' );
export const medical_consent_medications = routes.get( 'medical_consent_medications' );
export const medical_consent_allergies = routes.get( 'medical_consent_allergies' );
export const medical_consent_doctors = routes.get( 'medical_consent_doctors' );

/**
 * All Routes
 * @var {Map}
 */
export default routes;
