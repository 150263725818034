import { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes
import { trust_review, trust_funding, accounts, trust, checkout } from '../../../../core/routes';
import { DOCUMENTS } from '../../../core-data-service/models/Document';

// Hooks
import useTrustSectionStatus from '../../../hooks/useTrustSectionStatus';

// Redux
import { useSelector } from '../../../store';
import productsSelector from '../../../store/selectors/productsSelector';
import { generateQueryString } from '../../../utils/urlUtils';

// Views
import TrustAddFunds from '../../../views/Documents/Trust/TrustAddFunds';
import TrustReview from '../../../views/Documents/Trust/TrustReview';
import Accounts from '../../../views/Property/Accounts';

export default function TrustReviewRouter(): ReactElement{
  const { canCompleteTrust } = useTrustSectionStatus();
  const { pro } = useSelector( productsSelector );

  return (
    <Switch>
      {!canCompleteTrust && <Redirect to={ trust.path } />}
      {!pro.hasPurchased && <Redirect to={{ pathname: checkout.path, search: generateQueryString({ document: DOCUMENTS.trust }) }} />}
      <Route exact path={ trust_review.path } component={ TrustReview } />
      <Route exact path={ trust_funding.path } component={ TrustAddFunds } />
      <Route exact path={ accounts.path } component={ Accounts } />
    </Switch>
  );
}
