import { ReactElement } from 'react';
import { FieldErrors, RegisterOptions, UseFormMethods } from 'react-hook-form';

// Components
import SelectField from './SelectField';
import TextField from './TextField';
import Translation from './Translation';

// Utils
import inputMask from '../utils/inputMask';

// Types
import Address from '../core-data-service/models/Address';
import { FORM_PERSON_INPUTS } from '../views/People/PersonDetailForm';

type AddressFieldsProps = {
    register: UseFormMethods['register'];
    setValue: UseFormMethods['setValue'];
    getValues: UseFormMethods['getValues'];
    errors: FieldErrors;
    required?: {
      street?: {
        required: RegisterOptions['required'];
      };
      street2?: {
        required: RegisterOptions['required'];
      };
      city?: {
        required: RegisterOptions['required'];
      };
      state?: {
        required: RegisterOptions['required'];
      };
      zip?: {
        required: RegisterOptions['required'];
        minLength: RegisterOptions['minLength'];
      };
    };
}

const AddressFields = ({
  errors,
  getValues,
  register,
  setValue,
  required,
}: AddressFieldsProps ): ReactElement => {

  const useFormProps = {
    errors,
    getValues,
    register,
    setValue,
  };

  return(
    <>
      <TextField { ...useFormProps }
        name={ FORM_PERSON_INPUTS.STREET }
        label={ <Translation translationKey="address.street.label"/> }
        autoComplete="off"
        { ...required?.street }
      />

      <TextField { ...useFormProps }
        name={ FORM_PERSON_INPUTS.STREET2 }
        label={ <Translation translationKey="address.street2.label"/> }
        autoComplete="off"
        { ...required?.street2 }

      />

      <TextField { ...useFormProps }
        name={ FORM_PERSON_INPUTS.CITY }
        label={ <Translation translationKey="address.city.label"/> }
        autoComplete="off"
        { ...required?.city }
      />

      <div className="grid grid-cols-2 gap-x-8">
        <SelectField { ...useFormProps }
          label={ <Translation translationKey="address.state.label"/> }
          name={ FORM_PERSON_INPUTS.STATE }
          options={ Address.US_STATES }
          uniqueId="state"
          { ...required?.state }
        />
        <TextField { ...useFormProps }
          name={ FORM_PERSON_INPUTS.ZIP }
          label={ <Translation translationKey="address.zip.label"/> }
          { ...required?.zip }
          normalize={ inputMask.zip }
          type="tel"
        />
      </div>
    </>
  );
};

export default AddressFields;
