import { ReactElement, useEffect } from 'react';

// Components
import Flow from '../../components/Flow';
import GuardiansSummary from './GuardiansSummary';
import GuardiansInvites from './GuardiansInvites';

// Hooks
import useFlowQueryParams from '../../hooks/useFlowQueryParams';
import { useDispatch } from '../../store';
import { setUserAttributes } from '../../store/reducers/userReducer';


export default function Guardians(): ReactElement{

  const currentIndex = useFlowQueryParams([ 'invites' ]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( setUserAttributes({
      'app.guardiansChapterStarted': true,
      'app.guardiansChapterNeedsReview': false,
    }));
  }, [ dispatch ]);

  return(
    <div className="flex fixed inset-0 bg-white">
      <Flow currentIndex={ currentIndex }>
        <GuardiansSummary />
        <GuardiansInvites />
      </Flow>
    </div>
  );
}
