import { FC } from 'react';
import Person from '../../core-data-service/models/Person';


// Components
import { useSelector } from '../../store';
import PersonInterface from '../../types/PersonInterface';
import PeopleListView from './PeopleListView';


export const sortPeople = ( a: PersonInterface, b: PersonInterface ): number =>
  ( new Person( a ).sortBy > new Person( b ).sortBy )
    ? 1 : -1;


export const PeopleView: FC = () => {
  const people = useSelector( state => state.people.data )
    .filter( person => person.relationship !== 'ME' )
    .sort( sortPeople );
  return <PeopleListView people={ people }/>;
};


export default PeopleView;
