import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Routes
import {
  will_executors as executorsRoute,
  will as willRoute,
} from '../../../core/routes';

// Components
import Flow from '../../components/Flow';
import SegmentView from '../../components/SegmentView';
import Translation from '../../components/Translation';
import ExecutorsSummary from './ExecutorsSummary';
import InvitesList from '../Invites/InvitesList';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import useTranslations from '../../hooks/useTranslations';
import useExecutors from '../../hooks/useExecutors';
import useFlowQueryParams from '../../hooks/useFlowQueryParams';

// Redux
import { useDispatch } from '../../store';
import { setUserAttributes } from '../../store/reducers/userReducer';

// Types
import Role from '../../core-data-service/models/Role';
import { pop } from '../../store/reducers/backstackReducer';

export default function Executors(): ReactElement{

  const { goBack } = useBackstack( willRoute.get());
  const { push } = useHistory();

  const { getTranslation } = useTranslations();
  const { hasBackupExecutor } = useExecutors();

  const dispatch =  useDispatch();

  const currentIndex = useFlowQueryParams([ 'invites' ,'warning' ]);


  useEffect(() => {
    dispatch( setUserAttributes({
      'app.executorsChapterStarted': true,
    }));
  }, [ dispatch ]);


  return (
    <div className="flex fixed inset-0 bg-white">
      <Flow currentIndex={ currentIndex }>

        {/* 0 = default/undefined (no page query param) */}
        <ExecutorsSummary />

        {/* 1 = ...?page=invites */}
        <SegmentView
          onRetreat={ () => push( executorsRoute.get()) }
          onPrimaryAction={ () => hasBackupExecutor
            ? goBack()
            : push( executorsRoute.get({}, { page: 'warning' }))
          }
          primaryActionLabel={ getTranslation( '(button)done' ) }
          title={ `Invite your ${Role.TYPE.EXECUTOR.toLowerCase()}s.` }
        >
          <InvitesList roleType={ Role.TYPE.EXECUTOR }/>
        </SegmentView>

        {/* 2 = ...?page=warning */}
        { <SegmentView
          onRetreat={ goBack }
          title={ <Translation translationKey="(screen)executor.title" /> }
          primaryActionLabel={ 'I understand' }
          onPrimaryAction={ () => {
            dispatch( pop());
            push( willRoute.get());
          } }
        >
          <Translation translationKey="(screen)executor.subtitle" />
        </SegmentView>
        }
      </Flow>
    </div>
  );
}
