import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import appConfig from '../../core/appConfig';
import SanitizedFormData from '../../core/types/SanitizedFormData';
import { fieldRequired } from '../formValidators';
import useClipboard from '../hooks/useClipboard';
import useTranslations from '../hooks/useTranslations';
import useUserAttributes from '../hooks/useUserAttributes';
import { useDispatch } from '../store';
import { setUserAttributes } from '../store/reducers/userReducer';
import Button from './Button';
import Form from './Form';
import Icon from './Icon';
import TextField from './TextField';
import houseIcon from '../assets/images/houseIcon.svg';
import { SPOUSE_PARTNER_CODE } from '../../core/constants';
import analytics from '../utils/analytics';

interface SpousePerkFormData extends SanitizedFormData {
  code: string;
}

export function EPPSpousePerk(): ReactElement{
  const { copyToClipboard } = useClipboard();
  const { getTranslation } = useTranslations();
  const params = new URLSearchParams({
    partner_code: SPOUSE_PARTNER_CODE,
  }).toString();
  const spouseShareCode = `${appConfig.app.baseurl}?${params}`;

  // Form hooks
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState,
  } = useForm<SpousePerkFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      spouseShareCode,
    },
  });

  const { errors } = formState;
  const useFormProps = { register, setValue, getValues, errors };

  const [ hasEnteredSpouseEmail, setHasEnteredSpouseEmail ] = useState( false );
  const [ hasCopiedLink, setHasCopiedLink ] = useState( false );
  const { hasGeneratedSpousePerkCode } = useUserAttributes();

  const dispatch = useDispatch();

  const setHasGeneratedCode = () => {
    dispatch(
      setUserAttributes({
        'app.hasGeneratedSpousePerkCode': true,
      }),
    );
  };

  const onClick = () => {
    analytics.track( 'Touch - Spouse Perk Component', getButtonText());
    if ( !hasEnteredSpouseEmail ) {
      setHasGeneratedCode();
      setHasEnteredSpouseEmail( true );

      // Storing this attribute to know which users have generated in case we decide to deactivate it for them in the future
      setValue( 'spouseShare', spouseShareCode );
    } else if ( !hasCopiedLink ) {
      copyToClipboard( spouseShareCode );
      setHasCopiedLink( true );
    }
  };

  const getButtonText = () => {
    if ( !hasEnteredSpouseEmail ) {
      return getTranslation( 'spouse.perk.button.generate.code' );
    } else if ( !hasCopiedLink ) {
      return getTranslation( 'spouse.perk.button.copy.link' );
    } else {
      return getTranslation( 'spouse.perk.button.copied' );
    }
  };

  useEffect(() => {
    analytics.track( 'View - Spouse Perk Component' );
  }, []);

  return (
    <div>
      <hr className="sm-only:-mx-8 mb-8 border-t border-grey" />
      <div className="flex flex-row gap-4 items-center mb-5">
        <div>
          <h2 className="text-3xl font-headline text-black">
            {getTranslation( 'spouse.perk.header' )}
          </h2>
          <p className="mt-2 text-dark-grey">
            {getTranslation( 'spouse.perk.subheader' )}
          </p>
        </div>
        <div className="hidden md:block">
          <img src={ houseIcon } width="138" height="200" alt="house icon" />
        </div>
      </div>
      {hasEnteredSpouseEmail && (
        <div className="flex flex-row bg-moss h-10 mt-7 mb-5 items-center rounded-sm border border-forest">
          <Icon name="check-circle" className="w-6 h-6 text-forest mr-5 ml-5" />
          <p className="text-dark-grey">
            {'Copy and share their unique link below'}
          </p>
        </div>
      )}

      <Form handleSubmit={ handleSubmit } onSubmit={ onClick }>
        <div className="grid grid-cols-3 gap-4 mb-10 md:grid-cols-5 items-start">
          <TextField
            { ...useFormProps }
            label={ hasEnteredSpouseEmail ? '' : 'Enter your spouse\'s email' }
            minLength={ 1 }
            disabled={ hasCopiedLink }
            name="spouseShare"
            placeholder={ getTranslation( '(card)email(placeholder)' ) }
            className="mb-0 col-span-3"
            required={ hasEnteredSpouseEmail ? undefined : fieldRequired( getTranslation( 'error.invalid.email' )) }
            type= { hasEnteredSpouseEmail ? undefined : 'email' }
            onClick={ hasEnteredSpouseEmail ? onClick : undefined }
          />
          <Button
            small
            theme={ 'primary' }
            className={ `col-span-3 font-bold md:col-span-2 h-12 ${
              hasEnteredSpouseEmail ? 'mt-1' : 'mt-6'
            }` }
            type="submit"
          >
            {getButtonText()}
          </Button>
        </div>
      </Form>
    </div>
  );
}
