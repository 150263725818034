import { FC, useState, Dispatch, SetStateAction, ReactNode } from 'react';

// Components
import PersonIcon from './PersonIcon';
import PhotoDropzone from '../../components/PhotoDropzone';

// Utils
import { compressIfNeeded, convertHeicToJpeg } from '../../utils/compressImage';
import Button from '../../components/Button';
import PersonInterface from '../../types/PersonInterface';
import Translation from '../../components/Translation';


const PersonDetailAvatar: FC<{
  dispatchDeletePhoto: ( person?: PersonInterface|null, onSuccess?: ()=> void )=> void;
  isPendingPhoto: boolean;
  person?: PersonInterface;
  photo_url: string|null;
  isAvatar: boolean;
  setIsChoosingAvatar: Dispatch<SetStateAction<boolean>>;
  setIsCropAccepted: Dispatch<SetStateAction<boolean>>;
  setIsCropping: ( isCropping: boolean )=> void;
  setIsProcessingPhoto: Dispatch<SetStateAction<boolean>>;
  setStagedPhotoObjURL: Dispatch<SetStateAction<string|undefined>>;
  setStagedPhotoUpload: Dispatch<SetStateAction<File|null>>;
}> = ({
  dispatchDeletePhoto,
  isPendingPhoto,
  person,
  photo_url,
  isAvatar,
  setIsChoosingAvatar,
  setIsCropAccepted,
  setIsCropping,
  setIsProcessingPhoto,
  setStagedPhotoObjURL,
  setStagedPhotoUpload,
}) => {

  const [ shouldOpenFileDialog, setShouldOpenFileDialog ] = useState<boolean>( false );

  const processPhoto = ( photo: File ): void => {
    if ( !photo ){
      return;
    }
    setIsProcessingPhoto( true );
    convertHeicToJpeg( photo )
      .then( photo => {
        setIsProcessingPhoto( false );
        // Each time you call createObjectURL(), a new object URL is created, even
        // if you've already created one for the same object. Each of these must be
        // released by calling URL.revokeObjectURL() when you no longer need them.
        // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
        setStagedPhotoObjURL( URL.createObjectURL( photo ));
        setStagedPhotoUpload( photo );
        if( photo.type === 'image/gif' ){ // preserve animated gifs. why not?? 😅
          setIsCropAccepted( true );
        } else {
          setIsCropping( true );
        }
      })
      .catch( error => {
        console.error( error );
        setIsProcessingPhoto( false );
      });
    return;
  };
  /**
   * Process image and save a tmp file to the `PersonDetailView` component state.
   * Then open `PhotoCropper` component.
   * @param files
   */
  const onPhotoDrop = async( files: Array<File> ): Promise<void> => {
    await compressIfNeeded( files[0], processPhoto );
  };

  const dropProps = {
    onDrop: onPhotoDrop,
    shouldOpenFileDialog,
    setShouldOpenFileDialog,
  };

  const personIcon = (
    <PersonIcon
      isPending={ isPendingPhoto }
      photo_url={ photo_url }
      personName={ person?.name }
      alt="person avatar icon"
      className="w-32"
    />
  );

  const getUpdateIconButton = ( text: ReactNode ) => (
    <Button
      className="mr-2 flex-0"
      small
      theme={ !!photo_url ? 'clean' : 'ghost' }
    >
      { text }
    </Button>
  );

  return(
    <div className="flex content-start mb-8">
      {
        !isAvatar &&
          <PhotoDropzone { ...dropProps }>
            {
              !!photo_url &&
                personIcon
            }
          </PhotoDropzone>
      }
      {
        isAvatar &&
          <div
            role="button"
            tabIndex={ 0 }
            onClick={ () => setIsChoosingAvatar( true ) }
            onKeyDown={ () => setIsChoosingAvatar( true ) }
          >
            { personIcon }
          </div>
      }
      <div className={
        !!photo_url
          ? 'ml-6 flex flex-col justify-center'
          : 'flex flex-row justify-left'
      }>
        {
          !isAvatar &&
            <PhotoDropzone { ...dropProps }>
              { getUpdateIconButton( photo_url ? <Translation translationKey={ 'addperson.photo.states.photo_added.buttons.replace.title' }/> : <Translation translationKey={ 'addperson.photo.states.empty.buttons.photo.title' }/> ) }
            </PhotoDropzone>
        }
        {
          isAvatar &&
            <div
              role="button"
              tabIndex={ 0 }
              onClick={ () => setIsChoosingAvatar( true ) }
              onKeyDown={ () => setIsChoosingAvatar( true ) }
            >
              { getUpdateIconButton( <Translation translationKey={ 'addperson.photo.states.avatar_added.buttons.replace.title' }/> ) }
            </div>
        }
        {!photo_url &&
          <Button
            small
            theme="ghost"
            onClick={ () => setIsChoosingAvatar( true ) }
          >
            <Translation translationKey={ 'addperson.photo.states.empty.buttons.avatar.title' }/>
          </Button>
        }
        {
          !!photo_url &&
          <div>
            <Button
              theme="danger-ghost"
              className="mt-4"
              onClick={ () => dispatchDeletePhoto( person ) }
            >
              <Translation translationKey={ 'addperson.photo.states.avatar_added.buttons.remove.title' }/>
            </Button>
          </div>
        }
      </div>
    </div>
  );
};


export default PersonDetailAvatar;
