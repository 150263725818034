import { RootState } from '../index';
import ApplicationInterface from '../../types/ApplicationInterface';

const applicationsSelector = ( state: RootState ): Array<ApplicationInterface> => {
  return state.application.data;
};

export const isFetchingApplications = ( state: RootState ): boolean => {
  return state.application.fetchApplications.meta.requestStatus ==='pending';
};

export const isFetchingApplication = ( state: RootState ): boolean => {
  return state.application.fetchApplication.meta.requestStatus ==='pending';
};

export const isPendingApplicationSave = ( state: RootState ): boolean => {
  return state.application.patchApplication.meta.requestStatus === 'pending';
};


export default applicationsSelector;
