import RoleInterface from '../../types/RoleInterface';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  data: [] as Array<RoleInterface>,
  // for extra reducers
  fetchRole: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchRoles: {
    meta: {} as AsyncThunkMetaInterface,
  },
  patchRole: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postRole: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
