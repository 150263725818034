import { useParams } from 'react-router-dom';
import { Keyable } from '../../types/Keyable';

type idParams = 'id' | 'wardId' | 'caretakerId';

export const withPersonDetailParam = ( Component: any, idParam: idParams = 'id' ) => {
  return ( props: any ) => {
    const idToRender = useParams<Keyable>()[idParam];
    return <Component { ...props } id={ idToRender } />;
  };
};
