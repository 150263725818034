import { ReactElement } from 'react';
import Icon from './Icon';

export interface QuickAddCircleButtonProps {
  className?: string;
  onClick?: ()=> void;
}

function QuickAddCircleButton({
  className='',
  onClick,
}: QuickAddCircleButtonProps ): ReactElement{

  return(
    <button
      className={ `
        group
        w-full
        relative
        focus:outline-none
        ${className}`
      }
      onClick={ onClick }
    >
      <div
        className={ `
        rounded-full
        bg-yellow
        shadow-circle-icon
        group-hover:bg-yellow-600
        aspect-h-1
        aspect-w-1
      ` }
      ></div>
      <Icon name={ 'plus' } className="text-black absolute group-hover:scale-110 transition-transform top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
    </button>
  );
}

export default QuickAddCircleButton;
