import { ReactElement } from 'react';

// Components
import InvitationStatusButton from './InvitationStatusButton';

// Hooks
import useInvitation from '../hooks/useInvitation';

// Redux
import { useDispatch } from '../store';
import { showShareModal } from '../store/reducers/modalReducer';


// Types
import Invitation from '../core-data-service/models/Invitation';
import analytics from '../utils/analytics';
import { RoleTypes } from '../core-data-service/models/Role';


interface IInvitationWrapperProps {
  inviteeId: string;
  invitationType?: string;
  role?: RoleTypes;
  statusButtonClickEvent?: string;
}

export default function InvitationWrapper({
  inviteeId,
  invitationType = Invitation.TYPE_RELATIONSHIP,
  role,
  statusButtonClickEvent = 'Touch - Passive Invite',
}: IInvitationWrapperProps ): ReactElement{
  const dispatch = useDispatch();
  const { status, id = '', firstName } = useInvitation( inviteeId, invitationType, role );

  const handleInviteButtonClicked = () => {
    analytics.track( statusButtonClickEvent, {
      variant: role?.toLowerCase() || invitationType,
    });
    dispatch( showShareModal({ inviteId: id }));
  };

  return (
    id ? <>
      <InvitationStatusButton
        inviteeName={ firstName }
        inviteStatus={ status }
        onClick={ handleInviteButtonClicked }
      />
    </>
      : <></>
  );
}
