import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components / Views
import AttorneyInFact from './AttorneyInFact';
import HorizontalRule from '../../../../components/HorizontalRule';
import SegmentView from '../../../../components/SegmentView';
import Translation from '../../../../components/Translation';
import AddButton from '../../../../components/AddButton';

// Hooks
import useBackstack from '../../../../hooks/useBackstack';
import useTranslations from '../../../../hooks/useTranslations';

// Redux
import { useDispatch } from '../../../../store';
import { postRole } from '../../../../store/reducers/rolesReducer';

// Routes
import { poa as PoaRoute } from '../../../../../core/routes';

// Types
import Role from '../../../../core-data-service/models/Role';

// Utils
import analytics from '../../../../utils/analytics';
import PeopleSelector from '../../../../components/PeopleSelector';
import { toTitleCase } from '../../../../../core/utils/stringHelpers';
import useAttorneyInFact from '../../../../hooks/useAttorneyInFact';

export default function AttorneysInFactSummary(): ReactElement{

  const { push } = useHistory();
  const dispatch = useDispatch();
  const { goBack } = useBackstack( PoaRoute.get());
  const { getTranslation } = useTranslations();

  const [ isAddingAttorneyInFact, setIsAddingAttorneyInFact ] = useState<boolean>( false );

  const { roles, attorneysInFact, potentialNewAttorneysInFact } = useAttorneyInFact();

  /**
   * check if it has a fallback, route to warning if false
   */
  const onPrimaryAction = () => {
    analytics.track( 'Touch - Finish Attorneys In Fact' );
    push( PoaRoute.get());

  };

  const handlePersonSelected = ( id: string ) => {
    setIsAddingAttorneyInFact( false );
    dispatch( postRole({
      grantee_id: id,
      name: Role.TYPE.ATTORNEY_IN_FACT,
    }));
  };

  const hasExistingAttorneyInFact = attorneysInFact.length > 0;

  return (
    <>
      {isAddingAttorneyInFact && <PeopleSelector
        isOpen
        people={ potentialNewAttorneysInFact }
        optionTypeLabel={ toTitleCase( Role.TYPE.ATTORNEY_IN_FACT ) }
        onClose={ id => {
          id ? handlePersonSelected( id ) : setIsAddingAttorneyInFact( false );
        } }
        onSelect={ id => {
          handlePersonSelected( id );
        } }
        titleTranslationKey="products.will.select.an.person.title"
      />}
      <SegmentView
        onRetreat={ goBack }
        title={ <Translation translationKey={ 'poa.chapter.title.attorneyinfact' }/> }
        primaryActionLabel={ getTranslation( '(button)done' ) }
        hidePrimaryAction={ false }
        onPrimaryAction={ onPrimaryAction }
        subtitle={ <Translation translationKey="screen.attorneyinfact.subtitle" /> }
      >
        <HorizontalRule className="md:hidden"/>
        {attorneysInFact.length > 0 &&
          attorneysInFact.map(( attorney, idx ) =>
            <AttorneyInFact key={ `attorney${idx}` }
              className="mb-8"
              attorneyInFact={ attorney }
              idx={ idx }
              role={ roles.find( role => role.grantee_id === attorney.id ) }
            />,
          )
        }
        <span className="font-bold">
          <Translation translationKey={ hasExistingAttorneyInFact ? 'tile.role.title.backup' : 'tile.role.title.primary' } />
        </span>
        <AddButton
          onClick={ () => setIsAddingAttorneyInFact( true ) }
          iconName={ 'addPerson' }
          className={ 'mt-4' }
        />
      </SegmentView>
    </>
  );
}
