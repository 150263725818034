import branch from 'branch-sdk';
import { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from '../store';
import userSelector from '../store/selectors/userSelector';

// Reducers
import { fetchConfig } from '../store/reducers/configReducer';
import { fetchGoals } from '../store/reducers/goalsReducer';
import { fetchInheritances } from '../store/reducers/inheritancesReducer';
import { setIsLoading } from '../store/reducers/isLoadingReducer';
import { fetchPeople, patchPerson } from '../store/reducers/peopleReducer';
import { fetchProperties } from '../store/reducers/propertiesReducer';
import { fetchRoles } from '../store/reducers/rolesReducer';
import { fetchTranslations } from '../store/reducers/translationsReducer';
import { fetchUser, patchUser, postUser, setUserAttributes, setUserPartnerCode } from '../store/reducers/userReducer';
import { fetchDocuments } from '../store/reducers/documentsReducer';
import { fetchExperiments } from '../store/reducers/experimentsReducer';
import { fetchApplications } from '../store/reducers/applicationsReducer';
import { fetchInvitations } from '../store/reducers/invitationsReducer';
import { fetchPowers } from '../store/reducers/powersReducer';

// Utils
import { decodeJWT } from '../utils/cryptoUtils';
import appConfig from '../../core/appConfig';
import WebClientRequest from '../core-data-service/WebClientRequest';
import { filterAttributionParams } from '../utils/analytics';
import { LOCAL_STORAGE_REFERRAL_ID, LOCAL_STORAGE_UTM_ATTRIBUTION, QUERY_REFERRAL_ID, SEARCH_PARAM_EMAIL, SEARCH_PARAM_FIRST_NAME, SEARCH_PARAM_LAST_NAME, SEARCH_PARAM_PARTNER_CODE, SEARCH_PARAM_ZIP } from '../../core/constants';

// Types
import { BranchData } from '../types/BranchData';
import { fetchDirectives } from '../store/reducers/directivesReducer';
import { setInitialParams } from '../store/reducers/initialParamsReducer';
import { makeSnack } from '../store/reducers/snackbarReducer';
import userPersonSelector from '../store/selectors/userPersonSelector';


interface IUseFetchData {
  hasCompleted: boolean;
  isLoading: boolean;
  tokenGrant?: string;
}

const useFetchData = (): IUseFetchData => {
  const dispatch = useDispatch();
  const accessToken = useSelector( state => state.auth.access_token );
  const parsedToken = !!accessToken ? decodeJWT( accessToken ) : null;
  const tokenPayload = !!parsedToken ? parsedToken[1] : null;
  const tokenGrant = tokenPayload?.grant;

  const isLoading = useSelector( state => state.isLoading );
  const hasConsumedInitialParams = useSelector( state => state.initialParams );

  const hasFetchedConfig = useSelector( state => state.config.fetchConfig.meta.requestStatus === 'fulfilled' );
  const hasFetchedUser = useSelector( state => state.user.fetchUser.meta.requestStatus === 'fulfilled' );
  const hasFetchedPeople = useSelector( state => state.people.fetchPeople.meta.requestStatus === 'fulfilled' );
  const hasFetchedProperties = useSelector( state => state.properties.fetchProperties.meta.requestStatus === 'fulfilled' );
  const hasFetchedInheritances = useSelector( state => state.inheritances.fetchInheritances.meta.requestStatus === 'fulfilled' );
  const hasFetchedRoles = useSelector( state => state.roles.fetchRoles.meta.requestStatus === 'fulfilled' );
  const hasFetchedGoals = useSelector( state => state.goals.fetchGoals.meta.requestStatus === 'fulfilled' );
  const hasFetchedDocuments = useSelector( state => state.documents.fetchDocuments.meta.requestStatus === 'fulfilled' );
  const hasFetchedExperiments = useSelector( state => state.experiments.fetchExperiments.meta.requestStatus === 'fulfilled' );
  const hasFetchedTranslations = useSelector( state => state.translations.fetchTranslations.meta.requestStatus === 'fulfilled' );
  const hasFetchedPowers = useSelector( state => state.powers.fetchPowers.meta.requestStatus === 'fulfilled' );
  const hasFetchedDirectives = useSelector( state => state.directives.fetchDirectives.meta.requestStatus === 'fulfilled' );

  const hasCompleted = Boolean(
    hasFetchedConfig &&
    hasFetchedDocuments &&
    hasFetchedGoals &&
    hasFetchedInheritances &&
    hasFetchedPeople &&
    hasFetchedProperties &&
    hasFetchedRoles &&
    hasFetchedExperiments &&
    hasFetchedUser &&
    hasFetchedTranslations &&
    hasFetchedPowers &&
    hasFetchedDirectives,
  );

  const user = useSelector( userSelector );

  /**
   * If a user logs in, all of the dispatches have already been run, so they
   * end up seeing a flash of content without this useState variable.
   */

  // set `isLoading` false after complete
  useEffect(() => {
    if( hasCompleted && isLoading && hasConsumedInitialParams ) {
      dispatch( setIsLoading( false ));
    }
  }, [ hasCompleted, dispatch, isLoading, hasConsumedInitialParams ]);

  // initial data fetch ....
  useEffect(() => {
    dispatch( fetchTranslations());
  }, [ dispatch ]);

  // fetch user data if token grant is password
  useEffect(() => {
    const runDispatches = () => {
      dispatch( fetchExperiments());

      dispatch( fetchUser());

      dispatch( fetchConfig());

      dispatch( fetchPeople());

      dispatch( fetchInvitations());

      dispatch( fetchProperties());

      dispatch( fetchInheritances());

      dispatch( fetchRoles());

      dispatch( fetchGoals());

      dispatch( fetchDocuments());

      dispatch( fetchApplications());

      dispatch( fetchPowers());

      dispatch( fetchDirectives());
      /**
       * If you grab the translations before you have a user access token,
       * there will be no test data in the response.
       */
      dispatch( fetchTranslations());

      dispatch( setInitialParams( true ));
    };

    if( tokenGrant === 'password' ){
      WebClientRequest.getAccessToken()
        .then(() => {
          dispatch( setInitialParams( false ));
          dispatch( setIsLoading( true ));

          const query = new URLSearchParams( window.location.search );

          // Handle straight to checkout flow
          const checkoutEmail = query.get( SEARCH_PARAM_EMAIL );
          const checkoutZip = query.get( SEARCH_PARAM_ZIP );
          const checkoutNameFirst = query.get( SEARCH_PARAM_FIRST_NAME );
          const checkoutNameLast = query.get( SEARCH_PARAM_LAST_NAME );

          // Only patch user/person data if we have everything we need
          if ( checkoutEmail && checkoutZip && checkoutNameFirst && checkoutNameLast && user.person_id ) {
            dispatch( patchUser({ email:checkoutEmail }));

            const address = {
              zip: checkoutZip,
            };

            const id = user.person_id ?? '';
            const name = `${checkoutNameFirst.trim()} ${checkoutNameLast.trim()}`;

            dispatch( patchPerson({ id: id, address, name }));
          }

          // encode/store UTM params for later use in insurance funnel
          window.localStorage.setItem( LOCAL_STORAGE_UTM_ATTRIBUTION, btoa( filterAttributionParams( window.location.search )));

          // set ethos referral_id for if/when acct is created
          const referral_id = query.get( QUERY_REFERRAL_ID );
          if ( referral_id ) {
            window.localStorage.setItem( LOCAL_STORAGE_REFERRAL_ID, referral_id );
          }

          // hard-coded partner code param required for epp
          // @TODO remove me when branch is integrated to ethos
          const partnerCode = query.get( SEARCH_PARAM_PARTNER_CODE );
          if ( partnerCode ) {
            dispatch( setUserPartnerCode({ partnerCode }));
          }

          // Branch experience directly affects experiments
          branch.init( appConfig.branch.key, {}, ( _, data: BranchData | null ) => {
            const filteredParsedData = Object.entries( data?.data_parsed || {}).reduce(( acc, curr ) => {
              const[ key, value ] = curr;
              if ( key.startsWith( 'ua_' )) {
                acc[key] = `${value}`;
              }
              return acc;
            }, {} as {[key: string]: string});

            // set ethos referral_id
            const referral_id = data?.data_parsed['ref_id'];
            if ( referral_id ) {
              window.localStorage.setItem( LOCAL_STORAGE_REFERRAL_ID, referral_id );
            }

            // branch partner code param required advertisements
            const partnerCode = data?.data_parsed['partner_code'];
            if ( partnerCode ) {
              dispatch( setUserPartnerCode({ partnerCode }));
            }

            if( Object.keys( filteredParsedData ).length ) {
              dispatch( setUserAttributes({ ...filteredParsedData, onSuccess: ()=> {
                runDispatches();
              } }));
            } else {
              runDispatches();
            }
          });
        });
    } else if( tokenGrant === 'client' ){
      // checks for a user to keep from an additional loading state
      if( user.id ) {
        dispatch( setIsLoading( false ));
      } else {
        dispatch( postUser());
      }
    }
  }, [ tokenGrant, dispatch, user.id ]);

  return {
    hasCompleted,
    isLoading,
    tokenGrant,
  };
};


export default useFetchData;
