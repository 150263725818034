import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import DocumentSectionOverview from '../../../components/DocumentSectionOverview';
import DocumentNextButton from '../DocumentNextButton';
import DocumentRoleSummaryItem from '../DocumentRoleSummaryItem';
import Helper from '../../../components/Helper';
import MissingPersonData from '../../../components/MissingPersonData';
import Modal from '../../../components/Modal';
import ProductUpsellHelper from '../../../components/ProductUpsellHelper';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';

// Hooks
import useAgents from '../../../hooks/useAgents';
import useMissingPersonData from '../../../hooks/useMissingPersonData';
import useDirectiveSectionStatus, {
  DirectiveSections,
  DirectiveSectionStates,
  DirectiveSectionTitleKey,
} from '../../../hooks/useDirectiveSectionStatus';
import useTranslations from '../../../hooks/useTranslations';

// Routes
import {
  dashboard as dashboardRoute,
  healthcare_directive_address as addressRoute,
  healthcare_directive_agent as agentRoute,
  healthcare_directive_wishes as wishesRoute,
  healthcare_directive_review as reviewRoute,
  people as peopleRoute,
  checkout as checkoutRoute,
} from '../../../../core/routes';

// Redux
import productsSelector from '../../../store/selectors/productsSelector';
import { useDispatch, useSelector } from '../../../store';
import { fetchDirectives } from '../../../store/reducers/directivesReducer';
import { patchGoal, postGoal } from '../../../store/reducers/goalsReducer';

// Types
import { GoalTypes } from '../../../types/GoalInterface';

// Utils
import { getFutureDate } from '../../../../core/utils/datetimeUtils';
import { generateQueryString } from '../../../utils/urlUtils';
import { getEntitlementNameKey } from '../../../utils/entitlementsUtils';
import { DOCUMENTS } from '../../../core-data-service/models/Document';
import entitlementsSelector from '../../../store/selectors/entitlementsSelector';

export default function HealthcareDirective(): ReactElement{

  const { push } = useHistory();
  const dispatch  = useDispatch();
  const { hasMissingData } = useMissingPersonData();
  const { agents } = useAgents();
  const products = useSelector( productsSelector );
  const { getTranslation } = useTranslations();

  const { sectionStates, next } = useDirectiveSectionStatus();
  const directiveGoalId = useSelector( state => state.goals.data.find( goal => goal.type === GoalTypes['GET HEALTHCARE DIRECTIVE']))?.id;

  const hasAlreadyPurchasedDocument = useSelector( entitlementsSelector )[DOCUMENTS.healthcare_directive].isEnabled;

  useEffect(()=>{
    const onSuccess = ()=>{
      dispatch( fetchDirectives());
    };
    if( !directiveGoalId ){
      dispatch( postGoal({ type: GoalTypes['GET HEALTHCARE DIRECTIVE'], onSuccess }));
    } else {
      dispatch( patchGoal({
        id: directiveGoalId,
        type: GoalTypes['GET HEALTHCARE DIRECTIVE'],
        expected_at: getFutureDate( 7 ),
        onSuccess,
      }));
    }
  },[ dispatch, directiveGoalId ]);

  const onWishesSectionClicked = () =>{
    dispatch( fetchDirectives({ onSuccess: () => push( wishesRoute.get())  }));
  };

  const DirectiveActions = {
    address: {
      beginAction: () => push( addressRoute.get()),
      detailsAction: () => push( addressRoute.get()),
    },
    agent: {
      beginAction: () => push( agentRoute.get()),
      detailsAction: () => push( agentRoute.get()),
    },
    wishes: {
      beginAction: onWishesSectionClicked,
      detailsAction: onWishesSectionClicked,
    },
    review: {
      beginAction: () => push( reviewRoute.get()),
      detailsAction: ()=> undefined,
    },
  };

  const AgentString = (): ReactNode => {
    if ( sectionStates.agent.state === 'default' ) {
      return <span><Translation translationKey="tile.healthcaredirective.agent.subtitle.noagent"/></span>;
    } else if( sectionStates.agent.state !== 'complete' && sectionStates.agent.state !== 'review' ) {
      return <Helper className="mt-0" theme={ sectionStates.agent.state } body={ sectionStates.agent.message && <Translation { ...sectionStates.agent.message }/> } />;
    } else {
      return  agents.map(( agent, idx ) =>
        <DocumentRoleSummaryItem
          idx={ idx }
          key={ agent.id }
          person={ agent }
          roleTranslationKey="role.name.healthcareagent.displayname"
        />,
      );
    }
  };

  const WishesSummaryString = ( sectionStates: DirectiveSectionStates ): ReactElement => {
    if( sectionStates.wishes.state === 'complete' ) {
      return  <span><Translation translationKey= "tile.healthcaredirective.wishes.subtitle.summary"/></span>;
    }else {
      return <span><Translation translationKey= "tile.healthcaredirective.wishes.subtitle.unstarted"/></span>;
    }
  };

  return(
    <SegmentView
      contentClassName="pb-14 md:pb-36"
      title={ <Translation translationKey={ 'screen.healthcaredirective.status.title' } /> }
      retreatIcon="close"
      hidePrimaryAction={ true }
      onRetreat={ () => push( dashboardRoute.get()) }
    >
      <Modal
        isOpen={ hasMissingData }
      >
        <MissingPersonData title={ <Translation translationKey={ 'alert.document.missingdata.title' }/> }
          body={ <Translation translationKey={ 'alert.document.missingdata.body' }/> }
          primaryActionLabel={ <Translation translationKey={ 'alert.document.missingdata.button' }/> }
          onPrimaryAction={ ()=>push( peopleRoute.get()) }/>
      </Modal>

      <DocumentNextButton
        sectionLabelTranslationKey={ DirectiveSectionTitleKey( next ) }
        buttonTranslationKey={ next && sectionStates[next].state === 'error'
          ?'screen.healthcaredirectivestatus.chapter.button.resolve'
          :'screen.healthcaredirectivestatus.chapter.button.begin'
        }
        buttonAction={ sectionStates[next].state !== 'default'
          ? DirectiveActions[next].detailsAction
          : DirectiveActions[next].beginAction
        }
      />

      <DocumentSectionOverview
        state={ sectionStates.address.state }
        primaryActionId={ 'directive-address-section' }
        title={ <Translation translationKey={ DirectiveSectionTitleKey( DirectiveSections.address ) }/> }
        { ...DirectiveActions.address }
      >
        <span><Translation translationKey="status.tile.personaldetails.subtitle"/></span>
      </DocumentSectionOverview>

      <DocumentSectionOverview
        state={ sectionStates.agent.state }
        primaryActionId={ 'directive-agent-section' }
        title={ <Translation translationKey={ DirectiveSectionTitleKey( DirectiveSections.agent ) }/> }
        { ...DirectiveActions.agent }
      >
        {AgentString()}
      </DocumentSectionOverview>

      <DocumentSectionOverview
        state={ sectionStates.wishes.state }
        primaryActionId={ 'directive-wishes-section' }
        title={ <Translation translationKey={ DirectiveSectionTitleKey( DirectiveSections.wishes ) }/> }
        { ...DirectiveActions.wishes }
      >
        {WishesSummaryString( sectionStates )}
      </DocumentSectionOverview>

      <DocumentSectionOverview
        title={ <Translation translationKey={ DirectiveSectionTitleKey( DirectiveSections.review ) }/> }
        isGated={ sectionStates.review.isGated }
        primaryActionId={ 'directive-review-section' }
        className="!mb-0 !pb-0 !border-transparent"
        state={ sectionStates.review.state }
        { ...DirectiveActions.review }
      >
        { sectionStates.review.state === 'default' &&
          <span><Translation translationKey="status.tile.print.subtitle"/></span>
        }
      </DocumentSectionOverview>

      {!hasAlreadyPurchasedDocument &&
        <ProductUpsellHelper
          className="mt-8"
          title={ getTranslation( 'products.pro.chapter.print.not_started.card.title', [ getTranslation( getEntitlementNameKey( DOCUMENTS.healthcare_directive )) ]) }
          description={ getTranslation( 'products.pro.chapters.print.not_started.card.description' ) }
          primaryActionLabel={ getTranslation( 'products.pro.chapter.print.button', [ products.pro.price.displayValue ]) }
          primaryAction={ () => push({ pathname: checkoutRoute.get(), search: generateQueryString({ document: DOCUMENTS.healthcare_directive }) }) }
        />
      }

    </SegmentView>
  );
}
