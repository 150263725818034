import code from './maskCode';
import date from './maskDate';
import phone from './maskPhone';
import zip from './maskZip';

const inputMask = {
  date,
  phone,
  code,
  zip,
};


export default inputMask;
