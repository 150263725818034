import { ReactElement } from 'react';

// Components
import Button from './Button';
import HorizontalRule from './HorizontalRule';
import Translation from './Translation';

// Redux
import { TranslationKeys } from '../store/initial/translations';

interface DocumentInfoCardProps {
  titleKey: TranslationKeys;
  bodyTextKey: TranslationKeys;
  ctaAction?: ()=> void;
  ctaLabelKey?: TranslationKeys;
  className?: string;
  horizontalRuleClass?: string;
}

export default function DocumentInfoCard({
  titleKey,
  bodyTextKey,
  ctaAction,
  ctaLabelKey,
  className = '',
  horizontalRuleClass = '',
}: DocumentInfoCardProps ): ReactElement{
  return(
    <div className={ `mb-6 ${ className }` }>
      <HorizontalRule className={ `mb-4 ${ horizontalRuleClass }` }/>
      <h3 className="text-black font-bold">
        <Translation translationKey={ titleKey } />
      </h3>
      <p className="my-6">
        <Translation translationKey={ bodyTextKey } />
      </p>
      { ctaLabelKey &&
        <Button theme="clean" onClick={ ctaAction }>
          <Translation translationKey={ ctaLabelKey } />
        </Button>
      }
    </div>
  );
}
