import { ReactElement } from 'react';

// Components
import DocumentCheckoutView from '../Documents/DocumentCheckout';
import PricingSelectionView from './PricingSelectionView';
import Flow from '../../components/Flow';

// Hooks
import useQuery from '../../hooks/useQuery';
import useFlowQueryParams from '../../hooks/useFlowQueryParams';

// Utils
import { SEARCH_PARAM_DOCUMENT } from '../../../core/constants';
import { PRODUCT_KEYS } from '../../types/ProductsInterface';
import { DocumentEntitlements } from '../../types/Entitlements/EntitlementInterface';
import { BASIC_DOCUMENTS, DOCUMENTS } from '../../core-data-service/models/Document';

export const PURCHASE_PAGE = 'purchase';

export default function EPPCheckoutFlow(): ReactElement{
  const search = useQuery();
  const document: keyof DocumentEntitlements = search.get( SEARCH_PARAM_DOCUMENT ) as keyof DocumentEntitlements || DOCUMENTS.will;
  const initialProduct: PRODUCT_KEYS = BASIC_DOCUMENTS.includes( document ) ? PRODUCT_KEYS.basic : PRODUCT_KEYS.pro ;

  // move the user forward to the checkout view if they have purchased any product since we only have two
  const currentIndex = useFlowQueryParams([ PURCHASE_PAGE ]);

  return (
    <Flow currentIndex={ currentIndex } >
      <PricingSelectionView document={ document } selectedProduct={ initialProduct }/>
      <DocumentCheckoutView document={ document } selectedProduct={ initialProduct } />
    </Flow>
  );
}
