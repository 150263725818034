import { useSelector } from './../store';
import { entitlementsSelector } from './../store/selectors/entitlementsSelector';

const useRenderInsuranceUpsell = (): boolean => {
  const { insurance } = useSelector( entitlementsSelector );

  // unavailable/disabled means you live in an unsupported insurance state
  // or already have a policy
  return insurance.life.feature === 'enabled';
};

export default useRenderInsuranceUpsell;
