import { ReactElement, useEffect, useState } from 'react';
import { dashboard } from '../../../../core/routes';
import { getFutureDate } from '../../../../core/utils/datetimeUtils';

// Components / Views
import AddButton from '../../../components/AddButton';
import InsuranceHelper from '../../../components/InsuranceHelper';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';
import MedicalConsentChildSummaryList from './MedicalConsentChildSummaryList';
import PersonDetailView from '../../People/PersonDetailView';

// Redux
import { useDispatch, useSelector } from '../../../store';
import { patchGoal, postGoal } from '../../../store/reducers/goalsReducer';
import minorChildrenSelector from '../../../store/selectors/minorChildrenSelector';

// Types
import { RelationshipTypes } from '../../../core-data-service/models/Relationship';
import { GoalTypes } from '../../../types/GoalInterface';
import { useHistory } from 'react-router-dom';

const RELATIONSHIP_TYPE: RelationshipTypes = 'CHILDREN';

export default function MedicalConsent(): ReactElement{
  const dispatch = useDispatch();
  const { push } = useHistory();
  const consentGoalId = useSelector( state => state.goals.data.find( goal => goal.type === GoalTypes['GET MEDICAL CONSENT']))?.id;
  const minorChildren = useSelector( minorChildrenSelector );
  const [ isAddingChild, setIsAddingChild ] = useState<boolean>( false );

  useEffect(()=>{
    if( consentGoalId ){
      dispatch( patchGoal({
        id: consentGoalId,
        type: GoalTypes['GET MEDICAL CONSENT'],
        expected_at: getFutureDate( 7 ),
      }));
    } else {
      dispatch( postGoal({ type: GoalTypes['GET MEDICAL CONSENT'] }));
    }
  },[ dispatch, consentGoalId ]);

  return (
    <SegmentView
      onRetreat={ () => push( dashboard.get()) }
      title="Medical Consent"
      subtitle={ <Translation translationKey={ 'medicalconsent.screen.listofchildren.subtitle' } /> }
      hidePrimaryAction={ true }
    >
      <AddButton
        labelTranslationKey="medicalconsent.screen.listofchildren.button.addchild"
        onClick={ () => setIsAddingChild( true ) }
        iconName={ 'addPerson' }
      />
      <PersonDetailView
        isOpen={ isAddingChild }
        renderedAsRoute={ false }
        relationshipType={ RELATIONSHIP_TYPE }
        onSuccess={ () => {
          setIsAddingChild( false );
        } }
        onClose={ () => {
          setIsAddingChild( false );
        } }
      />
      <MedicalConsentChildSummaryList minorChildren={ minorChildren }/>
      <InsuranceHelper
        className="mt-8"
        body={ <Translation translationKey="upsell.messagebubble.medicalconsent.message"/> }
        primaryActionLabel={ <Translation translationKey="upsell.messagebubble.medicalconsent.button"/> }
        eventProperties={{ button: 'MedicalConsentSummary' }}
      />
    </SegmentView>
  );
}
