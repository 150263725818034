import { centsToUSDollars } from './../../utils/currencyUtils';
import { Keyable } from './../../types/Keyable';

import { RootState } from '..';
import appConfig from '../../../core/appConfig';
import { entitlementsSelector } from './entitlementsSelector';
import { ObjectKeys } from '../../types/helpers';
import { PRODUCT_KEYS } from '../../types/ProductsInterface';
import { DOCUMENTS } from '../../core-data-service/models/Document';

type ProductInfo = {
  hasPurchased: boolean;
  price: {
    value: number;
    displayValue: string;
  };
}

type ProductsSelector = Record<PRODUCT_KEYS, ProductInfo>

export const productsSelector = ( state: RootState ): ProductsSelector => {
  const entitlements = entitlementsSelector( state );

  const productsFromEntitlements = ObjectKeys( DOCUMENTS ).reduce(( acc, curr ): Keyable => {
    const upgrades = entitlements[curr].upgrades;
    if( upgrades ) {
      upgrades.forEach( upgrade => upgrade.skus.forEach( sku => {
        if( !acc[sku.sku]) {
          acc[sku.sku] = upgrade.price;
        }
      }));
    }
    return acc;
  }, {} as Keyable );

  // only return products the client knows about
  const accumulatedProducts = ObjectKeys( PRODUCT_KEYS ).reduce(( acc, curr ) => {
    if( !acc[curr]) {
      const price = productsFromEntitlements[appConfig.products[curr].sku] || 0;
      acc[curr] = {
        hasPurchased: !productsFromEntitlements[appConfig.products[curr].sku],
        price: {
          value: price,
          displayValue: centsToUSDollars( price ),
        },
      };
    }
    return acc;
  }, {} as ProductsSelector );

  /**
   * because partner codes do not remove products from upgrades array
   * manually set has purchased based on isEnabled
   */
  if( entitlements.trust.isEnabled ) {
    accumulatedProducts.pro.hasPurchased = true;
  }
  if( entitlements.will.isEnabled ) {
    accumulatedProducts.basic.hasPurchased = true;
  }

  return accumulatedProducts;
};


export default productsSelector;
