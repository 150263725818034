import { PropertyType } from './../core-data-service/models/Property';
import { TranslationKeys } from './../store/initial/translations/index';
import Property from '../core-data-service/models/Property';
import { useSelector } from '../store';
import PropertyInterface from '../types/Property/PropertyInterface';

interface AccountsCategory {
  translationKey: TranslationKeys;
  value: PropertyType;
}

interface UseAccounts {
  accounts: PropertyInterface[];
  accountsCategories: AccountsCategory[];
}

export default function useAccounts(): UseAccounts{

  const accounts = useSelector( state => state.properties.data
    .filter( property => Property.ACCOUNT_TYPES.includes( property.type )));

  const accountsCategories = Property.ACCOUNT_TYPES
    .map( type => ({ translationKey: Property.DISPLAY_KEY[type], value: type }));

  return {
    accounts,
    accountsCategories,
  };
}
