import { FC } from 'react';
import { SubmitHandler, UseFormMethods } from 'react-hook-form';
import { SanitizedFormData } from '../../core/types/SanitizedFormData';
import { trimWhitespace } from '../../core/utils/stringHelpers';

interface DefaultValues {
  [key: string]: string | DefaultValues[];
}

interface FormProps {
  className?: string;
  defaultValues?: DefaultValues;
  handleSubmit: UseFormMethods['handleSubmit'];
  onSubmit: SubmitHandler<SanitizedFormData>;
}

export interface FormData {
  [key: string]: string | string[] | FormData;
}


/**
 * change empty strings to null
 * trim extra whitespace
 * @param value
 */
const sanitizeFieldValue = ( value: string ): string|null =>
  Boolean( value === '' )
    ? null
    : trimWhitespace( value );


/**
 * combine array of field values into single string
 */
export const sanitizeFieldArray = ( value: string[]): string =>
  value.reduce(( prev, curr ) =>
    prev + trimWhitespace( curr ), '' );

/**
 * Convert FormData type to SanitizedFormData.
 * Handles nested FormData (potentially something like user.person)
 * and array of string values (like in current CodeField input)
 * @TODO UNIT TESTS
 */
const sanitizeFormData = ( data: FormData ): SanitizedFormData => {
  return Object.keys( data )
    .reduce(( result: SanitizedFormData, key: string ) => {
      const value = data[key];
      let sanitizedValue;

      // Sanitize regular string field value
      if( typeof value === 'string' ){
        sanitizedValue = sanitizeFieldValue( value );

      // Handle nested array of values
      } else if( value instanceof Array ){
        sanitizedValue = sanitizeFieldArray( value );

      // Handle nested object in FormData recursively
      } else if( !!value || ( typeof value === 'object' && value !== null )){
        sanitizedValue = sanitizeFormData( value );
      }

      return Object({
        ...result,
        [key]: sanitizedValue,
      });
    }, {} as SanitizedFormData );
};

// TODO: on change check if data has changed.
// if valid then allow submission

const Form: FC<FormProps> = (
  {
    children,
    className,
    handleSubmit,
    onSubmit,
  }) => {

  return (
    <form
      className={ className }
      onSubmit={ e => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(( data: FormData ) => onSubmit( sanitizeFormData( data )))();
      } }
    >
      { children }
    </form>
  );
};

export default Form;
