import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

// Utils
import WebClientRequest, { WebClientRequestHeaders } from '../../core-data-service/WebClientRequest';
import PropertyInterface, { AxiosPropertiesResponse, AxiosPropertyResponse } from '../../types/Property/PropertyInterface';
import compressImage, { ImageCompressionOptions } from '../../utils/compressImage';

// Data
import initialState from '../initial/properties';

// Redux
import { saveAttribute } from './userReducer';

interface IFetchProperties {
  onSuccess?: ( response: AxiosPropertiesResponse )=> void;
}

interface IPatchProperty {
  id: string;
  onSuccess?: ( response: AxiosPropertyResponse )=> void;
  onError?: ( response: AxiosResponse )=> void;
}

interface IPostProperty {
  onSuccess?: ( response: AxiosPropertyResponse )=> void;
}

interface IDeleteProperty {
  id: string;
  onSuccess?: ( response: AxiosResponse )=> void;
}

interface IUploadPropertyPhoto {
  id: string;
  onSuccess?: ( response: AxiosResponse )=> void;
  photo: File;
  compressionOptions: ImageCompressionOptions;
}

export const fetchProperties = createAsyncThunk(
  'properties/fetchProperties',
  async( arg: IFetchProperties | void, thunkAPI ) => {
    const url = '/v2/properties';
    return WebClientRequest
      .get( url )
      .then(( response: AxiosPropertiesResponse ) => {
        const properties = response.data.data;
        thunkAPI.dispatch( updateProperties( properties ));
        arg && arg.onSuccess && arg.onSuccess( response );
      });
  },
);


export const patchProperty = createAsyncThunk(
  'properties/patchProperty',
  async({ id, onSuccess, onError, ...data }: IPatchProperty, thunkAPI ) => {

    const url = `/v2/properties/${id}`;
    return WebClientRequest
      .patch( url, data )
      .then(( response: AxiosPropertyResponse ) => {
        thunkAPI.dispatch( saveAttribute({ 'financial.networth': response.data.meta.networth }));
        thunkAPI.dispatch( updateProperty(  response.data.data ));
        onSuccess && onSuccess( response );
      })
      .catch(( response: AxiosResponse ) => {
        onError && onError( response );
      });
  },
);

export const postProperty = createAsyncThunk(
  'properties/postProperty',
  async({ onSuccess, ...data }: IPostProperty, thunkAPI ) => {

    const url = '/v2/properties';
    return WebClientRequest
      .post( url, data )
      .then(( response: AxiosPropertyResponse ) => {
        thunkAPI.dispatch( saveAttribute({ 'financial.networth': response.data.meta.networth }));
        thunkAPI.dispatch( updateProperty( response.data.data ));
        onSuccess && onSuccess( response );
      });
  },
);

export const deleteProperty = createAsyncThunk(
  'properties/deleteProperty',
  async({ onSuccess, id }: IDeleteProperty, thunkAPI ) => {
    const url = `v2/properties/${id}`;
    return WebClientRequest
      .delete( url )
      // This returns a "net worth transformer"
      .then(( response: AxiosResponse ) => {
        thunkAPI.dispatch( saveAttribute({ 'financial.networth': response.data.meta.networth }));
        thunkAPI.dispatch( removeProperty({ id }));
        onSuccess && onSuccess( response );
      });
  },
);

export const uploadPropertyPhoto = createAsyncThunk(
  'properties/uploadPropertyPhoto',
  async({ id, onSuccess, photo, compressionOptions }: IUploadPropertyPhoto, thunkAPI ) => {

    const url = `/v2/properties/${id}/photo`;
    const headers: WebClientRequestHeaders = { 'Content-Type': 'multipart/form-data' };

    return compressImage( photo, compressionOptions )
      .then( compressedPhoto => {

        // Build form data
        const data = new FormData();
        data.append( 'photo', compressedPhoto );

        return WebClientRequest
          .post( url, data, headers )
          .then( response => {
            thunkAPI.dispatch( updateProperty(  response.data.data ));
            onSuccess && onSuccess( response.data.data );
          });
      });
  },
);


const propertiesSlice = createSlice({
  name: 'properties',
  initialState,

  reducers: {

    updateProperties( properties, action: PayloadAction<PropertyInterface[]> ){
      if( properties.data.length === 0 ){
        properties.data = action.payload;
      } else {
        action.payload.forEach( property => {
          const idx = properties.data
            .findIndex( xProperty => xProperty.id === property.id );
          if( idx >= 0 ){
            properties.data[idx] = property;
          } else {
            properties.data.push( property );
          }
        });
      }
    },

    updateProperty( properties, action: PayloadAction<PropertyInterface> ){
      const payload = action.payload;
      const idx = properties.data.findIndex( property => property.id === payload.id );
      if( idx >= 0 ){
        properties.data[idx] = payload;
      } else {
        properties.data.push( payload );
      }
    },

    removeProperty( properties, action: PayloadAction<{id: string}> ){
      const { id } = action.payload;
      properties.data = properties.data.filter( property => property.id !== id );
    },
  },

  extraReducers: builder => {

    // fetchProperties.<status>
    builder.addCase( fetchProperties.pending, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.fetchProperties.meta = meta;
    });
    builder.addCase( fetchProperties.fulfilled, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.fetchProperties.meta = meta;
    });
    builder.addCase( fetchProperties.rejected, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.fetchProperties.meta = meta;
    });

    // patchProperty.<status>
    builder.addCase( patchProperty.pending, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.patchProperty.meta = meta;
    });
    builder.addCase( patchProperty.fulfilled, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.patchProperty.meta = meta;
    });
    builder.addCase( patchProperty.rejected, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.patchProperty.meta = meta;
    });

    // postProperty.<status>
    builder.addCase( postProperty.pending, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.postProperty.meta = meta;
    });
    builder.addCase( postProperty.fulfilled, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.postProperty.meta = meta;
    });
    builder.addCase( postProperty.rejected, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.postProperty.meta = meta;
    });

    // uploadPropertyPhoto.<status>
    builder.addCase( uploadPropertyPhoto.pending, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.uploadPropertyPhoto.meta = meta;
    });
    builder.addCase( uploadPropertyPhoto.fulfilled, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.uploadPropertyPhoto.meta = meta;
    });
    builder.addCase( uploadPropertyPhoto.rejected, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.uploadPropertyPhoto.meta = meta;
    });

    // deleteProperty.<status>
    builder.addCase( deleteProperty.pending, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.deleteProperty.meta = meta;
    });
    builder.addCase( deleteProperty.fulfilled, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.deleteProperty.meta = meta;
    });
    builder.addCase( deleteProperty.rejected, ( properties, action ) => {
      const { arg, ...meta } = action.meta;
      properties.deleteProperty.meta = meta;
    });

  },
});


export const { updateProperties, updateProperty, removeProperty } = propertiesSlice.actions;

export default propertiesSlice.reducer;
