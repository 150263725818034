import { useSelector } from '../store';
import productsSelector from '../store/selectors/productsSelector';

const useHasPurchasedAndCompletedTrust = (): boolean => {
  const userAttributes = useSelector( state => state.user.data.attributes );
  const products = useSelector( productsSelector );
  return !!userAttributes['app.trustPreviewViewed'] && products.pro.hasPurchased;
};

export default useHasPurchasedAndCompletedTrust;
