import { ReactElement, useEffect } from 'react';

// Components
import AccountsListView from './AccountsListView';

// Hooks
import useUserAttributes from '../../../hooks/useUserAttributes';

// Redux
import { useDispatch } from '../../../store';
import { showVideoModal } from '../../../store/reducers/modalReducer';


export default function Valuables(): ReactElement{

  const dispatch = useDispatch();
  const { documents: { trust: { hasViewedTrustAccountsVideo } } } = useUserAttributes();

  useEffect(() => {
    if( !hasViewedTrustAccountsVideo ) {
      dispatch( showVideoModal({
        src: {
          portrait: 'video.trust.funding',
          landscape: 'video.trust.funding.landscape',
        },
        hasViewedKey: 'app.viewedTrustAccountsVideo',
        onSkip: () => undefined,
        onEnded: () => undefined }));
    }
  }, [ dispatch, hasViewedTrustAccountsVideo ]);

  return (
    <AccountsListView />
  );
}
