import { ReactElement } from 'react';

// Assets
import ethosEstatePlanning from '../../assets/images/logos/ethosEstatePlanning.svg';
import illustration from '../../assets/images/illustrations/person_designing_estate_plan.svg';

// Components
import Button from '../../components/Button';
import InternalDescriptiveLink from '../../components/InternalDescriptiveLink';
import Icon from '../../components/Icon';
import { Link } from 'react-router-dom';
import Translation from '../../components/Translation';

interface SpouseHomeProps {
  primaryAction: ()=> void;
}

export default function SpouseHome({
  primaryAction,
}: SpouseHomeProps ): ReactElement{
  return (
    <div className="p-8 md:p-12 flex justify-center h-full text-center bg-white">
      <div className="flex flex-col h-full max-w-sm md:max-w-md items-center">
        <img
          src={ ethosEstatePlanning }
          alt="Ethos Estate Planning Logo"
          width="101"
          height="47"
          className="w-18 mb-8 md:w-min md:mb-12"
        />
        <img
          src={ illustration }
          alt="Person designing an estate plan"
          width="234"
          height="171"
          className="w-44 mb-8 md:w-min md:mb-12"
        />
        <h1 className="mb-6 text-3xl text-black font-headline md:text-4xl ">
          <Translation translationKey="spouse.home.title" />
        </h1>
        <p className="text-sm md:mb-4 md:text-md">
          <Translation translationKey="spouse.home.subtitle" />
        </p>
        <Button
          theme="primary"
          className="my-6 w-full max-w-max md:self-center"
          id={ 'getStarted-landingPage' }
          onClick={ primaryAction }
        >
          <Translation translationKey="spouse.home.button" />
        </Button>
      </div>
    </div>
  );
}
