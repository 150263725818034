import { createSlice, PayloadAction } from '@reduxjs/toolkit';



const isLoadingReducer = createSlice({
  name: 'isLoading',
  initialState: true,
  reducers: {

    setIsLoading( isLoading, action: PayloadAction<boolean> ){
      return action.payload;
    },

  },
});

export const { setIsLoading } = isLoadingReducer.actions;

export default isLoadingReducer.reducer;
