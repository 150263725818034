import { Notifier } from '@airbrake/browser';
import appConfig from '../../core/appConfig';
import { ENV_DEVELOPMENT, ENV_PRODUCTION } from '../../core/constants';
import log from '../utils/log';


const airbrake = new Notifier({
  projectId: appConfig.airbrake.projectId,
  projectKey: appConfig.airbrake.projectKey,
  environment: appConfig.app.deploymentEnv || appConfig.app.env,
});

/**
 * Don't report if NODE_ENV is "development"
 * https://github.com/airbrake/airbrake-js/tree/master/packages/browser#filtering-errors
 */
airbrake.addFilter( notice => {
  if( appConfig.app.env === ENV_DEVELOPMENT ){
    log.info( 'airbrake filter does not report in dev env' );
    const message = notice?.error?.message;
    if( !!message ){
      log.error( message );
    }
    return null;
  }
  return notice;
});


export default airbrake;
