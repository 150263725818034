import DocumentInterface from '../../types/DocumentInterface';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  data: [] as Array<DocumentInterface>,
  // for extra reducers
  postDocument: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchDocuments: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
