import React, { ReactElement } from 'react';

import ActionContainer from '../../components/ActionContainer';
import AddButton from '../../components/AddButton';
import { Icons } from '../../components/Icon';
import { TranslationKeys } from '../../store/initial/translations';

interface Props {
  className?: string;
  title: React.ReactNode;
  subhead: React.ReactNode;
  primaryAction?: ()=> void;
  primaryActionLabel?: TranslationKeys;
  children: React.ReactNode;
  hasSpouse?: boolean;
  iconName?: Icons;
}

export default function HeirsScenarioActionContainer({ className = '', title, subhead, primaryAction, primaryActionLabel, children, hasSpouse, iconName = 'addPerson' }: Props ): ReactElement{
  return (
    <div className={ `${className}` }>
      <ActionContainer
        header={ <span>{subhead}</span> }
      >
        {title}
        {
          !hasSpouse &&
          <AddButton
            buttonId="add-spouse-button"
            onClick={ primaryAction }
            iconName={ iconName }
            labelTranslationKey={ primaryActionLabel }
            className={ 'mt-4' }
          />
        }
        {children}
      </ActionContainer>
    </div>
  );
}
