import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import DocumentInfoCard from '../../../components/DocumentInfoCard';
import OpenVideoButton from '../../../components/OpenVideoButton';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';

// Hooks
import useUserAttributes from '../../../hooks/useUserAttributes';

// Redux
import { showModal, showVideoModal } from '../../../store/reducers/modalReducer';
import { TranslationKeys } from '../../../store/initial/translations';
import { useDispatch } from '../../../store';
import { GlobalModals } from '../../../store/initial/modal';

// Routes
import { trust_review as trustReviewRoute } from '../../../../core/routes';

interface DocumentInfo {
  title: TranslationKeys;
  bodyText: TranslationKeys;
  ctaLink?: string;
  ctaLabel?: TranslationKeys;
}

const documents: DocumentInfo[] = [
  {
    title: 'products.trust.funding.checking.title',
    bodyText: 'products.trust.funding.checking.description',
    ctaLink: 'https://tomorrow.me/living-trust/the-definitive-guide-to-funding-a-trust/' ,
    ctaLabel: 'products.trust.funding.checking.action',
  },
  {
    title: 'products.trust.funding.brokerage.title',
    bodyText: 'products.trust.funding.brokerage.description',
    ctaLink: 'https://tomorrow.me/living-trust/the-definitive-guide-to-funding-a-trust/' ,
    ctaLabel: 'products.trust.funding.brokerage.action',
  },
  {
    title: 'products.trust.funding.retirement.title',
    bodyText: 'products.trust.funding.retirement.description',
  },
  {
    title: 'products.trust.funding.college.savings.title',
    bodyText: 'products.trust.funding.college.savings.description',
  },
];

function FundingDocuments(): ReactElement{
  return (
    <>
      {
        documents.map(( doc, idx ) => {
          const { title, bodyText, ctaLink, ctaLabel } = doc;
          const isFirst = idx === 0;
          const ctaAction = ctaLink ? () => window.open( ctaLink, '_blank' ) : undefined;
          return (
            <DocumentInfoCard
              key={ idx }
              titleKey={ title }
              bodyTextKey={ bodyText }
              ctaAction={ ctaAction }
              ctaLabelKey={ ctaLabel }
              horizontalRuleClass={ isFirst ? 'md:hidden' : '' }
            />
          );
        })
      }
    </>
  );
}

export default function TrustAddFunds(): ReactElement{
  const { push } = useHistory();
  const dispatch = useDispatch();

  const { documents: { trust: { hasViewedTrustAccountsVideo } } } = useUserAttributes();

  useEffect(() => {
    if( !hasViewedTrustAccountsVideo ) {
      dispatch( showVideoModal({
        src: { portrait: 'video.trust.funding',
          landscape: 'video.trust.funding.landscape',
        },
        hasViewedKey: 'app.viewedTrustAccountsVideo',
        onSkip: () => undefined,
        onEnded: () => undefined }));
    }
  }, [ dispatch, hasViewedTrustAccountsVideo ]);

  return (
    <SegmentView
      title={ <Translation translationKey="trust.funding.title"/> }
      subtitle={
        <OpenVideoButton
          labelKey="trust.funding.video_button"
          videoSrc={{ portrait: 'video.trust.funding', landscape: 'video.trust.funding.landscape' }}
        />
      }
      onRetreat={ ()=> push( trustReviewRoute.get()) }
      hidePrimaryAction
      onSecondaryAction={ () => dispatch( showModal( GlobalModals.help ))  }
      secondaryActionChildren={ <Translation translationKey="(button)help"/> }
      secondaryButtonTheme="clean"
    >
      <FundingDocuments />
    </SegmentView>
  );
}
