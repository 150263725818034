import ExperimentInterface from '../../types/ExperimentInterface';
import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';


const initialState = {
  data: [] as Array<ExperimentInterface>,
  meta: {
    impressionsReported: [] as string[],
  },
  // for extra reducers
  fetchExperiments: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postExperimentImpression: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
