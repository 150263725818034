import { ReactElement } from 'react';

// Redux
import { useDispatch } from '../store';
import { showContextualModal } from '../store/reducers/modalReducer';

// Components
import Icon from './Icon';

interface InformationalHelperButtonProps {
    title: string;
    modalDescription?: string;
    modalPrimaryActionLabel?: string;
    className?: string;
}

export default function InformationalHelperButton({ title, modalDescription, modalPrimaryActionLabel = 'Okay', className='' }: InformationalHelperButtonProps ): ReactElement{
  const dispatch = useDispatch();
  return (
    <button
      className={ `
        flex
        items-center
        justify-between
        w-full
        rounded-md
        bg-salamander--light
        text-salamander
        text-left
        focus-visible:ring-2
        focus:outline-none
        focus-visible:ring-salamander
        ${ className }
        ` }
      onClick={ () =>
        dispatch( showContextualModal({
          description: modalDescription,
          confirmLabel: modalPrimaryActionLabel,
          contentClassName: 'bg-moss',
        }))
      }
      disabled={ !modalDescription }>
      <p className="ml-4 mr-8 my-4 max-w-prose text-sm font-bold">
        { title }
      </p>
      <Icon name="info" className= { 'mr-4 flex-shrink-0' }>
      </Icon>
    </button>
  );
}
