import { ReactElement } from 'react';

// Components
import Button from '../../components/Button';
import Translation from '../../components/Translation';

// Type
import { TranslationKeys } from '../../store/initial/translations';

export interface DocumentNextButtonProps {
  sectionLabelTranslationKey: TranslationKeys;
  buttonTranslationKey: TranslationKeys;
  buttonAction: ()=> void;
}

export default function DocumentNextButton({ buttonTranslationKey, buttonAction, sectionLabelTranslationKey }: DocumentNextButtonProps ): ReactElement{

  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-center z-20 pointer-events-none">
      <div className="w-full sm-only:mx-4 md:w-lg md:px-12">
        <div className="rounded-t bg-forest shadow-button">
          <button
            tabIndex={ 0 }
            onClick={ buttonAction }
            className="px-8 py-6 md:px-12 md:py-8 w-full flex justify-between pointer-events-auto">
            <div className="text-white text-left">
              <span className="block opacity-60 mb-1">
                <Translation translationKey={ 'screen.willstatus.helper.upnext' }/>
              </span>
              <span className="capitalize">
                <Translation translationKey={ sectionLabelTranslationKey } />
              </span>
            </div>
            <Button
              className="shadow-button"
              element="div"
              small
              theme="light"
            >
              <Translation translationKey={ buttonTranslationKey } />
            </Button>
          </button>
        </div>
      </div>
    </div>
  );
}
