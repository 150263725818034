import { AnalyticsInstance } from 'analytics';
import { validate } from 'uuid';

export const EXTERNAL_ID_PARAM = 'external_id';

declare global {
  interface Window {
      analytics: AnalyticsInstance;
  }
}

const isAnalyticUserExist = () => {
  return window && window.analytics && typeof window.analytics.user === 'function';
};

export const getAnonymousID = (): string => {
  if( isAnalyticUserExist()) {
    return window.analytics.user().anonymousId();
  }
  return '';
};

export const getAndSetAnonymousIDFromParam = (): void => {
  const query = new URLSearchParams( window.location.search );
  const anonymousId=query.get( EXTERNAL_ID_PARAM );
  if ( anonymousId && validate( anonymousId )) {
    isAnalyticUserExist() && window.analytics.user().anonymousId( anonymousId );
  }
};
