import { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { people as peopleRoute, person as personRoute } from '../../core/routes';
import LocationState from '../types/LocationState';
interface Props {
  className?: string;
  onClick?: ()=> void;
}

export function AddPersonButton({ className, onClick }: Props ): ReactElement{

  const { push } = useHistory();
  const location = useLocation<LocationState>();

  const createPerson = () => push({
    pathname: personRoute.get({ id: 'create' }),
    state: { background: location || { pathname: peopleRoute.get() } },
  });

  return (
    <button
      className={ `inline-block ${ className } mb-2 align-top focus:outline-none` }
      onClick={ onClick || createPerson }
    >
      <div className="w-14 h-14 mb-2">
        <div className="rounded-full flex justify-center items-center h-14 w-14 bg-grey text-lg">+</div>
      </div>
      <span className="text-black-70 block text-center">Add</span>
    </button>
  );
}

export default AddPersonButton;
