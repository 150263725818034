import { ReactElement } from 'react';
import SegmentView from '../../components/SegmentView';
import useBackstack from '../../hooks/useBackstack';

export const LEGAL_DISCLAIMERS: string[] = [
  'Tomorrow is an online provider of legal documents that are constructed programmatically to ensure they adhere to the laws in your state. Tomorrow is an improvement to the typical “fill in the blank” forms because Tomorrow knows what sections to include based on your unique family situation. Tomorrow is not a law firm and does not provide legal advice nor represents you in any way.',
  'By using this app or our legal forms, you are not accessing attorney services or legal advice.  Tomorrow\'s services, including our app, our legal forms, and any legal information we provide, are not a substitute for the advice of an attorney.',
  'By using this app or our legal forms, you are not establishing an attorney-client relationship with Tomorrow  or any of its employees, or with any attorney.  Any information you provide us is not protected by attorney-client privilege or as work product.',
  'In accessing this app, the user assumes responsibility to provide correct and up-to-date information.  Inaccuracies in information provided could affect the validity or enforceability of the documents generated by the app. You will read any and all Tomorrow documents that you download and print prior to signing them.  You take sole responsibility for the accuracy of your personal information contained in Tomorrow documents that you sign.',
  'Each person’s legal situation is different and complex situations may require legal advice and representation. It is not possible for the app to identify all situations in which legal representation is recommended, necessary or desirable.  We do not review your documents or any information you input for accuracy or legal sufficiency, draw legal conclusions, provide legal advice or apply the law to the facts of your particular situation.',
  'Each user has the obligation to make his or her own determination as to whether to seek the advice of a lawyer.  Tomorrow recommends that all users consider reviewing their estate plan and estate planning documents with an attorney.',
  'Different states have different rules that may affect the enforceability or validity of planning documents, and each state’s laws may change over time. Tomorrow cannot ensure that the documents generated through use of the app will be enforceable or valid in all states or in every instance. Each user assumes the responsibility to seek legal review of their documents and estate plan.',
  'Guardianship appointments are determined by courts in most states. While a parent\'s choice of guardian is given deference, there is no guarantee that a parent’s choice of guardian will be upheld by a court. Seek legal advice to determine how best to protect your family.',
  'Executor appointments are determined by courts in most states. While your choice of Executor is given deference, there is no guarantee that your choice of Executor will be upheld by a court. Seek legal advice to determine how best to choose an appropriate Executor.',
  'Users should update their documents as their life circumstances change. It is up to users to determine when they need to update their documents. Failure to do so may affect the validity or enforceability of planning documents.',
  'Certain estate planning documents will not be valid or enforceable unless you follow detailed and specific steps to properly execute the documents.  Failure to precisely follow directions may result in invalid or unenforceable planning documents.  If you have any questions you should seek the advice of an attorney.',
  'Will and trust provisions can have financial and tax consequences. The financial and tax provisions will not be ideal for every situation and we do not guarantee that our provisions are appropriate for you. Seek legal advice to determine what specific tax or financial planning is best for you.',
  'Executors, guardians, trust creators and trustees should consult with an attorney to determine their legal rights and  obligations under estate planning documents and local law. In addition, the implementation of, or failure to follow, will and trust provisions can have financial and tax consequences for estates, trusts, executors, trustees, guardians, heirs and beneficiaries. Executors, guardians, trust creators and trustees should consult with an attorney for guidance.',
];

export default function LegalDisclaimer({
  isFromIOS = false,
}: {
  isFromIOS?: boolean;
}): ReactElement{

  const { goBack } = useBackstack();

  return (
    <main className="flex fixed inset-0 bg-white">
      <SegmentView
        onRetreat={ goBack }
        title="Legal Disclaimer"
        hidePrimaryAction
        shouldHideBackButton={ isFromIOS }
      >
        <ol className="list-decimal">
          {
            LEGAL_DISCLAIMERS
              .map( disclaimer =>
                <li key={ disclaimer } className="mb-4">
                  { disclaimer }
                </li>,
              )
          }
        </ol>
      </SegmentView>
    </main>
  );
}
