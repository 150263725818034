import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from '../../../components/Button';
import HorizontalRule from '../../../components/HorizontalRule';
import OpenVideoButton from '../../../components/OpenVideoButton';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';

// Hooks
import useOpenDocumentPDF from '../../../hooks/useDocumentPDF';
import useTranslations from '../../../hooks/useTranslations';

// Models
import Document from '../../../core-data-service/models/Document';

// Redux
import { useDispatch } from '../../../store';
import { setUserAttributes } from '../../../store/reducers/userReducer';
import { showContextualModal, showModal } from '../../../store/reducers/modalReducer';
import { GlobalModals } from '../../../store/initial/modal';

// Routes
import { trust_review as trustReviewRoute, will_review as willReviewRoute } from '../../../../core/routes';
import { SESSION_STORAGE_POUR_OVER_WILL } from '../../../../core/constants';

export default function PourOverWill(): ReactElement{

  const { push } = useHistory();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();

  const { openDocument, isFetchingDocument } =  useOpenDocumentPDF( Document.TYPE_WILL );

  function handlePrimaryAction(){
    dispatch( showContextualModal(
      { title: getTranslation( 'products.trust.manager.pouroverwill.alert.sign_confirm.title' ),
        description: getTranslation( 'products.trust.manager.pouroverwill.alert.sign_confirm.description' ),
        confirmLabel: getTranslation( 'toggle.button.yes' ),
        dismissLabel: getTranslation( 'toggle.button.no' ),
        onConfirm: () => {
          dispatch( setUserAttributes(
            {
              'app.trustHasSignedPourOverWill': true,
              'app.trustSignedPourOverWillDate': new Intl.DateTimeFormat( 'en-US', { dateStyle: 'long' }).format( new Date()),
            }));
          push( trustReviewRoute.get());
        },
        onDismiss: () => {
          dispatch( setUserAttributes(
            {
              'app.trustHasSignedPourOverWill': false,
              'app.trustSignedPourOverWillDate': '',
            }));
          push( trustReviewRoute.get());
        },
      }));
  }

  return (
    <SegmentView
      title={ <Translation translationKey="products.trust.manager.pouroverwill.title"/> }
      subtitle={
        <OpenVideoButton
          labelKey="products.trust.manager.pouroverwill.video_button"
          videoSrc={{ portrait: 'video.trust.pourover', landscape: 'video.trust.pourover.landscape' }}
        />
      }
      onRetreat={ ()=> push( trustReviewRoute.get()) }
      onPrimaryAction={ handlePrimaryAction }
      onSecondaryAction={ () => dispatch( showModal( GlobalModals.help ))  }
      primaryActionLabel={ <Translation translationKey="(button)done" /> }
      secondaryActionChildren={ <Translation translationKey="(button)help"/> }
      secondaryButtonTheme="clean"
    >
      <HorizontalRule className="mb-8 md:hidden"/>
      <p className="mb-6">
        <Translation translationKey="products.trust.manager.pouroverwill.description" />
      </p>
      <h3 className="text-black font-bold mb-6">
        <Translation translationKey="products.trust.manager.pouroverwill.section.print_prompt.title" />
      </h3>
      <div className="flex" >
        <Button
          theme="tertiary"
          small={ true }
          onClick={ openDocument }
          isPending={ isFetchingDocument }
        >
          <Translation translationKey="products.trust.manager.pouroverwill.section.print_prompt.buttons.open" />
        </Button>
        <Button
          theme="clean"
          small={ true }
          className="ml-4"
          onClick={ () => {
            sessionStorage.setItem( SESSION_STORAGE_POUR_OVER_WILL, new Date().toISOString());
            push({
              pathname: willReviewRoute.get(),
              search: '?page=completed',
            });
          }
          }
        >
          <Translation translationKey="products.trust.manager.pouroverwill.section.print_prompt.buttons.edit" />
        </Button>
      </div>
    </SegmentView>
  );
}
