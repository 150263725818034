import { ReactElement } from 'react';

// components
import CommaSeparatedStrings from '../../../components/CommaSeparatedStrings';
import OverlappingAvatars from '../../../components/OverlappingAvatars';
import Person from '../../../core-data-service/models/Person';

// Redux
import { useSelector } from '../../../store';

// Types
import RoleInterface from '../../../types/RoleInterface';

interface Props {
  className?: string;
  roles: RoleInterface[];
}

export default function WillGuardiansCatchAllSummary({ className = '', roles }: Props ): ReactElement{
  const people = useSelector( state => state.people.data );

  const uniqueRoleIds = new Set( roles.map( role => role.grantee_id ));
  const filteredRoles = [ ...uniqueRoleIds ];

  return (
    <div className={ className }>
      <OverlappingAvatars
        items={
          filteredRoles.map( id => {
            const guardian = people.find( person => person.id === id );
            return { src: guardian?.photo_url, name: guardian?.name };
          })
        }
      />
      <div className="text-black-70 mt-4">
        <CommaSeparatedStrings
          items={
            filteredRoles.map( id => {
              const guardian = people.find( person => person.id === id );
              if( !guardian ) {
                return '';
              }
              const person = new Person( guardian );
              return person.firstName;
            })
          }
        />
        {' '}have been designated as legal and backup guardians.
      </div>
    </div>
  );
}
