import { ReactElement, ReactNode } from 'react';

import Button, { ButtonTheme } from '../components/Button';

export interface HelperProps {
  // @TODO cleanup themes here
  theme?: 'error' | 'warning' | 'default' | 'review' | 'aside'  | 'complete' | 'unmanaged';
  className?: string;
  title?: string;
  body: ReactNode;
  primaryAction?: ()=>  void;
  primaryActionLabel?: ReactNode;
}

export default function Helper({ title, className = '', theme = 'default', body, primaryAction, primaryActionLabel = 'Continue' }: HelperProps ): ReactElement{
  return (
    <div className={ `${getThemeColor( theme ).bg} rounded-lg rounded-tl mt-4 p-6 relative ${className}` }>

      {/* triangle */}
      <svg
        className={ `${getThemeColor( theme ).text} absolute top-0 left-0 transform translate-y-[-11px]` }
        width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 23.276C0 17.557 1.69 12.488 5.072 8.07 7.585 4.786 10.98 2.158 15.252.185a2 2 0 012.703 2.542l-.12.312c-1.776 4.723.282 7.194 6.174 7.414L0 23.276z" fill="currentColor" fillRule="evenodd"/>
      </svg>

      {title && <h3 className="font-bold text-black mb-2">{title}</h3>}
      <div>{ body }</div>
      {primaryAction &&
        <Button
          theme={ getButtonTheme( theme ) }
          small
          onClick={ primaryAction }
          className="mt-4"
        >{primaryActionLabel}</Button>
      }
    </div>
  );
}

const getThemeColor = ( theme: HelperProps['theme']): {bg: string; text: string} => {
  switch ( theme ) {
  case 'error':
    return { bg: 'bg-buttercup', text: 'text-buttercup' };
  case 'review':
    return { bg: 'bg-yellow-300', text: 'text-yellow-300' };
  case 'warning':
    return { bg: 'bg-yellow-300', text: 'text-yellow-300' };
  case 'aside':
    return { bg: 'bg-green-400', text: 'text-green-400' };
  default:
    return { bg: 'bg-moss', text: 'text-moss' };
  }
};

const getButtonTheme = ( theme: HelperProps['theme']): ButtonTheme => {
  switch ( theme ) {
  case 'error':
    return 'clean-danger';
  case 'aside':
    return 'secondary';
  case 'unmanaged':
    return 'clean';
  default:
    return 'primary';
  }
};
