import { ReactElement } from 'react';
import { useHistory } from 'react-router';

// Components
import SegmentView from '../../components/SegmentView';
import InvitesList from '../Invites/InvitesList';

// Hooks
import useTranslations from '../../hooks/useTranslations';
import useBackstack from '../../hooks/useBackstack';

// Routes
import { will_guardians as guardiansRoute, will as willRoute } from '../../../core/routes';

// Types
import Role from '../../core-data-service/models/Role';

export default function GuardiansInvites(): ReactElement{
  const { getTranslation } = useTranslations();
  const { goBack } = useBackstack( guardiansRoute.get());
  const { push } = useHistory();

  return (
    <SegmentView
      onRetreat={ goBack }
      onPrimaryAction={ () => push( willRoute.get()) }
      primaryActionLabel={ getTranslation( '(button)done' ) }
      title={ `Invite your ${Role.TYPE.GUARDIAN.toLowerCase()}s.` }
    >
      <InvitesList roleType={ Role.TYPE.GUARDIAN }/>
    </SegmentView>
  );
}
