import React, { FC } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MODAL_TRANSITION_DURATION_MS } from '../../core/constants';

interface FlowProps {
  currentIndex: number;
}

const Flow: FC<FlowProps> = ({ children, currentIndex }) => {
  return (
    <AnimatePresence exitBeforeEnter initial={ false }>
      {React.Children.map( children, ( child, i ) => {
        if( currentIndex !== i ) {
          return null;
        }
        return (
          <motion.div
            className="flex-1 flex flex-col"
            transition={{ duration: MODAL_TRANSITION_DURATION_MS / 1000 }}
            initial={{ opacity: 0, translateY: '25px' }}
            animate={{ opacity: 1, translateY: '0px' }}
            exit={{ opacity: 0, translateY: '-25px' }}
          >
            {child}
          </motion.div>
        );
      })
      }
    </AnimatePresence>
  );
};

export default Flow;
