import React, { ReactElement, useState } from 'react';

// Components
import Person from '../../../core-data-service/models/Person';
import PersonIcon from '../../People/PersonIcon';
import QuickAddCircleButton from '../../../components/QuickAddCircleButton';

// Hooks
import useTranslations from '../../../hooks/useTranslations';

// Redux
import { useDispatch } from '../../../store';
import { postRole } from '../../../store/reducers/rolesReducer';

// Types
import PersonInterface from '../../../types/PersonInterface';

// Models
import ActionContainer from '../../../components/ActionContainer';
import { medical_consent_document } from '../../../../core/routes';
import { useHistory } from 'react-router-dom';
import Role from '../../../core-data-service/models/Role';
import PeopleSelector from '../../../components/PeopleSelector';
import useCaretakers from '../../../hooks/useCaretakers';
import { toTitleCase } from '../../../../core/utils/stringHelpers';
import OverlappingAvatars from '../../../components/OverlappingAvatars';
import Translation from '../../../components/Translation';

interface MedicalConsentChildListItemProps {
  className?: string;
  ward: PersonInterface;
}

const MedicalConsentChildListItem = ({
  className = '',
  ward,
}: MedicalConsentChildListItemProps ): ReactElement => {

  const { getTranslation } = useTranslations();

  const { caretakers, potentialCaretakers } = useCaretakers( ward.id );
  const { push } = useHistory();
  const dispatch = useDispatch();
  const wardPerson = new Person( ward );
  const wardFirstName = wardPerson.firstName;
  const boldWard = ( <span className="font-bold">{wardFirstName}'s</span> );
  const boldWardHeader = ( <span>{boldWard} Consent</span> );
  const [ isAddingCaretaker, setIsAddingCaretaker ] = useState<boolean>( false );
  const addCaretaker = () => {
    setIsAddingCaretaker( true );
  };
  const openMedicalConsent = () => {
    push( medical_consent_document.get({ wardId: ward.id }));
  };
  const handleCaretakerSelected = ( id: string ) => {
    setIsAddingCaretaker( false );
    dispatch( postRole({
      grantee_id: id,
      name: Role.TYPE.CARETAKER,
      ward: { id: ward.id },
    }));
  };

  const getCaretakerTranslation = () => {
    if ( caretakers.length > 1 ) {
      return <Translation translationKey= { 'medicalconsent.screen.listofchildren.childtile.subtitle.caretakers.multiple' }/>;
    } else if ( caretakers.length === 1 ) {
      return <Translation translationKey={ 'medicalconsent.screen.listofchildren.childtile.subtitle.caretakers.one' } dynamicStringValues={ [ caretakers[0].name || '' ] }/>;
    } else {
      return <Translation translationKey= { 'medicalconsent.screen.listofchildren.childtile.subtitle.caretakers.none' }/>;
    }
  };

  return (
    <>
      {isAddingCaretaker && <PeopleSelector
        isOpen
        people={ potentialCaretakers }
        onSelect={ id => {
          handleCaretakerSelected( id );
        } }
        onClose={ id => {
          id ? handleCaretakerSelected( id ) : setIsAddingCaretaker( false );
        }
        }
        optionTypeLabel={ toTitleCase( Role.TYPE.CARETAKER ) }
      />}
      <div className={ `rounded bg-moss flex ${className}` }>
        <div className={ 'flex pl-6' }>
          {caretakers.length === 0 &&
            <>
              <div
                className={ 'w-circleIcon_sm' }
              >
                <QuickAddCircleButton onClick={ addCaretaker } className={ 'z-10' } />
              </div>
              <div
                className={ 'w-circleIcon_sm -ml-3' }
              >
                <PersonIcon
                  alt="avatar"
                  personName={ ward.name ?? null }
                  photo_url={ ward.photo_url ?? null }
                />
              </div>
            </>
          }
          {caretakers.length === 1 ?
            <OverlappingAvatars limit={ 2 } items={ [ caretakers[0], ward ] } />
            :
            <OverlappingAvatars limit={ 1 } items={ caretakers } />
          }
        </div>
        <ActionContainer
          className="flex-1 pl-6 pr-6 pb-6"
          headerClassName="mb-4 font-bold"
          header={ boldWardHeader }
          primaryActionLabel={ getTranslation( '(button)edit' ) }
          primaryAction={ openMedicalConsent }
          primaryActionTheme={ 'clean' }
        >
          <div>{getCaretakerTranslation()}</div>
        </ActionContainer>
      </div>
    </>
  );
};

export default MedicalConsentChildListItem;
