import { ReactElement, useEffect } from 'react';

// Components
import AttorneysInFactSummary from './AttorneysInFactSummary';

// Redux
import { useDispatch } from '../../../../store';
import { setUserAttributes } from '../../../../store/reducers/userReducer';

export default function AttorneysInFact(): ReactElement{
  const dispatch =  useDispatch();
  useEffect(() => {
    dispatch( setUserAttributes({
      'app.attorneyChapterStarted': true,
    }));
  },[ dispatch ]);

  return (
    <div className="flex fixed inset-0 bg-white">
      <AttorneysInFactSummary />
    </div>
  );
}
