import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';
import DirectiveInterface from '../../types/DirectiveInterface';


const initialState = {
  data: [] as Array<DirectiveInterface>,
  // for extra reducers
  patchDirective: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postDirective: {
    meta: {} as AsyncThunkMetaInterface,
  },
  fetchDirectives: {
    meta: {} as AsyncThunkMetaInterface,
  },
};


export default initialState;
