import { ReactElement, useEffect } from 'react';

// Components
import Translation from '../../components/Translation';
import SegmentView from '../../components/SegmentView';
import InvitesList from './InvitesList';

// Types / Utils
import analytics from '../../utils/analytics';

// Hooks
import useBackstack from '../../hooks/useBackstack';

export default function InvitesPage(): ReactElement{
  useEffect(() => {
    analytics.track( 'View - Invite List Screen' );
  }, []);

  const { goBack } = useBackstack();

  return (
    <SegmentView
      onRetreat={ () => goBack() }
      onPrimaryAction={ () => goBack() }
      primaryActionLabel={ <Translation translationKey={ '(button)done' } /> }
      title={ <Translation translationKey="products.invites.list.title" /> }
      subtitle={ <Translation translationKey="products.invites.list.subtitle" /> }
      contentClassName={ 'text-center' }
    >
      <InvitesList />
    </SegmentView>
  );
}
