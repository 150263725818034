import { ReactElement } from 'react';
import { Card } from '../../components/Card';
import Button from '../../components/Button';
interface DashboardNotificationProps {
  title: ReactElement | string;
  bodyText: ReactElement | string;
  primaryActionText: ReactElement | string;
  primaryAction: ()=> void;
}

export default function DashboardNotification({
  title,
  bodyText,
  primaryActionText,
  primaryAction,
}: DashboardNotificationProps ): ReactElement{
  return (
    <Card
      title={ title }
      className={ 'flex flex-col rounded filter mb-6 px-6 py-4 bg-moss' }
    >
      <div className="sm:flex justify-between relative">
        <p className="text-sm sm:w-1/2">
          { bodyText }
        </p>
        <div className="sm-only:mt-4 sm:self-end">
          <Button small theme="primary" className="md:absolute bottom-0 right-0 font-bold shadow-none" onClick={ primaryAction }>
            <>
              <div className="bg-red absolute top-0 right-0 w-3 h-3 rounded-full border-2 border-white"></div>
              { primaryActionText }
            </>
          </Button>
        </div>
      </div>
    </Card>
  );
}
