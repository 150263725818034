import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';

// Components
import InformationalHelperButton from '../../components/InformationalHelperButton';
import InsuranceHelper from '../../components/InsuranceHelper';
import SegmentView from '../../components/SegmentView';
import StripePaymentForm from '../../components/StripePaymentForm';
import Translation from '../../components/Translation';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import useScript from '../../hooks/useScript';
import useTranslations from '../../hooks/useTranslations';
import useRenderInsuranceUpsell from '../../hooks/useRenderInsuranceUpsell';

// Redux
import { makeSnack } from '../../store/reducers/snackbarReducer';
import { patchUser } from '../../store/reducers/userReducer';
import { showContextualModal } from '../../store/reducers/modalReducer';
import { useSelector, useDispatch } from '../../store';
import productsSelector from '../../store/selectors/productsSelector';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import userSelector, { ethosPartnerCodeSelector } from '../../store/selectors/userSelector';
import entitlementsSelector from '../../store/selectors/entitlementsSelector';

// Routes
import {
  checkout,
} from '../../../core/routes';

// Utils
import appConfig from '../../../core/appConfig';
import analytics from '../../utils/analytics';
import statesUtil from '../../utils/statesUtil';
import { separateArrayStringsByComma } from '../../../core/utils/stringHelpers';
import { getEntitlementNameKey } from '../../utils/entitlementsUtils';
import { Product, PRODUCT_KEYS } from '../../types/ProductsInterface';
import { DocumentEntitlements } from '../../types/Entitlements/EntitlementInterface';
import { BASIC_DOCUMENTS, PRO_DOCUMENTS } from '../../core-data-service/models/Document';
import { getCheckoutNextRoute } from '../../router/checkout';

interface IDocumentCheckoutView {
  contentAfterForm?: ReactElement;
  document: keyof DocumentEntitlements;
  selectedProduct: keyof Product;
}

export default function DocumentCheckoutView({
  contentAfterForm,
  document,
  selectedProduct,
}: IDocumentCheckoutView ): ReactElement{
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const { unavailableDocuments, availableDocuments } = useSelector( entitlementsSelector );
  const products = useSelector( productsSelector );

  const { goBack } = useBackstack( checkout.get({},{ product: selectedProduct }));

  const user = useSelector( userSelector );
  const person = useSelector( userPersonSelector );
  const hasEthosPartnerCode = useSelector( ethosPartnerCodeSelector );
  const isInsuranceUpsellShown = useRenderInsuranceUpsell() && !hasEthosPartnerCode;

  const handleOnRetreat = () => goBack();
  const currentProductKey = PRODUCT_KEYS[selectedProduct];
  const isPro = currentProductKey === PRODUCT_KEYS.pro;
  const currentProduct = {
    ...products[currentProductKey],
    ...appConfig.products[currentProductKey],
  };
  const price = useRef( currentProduct.price.displayValue );
  const isUpgradeWarningShown = useRef( products.basic.hasPurchased );


  useEffect(() => {
    analytics.track( 'View - Payment Screen' );
  }, []);

  // Loading Stripe
  const stripeStatus = useScript( 'https://js.stripe.com/v3/' );

  const stripe = useMemo(() => {
    if( stripeStatus === 'loading' || !window.Stripe ) {
      return null;
    } else {
      return window.Stripe( appConfig.stripe.key );
    }
  }, [ stripeStatus ]);

  if( !stripe ) {
    return <></>;
  }

  const isUserUpgrading: boolean = products.basic.hasPurchased && isPro;
  const isStateWarningShown = !!unavailableDocuments.length;
  const userDisplayState = statesUtil.stateByCode( person?.address.state ) || 'your state';

  const unavailableDocumentsString = separateArrayStringsByComma( unavailableDocuments.map( document => getTranslation( getEntitlementNameKey( document ))));

  const getAvailalbleDocumentsString = (): string => {
    const bundleDocuments = isPro ? PRO_DOCUMENTS : BASIC_DOCUMENTS;
    // filter out unavailable documents
    return separateArrayStringsByComma( bundleDocuments
      .filter( document => availableDocuments.includes( document ))
      .map( document => getTranslation( getEntitlementNameKey( document ))),
    );
  };

  const billingDetails = {
    address: {
      postal_code: person?.address?.zip ?? '',
    },
  };

  const updateUserEmailBeforePurchase = ( email: string, onSuccess: ()=> void, onError: ()=> void ) => {
    dispatch( patchUser({ email, onSuccess, onError: err => {
      onError();
      dispatch( makeSnack({ message: err, theme: 'error' }));
    } }));
  };

  function CheckoutSubheader(): ReactElement{
    return <>
      <div className="border-t border-black-20 py-4">
        <p className="text-black font-bold mb-2 md:mb-4">
          <Translation translationKey={ isPro ? 'products.estateplan.paywall.checkout.product.pro' : 'products.estateplan.paywall.checkout.product.basic' } />
        </p>
        <p className="mb-6">
          {isPro
            ? <Translation translationKey={ 'screen.checkout.package.documents.included' } dynamicStringValues={ [ getAvailalbleDocumentsString() ] } />
            : <Translation translationKey="products.estateplan.paywall.checkout.description.basic" />
          }
        </p>
        { isStateWarningShown &&
          <InformationalHelperButton
            title={ getTranslation( 'screen.checkout.exclusion.description', [ unavailableDocumentsString, `${unavailableDocuments.length > 1 ? 'are' : 'is'}`, userDisplayState ]) }
            className="mb-6 md:mb-8"
            modalDescription={ getTranslation( 'alert.unsupportedDocuments.description', [ unavailableDocumentsString, userDisplayState, getAvailalbleDocumentsString() ]) }
          />
        }
        { isUpgradeWarningShown.current &&
          <InformationalHelperButton
            title={ getTranslation( 'screen.checkout.upgrade.refund.description' ) }
            className="mb-6 md:mb-8"
          />
        }
        <div className="flex justify-between">
          <span className="font-bold"><Translation translationKey={ 'products.estateplan.paywall.checkout.total' } /></span>
          <span>{ price.current } USD</span>
        </div>
      </div>
      <p className="font-bold text-black py-4 border-t border-black-20 md:hidden">{ getTranslation( 'products.estateplan.paywall.checkout.payment' ) }</p>
      { isInsuranceUpsellShown && <InsuranceHelper
        eventProperties={{ button: 'EstateBundleCheckout' }}
        primaryActionLabel={ <Translation translationKey="upsell.messagebubble.checkout.button" /> }
        body={ <Translation translationKey="upsell.messagebubble.checkout.message"/> }
      /> }
    </>;
  }

  return(
    <SegmentView
      onRetreat={ handleOnRetreat }
      contentClassName="sm-only:bg-grey-400 overflow-y-auto pb-8 container--lg pt-24 sm-only:pt-18"
      full={ true }
      hidePrimaryAction={ true }
    >
      <div className="md:container--sm">
        <h1 className="text-3xl md:text-5xl font-headline text-black sm-only:mt-4 mb-4 md:mb-16 md:text-center">
          <Translation translationKey="products.estateplan.paywall.checkout.title"/>
        </h1>
        <Elements stripe={ stripe }>
          <StripePaymentForm
            billingDetails={ billingDetails }
            userEmail={ user.email }
            className="md:items-center"
            sku={ currentProduct.sku }
            submitButtonLabel={ <Translation translationKey={ 'products.will.payment.button' } dynamicStringValues={ [ currentProduct.price.displayValue ] } /> }
            onSubmit={ ( onSuccess, onError, email ) => {
              analytics.track( 'Touch - Pay' );
              updateUserEmailBeforePurchase( email, onSuccess, onError );
            } }
            onError={ err => {
              dispatch( makeSnack({ message: err, theme: 'error' }));
            } }
            onSuccess={ () => {
              analytics.track( 'PaymentSuccessful' );
              isUserUpgrading && analytics.track( 'PackageUpgraded' );
              dispatch( showContextualModal({
                imageAboveHeaderSource: getTranslation( 'products.estateplan.checkout.success.headerImage' ),
                contentClassName: 'bg-moss',
                title: getTranslation( 'products.estateplan.checkout.success.title' ),
                description: getTranslation( 'epp.purchase.confirmation.subtitle', [  getTranslation( getEntitlementNameKey( document )) ]),
                confirmLabel: getTranslation( 'products.estateplan.checkout.success.button' ),
                confirmButtonId:'checkout-button',
                onConfirm: () => push( getCheckoutNextRoute( document ).get()),
              }));
            } }
            contentBeforeForm={ <CheckoutSubheader /> }
            contentAfterForm={ contentAfterForm ? contentAfterForm : <Translation translationKey="products.estateplan.paywall.checkout.summary" dynamicStringValues={ [ price.current ] } /> }
          />
        </Elements>
      </div>
    </SegmentView>
  );
}
