import { sumOfShares } from '../utils/numberUtils';
import Person from '../core-data-service/models/Person';
import { useSelector } from '../store';
import InheritanceInterface from '../types/InheritanceInterface';
import PersonInterface from '../types/PersonInterface';
import isDefined from '../utils/isDefined';
import { INITIAL_DISTRIBUTION_AGE, SECONDARY_DISTRIBUTION_AGE } from '../../core/constants';

export const DistributionTypes = {
  disinherited: 'disinherited',
  immediate: 'immediate',
  immediateAndLater: 'immediateAndLater',
  staged: 'staged',
  atAge: 'atAge',
  heldInTrust: 'heldInTrust',
} as const;

type DistributionType = keyof typeof DistributionTypes

interface IUseHeirsHook {
  heirs: PersonInterface[];
  childrenHeirs: PersonInterface[];
  sumOfChildrenShares: number | undefined;
  sumOfFallbackShares: number | undefined;
  fallbackHeirs: PersonInterface[];
  inheritances: InheritanceInterface[];
  spouse: PersonInterface|undefined;
  spouseInheritance: InheritanceInterface|undefined;
  childrenInheritances: ( InheritanceInterface|undefined )[];
  fallbackInheritances: ( InheritanceInterface|undefined )[];
}


const useHeirs = (): IUseHeirsHook => {

  // get heirs and create Person model
  const inheritances = useSelector( state => state.inheritances.data );
  const people = useSelector( state => state.people.data );
  const heirs = inheritances
    .map( inheritance => people.find( person => person.id === inheritance.person_id ))
    .filter( isDefined );

  // Get any heir that isn't a direct family member
  const fallbackHeirs = heirs
    .map( person => new Person( person ))
    .filter( heir => heir && !heir.isHeir )
    .map( person => person.toJSON());

  const spouse = useSelector( state => state.people.data.find( person => new Person( person ).isSpouse ));
  const spouseInheritance = getPersonInheritance( spouse, inheritances );

  const childrenHeirs = useSelector(
    state => state.people.data
      .filter( person => new Person( person ).isChild ),
  );

  const childrenInheritances = childrenHeirs.map( child => {
    return getPersonInheritance( child, inheritances );
  });

  const fallbackInheritances = fallbackHeirs.map( heir => {
    return getPersonInheritance( heir, inheritances );
  });

  /**
   * Sum up all the share values in the inheritances store array
   * @TODO webhooks instead?
   */
  const sumOfChildrenShares = sumOfShares( childrenInheritances );
  const sumOfFallbackShares = sumOfShares( fallbackInheritances );

  return {
    heirs,
    childrenHeirs,
    sumOfChildrenShares,
    sumOfFallbackShares,
    fallbackHeirs,
    inheritances,
    spouse,
    spouseInheritance,
    childrenInheritances,
    fallbackInheritances,
  };
};


export default useHeirs;

const getPersonInheritance = ( person: IUseHeirsHook['spouse'] , inheritances: IUseHeirsHook['inheritances']):  InheritanceInterface|undefined  => {
  return inheritances.find(( inheritance: InheritanceInterface ) => inheritance.person_id === person?.id );
};

export const getDistributionType = ( inheritance: InheritanceInterface, heir: Person ): DistributionType => {
  const [ firstDistribution, secondDistribution ] = inheritance.distributions;

  const hasValidDistributions = heir.isSpouse || (
    ( firstDistribution && firstDistribution.age === INITIAL_DISTRIBUTION_AGE )
    && ( secondDistribution && secondDistribution.age === SECONDARY_DISTRIBUTION_AGE )
  );

  if ( hasValidDistributions ) {
    if ( heir.isSpouse ) {
      return 'immediate';
    } else if ( inheritance.share === 0 ) {
      return 'disinherited';
    } else if ( heir.age && heir.age >= SECONDARY_DISTRIBUTION_AGE ) {
      return 'immediate';
    } else if ( heir.age && heir.age >= INITIAL_DISTRIBUTION_AGE ) {
      return firstDistribution?.share === 100 ? 'immediate' : 'immediateAndLater';
    } else if ( firstDistribution?.share > 0 ) {
      return 'staged';
    } else {
      return 'atAge';
    }
  } else {
    return 'heldInTrust';
  }

};
