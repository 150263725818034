import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';
import UserInterface from '../../types/UserInterface';


const initialState = {
  data: {
    attributes: {},
  } as UserInterface,
  // for extra reducers
  fetchUser: {
    meta: {} as AsyncThunkMetaInterface,
  },
  patchUser: {
    meta: {} as AsyncThunkMetaInterface,
  },
  postUser: {
    meta: {} as AsyncThunkMetaInterface,
  },
  deleteUser: {
    meta: {} as AsyncThunkMetaInterface,
  },
  setUserPartnerCode: {
    meta: {} as AsyncThunkMetaInterface,
  },
  setUserAttributes: {
    meta: {} as AsyncThunkMetaInterface,
  },
};

export default initialState;
