import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import DocumentIntroFlow from '../DocumentIntroFlow';
import Translation from '../../../components/Translation';

// Hooks
import useTranslations from '../../../hooks/useTranslations';
import { setUserAttributes } from '../../../store/reducers/userReducer';

// Routes
import { login, healthcare_directive as healthcareDirectiveRoute } from '../../../../core/routes';

// Redux
import useAuth from '../../../hooks/useAuth';
import { useDispatch } from '../../../store';
import { fetchDirectives } from '../../../store/reducers/directivesReducer';
import { postGoal } from '../../../store/reducers/goalsReducer';

// Utils
import { generateQueryString } from '../../../utils/urlUtils';
import { GoalTypes } from '../../../types/GoalInterface';

const HealthcareDirectiveIntro = (): ReactElement => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const { getTranslation } = useTranslations();

  // Application state
  const { hasAuthenticated, isGuestUser } = useAuth();

  const onSuccess = () => {
    if( hasAuthenticated && !isGuestUser ) {
      push({ pathname: healthcareDirectiveRoute.get() });
    } else {
      push({
        pathname: login.get(),
        search: generateQueryString({ next: healthcareDirectiveRoute.get() }),
      });
    }
  };

  const onComplete = () => {
    dispatch( setUserAttributes({
      'app.healthcareDirectiveDocumentStarted': true,
    }));
    dispatch( postGoal({ type: GoalTypes['GET HEALTHCARE DIRECTIVE'], onSuccess: () => {
      dispatch( fetchDirectives({ onSuccess }));
    } }));

  };

  return (
    <DocumentIntroFlow
      introTrackingId="Touch - Begin Healthcare Directive"
      onComplete={ onComplete }
      intro={{
        body: <Translation translationKey={ 'screen.healthcareDirective.intro.description' } />,
        title: <Translation translationKey={ 'screen.healthcaredirective.intro.title' } />,
        minToComplete: '10',
        image: {
          src: getTranslation( 'healthcaredirective.chapter.intro.headerImage' ),
          alt: 'Healthcare directive intro illustration',
        },
      }}
    />
  );
};

export default HealthcareDirectiveIntro;
