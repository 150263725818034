import { getAgeFromDate, isAdultFromAge } from '../../../core/utils/datetimeUtils';
import { splitFirstAndLastName } from '../../../core/utils/stringHelpers';
import AddressInterface from '../../types/AddressInterface';
import { PersonInterface } from './../../types/PersonInterface';
import Relationship from './Relationship';

export const Ratings = {
  excellent: 'excellent',
  good: 'good',
  fair: 'fair',
  poor: 'poor',
} as const;

export type RatingTypes = keyof typeof Ratings

class Person {
  constructor(
    private readonly _data: PersonInterface,
  ){
    this.relationship = new Relationship( _data.relationship );
  }

  public relationship: Relationship;

  toJSON(): PersonInterface{
    return this._data;
  }

  /**
   * Get a number for sorting by importance.
   * So spouse, significant other, children, and family are shown before others
   */
  public get sortBy(): number{
    if( !this._data.relationship ) {
      return Number.MAX_VALUE;
    }
    if( this.relationship.isSpouse || this.relationship.isBoo ){
      return 1;
    } else if( this.relationship.isChild ){
      return 2;
    } else if( this.relationship.isFamily ){
      return 3;
    }
    return 4;
  }

  public get isHeir(): boolean{
    return this.relationship.isHeir;
  }

  public get isSpouse(): boolean{
    return this.relationship.isSpouse;
  }

  public get isChild(): boolean|undefined{
    return this.relationship.isChild;
  }

  public get dob(): Date|null{
    return !!this._data.dob ? new Date( this._data.dob ) : null;
  }

  public get address(): AddressInterface|null{
    return this._data.address;
  }

  public get age(): number|null{
    return !!this.dob ? getAgeFromDate( this.dob ) : null;
  }

  public get isAdult(): boolean|undefined{
    if( typeof this.age === 'number' ){
      return isAdultFromAge( this.age );
    }
  }

  public getNameTitle = ( pronoun = 'your' ): string => {
    const firstName = splitFirstAndLastName( this._data.name )[0];

    if( firstName ) {
      return firstName;
    } else if( !!this._data.name ) {
      return this._data.name;
    } else {
      const relationship = this._data.relationship
        .toLowerCase()
        .replace( ' female', '' )
        .replace( ' male', '' );
      return `${ pronoun } ${ relationship }`;
    }
  }

  public get firstName(): string{
    return this.getNameTitle();
  }

  public get lastName(): string{
    return splitFirstAndLastName( this._data.name )[ 1 ];
  }
}

export default Person;
