import { ReactElement } from 'react';
import { AxiosResponse } from 'axios';
import { SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import SelectField from '../../components/SelectField';
import TextField from '../../components/TextField';
import Translation from '../../components/Translation';
import AddressFields from '../../components/AddressFields';

// Hooks
import useQuery from '../../hooks/useQuery';
import useRelationshipOptions from '../../hooks/useRelationshipOptions';
import useTranslations from '../../hooks/useTranslations';
import useUserAttributes from '../../hooks/useUserAttributes';

// Redux
import { deletePerson, patchPerson, postPerson } from '../../store/reducers/peopleReducer';
import { isPendingPersonSave, isFetchingPeople, isPendingPersonDelete } from '../../store/selectors/peopleSelectors';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import { useDispatch, useSelector } from '../../store';
import { showContextualModal } from '../../store/reducers/modalReducer';

// Types
import PersonInterface from '../../types/PersonInterface';
import Relationship, { RelationshipTypes } from '../../core-data-service/models/Relationship';
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Utils
import { capitalizeAllFirstLetters } from '../../../core/utils/stringHelpers';
import { isAdultFromDate, isDateInPast } from '../../../core/utils/datetimeUtils';
import { birthdateMinLength, fieldRequired, phoneNumberMinLength } from '../../formValidators';
import inputMask from '../../utils/inputMask';

// Constants
import { PHONE_MIN_LENGTH } from '../../../core/constants';


// Routes
import {
  will_guardians,
  trustees,
  will_executors,
  poa_attorney_in_fact,
  medical_consent_caretaker,
  medical_consent,
} from '../../../core/routes';
import { validatePerson } from './ValidatePerson';
import Role, { RoleTypes } from '../../core-data-service/models/Role';
import { addressTransformer } from '../../utils/address';

export const FORM_PERSON_INPUTS = {
  NAME_LAST: 'lastName',
  NAME_FIRST: 'firstName',
  RELATIONSHIP: 'relationship',
  DOB: 'dob',
  EMAIL: 'email',
  PHONE: 'phone',
  STREET: 'street',
  STREET2: 'street2',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
} as const;

// @todo - some ideas on how to improve the Form
// export interface IFormConfig {
//   allowMinors: boolean;
//   onlyAllowChildren: boolean;
//   fields: Partial<Record<typeof FORM_PERSON_INPUTS[keyof typeof FORM_PERSON_INPUTS], {isRequired: boolean}>>;
// }

// const FORM_PERSON_TYPE_DEFAULT: IFormConfig = {
//   allowMinors: true,
//   onlyAllowChildren: false,
//   fields: {
//     [FORM_PERSON_INPUTS.NAME_LAST]: { isRequired: true },
//     [FORM_PERSON_INPUTS.NAME_LAST]: { isRequired: true },
//     [FORM_PERSON_INPUTS.RELATIONSHIP]: { isRequired: true },
//   },
// };

// const FORM_PERSON_TYPE_MINOR = {
//   ...FORM_PERSON_TYPE_DEFAULT,
//   fields: {
//     ...FORM_PERSON_TYPE_DEFAULT.fields,
//     [FORM_PERSON_INPUTS.DOB]: { isRequired: true },
//   },
// };

// const FORM_PERSON_TYPE_CARETAKER = {
//   ...FORM_PERSON_TYPE_DEFAULT,
//   fields: {
//     ...FORM_PERSON_TYPE_DEFAULT.fields,
//     [FORM_PERSON_INPUTS.STREET]: { isRequired: true },
//   },
// };

// const getPersonFormConfig = ( relationship: PersonInterface['relationship'] | undefined, role: RoleTypes | undefined ): IFormConfig  => {
//   if( role === Role.TYPE.CARETAKER ) {
//     return FORM_PERSON_TYPE_CARETAKER;
//   }
//   return FORM_PERSON_TYPE_DEFAULT;
// };

export interface IPersonDetailFormProps {
  avatarURL?: string;
  clearErrors: UseFormMethods['clearErrors'];
  errors: UseFormMethods['errors'];
  getValues: UseFormMethods['getValues'];
  handleSubmit: UseFormMethods['handleSubmit'];
  isPendingPhoto: boolean;
  person?: PersonInterface;
  register: UseFormMethods['register'];
  setValue: UseFormMethods['setValue'];
  onPostSuccess: ( response: AxiosResponse )=> void;
  onPatchSuccess: ( response: AxiosResponse )=> void;
  onDeleteSuccess: ( response: AxiosResponse )=> void;
  prohibitedRelationships?: string;
  relationshipType?: RelationshipTypes;
  roleType?: RoleTypes;
  watch: UseFormMethods['watch'];
}

export default function PersonDetailForm({
  avatarURL,
  clearErrors,
  errors,
  getValues,
  handleSubmit,
  isPendingPhoto,
  onDeleteSuccess,
  onPatchSuccess,
  onPostSuccess,
  person,
  register,
  setValue,
  prohibitedRelationships,
  relationshipType,
  roleType,
  watch,
}: IPersonDetailFormProps ): ReactElement{
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { getTranslation } = useTranslations();
  const userAttributes = useUserAttributes();
  const { path } = useRouteMatch();
  // Application state
  const isFetching = useSelector( isFetchingPeople );
  const isPendingSave = useSelector( isPendingPersonSave );
  const isPendingDelete = useSelector( isPendingPersonDelete );
  const isPending = isFetching || isPendingSave || isPendingPhoto || isPendingDelete;

  /**
   * The below booleans are (not great) methods for determining
   * the type of person we're trying to add and then add validation
   * or other functionality. i.e. prevent minors from accepting roles
   * or adults in Medical Consent
   */
  const isEditingCaretaker = roleType === Role.TYPE.CARETAKER || path === medical_consent_caretaker.path;
  const isEditingRole = Boolean(
    [
      will_guardians.get(),
      trustees.get(),
      will_executors.get(),
      poa_attorney_in_fact.get(),
    ].includes( location.pathname ) ||
    isEditingCaretaker,
  );
  const isAddingChildInMedicalConsent = Boolean(
    [
      medical_consent.get(),
    ].includes( location.pathname ),
  );

  const useFormProps = {
    register,
    setValue,
    errors,
    getValues,
    clearErrors,
  };

  // don't think these params are required anymore
  const params = useQuery();
  const ofType = params.get( 'type' ) || relationshipType;
  const notType = params.get( 'not' ) || prohibitedRelationships;

  const relationshipOptions = useRelationshipOptions({ ofType, notType })
    .filter( _relationship => _relationship.value !== Relationship.NAMES.ORGANIZATION )
    .map( option => {
      const capitalizedRelationship = capitalizeAllFirstLetters( option.label, '-' );
      return {
        value: option.value,
        label: capitalizedRelationship,
      };
    });

  // If this form is rendered in a "roles" section, we should prevent you from adding minors
  const isSelectedRelationshipChild = Relationship.TYPE.CHILDREN.includes( watch( 'relationship' ));

  // useRelationshipOptions transforms something like "CHILDREN" into a meaningful array of options
  // check to see if _only_ children options are contained within the available relationship options
  // similar to doing something like relationshipOptions.forEach(relationship => Relationship.TYPE.CHILDREN.includes(relationship))
  const canOnlyAddChildren = new Set([ ...Relationship.TYPE.CHILDREN, relationshipOptions.map( option => option.value ) ]).size === Relationship.TYPE.CHILDREN.length;

  // this should change based on the field input
  const isPhysician = ( relationshipType && Relationship.TYPE.PHYSICIAN.includes( relationshipType )) || Relationship.TYPE.PHYSICIAN.includes( getValues( FORM_PERSON_INPUTS.RELATIONSHIP ));
  const renderDobField = ( getValues( FORM_PERSON_INPUTS.DOB ) || ( ofType && canOnlyAddChildren ) || isSelectedRelationshipChild ) && !isPhysician;
  const shouldShowEmail = isPhysician || isEditingCaretaker;
  const shouldShowPhone = isPhysician || isEditingCaretaker;
  const shouldShowAddress = isPhysician || isEditingCaretaker;

  const validatePersonProps = { person, location, history, dispatch, userAttributes, getTranslation };
  validatePerson( validatePersonProps );

  /**
   * Form submit function
   * @param data form data
   */
  const onSubmit: SubmitHandler<SanitizedFormData> = _data => {
    _data.name = _data.firstName+ ' ' + _data.lastName;
    const data = {
      ..._data,
      ...avatarURL && { photo_url: avatarURL },
    };

    if ( shouldShowAddress ) {
      Object.assign( data, addressTransformer( _data ));
    }

    if( !!person ) {
      dispatch( patchPerson({ id: person.id, ...data, onSuccess: response => {
        onPatchSuccess( response );
        const shouldShowSnack = validatePerson({ ...validatePersonProps, person: response.data.data });
        shouldShowSnack && dispatch( makeSnack({ message: getTranslation( 'form.actions.update.success', [ _data.name || '' ]) }));
      },
      onError: ( error: string ) => {
        dispatch( makeSnack({ message: error, theme: 'error' }));
      } }));
    } else {
      dispatch( postPerson({ ...data, onSuccess: response => {
        onPostSuccess( response );
        const shouldShowSnack = validatePerson({ ...validatePersonProps, person: response.data.data });
        shouldShowSnack && dispatch( makeSnack({ message: getTranslation( 'form.actions.create.success.v2', [ _data.name || '' ]) }));
      },
      onError: ( error: string ) => {
        dispatch( makeSnack({ message: error, theme: 'error' }));
      } }));
    }
  };

  const onDelete = () => {
    if( !!person ){
      dispatch( deletePerson({ id: person.id, onSuccess: response => {
        onDeleteSuccess( response );
        dispatch( makeSnack({ message: getTranslation( 'form.actions.remove.success.v2', [ person.name || '' ]), theme: 'warning' }));
      } }));
    }
  };

  return (
    <Form
      onSubmit={ onSubmit }
      handleSubmit={ handleSubmit }
    >
      <div className={ 'flex flex-1' }>

        <TextField { ...useFormProps }
          className={ 'flex-1 mr-4' }
          name={ FORM_PERSON_INPUTS.NAME_FIRST }
          required={ fieldRequired( getTranslation( 'addperson.error.first_name.error' )) }
          label={ <Translation translationKey={ 'addperson.fields.first_name.label' }/> }
        />

        <TextField { ...useFormProps }
          className={ 'flex-1 ml-4' }
          name={ FORM_PERSON_INPUTS.NAME_LAST }
          required={ fieldRequired( getTranslation( 'addperson.error.last_name.error' )) }
          label={ <Translation translationKey={ 'addperson.fields.last_name.label' }/> }
        />
      </div>

      <SelectField { ...useFormProps }
        label={ <Translation translationKey={ 'addperson.fields.relationship.label' }/> }
        name={ FORM_PERSON_INPUTS.RELATIONSHIP }
        options={ relationshipOptions }
        uniqueId={ 'PersonDetailView.relationship' }
        required={ fieldRequired( getTranslation( 'addperson.error.relationship.required' )) }
        onClick={ () => clearErrors( 'dob' ) }
      />

      {renderDobField &&
        <TextField { ...useFormProps }
          label={ <Translation translationKey={ 'addperson.fields.dob.label' }/> }
          minLength={ birthdateMinLength( getTranslation( 'addperson.error.dob.type' )) }
          name={ FORM_PERSON_INPUTS.DOB }
          normalize={ inputMask.date }
          placeholder="MM/DD/YYYY"
          required={ fieldRequired( getTranslation( 'addperson.error.dob.required' ), isSelectedRelationshipChild ) }
          type="tel"
          validate={ dobString => {
            if( !dobString || isDateInPast( new Date( dobString ))) {

              // @todo - don't allow minors for roles
              if( isEditingRole && !isAdultFromDate( new Date( dobString ))) {
                return getTranslation( 'addperson.error.role.minor' );
              } else if( isAddingChildInMedicalConsent && isAdultFromDate( new Date( dobString ))) {
                return getTranslation( 'addperson.error.medicalconsent.adult' );
              } else {
                return true;
              }
            } else {
              return getTranslation( 'addperson.error.dob.value' );
            }
          } }
        />
      }

      { shouldShowEmail &&
        <TextField { ...useFormProps }
          name={ FORM_PERSON_INPUTS.EMAIL }
          label={ <Translation translationKey={ 'profile.email.label' }/> }
          className="mb-4"
          required={ fieldRequired( getTranslation( 'error.invalid.email' )) }
          type="email"
        />
      }

      {shouldShowPhone &&
        <TextField { ...useFormProps }
          name={ FORM_PERSON_INPUTS.PHONE }
          label={ <Translation translationKey={ 'profile.phone.label' }/> }
          className="mb-4"
          required={ fieldRequired( getTranslation( 'error.invalid.phone' )) }
          minLength={ phoneNumberMinLength( getTranslation( 'error.invalid.phone' ), PHONE_MIN_LENGTH ) }
          type="tel"
          normalize={ inputMask.phone }
        />
      }

      {shouldShowAddress && <AddressFields { ...useFormProps } /> }

      <div className="flex justify-between mt-14">
        { !!person &&
          <Button type="button"
            theme="clean-danger"
            isPending={ isPendingDelete }
            onClick={ () => {
              dispatch( showContextualModal({
                description: getTranslation( 'form.actions.remove.confirmation.v2', [ person.name || '' ]),
                dismissLabel: getTranslation( '(button)cancel' ),
                confirmLabel: getTranslation( '(button)remove' ),
                confirmButtonTheme: 'danger',
                onConfirm: () => {
                  onDelete();
                },
              }));
            } }
          >
            {getTranslation( 'form.actions.remove.label' )}
          </Button>
        }
        <Button type="submit" isPending={ isPending } id="add-button">
          <Translation translationKey={ !!person ? 'form.actions.update.label' : 'form.actions.create.label' }/>
        </Button>
      </div>
    </Form>
  );
}


