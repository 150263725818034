import React, { ReactElement } from 'react';

export interface IFieldsetLegend {
  children: string | ReactElement;
  className?: string;
}

export default function FieldsetLegend({ children, className = '' }: IFieldsetLegend ): ReactElement{
  return (
    <legend className={ `${className} text-md font-bold` }>
      {children}
    </legend>
  );
}
