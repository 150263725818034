import { useSelector } from '../store';
import Person from '../core-data-service/models/Person';
import PersonInterface from '../types/PersonInterface';

const useMissingPersonData =(): {
  hasMissingData: boolean;
  peopleMissingData: PersonInterface[];
} => {
  const peopleMissingData = useSelector( state =>
    state.people.data
      .filter( _person => {
        const person = new Person( _person );
        return !person.relationship.isMe
          && (( !person.firstName
              || !person.lastName )
              || ( person.relationship.isBioChild && !person.dob ));
      }));
  const hasMissingData = peopleMissingData.length>0;
  return { hasMissingData,peopleMissingData };
};
export default useMissingPersonData;

