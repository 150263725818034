import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialParams = createSlice({
  name: 'initialParams',
  initialState: false,
  reducers: {

    setInitialParams( _initialParams, action: PayloadAction<boolean> ){
      return action.payload;
    },

  },
});

export const { setInitialParams } = initialParams.actions;

export default initialParams.reducer;
