import { ReactElement } from 'react';
import Icon from '../Icon';

export interface DevOnlyUserAttributesButtonProps {
  handleClick: ()=> void;
}

export default function DevOnlyUserAttributesButton({ handleClick }: DevOnlyUserAttributesButtonProps ): ReactElement{
  return (
    <button className="bg-moss z-50 bottom-10 left-0 p-1 pl-2 fixed rounded-r-md" onClick={ handleClick }>
      <Icon name="manageAccounts" />
    </button>
  );
}
