import { ReactElement, useState } from 'react';

// Components
import PeopleSelector from './PeopleSelector';
import PersonIcon from '../views/People/PersonIcon';
import QuickAddCircleButton from './QuickAddCircleButton';

// Hooks
import { useSelector, useDispatch } from '../store';
import useTranslations from '../hooks/useTranslations';

// Models
import Property from '../core-data-service/models/Property';
import Person from '../core-data-service/models/Person';

// Types
import PersonInterface from '../types/PersonInterface';
import PropertyInterface from '../types/Property/PropertyInterface';

// Reducers
import { patchProperty } from '../store/reducers/propertiesReducer';
import { makeSnack } from '../store/reducers/snackbarReducer';

// Utilities
import { toTitleCase } from '../../core/utils/stringHelpers';

export interface PropertyRolePickerProps {
  propertyItem: PropertyInterface;
  hasQuickAdd?: boolean;
}

function PropertyRolePicker({
  propertyItem,
  hasQuickAdd = false,
}: PropertyRolePickerProps ): ReactElement{
  const [ isPickerOpen, setIsPickerOpen ] = useState( false );
  const guardianOptions = useSelector( state => state.people.data ).filter( person => person.relationship !== 'ME' );
  const { getTranslation } = useTranslations();
  const dispatch = useDispatch();

  const handlePersonSelected = ( id: string ) => {
    const data = {
      beneficiaries: [{ id }],
    };
    dispatch( patchProperty({
      ...data,
      id: propertyItem.id,
      onError: ()=> dispatch( makeSnack({ message: getTranslation( 'role.peoplepicker.error' ), theme: 'error' })),
    }));
  };

  return (
    <>
      <div className="flex">
        <div className="flex mr-4">
          <div className="h-circleIcon_sm w-circleIcon_sm relative z-10">
            {( hasQuickAdd && propertyItem.beneficiaries[0])
              ? <PersonIcon
                photo_url={ propertyItem.beneficiaries[0]?.photo_url ?? null }
                personName={ propertyItem.beneficiaries[0]?.name ?? null }
                alt="avatar"
              />
              : <QuickAddCircleButton onClick={ ()=> setIsPickerOpen( true ) } />
            }
          </div>
          <div className="h-12 w-12 -ml-3">
            <PersonIcon
              photo_url={ Property.getIcon( propertyItem.subtype ) }
              personName={ propertyItem.name }
              alt="avatar"
            />
          </div>
        </div>
        {propertyItem.beneficiaries[0] ?
          <p className="mt-3">{getTranslation( 'products.will.pets.status.pet.primaryGuardian', [ new Person( propertyItem.beneficiaries[0]).firstName || '' ])}</p>
          :
          <p className="mt-3">{getTranslation( 'products.will.pets.status.pet.noGuardian', [ propertyItem.name ? propertyItem.name : 'this pet' ])}</p>
        }
      </div>
      { propertyItem.beneficiaries[1] &&
        <p className="mt-4">{getTranslation( 'products.will.pets.status.pet.backupGuardian', [ new Person( propertyItem.beneficiaries[1]).firstName || '' ])}</p>
      }
      <PeopleSelector
        onSelect={ ( id: PersonInterface['id']) => {
          setIsPickerOpen( false );
          handlePersonSelected( id );
        } }
        optionTypeLabel={ toTitleCase( getTranslation( 'role.name.guardian.displayname' )) }
        people={ guardianOptions }
        isOpen={ isPickerOpen }
        onClose={ () => setIsPickerOpen( !isPickerOpen ) }
        titleTranslationKey={ 'products.will.select.a.person.title' }
      />
    </>
  );
}

export default PropertyRolePicker;
