import { ReactElement, useEffect, useMemo } from 'react';
import { useForm, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import Form from '../../components/Form';
import SegmentView from '../../components/SegmentView';
import { RadioGroup } from '../../components/Radio';
import SelectField from '../../components/SelectField';
import Translation from '../../components/Translation';

// Redux
import { setUserAttributes } from '../../store/reducers/userReducer';
import { useDispatch, useSelector } from '../../store';
import { patchPerson } from '../../store/reducers/peopleReducer';
import userPersonSelector from '../../store/selectors/userPersonSelector';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Routes
import {
  dashboard as dashboardRoute,
  trust,
  trust_basics,
  will,
  will_basics,
} from '../../../core/routes';

// Utils
import { booleanToString } from '../../../core/utils/booleanUtils';
import analytics from '../../utils/analytics';

// Hooks
import useTranslations from '../../hooks/useTranslations';
import useChangingTrustWarningModal from '../../hooks/useChangingTrustWarningModal';
import useUserAttributes from '../../hooks/useUserAttributes';
import { ethosPartnerCodeSelector } from '../../store/selectors/userSelector';

// @Todo should this go somewhere else?
const transformUserAttribute = ( value: boolean | undefined ): string | null => {
  if( value === undefined ) {
    return null;
  }
  return booleanToString( value );
};

export default function DocumentBasics(): ReactElement{

  const { pathname } = useLocation();
  const isRenderedInTrust = pathname === trust_basics.get();
  const isRenderedInWill = pathname === will_basics.get();
  const onCompleteRoute = isRenderedInTrust ? trust.get() : will.get();

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { getTranslation } = useTranslations();

  // Application state
  const userPerson = useSelector( userPersonSelector );
  const {
    hasSpouse,
    hasDomesticPartnership,
    hasChildren,
    hasUnderageChildren,
    documents: {
      will: { hasConfirmedWillBasics },
      trust: { hasSignedTrust, hasConfirmedTrustBasics },
    },
  } = useUserAttributes();

  const hasConfirmedBasics = hasConfirmedWillBasics || hasConfirmedTrustBasics;
  const hasCompletedOnboarding = useSelector( state => state.auth.hasCompletedOnboarding );
  const canShowOnboarding = useSelector( state => state.settings.canShowOnboarding );
  const hasEthosPartnerCode = useSelector( ethosPartnerCodeSelector );

  // Application pending states
  const isPendingPostAttributes = useSelector( state => state.user.setUserAttributes.meta.requestStatus === 'pending' );
  const isPendingFetchUser = useSelector( state => state.user.fetchUser.meta.requestStatus === 'pending' );
  const isPendingPostGoal = useSelector( state => state.goals.postGoal.meta.requestStatus === 'pending' );
  const isPendingPatchGoal = useSelector( state => state.goals.patchGoal.meta.requestStatus === 'pending' );
  const isPending = isPendingPostAttributes || isPendingFetchUser || isPendingPostGoal || isPendingPatchGoal;

  const defaultValues = useMemo(
    () => {
      return {
        gender: userPerson?.gender,
        hasSpouse: transformUserAttribute( hasSpouse ),
        hasDomesticPartnership: transformUserAttribute( hasDomesticPartnership ),
        hasChildren: transformUserAttribute( hasChildren ),
        hasUnderageChildren: transformUserAttribute( hasUnderageChildren ),
      };
    },[ hasSpouse, hasDomesticPartnership, hasChildren, hasUnderageChildren, userPerson ],
  );

  // Form
  const { register, unregister, setValue, getValues, errors, handleSubmit, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  });

  const useFormProps = {
    register: register({ required: 'You must answer this question' }) as UseFormMethods['register'],
    unregister,
    setValue,
    errors,
    getValues,
  };

  // SelectField wants slightly different props
  const useFormPropsSelect = {
    register,
    setValue,
    errors,
    getValues,
    required: {
      value: true,
      message: 'Please select a gender',
    },
  };

  const hasSpouseFieldValue = watch( 'hasSpouse' );
  const hasSpouseFieldBool = hasSpouseFieldValue === 'true';
  const hasChildrenFieldBool = watch( 'hasChildren' ) === 'true';

  useChangingTrustWarningModal( isRenderedInTrust && !!hasSignedTrust );

  // onSubmit
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    const analyticsEvent = isRenderedInTrust
      ? 'Touch - Finish Trust Basics'
      : 'Touch - Finish Basics';

    analytics.track( analyticsEvent );
    // gender goes to Person, remaining fields go to User attrs
    const { gender, ...remainingFields } = data;
    userPerson && dispatch( patchPerson({ id: userPerson.id, gender }));

    // naming form variable app.hasChildren ends up submitting an object like:
    // app: { hasChildren }, we want to maintain the entire key
    // if this continues to get used, consider helper
    const transformedAttributes = Object.entries( remainingFields ).reduce(( acc, field ) => {
      const [ key, value ] = field;
      acc[`app.${key}`] = value;
      return acc;
    }, {} as SanitizedFormData );

    if ( isRenderedInWill ) {
      Object.assign( transformedAttributes, {
        'app.willHasConfirmedBasics': true,
      });
    } else if ( isRenderedInTrust ) {
      Object.assign( transformedAttributes, {
        'app.trustHasConfirmedBasics': true,
      });
    }

    dispatch( setUserAttributes({
      ...transformedAttributes,
      onSuccess: () => push( onCompleteRoute ),
    }));
  };

  // Remove input value on `hasDomesticPartnership` if `hasSpouse` field = true
  useEffect(() => {
    if( hasSpouseFieldBool ){
      setValue( 'hasDomesticPartnership', undefined );
    }
  }, [ hasSpouseFieldBool, setValue ]);

  // Remove input value on `hasUnderageChildren` if `hasChildren` field = 'false'
  useEffect(() => {
    if( !hasChildrenFieldBool ){
      setValue( 'hasUnderageChildren', undefined );
    }
  }, [ hasChildrenFieldBool, setValue ]);

  // Redirect home if onboarding is incomplete + hidden
  useEffect(() => {
    if( !hasCompletedOnboarding && !canShowOnboarding ){
      push({ pathname: dashboardRoute.get() });
    }
  }, [ hasCompletedOnboarding, canShowOnboarding, push ]);

  const getTitleTranslationKey = () => {
    if( hasConfirmedBasics ) {
      return 'products.basics.title.confirm';
    } else if ( isRenderedInTrust ) {
      return 'products.basics.title.not_started';
    } else if ( hasEthosPartnerCode ) {
      return 'screen.will.intro.title.ethos.policy.holder';
    } else {
      return 'screen.will.intro.title';
    }
  };

  return (
    <Form
      handleSubmit={ handleSubmit }
      onSubmit={ onSubmit }
    >
      <SegmentView
        title={ <Translation translationKey={ getTitleTranslationKey() } /> }
        onRetreat={ () =>  push(( hasConfirmedBasics || isRenderedInTrust ) ? onCompleteRoute : dashboardRoute.get())  }
        retreatIcon={ hasConfirmedBasics ? 'arrow-left' : 'close' }
        primaryActionLabel={ hasConfirmedBasics ? <Translation translationKey="products.basics.button.confirm" /> : <Translation translationKey="products.basics.button.not_started" /> }
        primaryActionButtonType="submit"
        primaryActionButtonIsPending={ isPending }
      >
        <p className="font-bold text-black mb-6">{ getTranslation( 'account.signup.gender.title' ) }</p>
        <Translation translationKey={ 'account.signup.gender.description' } />

        <SelectField
          className="pb-8 mt-6 mb-8 border-b border-black-20"
          { ...useFormPropsSelect }
          label={ getTranslation( 'profile.gender.title' ) }
          name="gender"
          uniqueId="gender"
          showLabel={ false }
          options={
            [
              { label: getTranslation( 'account.signup.name.fields.label.male' ), value: 'MALE' },
              { label: getTranslation( 'account.signup.name.fields.label.female' ), value: 'FEMALE' },
              { label: getTranslation( 'account.signup.name.fields.label.unspecified' ), value: 'UNSPECIFIED' },
            ]
          }
        />

        <RadioGroup { ...useFormProps }
          className="pb-8 mb-8 border-b border-black-20"
          name="hasSpouse"
          label={ <Translation translationKey={ 'basicinfo.question.married' }/> }
          items={
            [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]
          }
        />

        { hasSpouseFieldValue !== null && !hasSpouseFieldBool &&
        <RadioGroup { ...useFormProps }
          disabled={ hasSpouseFieldBool }
          className="pb-8 mb-8 border-b border-black-20"
          register={
            register({
              validate: {
                required: value => getValues( 'hasSpouse' ) === 'true' || ( getValues( 'hasSpouse' ) === 'false' && value !== null ),
              },
            }) as UseFormMethods['register'] }
          name="hasDomesticPartnership"
          label={ <Translation translationKey={ 'basicinfo.question.domesticpartner' }/> }
          items={
            [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]
          }
        />
        }

        <RadioGroup { ...useFormProps }
          className={ `${hasChildrenFieldBool ? 'border-b border-black-20 mb-8 pb-8' : ''}` }
          name="hasChildren"
          label={ <Translation translationKey={ 'basicinfo.question.kids' }/> }
          items={
            [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]
          }
        />

        {hasChildrenFieldBool &&
        <RadioGroup { ...useFormProps }
          disabled={ !hasChildrenFieldBool }
          name="hasUnderageChildren"
          label={ <Translation translationKey={ 'basicinfo.question.under18' }/> }
          register={
            register({
              validate: {
                required: value =>  getValues( 'hasChildren' ) === 'false' || ( getValues( 'hasChildren' ) === 'true' && value !== null ),
              },
            }) as UseFormMethods['register'] }
          items={
            [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]
          }
        />
        }
      </SegmentView>
    </Form>
  );
}
