const staticRoutes = [
  /**
   * iOS - License Info
   */
  {
    key: 'iosLicenseInfo',
    name: 'iOS / License Info',
    path: '/ios/license-info/',
    template: 'index.html',
  },

  /**
   * iOS - Legal Disclaimer
   */
  {
    key: 'iosLegalDisclaimer',
    name: 'iOS / Legal Disclaimer',
    path: '/ios/legal-disclaimer/',
    template: 'index.html',
  },

  /**
   * iOS - Terms of Service
   */
  {
    key: 'iosTermsOfService',
    name: 'iOS / Terms of Service',
    path: '/ios/terms/',
    template: 'index.html',
  },
];

export default staticRoutes;
