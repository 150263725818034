import Document, { AvailableDocumentType } from '../core-data-service/models/Document';
import { TranslationKeys } from '../store/initial/translations';

export type DocumentTitleAndDescription = {
  documentTitle: TranslationKeys;
  documentDescription: TranslationKeys;
}

export const documentToTitleAndDescription = ( docType: AvailableDocumentType ): DocumentTitleAndDescription => {
  switch ( docType ) {
  case Document.TYPE_POUROVER_WILL:
  case Document.TYPE_SIMPLE_WILL:
  case Document.TYPE_WILL:
    return { documentTitle: 'dashboard.products.will.title', documentDescription: 'screen.document.completion.will.description' };
  case Document.TYPE_TRUST:
    return { documentTitle: 'dashboard.products.trust.title', documentDescription: 'screen.document.completion.trust.description' };
  case Document.TYPE_POWER_OF_ATTORNEY:
    return { documentTitle: 'dashboard.products.poa.title', documentDescription: 'screen.document.completion.poa.description' };
  case Document.TYPE_MEDICAL_CONSENT:
    return { documentTitle: 'dashboard.products.medicalconsent.title', documentDescription: 'screen.document.completion.medicalconsent.description' };
  case Document.TYPE_HEALTHCARE_DIRECTIVE:
    return { documentTitle: 'dashboard.products.directive.title', documentDescription: 'screen.document.completion.directive.description' };
  }
};
