import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import DocumentIntroFlow from '../DocumentIntroFlow';
import Translation from '../../../components/Translation';

// Hooks
import useTranslations from '../../../hooks/useTranslations';
import { setUserAttributes } from '../../../store/reducers/userReducer';

// Routes
import { login, poa as poaRoute } from '../../../../core/routes';

// Redux
import useAuth from '../../../hooks/useAuth';
import { useDispatch } from '../../../store';

// Utils
import { generateQueryString } from '../../../utils/urlUtils';

const PoaIntro = (): ReactElement => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const { getTranslation } = useTranslations();

  // Application state
  const { hasAuthenticated, isGuestUser } = useAuth();

  const onComplete = () => {
    dispatch( setUserAttributes({
      'app.poaDocumentStarted': true,
    }));
    if( hasAuthenticated && !isGuestUser ) {
      push({ pathname: poaRoute.get() });
    } else {
      push({
        pathname: login.get(),
        search: generateQueryString({ next: poaRoute.get() }),
      });
    }
  };

  return (
    <DocumentIntroFlow
      introTrackingId="Touch - Begin PoA"
      onComplete={ onComplete }
      intro={{
        body: <Translation translationKey={ 'screen.poa.intro.description' } />,
        title: <Translation translationKey={ 'screen.poa.intro.title' } />,
        minToComplete: '10',
        image: {
          src: getTranslation( 'poa.chapter.intro.headerImage' ),
          alt: 'Poa intro illustration',
        },
      }}
    />
  );
};

export default PoaIntro;
