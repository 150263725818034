import { cloneElement, ReactElement, useEffect } from 'react';

// Components
import ScenarioA from './ScenarioA';
import ScenarioB from './ScenarioB';
import ScenarioC from './ScenarioC';
import HeirsSummary from './HeirsSummary';
import Flow from '../../components/Flow';

// Hooks
import useChangingTrustWarningModal from '../../hooks/useChangingTrustWarningModal';
import useQuery from '../../hooks/useQuery';
import useFlowQueryParams from '../../hooks/useFlowQueryParams';
import useUserAttributes from '../../hooks/useUserAttributes';

// Utils
import analytics from '../../utils/analytics';

// Redux
import { useDispatch } from '../../store';
import { setUserAttributes } from '../../store/reducers/userReducer';

// Types
import { heirsScenarios } from '.';
import { trust as trustRoute, trust_heirs as trustHeirsRoute } from '../../../core/routes';
import { VideoSourceMap } from '../../components/VideoPlayer';

export const trustHeirsVideoSourceMap: VideoSourceMap = { portrait: 'video.trust.heirs', landscape: 'video.trust.heirs.landscape' };

export function TrustHeirs(): ReactElement{

  const dispatch = useDispatch();

  const { hasChildren,
    hasDomesticPartnership,
    hasSpouse,
    documents: {
      will: { willHeirsChapterNeedsReview },
      trust: { hasSignedTrust, hasStartedTrustHeirsSection },
    },
  } = useUserAttributes();

  const routingProps = {
    documentRoute: trustRoute.get(),
    flowRoute: trustHeirsRoute.get(),
  };

  const validScenarios = [
    { page: heirsScenarios.a, component: <ScenarioA { ...routingProps } />, condition: hasSpouse || hasDomesticPartnership },
    { page: heirsScenarios.b, component: <ScenarioB { ...routingProps } />, condition: hasChildren },
    { page: heirsScenarios.c, component: <ScenarioC { ...routingProps } />, condition: true  },
  ].filter( scenario => scenario.condition );

  const currentIndex = useFlowQueryParams([ ...validScenarios.map( scen => scen.page ) ]);
  const page = useQuery().get( 'page' );

  useEffect(() => {
    if( page && validScenarios.map( scen => scen.page ).includes( page )) {
      analytics.track( 'View - Heirs', { scenario: page });
    }
  }, [ page ]);

  useEffect(() => {
    const attrs = {};
    if( !hasStartedTrustHeirsSection ) {
      Object.assign( attrs, { 'app.trustHeirsChapterStarted' : true });
    }
    if( willHeirsChapterNeedsReview ) {
      Object.assign( attrs, { 'app.willHeirsChapterNeedsReview' : false });
    }
    if( Object.entries( attrs ).length > 0 ) {
      dispatch( setUserAttributes( attrs ));
    }
  }, [ hasStartedTrustHeirsSection,willHeirsChapterNeedsReview, dispatch ]);

  useChangingTrustWarningModal( !!hasSignedTrust );

  return (
    <div className="flex fixed inset-0 bg-white">
      <Flow currentIndex={ currentIndex }>
        <HeirsSummary
          videoSourceMap={ trustHeirsVideoSourceMap }
          videoTranslationKey="products.trust.heirs.summary.video_button"
          completionEvent={ 'Touch - Finish Trust Heirs' }
          { ...routingProps }
        />
        { validScenarios.map( scenario => cloneElement( scenario.component, { key: scenario.page }))}
      </Flow>
    </div>
  );
}


export default TrustHeirs;
