import { RootState } from '..';
import InvitationInterface from '../../types/InvitationInterface';


export const invitationsSelector = ( state: RootState ): InvitationInterface[] => {
  return state.invitations.data;
};


export default invitationsSelector;
