import { History, LocationDescriptorObject } from 'history';
import { trust_heirs, will_heirs, trust, will, dashboard } from '../../../core/routes';
import { separateArrayStringsByComma } from '../../../core/utils/stringHelpers';
import Person from '../../core-data-service/models/Person';
import { GetTranslationType } from '../../hooks/useTranslations';
import { IUseUserAttributes } from '../../hooks/useUserAttributes';
import { AppDispatch } from '../../store';
import { showContextualModal } from '../../store/reducers/modalReducer';
import { setUserAttributes } from '../../store/reducers/userReducer';
import PersonInterface from '../../types/PersonInterface';
import { trustResetAttrs } from '../Documents/Trust/TrustReview';

export const validatePerson = ({
  person,
  location,
  history,
  dispatch,
  userAttributes,
  getTranslation,
}: {
  person: PersonInterface | undefined;
  history: History;
  location: LocationDescriptorObject;
  dispatch: AppDispatch;
  userAttributes: IUseUserAttributes;
  getTranslation: GetTranslationType;
},
): boolean => {
  if( !person ) {
    return false;
  }
  const _person = new Person( person );
  const {
    hasChildren,
    hasUnderageChildren,
    hasSpouse,
    hasDomesticPartnership,
    documents: {
      trust: {
        hasConfirmedTrustBasics,
        hasViewedPreview: hasCompletedTrust,
      },
      will: {
        hasConfirmedWillBasics,
        hasStartedGuardiansChapter,
        willPreviewViewed: hasCompletedWill,
      },
    },
  } = userAttributes;

  const hasSpouseConflict = _person.isSpouse && ( !hasSpouse && !hasDomesticPartnership );
  const hasChildConflict = _person.relationship.isBioChild && !hasChildren;
  const hasUnderageChildConflict = _person.relationship.isBioChild && !_person.isAdult && !hasUnderageChildren;

  if( !( hasSpouseConflict || hasChildConflict || hasUnderageChildConflict )) {
    return true;
  }

  const isRenderedInHeirsSection = location.pathname === trust_heirs.get() || location.pathname === will_heirs.get();

  const updatedUserAttrs = {};

  const documentsUserHasStarted = [];
  // calculate here, otherwise the dispatch below will reset
  const descriptionTranslationKey = getModalDescriptionTranslation( hasCompletedTrust, hasCompletedWill, hasConfirmedTrustBasics, hasConfirmedWillBasics );

  // make sure the will document and the guardians section gets reviewed for will
  if( hasConfirmedWillBasics ) {
    Object.assign( updatedUserAttrs, {
      'app.willNeedsReview': true,
      'app.willPreviewViewed': false,
    });

    if( hasUnderageChildConflict && hasStartedGuardiansChapter ) {
      Object.assign( updatedUserAttrs, {
        'app.willGuardiansChapterNeedsReview': true,
      });
    }
    documentsUserHasStarted.push( getTranslation( 'dashboard.products.will.title' ));
  }

  // make sure the trust gets reviewed
  if( hasConfirmedTrustBasics ) {
    Object.assign( updatedUserAttrs, {
      'app.trustNeedsReview': true,
      ...trustResetAttrs,
    });
    documentsUserHasStarted.push( getTranslation( 'dashboard.products.trust.title' ));
  }

  // make sure heirs section gets reviewed
  if( !isRenderedInHeirsSection && documentsUserHasStarted.length > 0 ) {
    Object.assign( updatedUserAttrs, {
      'app.willHeirsChapterNeedsReview': true,
    });
  }

  // modify basics
  if( hasSpouseConflict ) {
    Object.assign( updatedUserAttrs, {
      'app.hasSpouse': _person.relationship.isHusbandOrWife,
      'app.hasDomesticPartnership': !_person.relationship.isHusbandOrWife,
    });
  } else if ( hasUnderageChildConflict ) {
    Object.assign( updatedUserAttrs, {
      'app.hasChildren': true,
      'app.hasUnderageChildren': true,
    });
  } else if ( hasChildConflict ) {
    Object.assign( updatedUserAttrs, {
      'app.hasChildren': true,
      'app.hasUnderageChildren': false,
    });
  }
  dispatch( setUserAttributes( updatedUserAttrs ));

  // don't show modal if you're in the heirs section or you haven't even started will/trust
  if( isRenderedInHeirsSection || documentsUserHasStarted.length === 0 ) {
    return true;
  }

  dispatch( showContextualModal({
    title: getTranslation( 'addperson.basicscontradiction.title', [
      _person.firstName,
      separateArrayStringsByComma( documentsUserHasStarted ),
    ]),
    description: getTranslation( descriptionTranslationKey ),
    confirmLabel: getTranslation( 'addperson.basicscontradiction.confirm' ),
    dismissLabel: getTranslation( 'addperson.basicscontradiction.dismiss' ),
    onConfirm: () => {
      if( hasConfirmedTrustBasics && !hasConfirmedWillBasics ) {
        history.push( trust.get());
      } else if( !hasConfirmedTrustBasics && hasConfirmedWillBasics ) {
        history.push( will.get());
      } else {
        history.push( dashboard.get());
      }
    },
  }));

  return false;

};

const getModalDescriptionTranslation = (
  hasCompletedTrust?: boolean,
  hasCompletedWill?: boolean,
  hasConfirmedTrustBasics?: boolean,
  hasConfirmedWillBasics?: boolean,
) => {
  if( hasCompletedTrust || hasCompletedWill ) {
    return 'addperson.basicscontradiction.description.completed';
  } else if ( hasConfirmedTrustBasics && hasConfirmedWillBasics ) {
    return 'addperson.basicscontradiction.description.uncompletedMultiple';
  } else {
    return 'addperson.basicscontradiction.description.uncompleted';
  }
};
