import { ReactElement } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Routes
import { dashboard as dashboardRoute, login } from '../../core/routes';

// Components
import Modal from '../components/Modal';
import Icon from '../components/Icon';
import Button from '../components/Button';
import Translation from '../components/Translation';

// Utils
import { generateQueryString } from '../utils/urlUtils';

interface SignInModalProps {
  routeAfterSignIn?: string;
}

export default function SignInModal({ routeAfterSignIn }: SignInModalProps ): ReactElement{
  const { push } = useHistory();
  const location = useLocation();

  return (
    <Modal
      type="contextual"
      className="text-center"
    >
      <Icon name="alert-circle" width="100" height="100" className="text-forest mx-auto mb-8"/>
      <p className="mb-8">
        <Translation translationKey="account.continue.signin.label" />
      </p>
      <div className="flex justify-between">
        <Button
          theme="ghost"
          onClick={ () => {
            push( dashboardRoute.get());
          } }
          className="mx-auto"
        >
          <Translation translationKey="account.signup.prompt.buttons.secondaryButton.label" />
        </Button>
        <Button
          onClick={ () => {
            push({
              pathname: login.get(),
              search: routeAfterSignIn ? generateQueryString({ next: routeAfterSignIn }) : '',
              state: { background: location },
            });
          } }
          className="mx-auto"
        >
          <Translation translationKey="account.signin.button" />
        </Button>
      </div>
    </Modal>
  );
}
