import { ReactElement, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import SegmentView from '../../components/SegmentView';
import GuardiansSummaryAddChildPrompt from './GuardiansSummaryAddChildPrompt';
import Translation from '../../components/Translation';
import GuardiansSummaryList from './GuardiansSummaryList';
import OpenVideoButton from '../../components/OpenVideoButton';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import useGuardians from '../../hooks/useGuardians';
import { doAllMinorChildrenHaveGuardians } from '../../hooks/useWillSectionStatus';

// Routes
import {
  will as willRoute,
  person as personRoute,
} from '../../../core/routes';

// Redux
import { useDispatch } from '../../store';
import { showContextualModal, showInviteModal } from '../../store/reducers/modalReducer';

// Types
import LocationState from '../../types/LocationState';
import { VideoSourceMap } from '../../components/VideoPlayer';

// Utils
import analytics from '../../utils/analytics';
import Role from '../../core-data-service/models/Role';
import useUserAttributes from '../../hooks/useUserAttributes';
import useTranslations from '../../hooks/useTranslations';

export const guardiansVideoSourceMap: VideoSourceMap = { portrait: 'video.will.guardians', landscape: 'video.will.guardians.landscape' };

export default function GuardiansSummary(): ReactElement{

  const { push } = useHistory();
  const { goBack } = useBackstack( willRoute.get());
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();

  const { getTranslation } = useTranslations();

  const [ hasSeenWarningModal, setHasSeenWarningModal ] = useState( false );

  const { hasSeenGuardiansInviteModal, hasUnderageChildren: hasReportedUnderageChildren } = useUserAttributes();

  const {
    minorChildren,
    roles,
    hasAddedUnderageChildren,
    uninvitedGuardianFirstNames,
  } = useGuardians();


  const createChild = () => push({
    pathname: personRoute.get({ id: 'create' }),
    search: '?type=CHILDREN',
    state: { background: location },
  });

  const onPrimaryAction = () => {
    analytics.track( 'Touch - Finish Guardians' );
    if( !hasSeenWarningModal ){
      dispatch( showContextualModal({
        title: getTranslation( 'screen.guardians.warning.title' ),
        description: getTranslation( 'screen.guardians.warning.description' ),
        confirmLabel: getTranslation( 'screen.guardians.warning.confirm.label' ),
        onConfirm: () => {
          setHasSeenWarningModal( true );
        },
      }));
    } else if ( hasSeenGuardiansInviteModal || uninvitedGuardianFirstNames.length === 0 ) {
      goBack();
    } else {
      dispatch( showInviteModal({
        firstNames: uninvitedGuardianFirstNames,
        roleLabel: Role.TYPE.GUARDIAN,
        onDismiss: goBack,
      }));
    }
  };

  return (
    <SegmentView
      onRetreat={ goBack }
      title={ <Translation translationKey="will.chapter.title.guardians" /> }
      primaryActionLabel={ <Translation translationKey="products.will.chapter.actions.finish" /> }
      subtitle={ <>
        <Translation translationKey={ 'screen.guardians.subtitle' }/>
        <OpenVideoButton
          videoSrc={ guardiansVideoSourceMap }
          labelKey="guardians.video_button"
        />
      </> }
      hidePrimaryAction={ !doAllMinorChildrenHaveGuardians( minorChildren, roles ) }
      onPrimaryAction={ onPrimaryAction }

    >
      {/* render underage children that _do_ exist */}
      <GuardiansSummaryList minorChildren={ minorChildren } roles={ roles }/>

      {/* if they haven't added children, but said they had them, prompt to add */
        !hasAddedUnderageChildren && <GuardiansSummaryAddChildPrompt
          titleKey={ hasReportedUnderageChildren
            ? 'screen.willstatus.guardians.message.noChildrenAdded'
            : 'screen.guardians.nokids.message'
          }
          onPrimaryAction={ createChild }
        />
      }

    </SegmentView>
  );
}

