import React, { ReactElement } from 'react';

interface Props {
  direction?: 'y' | 'x' | 'auto';
  children: React.ReactNode;
  className?: string;
}

export default function Scroll({ direction = 'x', children, className = '' }: Props ): ReactElement{
  const isYDirection = direction === 'y';
  const overflowClass = isYDirection ? 'overflow-y-scroll overflow-x-hidden' : 'overflow-x-scroll overflow-y-hidden';
  return (
    <div className={ `${overflowClass} ${className} whitespace-nowrap` }>
      {children}
    </div>
  );
}
