/* eslint-disable jsx-a11y/no-onchange */
import { FC, ReactNode } from 'react';
import { FieldErrors, RegisterOptions, UseFormMethods } from 'react-hook-form';

// Components
import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

// Hooks
import useTranslations from '../hooks/useTranslations';

// Types
import { TranslationKeys } from '../store/initial/translations';

export interface SelectOption {
  label: string;
  value: string | undefined;
}

export interface SelectFieldProps {
  className?: string;
  disabledOptionLabelKey?: TranslationKeys;
  // exposing placeholder here in case it comes from server
  placeholder?: string;
  showDisabledOption?: boolean;
  errors: FieldErrors;
  label: ReactNode;
  name: string;
  options: Array<SelectOption>;
  register: UseFormMethods['register'];
  getValues: UseFormMethods['getValues'];
  setValue: UseFormMethods['setValue'];
  required?: RegisterOptions['required'];
  showLabel?: boolean;
  uniqueId: string;
  onClick?: ()=> void;
}

export const SelectField: FC<SelectFieldProps> = ({
  className = '',
  disabledOptionLabelKey = 'input.select.option.default.label',
  placeholder,
  showDisabledOption = true,
  errors,
  label,
  name,
  options,
  getValues,
  setValue,
  showLabel = true,
  register,
  required,
  uniqueId,
  onClick,
}) => {
  const { getTranslation } = useTranslations();
  const registerOptions = {
    required,
  } as RegisterOptions;
  const fieldError = errors[name];

  // setting value to null allows for no value to be selected
  const value = getValues( name );

  if( value === undefined ) {
    setValue( name, null );
  }

  return (
    <FieldLabel
      label={ label }
      name={ name }
      isHidden={ !showLabel }
      className={ className }
    >
      <select
        onClick={ onClick }
        id={ uniqueId }
        name={ name }
        ref={ register( registerOptions ) }
        aria-invalid={ !!fieldError }
        className={ `
            text-base
            appearance-none
            w-full
            rounded-md
            border
            border-initialFieldBorder
            focus:border-forest
            focus:ring-0
            focus:outline-none
            focus:shadow-inputFocus
            focus:text-black-70
            outline-none
            ring-0
            cursor-pointer
            ${value ? 'text-black-70' : 'text-black-50'}
          ` }
      >
        {showDisabledOption &&
          <option disabled hidden key={ 'disabled' } value={ '' }>
            {placeholder || getTranslation( disabledOptionLabelKey )}
          </option>
        }
        {options.map( opt => (
          <option key={ `${uniqueId} - ${opt.value}` } value={ opt.value }>
            {opt.label}
          </option>
        ))}
      </select>
      {!!fieldError &&
        <FieldError
          className="mt-2"
          errorMessage={ fieldError.message }
        />
      }
    </FieldLabel>
  );
};

export default SelectField;
