import { ReactElement, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import DocumentComplete from '../DocumentComplete';
import SegmentView from '../../../components/SegmentView';

// Hooks
import useUserAttributes from '../../../hooks/useUserAttributes';

// Models
import Document from '../../../core-data-service/models/Document';

// Redux
import { useDispatch } from '../../../store';
import { setUserAttributes } from '../../../store/reducers/userReducer';

// Routes
import {
  healthcare_directive as directiveRoute,
} from '../../../../core/routes';

export default function DirectiveReview(): ReactElement{
  const dispatch = useDispatch();
  const { push } =  useHistory();
  const { documents: { healthcareDirective: { hasViewedDocument: isDocumentViewed } } } = useUserAttributes();
  const documentType = Document.TYPE_HEALTHCARE_DIRECTIVE;

  const hasCurrentDocumentBeenViewed = useRef( isDocumentViewed );

  const setDocumentReviewedAttribute = ( documentViewed: boolean, onSuccess?: ()=> void ) =>{
    dispatch( setUserAttributes({ 'app.healthcareDirectiveDocumentViewed': documentViewed, onSuccess }));
  };

  const handleMakeChangesAction = () => {
    setDocumentReviewedAttribute( false, () => push( directiveRoute.get()));
  };

  return(
    <SegmentView
      contentClassName="absolute inset-0 z-10"
      full
      onRetreat={ () => push( directiveRoute.get()) }
      hidePrimaryAction={ true }
      hideSecondaryAction={ true }
      shouldHideBackButton={ true }
      hideLogo={ true }
    >
      <DocumentComplete
        documentType={ documentType }
        hasSeenView={ !!hasCurrentDocumentBeenViewed.current }
        onClickEdit={ handleMakeChangesAction }
        onRender={ ()=> setDocumentReviewedAttribute( true ) }
      />
    </SegmentView>
  );
}
