// Components
import ActionContainer from '../../components/ActionContainer';
import HorizontalRule from '../../components/HorizontalRule';
import InvitationWrapper from '../../components/InvitationWrapper';
import PersonSelectedContainer from '../../components/PersonSelectedContainer';

// Redux
import { deleteRole } from '../../store/reducers/rolesReducer';
import { useDispatch } from '../../store';
import { makeSnack } from '../../store/reducers/snackbarReducer';

// Utils
import { toTitleCase } from '../../../core/utils/stringHelpers';
import stringifyNumber from '../../utils/stringifyNumber';
import { getTrusteeDescription } from './TrusteesSummary';

// Models
import { PersonInterface } from '../../types/PersonInterface';
import RoleInterface from '../../types/RoleInterface';

// Icons
import useTranslations from '../../hooks/useTranslations';

import Invitation from '../../core-data-service/models/Invitation';
import Role from '../../core-data-service/models/Role';
import { showContextualModal } from '../../store/reducers/modalReducer';

interface Props {
  className?: string;
  trustee: PersonInterface;
  idx: number;
  role?: RoleInterface;
}

/**
 * return 'primary', 'backup', 'second backup', 'third backup'... etc
 * @param idx
 */
const getTier = ( idx: number ): string => {
  const tiers = [ 'primary', 'backup' ];
  return tiers[idx] || `${stringifyNumber( idx )} backup`;
};

const Trustee = ({
  className='',
  trustee,
  idx,
  role,
}: Props ): JSX.Element => {

  const { getTranslation } = useTranslations();

  const dispatch = useDispatch();

  const header = toTitleCase( `${getTier( idx )} Trustee` );

  const handleTrusteeRemoved = () => {
    const dynamicStringVariables = [ trustee.name || '', header ];
    const confirmRemoveTrustee = getTranslation( 'banner.role.general.removed.confirm', dynamicStringVariables );
    const trusteeRemovedSuccessMessage = getTranslation( 'banner.role.general.removed.success', dynamicStringVariables );
    if( !role ) {
      return;
    }
    dispatch( showContextualModal({
      description: confirmRemoveTrustee,
      dismissLabel: getTranslation( '(button)cancel' ),
      confirmLabel: getTranslation( '(button)remove' ),
      onConfirm: () => {
        dispatch( deleteRole({ id: role.id, onSuccess: () => {
          dispatch( makeSnack({ message: trusteeRemovedSuccessMessage }));
        } }));

      },
    }));
  };

  return (
    <>
      <ActionContainer
        className={ className }
        header={ header }
        headerClassName={ 'mb-2 text-black font-bold' }
        primaryActionLabel={ getTranslation( '(button)remove' ) }
        primaryAction={ handleTrusteeRemoved }
        primaryActionShouldFillContainer={ false }
        primaryActionTheme={ !!trustee ? 'clean-danger' : 'clean' }
      >
        { getTrusteeDescription( idx ) }
        <PersonSelectedContainer
          person={ trustee }
          role={ getTranslation( idx === 0 ?
            'products.trust.chapters.trustees.sections.primary.title' :
            'products.trust.chapters.trustees.sections.backup.title',
          ) }
          // this key is correct, consider changing to .role.label
          translationKey={ 'personSelector.executor.label' }
        />
      </ActionContainer>
      <InvitationWrapper
        inviteeId={ trustee.id }
        role={ Role.TYPE.TRUSTEE }
        invitationType={ Invitation.TYPE_ROLE }
      />
      <HorizontalRule/>
    </>
  );
};

export default Trustee;
