import { FC } from 'react';
import ReactModal from 'react-modal';

// Components
import Icon, { Icons } from './Icon';

import appConfig from '../../core/appConfig';
import useTranslations from '../hooks/useTranslations';

/**
 * @a11y
 * It is important for users of screenreaders that other page content be hidden
 * (via the aria-hidden attribute) while the modal is open. To allow
 * react-modal to do this, you should call Modal.setAppElement with a query
 * selector identifying the root of your app.
 * http://reactcommunity.org/react-modal/accessibility/#app-element
 */
if ( appConfig.app.env !== 'test' ) {
  ReactModal.setAppElement( '#root' );
}
export type ModalType = 'action-sheet' | 'contextual' | 'default' | 'bottom-contextual'

interface ModalProps {
  isOpen?: boolean;
  type?: ModalType;
  toggleModal?: ( value: boolean | (( prevVar: boolean )=> boolean ))=> void;
  transitionMs?: number;
  contentLabel?: string;
  className?: string;
  overlayClassName?: string;
  contentClassName?: string;
  closeIcon?: Icons;
  onClose?: ()=> void;
  onAfterOpen?: ()=> void;
}

export const Modal: FC<ModalProps> = ({
  children,
  className = '',
  contentClassName = '',
  overlayClassName = '',
  isOpen = true,
  closeIcon = 'close',
  type = 'default',
  onClose,
  onAfterOpen,
  toggleModal,
  transitionMs = 250,
}) => {

  const handleCancel = () => {
    if( toggleModal instanceof Function ) {
      toggleModal( !isOpen );
    }
    if( onClose instanceof Function ) {
      setTimeout(() => {
        onClose();
      }, transitionMs );
    }
  };

  const modalStyles = {
    wrapper: '',
    contentContainer: '',
    content: '',
  };

  switch ( type ) {
  case 'action-sheet':
    modalStyles.wrapper = 'p-2 flex-1 flex flex-col justify-center self-end max-w-full md:self-center md:max-w-[340px]';
    modalStyles.contentContainer ='rounded-lg shadow-lg w-full';
    break;
  case 'contextual':
    modalStyles.wrapper = 'p-8 flex-1 flex justify-center';
    modalStyles.contentContainer ='rounded-lg shadow-lg w-full max-w-[340px]';
    break;
  case 'default':
    modalStyles.wrapper = 'sm-only:flex sm-only:min-h-full md:p-12';
    modalStyles.contentContainer = 'sm-only:flex sm-only:flex-1 sm-only:w-screen md:rounded md:w-[450px]';
    modalStyles.content = 'sm-only:flex-1 sm-only:w-full sm-only:pt-modalCloseButtonOffset';
    break;
  case 'bottom-contextual':
    modalStyles.wrapper = 'sm-only:flex sm-only:self-end sm-only:w-full max-w-[450px]';
    modalStyles.contentContainer = 'sm-only:flex sm-only:flex-1 sm-only:w-screen md:rounded-b min-h-[334px]';
    modalStyles.content = 'sm-only:flex-1 sm-only:w-full sm-only:pt-modalCloseButtonOffset';
  }

  // needs to be cast as CSSProperties to be allowed on Modal style
  const overlayStyle = {
    '--modalTransitionMs': `${transitionMs}ms`,
    '--verticalTranslation': type === 'action-sheet' ? '100px' : '-20px',
    cursor: `${type !== 'contextual' ? 'pointer': 'initial'}`,
    overflowY: 'scroll',
  } as React.CSSProperties;

  const { getTranslation } = useTranslations();

  return (
    <ReactModal
      closeTimeoutMS={ transitionMs }
      isOpen={ isOpen }
      onAfterOpen={ onAfterOpen }
      style={{
        overlay: {
          ...overlayStyle,
        },
      }}
      overlayClassName={ overlayClassName }
      onRequestClose={ handleCancel }
      className={ `
        ${modalStyles.wrapper}
        ${className}
      ` }
    >
      {type === 'default' &&
        <button
          onClick={ handleCancel }
          id={ 'close-modal-button' }
          className="
          retreatButton
          top-6
          left-6
          md:top-12
          md:left-10
          bg-white
          shadow-md
          fixed
          z-10
          text-black
          rounded-full
          focus:outline-none
          focus-visible:ring-2
          focus-visible:ring-forest
          pointer-events-auto
          "
        >
          <Icon name={ closeIcon }/>
          <span className="sr-only">close</span>
        </button>
      }
      <div
        id="modal-header"
        className={ `
          modal-content
          ${modalStyles.contentContainer}
          overflow-hidden
          relative
          bg-white
          p-8
          ${contentClassName}
        ` }
      >
        <div
          className={ `
          ${modalStyles.content}
          flex
          flex-col
          ` }
        >
          { children }
        </div>
      </div>
      {type === 'action-sheet' &&
        <button
          onClick={ handleCancel }
          className="
          bg-white
          shadow-md
          z-10
          rounded-full
          focus:outline-none
          focus-visible:ring-2
          focus-visible:ring-blue
          w-full
          py-5
          mt-2
          font-bold
          text-forest
          "
        >
          {getTranslation( 'actionsheet.edit.cancel' )}
        </button>
      }
    </ReactModal>
  );
};

export default Modal;

