import { ReactElement } from 'react';

// Components
import VideoSegmentView from './VideoSegmentView';
import { VideoSourceMap } from '../../components/VideoPlayer';

// Hooks
import useBackstack from '../../hooks/useBackstack';

// Routes
import { dashboard as dashboardRoute } from '../../../core/routes';

export default function WhatIsTomorrow(): ReactElement{
  const { goBack } = useBackstack();
  const videoProps: VideoSourceMap = { landscape: 'video.home.whatistomorrow' };
  return (
    <VideoSegmentView
      onComplete={ goBack || dashboardRoute.get() }
      onRetreat={ goBack || dashboardRoute.get() }
      hidePrimaryAction={ true }
      hideSecondaryAction={ true }
      src={ videoProps }
      title="What is Tomorrow?"
    />
  );
}
