import TagManager from 'react-gtm-module';

import appConfig from '../../core/appConfig';
import { ENV_PRODUCTION } from '../../core/constants';

// Google Tag Manager
const tagManagerArgs = {
  gtmId: appConfig.gtag.id,
};

export const gTagInit = (): void => {
  if( appConfig.app.deploymentEnv === ENV_PRODUCTION ) {
    TagManager.initialize( tagManagerArgs );
  } else {
    console.warn( 'Google Tag Manager only available on production' );
  }
};

