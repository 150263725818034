const trustRoutes = [
  /**
   * Documents - Trust status
   */
  {
    key: 'trust',
    name: 'Your Trust',
    path: '/documents/trust',
    template: 'index.html',
  },

  /**
   * Documents - Trust basics
   */
  {
    key: 'trust_basics',
    name: 'Your Trust Basics',
    path: '/documents/trust/basics',
    template: 'index.html',
  },

  /**
   * Documents - Trust Trustees
   */
  {
    key: 'trustees',
    name: 'Your Trustees',
    path: '/documents/trust/trustees',
    template: 'index.html',
  },

  /**
   * Documents - Trust heirs
   */
  {
    key: 'trust_heirs',
    name: 'Your Heirs',
    path: '/documents/trust/heirs',
    template: 'index.html',
  },

  /**
   * Documents - Trust review
   */
  {
    key: 'trust_review',
    name: 'Review your Trust',
    path: '/documents/trust/review',
    template: 'index.html',
  },

  /**
   * Documents - Trust add funds
   */
  {
    key: 'trust_funding',
    name: 'Funding your Trust',
    path: '/documents/trust/review/funding',
    template: 'index.html',
  },
  /**
   * Trust - Pour Over Will
   */
  {
    key: 'pourOverWill',
    name: 'Pour Over Will',
    path: '/documents/trust/pourover',
    template: 'index.html',
  },

  /**
   * Accounts
   */
  {
    key: 'accounts',
    name: 'Review your Trust',
    path: '/documents/trust/review/accounts',
    template: 'index.html',
  },
];

export default trustRoutes;
